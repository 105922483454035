import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import InteractionHistoryCard from "../../common/InteractionHistoryCard/InteractionHistoryCard";
import BasicTimeline from "../../common/BasicTimeLine/BasicTimeLine";
import calenderIcon from "../../assets/svgs/email-icon.svg";
import profileIcon from "../../assets/svgs/profile_logo.svg";
import { InteractionHistoryStyles } from "./InteractionHistoryStyles";
import CustomIcon from "../../common/CustomIcon/CustomIcon";
import starIcon from "../../assets/svgs/chat-icon.svg";
import bellIcon from "../../assets/svgs/call-icon.svg";
import styled from "@emotion/styled";
const iconMap: { [key: string]: string } = {
  calenderIcon,
  bellIcon,
  starIcon,
  profileIcon,
};
export interface InteractionHistoryItemProps {
  left: string;
  cardIcon: string;
  idNumber: string;
  interaction: string;
  text: string;
  profileIcon: string;
  agentName: string;
}

export interface InteractionHistoryDataProps {
  interactionHistoryData: InteractionHistoryItemProps[];
}
const StyledContainer = styled(Box)({
  gap: "32px",
  paddingTop: "10px",
  "& .MuiTimelineOppositeContent-root": {
    maxWidth: "92px",
    padding: "0px",
    paddingRight: "21px",
    color: "var(--support-white)",
    "@media (max-width: 1023px)": {
      paddingRight: "0px",
      minWidth: "0px",
    },
  },
  "@media (max-width: 1023px)": {
    "& .MuiTimeline-root": {
      padding: "0px",
    },
    "& .MuiTimelineContent-root": {
      padding: "0px",
    },
  },
});
const InteractionHistory: React.FC<InteractionHistoryDataProps> = ({
  interactionHistoryData,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const iconMap: { [key: string]: React.ReactNode | string } = {
    calenderIcon: (
      <CustomIcon
        width={isSmallScreen ? "24px" : "36px"}
        height={isSmallScreen ? "24px" : "36px"}
        iconName="InteractionEmail"
      />
    ),
    bellIcon: (
      <CustomIcon
        width={isSmallScreen ? "24px" : "36px"}
        height={isSmallScreen ? "24px" : "36px"}
        iconName="InteractionPhone"
      />
    ),
    starIcon: (
      <CustomIcon
        width={isSmallScreen ? "24px" : "36px"}
        height={isSmallScreen ? "24px" : "36px"}
        iconName="InteractionChat"
      />
    ),
  };
  const mappedTimelineData = interactionHistoryData.map((item, index) => ({
    left: isSmallScreen ? "" : item.left,
    right: isSmallScreen ? (
      <Box sx={InteractionHistoryStyles.timelineCard}>
        <Typography sx={InteractionHistoryStyles.timelineLeft}>
          {item.left}
        </Typography>

        <InteractionHistoryCard
          key={index}
          cardIcon={iconMap[item.cardIcon]}
          idNumber={item.idNumber}
          interaction={item.interaction}
          text={item.text}
          profileIcon={profileIcon}
          agentName={item.agentName}
        />
      </Box>
    ) : (
      <InteractionHistoryCard
        key={index}
        cardIcon={iconMap[item.cardIcon]}
        idNumber={item.idNumber}
        interaction={item.interaction}
        text={item.text}
        profileIcon={profileIcon}
        agentName={item.agentName}
      />
    ),
  }));

  return (
    <StyledContainer>
      {isSmallScreen ? (
        <BasicTimeline type={"ternary"} data={mappedTimelineData} />
      ) : (
        <BasicTimeline data={mappedTimelineData} />
      )}
    </StyledContainer>
  );
};

export default InteractionHistory;
