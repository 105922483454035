import { Box, Typography } from "@mui/material";
import { IPremiumPerVehicle } from "../../../../../models/user/policyDetails/PolicyDetails";
import { PremiunStyles } from "./PremiumStyles";
import { currencyFormat } from "../../../../../utils/Utility";

interface IProps {
  perVehicledata: IPremiumPerVehicle[];
  totalPremium: string | null;
}

const Premium = (props: IProps) => {
  const { perVehicledata, totalPremium } = props;

  const getPerVehicleSection = () => {
    let element: any = <Box>No records found</Box>;
    if (perVehicledata && perVehicledata.length > 0) {
      element = perVehicledata.map((item, index) => {
        return (
          <Box key={`p-v-${index}`} sx={PremiunStyles.vehicleContainer}>
            <Typography sx={PremiunStyles.vehicleLabel}>
              {currencyFormat("us", parseFloat(item.amount))}
            </Typography>
            <Typography sx={PremiunStyles.vehicleText}>
              {`${item.manufactureYr}-${item.make}-${item.model}`}
            </Typography>
          </Box>
        );
      });
    }
    return element;
  };

  return (
    <>
      <Box sx={PremiunStyles.mainBox}>
        <Box sx={PremiunStyles.totalPremiumBox}>
          <Typography sx={PremiunStyles.totalPremiumLabel}>
            Total Policy Premium
          </Typography>
          <Typography sx={PremiunStyles.totalPremiumText}>
            {totalPremium
              ? currencyFormat("us", parseFloat(totalPremium))
              : `$0`}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{ ...PremiunStyles.totalPremiumLabel, float: "left" }}
          >
            Total Premium Per Vehicle
          </Typography>
          <Box sx={PremiunStyles.perVehicleBox}>{getPerVehicleSection()}</Box>
        </Box>
      </Box>
    </>
  );
};
export default Premium;
