import React, { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";
import tickIcon from "../../../../assets/tick.svg";
import {
  billingHead,
  dueOn,
  premiumHead,
  vehicles,
  dateFormat,
} from "../../../../constants/Constants";
import CustomTable, {
  IColumn,
} from "../../../../common/CustomTable/CustomTable";
import { currencyFormat } from "../../../../utils/Utility";
import CustomDialog from "../../../../common/CustomDialog/CustomDialog";
import { IPolicyDetailsHeader } from "../../../../models/user/policyDetails/PolicyDetails";
import BasicInfoCardStyles from "./BasicInfoCardStyles";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";

interface IProps {
  data: IPolicyDetailsHeader | null;
}

const BasicInfoCard: React.FC<IProps> = ({ data }: IProps) => {
  const [billingDetailsDialogOpen, setBillingDetailsDialogOpen] =
    useState<boolean>(false);

  let vehicleText = vehicles;
  if (data && data.riskCount <= 1) {
    vehicleText = "Vehicle";
  }

  const columns: IColumn[] = [
    {
      label: "Invoice Date",
      accessorKey: "invoiceDate",
      Cell: (bill: any) => (
        <Typography
          sx={{
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "initial",
          }}
        >
          {`${moment(bill.invoiceDate).format(dateFormat)}`}
        </Typography>
      ),
    },
    {
      label: "Due Date",
      accessorKey: "invoiceDueDate",
      Cell: (bill: any) => (
        <Typography
          sx={{
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "initial",
          }}
        >
          {`${moment(bill.invoiceDueDate).format(dateFormat)}`}
        </Typography>
      ),
    },
    {
      label: "Status",
      styles: { textAlign: "center" },
      accessorKey: "invoiceStatus",
      Cell: (bill: any) => (
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "initial",
          }}
        >
          {bill.invoiceStatus}
        </Typography>
      ),
    },
    {
      label: "Premium Amount",
      accessorKey: "premiumAmount",
      styles: { textAlign: "right" },
      Cell: (bill: any) => (
        <Typography
          sx={{
            textAlign: "right",
            fontFamily: "Noto Sans",
            fontSize: "14px",
            fontWeight: 400,
            whiteSpace: "initial",
          }}
        >
          {currencyFormat("us", bill.premiumAmount)}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "8% 92%",
          padding: "20px",
          gap: "20px",
          "@media (max-width: 768px)": {
            gridTemplateColumns: "20% 80%",
          },
        }}
        aria-label="Basic card info details container"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
          }}
        >
          {data && data.lineCode === "1" && (
            <CustomIcon iconName="Car" />
            // <img src={car} style={{ width: "100%" }} alt="car-logo" />
          )}
          {data && data.lineCode === "2" && <CustomIcon iconName="Home" />}
          <Box sx={BasicInfoCardStyles.status}>
            {data && data.carrierPolicyStatus === "Active" && (
              <img src={tickIcon} alt="tick-logo" />
            )}
            <Typography sx={BasicInfoCardStyles.statusText}>
              {data && data.carrierPolicyStatus ? data.carrierPolicyStatus : ""}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            "@media (max-width: 768px)": {
              gridTemplateColumns: "1fr",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Typography sx={BasicInfoCardStyles.head}>Insured</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                "@media (max-width: 1331px)": {
                  flexDirection: "column",
                  gap: "5px",
                },
              }}
            >
              <Typography sx={BasicInfoCardStyles.content}>
                {data && data.insuredName ? data.insuredName : ""}{" "}
                {data && data.accountType === "Joint" && data.insuredNameJoint
                  ? `| ${data.insuredNameJoint}`
                  : ""}
              </Typography>
              <Typography sx={BasicInfoCardStyles.content}>
                | {data && data.riskCount ? data.riskCount : "0"} {vehicleText}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                gap: "5px",
              },
            }}
          >
            <Typography
              sx={BasicInfoCardStyles.head}
            >{`${billingHead}`}</Typography>
            <Typography sx={BasicInfoCardStyles.content}>
              {data?.dueAmount && data.dueDate
                ? ` ${currencyFormat(
                    "us",
                    +parseFloat(data?.dueAmount).toFixed(2)
                  )} | ${dueOn} ${moment(data.dueDate).format(dateFormat)}`
                : "No Pending Dues"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <Typography
              sx={BasicInfoCardStyles.head}
            >{`${premiumHead}`}</Typography>
            <Box
              sx={{
                display: "flex",
                gap: "12px",
                "@media (max-width: 768px)": {
                  flexDirection: "column",
                  gap: "5px",
                },
              }}
            >
              <Typography sx={BasicInfoCardStyles.content}>
                {data ? currencyFormat("us", Number(data.totalPremium)) : ""} /{" "}
                {data ? data.tenure : ""}
              </Typography>
              <Typography sx={BasicInfoCardStyles.content}>
                |{" "}
                {data?.effectiveDate
                  ? `${moment(data.effectiveDate).format(
                      dateFormat
                    )} - ${moment(data.expiryDate).format(dateFormat)}`
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Typography sx={BasicInfoCardStyles.head}>Payment Plan</Typography>
            <Link
              sx={{ ...BasicInfoCardStyles.content, cursor: "pointer" }}
              onClick={() => setBillingDetailsDialogOpen(true)}
            >
              {data?.paymentPlan}
            </Link>
          </Box>
        </Box>
      </Box>
      <CustomDialog
        content={
          <CustomTable
            columns={columns}
            data={data?.policyHeaderBillingDetails || []}
          />
        }
        maxWidth="xl"
        onClose={() => setBillingDetailsDialogOpen(false)}
        open={billingDetailsDialogOpen}
        title="Payment Plans"
        showDivider={true}
      ></CustomDialog>
    </>
  );
};

export default BasicInfoCard;
