import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import {
  API_GET_TASKS,
  API_POST_DELETE_TASK,
} from "../../../Apis/ApiEndPoints";
import { Api } from "../../..";
import {
  IDeleteTaskRequestParams,
  IGetTasksRequestParams,
  IGetTasksResponse,
} from "../../../models/user/Tasks/Tasks";

export const getTasks = createAsyncThunk(
  "tasks/getTasks",
  async ({ customerId }: IGetTasksRequestParams, { dispatch }) => {
    const response: IGetTasksResponse = await CustomAction(
      Api.post(API_GET_TASKS, { customerId }),
      dispatch,
      "tasks/getTasks"
    );
    return response;
  }
);

export const deleteTask = createAsyncThunk(
  "tasks/deleteTask",
  async (requestParams: IDeleteTaskRequestParams, { dispatch }) => {
    const response: IGetTasksResponse = await CustomAction(
      Api.post(API_POST_DELETE_TASK, requestParams),
      dispatch,
      "tasks/deleteTask"
    );
    return response;
  }
);
