export const DrawerStyles = {
  heading: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "26.66px",
    textAlign: "left",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  children: {
    width: "700px",
    padding: "16px",
  },
  close: {
    width: "20px",
    height: "20px",
    cursor: "pointer",
  },
};
