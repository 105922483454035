import { Avatar, Box } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../ImageAndText/ImageAndText";
import phoneIcon from "../../assets/svgs/phone-icon.svg";
import mailIcon from "../../assets/svgs/mail-icon.svg";
import docIcon from "../../assets/svgs/document-icon.svg";
import stringAvatar from "../../utils/StringAvatar";
import { CustomerListingCardStyles } from "./CustomerListingCardStyles";
import {
  ACTIVE,
  CLAIM_STATUS_CAPS,
  EXPIRED,
  PENDING,
  PENDING_RENEWAL,
  POLICY_STATUS_CAPS,
  TASK,
  TASKS,
  VIEW_DETAILS,
} from "../../constants";
import { useNavigate } from "react-router-dom";

interface CustomerListingCardProps {
  name: string;
  image: string;
  policyNo: string;
  phoneNumber: string;
  email: string;
  policyStatus: {
    active: number;
    pending: number;
    expired: number;
    tasks: number;
  };
  claimStatus: {
    open: number;
    closed: number;
  };
}

const CustomerListingCard: React.FC<CustomerListingCardProps> = ({
  name,
  policyNo,
  phoneNumber,
  email,
  policyStatus,
  claimStatus,
  image,
}) => {
  const navigate = useNavigate();
  const formatPolicyStatus = (policyStatus: {
    active: number;
    pending: number;
    expired: number;
    tasks: number;
  }): string => {
    const { active, pending, expired, tasks } = policyStatus;
    let statusString = "";
    if (active > 0) statusString += `${active} ${ACTIVE}`;
    if (pending > 0)
      statusString +=
        (statusString ? " | " : "") + `${pending} ${PENDING_RENEWAL}`;
    if (expired > 0)
      statusString += (statusString ? " | " : "") + `${expired} ${EXPIRED}`;
    if (tasks > 0)
      statusString +=
        (statusString ? " | " : "") +
        `${tasks} ${tasks > 1 ? TASKS : TASK} ${PENDING} `;
    return statusString;
  };

  const formatClaimStatus = (claimStatus: {
    open: number;
    closed: number;
  }): string => {
    const { open, closed } = claimStatus;
    let statusString = "";
    if (open === 0 && closed === 0) {
      return "0 Claims";
    }
    if (open > 0) statusString += `${open} Open`;
    if (closed > 0)
      statusString += (statusString ? " | " : "") + `${closed} Closed`;

    return statusString;
  };
  const handleNavigationClick = () => {
    navigate("/customer-details");
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <Box sx={CustomerListingCardStyles.container}>
      <Box sx={CustomerListingCardStyles.header}>
        <Box sx={CustomerListingCardStyles.avatar}>
          {image.length === 0 ? (
            <Avatar {...stringAvatar(name)} />
          ) : (
            <Avatar src={image} />
          )}
          <Box sx={CustomerListingCardStyles.headingContainer}>
            <HeadingSubHeading
              headingAdditionalClassName={
                CustomerListingCardStyles.avatarheadingText
              }
              containerAdditionalClassName={CustomerListingCardStyles.heading}
              subHeadingAdditionalClassName={
                CustomerListingCardStyles.subHeadingPolicyText
              }
              heading={name}
              subheading={policyNo}
            />
          </Box>
        </Box>
        <Box sx={CustomerListingCardStyles.saperator}></Box>
        <Box sx={CustomerListingCardStyles.imageContainer}>
          <ImageAndText
            additionalClassName={CustomerListingCardStyles.imageAndText}
            image={phoneIcon}
            text={phoneNumber}
          />
          <ImageAndText
            additionalClassName={CustomerListingCardStyles.imageAndText}
            image={mailIcon}
            text={email}
            additionalClassText={CustomerListingCardStyles.email}
          />
        </Box>
      </Box>
      <Box sx={CustomerListingCardStyles.statuses}>
        <HeadingSubHeading
          heading={formatPolicyStatus(policyStatus)}
          headingAdditionalClassName={
            CustomerListingCardStyles.headingStatusText
          }
          containerAdditionalClassName={CustomerListingCardStyles.status}
          subHeadingAdditionalClassName={
            CustomerListingCardStyles.subHeadingText
          }
          subheading={POLICY_STATUS_CAPS}
        />
        <HeadingSubHeading
          heading={formatClaimStatus(claimStatus)}
          headingAdditionalClassName={
            CustomerListingCardStyles.headingStatusText
          }
          containerAdditionalClassName={CustomerListingCardStyles.status}
          subHeadingAdditionalClassName={
            CustomerListingCardStyles.subHeadingText
          }
          subheading={CLAIM_STATUS_CAPS}
        />
      </Box>

      <ImageAndText
        additionalClassName={CustomerListingCardStyles.imageAndTextDetails}
        image={docIcon}
        text={VIEW_DETAILS}
        onClick={handleNavigationClick}
        additionalClassText={CustomerListingCardStyles.viewDetails}
      />
    </Box>
  );
};

export default CustomerListingCard;
