import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CustomerList from "../CustomerList/CustomerList";
import CustomerListCards from "../CustomerListingResponsive/CustomerListingResponsive";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import { CustomerlistingStyles } from "./CustomerListingStyles";
import HeadingText from "../../common/HeadingText/HeadingText";
import { CUSTOMERS, CUSTOMER_LISTING_CAPS, FILTER } from "../../constants";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import {
  customerListingData,
  customerListingTableHeadingsData,
} from "../../constants/customerListingData";

const CustomerListing: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  return (
    <>
      <Box sx={CustomerlistingStyles.container}>
        <Box sx={CustomerlistingStyles.headingContainer}>
          <HeadingText
            additionalHeadingClass={CustomerlistingStyles.heading}
            heading={isSmallScreen ? CUSTOMERS : CUSTOMER_LISTING_CAPS}
          />
          <ImageAndText
            onhandleClick={handleClick}
            image={filterIcon}
            text={FILTER}
          />
        </Box>{" "}
        {isSmallScreen ? (
          <CustomerListCards customerListingData={customerListingData} />
        ) : (
          <CustomerList
            customerListingData={customerListingData}
            headerData={customerListingTableHeadingsData}
          />
        )}
      </Box>
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default CustomerListing;
