const TasksStyles = {
  tasks: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "100%",
    minHeight: "88.5vh",
    "@media (max-width:768px)": { padding: "10px" },
  },
  heading: {
    color: "#383D4E",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Playfair Display",
    lineHeight: "22px",
  },
};

export default TasksStyles;
