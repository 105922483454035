import React from "react";
import { Props } from "./Props";

const DiscountIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41 40"
      fill="none"
    >
      <circle cx="21.0003" cy="20.3333" r="4.33333" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2461 14.2988C12.2461 12.642 13.5892 11.2988 15.2461 11.2988C16.9029 11.2988 18.2461 12.642 18.2461 14.2988C18.2461 15.9557 16.9029 17.2988 15.2461 17.2988C13.5892 17.2988 12.2461 15.9557 12.2461 14.2988ZM15.2461 12.2988C14.1415 12.2988 13.2461 13.1943 13.2461 14.2988C13.2461 15.4034 14.1415 16.2988 15.2461 16.2988C16.3507 16.2988 17.2461 15.4034 17.2461 14.2988C17.2461 13.1943 16.3507 12.2988 15.2461 12.2988Z"
        fill="black"
      />
      <path
        d="M28.0996 12.4453C28.2949 12.6405 28.2949 12.9571 28.0996 13.1524L14.0996 27.1524C13.9044 27.3476 13.5878 27.3476 13.3925 27.1524C13.1973 26.9571 13.1973 26.6405 13.3925 26.4453L27.3925 12.4453C27.5878 12.25 27.9044 12.25 28.0996 12.4453Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2461 22.2988C24.5892 22.2988 23.2461 23.642 23.2461 25.2988C23.2461 26.9557 24.5892 28.2988 26.2461 28.2988C27.9029 28.2988 29.2461 26.9557 29.2461 25.2988C29.2461 23.642 27.9029 22.2988 26.2461 22.2988ZM24.2461 25.2988C24.2461 24.1943 25.1415 23.2988 26.2461 23.2988C27.3507 23.2988 28.2461 24.1943 28.2461 25.2988C28.2461 26.4034 27.3507 27.2988 26.2461 27.2988C25.1415 27.2988 24.2461 26.4034 24.2461 25.2988Z"
        fill="black"
      />
    </svg>
  );
};

export default DiscountIcon;
