const LienHoldersStyles = {
  leinholderContainer: {
    marginTop: "20px",
    marginBottom: "60px",
  },
  searchBox: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    marginBottom: "20px",
    gap: "8px",
    "@media(max-width: 599px)": {
      alignItems: "center",
    },
  },
  searchInput: {
    width: "251px",
    display: "flex",
    alignItems: "center",
    borderRadius: "2px",
    border: "var(--none, 1px) solid var(--Neutral-5, #D9D9D9)",
    background: "var(--Neutral-1, #FFF)",
    padding: "5px 12px",
    gap: "var(--borderRadius, 4px)",
    flexShrink: 0,
    "@media(max-width: 599px)": {
      width: "100%",
    },
  },
  searchPlaceholder: {
    fontSize: "14px",
    width: "207px",
    ml: 1,
    flex: 1,
    padding: "0px",
    margin: "0px",
  },
  tableHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "20.5px",
    cursor: "pointer",
    justifyContent: "flex-end",
    "@media (min-width: 699px) and (max-width: 799px)": {
      marginTop: "-25px",
      marginBottom: "16px",
    },
  },
  textField: {
    width: "300px",
    height: "56px",
    marginBottom: "20px",
    fontFamily: "Noto Sans",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  tableContainer: {
    width: "auto",
    border: "1px solid rgba(201, 225, 236, 1)",
    marginLeft: "0px",
    boxShadow: "none",
    "@media (max-width: 799px)": {
      display: "none",
    },
  },
  leinholderMobileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "60px",
  },
  tableHeadText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "70%",
    borderBottom: "0px",
  },
  leinholderTableRows: {
    borderBottom: "1px solid #D0D1D9",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
  tableRow: {
    borderBottom: "1px solid #3F3F3F",
  },
  tableHeadCellContainer: {
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    "&:hover": {
      img: {
        visibility: "visible",
      },
    },
    img: {
      visibility: "hidden",
    },
  },
  tableBodyCells: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    borderBottom: "0px",
  },
  cardContainer: {
    display: "none",
    "@media(max-width: 799px)": {
      display: "block",
    },
  },
  container: {
    display: "flex",
    height: "auto",
    width: "auto",
    columnGap: "20px",
    rowGap: "23.94px",
    flexWrap: "wrap",
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(201, 225, 236, 1)",
    borderRadius: "13px",
    padding: "30px",
  },
  contentText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  cardContent: {
    minWidth: "89px",
    display: "flex",
    flexDirection: "column",
    gap: "2.09px",
  },
  descriptionText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "rgba(143, 147, 155, 1)",
  },
  noLienHoldersCard: {
    padding: "30px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  },
  noLienHoldersText: {
    overflow: "hidden",
    color: "#1D252B",
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "400",
    lineHeight: "19.8px",
    textOverflow: "ellipsis",
    "@media (max-width:599px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "@media (min-width:600px) and (max-width:1366px)": {
      whiteSpace: "nowrap",
    },
  },
};
export default LienHoldersStyles;
