import React from "react";
import { Props } from "./Props";

const InteractionCardStarIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 41"
      fill="none"
    >
      <path
        d="M9.50586 27.7646L11.8397 6.76367L25.3212 23.3573L9.50586 27.7646Z"
        fill={fill}
      />
      <path
        d="M23.8723 11.8298L20.3047 1L15.8457 16.926"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M12.6906 15.7186L1.29168 15.996L15.2431 24.8773"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M12.16 27.5266L8.2392 38.2337L21.6167 28.5094"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M23.4486 30.9074L32.2212 38.1913L27.5454 22.3276"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M31.2432 22.5324L40.4895 15.86L23.9606 16.4202"
        stroke="#262729"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InteractionCardStarIcon;
