import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { FormEvent, useCallback, useState } from "react";
import LoginWithNameAndMobileStyles from "./LoginWithNameAndMobileStyles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { login } from "../../store/user/userAuth/userAuthActions";
import { ILoginRequestParams } from "../../models/user/userAuth/userAuth";

interface IProps {
  isFunctionable: boolean;
}

const LoginWithNameAndMobile: React.FC<IProps> = ({ isFunctionable }) => {
  const dispatch = useAppDispatch();

  const { calledReducerType, isError, message } = useAppSelector(
    (state) => state.common
  );

  const [loginData, setLoginData] = useState<ILoginRequestParams>({
    customerName: "",
    mobile: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    customerName: "",
    mobile: "",
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = event.target;
      setValidationErrors((prevState) => ({ ...prevState, [name]: "" }));
      if (name === "mobile") {
        const cleanedValue = value.replace(/\D/g, "");
        if (cleanedValue.length <= 10) {
          setLoginData((prev) => ({
            ...prev,
            [name]: cleanedValue,
          }));
        }
      } else {
        setLoginData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    []
  );

  const validate = useCallback(() => {
    let validationErrors = {
      customerName: "",
      mobile: "",
    };
    if (!loginData.customerName || loginData.customerName.trim().length <= 0) {
      validationErrors.customerName = "Name is required";
    }
    if (!loginData.mobile || loginData.mobile.trim().length !== 10) {
      validationErrors.mobile = "Invalid mobile number";
    }
    return validationErrors;
  }, [loginData]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      const errors = validate();
      if (Object.values(errors).some((error) => error)) {
        setValidationErrors(errors);
        return;
      }
      dispatch(login({ loginType: "direct", requestParams: loginData }));
    },
    [loginData, validate, dispatch]
  );

  return (
    <form
      onSubmit={handleSubmit}
      style={LoginWithNameAndMobileStyles.loginWithNameAndMobileForm}
    >
      {isError && calledReducerType === "userAuth/login" ? (
        <Alert severity="error">
          {message || "Something went wrong, please try again..."}
        </Alert>
      ) : (
        <></>
      )}
      <FormControl disabled={!isFunctionable} fullWidth>
        <InputLabel>Name</InputLabel>
        <OutlinedInput
          label="Name"
          type={"text"}
          placeholder="Please enter your username"
          name="customerName"
          onChange={handleChange}
          value={loginData.customerName}
          error={Boolean(validationErrors.customerName)}
        />
        {validationErrors.customerName ? (
          <Typography color="error">{validationErrors.customerName}</Typography>
        ) : (
          <></>
        )}
      </FormControl>
      <FormControl disabled={!isFunctionable} fullWidth>
        <InputLabel>Mobile Number</InputLabel>
        <OutlinedInput
          label="Mobile Number"
          type={"text"}
          placeholder="Please enter your mobile number"
          name="mobile"
          onChange={handleChange}
          value={loginData.mobile}
          error={Boolean(validationErrors.mobile)}
        />
        {validationErrors.mobile ? (
          <Typography color="error">{validationErrors.mobile}</Typography>
        ) : (
          <></>
        )}
      </FormControl>
      <Button
        variant="contained"
        type="submit"
        disabled={
          Boolean(
            validationErrors.customerName || Boolean(validationErrors.mobile)
          ) || !isFunctionable
        }
      >
        Login
      </Button>
    </form>
  );
};

export default LoginWithNameAndMobile;
