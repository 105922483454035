const ActivityStyles = {
  container: {
    width: "auto",
    height: "auto",
    marginBottom: "69px",
    "@media (min-width:360px) and (max-width:599px)": {
      padding: "0px !important",
      marginBottom: "128px",
      height: "auto",
    },
    "@media (min-width:600px) and (max-width:1024px)": {
      padding: "0px !important",
      marginTop: "-18px",
    },
  },

  hoverCard: {
    width: "100%",
    "@media (min-width: 1025px)": {
      "&:hover": {
        height: "auto",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.09) inset",
        background: "rgba(243, 245, 246, 1)",
      },
    },
  },
  oneCard: {
    position: "relative",
    height: "auto",
    width: "95%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30.06px",
    marginRight: "auto",
    marginLeft: "auto",
    background: "none",
    "@media (min-width:360px) and (max-width:1024px)": {
      marginTop: "30px",
      width: "auto",
      height: "auto",
      border: "1px solid rgba(201, 225, 236, 1)",
      background: "rgba(255, 255, 255, 1)",
      borderRadius: "10px",
      gap: "17.46px",
      padding: "0px 20px",
    },
  },

  logo: {
    img: {
      width: "40.94px",
      height: "40px",
      marginTop: "-20px",
      "@media (min-width: 360px) and (max-width: 1024px)": {
        width: "46.36px",
        height: "48.88px",
      },
    },
  },
  cardContent: {
    marginTop: "23px",
    height: "auto",
    width: "auto",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      marginTop: "20px",
      height: "auto",
      width: "auto",
    },
  },
  heading: {
    width: "auto",
    height: "22px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      height: "18px",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },
  description: {
    width: "auto",
    height: "20px",
    marginTop: "6px",
    left: "132px",
    fontWeight: "300",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      height: "18px",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },

  date: {
    width: "auto",
    height: "20px",
    marginTop: "7px",
    marginBottom: "21px",
    fontWeight: "300",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",

    "@media (min-width: 360px) and (max-width: 1024px)": {
      height: "18px",
      marginBottom: "20px",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
    },
  },
  claimNumberClosureReason: {
    display: "flex",
    alignItems: "center",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      flexDirection: "column",
      alignItems: "initial",
    },
  },
  line: {
    marginTop: "16px",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "19.8px",
    textAlign: "left",
    margin: "10px 10px 0px 10px",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      display: "none",
    },
  },

  closureReason: {
    width: "auto",
    height: "20px",
    marginTop: "12.5px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      width: "222px",
      height: "18px",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
      marginTop: "5px",
      marginBottom: "20px",
    },
  },
  claimNumber: {
    width: "auto",
    height: "20px",
    marginTop: "12.5px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      height: "18px",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "18px",
      letterSpacing: "0em",
      textAlign: "left",
      marginTop: "5px",
    },
  },
  claimLine: {
    margin: "10px 10px 0px 10px",
  },
  payoutAmount: {
    width: "auto",
    height: "20px",
    marginTop: "12.5px",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
  },
  hoveredImage1: {
    width: "40px",
    height: "40px",
  },
  hoverImg: {
    display: "flex",
    justifyContent: "space-between",
    flex: "1 1 0%",
  },
  images: {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    left: "94.0549%",
    cursor: "pointer",
    justifyContent: "flex-end",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      display: "none",
    },
  },
  ellipsis: {
    display: "none",
    "@media (min-width: 360px) and (max-width: 1024px)": {
      position: "absolute",
      display: "block",
      marginTop: "20px",
      right: "5.55%",
    },
  },
  tableHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "20.5px",
    marginLeft: "95%",
  },
  tableHeadingFilterImage: {
    img: {
      width: "24px",
      height: "24px",
    },
  },
  tableHeadingFilterText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    marginTop: "-5px",
  },
};
export default ActivityStyles;
