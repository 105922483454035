import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import { SendRemainderResponsiveStyles } from "./SendRemainderResponsiveStyles";
import {
  SEND_REMAINDER,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import SendRemainder from "../../common/SendRemainder/SendRemainder";
import { sendRemaindersAndShareMarketData } from "../../constants/SendRemaindersAndShareMarketData";

const SendRemainderResponsive: React.FC = () => {
  const navigate = useNavigate();
  const [selectedDropdownValue, setSelectedDropdownValue] = React.useState("");
  const handleRemainderChange = (event: SelectChangeEvent) => {
    setSelectedDropdownValue(event.target.value as string);
  };
  return (
    <Box sx={SendRemainderResponsiveStyles.container}>
      <DashboardBreadCrumb
        handleClick={() => navigate(-1)}
        additionalContentClass={SendRemainderResponsiveStyles.breadCrumbText}
        additionalContianerClass={SendRemainderResponsiveStyles.breadCrumb}
        variant={"primary"}
        text={SEND_REMAINDER}
      />
      <SendRemainder
        recieverName={sendRemaindersAndShareMarketData.recieverName}
        dropDownData={sendRemaindersAndShareMarketData.dropdownData}
        primary={false}
        fileUploadName={sendRemaindersAndShareMarketData.fileUploadName}
        fileUploadSize={sendRemaindersAndShareMarketData.fileUploadSize}
        handleRemainderChange={handleRemainderChange}
        selectedDropdownValue={selectedDropdownValue}
      />
    </Box>
  );
};

export default SendRemainderResponsive;
