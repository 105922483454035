export const FormDropdownStyles = {
  container: {
    width: "100%",
    maxWidth: "360px",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
  },
  formControl: {
    width: "100%",
  },
  menuItem: {},
};
