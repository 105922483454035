import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Dayjs } from "dayjs";
import { SxProps, Theme } from "@mui/system";
import styled from "@emotion/styled";

interface TimePickerProps {
  label: string;
  additionalClassName?: SxProps<Theme>;
  handleTime?: (time: Dayjs | null) => void;
}
const StyledDemoContainer = styled("div")({
  "& .MuiTextField-root": {
    width: "360px",
    "& .MuiStack-root": {
      paddingTop: "0px !important",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      minWidth: "320px !important",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "var(--font-noto-sans)",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "var(--font-noto-sans)",
  },
});
const TimePickerComponent: React.FC<TimePickerProps> = ({
  label,
  additionalClassName,
  handleTime,
}) => {
  const handleTimePickerChange = (value: Dayjs | null) => {
    if (handleTime) {
      handleTime(value);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDemoContainer>
        <TimePicker
          sx={additionalClassName}
          ampm={false}
          label={label}
          onChange={handleTimePickerChange}
        />
      </StyledDemoContainer>
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
