import React from "react";
import { Props } from "./Props";

const ClaimsCardCarIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
    >
      <path
        d="M9.49442 10.5H34.1275V18.754H2.35156L9.49442 10.5Z"
        fill={fill}
      />
      <path d="M48.1782 18.75H42.9766" stroke="black" stroke-width="1.5" />
      <path d="M7.35003 18.2734H2.14844" stroke="black" stroke-width="1.5" />
      <path
        d="M12.0397 39.1485C11.9986 40.2609 11.4467 42.535 9.56866 42.7325C7.69061 42.9299 5.5737 42.8147 4.75 42.7325V24.199L11.1748 10.9789C11.7514 9.70206 13.5471 7.14844 16.117 7.14844C18.6869 7.14844 28.8843 7.14844 33.6618 7.14844C34.8973 7.35435 37.6897 8.40872 38.9746 10.9789C40.2596 13.549 43.7933 20.8632 45.3995 24.199V42.7325C43.5662 43.0515 39.7983 43.3598 39.3924 42.0407C38.9865 40.7215 38.9696 39.5629 39.0119 39.1485"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M41.878 34.7734C39.7728 35.3544 32.4126 36.3401 24.9358 36.3401C15.7884 36.3401 11.0621 36.5157 9.21094 34.7734"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M34.9053 30.1543C34.5826 28.3504 35.2928 24.8678 40.7155 25.3691"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M14.5596 30.1543C14.8823 28.3504 14.172 24.8678 8.74937 25.3691"
        stroke="black"
        stroke-width="1.5"
      />
      <path
        d="M31.5838 30.3848L33.0007 26.1562H17.4141L18.6366 30.3848H31.5838Z"
        stroke="black"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default ClaimsCardCarIcon;
