import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import HeadingText from "../../common/HeadingText/HeadingText";
import archiveBlackIcon from "../../assets/svgs/archive-black-icon.svg";
import moveIcon from "../../assets/svgs/move-icon.svg";
import expandIcon from "../../assets/svgs/expand-view-icon.svg";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import { DocumentPreviewStyles } from "./DocumentPreviewStyles";
import {
  ARCHIVE,
  DOCUMENT_PREVIEW,
  DOWNLOAD,
  EXPAND,
  MOVE,
} from "../../constants";
interface DocumentPreviewProps {
  template: string;
  values: {
    companyName: string;
    contactName: string;
    date: string;
    stateName: string;
  };
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  template,
  values,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  const populateTemplate = (
    template: string,
    values: DocumentPreviewProps["values"]
  ): string => {
    return template
      .replace(/\[Company name\]/g, values.companyName)
      .replace(/\[Contact name\]/g, values.contactName)
      .replace(/\[Date here\]/g, values.date)
      .replace(/\[State name\]/g, values.stateName);
  };
  return (
    <Box sx={DocumentPreviewStyles.container}>
      <Box sx={DocumentPreviewStyles.header}>
        <Box sx={DocumentPreviewStyles.headingTextContainer}>
          <HeadingText
            additionalHeadingClass={DocumentPreviewStyles.headingText}
            heading={DOCUMENT_PREVIEW}
          />
          {isSmallScreen && (
            <ImageAndText
              additionalClassText={DocumentPreviewStyles.text}
              additionalClassName={DocumentPreviewStyles.imageAndTextContainer}
              image={expandIcon}
              text={EXPAND}
              imageClassName={DocumentPreviewStyles.expandIcon}
            />
          )}
        </Box>
        <Box sx={DocumentPreviewStyles.imagesContainer}>
          <ImageAndText
            additionalClassText={DocumentPreviewStyles.text}
            additionalClassName={DocumentPreviewStyles.imageAndTextContainer}
            image={archiveBlackIcon}
            text={ARCHIVE}
            imageClassName={DocumentPreviewStyles.archiveIcon}
          />
          <Typography sx={DocumentPreviewStyles.line}></Typography>
          <ImageAndText
            additionalClassText={DocumentPreviewStyles.text}
            additionalClassName={DocumentPreviewStyles.imageAndTextContainer}
            image={moveIcon}
            text={MOVE}
            imageClassName={DocumentPreviewStyles.moveIcon}
            
          />
          <Typography sx={DocumentPreviewStyles.line}></Typography>
          {!isSmallScreen && (
            <ImageAndText
              additionalClassText={DocumentPreviewStyles.text}
              additionalClassName={DocumentPreviewStyles.imageAndTextContainer}
              image={expandIcon}
              text={EXPAND}
              imageClassName={DocumentPreviewStyles.expandIcon}
            />
          )}
          <ImageAndText
            additionalClassText={DocumentPreviewStyles.text}
            additionalClassName={DocumentPreviewStyles.imageAndTextContainer}
            image={downloadIcon}
            text={DOWNLOAD}
            imageClassName={DocumentPreviewStyles.downloadIcon}
          />
        </Box>
      </Box>
      <Box sx={DocumentPreviewStyles.textContainer}>
        <Typography sx={DocumentPreviewStyles.templateStyles}>
          {populateTemplate(template, values)}
        </Typography>
      </Box>
    </Box>
  );
};

export default DocumentPreview;
