export const PaymentScheduleCardStyles = {
  container: {
    height: "92px",
    width: "601px",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "5px",
    display: "flex",
    gap: "70px",
    padding: " 35px 35px 30px",
    paddingRight: "unset",
    boxSizing: "border-box",
    backgroundColor: "var(--white)",
    "@media (max-width: 1200px)": {
      width: "100%",
      height: "auto",
      flexWrap: "wrap",
      rowGap: "12px",
      columnGap: "30px",
      padding: "16px 16px",
    },
  },
  date: {
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
  },
  name: {
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
  },
  payment: {
    borderRadius: "1px",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
    color: "var(--support-white)",
    fontFamily: "var(--font-noto-sans)",
    minWidth: "84px",
  },
  price: {
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
  },
  text: {
    fontWeight: "var(--font-weight-300)",
    fontSize: "var(--font-size-12)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    " @media (min-width: 1023px)": {
      display: "none",
    },
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  totalContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  imagesContainer: {
    cursor: "unset",
    gap: "5px",
  },
};
