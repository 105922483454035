import { Box, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import CustomerDetailsHeader from "../../common/CustomerDetailsHeader/CustomerDetailsHeader";
import DropDown from "../../common/DropDown/DropDown";
import InvoicesCardListing from "../InvoicesCardListing/InvoicesCardListing";
import { CustomerDetailsResponsiveStyles } from "./CustomerDetailsResponsiveStyles";
import OverViewCards from "../OverViewCards/OverViewCards";
import { PersonChatProps } from "../CustomerDetailsNotes/CustomerDetailsNotes";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import { FILTER } from "../../constants";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import CustomerDetailsNotes from "../CustomerDetailsNotes/CustomerDetailsNotes";
import InteractionHistory, {
  InteractionHistoryItemProps,
} from "../InteractionHistory/InteractionHistory";
import ClaimHistory from "../ClaimHistory/ClaimHistory";
import {
  ClaimHistoryProps,
  CustomerDetailsHeaderCardDataProps,
  InvoicesTableDataProps,
  MytasksDataProps,
  OverviewTableDataProps,
} from "../CustomerDetailsList/CustomerDetailsList";
import PaymentMethod, {
  PaymentMethodCardDataProps,
} from "../../common/PaymentMethod/PaymentMethod";

interface ClaimCardDataProps {
  activeClaimsData: ClaimHistoryProps[];
  pastClaimsData: ClaimHistoryProps[];
}

interface CutomerDetailsDataProps {
  claimData: ClaimCardDataProps;
  interactionHistoryData: InteractionHistoryItemProps[];
  overviewData: OverviewTableDataProps[];
  mytasksData: MytasksDataProps[];
  invoiceCards: InvoicesTableDataProps[];
  paymentMethodCardData: PaymentMethodCardDataProps[];
  customerHeaderData: CustomerDetailsHeaderCardDataProps;
  notesChatData: PersonChatProps[];
}
const CustomerDetailsResponsive: React.FC<CutomerDetailsDataProps> = ({
  claimData,
  interactionHistoryData,
  overviewData,
  mytasksData,
  invoiceCards,
  paymentMethodCardData,
  customerHeaderData,
  notesChatData,
}) => {
  const dropdownData = [
    { name: "Overview", value: "overView" },
    { name: "Invoices", value: "invoices" },
    { name: "Payment Methods", value: "paymentMethods" },
    { name: "Claim History", value: "claimHistory" },
    { name: "Interaction History", value: "interactionHistory" },
    { name: "Notes", value: "notes" },
  ];

  const [value, setValue] = useState("overView");

  const handleDropDownChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  const renderComponent = () => {
    switch (value) {
      case "overView":
        return (
          <OverViewCards
            overviewData={overviewData}
            mytasksData={mytasksData}
          />
        );
      case "claimHistory":
        return (
          <ClaimHistory
            activeClaimsData={claimData.activeClaimsData}
            pastClaimsData={claimData.pastClaimsData}
          />
        );
      case "invoices":
        return <InvoicesCardListing invoiceCards={invoiceCards} />;
      case "paymentMethods":
        return <PaymentMethod paymentMethodCardData={paymentMethodCardData} />;
      case "interactionHistory":
        return (
          <InteractionHistory interactionHistoryData={interactionHistoryData} />
        );
      case "notes":
        return <CustomerDetailsNotes personChatArray={notesChatData} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={CustomerDetailsResponsiveStyles.container}>
        <CustomerDetailsHeader data={customerHeaderData} />
        <Box sx={CustomerDetailsResponsiveStyles.componentRendererContainer}>
          <Box sx={CustomerDetailsResponsiveStyles.dropDown}>
            <DropDown
              type="primary"
              data={dropdownData}
              placeholder={"Select"}
              selectedValue={value}
              onChange={handleDropDownChange}
              selectAdditionalClassName={
                CustomerDetailsResponsiveStyles.selectText
              }
              menuItemClassName={CustomerDetailsResponsiveStyles.menuItem}
              formControlAdditionalClassName={
                CustomerDetailsResponsiveStyles.dropDownContainer
              }
            />
            {(value === "invoices" || value === "interactionHistory") && (
              <ImageAndText
                onhandleClick={handleClick}
                image={filterIcon}
                text={FILTER}
              />
            )}
          </Box>
          <Box>{renderComponent()}</Box>
        </Box>
      </Box>

      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default CustomerDetailsResponsive;
