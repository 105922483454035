export const CelebrationCardStyles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "@media screen and (max-width: 1023px)": {
      gap: "0px",
      marginTop: "21px",
    },
  },
  card: {
    border: "1px solid var(--primary-grey)",
    borderRadius: "8px",
    padding: "10px 15px 10px 10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "@media screen and (max-width: 1023px)": {
      border: "none",
      borderRadius: "0px",
      borderBottom: "1px solid var(--primary-grey)",
      padding: "14px 0px 14px 8px",
      gap: "6px",
    },
    "&:last-child": {
      "@media screen and (max-width: 1023px)": {
        borderBottom: "0px",
      },
    },
  },
  text: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.34px",
    },
  },
};
