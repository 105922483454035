import React from "react";
import { Props } from "./Props";

const HomeIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 64 55"
      fill="none"
    >
      <rect
        x="26.5547"
        y="19.09"
        width="22.4099"
        height="22.4099"
        rx="2"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4316 46.602V24.1921H42.8415V46.602H20.4316ZM21.4316 25.1921H31.2148V34.8208H21.4316V25.1921ZM21.4316 35.8208V45.602H31.2148V35.8208H21.4316ZM32.2148 35.8208V45.602H41.8415V35.8208H32.2148ZM41.8415 34.8208V25.1921H32.2148V34.8208H41.8415Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0288 0.368896L63.0495 24.2676L62.4392 25.0598L58.5409 22.0565V54.3119H5.19922V21.9293L1.30234 24.906L0.695312 24.1114L11.9785 15.4924L12.0957 15.6458V0.658203H26.8864V4.33004L32.0288 0.368896ZM6.19922 53.3119V21.1654L12.0957 16.6613V24.1948H13.0957V1.6582H25.8864V5.10032L20.127 9.53665L20.7372 10.3289L32.0288 1.63121L57.5409 21.2861V53.3119H6.19922Z"
        fill="black"
      />
    </svg>
  );
};

export default HomeIcon;
