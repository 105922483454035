import React from "react";
import { Props } from "./Props";

const DriverIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45 55"
      fill="none"
    >
      <path
        d="M18.5 42.5L21.8265 44.5791C22.2591 44.8494 22.7786 44.9443 23.2788 44.8442L41.4117 41.2177C43.268 40.8464 45 42.2663 45 44.1594V54.5H18.5V42.5Z"
        fill={fill}
      />
      <path
        d="M21.9673 11.3492C18.8997 11.1536 16.0203 11.551 14.1836 12.0058V15.5181H13.1836V11.2372L13.552 11.1368C15.4536 10.6182 18.6302 10.1344 22.0309 10.3512C25.4281 10.5678 29.1069 11.4875 31.9511 13.8326L31.315 14.6042C28.6949 12.4439 25.2522 11.5586 21.9673 11.3492Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5964 0H35.241C36.0694 0 36.741 0.671573 36.741 1.5V12.5679C36.8808 13.1431 36.9599 14.0374 36.7844 14.9912C36.6137 15.9185 36.197 16.9262 35.3364 17.7408C35.5893 20.259 35.3093 23.9052 33.6428 26.9835C33.1219 27.9457 32.4672 28.8486 31.6566 29.6419V36.5337L41.7492 40.029C43.695 40.7028 45 42.5358 45 44.595V53.7851H44V44.595C44 42.962 42.9651 41.5083 41.4219 40.9739L31.2031 37.4349L22.7311 42.0851L14.7375 37.4342L3.6535 41.0169C2.07174 41.5282 1 43.0009 1 44.6633V53.7851H0V44.6633C0 42.5672 1.35142 40.7101 3.34594 40.0654L14.1367 36.5775V29.6148C13.2745 28.8358 12.5523 27.953 11.9531 27.0145C9.95924 23.8914 9.33778 20.1732 9.37083 17.6482C8.27722 15.3171 7.36523 11.6898 8.17487 8.24831C8.58835 6.49078 9.45144 4.78254 10.9641 3.33465C12.4751 1.88832 14.6112 0.723697 17.5384 0.014075L17.5964 0ZM23.0569 32.769C26.341 32.769 28.8179 31.8852 30.6566 30.5015V36.5941L22.7454 40.9365L15.1367 36.5096V30.4233C17.1153 31.85 19.7067 32.769 23.0569 32.769ZM17.7163 1C14.9556 1.67769 13.006 2.76447 11.6556 4.05705C10.2974 5.35705 9.52242 6.88708 9.1483 8.47732C8.39532 11.6779 9.26867 15.1247 10.3236 17.3234L10.3752 17.4311L10.3727 17.5505C10.3212 19.9341 10.9034 23.5119 12.796 26.4764C14.6701 29.4119 17.8392 31.769 23.0569 31.769C28.2699 31.769 31.1875 29.4183 32.7634 26.5074C34.3611 23.5562 34.5981 19.9859 34.3149 17.5986L34.2832 17.3313L34.4889 17.1576C35.2692 16.499 35.6482 15.6397 35.8009 14.8102C35.9548 13.9739 35.8724 13.1961 35.7573 12.7566L35.741 12.6944V1.5C35.741 1.22386 35.5172 1 35.241 1H17.7163Z"
        fill="black"
      />
      <path
        d="M8.10156 47.7202V53.7852H9.10156V47.7202H8.10156Z"
        fill="black"
      />
      <path
        d="M35.8984 53.7852V47.7202H36.8984V53.7852H35.8984Z"
        fill="black"
      />
    </svg>
  );
};

export default DriverIcon;
