import { Box, InputAdornment, SxProps, TextField, Theme, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { CustomerDetailsNotesStyles } from "./CustomerDetailsNotesStyles";
import Chat from "../../common/Chat/Chat";
import CustomButton from "../../common/CustomButton/CustomButton";
import { SAVE, ADD_NOTES } from "../../constants";
import saveIcon from "../../assets/svgs/save-icon.svg";

interface UserDetailsProps {
  personLogo: string;
  clientName: string;
  date: string;
  time: string;
  userMessage: boolean;
}

export interface PersonChatProps {
  message: string;
  userDetails: UserDetailsProps;
}

interface CustomerDetailsNotesProps {
  personChatArray: PersonChatProps[];
  additionalChatContainer?: SxProps<Theme>;
  additionalSeparatorClass?: SxProps<Theme>;
}

const CustomerDetailsNotes: React.FC<CustomerDetailsNotesProps> = ({
  personChatArray,
  additionalChatContainer,
  additionalSeparatorClass
}) => {
  const isMobile = useMediaQuery("(max-width:1023px)");
  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 300);
    }
  }, []);

  return (
    <Box sx={CustomerDetailsNotesStyles.container}>
      <Box sx={Object.assign({},CustomerDetailsNotesStyles.chatContainer,additionalChatContainer)}>
        {personChatArray.map((personChat, index) => (
          <Chat key={index} personChat={personChat} />
        ))}
      </Box>

      <Box>
        <TextField
          inputRef={textFieldRef}
          sx={CustomerDetailsNotesStyles.textField}
          placeholder={ADD_NOTES}
          multiline
          maxRows={3}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Box sx={CustomerDetailsNotesStyles.customButton}>
                  {!isMobile ? (
                    <CustomButton
                      additionalClassName={
                        CustomerDetailsNotesStyles.saveButton
                      }
                      text={SAVE}
                    />
                  ) : (
                    <img src={saveIcon} alt={SAVE} />
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              overflow: "auto",
            },
          }}
          autoFocus
        />
      </Box>
      <Box sx={Object.assign({},CustomerDetailsNotesStyles.separator,additionalSeparatorClass)}></Box>
    </Box>
  );
};

export default CustomerDetailsNotes;
