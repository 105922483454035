import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const insureAssistSlice = createSlice({
  name: "insureAssist",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default insureAssistSlice.reducer;
