import person24Logo from "../assets/svgs/profile-24-logo.svg";
export const personChat = [
  {
    message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo",
    userDetails: {
      personLogo: person24Logo,
      clientName: "Agent Name",
      date: "28th Jul",
      time: "04:30 PM",
      userMessage: true,
    },
  },
  {
    message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    userDetails: {
      personLogo: person24Logo,
      clientName: "Agent Name",
      date: "28th Jul",
      time: "04:30 PM",
      userMessage: true,
    },
  },
  {
    message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    userDetails: {
      personLogo: person24Logo,
      clientName: "Agent Name",
      date: "28th Jul",
      time: "04:30 PM",
      userMessage: false,
    },
  },
];
