export const CustomerListingCardStyles = {
  avatar: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  saperator: {
    height: "1px",
    width: "76px",
    backgroundColor: "var(--primary-grey)",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
  contactContainer: {
    display: "flex",
    gap: "21px",
    alignItems: "flex-end",
    "> img": {
      width: "20px",
      height: "20px",
    },
  },
  heading: {
    gap: "0px",
    width: "100%",
  },
  statuses: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "14px 0px",
    borderTop: "1px solid var(--primary-grey)",
    borderBottom: "1px solid var(--primary-grey)",
  },
  container: {
    padding: "16px",
    border: "1px solid var(--shaded-cyan)",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
  },
  headingText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  headingStatusText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  avatarheadingText: {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--support-white)",
    lineHeight: "22px",
  },
  status: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  subHeadingText: {
    fontSize: "var(--font-size-12)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  subHeadingPolicyText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--support-white)",
    lineHeight: "22px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "90%",
  },
  email: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "90%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingBottom: "14px",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  imageAndText: {
    gap: "6px",
  },
  imageAndTextDetails: {
    paddingTop: "16px",
    justifyContent: "flex-end",
  },
  viewDetails: {
    color: "var(--support-white)",
  },
};
