import React from "react";
import { Props } from "./Props";

const InteractionCardChatIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M23.6575 10.3724C23.6575 14.9285 19.964 18.622 15.4078 18.622C10.8517 18.622 7.1582 14.9285 7.1582 10.3724C7.1582 5.81623 10.8517 2.12275 15.4078 2.12275C19.964 2.12275 23.6575 5.81623 23.6575 10.3724Z"
        fill={fill}
      />
      <path
        d="M9.1582 10.9974H17.1578"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.1582 14.9971H17.1578"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.14999 23.3855C9.67054 24.8443 12.6356 25.3366 15.4923 24.7707C18.3491 24.2048 20.9025 22.6193 22.6766 20.3098C24.4507 18.0003 25.3244 15.1244 25.1347 12.2183C24.945 9.31227 23.7049 6.57437 21.6456 4.51509C19.5863 2.45582 16.8484 1.21572 13.9423 1.02602C11.0363 0.836315 8.16037 1.70995 5.85086 3.48404C3.54135 5.25813 1.95584 7.81158 1.38995 10.6683C0.824059 13.5251 1.3164 16.4901 2.77519 19.0107L1.21152 23.6792C1.15276 23.8554 1.14423 24.0445 1.18689 24.2252C1.22955 24.406 1.32171 24.5713 1.45304 24.7026C1.58437 24.834 1.74967 24.9261 1.93044 24.9688C2.1112 25.0114 2.30027 25.0029 2.47646 24.9442L7.14999 23.3855Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InteractionCardChatIcon;
