import React from "react";
import { Props } from "./Props";
import { useMediaQuery } from "@mui/material";

const RoadsideAssistanceIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;
  const tab = useMediaQuery("@media(min-width: 600px) and (max-width: 800px)");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={tab ? "40px" : width}
      height={tab ? "40px" : height}
      viewBox="0 0 37 42"
      fill="none"
    >
      <rect y="25.454" width="24.7793" height="11.5557" rx="2" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0572 1.83643C28.2705 3.02164 30.2289 5.18075 30.3063 8.89648C30.3821 12.534 29.1251 15.5723 26.4361 17.762C23.7678 19.9349 19.7436 21.2283 14.3595 21.5233C10.2228 21.75 7.90494 23.1526 6.65476 24.7343C5.39324 26.3304 5.16227 28.1791 5.33216 29.3863C5.3387 29.4328 5.34218 29.4797 5.34218 29.5284V41.024H4.34218V29.5284L4.34192 29.5257C4.14023 28.0925 4.41314 25.9578 5.87023 24.1142C7.33865 22.2564 9.95414 20.7632 14.3048 20.5248C19.5777 20.2359 23.3636 18.9744 25.8047 16.9866C28.2251 15.0155 29.3768 12.2871 29.3065 8.91731C29.238 5.6257 27.5396 3.76463 25.5851 2.71799C23.5987 1.65428 21.3562 1.44343 20.3363 1.51142C20.2749 1.51551 20.2108 1.51961 20.1441 1.52387C18.9973 1.59714 17.0916 1.7189 15.3948 2.69287C13.6495 3.69475 12.0548 5.64493 11.9122 9.64615C11.9133 12.1351 12.3761 13.7247 12.9288 14.7462C13.4785 15.7622 14.1339 16.2475 14.5802 16.5026L14.084 17.3708C13.5086 17.0419 12.7041 16.4323 12.0492 15.2221C11.398 14.0184 10.9122 12.2529 10.9122 9.63737V9.6286L10.9125 9.61984C11.0638 5.30575 12.8186 3.01862 14.897 1.8256C16.8038 0.731069 18.9228 0.598295 20.0622 0.526904C20.1356 0.522307 20.2049 0.517965 20.2698 0.513637C21.4396 0.435647 23.8758 0.668308 26.0572 1.83643ZM32.5233 24.4773C30.9134 23.409 28.9807 22.9157 27.7015 22.7939L27.7963 21.7984C29.1978 21.9318 31.2983 22.4643 33.0762 23.644C34.8691 24.8337 36.3608 26.7053 36.4141 29.482C36.5047 34.2047 36.4638 38.5431 36.4246 40.5477C36.4139 41.095 35.9662 41.524 35.4261 41.524H10.4996V40.524H35.4249C35.4638 38.528 35.5045 34.2055 35.4143 29.5012C35.3688 27.1341 34.1182 25.5356 32.5233 24.4773Z"
        fill="black"
      />
    </svg>
  );
};

export default RoadsideAssistanceIcon;
