export const commissionStatemnetsTableHeadingsData = [
  "Date",
  "Statement",
  "Commission Amount",
  "Status",
];
export const comissionStatementsData = [
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Pending",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Pending",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug ‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Pending",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Pending",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionStatemnet: "August Statement",
    commissionAmount: "$16,656",
    commissionStatus: "Paid",
  },
];
