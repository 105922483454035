const BillingTabStyles = {
  tableHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "19.5px",
    cursor: "pointer",
    justifyContent: "flex-end",
    "@media (min-width: 360px) and (max-width: 799px)": {
      marginTop: "-34px",
      marginBottom: "16px",
    },
  },
  tableHeadingFilterImage: {
    img: {
      width: "24px",
      height: "24px",
    },
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "5px 0px",
    justifyContent: "flex-end",
    img: {
      width: "24px",
      height: "24px"
    }
  },
  tableHeadingFilterText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    marginTop: "-5px",
    textTransform: "none",
  },
  popoverContent: {
    width: "340px",
    height: "172px",
    padding: "20px 20px 0px 20px",
    gap: "20px",
    borderRadius: "4px",
  },
  popoverStyles: {
    "& .MuiPopover-paper": {
      left: "unset !important",
      right: "0px !important",
    },
    "@media(max-width:699px)": {
      "& .MuiPopover-paper": {
        left: "unset !important",
        right: "7px !important",
      },
    },
  },
  textField: {
    width: "300px",
    height: "56px",
    marginBottom: "20px",
    fontFamily: "Noto Sans",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  tableContainer: {
    padding: "0px 24px",
    width: "auto",
    border: "1px solid rgba(201, 225, 236, 1)",
    marginTop: "10px",
    marginLeft: "0px",
    marginBottom: "10px",
    boxShadow: "none",
  },
  billingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "60px",
    marginTop: "10px"
  },
  payButton: {
    borderRadius: "20px",
    width: "151px",
    height: "35px",
    backgroundColor: "#FFFFFF",
    color: "#1D252B",
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "17.6px",
    textAlign: "left",
    border: "1px solid #B3C2C8",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#1D252B",
      border: "1px solid #B3C2C8",
      boxShadow: "none",
    },
  },
  payAndDownloadOptions: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "flex-end",
    borderBottom: "0px",
    borderTop: "0px",
    img: {
      cursor: "pointer",
    },
  },
  tableHeadText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "70%",
    borderBottom: "0px",
  },
  billingTableRows: {
    borderBottom: "1px solid #D0D1D9",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
  tableRow: {
    borderBottom: "1px solid #3F3F3F",
  },
  tableHeadCellContainer: {
    display: "flex",
    gap: "10px",
    cursor: "pointer",
    "&:hover": {
      img: {
        visibility: "visible",
      },
    },
    img: {
      visibility: "hidden",
    },
  },
  tableBodyCells: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    borderBottom: "0px",
    textAlign: "left",
  },

  billingTable: {
    display: "block",
    "@media(max-width:699px)": {
      display: "none",
    },
  },
  cardContainer: {
    display: "none",
    "@media(max-width: 699px)": {
      display: "block",
    },
  },
  container: {
    display: "flex",
    height: "auto",
    width: "auto",
    columnGap: "55px",
    rowGap: "23.94px",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    border: "1px solid rgba(201, 225, 236, 1)",
    borderRadius: "13px",
    padding: "30px",
  },
  amount: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  payActionButton: {
    width: "90px",
    height: "34px",
    padding: "10px",
    borderRadius: "40px",
    cursor: "pointer",
    alignItems: "center",
    textTransform: "none",
    background: "#3F3F3F",
    boxShadow: "0px 6px 10px 0px #3F3F3F4D",
  },
  downloadIcon: {
    marginLeft: "80px",
    alignItems: "center",
    cursor: "pointer",
    img: {
      marginTop: "5px",
    },
  },
  cardContent: {
    minWidth: "89px",
    display: "flex",
    flexDirection: "column",
    gap: "2.09px",
  },
  filterContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "-34px",
    marginBottom: "10px",
  },
  descriptionText: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "12px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "rgba(143, 147, 155, 1)",
  },
  paid: {
    paddingRight: "44px",
    textAlign: "right"
  },
  paidOn: {
    paddingRight: "25px",
    textAlign: "right",
  }
};
export default BillingTabStyles;
