import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { IAdminLoginRequestBody } from "../../../models/admin/adminAuth/adminAuth";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { adminLogin } from "../../../store/admin/adminAuth/adminAuthActions";
import { useNavigate } from "react-router-dom";
import { resetCommonSlice } from "../../../store/common/CommonSlice";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isSuccess, calledReducerType, isError, message } = useAppSelector(
    (state) => state.common
  );

  const [adminLoginRequestBody, setAdminLoginRequestBody] =
    useState<IAdminLoginRequestBody>({
      email: "SmartIms@gmail.com",
      password: "Smartims@123",
    });
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(resetCommonSlice());
      const { name, value } = event.target;
      setAdminLoginRequestBody((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prevState) => ({ ...prevState, [name]: "" }));
    },
    [dispatch]
  );

  const handleClickShowPassword = () =>
    setShowPassword((prevState) => !prevState);

  const adminLoginValidator = useCallback((): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const errors: { email?: string; password?: string } = {};
    if (
      !adminLoginRequestBody.email ||
      adminLoginRequestBody.email.trim().length === 0
    ) {
      errors.email = "Email address cannot be blank";
    } else if (!emailRegex.test(adminLoginRequestBody.email)) {
      errors.email = "Please enter a valid email address";
    }

    if (
      !adminLoginRequestBody.password ||
      adminLoginRequestBody.password.trim().length === 0
    ) {
      errors.password = "Password cannot be blank";
    } else if (!passwordRegex.test(adminLoginRequestBody.password)) {
      errors.password = "Please enter a valid Password";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }, [adminLoginRequestBody]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (adminLoginValidator()) {
        dispatch(adminLogin(adminLoginRequestBody));
      }
    },
    [adminLoginRequestBody, adminLoginValidator, dispatch]
  );

  useEffect(() => {
    if (isSuccess && calledReducerType === "adminAuth/adminLogin") {
      navigate("/admin/dashboard");
    }
  }, [isSuccess, calledReducerType, navigate]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ width: "30%" }}>
        <CardHeader
          title="Admin Login"
          sx={{
            textAlign: "center",
          }}
        />
        <form onSubmit={handleSubmit}>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {isError && message ? (
              <Alert severity="error">{message}</Alert>
            ) : (
              <></>
            )}
            <Box>
              <TextField
                fullWidth
                label="Email"
                name="email"
                placeholder="Please enter your email address"
                value={adminLoginRequestBody.email}
                onChange={handleInputChange}
              />
              {errors.email ? (
                <Typography sx={{ color: "red", marginTop: "8px" }}>
                  {errors.email}
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            <FormControl>
              <InputLabel>Password</InputLabel>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                fullWidth
                label="Password"
                name="password"
                placeholder="Please enter your password"
                value={adminLoginRequestBody.password}
                onChange={handleInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password ? (
                <Typography sx={{ color: "red", marginTop: "8px" }}>
                  {errors.password}
                </Typography>
              ) : (
                <></>
              )}
            </FormControl>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" type="submit">
              Login
            </Button>
          </CardActions>
        </form>
      </Card>
    </Box>
  );
};

export default Login;
