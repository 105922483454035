import { CheckBox } from "@mui/icons-material";
import { Box, Typography, TextField, Button, Container } from "@mui/material";
import React, { useState, ChangeEvent } from "react";

interface IRegisteration {
  custFirstName: string;
  custLastName: string;
  custEmail: string;
  custMobileNo: string;
  custFax: string;
  custDob: string;
  custCity: string;
  custState: string;
  custCountry: string;
  custCarier: string[];
  custPassword: string;
  cPassword: string;
}

const Registration = () => {
  const [data, SetData] = useState<IRegisteration | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {}

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center' }}>
    <Box>
      <Box sx={{ margin: '1rem'}}>
        <Typography sx={{ fontSize: '1.5rem', textAlign: 'center'}}>Register</Typography>
      </Box>
      <Box sx={{ margin: '1rem' }}>
        <TextField
          sx={{margin: '0.5rem'}}
          label="First Name*"
          type="text"
          name="custFirstName"
          value={data?.custFirstName}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Last Name*"
          type="text"
          name="custLastName"
          value={data?.custLastName}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Email*"
          type="text"
          name="custEmail"
          value={data?.custEmail}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
      </Box>
      <Box sx={{ margin: '1rem' }}>
      <TextField
          sx={{margin: '0.5rem'}}
          label="Password*"
          type="text"
          name="custPassword"
          value={data?.custPassword}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Confirm Password*"
          type="text"
          name="cPassword"
          value={data?.cPassword}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Mobile No*"
          type="text"
          name="custMobileNo"
          value={data?.custMobileNo}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
      </Box>
      <Box sx={{ margin: '1rem' }}>
      <TextField
          sx={{margin: '0.5rem'}}
          label="Fax"
          type="text"
          name="custFax"
          value={data?.custFax}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Date Of Birth*"
          type="text"
          name="custDob"
          value={data?.custDob}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
      </Box>
      <Box sx={{ margin: '1rem' }}>
      <TextField
          sx={{margin: '0.5rem'}}
          label="City*"
          type="text"
          name="custCity"
          value={data?.custCity}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
      <TextField
          sx={{margin: '0.5rem'}}
          label="State"
          type="text"
          name="custState"
          value={data?.custState}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <TextField
          sx={{margin: '0.5rem'}}
          label="Country*"
          type="text"
          name="custCountry"
          value={data?.custCountry}
          onChange={handleChange}
          autoComplete="off"
          // helperText="field can't be empty."
          variant="outlined"
          size="small"
        />
        <Box sx={{margin: '0.5rem'}} />
      </Box>
      <Box sx={{ margin: '1.5rem' }}>
        <Box><Typography>Fetch Policy From:</Typography></Box>
        <Box>
          <CheckBox name="insure-now" /> Insure Now
          <CheckBox name="guidewire" /> Guidewire
          <CheckBox name="duckery" /> Duckery
        </Box>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
        <Button>Register</Button>
        <Button>Reset</Button>
      </Box>
    </Box>
    </Container>
  )
}

export default Registration;
