import React from "react";
import Tasks from "../MyTasksResponsiveCard/MyTasksResponsiveCard";
import { Box } from "@mui/material";
import { TaskListStyles } from "./TaskListStyles";
import { MytasksDataProps } from "../CustomerDetailsList/CustomerDetailsList";
interface TaskListProps {
  mytasksData: MytasksDataProps[];
}
const TaskList: React.FC<TaskListProps> = ({ mytasksData }) => {
  return (
    <Box sx={TaskListStyles.container}>
      {mytasksData.map((task, index) => (
        <Tasks
          key={index}
          timeStamp={
            typeof task.timeStamp === "string"
              ? { date: "", time: "" }
              : task.timeStamp
          }
          task={task.task}
          clientName={task.clientDetails.clientName}
          policy={task.policy}
          priority={task.priority}
          status={task.status}
        />
      ))}
    </Box>
  );
};

export default TaskList;
