import { useCallback, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { ClaimsStyles } from "./ClaimsStyles";
import ClaimCard from "./ClaimCard/ClaimCard";
import rightArrowIcon from "../../../assets/viewAllArrow.svg";
import CustomDialog from "../../../common/CustomDialog/CustomDialog";
import { IClaimsData } from "../../../models/user/dashboard/Dashboard";

interface IProps {
  claimsData: IClaimsData;
}

const Claims: React.FC<IProps> = ({ claimsData }) => {
  const [viewAllClaimsDialogOpen, setViewAllClaimsDialogOpen] = useState(false);

  const getActiveClaims = useCallback(() => {
    if (claimsData && claimsData.claimList && claimsData.claimList.length > 0) {
      let claims = [...claimsData.claimList];
      let activeClaims = claims
        .filter(
          (claim) =>
            claim.carrierClaimNo && claim.carrierClaimStatus !== "Closed"
        )
        .sort(
          (a, b) =>
            new Date(b.initiatedDate).getTime() -
            new Date(a.initiatedDate).getTime()
        );
      return activeClaims;
    } else {
      return [];
    }
  }, [claimsData]);

  const handleViewPastClaimsClick = () => {
    setViewAllClaimsDialogOpen(true);
  };

  const handleAllClaimsDialogClose = () => {
    setViewAllClaimsDialogOpen(false);
  };

  return claimsData ? (
    <>
      <Box sx={ClaimsStyles.claimsContainer}>
        <Box sx={ClaimsStyles.claimCardsContainerHeader}>
          <Typography sx={ClaimsStyles.heading}>
            My Claims (
            {claimsData?.claimCount
              ? claimsData.claimCount < 10
                ? `0${claimsData?.claimCount}`
                : claimsData?.claimCount
              : "0"}
            )
          </Typography>
          {claimsData &&
          claimsData.claimList &&
          claimsData.claimList.length > 0 ? (
            <Box sx={ClaimsStyles.viewAll} onClick={handleViewPastClaimsClick}>
              <Typography sx={ClaimsStyles.viweAllText}>
                View all claims
              </Typography>
              <img src={rightArrowIcon} alt="right-arrow-icon" />
            </Box>
          ) : (
            <></>
          )}
        </Box>
        {getActiveClaims().length > 0 ? (
          <Box sx={ClaimsStyles.claims}>
            {getActiveClaims().map((claim) => (
              <ClaimCard
                claim={claim}
                key={`dash-claim-${claim.carrierClaimNo}`}
              />
            ))}
          </Box>
        ) : (
          <Card sx={ClaimsStyles.noClaimsCard}>
            <Typography sx={ClaimsStyles.noClaimsText}>
              No Active Claims Available
            </Typography>
          </Card>
        )}
      </Box>
      <CustomDialog
        showDivider={true}
        open={viewAllClaimsDialogOpen}
        maxWidth="xl"
        onClose={handleAllClaimsDialogClose}
        title="All Claims"
        content={
          <Box
            sx={{
              display: "grid",
              gap: "10px",
            }}
          >
            {claimsData &&
            claimsData.claimList &&
            claimsData.claimList.length > 0 ? (
              claimsData.claimList.map((claim) => (
                <ClaimCard
                  claim={claim}
                  key={`mob-dash-claim-${claim.carrierClaimNo}`}
                />
              ))
            ) : (
              <></>
            )}
          </Box>
        }
      />
    </>
  ) : (
    <></>
  );
};

export default Claims;
