import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getFeatures,
  postFeatures,
} from "../../../store/admin/features/featuresActions";
import { RootState } from "../../../store/store";
import { IFeature } from "../../../models/admin/features/features";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";

const Features = () => {
  const dispatch = useAppDispatch();

  const { features } = useAppSelector((state: RootState) => state.features);
  const { calledReducerType, isError, isSuccess } = useAppSelector(
    (state: RootState) => state.common
  );

  const [value, setValue] = useState<string>("");
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedFeatures, setSelectedFeatures] = useState<IFeature[]>([]);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const tabOptions = useMemo(
    () => features.filter((feature) => feature.parentCode === ""),
    [features]
  );

  const handleChange =
    (parentCode: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? parentCode : false);
    };

  const handleCheckBoxChange = useCallback(
    (childFeature: IFeature) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
          selectedFeatures.some(
            (selectedFeature) =>
              childFeature.featureCode === selectedFeature.featureCode
          )
        ) {
          // If the feature is already selected, remove it and its children
          const removeFeatureWithChildren = (
            feature: IFeature,
            featuresList: IFeature[]
          ) => {
            const children = selectedFeatures.filter(
              (f) => f.parentCode === feature.featureCode
            );
            let updatedList = featuresList.filter(
              (f) => f.featureCode !== feature.featureCode
            );

            // Recursively remove children
            children.forEach((child) => {
              updatedList = removeFeatureWithChildren(child, updatedList);
            });

            return updatedList;
          };

          setSelectedFeatures((prevState) =>
            removeFeatureWithChildren(childFeature, prevState)
          );
        } else {
          // If the feature is not selected, add it to the selected features
          setSelectedFeatures((prevState) => [
            ...prevState,
            { ...childFeature, selected: true },
          ]);
        }
      },
    [selectedFeatures]
  );

  const handleSave = useCallback(() => {
    if (selectedFeatures.length > 0) {
      dispatch(
        postFeatures(
          features.map((feature) => ({
            ...feature,
            selected: selectedFeatures.some(
              (selectedFeature) =>
                selectedFeature.featureCode === feature.featureCode
            ),
          }))
        )
      );
      setExpanded(false);
    } else {
      alert("Please select at least one feature");
    }
  }, [selectedFeatures, features, dispatch]);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    dispatch(getFeatures());
  }, [dispatch]);

  useEffect(() => {
    const selectedFeatures = features.filter((feature) => feature.selected);
    setSelectedFeatures(selectedFeatures);
  }, [features]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "features/postFeatures"
    ) {
      setMessageDialogOpen(true);
    }
  }, [isError, isSuccess, calledReducerType]);

  useEffect(() => {
    if (tabOptions.length > 0) {
      setValue(tabOptions[0].featureCode);
    }
  }, [tabOptions]);

  const featuresList = ({
    childFeatures,
  }: {
    childFeatures: IFeature[];
  }): IFeature[] => {
    let result: IFeature[] = [];

    childFeatures.forEach((childFeature) => {
      let grandChildFeatures = features.filter(
        (feature) => feature.parentCode === childFeature.featureCode
      );
      if (grandChildFeatures.length > 0) {
        const grandChildResults = featuresList({
          childFeatures: grandChildFeatures,
        });
        result = [...result, childFeature, ...grandChildResults];
      }
    });
    return result;
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Typography variant="h4">Features</Typography>
        {value ? (
          <Tabs
            sx={{
              borderBottom: "1px solid #94ABB6",
            }}
            onChange={(e, value) => setValue(value)}
            value={value}
            allowScrollButtonsMobile
          >
            {tabOptions.map((tab) => (
              <Tab
                key={tab.featureCode}
                label={tab.featureLabel}
                value={tab.featureCode}
              />
            ))}
          </Tabs>
        ) : null}
        <Card>
          <CardContent
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(${
                features.filter((feature) => feature.parentCode === value)
                  .length
              }, 1fr)`,
            }}
          >
            {features
              .filter((feature) => feature.parentCode === value)
              .map((feature) => (
                <FormControlLabel
                  key={feature.featureCode}
                  control={
                    <Checkbox
                      checked={selectedFeatures.some(
                        (selectedFeature) =>
                          selectedFeature.featureCode === feature.featureCode
                      )}
                      onChange={handleCheckBoxChange(feature)}
                    />
                  }
                  label={feature.featureLabel}
                />
              ))}
          </CardContent>
        </Card>
        {value ? (
          featuresList({
            childFeatures: features.filter(
              (feature) => feature.parentCode === value
            ),
          }).map((parentFeature) => {
            const childFeatures = features.filter(
              (feature) => feature.parentCode === parentFeature.featureCode
            );
            return (
              <Accordion
                key={parentFeature.featureCode}
                expanded={
                  expanded === parentFeature.featureCode &&
                  selectedFeatures.some(
                    (selectedFeature) =>
                      selectedFeature.featureCode === parentFeature.featureCode
                  )
                }
                onChange={handleChange(parentFeature.featureCode)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Feature Name
                  </Typography>
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {parentFeature.featureLabel}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {childFeatures.map((childFeature) => (
                      <Grid item xs={3} key={childFeature.featureCode}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedFeatures.some(
                                (feature) =>
                                  feature.featureCode ===
                                  childFeature.featureCode
                              )}
                              onChange={handleCheckBoxChange(childFeature)}
                            />
                          }
                          label={childFeature.featureLabel}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <></>
        )}
        <Button
          onClick={handleSave}
          variant="contained"
          sx={{
            width: "max-content",
          }}
        >
          Save
        </Button>
      </Box>
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
    </>
  );
};

export default Features;
