import tasksIcon from '../assets/svgs/view-tasks-icon.svg';
import policyIcon from '../assets/svgs/policy-icon.svg';
import customerIcon from '../assets/svgs/customer-icon.svg';
import dashboardIcon from '../assets/svgs/dashboard-icon.svg';
import commissionIcon from '../assets/svgs/commission-icon.svg';
import paymentIcon from '../assets/svgs/payment-icon.svg';
import documentIcon from '../assets/svgs/document-folder-icon.svg';
import settingsIcon from '../assets/svgs/setting-icon.svg';

export const menuItems = [
     {
      image: tasksIcon,
      action: "myTasks",
      text: "My Tasks",
      key : "myTasks",
    },
     {
      image: dashboardIcon,
      action: "viewDashboard",
      text: "Dashboard",
      key : "dashboard",
    },
     {
      image: policyIcon,
      action: "viewPolicy",
      text: "Policy Listing",
      key : "policyListing",
    },
     {
      image: customerIcon,
      action: "viewCustomer",
      text: "Customer Listing",
      key : "customerListing",
    },
     {
      image: commissionIcon,
      action: "viewCommission",
      text: "Commission Statements",
      key : "commissionStatements",
    },
 {
      image: paymentIcon,
      action: "viewPayments",
      text: "Payment Schedule",
      key : "paymentSchedule",
    },
     {
      image: documentIcon,
      action: "viewDocuments",
      text: "Document Management",
      key : "documentManagement",
    },
 {
      image: settingsIcon,
      action: "viewSettings",
      text: "Settings",
      key : "settings",
    },
];