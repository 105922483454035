import React from "react";
import { Props } from "./Props";

const DashboardCardBulbIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 30"
      fill="none"
    >
      <ellipse
        cx="13.7058"
        cy="14.7267"
        rx="9.03781"
        ry="8.96985"
        fill={fill}
      />
      <path
        d="M6.4375 29H17.3101"
        stroke="#3F3F3F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.873 22.2052V16.9048"
        stroke="#3F3F3F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.52344 12.555L11.8725 16.904L16.2215 12.555"
        stroke="#3F3F3F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.649 21.5747C17.4274 22.0244 17.3111 22.5187 17.309 23.0201V23.8355C17.309 24.1239 17.1944 24.4004 16.9905 24.6043C16.7866 24.8082 16.5101 24.9228 16.2217 24.9228H7.5237C7.23534 24.9228 6.95879 24.8082 6.75489 24.6043C6.55099 24.4004 6.43644 24.1239 6.43644 23.8355V23.0201C6.43592 22.5219 6.32182 22.0304 6.1028 21.583C5.88378 21.1355 5.56562 20.7439 5.17251 20.4379C3.88 19.4282 2.8331 18.1387 2.11045 16.6664C1.38781 15.194 1.0082 13.577 1.00017 11.9369C0.967549 6.04397 5.71885 1.1391 11.6104 1.00319C13.8937 0.947885 16.1364 1.613 18.0203 2.90417C19.9042 4.19534 21.3337 6.04699 22.1059 8.1964C22.8781 10.3458 22.9539 12.6838 22.3224 14.8787C21.8981 16.3536 21.1689 17.7135 20.1911 18.8749"
        stroke="#3F3F3F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DashboardCardBulbIcon;
