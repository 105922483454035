export const ImageAndTextStyles = {
  container: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    cursor: "pointer",
  },
  primaryClassName: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
  },
  text: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "14px",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  image: {
    display: "flex",
    alignItems: "center",
    "> img": {
      width: "100%",
    },
  },
  hoverElement: {
    marginLeft:"10px"
  },
};
