import { useCallback, useEffect, useMemo, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import PolicyDetails from "./PolicyDetails/PolicyDetails";
import PaymentSuccess from "./Payment/PaymentSuccess";
import PaymentCancel from "./Payment/PaymentCancel";
import Tasks from "./Tasks/Tasks";
import Profile from "./Profile/Profile";
import { Box, Fab, useTheme } from "@mui/material";
import InsureAssist from "./Dashboard/InsureAssist/InsureAssist";
import ChatIcon from "@mui/icons-material/Chat";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { getCookie, isFeatureAvailable } from "../utils/Utility";
import { useAppSelector } from "../store/hooks";
import { RootState } from "../store/store";
import Error from "../common/Error/Error";

const CustomerRoutes = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const authenticated = getCookie("authenticated");
  const roleCode = getCookie("roleCode");

  const { features } = useAppSelector((state: RootState) => state.features);

  const showInsureAssist: boolean = useMemo(
    () =>
      features.some(
        (feature) => feature.featureCode === "insure_assist" && feature.selected
      ),
    [features]
  );

  const [insureAssistOpen, setInsureAssistOpen] = useState<boolean>(false);

  const toggleChatDrawer = useCallback((): void => {
    setInsureAssistOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (!authenticated || roleCode !== "2") {
      window.location.href = "/";
    }
  }, [authenticated, roleCode, navigate]);

  return authenticated && roleCode === "2" ? (
    <Box
      sx={{
        background: "linear-gradient(180deg, #fbfdfc 0%, #e5f6fe 100%)",
        position: "relative",
      }}
    >
      <Header />
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/policyDetails/:policyId" element={<PolicyDetails />} />
        <Route
          path="/paymentSuccess/:responseData"
          element={<PaymentSuccess />}
        />
        <Route
          path="/tasks"
          element={
            isFeatureAvailable({ featureCodes: ["lob_pa_tasks"], features }) ? (
              <Tasks />
            ) : (
              <Error />
            )
          }
        />
        <Route path="/paymentError/:errorCode" element={<PaymentCancel />} />
        <Route
          path="/profile"
          element={
            isFeatureAvailable({ featureCodes: ["profile"], features }) ? (
              <Profile />
            ) : (
              <Error />
            )
          }
        />
        <Route path="*" element={<Dashboard />} />
      </Routes>
      <Footer />
      {showInsureAssist ? (
        <>
          <Fab
            sx={{
              position: "fixed",
              bottom: 70,
              right: 16,
              fontFamily: "Noto Sans",
              backgroundColor: theme.palette.secondary.main,
              ":hover": { backgroundColor: theme.palette.secondary.light },
            }}
            onClick={toggleChatDrawer}
          >
            <ChatIcon
              sx={{
                color: "white",
                fontSize: "30px",
              }}
            />
          </Fab>
          {insureAssistOpen ? (
            <InsureAssist
              open={insureAssistOpen}
              setOpen={setInsureAssistOpen}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  ) : (
    <></>
  );
};

export default CustomerRoutes;
