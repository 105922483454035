export const SendRemainderResponsiveStyles = {
  container: {
    marginTop: "60px",
    padding: "24px 20px",
  },
  sendRemainderText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  dropDown: {
    borderBottom: "1px solid var(--primary-black)",
    marginLeft: "0px",
  },
  sendRemainder: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    paddingTop: "10px",
    paddingBottom: "25px",
  },
  textArea: {
    background: "var(--white)",
    border: "1px solid var(--shaded-cyan)",
  },
  formArea: {
    minHeight: "calc(100vh - 470px)",
  },
  button: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    paddingTop: "23px",
  },
  resetButtons: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
  },
  breadCrumb: {
    paddingBottom: "14px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  breadCrumbText: {
    fontSize: "var(--font-size-14)",
  },
};
