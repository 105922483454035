import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withScrolling from "react-dnd-scrolling";
import { ALL_POLICIES } from "../../constants";
import { Box, SxProps } from "@mui/material";
import DashboardDraggableCard from "../DashboardDraggableCard/DashboardDraggableCard";
import { DashboardContainerStyles } from "./DashboardContainerStyles";
import { Theme } from "@emotion/react";
const ScrollingComponent = withScrolling("div");

interface DashboardJsonProps {
  id: string;
  heading: string;
  component: React.ReactNode;
  viewTask: boolean;
  dropDownData: { name: string; value: number | string }[];
  showFooter?: boolean;
}

interface DragContainerProps {
  data: DashboardJsonProps[];
  additionalClassContainer?: SxProps<Theme>;
}

const DashboardContainer: React.FC<DragContainerProps> = ({
  data,
  additionalClassContainer,
}) => {
  const [cards, setCards] = useState(data);
  useEffect(() => {
    setCards(data);
  }, [data]);
  const moveCard = (fromIndex: number, toIndex: number) => {
    const updatedCards = [...cards];
    const [movedCard] = updatedCards.splice(fromIndex, 1);
    updatedCards.splice(toIndex, 0, movedCard);
    setCards(updatedCards);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        component={ScrollingComponent}
        sx={Object.assign(
          {},
          DashboardContainerStyles.container,
          additionalClassContainer
        )}
      >
        {cards.map((card, index) => (
          <DashboardDraggableCard
            id={card.id}
            index={index}
            heading={card.heading}
            dropDownData={card.dropDownData}
            viewTask={card.viewTask}
            showFooter={card.showFooter}
            moveCard={moveCard}
            placeholder={ALL_POLICIES}
          >
            {card.component}
          </DashboardDraggableCard>
        ))}
      </Box>
    </DndProvider>
  );
};

export default DashboardContainer;
