export const claimTrackingHeaderData = [
  {
    heading: "18 Feb’24",
    subHeading: "Updated On",
  },
  {
    heading: "18 Feb’24",
    subHeading: "Claim Initiated",
  },
  {
    heading: "Verification",
    subHeading: "Stage",
  },
  {
    heading: "12AW1145G057",
    subHeading: "Policy Number",
  },
  {
    heading: "Rear-end collision on Highway 101  ",
    subHeading: "Description",
  },
];
