import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import LoginStyles from "./LoginStyles";
import { resetCommonSlice } from "../store/common/CommonSlice";
import LoginWithNameAndMobile from "./LoginWithNameAndMobile/LoginWithNameAndMobile";
import LoginWithEmailOrMobile from "./LoginWithEmailOrMobile/LoginWithEmailOrMobile";
import { RootState } from "../store/store";
import { IFeature } from "../models/admin/features/features";

interface IProps {
  logoSrc: string | File;
  loginBannerSrc: string | File;
  isFunctionable: boolean;
}

const Login: React.FC<IProps> = ({
  logoSrc,
  loginBannerSrc,
  isFunctionable,
}) => {
  const dispatch = useAppDispatch();

  const { uiSettings } = useAppSelector((state: RootState) => state.uiSettings);
  const { features } = useAppSelector((state: RootState) => state.features);

  const [loginType, setLoginType] = useState<string>("");

  const handleLoginWithChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginType((event.target as HTMLInputElement).value);
      dispatch(resetCommonSlice());
    },
    [dispatch]
  );

  const loginOptions: IFeature[] = useMemo(
    () =>
      features
        .filter(
          (feature) =>
            (feature.featureCode === "name_mobile_login" && feature.selected) ||
            (feature.featureCode === "email_mobile_login" &&
              feature.selected) ||
            (feature.featureCode === "google_login" && feature.selected)
        )
        .sort((a, b) => a.featureLabel.localeCompare(b.featureCode)),
    [features]
  );

  const getLoginForm = useCallback(() => {
    switch (loginType) {
      case "name_mobile_login":
        return <LoginWithNameAndMobile isFunctionable={isFunctionable} />;
      case "email_mobile_login":
        return <LoginWithEmailOrMobile isFunctionable={isFunctionable} />;
    }
  }, [loginType, isFunctionable]);

  useEffect(() => {
    setLoginType(loginOptions.length > 0 ? loginOptions[0].featureCode : "");
  }, [loginOptions]);

  return (
    <Box sx={LoginStyles.login}>
      <Box sx={LoginStyles.loginImage}>
        {uiSettings && uiSettings?.loginBanner && (
          <img
            // src={`data:image/png;base64,${uiSettings.loginBanner}`}
            src={`data:image/png;base64,${loginBannerSrc}`}
            alt="Logo Preview"
          />
        )}
      </Box>
      <Box style={LoginStyles.loginCardContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "80%",
          }}
        >
          <Box sx={LoginStyles.logo}>
            {uiSettings && uiSettings?.logo && (
              <img
                src={`data:image/png;base64,${logoSrc}`}
                alt="Logo Preview"
              />
            )}
          </Box>
          <Typography sx={LoginStyles.loginMessage}>
            Welcome, please login to continue
          </Typography>
          <FormControl>
            {loginOptions.length > 0 ? (
              <FormLabel
                sx={{
                  fontFamily: "Playfair Display",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "rgba(56, 61, 78, 1)",
                }}
              >
                Login With
              </FormLabel>
            ) : (
              <></>
            )}
            <RadioGroup
              defaultValue="emailOrMobile"
              value={loginType}
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={handleLoginWithChange}
            >
              {loginOptions.map((option) => (
                <FormControlLabel
                  key={option.featureCode}
                  value={option.featureCode}
                  control={<Radio />}
                  label={option.featureLabel}
                  disabled={!isFunctionable}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {getLoginForm()}
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
