const ClaimCardStyles = {
  claimsCard: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  },
  containerOne: {
    display: "flex",
    alignItems: "center",
  },
  containerTwo: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    width: "100%",
    "@media (max-width:1200px)": {
      display: "grid",
      gridTemplateAreas: `"policyName claimNumber verification"
        "updatedOn claimInitiated contactPerson"
        "button button button"`,
      gridTemplateColumns: "1fr 1fr 1fr",
      gap: "20px",
    },
    "@media (max-width:600px)": {
      gridTemplateAreas: `"policyName claimNumber"
        "verification updatedOn"
        "claimInitiated contactPerson"
        "button button"`,
      gridTemplateColumns: "1fr 1fr",
    },
  },
  claimPolicyName: {
    "@media (max-width:1200px)": {
      gridArea: "policyName",
    },
  },

  claimsCardItemHeading: {
    overflow: "hidden",
    color: "#1D252B",
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "400",
    textOverflow: "ellipsis",
    "@media (max-width:599px)": {
      fontSize: "16px",
    },
    "@media (min-width:600px) and (max-width:1024px)": {
      whiteSpace: "nowrap",
    },
  },
  claimsCartItemContent: {
    color: "#1D252B",
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "300",
    marginTop: "0.5rem",
    "@media(max-width:599px)": {
      fontSize: "14px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: '"Noto Sans"',
    },
    "@media (min-width: 1025px) and (max-width: 1366px)": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  claimsCardButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    "@media (max-width:1200px)": {
      gridArea: "button",
    },
    "@media (max-width:600px)": {
      justifyContent: "center",
    },
  },
  claimsCardButton: {
    textTransform: "none",
    width: "fit-content",
    minWidth: "155px",
    padding: "12px 12px",
    flexShrink: 0,
    borderRadius: "40px",
    backgroundColor: "#3F3F3F",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
    color: "var(--Default-White, #FFF)",
    fontWeight: 400,
    fontFamily: "Lato",
    letterSpacing: "0.5px",
    fontSize: "14px",
  },
  contactPopoverContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
  },
};
export default ClaimCardStyles;
