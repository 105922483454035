const DropDownStyles = {
    container: {
        width: "220px",
        borderRadius: "10px"
    },
    text: {
        color: "#1D252B",
        fontFamily: "Noto Sans",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 300,
        lineHeight: "110%",
        padding: "0px",
    }
}

export default DropDownStyles
