import cakeIcon from "../assets/svgs/cake-icon.svg";
export const birthdayCards = [
  {
    image: "birthday",
    text: "John Mathew is celebrating his birthday , wish him .",
  },
  {
    image: "birthday",
    text: "John Mathew is celebrating his birthday , wish him .",
  },
  {
    image: "birthday",
    text: "John Mathew is celebrating his birthday , wish him .",
  },
  {
    image: "birthday",
    text: "John Mathew is celebrating his birthday , wish him .",
  },
  {
    image: "birthday",
    text: "John Mathew is celebrating his birthday , wish him .",
  },
];
