import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import CustomTable, { IColumn } from "../../../common/CustomTable/CustomTable";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import {
  getLookups,
  addLookUp,
  deleteLookup,
} from "../../../store/admin/lookup/lookupAction";
import { ILookup } from "../../../models/admin/lookup/Lookup";
import LookupStyle from "./LookupStyle";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { lookupFormFields } from "../../../constants/Constants";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";

const Lookup = () => {
  const dispatch = useAppDispatch();

  const { lookupList } = useAppSelector((store: RootState) => store.lookup);
  const { isError, isSuccess, calledReducerType } = useAppSelector(
    (store: RootState) => store.common
  );

  const defaultLookupFormData: ILookup = useMemo(
    () => ({
      name: "",
      code: "",
      label: "",
    }),
    []
  );

  const defaultErrors = useMemo(
    () => ({
      name: "",
      code: "",
      label: "",
    }),
    []
  );

  const [lookupFormData, setLookupFormData] = useState<ILookup>(
    defaultLookupFormData
  );
  const [errors, setErrors] = useState<{
    code: string;
    name: string;
    label: string;
  }>(defaultErrors);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value && value.trim().length > 0) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
    setLookupFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateFields = () => {
    const errors = { ...defaultErrors };
    for (let key in defaultErrors) {
      if (!lookupFormData[key as keyof typeof defaultErrors].trim()) {
        errors[key as keyof typeof defaultErrors] = "Invalid input";
      }
    }
    const isNotValid = Object.keys(errors).some((item) => {
      return errors[item as keyof typeof errors].length > 0;
    });

    if (!isNotValid) {
      setErrors({ ...defaultErrors });
      return true;
    }
    setErrors({ ...errors });
    return false;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      dispatch(addLookUp(lookupFormData));
    }
  };

  const handleReset = useCallback(() => {
    setLookupFormData({ ...defaultLookupFormData });
    setErrors({ ...defaultErrors });
  }, [defaultLookupFormData, defaultErrors]);

  const updateFormFields = (lookup: ILookup) => {
    setLookupFormData({ ...lookup });
  };

  const handleDeleteIconClick = (lookup: ILookup) => {
    setLookupFormData(lookup);
    setAlertDialogOpen(true);
  };

  const handleAlertDialogCancelClick = useCallback(() => {
    setAlertDialogOpen(false);
    setLookupFormData({ ...defaultLookupFormData });
  }, [defaultLookupFormData]);

  const handleAlertDialogOkClick = useCallback(() => {
    setAlertDialogOpen(false);
    dispatch(deleteLookup(lookupFormData));
  }, [dispatch, lookupFormData]);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    dispatch(getLookups());
  }, [dispatch]);

  const getFormFields = () => {
    return lookupFormFields.map((formField, index) => {
      return (
        <FormControl
          key={`lookup-form-${index}`}
          sx={{ width: `${100 / lookupFormFields.length}%` }}
        >
          <TextField
            type="text"
            label={formField.label}
            variant="outlined"
            name={formField.name}
            value={
              lookupFormData[formField.name as keyof typeof lookupFormData]
            }
            placeholder={`Please enter the lookup ${formField.label}`}
            error={
              errors[formField.name as keyof typeof lookupFormData]
                ? true
                : false
            }
            onChange={handleChange}
          />
          <Typography sx={{ color: "red" }}>
            {errors[formField.name as keyof typeof lookupFormData]}
          </Typography>
        </FormControl>
      );
    });
  };

  const getFormSection = () => {
    return (
      <>
        <Box sx={{ display: "inline-flex", width: "100%", gap: "1rem" }}>
          {getFormFields()}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Button name="submit" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
          <Button name="submit" variant="contained" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </>
    );
  };

  const getListSection = () => {
    const columns: IColumn[] = [
      {
        label: "Label",
        accessorKey: "label",
        Cell: (lookup: any) => (
          <Link
            onClick={() => updateFormFields(lookup)}
            sx={{ cursor: "pointer" }}
          >
            {lookup.label}
          </Link>
        ),
      },
      {
        label: "Name",
        accessorKey: "name",
      },
      {
        label: "Code",
        accessorKey: "code",
      },

      {
        label: "Actions",
        accessorKey: "",
        Cell: (lookup: any) => (
          <IconButton
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handleDeleteIconClick(lookup)}
          >
            <DeleteOutlineOutlined
              sx={{ cursor: "pointer" }}
              titleAccess="Delete this record"
            />
          </IconButton>
        ),
      },
    ];

    return (
      <CustomTable
        columns={columns}
        data={[...lookupList].reverse()}
        stickyHeader={true}
        styles={{ maxHeight: "45vh" }}
        pagination={true}
      />
    );
  };

  useEffect(() => {
    dispatch(getLookups());
  }, [dispatch]);

  useEffect(() => {
    if (
      ((isSuccess || isError) && calledReducerType === "lookup/addLookUp") ||
      ((isSuccess || isError) && calledReducerType === "lookup/deleteLookup")
    ) {
      setMessageDialogOpen(true);
      handleReset();
    }
  }, [isSuccess, isError, calledReducerType, handleReset]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Card sx={LookupStyle.lookupCard}>
        <CardHeader title={"Add/Update Lookup record"} />
        <CardContent sx={{ display: "grid", gap: "20px" }}>
          {getFormSection()}
        </CardContent>
      </Card>
      <Box>{getListSection()}</Box>
      <AlertDialog
        open={alertDialogOpen}
        handleCancelClick={handleAlertDialogCancelClick}
        handleOkClick={handleAlertDialogOkClick}
        title="Are you sure to delete this lookup?"
      />
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
    </Box>
  );
};

export default Lookup;
