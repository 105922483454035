const PolicyDetailsHeaderStyles = {
  BasicInfoCard: {
    background: "linear-gradient(108deg, #FFF 80.85%, #F8F9FF 145.84%)",
    display: "grid",
    gridTemplateColumns: "2fr 0.75fr",
    justifyContent: "space-between",
    border: "1px solid #C9E1EC",
    borderRadius: "10px",
    "@media (max-width: 1200px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
};

export default PolicyDetailsHeaderStyles;
