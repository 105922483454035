const CustomTableStyles = {
  tableContainer: {
    width: "100%",
    padding: "0px 30px",
    border: "1px solid rgba(201, 225, 236, 1)",
    "@media (max-width:599px)": {
      width: "100%",
    },
  },
  tableHeadCell: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  row: {
    width: "100%",
    height: "64px",
    fontFamily: "Noto Sans",
    whiteSpace: "wrap",
  },
  rowCell: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    textAlign: "left",
    padding: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: 400,
    opacity: "100%",
    letterSpacing: "0px",
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default CustomTableStyles;
