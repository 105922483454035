import { Box, Typography } from "@mui/material";
import React from "react";
import { POLICY_LISTING, TASKS } from "../../constants";
import { OverViewCardsStyles } from "./OverViewCardsStyles";
import TaskList from "../TaskList/TaskList";
import { customerDetailsOverviewPolicyListing } from "../../constants/customerDetailsOverviewPolicyListing";
import OverviewPolicyListingCard from "../../common/OverviewPolicyListingCard/OverviewPolicyListingCard";
import {
  MytasksDataProps,
  OverviewTableDataProps,
} from "../CustomerDetailsList/CustomerDetailsList";

interface OverViewCardsProps {
  overviewData: OverviewTableDataProps[];
  mytasksData: MytasksDataProps[];
}
const OverViewCards: React.FC<OverViewCardsProps> = ({
  overviewData,
  mytasksData,
}) => {
  return (
    <Box sx={OverViewCardsStyles.container}>
      <Box sx={OverViewCardsStyles.policyContainer}>
        <Typography sx={OverViewCardsStyles.heading}>
          {POLICY_LISTING}
        </Typography>
        <Box sx={OverViewCardsStyles.policyCards}>
          {overviewData.map((policy, index) => {
            const {
              policyType,
              policyDetails,
              policyStatus,
              policyPremium,
              policyTerm,
              policyIdNo,
              policyLastPayment,
            } = policy;
            if (typeof policyDetails !== "object" || !policyDetails) {
              return null;
            }

            return (
              <OverviewPolicyListingCard
                key={index}
                policyType={policyType}
                policyNumber={policyDetails.policyNumber}
                policyTypeNumber={policyDetails.policyTypeNumber}
                policyStatus={policyStatus}
                policyPremium={policyPremium}
                policyTerm={policyTerm}
                policyIdNo={policyIdNo}
                policyLastPayment={policyLastPayment}
              />
            );
          })}
        </Box>
      </Box>
      <Box sx={OverViewCardsStyles.claimsContainer}>
        <Typography sx={OverViewCardsStyles.heading}>{TASKS}</Typography>
        <TaskList mytasksData={mytasksData} />
      </Box>
    </Box>
  );
};

export default OverViewCards;
