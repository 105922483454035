export const CommissionStatementsStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
    padding: "25px 30px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
    "@media (max-width: 1023px)": {
      gap: "30px",
      padding: "24px 20px",
      marginTop: "60px",
    },
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  filterContainer: {
    display: "flex",
    gap: "20px",
    width: "213px",
    justifyContent: "flex-end",
    "@media (max-width: 1023px)": {
      width: "auto",
      gap: "12px",
      justifyContent: "flex-start",
    },
  },
  headingsContainer: {
    display: "flex",
    gap: "65px",
    border: "1px solid var(--shaded-cyan)",
    padding: "20px",
    borderRadius: "8px",
    height: "107px",
    backgroundColor: "var(--white)",
    "@media (max-width: 1023px)": {
      gap: "20px",
      height: "auto",
      padding: "20px 16px",
    },
  },

  header: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    "@media (max-width: 1023px)": {
      gap: "30px",
    },
  },
  line: {
    color: "var(--primary-grey)",
    width: "1px",
    fontSize: "var(--font-size-20)",
  },
  headingSubheadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  imageAndTextContainer: {
    gap: "8px",
  },
  image: {
    ">img": {
      width: "20px",
      height: "20px",
    },
  },
  headingText: {
    fontSize: "var(--font-size-20)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  headingContainerText: {
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      fontWeight: "var(--font-weight-400)",
      lineHeight: "20px",
    },
  },
  subheadingContainerText: {
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      fontWeight: "var(--font-weight-300)",
      lineHeight: "16.34px",
    },
  },
};
