import React from "react";
import { SxProps, Typography } from "@mui/material";
import { HeadingTextStyles } from "./HeadingTextStyles";
import { Theme } from "@emotion/react";
interface HeadingTextProps {
  heading: string;
  additionalHeadingClass?: SxProps<Theme>;
}

const HeadingText: React.FC<HeadingTextProps> = ({
  heading,
  additionalHeadingClass,
}) => {
  return (
    <Typography
      sx={Object.assign(
        {},
        HeadingTextStyles.dashboardCardHeading,
        additionalHeadingClass
      )}
    >
      {heading}
    </Typography>
  );
};

export default HeadingText;
