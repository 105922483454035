import React from "react";
import { Props } from "./Props";

const DocumentIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 35 42"
      fill="none"
    >
      <rect x="4.95117" y="24.75" width="25.4167" height="13.75" fill={fill} />
      <path
        d="M32.6667 41H2.66667C2.22464 41 1.80072 40.8244 1.48816 40.5118C1.1756 40.1993 1 39.7754 1 39.3333V2.66667C1 2.22464 1.1756 1.80072 1.48816 1.48816C1.80072 1.1756 2.22464 1 2.66667 1H22.6667L34.3333 12.6667V39.3333C34.3333 39.7754 34.1578 40.1993 33.8452 40.5118C33.5326 40.8244 33.1087 41 32.6667 41Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.6738 1V12.6667H34.3405"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 22.6506H24.3333"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 29.3494H24.3333"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DocumentIcon;
