const AutoPaymentStyles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 0px 0px 20px",
        width: "500px",
        "@media(max-width: 780px)": {
            width: "auto",
        },
    },
    dialogTitle: {
        padding: "0px",
        fontFamily: "Playfair Display",
        fontSize: "20px",
        lineHeight: "110%",
        "@media(max-width: 780px)": {
            fontSize: "16px",
        },
    },
    boxOne: {
        padding: "0px 20px 0px 20px",
    },
    formBox: {
        width: "301px",
        "@media(max-width: 780px)": {
            width: "250px",
        },
    },
    textOne: {
        fontFamily: "Noto Sans",
        fontSize: "16px",
        lineHeight: "110%",
    },
    divider: {
        paddingTop: "15px"
    },
    dialogBox: {
        padding: "0px",
        textAlign: "left"
    },
    boxTwo: {
        display: "flex",
        flexDirection: "column",
        width: "fit-content",
        padding: "17px 20px 30px 20px ",
    },
    text: {
        fontFamily: "Noto Sans",
        fontSize: "16px",
        lineHeight: "110%",
    },
    form: {
        mt: 4,
        textAlign: "left"
    },
    boxThree: {
        paddingTop: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer"
    },
    dialogActions: {
        padding: "0px 20px 20px 20px",
    },
    button: {
        textTransform: "none",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        lineHeight: "110%",
        borderRadius: "40px",
    },
    cancelButton: {
        borderRadius: "40px",
        border: "1px solid #3F3F3F",
        padding: "16px 12px",
        color: "#3F3F3F",
        width: "150px",
        height: "40px",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "14px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textTransform: "none",
    },
    saveButton: {
        borderRadius: "40px",
        padding: "16px 12px",
        width: "150px",
        height: "40px",
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "14px",
        letterSpacing: "0.5px",
        textAlign: "left",
        textTransform: "none",
        backgroundColor: "#3F3F3F",
    },
}
export default AutoPaymentStyles