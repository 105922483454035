import React from "react";
import { Props } from "./Props";

const ActiveClaimsIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 22"
      fill="none"
    >
      <path
        d="M11.9199 4.90186H5.91992C5.36764 4.90186 4.91992 5.34957 4.91992 5.90186V7.90186C4.91992 8.45414 5.36764 8.90186 5.91992 8.90186H11.9199C12.4722 8.90186 12.9199 8.45414 12.9199 7.90186V5.90186C12.9199 5.34957 12.4722 4.90186 11.9199 4.90186Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2.5V2C4.5 1.17157 5.17157 0.5 6 0.5H12C12.8284 0.5 13.5 1.17157 13.5 2V2.5H15C15.663 2.5 16.2989 2.76339 16.7678 3.23223C17.2366 3.70107 17.5 4.33696 17.5 5V19C17.5 19.663 17.2366 20.2989 16.7678 20.7678C16.2989 21.2366 15.663 21.5 15 21.5H3C2.33696 21.5 1.70107 21.2366 1.23223 20.7678C0.763392 20.2989 0.5 19.663 0.5 19V5C0.5 4.33696 0.763392 3.70107 1.23223 3.23223C1.70107 2.76339 2.33696 2.5 3 2.5H4.5ZM5.5 2C5.5 1.72386 5.72386 1.5 6 1.5H12C12.2761 1.5 12.5 1.72386 12.5 2V4C12.5 4.27614 12.2761 4.5 12 4.5H6C5.72386 4.5 5.5 4.27614 5.5 4V2ZM13.5 3.5H15C15.3978 3.5 15.7794 3.65804 16.0607 3.93934C16.342 4.22064 16.5 4.60218 16.5 5V19C16.5 19.3978 16.342 19.7794 16.0607 20.0607C15.7794 20.342 15.3978 20.5 15 20.5H3C2.60218 20.5 2.22064 20.342 1.93934 20.0607C1.65804 19.7794 1.5 19.3978 1.5 19V5C1.5 4.60218 1.65804 4.22064 1.93934 3.93934C2.22064 3.65804 2.60218 3.5 3 3.5H4.5V4C4.5 4.82843 5.17157 5.5 6 5.5H12C12.8284 5.5 13.5 4.82843 13.5 4V3.5Z"
        fill="black"
      />
    </svg>
  );
};

export default ActiveClaimsIcon;
