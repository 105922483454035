export const DocumnetListingCardsStyles = {
  folderContainer: {
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
    "@media (max-width: 1023px)": {
      gap: "16px",
    },
  },
  filesContainer: {
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
    "@media (max-width: 1023px)": {
      rowGap: "45px",
      columnGap: "16px",
    },
  },
  fileHeading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "27.24px",
    textAlign: "left",
    color: "var(--primary-black)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  totalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "@media (max-width: 1023px)": {
      gap: "8px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",

    "@media (max-width: 1023px)": {
      marginTop: "-3px",
      gap: "24px",
    },
  },
};
