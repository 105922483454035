import { Box, Typography } from "@mui/material";
import Tables from "../../common/Tables/Tables";
import { POLICY_LISTING, TASKS } from "../../constants";
import { CustomerDetailsOverViewStyles } from "./CustomerDetailsOverViewStyles";
import MyTasksCard from "../MyTasksCard/MyTasksCard";
import {
  MytasksDataProps,
  OverviewTableDataProps,
} from "../CustomerDetailsList/CustomerDetailsList";
interface CustomerDetailsOverviewDataProps {
  overviewPolicyData: OverviewTableDataProps[];
  tasksData: MytasksDataProps[];
  overviewTableHeaders: string[];
}
const CustomerDetailsOverView: React.FC<CustomerDetailsOverviewDataProps> = ({
  overviewPolicyData,
  tasksData,
  overviewTableHeaders,
}) => {
  return (
    <Box sx={CustomerDetailsOverViewStyles.container}>
      <Box sx={CustomerDetailsOverViewStyles.policyContainer}>
        <Typography sx={CustomerDetailsOverViewStyles.heading}>
          {POLICY_LISTING}
        </Typography>
        <Tables
          headerData={overviewTableHeaders}
          data={overviewPolicyData}
          type="overviewTable"
        />
      </Box>
      <Box sx={CustomerDetailsOverViewStyles.policyContainer}>
        <Typography sx={CustomerDetailsOverViewStyles.heading}>
          {TASKS}
        </Typography>
        <Box sx={CustomerDetailsOverViewStyles.tasksContainer}>
          {tasksData.map((task, index) => (
            <MyTasksCard
              id={index.toString()}
              task={task.task}
              priority={task.priority}
              status={task.status}
              clientName={task.clientDetails.clientName}
              timeStamp={
                typeof task.timeStamp === "string"
                  ? { date: task.timeStamp, time: "" }
                  : task.timeStamp
              }
              policy={task.policy}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerDetailsOverView;
