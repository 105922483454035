import { createSlice } from "@reduxjs/toolkit";
import { IFeaturesSlice } from "../../../models/admin/features/features";
import { getFeatures } from "./featuresActions";

const initialState: IFeaturesSlice = { features: [] };

const featuresSlice = createSlice({
  name: "features",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatures.fulfilled, (state, { payload }) => {
      state.features = payload.data || [];
    });
  },
});

export default featuresSlice.reducer;
