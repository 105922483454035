import React from "react";
import { Props } from "./Props";

const AttentionIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 55"
      fill="none"
    >
      <circle cx="30.9992" cy="15.7111" r="9.59863" fill={fill} />
      <path d="M34.1206 19.3182C33.184 16.2759 31.0773 13.7303 28.2638 12.2414C25.4504 10.7524 22.1607 10.4421 19.1184 11.3787C16.0761 12.3153 13.5305 14.422 12.0416 17.2355C10.5526 20.049 10.2423 23.3387 11.1789 26.3809C15.2988 39.7636 10.7405 45.3529 10.7405 45.3529L45.1531 34.7588C45.1531 34.7588 38.2406 32.7008 34.1206 19.3182Z" stroke="#262729" strokeLinejoin="round"/>
        <path d="M33.6092 46.6842C33.4515 47.3672 33.1171 47.9966 32.6396 48.5097C32.1621 49.0227 31.5581 49.4013 30.8883 49.6075C30.2184 49.8137 29.5062 49.8403 28.8228 49.6847C28.1394 49.5291 27.5089 49.1966 26.9944 48.7207" stroke="#262729" strokeLinecap="round"/>
        <defs>
            <linearGradient id="paint0_linear_8_54" x1="30.9992" y1="6.11249" x2="30.9992" y2="25.3098" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA543"/>
            <stop offset="1" stopColor="#FFCC83"/>
            </linearGradient>
        </defs>
    </svg>
  );
};

export default AttentionIcon;
