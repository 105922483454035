import React, { ReactElement, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Legend,
  Cell,
} from "recharts";
import PieChartToolTip from "../../common/PieChartToolTip/PieChartToolTip";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PieChartStyles } from "./PieChartStyles";
import {
  classificationData,
  GRADIENTS,
  legendColors,
  pieChartTooltipData,
} from "../../constants/PieChartData";

// Define the shape rendering for active segments
const renderActiveShape = (props: any): ReactElement => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  const fixedTooltipX = 250;
  const fixedTooltipY = 0;

  let sx, sy, mx, my, ex, ey;
  switch (payload.id) {
    case 0: //red
      sx = cx + outerRadius - 10;
      sy = cy - 50;
      mx = sx + 10;
      my = sy - 40;
      ex = fixedTooltipX - 10;
      ey = my;
      break;
    case 1: // green
      sx = cx - 50;
      sy = cy + outerRadius - 10;
      mx = sx;
      my = sy + 30;
      ex = fixedTooltipX - 10;
      ey = my;
      break;
    case 2: //  (yellow)
      sx = cx + 60;
      sy = cy + outerRadius - 30;
      mx = sx + 60;
      my = sy;
      ex = fixedTooltipX - 10;
      ey = my;
      break;
    default:
      sx = cx;
      sy = cy;
      mx = cx;
      my = cy;
      ex = cx;
      ey = cy;
  }

  const tooltipData = pieChartTooltipData[payload.id];

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 10}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        strokeWidth={2}
        stroke="var(--white)"
        style={{
          cursor: "pointer",
          width: "100%",
          filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.2))",
          pointerEvents: "none",
        }}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={"var(--shaded-orange)"}
        fill="none"
      />
      <polygon
        points={`${ex},${ey - 6} ${ex + 6},${ey} ${ex},${ey + 6} ${
          ex - 6
        },${ey}`}
        fill={"var(--shaded-orange)"}
        stroke="none"
      />

      <foreignObject
        x={fixedTooltipX}
        y={fixedTooltipY}
        width = {window.innerWidth < 699 ? 260 : window.innerWidth >= 700 && window.innerWidth <= 1320 ? 250 : 312}
        height="268"
        style={{ pointerEvents: "none" }}
      >
        <Box sx={PieChartStyles.tooltipContainer}>
          {tooltipData.slice(0, 4).map((item, index) => (
            <PieChartToolTip
              key={index}
              heading={item.heading}
              subheading={item.subheading}
            />
          ))}
        </Box>
      </foreignObject>
    </g>
  );
};

const CustomLegend = (props: any) => {
  const { payload } = props;

  return (
    <Box sx={PieChartStyles.container}>
      {payload.map((entry: any, index: number) => (
        <Box key={`item-${entry.id}`} style={PieChartStyles.legendItem}>
          <Box
            style={{
              backgroundColor: legendColors[index],
            }}
            sx={PieChartStyles.legendContainer}
          />
          <Typography sx={PieChartStyles.legendText}>{entry.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const PieChartComponent: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  return (
    <ResponsiveContainer
      style={{ cursor: "pointer" }}
      width="100%"
      height={400}
    >
      <PieChart style={{ cursor: "pointer" }} width={400} height={200}>
        <defs>
          {GRADIENTS.map((grad) => (
            <linearGradient
              key={grad.id}
              id={grad.id}
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              {grad.colors.map((color, colorIndex) => (
                <stop
                  key={`${grad.id}-${colorIndex}`}
                  offset={`${colorIndex * 100}%`}
                  stopColor={color}
                />
              ))}
            </linearGradient>
          ))}
        </defs>
        <Pie
          activeIndex={isSmallScreen ? -1 : activeIndex}
          activeShape={renderActiveShape}
          data={classificationData}
          cx={isSmallScreen ? "50%" : "20%"}
          cy="30%"
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
          onMouseEnter={onPieEnter}
          stroke="none"
          focusable={false}
          tabIndex={-1}
          style={{ cursor: "pointer" }}
        >
          {classificationData.map((entry, index) => (
            <Cell key={`cell-${entry.id}`} fill={`url(#grad${entry.id + 1})`} />
          ))}
        </Pie>
        <Legend content={<CustomLegend />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
