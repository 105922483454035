export const CustomerDetailsNotesStyles = {
  chatContainer: {
    border: "1px solid var(--primary-grey)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: "var(--acne-blue)",
    padding: "10px",
    height: "432px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
    "@media (max-width: 1023px)": {
      height: "unset",
      backgroundColor: "unset",
      padding: "16px",
      paddingTop: "20px",
      borderRadius: "unset",
      border: "1px solid var(--shaded-cyan)",
      borderBottom: "0px",
      margin: "0px -20px",
      overflowY: "unset",
    },
  },
  textField: {
    width: "100%",
    background: "var(--white)",
    "@media (max-width: 1023px)": {
      marginTop: "34px",
    },
    "& .MuiOutlinedInput-root": {
      fontFamily: "var(--font-noto-sans)",
      fontSize: "var(--font-size-16)",
      color: "var(--primary-black)",
      "&:hover fieldset": {
        border: "1px solid var(--primary-grey)",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--primary-grey)",
      },
      "@media (max-width: 1023px)": {
        padding: "12.5px 14px",
      },
      "& .MuiOutlinedInput-input": {
        maxWidth: "85%",
      },
    },
  },
  customButton: {
    position: "absolute",
    right: "10px",
    "@media (max-width: 1023px)": {
      right: "6px",
      top: "7px",
    },
  },
  container: {
    width: "68.301%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media screen and (max-width: 1023px)": {
      width: "100%",
    },
  },
  saveButton: {
    padding: "9px 38.5px",
    boxShadow: "0px 6px 10px 0px #3F3F3F4D",
  },
  separator: {
    display: "none",
    "@media (max-width: 1023px)": {
      display: "block",
      height: "1px",
      width: "100vw",
      marginTop: "18px",
      backgroundColor: "var(--shaded-cyan)",
      marginLeft: "-20px",
    },
  },
};
