const ContactComponentStyles = {
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
  },
  contactInsuranceCompany: {
    fontSize: "18px",
    fontWeight: "400",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
  contactContactNumber: {
    display: "flex",
    gap: "10px",
  },
  contactPhoneLogo: {
    width: "24px",
    height: "24px",
  },
  contactMailLogo: {
    width: "24px",
    height: "24px",
  },
  contactNumber: {
    fontSize: "18px",
    fontWeight: "400",
    letterSpacing: "0em",
    fontFamily: "Noto Sans",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
  contactBusinessAddress: {
    fontSize: "18px",
    fontWeight: "300",
    fontFamily: "Noto Sans",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
  contactAddress: {
    width: "auto",
    fontSize: "18px",
    fontWeight: "400",
    fontFamily: "Noto Sans",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
  heading: {
    fontFamily: "Noto Sans",
    width: "auto",
    fontSize: "18px",
    fontWeight: "300",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
  content: {
    fontFamily: "Noto Sans",
    width: "auto",
    fontSize: "18px",
    fontWeight: "400",
    "@media(max-width: 780px)": {
      fontSize: "14px",
    },
  },
};

export default ContactComponentStyles;
