export const ClaimCardStyles = {
  heading: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
  },
  subheading: {
    fontSize: "var(--font-size-12)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "16px",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-300)",
    "@media screen and (min-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    "@media screen and (min-width: 1023px)": {
      gap: "7px",
    },
  },
  container: {
    "@media screen and (min-width: 1023px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  claimIdContainer: {
    borderBottom: "1px solid var(--primary-grey)",
    paddingBottom: "10px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "@media screen and (min-width: 1023px)": {
      borderBottom: "0px",
      gap: "30px",
      paddingBottom: "0px",
    },
  },
  claimsContainer: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "54px",
    paddingTop: "16px",
    paddingBottom: "20px",
    borderBottom: "1px solid var(--primary-grey)",
    "@media screen and (min-width: 1023px)": {
      borderBottom: "0px",
      padding: "0px",
      gap: "70px",
    },
  },
  claimId: {
    fontSize: "var(--font-size-20)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
  },
  button: {
    marginTop: "10px",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    "@media screen and (min-width: 1023px)": {
      justifyContent: "unset",
      width: "unset",
    },
  },
  imageAndTextContainer: {
    gap: "2px",
    paddingTop: "10px",
    "@media screen and (min-width: 1023px)": {
      paddingTop: "0px",
    },
  },
  firstHeaderClassName: {
    minWidth: "90%",
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    "@media screen and (min-width: 1023px)": {
      minWidth: "0px",
    },
  },
  carIcon: {
    "> img": {
      width: "38px",
      height: "38px",
      "@media screen and (min-width: 1023px)": {
        width: "50px",
        height: "50px",
      },
    },
  },
  innerContainer: {
    "@media screen and (min-width: 1023px)": {
      display: "flex",
      flexDirection: "row",
      gap: "70px",
    },
  },
  claimCardContainer: {
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
    padding: "16px",
    paddingBottom: "14px",
    "@media screen and (min-width: 1023px)": {
      display: "flex",
      flexDirection: "column",
      padding: "17px 25px 17px 25px",
      gap: "15px",
    },
  },
  claimIdNo: {
    display: "none",
    "@media screen and (min-width: 1023px)": {
      display: "block",
      fontSize: "var(--font-size-14)",
      lineHeight: "22px",
      color: "var(--primary-black)",
      fontWeight: "var(--font-weight-400)",
      fontFamily: "var(--font-noto-sans)",
    },
  },
};
