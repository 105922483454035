import React from "react";
import { Props } from "./Props";

const PolicyRenewalIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 41"
      fill="none"
    >
      <rect y="3.63672" width="22.7266" height="12.7269" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54481 3.50195H14.0449V0H15.0449V3.50195H30.043V0H31.043V3.50195H36.544C39.0292 3.50195 41.0439 5.51662 41.0439 8.00184V36.001C41.0439 38.4863 39.0292 40.5009 36.544 40.5009H8.54481C6.05959 40.5009 4.04492 38.4863 4.04492 36.001V8.00184C4.04492 5.51662 6.05959 3.50195 8.54481 3.50195ZM30.043 4.50195V7.99977H31.043V4.50195H36.544C38.4769 4.50195 40.0439 6.0689 40.0439 8.00184V15.498H5.04492V8.00184C5.04492 6.0689 6.61187 4.50195 8.54481 4.50195H14.0449V7.99977H15.0449V4.50195H30.043ZM5.04492 16.498V36.001C5.04492 37.934 6.61187 39.5009 8.54481 39.5009H36.544C38.4769 39.5009 40.0439 37.934 40.0439 36.001V16.498H5.04492Z"
        fill="#262729"
      />
    </svg>
  );
};

export default PolicyRenewalIcon;
