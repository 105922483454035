import { Box, Card, Typography } from "@mui/material";
import WelcomeCardStyle from "./WelcomeCardStyle";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import React, { useMemo, useState } from "react";
import FileAClaim from "../../../components/FileAClaim/FileAClaim";
import { setPolicyDetailsHeader } from "../../../store/user/policyDetails/policyDetailsSlice";
import CustomIcon from "../../../common/CustomIcon/CustomIcon";
import { isFeatureAvailable } from "../../../utils/Utility";

interface Props {
  handleYourPoliciesClick: () => void;
}

const WelcomeCard: React.FC<Props> = ({ handleYourPoliciesClick }) => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { features } = useAppSelector((state: RootState) => state.features);

  const [fileAClaimOpen, setFileAClaimOpen] = useState<boolean>(false);

  const time = useMemo(
    () => new Date().toLocaleTimeString().split(" ")[0].split(":"),
    []
  );

  const isAM = useMemo(
    () => new Date().toLocaleTimeString().split(" ")[1] === "AM",
    []
  );

  const toggleDrawer = (): void => {
    dispatch(setPolicyDetailsHeader(null));
    setFileAClaimOpen(!fileAClaimOpen);
  };

  return (
    <>
      <Box sx={WelcomeCardStyle.welcomeCardContainerStyle}>
        <Typography sx={WelcomeCardStyle.welcomeCardTextStyle}>
          Good{" "}
          {isAM ? "Morning" : parseInt(time[0]) >= 5 ? "evening" : "afternoon"},{" "}
          {userDetails?.customerName}! Need help today?
        </Typography>
        <Card sx={WelcomeCardStyle.welcomeCardStyle} aria-label="welcome Card">
          {isFeatureAvailable({
            featureCodes: ["lob_pa_policies_my_policies"],
            features,
          }) ? (
            <Box
              sx={WelcomeCardStyle.welcomeCardItemStyle}
              onClick={handleYourPoliciesClick}
            >
              <CustomIcon iconName="PaymentHistory" />
              <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
                My Policies
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          {isFeatureAvailable({
            featureCodes: ["lob_pa_claims_new_claim"],
            features,
          }) ? (
            <Box
              sx={WelcomeCardStyle.welcomeCardItemStyle}
              onClick={toggleDrawer}
            >
              <CustomIcon iconName="Star" />
              <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
                New Claim
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          {features.some(
            (feature) =>
              feature.featureCode === "roadside_assistance" && feature.selected
          ) ? (
            <Box sx={WelcomeCardStyle.welcomeCardItemStyle}>
              <CustomIcon iconName="RoadsideAssistance" />
              <Typography sx={WelcomeCardStyle.WelcomCardItemTextStyle}>
                Roadside Assistance
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Card>
      </Box>
      <FileAClaim open={fileAClaimOpen} setOpen={setFileAClaimOpen} />
    </>
  );
};

export default WelcomeCard;
