export const commissionStatemnetsHeadingsData = [
  {
    heading: "$200,000",
    subheading: "Total Commission Earned",
  },
  {
    heading: "$80,000",
    subheading: "Unrealised commission",
  },
];
