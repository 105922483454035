import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IGetPreferenceTypesResponse,
  IGetUserPreferences,
  IPostUserPreferencesResponse,
  IResetUserPreferencesResponse,
  IUserPreference,
} from "../../../models/user/Profile/Profile";
import { Api } from "../../..";
import { CustomAction } from "../../customAction";
import {
  API_DELETE_USER_PREFERENCES,
  API_GET_PREFERENCE_TYPES,
  API_GET_USER_PREFERENCES,
  API_POST_USER_PREFERENCES,
} from "../../../Apis/ApiEndPoints";

export const getPreferenceTypes = createAsyncThunk(
  "profile/getPreferenceTypes",
  async (_, { dispatch }) => {
    const response: IGetPreferenceTypesResponse = await CustomAction(
      Api.get(API_GET_PREFERENCE_TYPES),
      dispatch,
      "profile/getPreferenceTypes"
    );
    return response;
  }
);

export const getUserPreferences = createAsyncThunk(
  "profile/getUserPreferences",
  async (customerId: string, { dispatch }) => {
    const response: IGetUserPreferences = await CustomAction(
      Api.get(`${API_GET_USER_PREFERENCES}/${customerId}`),
      dispatch,
      "profile/getUserPreferences"
    );
    return response;
  }
);

export const postUserPreferences = createAsyncThunk(
  "profile/postUserPreferences",
  async (requestParams: IUserPreference[], { dispatch }) => {
    const response: IPostUserPreferencesResponse = await CustomAction(
      Api.post(API_POST_USER_PREFERENCES, requestParams),
      dispatch,
      "profile/postUserPreferences"
    );
    return response;
  }
);

export const resetUserPreferences = createAsyncThunk(
  "profile/resetUserPreferences",
  async (customerId: string, { dispatch }) => {
    const response: IResetUserPreferencesResponse = await CustomAction(
      Api.delete(`${API_DELETE_USER_PREFERENCES}/${customerId}`),
      dispatch,
      "profile/resetUserPreferences"
    );
    return response;
  }
);
