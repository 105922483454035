import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Logo from "../../assets/Layer 1 copy 1.svg";
import notification from "../../assets/notification.svg";
import displayPicture from "../../assets/Rectangle 41106.svg";
import menu from "../../assets/align-justify.svg";
import searchIcon from "../../assets/search.svg";
import { useNavigate } from "react-router-dom";
import { AgencyHeaderStyles } from "./AgencyHeaderStyles";
import SearchField from "../SearchField/SearchField";
import { DISPLAY_PICTURE, MENU, MENU_ICON, NOTIFICATION, SEARCH_ICON, SIMS_LOGO } from "../../constants";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import Drawers from "../Drawers/Drawers";

const AgencyHeader: React.FC = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible]);

  const headerData = [
    {
      logo: Logo,
      notification: notification,
      displayPicture: displayPicture,
      menu: menu,
    },
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/profile-details");
  };

  const isMobile = useMediaQuery("(max-width:599px)");
  const isSmallScreen = useMediaQuery("(max-width:1023px)");

  const toggleMenu = () => {
    window.scrollTo({
      top: 0, 
    });
    if (isMobile) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      setIsDrawerOpen(true); 
    }
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      {(!isMenuOpen || !isMobile) && ( 
        <Box
          sx={{
            ...AgencyHeaderStyles.headerContainer,
            display: visible ? "flex" : "none",
          }}
        >
          <Box sx={AgencyHeaderStyles.logo}>
            <img
              src={headerData[0].logo}
              style={AgencyHeaderStyles.simsLogo}
              alt={SIMS_LOGO}
              onClick={() => window.location.reload()}
            />
          </Box>
          {!isSmallScreen && <SearchField />}
          <Box sx={AgencyHeaderStyles.headerItems}>
            <Box>
              {isSmallScreen ? (
                <img style={AgencyHeaderStyles.searchIcon} src={searchIcon} alt={SEARCH_ICON} />
              ) : (
                <img
                  src={headerData[0].notification}
                  alt={NOTIFICATION}
                  style={AgencyHeaderStyles.notificationIcon}
                />
              )}
            </Box>
            <Box onClick={handleClick}>
              <img
                src={headerData[0].displayPicture}
                alt={DISPLAY_PICTURE}
                style={AgencyHeaderStyles.displayPicture}
              />
            </Box>
            <Box onClick={toggleMenu}> 
              <img
                src={headerData[0].menu}
                alt={MENU_ICON}
                style={AgencyHeaderStyles.menuIcon}
              />
            </Box>
          </Box>
        </Box>
      )}

      {isMenuOpen && isMobile && <HeaderMenu onClose={toggleMenu} />}

      {!isMobile && (
        <Drawers
          open={isDrawerOpen}
          toggleDrawer={closeDrawer}
          heading={MENU}
         additionalClass={AgencyHeaderStyles.drawer}
        >
          <HeaderMenu onClose={closeDrawer}/>
        </Drawers>
      )}
    </>
  );
};

export default AgencyHeader;
