// Define the data for the pie chart
export const classificationData = [
    { name: "High", value: 400, id: 0 },
    { name: "Medium", value: 300, id: 1 },
    { name: "Low", value: 300, id: 2 },
  ];
 export const legendColors = [
    "var(--secondary-red)",
    "var(--ternary-yellow)",
    "var( --ternary-green)",
  ];
  
  // Define the additional data for tooltips
 export const pieChartTooltipData = [
    [
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
  
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
    ],
    [
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
  
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
    ],
    [
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
  
      {
        heading: "Schedule Follow up | 22nd Jul",
        subheading: "customer name | Policy #12345",
      },
    ],
  ];
  
  // Define colors for the pie chart segments
 export const GRADIENTS = [
    { id: "grad1", colors: ["var( --support-red)", "var(--secondary-red)"] },
    { id: "grad2", colors: ["var( --pale-green)", "var( --sea-green)"] },
    {
      id: "grad3",
      colors: ["var( --support-yellow)", "var( --saturated-yellow)"],
    },
  ];