import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../CustomButton/CustomButton";
import {
  ADD,
  ADD_PAYMENT_METHOD,
  EXP,
  MENU_ICON,
  RECURRING_PAYMENTS,
  VISA,
} from "../../constants";
import menuIcon from "../../assets/svgs/menu-icon-white.svg";
import visaIcon from "../../assets/svgs/visa-icon.svg";
import { PaymentMethodStyles } from "./PaymentMethodStyles";
import addIcon from "../../assets/svgs/plus-icon.svg";
export interface PaymentMethodCardDataProps {
  cardHolder: string;
  cardNumber: string;
  expirationDate: string;
}
interface PaymentMethodCardProps {
  paymentMethodCardData: PaymentMethodCardDataProps[];
}
const PaymentMethod: React.FC<PaymentMethodCardProps> = ({
  paymentMethodCardData,
}) => {
  const maskedCardNumber = (cardNumber: string) =>
    `****  ****  **** ${cardNumber.slice(-4)}`;
  return (
    <Box sx={PaymentMethodStyles.cardsContainer}>
      {paymentMethodCardData.map((card, index) => (
        <Box key={index} sx={PaymentMethodStyles.customerCard}>
          <Box sx={PaymentMethodStyles.cardHeader}>
            <CustomButton
              text={RECURRING_PAYMENTS}
              type="outlined"
              additionalClassName={PaymentMethodStyles.recurringButton}
              
            />
            <img src={menuIcon} alt={MENU_ICON} />
          </Box>

          <Box sx={PaymentMethodStyles.cardNumberContainer}>
            <Typography sx={PaymentMethodStyles.cardNumber}>
              {maskedCardNumber(card.cardNumber)}
            </Typography>
          </Box>

          <Box sx={PaymentMethodStyles.contentContainer}>
            <Box sx={PaymentMethodStyles.cardInformation}>
              <Typography sx={PaymentMethodStyles.cardHolderName}>
                {card.cardHolder}
              </Typography>
              <Typography sx={PaymentMethodStyles.cardExpiry}>
                {EXP} {card.expirationDate}
              </Typography>
            </Box>

            <img src={visaIcon} alt={VISA} />
          </Box>
        </Box>
      ))}
      <Box sx={PaymentMethodStyles.addPaymentMethodContainer}>
        <Box sx={PaymentMethodStyles.addPaymentMethodContent}>
          <Box sx={PaymentMethodStyles.addPaymentMethodAddSymbol}>
            <img src={addIcon} alt={ADD} />
          </Box>
          <Typography sx={PaymentMethodStyles.addPaymentMethodDescription}>
            {ADD_PAYMENT_METHOD}
          </Typography>
        </Box>
      </Box>
      <Box>
        <CustomButton
          text={ADD_PAYMENT_METHOD}
          type="outlined"
          additionalClassName={PaymentMethodStyles.addPaymentButton}
        />
      </Box>
    </Box>
  );
};

export default PaymentMethod;
