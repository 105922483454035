export const customerListingTableHeadingsData = [
  "Customer Details",
  "Contact Details",
  "Policy Status",
  "Claim Status",
  "Last Interaction Date",
];

export const customerListingData = [
  {
    customerDetails: {
      image: "",
      customerName: "John Smith",
      customerNumber: "(321) 654-0987",
    },
    contactDetails: {
      customerPhoneNumber: "(321) 654-0987",
      customerEmail: "john.smith@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 1,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "March 12, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mary Johnson",
      customerNumber: "(234) 567-8901",
    },
    contactDetails: {
      customerPhoneNumber: "(234) 567-8901",
      customerEmail: "mary.johnson@sims.com",
    },
    customerPolicyStatus: {
      active: 3,
      pending: 0,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 1,
    },
    customerLastInteractionDate: "June 23, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Michael Williams",
      customerNumber: "(345) 678-9012",
    },
    contactDetails: {
      customerPhoneNumber: "(345) 678-9012",
      customerEmail: "michael.williams@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 2,
      tasks: 1,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "April 8, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Jennifer Brown",
      customerNumber: "(456) 789-0123",
    },
    contactDetails: {
      customerPhoneNumber: "(456) 789-0123",
      customerEmail: "jennifer.brown@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 1,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 2,
      closed: 0,
    },
    customerLastInteractionDate: "September 5, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "(567) 890-1234",
    },
    contactDetails: {
      customerPhoneNumber: "(567) 890-1234",
      customerEmail: "robert.jones@sims.com",
    },
    customerPolicyStatus: {
      active: 4,
      pending: 0,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 0,
      closed: 1,
    },
    customerLastInteractionDate: "September 28, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Linda Miller",
      customerNumber: "(678) 901-2345",
    },
    contactDetails: {
      customerPhoneNumber: "(678) 901-2345",
      customerEmail: "linda.miller@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 1,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "July 14, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "William Davis",
      customerNumber: "(789) 012-3456",
    },
    contactDetails: {
      customerPhoneNumber: "(789) 012-3456",
      customerEmail: "william.davis@sims.com",
    },
    customerPolicyStatus: {
      active: 3,
      pending: 0,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 2,
    },
    customerLastInteractionDate: "May 20, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Elizabeth Garcia",
      customerNumber: "(890) 123-4567",
    },
    contactDetails: {
      customerPhoneNumber: "(890) 123-4567",
      customerEmail: "elizabeth.garcia@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 0,
      tasks: 1,
    },
    claimStatus: {
      open: 0,
      closed: 1,
    },
    customerLastInteractionDate: "August 9, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "David Rodriguez",
      customerNumber: "(901) 234-5678",
    },
    contactDetails: {
      customerPhoneNumber: "(901) 234-5678",
      customerEmail: "david.rodriguez@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 1,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "March 25, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Barbara Wilson",
      customerNumber: "(012) 345-6789",
    },
    contactDetails: {
      customerPhoneNumber: "(012) 345-6789",
      customerEmail: "barbara.wilson@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 2,
      tasks: 2,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "August 25, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Richard Martinez",
      customerNumber: "(123) 456-7890",
    },
    contactDetails: {
      customerPhoneNumber: "(123) 456-7890",
      customerEmail: "richard.martinez@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 0,
      expired: 0,
      tasks: 1,
    },
    claimStatus: {
      open: 1,
      closed: 1,
    },
    customerLastInteractionDate: "September 1, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Susan Anderson",
      customerNumber: "(234) 567-8901",
    },
    contactDetails: {
      customerPhoneNumber: "(234) 567-8901",
      customerEmail: "susan.anderson@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 1,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 0,
      closed: 1,
    },
    customerLastInteractionDate: "February 10, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Joseph Taylor",
      customerNumber: "(345) 678-9012",
    },
    contactDetails: {
      customerPhoneNumber: "(345) 678-9012",
      customerEmail: "joseph.taylor@sims.com",
    },
    customerPolicyStatus: {
      active: 3,
      pending: 0,
      expired: 1,
      tasks: 1,
    },
    claimStatus: {
      open: 2,
      closed: 0,
    },
    customerLastInteractionDate: "June 5, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Jessica Thomas",
      customerNumber: "(456) 789-0123",
    },
    contactDetails: {
      customerPhoneNumber: "(456) 789-0123",
      customerEmail: "jessica.thomas@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 1,
      expired: 0,
      tasks: 1,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "May 5, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Thomas Hernandez",
      customerNumber: "(567) 890-1234",
    },
    contactDetails: {
      customerPhoneNumber: "(567) 890-1234",
      customerEmail: "thomas.hernandez@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 1,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "August 22, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Patricia Moore",
      customerNumber: "(678) 901-2345",
    },
    contactDetails: {
      customerPhoneNumber: "(678) 901-2345",
      customerEmail: "patricia.moore@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "February 18, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Christopher Jackson",
      customerNumber: "(789) 012-3456",
    },
    contactDetails: {
      customerPhoneNumber: "(789) 012-3456",
      customerEmail: "christopher.jackson@sims.com",
    },
    customerPolicyStatus: {
      active: 3,
      pending: 0,
      expired: 1,
      tasks: 1,
    },
    claimStatus: {
      open: 2,
      closed: 1,
    },
    customerLastInteractionDate: "July 8, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Sarah White",
      customerNumber: "(890) 123-4567",
    },
    contactDetails: {
      customerPhoneNumber: "(890) 123-4567",
      customerEmail: "sarah.white@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 1,
      expired: 0,
      tasks: 1,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "March 3, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Daniel Harris",
      customerNumber: "(901) 234-5678",
    },
    contactDetails: {
      customerPhoneNumber: "(901) 234-5678",
      customerEmail: "daniel.harris@sims.com",
    },
    customerPolicyStatus: {
      active: 4,
      pending: 0,
      expired: 1,
      tasks: 2,
    },
    claimStatus: {
      open: 3,
      closed: 0,
    },
    customerLastInteractionDate: "September 15, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Nancy Martin",
      customerNumber: "(012) 345-6789",
    },
    contactDetails: {
      customerPhoneNumber: "(012) 345-6789",
      customerEmail: "nancy.martin@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 2,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 2,
    },
    customerLastInteractionDate: "April 20, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Paul Thompson",
      customerNumber: "(123) 456-7890",
    },
    contactDetails: {
      customerPhoneNumber: "(123) 456-7890",
      customerEmail: "paul.thompson@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 0,
      expired: 1,
      tasks: 1,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "June 10, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Karen Garcia",
      customerNumber: "(234) 567-8901",
    },
    contactDetails: {
      customerPhoneNumber: "(234) 567-8901",
      customerEmail: "karen.garcia@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 0,
      tasks: 1,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "May 18, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Steven Martinez",
      customerNumber: "(345) 678-9012",
    },
    contactDetails: {
      customerPhoneNumber: "(345) 678-9012",
      customerEmail: "steven.martinez@sims.com",
    },
    customerPolicyStatus: {
      active: 3,
      pending: 1,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "August 2, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Lisa Robinson",
      customerNumber: "(456) 789-0123",
    },
    contactDetails: {
      customerPhoneNumber: "(456) 789-0123",
      customerEmail: "lisa.robinson@sims.com",
    },
    customerPolicyStatus: {
      active: 2,
      pending: 0,
      expired: 2,
      tasks: 2,
    },
    claimStatus: {
      open: 1,
      closed: 0,
    },
    customerLastInteractionDate: "February 5, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mark Clark",
      customerNumber: "(567) 890-1234",
    },
    contactDetails: {
      customerPhoneNumber: "(567) 890-1234",
      customerEmail: "mark.clark@sims.com",
    },
    customerPolicyStatus: {
      active: 4,
      pending: 0,
      expired: 0,
      tasks: 2,
    },
    claimStatus: {
      open: 2,
      closed: 0,
    },
    customerLastInteractionDate: "July 25, 2024",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Sandra Lewis",
      customerNumber: "(678) 901-2345",
    },
    contactDetails: {
      customerPhoneNumber: "(678) 901-2345",
      customerEmail: "sandra.lewis@sims.com",
    },
    customerPolicyStatus: {
      active: 1,
      pending: 1,
      expired: 1,
      tasks: 1,
    },
    claimStatus: {
      open: 0,
      closed: 0,
    },
    customerLastInteractionDate: "March 15, 2024",
  },
];
