import { Box } from "@mui/material";
import React from "react";
import InvoicesCard from "../../common/InvoicesCard/InvoicesCard";
import { InvoicesCardListingStyles } from "./InvoicesCardListingStyles";
import { InvoicesTableDataProps } from "../CustomerDetailsList/CustomerDetailsList";
interface InvoiceCardsProps {
  invoiceCards: InvoicesTableDataProps[];
}
const InvoicesCardListing: React.FC<InvoiceCardsProps> = ({ invoiceCards }) => {
  return (
    <Box sx={InvoicesCardListingStyles.container}>
      {invoiceCards.map((invoice, index) => (
        <InvoicesCard
          billingDate={invoice.billingDate}
          paymentMethod={invoice.paymentMethod}
          paidOn={invoice.paidOn}
          amount={invoice.amount}
          invoice={invoice.invoice}
        />
      ))}
    </Box>
  );
};

export default InvoicesCardListing;
