import { Box } from "@mui/material";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../ImageAndText/ImageAndText";
import phoneIcon from "../../assets/svgs/phone-icon.svg";
import mailIcon from "../../assets/svgs/mail-icon.svg";
import clockIcon from "../../assets/svgs/clock-icon-1.svg";
import { CustomerDetailsCardStyles } from "./CustomerDetailsCardStyles";

const icons: { [key: string]: string } = {
  phoneIcon,
  mailIcon,
  clockIcon,
};

interface CustomerDetailsCardProps {
  data: {
    policyDetails: { heading: string; subheading: string }[];
    claimDetails: { heading: string; subheading: string }[];
    contactDetails: { image: string; text: string }[];
    interactionDetail: { text: string };
  };
}

const CustomerDetailsCard: React.FC<CustomerDetailsCardProps> = ({ data }) => {
  return (
    <Box sx={CustomerDetailsCardStyles.container}>
      <Box sx={CustomerDetailsCardStyles.policyDetails}>
        <Box sx={CustomerDetailsCardStyles.policyContainer}>
          {data.policyDetails.map((detail, index) => (
            <HeadingSubHeading
              key={index}
              heading={detail.heading}
              subheading={detail.subheading}
              containerAdditionalClassName={
                CustomerDetailsCardStyles.contentContainer
              }
              headingAdditionalClassName={
                CustomerDetailsCardStyles.contentContainerHeading
              }
              subHeadingAdditionalClassName={
                CustomerDetailsCardStyles.contentContainerSubHeading
              }
            />
          ))}
        </Box>
        <Box sx={CustomerDetailsCardStyles.separator}></Box>
        <Box sx={CustomerDetailsCardStyles.claimContainer}>
          {data.claimDetails.map((detail, index) => (
            <HeadingSubHeading
              key={index}
              heading={detail.heading}
              subheading={detail.subheading}
              containerAdditionalClassName={
                CustomerDetailsCardStyles.contentContainer
              }
              headingAdditionalClassName={
                CustomerDetailsCardStyles.contentContainerHeading
              }
              subHeadingAdditionalClassName={
                CustomerDetailsCardStyles.contentContainerSubHeading
              }
            />
          ))}
        </Box>
        <Box sx={CustomerDetailsCardStyles.separator}></Box>
        <Box sx={CustomerDetailsCardStyles.customerDetails}>
          {data.contactDetails.map((contact, index) => (
            <ImageAndText
            additionalClassName={CustomerDetailsCardStyles.contentDetailsContainer}
              key={index}
              image={icons[contact.image]}
              text={contact.text}
            />
          ))}
        </Box>
      </Box>
      <Box>
        <ImageAndText
          image={clockIcon}
          additionalClassName={CustomerDetailsCardStyles.interactionDetailContainer}
          text={`Last Interaction ${data.interactionDetail.text}`}
          additionalClassText={CustomerDetailsCardStyles.interactionDetail}
        />
      </Box>
    </Box>
  );
};

export default CustomerDetailsCard;
