import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import UpdateCoveragesStyles from "./UpdateCoveragesStyles";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { IPolicyVehicle } from "../../../../../models/user/policyDetails/PolicyDetails";
import { GetOptedAndUnoptedCoverages } from "../../../../../store/user/vehicle/vehicleActions";
import { RootState } from "../../../../../store/store";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  vehicle: IPolicyVehicle;
}

const UpdateCoverages: React.FC<IProps> = ({ open, setOpen, vehicle }) => {
  const dispatch = useAppDispatch();

  const { optedCoverages, unoptedCoverages } = useAppSelector(
    (state: RootState) => state.vehicle
  );

  const [formValues, setFormValues] = useState<{ [key: string]: any }>({});

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (open) {
      dispatch(
        GetOptedAndUnoptedCoverages({
          lineCd: vehicle.lineCd,
          policyId: vehicle.policyId,
          productVersionId: vehicle.productVersionId,
          riskNumber: vehicle.riskNumber,
        })
      );
    }
  }, [open, vehicle, dispatch]);

  useEffect(() => {}, [optedCoverages, unoptedCoverages]);

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: "50%",
          "@media (max-width:768px)": {
            width: "90%",
          },
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <Box sx={UpdateCoveragesStyles.heading}>
        <Typography sx={UpdateCoveragesStyles.headingText}>
          Update Coverages
        </Typography>
        <CloseOutlined sx={{ cursor: "pointer" }} onClick={handleClose} />
      </Box>
      <Box sx={{ padding: "20px" }}>
        <FormGroup>
          {optedCoverages.map((optedCoverage) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                control={<Checkbox checked />}
                label={optedCoverage.coverageName}
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                <Typography>Limits</Typography>
                <Typography>
                  {optedCoverage.limits.map((limit, index) =>
                    index === optedCoverage.limits.length - 1
                      ? limit.value
                      : `${limit.value}/`
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
          {unoptedCoverages.map((unoptedCoverage) => (
            <FormControlLabel
              control={<Checkbox />}
              label={unoptedCoverage.coverageName}
            />
          ))}
        </FormGroup>
        {optedCoverages.length > 0 && unoptedCoverages.length > 0 ? (
          <Button variant="contained">Submit</Button>
        ) : (
          <></>
        )}
      </Box>
    </Drawer>
  );
};

export default UpdateCoverages;
