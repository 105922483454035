import React from "react";
import { Props } from "./Props";

const CelebrationCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 41 44"
      fill="none"
    >
      <path
        d="M4.58233 20.9905H36.89C37.7469 20.9905 38.5686 21.3309 39.1745 21.9367C39.7804 22.5426 40.1208 23.3644 40.1208 24.2212V28.9321C40.1208 32.4537 37.3605 35.43 33.841 35.5269C32.9774 35.5512 32.1178 35.4021 31.3129 35.0884C30.508 34.7747 29.7741 34.3028 29.1548 33.7006C28.5354 33.0983 28.0431 32.378 27.707 31.5822C27.3708 30.7864 27.1976 29.9313 27.1977 29.0674C27.1977 30.7811 26.5169 32.4246 25.3052 33.6364C24.0934 34.8482 22.4499 35.5289 20.7362 35.5289C19.0225 35.5289 17.379 34.8482 16.1672 33.6364C14.9554 32.4246 14.2746 30.7811 14.2746 29.0674C14.275 29.9315 14.102 30.7868 13.766 31.5828C13.43 32.3789 12.9377 33.0994 12.3183 33.7019C11.6989 34.3043 10.965 34.7764 10.1599 35.0902C9.35489 35.4041 8.49508 35.5532 7.63137 35.5289C4.11185 35.43 1.35156 32.4537 1.35156 28.9321V24.2212C1.35156 23.3644 1.69195 22.5426 2.29783 21.9367C2.90372 21.3309 3.72548 20.9905 4.58233 20.9905Z"
        fill={fill}
      />
      <path
        d="M20.1309 13.9231V18.7692"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.97686 18.7692H36.2846C37.1414 18.7692 37.9632 19.1096 38.5691 19.7155C39.1749 20.3214 39.5153 21.1431 39.5153 22V26.7109C39.5153 30.2324 36.755 33.2087 33.2355 33.3057C32.372 33.33 31.5123 33.1809 30.7074 32.8672C29.9025 32.5535 29.1687 32.0815 28.5493 31.4793C27.9299 30.8771 27.4376 30.1568 27.1015 29.361C26.7653 28.5652 26.5922 27.71 26.5922 26.8461C26.5922 28.5599 25.9115 30.2034 24.6997 31.4151C23.4879 32.6269 21.8444 33.3077 20.1307 33.3077C18.417 33.3077 16.7735 32.6269 15.5617 31.4151C14.3499 30.2034 13.6692 28.5599 13.6692 26.8461C13.6695 27.7102 13.4966 28.5655 13.1605 29.3616C12.8245 30.1576 12.3322 30.8782 11.7128 31.4806C11.0934 32.0831 10.3595 32.5552 9.55447 32.869C8.74942 33.1828 7.88961 33.332 7.0259 33.3077C3.50638 33.2087 0.746094 30.2324 0.746094 26.7109V22C0.746094 21.1431 1.08648 20.3214 1.69236 19.7155C2.29825 19.1096 3.12001 18.7692 3.97686 18.7692Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.8998 31.0663V39.7692C37.8998 40.6261 37.5594 41.4478 36.9535 42.0537C36.3476 42.6596 35.5259 43 34.669 43H5.5921C4.73524 43 3.91349 42.6596 3.3076 42.0537C2.70171 41.4478 2.36133 40.6261 2.36133 39.7692V31.0663"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.9775 9.07692C24.9775 10.3622 24.4669 11.5948 23.5581 12.5037C22.6492 13.4125 21.4166 13.9231 20.1313 13.9231C18.846 13.9231 17.6134 13.4125 16.7046 12.5037C15.7957 11.5948 15.2852 10.3622 15.2852 9.07692C15.2852 4.23077 20.1313 1 20.1313 1C20.1313 1 24.9775 4.23077 24.9775 9.07692Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CelebrationCardIcon;
