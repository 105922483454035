import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { BarChartComponentStyles } from "./BarChartComponentStyles";
interface BarChartProps {
  barChartData: {
    name: string | number;
    uv: number;
  }[];
  yAxisData: number[];
  primary?: boolean;
}
const BarChartComponent: React.FC<BarChartProps> = ({
  barChartData,
  yAxisData,
  primary = false,
}) => {
  const isMobile = useMediaQuery("(max-width:599px)");
  const xAxisTickStyle = {
    fontSize: isMobile ? "var(--font-size-10)" : "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: 22,
    fontFamily: "var(--font-noto-sans)",
    fill: "var(--secondary-grey)",
    color: "var(--secondary-grey)",
  };

  const yAxisTickStyle = {
    fontSize: isMobile ? "var(--font-size-10)" : "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: 19,
    fill: "var(--secondary-grey)",
    color: "var(--secondary-grey)",
  };

  const CustomXAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
      <foreignObject x={isMobile?x-25:x - 45} y={y} width={isMobile?60:100} height={100}>
        <div
          style={{
            fontSize: xAxisTickStyle.fontSize,
            fontWeight: xAxisTickStyle.fontWeight,
            fontFamily: xAxisTickStyle.fontFamily,
            color: xAxisTickStyle.color,
            whiteSpace: "nowrap",
            overflow: "hidden",
            wordBreak: "break-all",
            textAlign: 'center',
            textOverflow: "ellipsis",
          }}
        >
          {payload.value}
        </div>
      </foreignObject>
    );
  };

  const formatYAxisTick = (value: any) => {
    const shouldFormat = primary;
    return shouldFormat
      ? ` ${value}`
      : value === 0
      ? `$${value}`
      : `$${value / 1000}K`;
  };
  const maxDataValue = Math.max(...barChartData.map((data) => data.uv));

  return (
    <Box sx={BarChartComponentStyles.barChartContainer}>
      <ResponsiveContainer height={isMobile ? 235 : 257} width={"100%"}>
        <BarChart
          data={barChartData}
          margin={{ top: 30, right: 0, left: isMobile ? 0 : 10, bottom: 0 }}
          style={{ cursor: "pointer" }}
        >
          <defs>
            <linearGradient id="color1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#4586E7" stopOpacity={1} />
              <stop offset="100%" stopColor="#8EBBFF" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            tickLine={false}
            dataKey="name"
            tick={CustomXAxisTick}
            interval={0}
          />
          <YAxis
            tickFormatter={formatYAxisTick}
            domain={[0, maxDataValue]}
            ticks={yAxisData}
            tick={{ ...yAxisTickStyle }}
            tickLine={false}
            axisLine={false}
          />
          <Bar dataKey="uv" fill="url(#color1)" barSize={isMobile ? 10 : 20} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BarChartComponent;
