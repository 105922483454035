export const NewTaskStyles = {
  container: {
    padding: "30px",
    height: "calc(100vh - 48px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "@media (max-width: 1023px)": {
      minHeight: "calc(100vh - 164px)",
      marginTop: "60px",
      padding: "20px",
      height: "unset",
    },
  },
  breadcrumb: {
    display: "none",
    "@media (max-width: 1023px)": {
      display: "block",
      paddingBottom: "16px",
      margin: "0px 0px 0px 0px",
      borderBottom: "0.5px solid var(--primary-grey)",
    },
  },
  breadcrumbtext: {
    fontSize: "var(--font-size-14)",
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "40px",
    rowGap: "35px",
    "@media screen and (max-width: 1023px)": {
      padding: "25px 0px 30px 0px",
      rowGap: "30px",
    },
    "@media (max-width: 1023px)": {
      padding: "25px 0px 30px 0px",
      rowGap: "30px",
    },
  },
  dropDown: {
    minWidth: "360px",
    "@media (max-width: 1023px)": {
      minWidth: "328px",
      width: "100%",
      margin: "0 auto",
    },
  },
  textArea: {
    width: "100%",
  },
  timePicker: {
    width: "360px",
  },
  buttonsContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "end",
    marginTop: "20px",
    "@media (max-width: 1023px)": {
      position: "unset",
      flexWrap: "wrap",
      justifyContent: "space-between",
      rowGap: "25px",
      width: "100%",
      marginTop: "unset",
    },
  },
  dateContainer: {
    "@media (max-width: 1023px)": {
      margin: "0 auto",
      width: "100%",
    },
  },
  timeContainer: {
    "@media (max-width: 1023px)": {
      margin: "0 auto",
      width: "100%",
    },
  },
  resetButton: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
      color: "var(--primary-black)",
    },
    "@media (max-width: 1023px)": {
      padding: "9px 56.5px",
    },
  },
  cancelButton: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
      color: "var(--primary-black)",
    },
    "@media (max-width: 1023px)": {
      padding: "9px 53px",
    },
  },
  saveChangesButton: {
    boxShadow: "none",
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
  resetButtons: {
    display: "flex",
    gap: "20px",
    "@media (max-width: 1023px)": {
      justifyContent: "space-between",
      width: "100%",
    },
  },
  saveChangesButtonContainer: {
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
};
