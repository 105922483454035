import React from "react";
import { Props } from "./Props";

const YoutubeIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill="none"
    >
      <rect
        x="5.33984"
        y="4.01358"
        width="15"
        height="11"
        rx="5.5"
        fill={fill}
      />
      <path
        d="M3.15815 17.3636L21.34 17.3636C22.3442 17.3636 23.1582 16.5496 23.1582 15.5454L23.1582 2.81809C23.1582 1.81394 22.3442 0.999907 21.34 0.999907L3.15815 0.999907C2.15399 0.999907 1.33996 1.81394 1.33996 2.81809L1.33996 15.5454C1.33996 16.5496 2.15399 17.3636 3.15815 17.3636Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8821 21.0002H8.60938"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.8823 9.18278L10.4277 5.5464V12.8191L15.8823 9.18278Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default YoutubeIcon;
