export const AgencyHeaderStyles = {
  headerContainer: {
    width: "100%",
    position: "fixed",
    zIndex: "1",
    padding: "10px 30px",
    alignItems: "center",
    transition: "transform s ease",
    top: "0",
    height: "68px",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "var(--white)",
    boxShadow: "0px 4px 14px 0px #00000014",
    "@media (max-width: 1023px)": {
      height: "60px",
    },
  },
  menuIcon: {
    width: "40px",
    height: "auto",
    cursor: "pointer",
  },
  simsLogo: {
    cursor: "pointer",
  },
  displayPicture: {
    width: "32px",
    height: "auto",
    cursor: "pointer",
  },
  notificationIcon: {
    width: "36px",
    height: "auto",
    cursor: "pointer",
  },
  logo: {},
  headerItems: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  searchIcon: {
    width: "27px",
    height: "29px",
  },
  drawer:{
    width: "391px",
    padding: "0px",
  }
};
