export const TasksStyles = {
  container: {
    width: "100%",
    border: "1px solid var(--shaded-cyan)",
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: "10px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  headingContainer: {
    gap: "0px",
    lineHeight: "22px",
  },
  priorityContainer: {
    gap: "4px",
  },
  headingText: {
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
  },
  subHeadingText: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
  },
  headingNameText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "22px",
    color: "var(--support-white)",
  },
  subHeadingNameText: {
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
    color: "var(--support-white)",
    fontWeight: "var(--font-weight-300)",
  },
  headingtaskText: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
  },
  subHeadingTaskText: {
    fontSize: "var(--font-size-14)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    width: "90%",
  },
  innerContainer: {
    display: "flex",
    columnGap: "45px",
    rowGap: "20px",
    flexWrap: "wrap",
    paddingTop: "16px",
    paddingBottom: "10px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  menuContainer: {
    paddingTop: "11px",
    display: "flex",
    justifyContent: "flex-end",
    "> img": {
      paddingRight: "10px",
    },
  },
  statusContainer: {
    gap: "4px",
    ">img": {
      height: "16px",
      width: "16px",
    },
  },
  statusImage: {
    ">img": {
      width: "16px",
      height: "16px",
    },
  },
  subHeadingPriorityText: {
    color: "var(--support-white)",
    fontSize: "var(--font-size-14)",
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
};
