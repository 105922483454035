export const MonthlyCommissionTableStyles = {
  headingsContainer: {
    display: "flex",
    gap: "90px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "25px 30px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
  },
  imageContainer: {
    position: "absolute",
    right: "0",
    display: "none",
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "self-end",
    border: "1px solid var(--shaded-cyan)",
    padding: "30px",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
  },
  heading: {
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "27px",
  },
  subHeading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
  },
  image: {
    "> img": {
      width: "20px",
      height: "20px",
    },
  },
  imageAndTextContainer: {
    gap: "4px",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 346px)",
  },
};
