import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import BasicTabs from "../../common/BasicTabs/BasicTabs";
import Tables from "../../common/Tables/Tables";
import { PolicyListingListStyles } from "./PolicyListingListStyles";
import styled from "@emotion/styled";
interface customerDetailsProps {
  image: string;
  customerName: string;
  customerNumber: string;
}
interface policyDetailsProps {
  policyNumber: string;
  policyTypeNumber: string;
}
export interface PolicyListingDataProps {
  customerDetails: customerDetailsProps;
  policyDetails: policyDetailsProps;
  customerStatus: string;
  customerPremium: string;
  customerTerm: string;
  customItems: string;
  customerCarrier: string;
}
export interface CustomerListingProps {
  policyDetails: policyDetailsProps;
  customerStatus: string;
  customerDetails: customerDetailsProps;
  customerPremium: string;
  customerTerm: string;
  customItems: string;
  customerCarrier: string;
}

interface CustomerPoliciesProps {
  activeTab: string;
  policyListingHeaderData: string[];
  customerListingHeaderData: string[];
  policyListingData: PolicyListingDataProps[];
  customerPoliciesData: CustomerListingProps[];
  policiesThatNeedAttention: PolicyListingDataProps[];
  customerPoliciesThatNeedAttention: CustomerListingProps[];
}
const TabList = styled("div")({
  "& .MuiTabs-flexContainer": {
    "& .MuiTypography-root": {
      fontFamily: "var(--font-playfair-display)",
      fontSize: "var(--font-size-20)",
    },
  },
});
const PolicyListingList: React.FC<CustomerPoliciesProps> = ({
  policyListingHeaderData,
  policyListingData,
  customerPoliciesData,
  customerListingHeaderData,
  activeTab,
  policiesThatNeedAttention,
  customerPoliciesThatNeedAttention,
}) => {
  const policyListingTabsData = [
    {
      label: "Policies That Need Attention",
      content: (
        <Tables
          type="policyListing"
          primary={false}
          data={policiesThatNeedAttention}
          headerData={policyListingHeaderData}
          additionaltableContainer={PolicyListingListStyles.tableContainer}
        />
      ),
    },
    {
      label: "All Policies",
      content: (
        <Tables
          headerData={policyListingHeaderData}
          type="policyListing"
          primary={false}
          data={policyListingData}
          additionaltableContainer={PolicyListingListStyles.tableContainer}
        />
      ),
    },
  ];
  const customerPoliciesTabsData = [
    {
      label: "Policies That Need Attention",
      content: (
        <Tables
          type="policyListing"
          primary={false}
          data={customerPoliciesThatNeedAttention}
          headerData={customerListingHeaderData}
          additionaltableContainer={PolicyListingListStyles.tableContainer}
        />
      ),
    },
    {
      label: "All Policies",
      content: (
        <Tables
          type="policyListing"
          primary={false}
          data={customerPoliciesData}
          headerData={customerListingHeaderData}
          additionaltableContainer={PolicyListingListStyles.tableContainer}
        />
      ),
    },
  ];

  const [tabsData, setTabsData] = useState(policyListingTabsData);
  useEffect(() => {
    setTabsData(
      activeTab === "tab1" ? policyListingTabsData : customerPoliciesTabsData
    );
  }, [activeTab]);
  return (
    <>
      <Box sx={PolicyListingListStyles.container}>
        <TabList>
          <BasicTabs tabs={tabsData} />
        </TabList>
      </Box>
    </>
  );
};

export default PolicyListingList;
