export const InteractionHistoryCardStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    padding: "16px 20px 16px 20px",
    gap: "12px",
    height: "79px",
    width: "86.23%",
    borderRadius: "8px",
    border: "1px solid var(--shaded-cyan)",
    boxShadow: "2px 2px 4px 0px #00000014",
    backgroundColor: "var(--white)",
    marginBottom: "32px",
    "@media (max-width: 1023px)": {
      padding: "12px",
      width: "87vw",
      height: "152px",
    },
  },

  imageContainer: {
    display: "flex",
  },
  image: {
    "@media (max-width: 1023px)": {
      width: "24px",
      height: "18px",
      "> img": {
        width: "100%",
      },
    },
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media screen and (max-width: 1023px)": {
      flexDirection: "column",
      gap: "12px",
    },
  },

  idTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },

  idContainer: {
    display: "flex",
    gap: "5px",
    "@media screen and (max-width: 1023px)": {
      flexDirection: "column",
      gap: "0px",
    },
  },

  idNumber: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },

  symbol: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var( --font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },

  interaction: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var( --font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },

  text: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var( --font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    textAlign: "left",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      marginLeft: "-35px",
    },
  },

  statusContainer: {
    display: "flex",
    alignItems: "end",
    gap: "6px",
    "@media screen and (max-width: 1023px)": {
      marginLeft: "-35px",
    },
  },

  profileContainer: {
    display: "flex",
    alignItems: "end",
  },

  agentname: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var( --font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    textAlign: "left",
    marginBottom: "3px",
  },
};
