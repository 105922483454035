import { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import ModalComponent from "../../common/ModalComponent/ModalComponent";
import MyTasksCalendarCard from "../MyTasksCalendarCard/MyTasksCalendarCard";
import {
  Box,
  MenuItem,
  Popover,
  Select,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MyTasksCalendarStyles } from "./MyTasksCalendarStyles";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dropDownIcon from "../../assets/svgs/dropdown-carret.svg";
import ReactDOM from "react-dom";

interface ClientDetailsPrps {
  clientName: string;
  clientEmail: string;
  clientPhone: string;
}
interface MeetingData {
  timeStamp: {
    date: string;
    time: string;
  };
  task: string;
  clientDetails: ClientDetailsPrps;
  policy: string;
  status: string;
  priority: string;
}

interface Meeting {
  meetingId: string;
  title: string;
  startTime: string;
  endTime: string;
  taskDetails: MeetingData;
}

interface EventData {
  id: string;
  date: string;
  isMeetingsAvailable: boolean;
  meetings: Meeting[];
}

interface FullCalendarComponentProps {
  dayWiseEvents: EventData[];
}

const StyledSelect = styled(Select)({
  width: "100px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiSelect-select": {
    border: "none",
  },
});

const PopOverContainer = styled(Popover)({
  position: "absolute",
  "& .MuiPopover-paper": {
    top: "222px !important",
    left: "30px !important",
    "@media (max-width: 1023px)": {
      left: "13px !important",
      top: "241px !important",
    },
  },
});

const FullCalendarComponent: React.FC<FullCalendarComponentProps> = ({
  dayWiseEvents,
}) => {
  const calendarRef = useRef<any>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<MeetingData | undefined>(
    undefined
  );
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  const [currentView, setCurrentView] = useState<string>("dayGridMonth");
  const [modalMeetings, setModalMeetings] = useState<Meeting[]>([]);
  const [modalDate, setModalDate] = useState<Date>(new Date());
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [, setSelectedDate] = useState<Date | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [, setIsRotated] = useState(false);

  const handleEventClick = (id: string) => {
    const event = dayWiseEvents.flatMap((event) =>
      event.meetings.find((meeting) => meeting.meetingId === id)
    );
    const validEvent = event.find((e) => e !== undefined);
    setModalData(validEvent?.taskDetails);

    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setDetailsModalOpen(false);
  };

  const handleDateSelect = (date: any) => {
    const newDate = date.$d;
    setSelectedDate(newDate);

    const calendarApi = calendarRef.current?.getApi();

    if (calendarApi) {
      const newViewType = calendarApi.view.type;
      calendarApi.changeView(newViewType, newDate);
      setCurrentView(newViewType);
      setPopoverOpen(false);
    }
  };

  const handleDatesSet = (dateInfo: any) => {
    const viewName = dateInfo.view.type;
    setCurrentView(viewName);
    if (viewName === "dayGridMonth") {
      const newDate = new Date(dateInfo.end);
      newDate.setMonth(newDate.getMonth() - 1);
      setCurrentDate(newDate);
    } else {
      setCurrentDate(new Date(dateInfo.start));
    }
  };

  const handleViewChange = (view: string) => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.changeView(view);
    }
    setCurrentView(view);
  };
  const addTimeLabel = () => {
    const timeGridHeader = document.querySelector(".fc-timegrid-axis-frame");
    if (timeGridHeader && !timeGridHeader.innerHTML.includes("Time")) {
      timeGridHeader.innerHTML = `
        <span style="font-weight: var(--font-weight-400); font-size: var(--font-size-16); color: var(--primary-black);font-family:'var(--font-noto-sans)'">Time</span>
      `;
    }
  };
  const handleTitleClick = () => {
    setPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setPopoverOpen(false);
  };
  const eventData = dayWiseEvents
    .map((event) => {
      if (!event.isMeetingsAvailable) return undefined;
      return {
        id: event.id,
        title: event.date,
        start: event.meetings[0].startTime,
        extendedProps: {
          meetings: event.meetings,
        },
      };
    })
    .filter((event) => event !== undefined) as [];
  const standardMeetingCount = 3;
  const eventWeekData = dayWiseEvents
    .flatMap((event) => {
      if (!event.isMeetingsAvailable) return [];
      return event.meetings.map((meeting) => ({
        id: meeting.meetingId,
        title: event.date,
        start: meeting.startTime,
        extendedProps: {
          meetings: [meeting],
        },
      }));
    })
    .filter((event) => event !== undefined) as [];
  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();

    const injectCustomTitle = () => {
      const toolbarElement = document.querySelector(".fc-toolbar-chunk");

      if (toolbarElement) {
        ReactDOM.render(
          <Box
            sx={MyTasksCalendarStyles.monthTitle}
            onClick={() => setIsRotated((prev) => !prev)}
          >
            <Typography sx={MyTasksCalendarStyles.toolbarHeader}>
              {currentDate.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}
            </Typography>
            <Box
              component="img"
              src={dropDownIcon}
              alt="Custom Icon"
              sx={{
                ...MyTasksCalendarStyles.dropDownIcon,
                transform: popoverOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </Box>,
          toolbarElement
        );
      }
    };

    const addTitleClickListener = () => {
      const titleElement = document.querySelector(".fc-toolbar-chunk");
      if (titleElement) {
        (titleElement as HTMLElement).style.cursor = "pointer";
        titleElement.addEventListener("click", handleTitleClick);
      }
    };

    const removeTitleClickListener = () => {
      const titleElement = document.querySelector(".fc-toolbar-chunk");
      if (titleElement) {
        titleElement.removeEventListener("click", handleTitleClick);
      }
    };

    if (calendarApi) {
      injectCustomTitle();
      addTitleClickListener();
    }

    return () => {
      removeTitleClickListener();
    };
  }, [calendarRef, currentView, currentDate, popoverOpen]);

  const getSxForView = () => {
    return currentView === "timeGridWeek" || currentView === "dayGridMonth"
      ? {
          "& .fc-theme-standard th": {
            width: "231px",
          },

          "& .fc-scroller": {
            position: "relative",
          },
          ...(currentView === "dayGridMonth" && {
            "& .fc table": {
              borderCollapse: "separate !important",
            },
          }),
        }
      : {
          "& .fc-theme-standard th": {
            width: "unset",
          },
        };
  };

  const getCurrentTime = () => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}:00`;
  };

  const handleScrollToCurrentTime = () => {
    setTimeout(() => {
      const calendarApi = calendarRef.current?.getApi();
      if (calendarApi && calendarRef.current) {
        const calendarEl = calendarRef.current.el;

        if (calendarEl) {
          const scrollContainer = calendarEl.querySelector(".fc-scroller");
          const currentTimeElement = calendarEl.querySelector(
            ".fc-now-indicator-line"
          );
          if (scrollContainer && currentTimeElement) {
            const scrollOffset = 50;
            const currentTimePosition = currentTimeElement.offsetTop;
            scrollContainer.scrollTop = currentTimePosition - scrollOffset;
          }
        }
      }
    }, 0);
  };

  return (
    <Box sx={MyTasksCalendarStyles.calendarContainer}>
      {isSmallScreen && (
        <Box sx={MyTasksCalendarStyles.mobileHeaderContainer}>
          <Box
            sx={MyTasksCalendarStyles.mobileDropdown}
            onClick={handleTitleClick}
          >
            <Typography>
              {currentDate.toLocaleDateString("en-US", {
                month: "long",
                year: "numeric",
              })}{" "}
            </Typography>
            <Box
              component="img"
              src={dropDownIcon}
              alt="Custom Icon"
              sx={{
                ...MyTasksCalendarStyles.dropDownIcon,
                transform: popoverOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </Box>
          <Box sx={MyTasksCalendarStyles.selectContainer}>
            <StyledSelect
              value={currentView}
              onChange={(e) => handleViewChange(e.target.value as string)}
            >
              <MenuItem value="dayGridMonth">Month</MenuItem>
              <MenuItem value="timeGridWeek">Week</MenuItem>
              <MenuItem value="timeGridDay">Day</MenuItem>
            </StyledSelect>
          </Box>
        </Box>
      )}
      <PopOverContainer
        open={popoverOpen}
        anchorEl={calendarRef.current}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ padding: 2 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar onChange={handleDateSelect} />
          </LocalizationProvider>
        </Box>
      </PopOverContainer>
      <Box sx={isSmallScreen ? {} : getSxForView()}>
        <FullCalendar
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            momentPlugin,
          ]}
          initialView={currentView}
          nowIndicator={true}
          events={currentView === "dayGridMonth" ? eventData : eventWeekData}
          scrollTime={getCurrentTime()}
          datesSet={handleDatesSet}
          headerToolbar={
            !isSmallScreen && {
              center: "today prev,next",
              left: "",
              right: isSmallScreen
                ? undefined
                : "timeGridDay,timeGridWeek,dayGridMonth",
            }
          }
          views={{
            timeGridWeek: {
              scrollTime: getCurrentTime(),
              dayHeaderFormat: isSmallScreen
                ? { weekday: "narrow", day: "2-digit" }
                : { weekday: "long", day: "2-digit" },
            },
            timeGridDay: {
              scrollTime: getCurrentTime(),
              dayHeaderFormat: isSmallScreen
                ? { weekday: "short", day: "2-digit" }
                : { weekday: "long", day: "2-digit" },
            },
          }}
          dayHeaderFormat={
            isSmallScreen ? { weekday: "narrow" } : { weekday: "long" }
          }
          viewDidMount={(info) => {
            if (
              info.view.type === "timeGridDay" ||
              info.view.type === "timeGridWeek"
            ) {
              addTimeLabel();
              if (typeof handleScrollToCurrentTime === "function") {
                handleScrollToCurrentTime();
              }
            }
          }}
          eventContent={(info: any) => {
            const { event } = info;
            const meetings = event.extendedProps.meetings;
            if (!meetings || meetings.length === 0) return null;

            const renderTime = (time: string) => {
              const date = new Date(time);
              return date.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              });
            };
            const handleFullDetails = (date: Date, allMeetings: Meeting[]) => {
              setModalMeetings(allMeetings);
              setModalDate(date);
              setDetailsModalOpen(true);
            };
            const topMeetings = meetings.slice(0, standardMeetingCount);
            const remainingCount = meetings.length - standardMeetingCount;
            return currentView.toLowerCase() === "daygridmonth" ? (
              <Box sx={MyTasksCalendarStyles.totalContainer}>
                <Box sx={MyTasksCalendarStyles.totalMeetingsMonthContainer}>
                  {topMeetings.map((meeting: Meeting) => (
                    <Box
                      key={meeting.meetingId}
                      onClick={() => handleEventClick(meeting.meetingId)}
                      sx={
                        meeting.taskDetails.priority.toLowerCase() === "low"
                          ? MyTasksCalendarStyles.lowPriorityContainer
                          : meeting.taskDetails.priority.toLowerCase() ===
                            "medium"
                          ? MyTasksCalendarStyles.mediumPriorityContainer
                          : MyTasksCalendarStyles.highPriorityContainer
                      }
                    >
                      <Box sx={MyTasksCalendarStyles.innerTextContainer}>
                        <Box
                          sx={
                            meeting.taskDetails.priority.toLowerCase() === "low"
                              ? MyTasksCalendarStyles.lowDot
                              : meeting.taskDetails.priority.toLowerCase() ===
                                "medium"
                              ? MyTasksCalendarStyles.mediumDot
                              : MyTasksCalendarStyles.highDot
                          }
                        ></Box>
                        <Typography sx={MyTasksCalendarStyles.withWhom}>
                          {meeting.taskDetails.clientDetails.clientName.split(" ")[0]}{" "}
                        </Typography>
                      </Box>
                      <Typography sx={MyTasksCalendarStyles.formattedTime}>
                        {renderTime(meeting.startTime)}{" "}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {remainingCount > 0 &&
                  (isSmallScreen ? (
                    <Typography
                      onClick={() => handleFullDetails(event.start, meetings)}
                      sx={MyTasksCalendarStyles.remaining}
                    >
                      ...
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => handleFullDetails(event.start, meetings)}
                      sx={MyTasksCalendarStyles.remaining}
                    >
                      +{remainingCount} More
                    </Typography>
                  ))}
              </Box>
            ) : currentView.toLowerCase() === "timegridweek" ? (
              <Box>
                {meetings.map((meeting: Meeting) => (
                  <Box
                    onClick={() => handleEventClick(meeting.meetingId)}
                    key={meeting.meetingId}
                    sx={
                      meeting.taskDetails.priority.toLowerCase() === "low"
                        ? MyTasksCalendarStyles.lowPriorityWeekContainer
                        : meeting.taskDetails.priority.toLowerCase() ===
                          "medium"
                        ? MyTasksCalendarStyles.mediumPriorityWeekContainer
                        : MyTasksCalendarStyles.highPriorityWeekContainer
                    }
                  >
                    <Box sx={MyTasksCalendarStyles.innerWeekTextContainer}>
                      <Typography sx={MyTasksCalendarStyles.withWhom}>
                        {meeting.taskDetails.clientDetails.clientName.split(" ")[0]}{" "}
                      </Typography>

                      <Box sx={MyTasksCalendarStyles.timeContainer}>
                        <Typography sx={MyTasksCalendarStyles.formattedTime}>
                          {renderTime(meeting.startTime)}
                        </Typography>
                        <Typography sx={MyTasksCalendarStyles.formattedTime}>
                          -
                        </Typography>
                        <Typography sx={MyTasksCalendarStyles.formattedTime}>
                          {renderTime(meeting.endTime)}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography sx={MyTasksCalendarStyles.formattedTime}>
                      {meeting.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box>
                {meetings.map((meeting: Meeting) => (
                  <Box
                    onClick={() => handleEventClick(meeting.meetingId)}
                    key={meeting.meetingId}
                    sx={
                      meeting.taskDetails.priority.toLowerCase() === "low"
                        ? MyTasksCalendarStyles.lowPriorityWeekContainer
                        : meeting.taskDetails.priority.toLowerCase() ===
                          "medium"
                        ? MyTasksCalendarStyles.mediumPriorityWeekContainer
                        : MyTasksCalendarStyles.highPriorityWeekContainer
                    }
                  >
                    {isSmallScreen ? (
                      <Typography sx={MyTasksCalendarStyles.meetingTitle}>
                        {meeting.title}
                      </Typography>
                    ) : (
                      <>
                        <Box sx={MyTasksCalendarStyles.innerWeekTextContainer}>
                          <Typography sx={MyTasksCalendarStyles.withWhom}>
                            {meeting.taskDetails.clientDetails.clientName.split(" ")[0]}{" "}
                          </Typography>
                          <Box sx={MyTasksCalendarStyles.timeContainer}>
                            <Typography
                              sx={MyTasksCalendarStyles.formattedTime}
                            >
                              {renderTime(meeting.startTime)}
                            </Typography>
                            <Typography
                              sx={MyTasksCalendarStyles.formattedTime}
                            >
                              -
                            </Typography>
                            <Typography
                              sx={MyTasksCalendarStyles.formattedTime}
                            >
                              {renderTime(meeting.endTime)}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography sx={MyTasksCalendarStyles.formattedTime}>
                          {meeting.title}
                        </Typography>
                      </>
                    )}
                  </Box>
                ))}
              </Box>
            );
          }}
          slotLabelFormat={{
            hour: "2-digit",
            hour12: true,
          }}
        />
      </Box>
      <ModalComponent
        heading={"Task Details"}
        open={modalOpen}
        handleOpen={handleModalClose}
        additionalClassContainer={MyTasksCalendarStyles.modalContainer}
        headingContainerAdditionalClass={
          MyTasksCalendarStyles.modalHeadingContainer
        }
      >
        {modalData ? (
          <MyTasksCalendarCard data={modalData} />
        ) : (
          <Typography>No Data Available</Typography>
        )}
      </ModalComponent>
      <ModalComponent
        heading={new Date(modalDate).toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })}
        open={detailsModalOpen}
        handleOpen={handleModalClose}
        additionalClassContainer={MyTasksCalendarStyles.modalMeetingsContainer}
      >
        <Box sx={MyTasksCalendarStyles.totalMeetingsContainer}>
          {modalMeetings.map((meeting) => (
            <Box
              key={meeting.meetingId}
              sx={
                meeting.taskDetails.priority.toLowerCase() === "low"
                  ? MyTasksCalendarStyles.lowPriorityModalContainer
                  : meeting.taskDetails.priority.toLowerCase() === "medium"
                  ? MyTasksCalendarStyles.mediumPriorityModalContainer
                  : MyTasksCalendarStyles.highPriorityModalContainer
              }
            >
              <Box sx={MyTasksCalendarStyles.innerTextContainer}>
                <Box
                  sx={
                    meeting.taskDetails.priority.toLowerCase() === "low"
                      ? MyTasksCalendarStyles.lowModalDot
                      : meeting.taskDetails.priority.toLowerCase() === "medium"
                      ? MyTasksCalendarStyles.mediumModalDot
                      : MyTasksCalendarStyles.highModalDot
                  }
                ></Box>
                <Typography sx={MyTasksCalendarStyles.withWhom}>
                  {meeting.taskDetails.clientDetails.clientName.split(" ")[0]}{" "}
                </Typography>
              </Box>
              <Typography sx={MyTasksCalendarStyles.formattedModalTime}>
                {new Date(meeting.endTime).toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "numeric",
                  hour12: true,
                })}
              </Typography>
            </Box>
          ))}
        </Box>
      </ModalComponent>
    </Box>
  );
};
export default FullCalendarComponent;
