import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import LoginStyles from "../../../Login/LoginStyles";
import { resetCommonSlice } from "../../../store/common/CommonSlice";
import LoginWithNameAndMobile from "../../../Login/LoginWithNameAndMobile/LoginWithNameAndMobile";
import LoginWithEmailOrMobile from "../../../Login/LoginWithEmailOrMobile/LoginWithEmailOrMobile";
import { RootState } from "../../../store/store";
import { IFeature } from "../../../models/admin/features/features";

interface IProps {
  logoSrc: string | File;
  loginBannerSrc: string | File;
  isFunctionable: boolean;
}

const CustomerLoginPreview: React.FC<IProps> = ({
  logoSrc,
  loginBannerSrc,
  isFunctionable,
}) => {
  const dispatch = useAppDispatch();
  const { features } = useAppSelector((state: RootState) => state.features);

  const [loginType, setLoginType] = useState<string>("");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [bannerUrl, setBannerUrl] = useState<string>("");

  const handleLoginWithChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginType((event.target as HTMLInputElement).value);
      dispatch(resetCommonSlice());
    },
    [dispatch]
  );

  const loginOptions: IFeature[] = useMemo(
    () =>
      features.filter(
        (feature) =>
          (feature.featureCode === "name_mobile_login" && feature.selected) ||
          (feature.featureCode === "email_mobile_login" && feature.selected) ||
          (feature.featureCode === "google_login" && feature.selected)
      ),
    [features]
  );

  const getLoginForm = useCallback(() => {
    switch (loginType) {
      case "name_mobile_login":
        return <LoginWithNameAndMobile isFunctionable={isFunctionable} />;
      case "email_mobile_login":
        return <LoginWithEmailOrMobile isFunctionable={isFunctionable} />;
      default:
        return null;
    }
  }, [loginType, isFunctionable]);

  const handleImageSrc = useCallback((src: string | File) => {
    if (src instanceof File) {
      return URL.createObjectURL(src);
    } else if (typeof src === "string") {
      if (src.startsWith("data:image") || src.startsWith("http")) {
        return src;
      } else {
        return `data:image/png;base64,${src}`;
      }
    }
    return "";
  }, []);

  useEffect(() => {
    setLogoUrl(handleImageSrc(logoSrc));
    return () => {
      if (logoSrc instanceof File) {
        URL.revokeObjectURL(logoUrl);
      }
    };
  }, [logoSrc, handleImageSrc, logoUrl]);

  useEffect(() => {
    setBannerUrl(handleImageSrc(loginBannerSrc));
    return () => {
      if (loginBannerSrc instanceof File) {
        URL.revokeObjectURL(bannerUrl);
      }
    };
  }, [loginBannerSrc, handleImageSrc, bannerUrl]);

  useEffect(() => {
    if (features.length > 0) {
      const defaultLoginType = features.find(
        (feature) =>
          (feature.featureCode === "name_mobile_login" && feature.selected) ||
          (feature.featureCode === "email_mobile_login" && feature.selected) ||
          (feature.featureCode === "google_login" && feature.selected)
      )?.featureCode;

      setLoginType(defaultLoginType || "");
    }
  }, [features]);

  return (
    <Box sx={LoginStyles.login}>
      <Box sx={LoginStyles.loginImage}>
        {bannerUrl && <img src={bannerUrl} alt="Login Banner Preview" />}
      </Box>
      <Box style={LoginStyles.loginCardContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "80%",
          }}
        >
          <Box sx={LoginStyles.logo}>
            {logoUrl && <img src={logoUrl} alt="Logo Preview" />}
          </Box>
          <Typography sx={LoginStyles.loginMessage}>
            Welcome, please login to continue
          </Typography>
          <FormControl>
            {loginOptions.length > 0 && (
              <FormLabel
                sx={{
                  fontFamily: "Playfair Display",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  color: "rgba(56, 61, 78, 1)",
                }}
              >
                Login With
              </FormLabel>
            )}
            <RadioGroup
              defaultValue="emailOrMobile"
              value={loginType}
              sx={{ display: "flex", flexDirection: "row" }}
              onChange={handleLoginWithChange}
            >
              {loginOptions.map((option) => (
                <FormControlLabel
                  key={option.featureCode}
                  value={option.featureCode}
                  control={<Radio />}
                  label={option.featureLabel}
                  disabled={!isFunctionable}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {getLoginForm()}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerLoginPreview;
