export const PerformingPoliciesStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "5px",
    flexWrap: "wrap",
    gap: "12px",
    paddingLeft: "2px",
    "@media screen and (max-width: 1023px)": {
      gap: "0px",
      marginTop: "15px",
    },
  },
};
