const FileAClaimStyles = {
  container: {
    padding: " 0px 30px 0px",
    maxWidth: "875px",
    height: "100%",
    background: " #FFFFFF",
    "@media(min-width:600px) and (max-width:1023px)": {
      maxWidth: "540px",
    },
    "@media(min-width:360px) and (max-width:599px)": {
      padding: "0px",
      width: "auto",
      background: "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 89px)",
    margin: "0px auto",

    justifyContent: "space-between",
  },
  imageDisplayerBox: {
    display: "flex",
    gap: "16px",
    height: "80px",
    border: "1px solid #C4C4C4",
    backgroundColor: "#FFFFFF",
    padding: "20px 16px",
    marginTop: "14px",
    borderRadius: "4px",
    alignItems: "center",
    img: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
    },
    "& img:first-child": {
      height: "40px",
      width: "40px",
    },
  },
  documentImage: {
    img: {
      width: "40px",
    },
  },
  uplaodBox: {
    textAlign: "left",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "flex-start",
    marginLeft: "-197px",
    height: "44.4px",
    "@media(max-width:1023px)": {
      marginLeft: "-136px",
    },
    img: {
      paddingLeft: "14px",
    },
  },
  fileNames: {
    width: "192px",
    display: "flex",
    gap: "0px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  fileUploadButton: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
    border: "1px solid #C4C4C4",
    textTransform: "none",
    color: "#000000",
    "&:hover": {
      color: "#000000",
    },
  },
  formsContainer: {
    padding: "5px 20px 20px 0px",
    display: "flex",
    width: "780px",
    gap: "40px",
    flexWrap: "wrap",
    marginTop: "30px",

    "@media (min-width:360px) and (max-width:599px)": {
      padding: " 20px 30px",
      width: "auto",
      justifyContent: "center",
    },
    "@media (min-width:600px) and (max-width:1023px)": {
      padding: " 20px 30px",
      width: "auto",
      justifyContent: "center",
    },
  },
  policyLabel: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  heading: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #3F3F3F",
    position: "sticky",
    top: 0,
    background: "#FBFDFC",
    zIndex: 99,
    paddingTop: "30px",
    img: {
      cursor: "pointer",
    },
    "@media (min-width:360px) and (max-width:599px)": {
      paddingBottom: "0px",
      height: "60px",
      alignItems: "center",
      padding: "19px 20px 19px 20px",
      border: "1px solid #D8D8D8",
      boxShadow: "0px 6px 30px 0px #00000014",
    },
  },
  headingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
  },
  submitButton: {
    borderRadius: "40px",
    padding: "16px 12px",
    width: "150px",
    height: "40px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    letterSpacing: "0.5px",
    textAlign: "left",
    textTransform: "none",
    backgroundColor: "#3F3F3F",
    boxShadow: "0px 6px 10px 0px #3F3F3F4D",
  },
  cancelButton: {
    borderRadius: "40px",
    border: "1px solid #3F3F3F",
    padding: "16px 12px",
    color: "#3F3F3F",
    width: "150px",
    height: "40px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    letterSpacing: "0.5px",
    textAlign: "left",
    textTransform: "none",
  },
  fileNameText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "28px",
    overflowWrap: "break-word",
    width: "100%",
    maxWidth: "130px",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  completedText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20.02px",
    letterSpacing: "0.17000000178813934px",
    textAlign: "left",
    color: "#00000099",
  },
  inputField: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  textFields: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    label: {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textAlign: "left",
    },
  },
  fileSizeText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20.02px",
    letterSpacing: "0.17000000178813934px",
    textAlign: "left",
    width: "auto",
    color: "#00000099",
  },
  fileSizeBox: {
    display: "flex",
    gap: "8px",
    width: "132px",
    alignItems: "center",
    img: {
      width: "5px",
      height: "5px",
    },
  },
  buttons: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    paddingBottom: "30px",
    "@media(min-width:360px) and (max-width:1023px)": {
      justifyContent: "flex-end",
      padding: "0px",
      marginTop: "48px",
      paddingBottom: "30px",
    },
    "@media(min-width:360px) and (max-width:599px)": {
      marginRight: "30px",
    },
    "@media(min-width:360px) and (max-width:430px)": {
      marginRight: "0px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};
export default FileAClaimStyles;
