export const PerformingPoliciesCardStyles = {
  innerContainer: {
    display: "flex",
    gap: "13px",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    paddingLeft: "13px",
    paddingRight: "18px",
    border: "1px solid var(--primary-grey)",
    borderRadius: "8px",
    height: "60px",
    "@media only screen and (max-width: 1023px)": {
      height: "unset",
      border: "none",
      borderBottom: "1px solid var(--primary-grey)",
      borderRadius: "0px",
      padding: "8px",
    },
  },
  mobileContainer: {
    borderTop: "none",
    borderBottom: "1px solid var(--primary-grey)",
    borderLeft: "none",
    borderRight: "none",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px",
  },
  description: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    maxWidth: "100%",
    "@media only screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.34px",
    },
    "@media only screen and (max-width: 599px)": {
      maxWidth: "83%",
    },
  },
  number: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    maxWidth: "100%",
    "@media only screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.34px",
    },
  },
};
