import React from "react";
import { Props } from "./Props";

const ForgotPasswordIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 110 110"
      fill="none"
    >
      <path
        d="M32 66.25V47.9167C32 41.8388 34.4144 36.0098 38.7121 31.7121C43.0098 27.4144 48.8388 25 54.9167 25C60.9945 25 66.8235 27.4144 71.1212 31.7121C75.4189 36.0098 77.8333 41.8388 77.8333 47.9167V66.25"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.3255 16.4092C43.4825 12.2522 49.1207 9.91675 54.9997 9.91675C60.8786 9.91675 66.5168 12.2522 70.6739 16.4092C74.8309 20.5663 77.1663 26.2045 77.1663 32.0834V49.6667H32.833V32.0834C32.833 26.2045 35.1684 20.5663 39.3255 16.4092ZM31.333 49.6667V32.0834C31.333 25.8066 33.8265 19.7869 38.2648 15.3486C42.7032 10.9102 48.7229 8.41675 54.9997 8.41675C61.2765 8.41675 67.2962 10.9102 71.7345 15.3486C76.1729 19.7869 78.6663 25.8066 78.6663 32.0834V49.6667H87.0833C92.5602 49.6667 97 54.1066 97 59.5834V91.6667C97 97.1436 92.5602 101.583 87.0833 101.583H22.9167C17.4398 101.583 13 97.1436 13 91.6667V59.5834C13 54.1066 17.4398 49.6667 22.9167 49.6667H31.333ZM14.5 59.5834C14.5 54.935 18.2683 51.1667 22.9167 51.1667H87.0833C91.7317 51.1667 95.5 54.935 95.5 59.5834V91.6667C95.5 96.3151 91.7317 100.083 87.0833 100.083H22.9167C18.2683 100.083 14.5 96.3151 14.5 91.6667V59.5834Z"
        fill="black"
      />
    </svg>
  );
};

export default ForgotPasswordIcon;
