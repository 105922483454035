export const PieChartStyles = {
  container: {
    display: "flex",
    justifyContent: "start",
    marginTop: "-165px",
    "@media (max-width: 1023px)": {
      justifyContent: "center",
      marginTop: "-180px",
    },
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  tooltipContainer: {
    border: "1px solid var(--shaded-orange)",
    padding: "20px",
    borderRadius: "4px",
    backgroundColor: "var(--white)",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    color: "var(--ternary-black)",
    fontSize: "var(--font-size-14)",
    minWidth: "250px",
    height: "268px",
    overflowY: "scroll",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    display: "flex",
    flexDirection: "column",
    gap: "17px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  legendText: {
    fontSize: "var(--font-size-12)",
    color: "var(--black)",
    fontFamily: "var(--font-noto-sans)",
  },
  legendContainer: {
    width: 9,
    height: 9,
    borderRadius: "50%",
    marginRight: "8px",
  },
};
