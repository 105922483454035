import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import { Box } from "@mui/material";
import { AccordianStyles } from "./AccordianStyles";
import styled from "@emotion/styled";

interface AccordionExpandDefaultProps {
  header: string;
  body: React.ReactNode;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  isFirst?: boolean;
  isLast?: boolean;
}
const AccordianSummaryContent = styled(AccordionSummary)<{
  expanded: boolean;
  isLast?: boolean;
}>(({ expanded, isLast = false }) => ({
  padding: "15px 0px",
  margin: "0px 16px",
  minHeight: "unset",
  borderBottom: !expanded && !isLast ? "1px solid var(--primary-grey)" : "0px",
  "& .MuiAccordionSummary-content": {
    margin: "0px 0px",
    display: "flex",
    gap: "8px !important",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "0px",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#000000 !important",
  },
  "& .Mui-checked":{
    color: "var(--primary-black)",
  },
}));
const AccordianDetailsContent = styled(AccordionDetails)<{
  expanded: boolean;
  isLast?: boolean;
}>(({ expanded, isLast = false }) => ({
  padding: "0px 0px 16px 0px",
  margin: "0px 16px",
  borderBottom: expanded && !isLast ? "1px solid var(--primary-grey)" : "0px",
}));
const Accordian = ({
  header,
  body,
  expanded,
  onChange,
  isFirst,
  isLast = false,
}: AccordionExpandDefaultProps) => {
  return (
    <Box>
      <Accordion
        sx={{
          ...(isFirst
            ? AccordianStyles.firstAccordian
            : AccordianStyles.accordian),
        }}
        expanded={expanded}
        onChange={onChange}
      >
        <AccordianSummaryContent
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          expanded={expanded}
          isLast={isLast}
        >
          <Radio sx={AccordianStyles.radioButton} checked={expanded} />
          <Typography sx={AccordianStyles.headingText}>{header}</Typography>
        </AccordianSummaryContent>
        <AccordianDetailsContent expanded={expanded} isLast={isLast}>
          <Typography>{body}</Typography>
        </AccordianDetailsContent>
      </Accordion>
    </Box>
  );
};

export default Accordian;
