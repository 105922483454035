import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import DocumentPreview from "../DocumentPreview/DocumentPreview";
import DocumentProperties from "../DocumentProperties/DocumentProperties";
import { DocumentManagementStyles } from "./DocumenManagementDetailsStyles";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import infoIcon from "../../assets/svgs/info-icon-document.svg";
import { DETAILS, DOCUMENT_DETAILS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { documentPropertiesData } from "../../constants/DocumentPropertiesData";
import { documentPreviewValues } from "../../constants/DocumentPreviewValues";
import { template } from "../../constants/DocumentDetailsPdfTemplate";
const DocumenManagementDetails: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const navigate = useNavigate();
  const handleClick = () => {
    window.scrollTo({
      top: 0, 
    });
    navigate("/properties");
  };

  return (
    <Box sx={DocumentManagementStyles.container}>
      <Box sx={DocumentManagementStyles.breadCrumbContainer}>
        <DashboardBreadCrumb
          variant={"primary"}
          text={DOCUMENT_DETAILS}
          handleClick={() => navigate(-1)}
          additionalContentClass={DocumentManagementStyles.breadCrumb}
        />
        {isSmallScreen && (
          <ImageAndText
          additionalClassName={DocumentManagementStyles.detailsContainer}
            onClick={handleClick}
            image={infoIcon}
            text={DETAILS}
          />
        )}
      </Box>
      {isSmallScreen ? (
        <DocumentPreview template={template} values={documentPreviewValues} />
      ) : (
        <Box sx={DocumentManagementStyles.innerContainer}>
          <DocumentPreview template={template} values={documentPreviewValues} />
          <DocumentProperties documentPropertiesData={documentPropertiesData} />
        </Box>
      )}
    </Box>
  );
};

export default DocumenManagementDetails;
