const LoginWithNameAndMobileStyles = {
  loginWithNameAndMobileForm: {
    width: "100%",
    display: "grid",
    gap: "20px",
  },
  loginBtn: {
    // width: "100%",
    // borderRadius: "40px",
    // background: "rgba(63, 63, 63, 1)",
    // boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
    // fontFamily: "Lato",
    // fontSize: "14px",
    // fontWeight: "400",
    // padding: "10px",
  },
};

export default LoginWithNameAndMobileStyles;
