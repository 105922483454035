export const template = `This agreement between [Company name] and [Contact name] is hereby entered into on this date: [Date here].

Purpose: [Company name] and [Contact name] will be entering into discussions involving [Company name]'s development and operation, which require [Company name] to disclose confidential information to [Contact name] on an ongoing basis. This agreement’s purpose is to ensure confidentiality and to prevent [Contact name] from disclosing such confidential information.

[Company name] and [Contact name] hereby agree to the following:

A: Throughout this agreement, the term “confidential information” will refer to any information related to [Company name] that is generally not known to any third parties and is owned by [Company name]. Confidential information includes, but is not limited to:
- Product strategies
- Finances
- Contract discussions
- Trade secrets
- Investing strategies
- Marketing strategies
- Business plans
- Other business affairs of [Company name]

B: [Contact name] understands that any intentional or unintentional disclosure of confidential information may be detrimental to [Company name] and accordingly agrees:
- Not to disclose discussions with [Company name] except when required by law
- Not to use any confidential information in any way other than for authorized purposes
- To maintain confidentiality at all times unless expressly written permission is received from [Company name]
- That upon dissolution, all records related to [Company name] will be returned

C: The terms outlined above shall not apply when specific information:
- Becomes part of public domain
- Can be proven to be already owned by [Company name] before signing the agreement
- [Company name] expressly gives written permission of disclosure
- Is court-ordered for disclosure

D: Should a court order for disclosure of confidential information be received by [Contact name], [Company name] must be notified immediately in order to either seek protective orders or waive this agreement according to the circumstances involved.

E: Both parties understand that confidential information is owned by [Company name] and any disclosure of such to [Contact name] does not convey any manner of license, title, or right to this information and may not appropriate any portion of it for unauthorized uses.

F: Termination of the relationship between [Company name] and [Contact name] does not relieve [Contact name] and associates of their obligations outlined in this agreement, including the return of all records.

G: This agreement shall be governed under the jurisdiction of the state of [State name].`;
