export const PaymentScheduleStyles = {
  upcomingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    gap: "20px",
    "@media (max-width: 1023px)": {
      gap: "12px",
    },
  },
  line: {
    fontSize: "var(--font-size-24)",
    color: "var(--primary-grey)",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "var(--white)",
    padding: " 20px 30px",
    borderRadius: "10px",
    alignItems: "flex-start",
    position: "relative",
    "@media (max-width: 1200px)": {
      flexDirection: "column",
      gap: "10px",
      padding: "0px",
      backgroundColor: "transparent",
    },
  },
  upComingEventsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  upcomingText: {
    fontSize: "var(--font-size-20)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "25px 30px",
    maxWidth: "1366px",
    margin: "0 auto",
    marginTop: "68px",
    "@media (max-width: 1023px)": {
      padding: "20px 20px",
      marginTop: "60px",
      gap: "30px",
    },
  },
  totalContainer: {
    "@media (max-width: 1200px)": {
      display: "flex",
      gap: "20px",
      flexWrap: "wrap",
    },
  },
  headingText: {
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  imageAndTextContainer: {
    gap: "4px",
  },
};
