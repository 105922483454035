export const ClaimTrackingCardStyles = {
  trackingContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 16px",
    gap: "6px",
    borderRadius: "8px",
    border: "1px solid var(--shaded-cyan)",
    boxShadow: "2px 2px 4px 0px #00000014",
    backgroundColor: "var(--white)",
    "@media (max-width: 1023px)": {
      padding: "12px",
      backgroundColor: "var(--white)",
      width: "77%",
      maxWidth: "390px",
    },
  },

  title: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },

  subTitle: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "16.07px",
    textAlign: "left",
  },
};
