import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";
import {
  IGetUiSettingsResponse,
  IPostUiSettingsRequestBody,
} from "../../../models/admin/uiSettings/uiSettings";
import {
  API_GET_UI_SETTINGS,
  API_POST_UI_SETTINGS,
} from "../../../Apis/ApiEndPoints";

export const postUiSettings = createAsyncThunk(
  "uiSettings/postUiSettings",
  async (formData: IPostUiSettingsRequestBody, { dispatch }) => {
    const resposne = await CustomAction(
      Api.post(API_POST_UI_SETTINGS, formData),
      dispatch,
      "uiSettings/postUiSettings"
    );
    return resposne;
  }
);

export const getUiSettings = createAsyncThunk(
  "uiSettings/getUiSettings",
  async (_, { dispatch }) => {
    const response: IGetUiSettingsResponse = await CustomAction(
      Api.get(API_GET_UI_SETTINGS),
      dispatch,
      "uiSettings/getUiSettings"
    );
    return response;
  }
);
