import React from "react";
import { Props } from "./Props";

const LocationIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <ellipse
        cx="11.6706"
        cy="16.427"
        rx="6.67061"
        ry="6.37804"
        fill={fill}
      />
      <path
        d="M6.84375 11.2975C6.84375 6.45966 10.9521 2.5 16.0677 2.5C21.1834 2.5 25.2917 6.45966 25.2917 11.2975C25.2917 12.9405 24.8199 14.478 23.997 15.7947L16.0526 26.4345L11.6363 20.9559C11.463 20.741 11.1482 20.7072 10.9332 20.8805C10.7182 21.0538 10.6844 21.3685 10.8577 21.5835L16.0828 28.0655L24.8226 16.3605L24.8333 16.3434C25.7586 14.8703 26.2917 13.1433 26.2917 11.2975C26.2917 5.86558 21.6929 1.5 16.0677 1.5C10.4425 1.5 5.84375 5.86558 5.84375 11.2975C5.84375 13.1415 6.37577 14.8668 7.29935 16.339C8.02868 17.5548 9.41 18.9695 11.3473 19.0058C12.4135 19.0259 13.2743 18.2789 13.6316 17.3742C14.0009 16.4396 14.0029 15.7298 13.7844 15.1432C13.6203 14.7024 13.3266 14.3509 13.1177 14.1009C13.0706 14.0445 13.0278 13.9932 12.9917 13.9473L12.9388 13.9888C12.707 13.7591 12.5002 13.4987 12.3253 13.2089C11.1733 11.3011 11.8499 8.8437 13.8694 7.72885C15.889 6.61399 18.4531 7.28243 19.6051 9.19029C20.7571 11.0982 20.0805 13.5555 18.061 14.6704C17.8192 14.8038 17.7389 15.1037 17.8817 15.3401C18.0244 15.5764 18.3361 15.6599 18.5779 15.5264C21.0623 14.1549 21.9289 11.1056 20.4806 8.70701C19.0322 6.30838 15.837 5.50131 13.3525 6.87281C10.868 8.2443 10.0014 11.2936 11.4498 13.6922C11.6857 14.0829 11.9689 14.4322 12.2885 14.737C12.328 14.7746 12.3721 14.8049 12.4189 14.8279C12.6051 15.0535 12.7518 15.2359 12.8473 15.4922C12.9596 15.7937 13.0036 16.2424 12.7016 17.0068C12.4617 17.6142 11.9252 18.0165 11.366 18.006C9.93312 17.9791 8.80938 16.9141 8.15466 15.8206L8.14898 15.8116C7.31961 14.4912 6.84375 12.9475 6.84375 11.2975Z"
        fill="black"
      />
    </svg>
  );
};

export default LocationIcon;
