import { Box, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import PolicyListingCard from "../../common/PolicyListingCard/PolicyListingCard";
import { PolicyListingResponsiveStyles } from "./PolicyListingResponsiveStyles";
import { FILTER, TAB1 } from "../../constants";
import DropDown from "../../common/DropDown/DropDown";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import {
  CustomerListingProps,
  PolicyListingDataProps,
} from "../PolicyListingList/PolicyListingList";
interface PolicyListingResponsiveDataProps {
  activeTab: string;
  policyListingData: PolicyListingDataProps[];
  policiesThatNeedAttention: PolicyListingDataProps[];
  customerPoliciesData: CustomerListingProps[];
  customerPoliciesThatNeedAttention: CustomerListingProps[];
}
const PolicyListingResponsive: React.FC<PolicyListingResponsiveDataProps> = ({
  policyListingData,
  customerPoliciesData,
  policiesThatNeedAttention,
  activeTab,
  customerPoliciesThatNeedAttention,
}) => {
  const [tabsData, setTabsData] = useState(policiesThatNeedAttention);
  const [value, setValue] = useState("1");

  const handleDropDownChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (activeTab === TAB1) {
      if (value == "1") {
        setTabsData(policiesThatNeedAttention);
      } else {
        setTabsData(policyListingData);
      }
    } else {
      if (value == "2") {
        setTabsData(customerPoliciesThatNeedAttention);
      } else {
        setTabsData(customerPoliciesData);
      }
    }
  }, [activeTab, value]);
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  return (
    <>
      <Box sx={PolicyListingResponsiveStyles.policyListingContainer}>
        <Box sx={PolicyListingResponsiveStyles.header}>
          <Box sx={PolicyListingResponsiveStyles.dropDownContainer}>
            <DropDown
              data={[
                { name: "Policies That Need Attention", value: 1 },
                { name: "All Policies", value: 2 },
              ]}
              placeholder={"Policies That Need Attention"}
              selectedValue={value}
              onChange={handleDropDownChange}
            />
            <ImageAndText
              onhandleClick={handleClick}
              image={filterIcon}
              text={FILTER}
            />
          </Box>
        </Box>
        <Box sx={PolicyListingResponsiveStyles.container}>
          {tabsData.map((policy, index) => {
            const {
              customerDetails,
              policyDetails,
              customerStatus,
              customerPremium,
              customerTerm,
              customItems,
              customerCarrier,
            } = policy;

            if (typeof customerDetails === "string" || !policyDetails) {
              return null;
            }

            return (
              <PolicyListingCard
                key={index}
                image={customerDetails?.image || ""}
                customerName={customerDetails?.customerName || ""}
                customerNumber={customerDetails?.customerNumber || ""}
                policyNumber={policyDetails?.policyNumber || ""}
                policyTypeNumber={policyDetails?.policyTypeNumber || ""}
                customerStatus={customerStatus}
                customerPremium={customerPremium}
                customerTerm={customerTerm}
                customItems={customItems}
                customerCarrier={customerCarrier}
              />
            );
          })}
        </Box>
      </Box>

      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default PolicyListingResponsive;
