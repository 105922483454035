export const DashboardDraggableCardStyles = {
  dashboardCard: {
    border: "1px solid #c9e1ec",
    borderRadius: "10px",
    padding: "25px",
    paddingTop: "22px",
    backgroundColor: "var(--white)",
    width: "48.8%",
    maxWidth: "638px",
    height: "415px",
    position: "relative",
    cursor: "pointer",
    transition: "box-shadow 0.2s ease",
    "@media screen and (min-width: 1200px) and (max-width: 1310px)": {
      width: "48.5%",
    },
    "@media screen and (min-width: 1023px) and (max-width:1199px)": {
      width: "638px",
    },
    "@media screen and (max-width: 1023px)": {
      width: "100%",
      minWidth: "68vw",
      height: "418px",
      padding: "12px 17px 0px 15px",
    },
    "@media screen and (max-width: 900px)": {
      minWidth: "88vw",
      maxWidth: "88vw",
    },
    "@media screen and (max-width: 599px)": {
      width: "100%",
      minWidth: "89vw",
      maxWidth: "89vw",
      minHeight: "356px",
      height: "380px",
    },
    "@media screen and (min-width: 1023px)": {
      "&:hover": {
        boxShadow: "4px 4px 10px 0px #0000001f",
        border: "1px solid var(--primary-blue)",
      },
    },
  },
  hoveredElementsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    background:
      "linear-gradient(90.03deg, rgba(251, 253, 252, 0.5) 2.21%, #E5F6FE 99.95%)",
    borderRadius: "0px 0px 10px 10px",
  },
  hoverOptions: {
    display: "flex",
    gap: "25px",
    marginRight: "2px",
  },
  dashboardCardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    "@media screen and (max-width: 1023px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "15px",
    },
    "@media screen and (max-width: 599px)": {
      marginBottom: "0px",
    },
  },
  dashboardCardHeading: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "26.66px",
    textAlign: "left",
    paddingLeft: "4px",
    color: "var(--support-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
      paddingLeft: "0px",
    },
  },
  dashboardCardFooter: {
    position: "absolute",
    bottom: "0px",
    left: "0",
    width: "100%",
    borderTop: "1px solid var(--primary-grey)",
  },
  hoverElement: {
    color: "var(--primary-blue)",
    cursor: "pointer",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "600",
    lineHeight: "22px",
    textAlign: "left",
  },
  "dashboardCard:hover .dashboardCardFooter": {
    background:
      "linear-gradient(90.03deg, rgba(251, 253, 252, 0.5) 2.21%, #e5f6fe 99.95%)",
  },
  dragButton: {
    width: "15.9px",
    height: "25.35px",
    marginLeft: "15px",
  },
  eyeButton: {
    width: "24px",
    height: "24px",
  },
  downloadButton: {
    width: "24px",
    height: "24px",
  },
  maximizeButton: {
    width: "24px",
    height: "24px",
  },
  dashboardCardContent: {},
  imageTextContainer: {
    padding: "10px",
    paddingLeft: "18px",
    "@media screen and (max-width: 1023px)": {
      padding: "15px",
      paddingLeft: "13px",
    },
  },
  imageAndTextContainer: {
    gap: "10px",
  },
  bulbIcon: {
    width: "22px",
    height: "28px",
    marginLeft: "10px",
    "@media screen and (max-width: 599px)": {
      width: "18.5px",
      height: "24px",
      marginLeft: "6px",
    },
  },
  text: {
    color: "var(--primary-black)",
    "@media screen and (max-width: 599px)": {
      lineHeight: "20px",
      width: "90%",
    },
  },
  dropdownClass: {
    "@media screen and (max-width: 1023px)": {
      gap: "10px",
      width: "100%",
      paddingTop: "11px",
    },
  },
  dashboardHeaderMenu: {
    "@media screen and (max-width: 1023px)": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "0.5px solid var(--primary-grey)",
      paddingBottom: "8px",
    },
  },
  menuIcon: {
    ">img ": {
      display: "none",
    },
    "@media screen and (max-width: 1023px)": {
      ">img": {
        display: "block",
        paddingRight: "6px",
        paddingTop: "3px",
      },
    },
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  headingText: {
    fontSize: "var(--font-size-18)",
    padding: "16px 0px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  drawerText: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    borderBottom: "0.5px solid var(--primary-grey)",
    paddingBottom: "12px",
    paddingTop: "10px",
  },
  viewTasks: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    borderBottom: "0.5px solid var(--primary-grey)",
    paddingBottom: "12px",
  },
  drawerhideText: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    paddingTop: "12px",
  },
  textsContainer: {
    padding: "12px 24px 16px 24px",
  },
};
