import React from "react";
import { Props } from "./Props";

const LatestNewsCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 26"
      fill="none"
    >
      <path
        d="M11.276 12.285C11.276 12.285 12.2077 16.77 8.54602 16.77C4.88437 16.77 1.91602 14.762 1.91602 12.285C1.91602 9.80804 4.88437 7.80003 8.54602 7.80003C12.2077 7.80003 11.276 12.285 11.276 12.285Z"
        fill={fill}
      />
      <path
        d="M11.1465 7.27953V22.9666C11.1464 23.1377 11.1041 23.306 11.0234 23.4568C10.9426 23.6076 10.826 23.7362 10.6837 23.8311L9.25368 24.784C9.11498 24.8765 8.95607 24.9343 8.79036 24.9525C8.62465 24.9707 8.45699 24.9488 8.30151 24.8887C8.14603 24.8285 8.00729 24.7319 7.89698 24.6069C7.78667 24.4819 7.70803 24.3322 7.66769 24.1704L5.94648 17.6795"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.8511 18.3397C12.6118 17.7379 11.1461 17.6797 11.1461 17.6797H5.94609C4.56697 17.6797 3.24433 17.1319 2.26914 16.1567C1.29395 15.1815 0.746095 13.8589 0.746095 12.4797C0.746095 11.1006 1.29395 9.77796 2.26914 8.80278C3.24433 7.82759 4.56697 7.27973 5.94609 7.27973H11.1461C11.1461 7.27973 18.2246 6.99893 25.0366 1.28543C25.1881 1.158 25.3728 1.07645 25.569 1.05035C25.7652 1.02426 25.9648 1.0547 26.1443 1.13811C26.3238 1.22152 26.4758 1.35444 26.5824 1.52123C26.689 1.68803 26.7458 1.88178 26.7461 2.07973V22.8797C26.746 23.0778 26.6894 23.2717 26.5829 23.4387C26.4764 23.6057 26.3245 23.7388 26.1449 23.8224C25.9653 23.9059 25.7657 23.9365 25.5693 23.9105C25.373 23.8844 25.1882 23.8028 25.0366 23.6753C23.5348 22.4154 22.0201 21.4197 20.5711 20.6328"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LatestNewsCardIcon;
