import { Api } from "../../..";
import {
  API_GET_FEATURES,
  API_POST_FEATURES,
} from "../../../Apis/ApiEndPoints";
import {
  IFeature,
  IGetAllFeaturesResponse,
} from "../../../models/admin/features/features";
import { CustomAction } from "./../../customAction";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFeatures = createAsyncThunk(
  "features/getFeatures",
  async (_, { dispatch }) => {
    const response: IGetAllFeaturesResponse = await CustomAction(
      Api.get(`${API_GET_FEATURES}`),
      dispatch,
      "features/getFeatures"
    );
    return response;
  }
);

export const postFeatures = createAsyncThunk(
  "features/postFeatures",
  async (requestBody: IFeature[], { dispatch }) => {
    await CustomAction(
      Api.post(API_POST_FEATURES, requestBody),
      dispatch,
      "features/postFeatures"
    );
  }
);
