import { Box } from "@mui/material";
import React from "react";

import { CommissionStatementsListStyles } from "./CommissionStatementsListStyles";
import Tables from "../../common/Tables/Tables";
import { COMMISSION_STATEMENTS } from "../../constants";

export interface CommissionStatementsDataProps {
  commissionDate: string;
  commissionStatemnet: string;
  commissionAmount: string;
  commissionStatus: string;
}
interface CommissionStatementsListProps {
  comissionStatementsData: CommissionStatementsDataProps[];
  headerData: string[];
}
const CommissionStatementsList: React.FC<CommissionStatementsListProps> = ({
  comissionStatementsData,
  headerData,
}) => {
  return (
    <>
      <Box>
        <Tables
          primary={true}
          type={COMMISSION_STATEMENTS}
          data={comissionStatementsData}
          additionaltableContainer={
            CommissionStatementsListStyles.tableContainer
          }
          headerData={headerData}
        />
      </Box>
    </>
  );
};

export default CommissionStatementsList;
