export const DocumentPropertiesStyles = {
  container: {
    padding: "20px",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    backgroundColor: "var(--white)",
    minHeight: "calc(100vh - 215px)",
    width: "362px",
    "@media screen and (max-width: 1023px)": {
      width: "100%",
      height: "402px",
    },
  },
  headingText: {
    fontSize: "var(--font-size-20)",
    color: "var(--light-black)",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  totalContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      marginTop: "60px",
      padding: "24px 20px",
    },
  },
  breadCrumb: {
    display: "none",
    "@media screen and (max-width: 1023px)": {
      display: "flex",
    },
  },
  pdfHeading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  pdfSubheading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  heading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  subheading: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "16.34px",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
    },
  },
  statusHeading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    textTransform: "uppercase",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  pdfCardContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingBottom: "24px",
    borderBottom: "1px solid var(--primary-grey)",
    "@media screen and (max-width: 1023px)": {
      paddingBottom: "20px",
    },
  },
  innerContainer: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "28px",
    columnGap: "40px",
    padding: "25px 0px",
    paddingBottom: "26px",
    borderBottom: "1px solid var(--primary-grey)",
    "@media screen and (max-width: 1023px)": {
      padding: "20px 0px",
      columnGap: "55px",
    },
  },
  lowerContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "80px",
    padding: "25px 0px",
    borderBottom: "1px solid var(--primary-grey)",
    "@media screen and (max-width: 1023px)": {
      padding: "20px 0px",
      paddingBottom: "18px",
      gap: "88px",
    },
  },
  statusContainer: {
    paddingTop: "25px",
    "@media screen and (max-width: 1023px)": {
      paddingTop: "17px",
    },
  },
  headingSubHeadingHeaderContainer: {
    gap: "2px",
  },
  headingSubHeadingContainer: {
    gap: "4px",
  },
  breadCrumbText: {
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
};
