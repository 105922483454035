import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { DocumentFileCardStyles } from "./DocumentFileCardStyles";
import {
  ARCHIVE_FILE,
  ARE_YOU_SURE_ARCHIVE,
  CANCEL,
  DOC_IMAGE,
  FILE_LOWER,
  LAST_MODIFIED,
  MENU_ICON,
} from "../../constants";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import CustomTooltip from "../Tooltip/Tooltip";
import HeadingText from "../HeadingText/HeadingText";
import Drawers from "../Drawers/Drawers";
import ModalComponent from "../ModalComponent/ModalComponent";
import CustomButton from "../CustomButton/CustomButton";
import ImageAndText from "../ImageAndText/ImageAndText";
import CustomIcon from "../CustomIcon/CustomIcon";
import { useNavigate } from "react-router-dom";
interface DocumentFileCardProps {
  fileName: string;
  lastModified: string;
  docImage: string;
}

const DocumentFileCard: React.FC<DocumentFileCardProps> = ({
  fileName,
  lastModified,
  docImage,
}) => {
  const drawerOptions = [
    { value: "View", key: "view" },
    { value: "Edit", key: "edit" },
    { value: "Move", key: "move" },
    { value: "Archive", key: "archive" },
  ];
  const navigate = useNavigate();
  const [hovered, setHovered] = React.useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [toolTipOpen, setToolTipOpen] = React.useState(false);
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDrawerOptionClick = (key: string) => {
    if (key === "archive") {
      setArchiveOpen(!archiveOpen);
    }
    if (key === "view") {
      navigate("/document-details");
      window.scrollTo({
        top: 0,
      });
    }
  };
  const handleArchiveClick = () => {
    setArchiveOpen(!archiveOpen);
  };
  const handleToolTipItem = (key: string) => {
    if (key === "archive") {
      setArchiveOpen(!archiveOpen);
      setHovered(false);
    }
    if (key === "view") {
      navigate("/document-details");
    }
    setToolTipOpen(!toolTipOpen);
  };
  const handleToolTip = () => {
    setToolTipOpen(!toolTipOpen);
  };
  const handleNavigationClick = () => {
    navigate("/document-details");
    setToolTipOpen(false);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Box
        onDoubleClick={handleNavigationClick}
        sx={
          !isSmallScreen
            ? DocumentFileCardStyles.container
            : DocumentFileCardStyles.hoverContainer
        }
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => {
          setHovered(false);
          setToolTipOpen(false);
        }}
      >
        <Box sx={DocumentFileCardStyles.imageContainer}>
          <img src={docImage} alt={DOC_IMAGE} />
        </Box>
        <Box sx={DocumentFileCardStyles.footer}>
          {!isSmallScreen && (
            <Box sx={DocumentFileCardStyles.documentIcon}>
              <CustomIcon iconName="Document" width="24px" height="24px" />
            </Box>
          )}

          <Box sx={DocumentFileCardStyles.fileContainer}>
            <Typography sx={DocumentFileCardStyles.fileName}>
              {fileName}
            </Typography>
            <Typography sx={DocumentFileCardStyles.lastModified}>
              {!isSmallScreen ? LAST_MODIFIED : ""} {lastModified}
            </Typography>
          </Box>
          {!isSmallScreen && hovered && (
            <>
              {!toolTipOpen && (
                <Box
                  onClick={handleToolTip}
                  sx={DocumentFileCardStyles.menuIconStyles}
                >
                  <img src={menuIcon} alt={MENU_ICON} />{" "}
                </Box>
              )}
              {toolTipOpen && (
                <Box
                  sx={DocumentFileCardStyles.menuIcon}
                  onClick={(e) => e.stopPropagation()}
                >
                  {}
                  <CustomTooltip
                    menuIcon={menuIcon}
                    MENU_ICON={MENU_ICON}
                    myTasksTooltip={drawerOptions}
                    placement="bottom"
                    handleTooltipItem={handleToolTipItem}
                    open={toolTipOpen}
                    handleTooltip={handleToolTip}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        <>
          {isSmallScreen && (
            <Box
              onClick={handleMenuOpen}
              sx={DocumentFileCardStyles.menuIconStyles}
            >
              <img src={menuIcon} alt={MENU_ICON} />{" "}
            </Box>
          )}
        </>
      </Box>
      <>
        <Drawers
          type="secondary"
          anchor="bottom"
          open={menuOpen}
          additionalClass={DocumentFileCardStyles.drawer}
          toggleDrawer={handleMenuOpen}
        >
          <Box>
            <HeadingText
              additionalHeadingClass={DocumentFileCardStyles.drawertextHeading}
              heading={"Menu"}
            />
            <Box sx={DocumentFileCardStyles.optionsContainer}>
              {drawerOptions.map((option, index) => (
                <Typography
                  sx={
                    index === drawerOptions.length - 1
                      ? DocumentFileCardStyles.lastText
                      : DocumentFileCardStyles.drawerTexts
                  }
                  onClick={() => handleDrawerOptionClick(option.key)}
                >
                  {option.value}
                </Typography>
              ))}
            </Box>
          </Box>
        </Drawers>
      </>
      <ModalComponent
        headingAdditionalClass={DocumentFileCardStyles.modalHeading}
        heading={ARCHIVE_FILE}
        open={archiveOpen}
        handleOpen={handleArchiveClick}
        additionalClassContainer={DocumentFileCardStyles.modalContainer}
        headingContainerAdditionalClass={
          DocumentFileCardStyles.modalHeadingContainer
        }
      >
        <Box sx={DocumentFileCardStyles.contentContainer}>
          <ImageAndText
            image={<CustomIcon iconName="Archive" />}
            text={ARE_YOU_SURE_ARCHIVE + `"${fileName}"` + FILE_LOWER}
            additionalClassText={DocumentFileCardStyles.imageText}
          />
          <Box sx={DocumentFileCardStyles.buttonsContainer}>
            <CustomButton
              text={"Confirm"}
              additionalClassName={DocumentFileCardStyles.cancelButton}
            />
            <CustomButton
              onHandleClick={handleArchiveClick}
              text={CANCEL}
              type="outlined"
              additionalClassName={DocumentFileCardStyles.cancelButton}
            />
          </Box>
        </Box>
      </ModalComponent>
    </>
  );
};

export default DocumentFileCard;
