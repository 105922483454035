export const customerDetailsCardData = {
  policyDetails: [
    { heading: "03", subheading: "Policies" },
    { heading: "02", subheading: "Active" },
    { heading: "00", subheading: "Expired" },
    { heading: "01", subheading: "Pending Renewal" },
  ],
  claimDetails: [
    { heading: "01", subheading: "Claims" },
    { heading: "00", subheading: "Open" },
    { heading: "01", subheading: "Closed" },
  ],
  contactDetails: [
    { image: "phoneIcon", text: "(+1) - 9013877656" },
    { image: "mailIcon", text: "Chrisstan@hotmail.com" },
  ],
  interactionDetail: {
    text: " 23 Jul ‘24",
  },
};
