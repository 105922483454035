import stringToColor from "./NameAvatarStringToColor";

const stringAvatar = (name: string) => {
  return {
    sx: {
      fontSize: "var(--font-size-14)",
      fontFamily: "var(--font-noto-sans)",
      border: "1px solid var(--primary-grey)",
      bgcolor: stringToColor(name),
      width: "34px",
      height: "34px",
      "@media screen and (max-width: 1023px)": {
        width: "38px",
        height: "38px",
      },
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};
export default stringAvatar;
