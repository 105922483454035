export const DropDownStyles = {
  container: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    height: "27px",
  },
  text: {
    color: "var(--secondary-gray)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    paddingBottom: "0px",
  },
  select: {
    marginTop: "0px !important",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    "& .MuiSvgIcon-root": {
      color: "var(--black)",
    },
    "&:hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "0px solid",
    },
  },
  menuItem: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
  },
};
