import React from "react";
import { Props } from "./Props";

const FolderCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 36 28"
      fill="none"
    >
      <path
        d="M1.52674 23.3697L0.503906 9.56148H35.3328L34.31 23.3697C34.1166 25.9807 31.9418 28.0003 29.3237 28.0003H6.51307C3.89495 28.0003 1.72014 25.9807 1.52674 23.3697Z"
        fill={fill}
      />
      <path
        d="M1.1875 0.810662V7.51182H35.3335V5.42038C35.3335 4.68962 34.7411 4.09722 34.0104 4.09722H16.2117C15.0802 4.09722 14.163 3.17996 14.163 2.04846C14.163 0.916957 13.2457 -0.000305176 12.1142 -0.000305176H1.99847C1.55058 -0.000305176 1.1875 0.362777 1.1875 0.810662Z"
        fill={fill}
      />
    </svg>
  );
};

export default FolderCardIcon;
