export const MyTasksResponsiveStyles = {
  switchContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  dropDownContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tasksCardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  lowerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "19px",
  },
  container: {
   
  },
  headingText: {
    fontSize: "var(--font-size-16)",
  },
  dropDown: {
    marginLeft: "0px",
  },
};
