export const options = [
  "Road Side Assistance",
  "Policy Change",
  "Cancel Policy",
];

export const mobileOptions = [
  "Contact",
  "Pay",
  "Download ID",
  "Road Side Assistance",
  "Policy Change",
  "Cancel Policy",
];
