import { useState } from "react";
import DatePickerComponent from "../../common/DatePickerComponent/DatePickerComponent";
import MultilineTextFields from "../../common/MultilineTextFields/MultilineTextFields";
import FormDropDown from "../../common/FormDropDown/FormDropDown";
import { Box } from "@mui/material";
import { NewTaskStyles } from "./NewTaskStyles";
import Button from "../../common/CustomButton/CustomButton";
import TimePickerComponent from "../../common/TimePickerComponent/TimePickerComponent";
import { formDropDown } from "../../constants/FormDropDown";
import {
  CANCEL,
  DUE_DATE,
  NEW_TASK,
  NOTES,
  POLICY,
  RESET,
  SAVE_CHANGES,
  TASK_TYPE,
  TIME,
} from "../../constants";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import { useNavigate } from "react-router-dom";
interface NewTaskProps {
  onCancelClick?: () => void;
}

const NewTask: React.FC<NewTaskProps> = ({ onCancelClick }) => {
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const handleChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <Box sx={NewTaskStyles.container}>
      <Box>
        <Box sx={NewTaskStyles.breadcrumb}>
          <DashboardBreadCrumb
            handleClick={() => navigate(-1)}
            variant={"primary"}
            text={NEW_TASK}
            additionalContentClass={NewTaskStyles.breadcrumbtext}
          />
        </Box>
        <Box sx={NewTaskStyles.formContainer}>
          <Box sx={NewTaskStyles.dropDown}>
            <MultilineTextFields variant="primary" label={TASK_TYPE} />
          </Box>
          <Box sx={NewTaskStyles.dropDown}>
            <FormDropDown
              label={POLICY}
              labelId="my-dropdown"
              value={selectedValue}
              options={formDropDown}
              onChange={handleChange}
            />
          </Box>
          <Box sx={NewTaskStyles.dateContainer}>
            <DatePickerComponent label={DUE_DATE} type="secondary" />
          </Box>
          <Box sx={NewTaskStyles.timeContainer}>
            <TimePickerComponent
              label={TIME}
              additionalClassName={NewTaskStyles.timePicker}
            />
          </Box>
          <Box sx={NewTaskStyles.textArea}>
            <MultilineTextFields variant="secondary" rows={6} label={NOTES} />
          </Box>
        </Box>
      </Box>
      <Box sx={NewTaskStyles.buttonsContainer}>
        <Box sx={NewTaskStyles.resetButtons}>
          <Button
            text={RESET}
            type="outlined"
            additionalClassName={NewTaskStyles.resetButton}
          />
          <Button
            onClick={onCancelClick}
            text={CANCEL}
            type="outlined"
            additionalClassName={NewTaskStyles.cancelButton}
          />
        </Box>
        <Button
          text={SAVE_CHANGES}
          type="filled"
          additionalContainerClassName={
            NewTaskStyles.saveChangesButtonContainer
          }
          additionalClassName={NewTaskStyles.saveChangesButton}
        />
      </Box>
    </Box>
  );
};

export default NewTask;
