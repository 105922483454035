export const ClaimHistoryStyles = {
  headingText: {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-500)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  claimCards: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  claimsFields: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
};
