import * as React from "react";
import Drawer from "@mui/material/Drawer";
import close from "../../assets/svgs/cross-icon.svg";
import { DrawerStyles } from "./DrawersStyles";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { CLOSE } from "../../constants/Constants";
import styled from "@emotion/styled";

interface ReusableDrawerProps {
  open: boolean;
  toggleDrawer: () => void;
  anchor?: "left" | "right" | "top" | "bottom";
  heading?: string;
  additionalClass?: SxProps<Theme>;
  children?: React.ReactNode;
  type?: "primary" | "secondary";
}
const StyledDrawer = styled(Drawer)<{ type: string }>(({ type }) => ({
  "& .MuiDrawer-paper": {
    borderTopLeftRadius: type === "secondary" ? "12px !important" : "0px",
    borderTopRightRadius: type === "secondary" ? "12px !important" : "0px",
  },
}));
const Drawers: React.FC<ReusableDrawerProps> = ({
  open,
  toggleDrawer,
  anchor = "right",
  heading,
  children,
  additionalClass,
  type = "primary",
}) => {
  return (
    <StyledDrawer
      type={type}
      anchor={anchor}
      open={open}
      onClose={toggleDrawer}
    >
      {type !== "secondary" && (
        <Box sx={DrawerStyles.headingContainer}>
          <Typography sx={DrawerStyles.heading}>{heading}</Typography>
          <Box sx={DrawerStyles.close}>
            <img src={close} alt={CLOSE} onClick={toggleDrawer} />
          </Box>
        </Box>
      )}

      <Box sx={Object.assign({}, DrawerStyles.children, additionalClass)}>
        {children}
      </Box>
    </StyledDrawer>
  );
};

export default Drawers;
