import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IDriverPolicyChangeRequestParams,
  IPolicyChangeResponse,
  IVehiclePolicyChangeRequestParams,
} from "../../../models/user/PolicyChange/PolicyChange";
import { API_POLICY_CHANGE } from "../../../Apis/ApiEndPoints";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";

export const PostAddVehicle = createAsyncThunk(
  "policyChange/PostAddVehicle",
  async (requestParams: IVehiclePolicyChangeRequestParams, { dispatch }) => {
    const response: IPolicyChangeResponse = await CustomAction(
      Api.post(`${API_POLICY_CHANGE}/addVehicle`, requestParams),
      dispatch,
      "policyChange/PostAddVehicle"
    );
    return response;
  }
);

export const PostRemoveVehicle = createAsyncThunk(
  "policyChange/PostRemoveVehicle",
  async (requestParams: IVehiclePolicyChangeRequestParams, { dispatch }) => {
    const response: IPolicyChangeResponse = await CustomAction(
      Api.post(`${API_POLICY_CHANGE}/removeVehicle`, requestParams),
      dispatch,
      "policyChange/PostRemoveVehicle"
    );
    return response;
  }
);

export const PostAddDriver = createAsyncThunk(
  "policyChange/PostAddDriver",
  async (requestParams: IDriverPolicyChangeRequestParams, { dispatch }) => {
    const response: IPolicyChangeResponse = await CustomAction(
      Api.post(`${API_POLICY_CHANGE}/addDriver`, requestParams),
      dispatch,
      "policyChange/PostAddDriver"
    );
    return response;
  }
);

export const postRemoveDriver = createAsyncThunk(
  "policyChange/postRemoveDriver",
  async (requestParams: IDriverPolicyChangeRequestParams, { dispatch }) => {
    const response: IPolicyChangeResponse = await CustomAction(
      Api.post(`${API_POLICY_CHANGE}/removeDriver`, requestParams),
      dispatch,
      "policyChange/postRemoveDriver"
    );
    return response;
  }
);
