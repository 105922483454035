import React from "react";
import { SxProps, Button } from "@mui/material";

interface ActionButtonProps {
  buttonText: string;
  sx?: SxProps;
  onClick?: () => void; 
}

const ActionButton: React.FC<ActionButtonProps> = ({ buttonText, sx, onClick }) => {
  return (
    <Button disableRipple variant="contained" sx={{ ...sx }} onClick={onClick}>
      {buttonText}
    </Button>
  );
};

export default ActionButton;
