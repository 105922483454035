import { createSlice } from "@reduxjs/toolkit";
import { ILookupSlice } from "../../../models/admin/lookup/Lookup";
import { getLookups } from "./lookupAction";

const initialState: ILookupSlice = {
  lookupList: [],
};

const lookupSlice = createSlice({
  name: "lookup",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLookups.fulfilled, (state, { payload }) => {
      state.lookupList =
        payload && payload.status !== "FAIL" ? payload.data : [];
    });
  },
});

export default lookupSlice.reducer;
