export const NO_DATA_AVAILABLE = "No data available";
export const PRIORITY = "Priority";
export const HIGH_PRIORITY = "High Priority";
export const MEDIUM_PRIORITY = "Medium Priority";
export const LOW_PRIORITY = "Low Priority";
export const POLICY = "Policy";
export const STATUS = "Status";
export const PERFORMING_POLICIES = "performing-policies";
export const SEARCH = "Search";
export const CLOSE = "close";
export const INSURER_NAME = "Insurer Name";
export const LATEST_NEWS = "Latest News";
export const VIEW_TASKS = "View Tasks";
export const LOGO = "logo";
export const VIEW_BY = "View by";
export const CLIENT_NAME = "Customer name";
export const PROFILE_LOGO = "profile-logo";
export const TASK_DATE = "Task Date";
export const INFO_ICON = "Info icon";
export const CLOCK_ICON = "Clock icon";
export const MENU_ICON = "menu-icon";
export const WELCOME = "Welcome";
export const MIKE = "Mike!";
export const DASHBOARD_SUBHEADING =
  "Let’s have a look at your Agent Metrics overview for";
export const ALL_LOCATIONS = "All locations";
export const SWITCH_TO_AGENT_DASHBOARD = "Switch to Agent dashboard";
export const CONFIGURE_DASHBOARD = "Configure Dashboard";
export const SAVE_CHANGES = "Save Changes";
export const AGENT_METRICS = "Agent Metrics";
export const ALL_POLICIES = "All Policies";
export const CARD_FOOTER_TEXT = "Sample insight can be shown here as needed.";
export const DRAG_ICON = "Drag icon";
export const EYE_ICON = "Eye icon";
export const DOWNLOAD_ICON = "Download icon";
export const MAXIMIZE_ICON = "Maximize icon";
export const PERSON_LOGO = "person logo";
export const DUE_DATE = "Due Date";
export const TYPE_YOUR_MESSAGE = "Type your message";
export const MY_TASKS = "My Tasks";
export const FILTER = "Filter";
export const SORT = "Sort";
export const NOTES = "Notes";
export const TOTAL_TASKS = "Total Tasks";
export const HIGH_PRIORITY_TASKS = "High Priority Tasks";
export const MEDIUM_PRIORITY_TASKS = "Medium Priority Tasks";
export const LOW_PRIORITY_TASKS = "Low Priority Tasks";
export const THIS_MONTH = "This Month";
export const AND = "and";
export const SEARCHFIELD_PLACEHOLDER =
  "Search by Policy name, Type, Number, and more!";
export const SEARCH_ICON = "search-icon";
export const SAVE = "Save";
export const POLICY_DETAILS = "policyDetails";
export const POLICY_STATUS = "policyStatus";
export const POLICY_TYPE = "policyType";
export const CUSTOMER_DETAILS = "customerDetails";
export const CONTACT_DETAILS = "contactDetails";
export const CUSTOMER_POLICY_STATUS = "customerPolicyStatus";
export const CLAIM_STATUS = "claimStatus";
export const PENDING_RENEWAL = "Pending Renewal";
export const POLICY_TYPE_IMAGE = "policy type image";
export const EXPIRED = "Expired";
export const CLAIMS = "Claims";
export const CLOSED = "Closed";
export const OPEN = "Open";
export const TAB1 = "tab1";
export const TAB2 = "tab2";
export const TASK_TYPE = "Task Type";
export const TIME = "Time";
export const RESET = "Reset";
export const CANCEL = "Cancel";
export const DOC_IMAGE = "Document Image";
export const LAST_MODIFIED = "Last Modified";
export const PDF = "pdf";
export const FOLDER = "Folder";
export const CUSTOMER_STATUS = "customerStatus";
export const RENEWAL = "renewal";
export const DUE_RENEWAL = "Due Renewal";
export const CANCELLED = "cancelled";
export const OVERDUE = "overdue";
export const OVERDUE_CAPS = "Overdue";
export const ACTIVE = "Active";
export const ACTIVE_CAPS = "Active";
export const CANCELLED_CAPS = "Cancelled  ";
export const NEW_TASK = "New Task";
export const DETAILS = "Details";
export const DETAILS_ICON = "details icon";
export const CRISTOFER = "Cristofer Stanton";
export const CRISTOFER_ID = "#12345678";
export const ADD_TASK = "Add Task";
export const SORT_ICON = "sort icon";
export const POLICY_LISTING = " Policy Listing";
export const CLAIM_LISTING = "Claim Listing";
export const DOWNLOAD = "Download";
export const REMAINDER = "Reminder";
export const PAY_NOW = "Pay Now";
export const UPCOMING_EVENTS = "Upcoming Events";
export const PAYMENT_SCHEDULE = "Payment Schedule";
export const COMMISSION_STATUS = "commissionStatus";
export const AUGUST = "August";
export const FOLDERS = "Folders";
export const DOCUMENTS = "Documents";
export const COMMISSION_STATEMENTS_CAPS = "Commission Statements";
export const COMMISSION_STATEMENTS = "commissionStatements";
export const CUSTOMER_LISTING_CAPS = "Customer Listing";
export const CUSTOMER_LISTING = "customerListing";
export const POLICIES = "Policies";
export const CUSTOMER = "Customer";
export const DOCUMNET_LISTING = "Document Listing";
export const CARD_VIEW = "Card View";
export const LIST_VIEW = "List View";
export const ADD_NEW = "Add New";
export const COMMISSION = "Commission";
export const STATEMENT = "Statement";
export const PERFORMANCE_ANALYTICS = "Performance Analytics";
export const VIEW_MORE = "View More";
export const ADD_A_TASK = "Add a Task";
export const CUSTOMER_NAME = "Cristofer Stanton";
export const CUSTOMER_ID = "#123456789";
export const FILE_A_CLAIM = "File a Claim";
export const VIEW_DETAILS = "View Details";
export const PHONE_ICON = "Phone Icon";
export const EMAIL_ICON = "Email Icon";
export const POLICY_STATUS_CAPS = "Policies";
export const CLAIM_STATUS_CAPS = "Claims";
export const DROP_DOWN_ICON = "Drop Down Icon";
export const COMMISSION_EARNED = "Commission Earned";
export const TRANS_TYPE = "Trans Type";
export const TRANS_DATE = "Trans Date";
export const CUSTOMERS = "Customers";
export const TERMS = "Terms";
export const PREMIUM = "Premium";
export const CARRIER = "Carrier";
export const NO_OF_ITEMS = "No of Items";
export const INVOICE = "Invoice #";
export const AMOUNT = "Amount";
export const PAID_ON = "Paid On";
export const PAYMENT_METHOD = "Payment Method";
export const BILLING_DATE = "Billing Date";
export const DATE = "Date";
export const COMMISSION_AMOUNT = "Commission Amount";
export const ADD_NOTES = "Add Notes...";
export const POLICY_DETAILS_CAPS = "Policy Details";
export const DISPLAY_PICTURE = "displayPicture";
export const SIMS_LOGO = "simsLogo";
export const NOTIFICATION = "notification";
export const CELEBRATION_TEXT =
  "John Mathew is celebrating his birthday , wish him .";
export const FOR = "For";
export const MESSAGES = "Messages";
export const TRACK_YOUR_CLAIM = "Track Your Claim";
export const MESSAGE_COUNT = "2";
export const ACTIVE_CLAIMS = "Active Claims";
export const PAST_CLAIMS = "Past Claims";
export const TASKS = "Tasks";
export const TASK = "Task";
export const PENDING = "Pending";
export const ACTIVE_LOWER = "active";
export const POLICY_LOWER = "policy";
export const STATUS_LOWER = "status";
export const CLIENT_NAME_LOWER = "clientName";
export const CLIENT_DETAILS_LOWER = "clientDetails";
export const TASK_DATE_LOWER = "timeStamp";
export const PRIORITY_LOWER = "priority";
export const CUSTOMER_NAME_2 = "Customer Name 2";
export const EXPECTED_DATE = "Expected Date";
export const UNDERWRITER = "Underwriter";
export const VIEW_LESS = "View Less";
export const RESERVED_RIGHTS = "© 2024 Smart IMS. All rights reserved.";
export const TERMS_AND_SERVICES = "Terms And Service";
export const PRIVACY_POLICY = "  Privacy Policy";
export const SHARE_MARKETING_COLLATERAL = "Share Marketing Collateral";
export const REMAINDER_TYPE = "Reminder Type";
export const ALONG_WITH_THE = " along with the below message:";
export const TO = "to";
export const SEND_REMAINDER_TO = "Send reminder to";
export const REGARDING = "regarding";
export const REMOVE = "Remove";
export const SEND_REMAINDER = "Send Reminder";
export const CRISTOFER_STANTON = "Cristofer Stanton";
export const FILE_UPLOAD_DETAILS = "ABC’s New Housing Policy AD 1.2MB";
export const CLAIM_DETAILS = "Claim Details";
export const OVERVIEW = "Overview";
export const TASK_LOWER = "task";
export const AGENCY_DASHBOARD_SUBHEADING =
  "Let’s have a look at agency overview considering";
export const AGENT_DASHBOARD_SUBHEADING =
  "Let’s have a look at your agent overview for";
export const CONTACT = "Contact";
export const DOWNLOAD_WIDGET = "Download Widget";
export const HIDE_WIDGET = "Hide Widget";
export const SWITCH_TO_AGENCY_DASHBOARD = "Switch to Agency Dashboard";
export const NEXT_PAYMENT = "Next Payment";
export const MENU = "Menu";
export const LOGOUT = "Log Out";
export const TODAY = "Today";
export const CAR = "car";
export const COMMISSION_POLICY_DETAILS = "commissionPolicyDetails";
export const COMMISSION_CUSTOMER_DETAILS = "commissionCustomerDetails";
export const MY_TASKS_LOWER = "myTasks";
export const VIEW_DASHBOARD_LOWER = "viewDashboard";
export const VIEW_POLICY_LOWER = "viewPolicy";
export const VIEW_CUSTOMER_LOWER = "viewCustomer";
export const VIEW_COMMISSION_LOWER = "viewCommission";
export const VIEW_PAYMENTS_LOWER = "viewPayments";
export const VIEW_DOCUMENTS_LOWER = "viewDocuments";
export const VIEW_SETTINGS_LOWER = "viewSettings";
export const RECURRING_PAYMENTS = "Recurring Payments";
export const VISA = "Visa";
export const ADD = "Add";
export const ADD_PAYMENT_METHOD = "Add Payment Method";
export const EXP = "Exp";
export const LEFT_ARROW = "Left Arrow";
export const RIGHT_ARROW = "Right Arrow";
export const MOVE = "Move";
export const ARCHIVE = "Archive";
export const EXPAND = "Expand View";
export const DOCUMENT_PREVIEW = "Document Preview";
export const DOCUMENT_PROPERTIES = "Document Properties";
export const FOLDER_NAME = "folderName";
export const FILE_NAME = "fileName";
export const PROPERTIES = "Properties";
export const FILE_NAME_CAPS = "File Name";
export const LAST_VIEWED = "Last Viewed";
export const CREATED_ON = "Created on";
export const FILE_TYPE = "File Type";
export const FILE_SIZE = "File Size";
export const DOCUMENT_DETAILS = "Document Name 1 Details";
export const FILES = "Files";
export const ARE_YOU_SURE_ARCHIVE = "Are you sure you want to archive the ";
export const FOLDER_LOWER = " folder";
export const ARCHIVING_THE_FOLDER =
  "Archiving the folder will affect 10 files contained within it.";
export const ARCHIVE_FILE = "Archive File";
export const FILE_LOWER = " file";
export const ACTIONS = "Actions";