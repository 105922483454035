export const DocumentFileCardStyles = {
  fileContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  footer: {
    display: "flex",
    gap: "10px",
    padding: "20px 20px 30px 20px",
    position: "relative",
    alignItems: "center",
    backgroundColor: "var(--white)",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    "@media (max-width: 1023px)": {
      padding: "12px 12px 10px 12px",
      height: "88px",
    },
  },
  container: {
    border: "1px solid var(--shaded-cyan)",
    boxShadow: "2px 2px 8px 0px #0000001A",
    width: "308px",
    height: "206px",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
    ":hover": {
      border: "1px solid var(--support-white)",
      boxShadow: "6px 6px 16px 0px #0000001F",
    },
    "@media (max-width: 1023px)": {
      width: "152px",
      height: "145px",
    },
  },
  hoverContainer: {
    border: "1px solid var(--shaded-cyan)",
    boxShadow: "2px 2px 8px 0px #0000001A",
    width: "308px",
    height: "206px",
    borderRadius: "8px",
    cursor: "pointer",
    position: "relative",
    "@media (max-width: 1023px)": {
      width: "152px",
      height: "145px",
    },
  },

  imageContainer: {
    backgroundColor: "var(--shaded-acne-blue)",
    position: "relative",
    height: "109px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    "> img": {
      width: "72px",
      height: "88px",
      position: "absolute",
      top: "11px",
      left: "118px",
    },
    "@media (max-width: 1023px)": {
      height: "55px",
      "> img": {
        width: "110px",
        height: "120px",
        position: "absolute",
        top: "10px",
        left: "19px",
      },
    },
  },
  fileName: {
    fontFamily: " var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--support-white)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  lastModified: {
    fontFamily: " var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--secondary-grey)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.5px",
    },
  },
  menuIcon: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  },
  menuIconStyles: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  cancelButton: {
    boxShadow: "none",
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
  modalContainer: {
    width: "604px",
    height: "220px",
    paddingTop: "10px",
    "@media (max-width: 1023px)": {
      width: "320px",
      height: "290px",
      paddingTop: "10px",
    },
  },
  modalHeadingContainer: {
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    paddingTop: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "30px",
      paddingTop: "30px",
      paddingBottom: "20px",
    },
  },
  modalHeading: {
    fontSize: "var(--font-size-16)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "normal",
    },
  },
  imageText: {
    paddingLeft: "10px",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "normal",
      paddingLeft: "0px",
    },
  },
  documentIcon: {
    width: "24px",
    height: "24px",
  },
};
