export const HeaderMenuStyles = {
  menuContainer: {
    background: "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    width: "100%",
    overflow: "hidden",
    "@media (max-width:599px)": {
      overflow: "auto",
      width: "100vw",
      height: "100dvh",
    },
  },
  headingContainer: {
    display: "none",
    "@media (max-width:599px)": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "1px solid var(--primary-grey)",
      padding: "16px 24px 12px 24px",
    },
  },
  headingText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-20)",
    color: "var(--support-black)",
    lineHeight: "27px",
  },
  linksContainer: {
    minHeight: "calc(100vh - 48px)",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 599px)": {
      minHeight: "calc(100vh - 58px)",
    },
  },
  imageContainer: {
    display: "flex",
    gap: "20px",
  },
  crossIcon: {
    cursor: "pointer",
  },
  navigationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "12px 8px 0px 8px",
  },
  imageTextContainer: {
    padding: "9px 16px",
    ":hover": {
      backgroundColor: "var(--white)",
      borderRadius: "4px",
      "& img": {
        filter:
          "brightness(0) saturate(100%) invert(12%) sepia(8%) saturate(282%) hue-rotate(160deg) brightness(94%) contrast(88%)",
      },
    },
  },
  imageText: {
    color: "var(--secondary-grey)",
  },
  imageHoverText: {
    color: "var(--primary-black)",
    transition: "filter 0.3s ease",
  },
  image: {
    width: "24px",
    height: "24px",
    stroke: "var(--secondary-grey)",
    transition: "filter 0.3s ease",
    "> img": {
      width: "100%",
      height: "100%",
    },
  },
  logOutContainer: {
    padding: "0px 8px",
    marginBottom: "11px",
  },
};
