import { useEffect, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Error from "./common/Error/Error";
import Login from "./Login/Login";
import "./App.css";
import CustomerRoutes from "./customers/CustomerRoutes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { RootState } from "./store/store";
import { getCookie } from "./utils/Utility";
import Registration from "./registration/Registration";
import { setUserDetails } from "./store/user/userAuth/userAuthSlice";
import AdminRoutes from "./admin/AdminRoutes";
import { setAdminDetails } from "./store/admin/adminAuth/adminAuthSlice";
import { adminPages, customerPages } from "./constants/Constants";
import AgentDashboard from "./components/AgentDashboard/AgentDashBoard";
import AgencyDashboard from "./components/AgencyDashboard/AgencyDashboard";
import PaymentSchedule from "./components/PaymentSchedule/PaymentSchedule";
import DocumentListing from "./components/DocumentListing/DocumentListing";
import NewTask from "./components/NewTask/NewTask";
import PolicyListing from "./components/PolicyListing/PolicyListing";
import CustomerListing from "./components/CustomerListing/CustomerListing";
import CommissionStatements from "./components/CommissionStatements/CommissionStatements";
import CustomerDetails from "./components/CustomerDetails/CustomerDetails";
import AgencyHeader from "./common/AgencyHeader/AgencyHeader";
import { Box, useMediaQuery } from "@mui/material";
import AgencyFooter from "./common/AgentFooter/AgentFooter";
import ConfigureDashboardResponsive from "./components/ConfigureDashBoardResponsive/ConfigureDashboardResponsive";
import ClaimTrackingResponsive from "./components/ClaimTrackingResponsive/ClaimTrackingResponsive";
import SendRemainderResponsive from "./components/SendRemainderResponsive/SendRemainderResponsive";
import ShareMarketingCollateralResponsive from "./components/ShareMarketingCollateralResponsive/ShareMarketingCollateralResponsive";
import MyTasks from "./components/MyTasks/MyTasks";
import MonthlyCommissionStatements from "./components/MonthlyCommissionStatements/MonthlyCommissionStatements";
import DocumentProperties from "./components/DocumentProperties/DocumentProperties";
import { documentPropertiesData } from "./constants/DocumentPropertiesData";
import DocumenManagementDetails from "./components/DocumenManagementDetails/DocumenManagementDetails";
import MyTasksNotesResponsoive from "./components/MyTasksNotesResponsoive/MyTasksNotesResponsoive";
import PolicyDetails from "./PolicyDetails/PolicyDetails";
const ApplicationRoutes = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const authenticated = getCookie("authenticated");
  const id = getCookie("id");
  const name = getCookie("name");
  const roleCode = getCookie("roleCode");

  const { isLoading } = useAppSelector((state: RootState) => state.common);
  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { adminDetails } = useAppSelector(
    (state: RootState) => state.adminAuth
  );

  const routeToAdmin = useCallback(() => {
    const pathArray = location.pathname.split("/");
    if (pathArray[1] !== "admin") {
      navigate("/admin/dashboard");
    } else {
      if (
        pathArray[2] &&
        adminPages
          .filter((pageName) => pageName !== "login")
          .some((page: string) => page === pathArray[2])
      ) {
        navigate(location.pathname);
      } else {
        navigate("/admin/dashboard");
      }
    }
  }, [navigate, location.pathname]);

  const routeToCustomer = useCallback(() => {
    const pathArray = location.pathname.split("/");
    if (pathArray[1] !== "customer") {
      navigate("/customer/dashboard");
    } else {
      if (
        pathArray[2] &&
        customerPages.some((page: string) => page === pathArray[2])
      ) {
        navigate(location.pathname);
      } else {
        navigate("/customer/dashboard");
      }
    }
  }, [navigate, location.pathname]);

  const checkAuthentication = useCallback(() => {
    switch (roleCode) {
      case "1":
        if (!adminDetails) {
          dispatch(setAdminDetails({ id, name }));
        }
        routeToAdmin();
        return;
      case "2":
        if (!userDetails) {
          dispatch(setUserDetails({ customerId: id, customerName: name }));
        }
        routeToCustomer();
        return;
    }
  }, [
    roleCode,
    routeToAdmin,
    routeToCustomer,
    dispatch,
    id,
    name,
    userDetails,
    adminDetails,
  ]);

  useEffect(() => {
    if (authenticated) {
      checkAuthentication();
    }
  }, [authenticated, checkAuthentication]);
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  return (
    <>
      <Dialog
        open={isLoading}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            overflow: "hidden",
            position: "relative",
          },
        }}
      >
        <CircularProgress size="4rem" />
      </Dialog>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <AgencyHeader />
        <Box
          sx={{
            background:
              "linear-gradient(rgb(251, 253, 252) 0%, rgb(229, 246, 254) 100%)",
            flex: 1,
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  logoSrc={""}
                  loginBannerSrc={""}
                  isFunctionable={false}
                />
              }
            />
            <Route
              path="/login"
              element={
                <Login
                  logoSrc={""}
                  loginBannerSrc={""}
                  isFunctionable={false}
                />
              }
            />
            <Route path="/policy-details" element={<PolicyDetails/>}/>
            <Route path="/customer/*" element={<CustomerRoutes />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/agent" element={<AgentDashboard />} />
            <Route path="/mytasks" element={<MyTasks />} />
            <Route path="/agency" element={<AgencyDashboard />} />
            <Route path="/payment-schedule" element={<PaymentSchedule />} />
            <Route path="/document-listing" element={<DocumentListing />} />
            <Route path="/policy-listing" element={<PolicyListing />} />
            <Route path="/customer-listing" element={<CustomerListing />} />
            <Route path="/new-task" element={isSmallScreen && <NewTask />} />
            <Route path="/notes" element={isSmallScreen && <MyTasksNotesResponsoive/>} />
            <Route
              path="/commission-statements"
              element={<CommissionStatements />}
            />
            <Route
              path="/monthly-statement"
              element={<MonthlyCommissionStatements />}
            />
            <Route path="/config" element={<ConfigureDashboardResponsive />} />
            <Route path="/customer-details" element={<CustomerDetails />} />
            <Route
              path="/claim-tracking"
              element={isSmallScreen && <ClaimTrackingResponsive />}
            />
            <Route
              path="/market"
              element={isSmallScreen && <ShareMarketingCollateralResponsive />}
            />
            <Route
              path="/properties"
              element={
                <DocumentProperties
                  documentPropertiesData={documentPropertiesData}
                />
              }
            />
            <Route
              path="/document-details"
              element={<DocumenManagementDetails />}
            />
            <Route
              path="/remainder"
              element={isSmallScreen && <SendRemainderResponsive />}
            />
            <Route path="*" element={<Error header={true} {...props} />} />
          </Routes>
        </Box>
        <AgencyFooter />
      </Box>
    </>
  );
};

export default ApplicationRoutes;
