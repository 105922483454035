import { Box, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import PolicyListingResponsive from "../PolicyListingResponsive/PolicyListingResponsive";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import {
  ADD_TASK,
  CUSTOMER,
  FILTER,
  POLICIES,
  TAB1,
  TAB2,
  VIEW_LESS,
  VIEW_MORE,
} from "../../constants";
import {
  policiesThatNeedAttention,
  policyListingData,
  policyListingTableHeaderData,
} from "../../constants/policyListingData";
import {
  customerPoliciesData,
  customerPoliciesTableHeaderData,
  customerPoliciesThatNeedAttention,
} from "../../constants/CustomerPoliciesData";
import TabSwitch from "../../common/TabSwitch/TabSwitch";
import HeadingText from "../../common/HeadingText/HeadingText";
import docIcon from "../../assets/svgs/document-icon.svg";
import docIconBlack from "../../assets/svgs/document-icon-black.svg";
import personIconWhite from "../../assets/svgs/person-icon-white.svg";
import personIcon from "../../assets/svgs/person-icon.svg";
import { PolicyListingStyles } from "./PolicyListingStyles";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import { policyListingHeaderData } from "../../constants/PolicyListingHeaderData";
import dropDownArrowIcon from "../../assets/svgs/drop-down-arrow.svg";
import upSideDownArrowIcon from "../../assets/svgs/upside-down-icon.svg";
import CustomButton from "../../common/CustomButton/CustomButton";
import Drawers from "../../common/Drawers/Drawers";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import PolicyListingList from "../PolicyListingList/PolicyListingList";
import NewTask from "../NewTask/NewTask";

const PolicyListing: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  const [activeTab, setActiveTab] = useState(TAB1);
  const [isSlide, setIsSlide] = useState(false);
  const [isViewMore, setIsViewMore] = useState(true);
  const [notesOpen, setNotesOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [headerData, setHeaderData] = useState(
    isSmallScreen
      ? policyListingHeaderData.slice(0, 4)
      : policyListingHeaderData
  );

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setIsSlide(tab === TAB2);
  };

  const handleSlicing = () => {
    if (isViewMore) {
      setHeaderData(policyListingHeaderData);
      setIsViewMore(false);
    } else {
      setHeaderData(policyListingHeaderData.slice(0, 4));
      setIsViewMore(true);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  const handleNotesClick = () => {
    setNotesOpen(!notesOpen);
  };
  return (
    <>
      <Box sx={PolicyListingStyles.container}>
        <Box sx={PolicyListingStyles.innerContainer}>
          <Box sx={PolicyListingStyles.tabsContainer}>
            <HeadingText heading={POLICIES} />
            <Box sx={PolicyListingStyles.rightContainer}>
              <ImageAndText
                additionalClassName={PolicyListingStyles.filterIcon}
                onhandleClick={handleClick}
                image={filterIcon}
                text={FILTER}
              />
              <CustomButton
                text={ADD_TASK}
                onClick={handleNotesClick}
                additionalClassName={PolicyListingStyles.addTaskButton}
              />
              <TabSwitch
                tab1Title={POLICIES}
                tab2Title={CUSTOMER}
                tab1ActiveImage={docIconBlack}
                tab2ActiveImage={personIcon}
                tab2InActiveImage={personIconWhite}
                tab1InActiveImage={docIcon}
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                isSlide={isSlide}
              />
            </Box>
          </Box>
          <Box sx={PolicyListingStyles.headerContainer}>
            {headerData.map((item, index) => (
              <HeadingSubHeading
                key={index}
                heading={item.heading}
                headingAdditionalClassName={
                  PolicyListingStyles.headingContainerText
                }
                containerAdditionalClassName={
                  PolicyListingStyles.headingSubheadingContainer
                }
                subHeadingAdditionalClassName={
                  PolicyListingStyles.subHeadingContainerText
                }
                subheading={item.subHeading}
              />
            ))}
            {isSmallScreen && (
              <Box
                sx={{
                  ...PolicyListingStyles.footerImageAndText,
                  justifyContent: isViewMore ? "flex-end" : "center",
                }}
              >
                {isViewMore ? (
                  <ImageAndText
                    additionalClassName={PolicyListingStyles.viewMoreContainer}
                    onClick={handleSlicing}
                    image={dropDownArrowIcon}
                    text={VIEW_MORE}
                  />
                ) : (
                  <ImageAndText
                    additionalClassName={PolicyListingStyles.viewLessContainer}
                    onClick={handleSlicing}
                    image={upSideDownArrowIcon}
                    text={VIEW_LESS}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          {isSmallScreen ? (
            <PolicyListingResponsive
              activeTab={activeTab}
              policyListingData={policyListingData}
              policiesThatNeedAttention={policiesThatNeedAttention}
              customerPoliciesData={customerPoliciesData}
              customerPoliciesThatNeedAttention={
                customerPoliciesThatNeedAttention
              }
            />
          ) : (
            <PolicyListingList
              policyListingHeaderData={policyListingTableHeaderData}
              customerListingHeaderData={customerPoliciesTableHeaderData}
              activeTab={activeTab}
              policyListingData={policyListingData}
              customerPoliciesData={customerPoliciesData}
              policiesThatNeedAttention={policiesThatNeedAttention}
              customerPoliciesThatNeedAttention={
                customerPoliciesThatNeedAttention
              }
            />
          )}
        </Box>
      </Box>

      <Drawers
        open={notesOpen}
        toggleDrawer={handleNotesClick}
        heading={ADD_TASK}
        additionalClass={PolicyListingStyles.drawer}
      >
        <NewTask onCancelClick={handleNotesClick} />
      </Drawers>

      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default PolicyListing;
