export const MyTasksNotesResponsoiveStyles = {
    container:{
        marginTop: "60px",
    },
    chatContainer:{
        margin: "0px 0px !important",
        height: "calc(100vh - 322px) !important",
        overflowY: "scroll !important",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { display: "none" },
    },
    breadCrumb:{
    padding: " 20px",
    },
    separator:{
        "@media screen and (max-width: 1023px)": {
            display: "none",
        },
    }
};