import React from "react";
import { Props } from "./Props";

const InteractionCardPhoneIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M6.96117 3.83392C9.73233 1.26012 14.0653 1.42012 16.6391 4.19128L21.0774 8.96998C23.6512 11.7411 23.4912 16.0741 20.7201 18.6479C17.9489 21.2217 13.616 21.0617 11.0422 18.2905L6.60381 13.5118C4.03001 10.7407 4.19001 6.40772 6.96117 3.83392Z"
        fill={fill}
      />
      <path
        d="M17.3853 15.7833C17.5298 15.6871 17.696 15.6285 17.8689 15.6128C18.0418 15.5971 18.2158 15.6247 18.3753 15.6933L24.5268 18.4494C24.7341 18.538 24.9071 18.6914 25.02 18.8865C25.1328 19.0817 25.1795 19.3082 25.1529 19.532C24.9502 21.0465 24.2044 22.4358 23.0542 23.4416C21.904 24.4474 20.4276 25.0012 18.8997 25C14.1949 25 9.68275 23.131 6.35595 19.8042C3.02914 16.4774 1.16016 11.9653 1.16016 7.26047C1.15899 5.73253 1.71279 4.25616 2.71858 3.10594C3.72436 1.95572 5.11366 1.20994 6.62811 1.00729C6.85198 0.980692 7.07845 1.02732 7.27361 1.14018C7.46877 1.25305 7.62213 1.42608 7.71074 1.63339L10.4669 7.79005C10.5346 7.94818 10.5622 8.12059 10.5472 8.29195C10.5321 8.46332 10.475 8.62831 10.3808 8.77224L7.59335 12.0867C7.49447 12.2359 7.436 12.4082 7.42365 12.5867C7.41131 12.7653 7.44551 12.944 7.52291 13.1054C8.60163 15.3137 10.8843 17.569 13.0991 18.6372C13.2614 18.7143 13.4409 18.7477 13.62 18.7342C13.7991 18.7208 13.9716 18.6608 14.1204 18.5603L17.3853 15.7833Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InteractionCardPhoneIcon;
