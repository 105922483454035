import { Box, Button, Divider, Link, Tooltip, Typography } from "@mui/material";
import emailLogo from "../../assets/emailLogo.svg";
import { useNavigate } from "react-router-dom";
import addressLogo from "../../assets/addressLogo.svg";
import phoneNumberLogo from "../../assets/phoneNumberLogo.svg";
import ProfileStyles from "./ProfileStyles";
import { useState } from "react";
import SettingsAndPreferences from "./SettingsAndPreferences/SettingsAndPreferences";
import ChangePassword from "./ChangePassword/ChangePassword";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const Profile = () => {
  const navigate = useNavigate();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);

  const [isPassword, setIsPassword] = useState<boolean>(false);

  const handleEditClick = () => {
    navigate("/edit-profile");
  };

  return (
    <Box sx={ProfileStyles.profile}>
      <Box sx={{ display: "flex" }}>
        <Link
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => navigate("customer/dashboard")}
        >
          <Tooltip title="Go to Dashboard">
            <KeyboardArrowLeftIcon />
          </Tooltip>
        </Link>
        <Typography sx={ProfileStyles.profileDetailsHeading}>
          Profile
        </Typography>
      </Box>
      <Box sx={ProfileStyles.profileDetailsContainer}>
        {/* {isPassword ? (
          <ChangePassword onClick={() => setIsPassword(false)} />
        ) : (
          <Box sx={ProfileStyles.container}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Box sx={ProfileStyles.userNameContainer}>
                <Typography sx={ProfileStyles.userName}>
                  {userDetails?.customerName.slice(0, 1)}
                </Typography>
              </Box>
              <Typography sx={ProfileStyles.userName}>
                {userDetails?.customerName}
              </Typography>
            </Box>
            <Divider />
            <Box sx={ProfileStyles.detailsInfo}>
              <img src={emailLogo} alt="email-logo" />
              <Typography sx={ProfileStyles.profileCardTextStyle}>
                elen.rodiguez@gmail.com
              </Typography>
            </Box>
            <Box sx={ProfileStyles.detailsInfo}>
              <img src={phoneNumberLogo} alt="email-logo" />
              <Typography sx={ProfileStyles.profileCardTextStyle}>
                626-358-7271
              </Typography>
            </Box>
            <Box sx={ProfileStyles.detailsInfo}>
              <img src={addressLogo} alt="email-logo" />
              <Typography sx={ProfileStyles.profileCardTextStyle}>
                Maria A Mendoza De Zuniga, 2929 SW 19th St Oklahoma City, OK
                73108-4410
              </Typography>
            </Box>
            <Box sx={ProfileStyles.buttons}>
              <Button
                sx={ProfileStyles.profileButton}
                onClick={handleEditClick}
              >
                Edit
              </Button>
              <Button
                sx={ProfileStyles.profileButton}
                onClick={() => setIsPassword(true)}
              >
                ChangePassword
              </Button>
            </Box>
          </Box>
        )} */}
        <SettingsAndPreferences />
      </Box>
    </Box>
  );
};
export default Profile;
