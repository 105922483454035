import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import crossIcon from "../../assets/svgs/cross-icon.svg";
import { ModalStyles } from "./ModalComponentStyles";
import { SxProps, Typography } from "@mui/material";
import { CLOSE, SEARCH } from "../../constants";
import { Theme } from "@emotion/react";

interface ModalProps {
  heading: string;
  open: boolean;
  handleOpen: () => void;
  searchImage?: string;
  children?: React.ReactNode;
  additionalClassContainer?: SxProps<Theme>;
  headingAdditionalClass?: SxProps<Theme>;
  headingContainerAdditionalClass?: SxProps<Theme>;
  additionalModalClass?:SxProps<Theme>;
}

const ModalComponent: React.FC<ModalProps> = ({
  heading,
  handleOpen,
  open,
  searchImage,
  children,
  additionalClassContainer,
  headingAdditionalClass,
  headingContainerAdditionalClass,
  additionalModalClass
}) => {
  return (
    <Modal
      open={open}
      onClose={handleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ outline: "none" }}
    >
      <Box
        sx={Object.assign({}, ModalStyles.container, additionalClassContainer)}
      >
        <Box sx={Object.assign({},ModalStyles.headingContainer,headingContainerAdditionalClass)}>
          <Typography
            sx={Object.assign(
              {},
              ModalStyles.headingText,
              headingAdditionalClass
            )}
          >
            {heading}
          </Typography>
          <Box sx={ModalStyles.imageContainer}>
            {searchImage && (
              <Box sx={ModalStyles.searchIcon}>
                <img src={searchImage} alt={SEARCH} />
              </Box>
            )}
            <Box sx={ModalStyles.crossIcon}>
              <img src={crossIcon} alt={CLOSE} onClick={handleOpen} />
            </Box>
          </Box>
        </Box>
        <Box sx={Object.assign(
              {},
              ModalStyles.modalContent,
              additionalModalClass
            )} >{children}</Box>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
