import { createSlice } from "@reduxjs/toolkit";
import { getForms, getFormTypes } from "./formsActions";
import { IFormsSlice } from "../../../models/admin/Forms/Forms";

const initialState: IFormsSlice = { formTypeOptions: [], forms: [] };

const formsSlice = createSlice({
  name: "forms",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFormTypes.fulfilled, (state, { payload }) => {
      state.formTypeOptions = payload.data;
    });
    builder.addCase(getForms.fulfilled, (state, { payload }) => {
      state.forms = payload.data || [];
    });
  },
});

export default formsSlice.reducer;
