import Box from "@mui/material/Box";
import { LOGO } from "../../constants";
import { DashboardBreadCrumStyles } from "./DashboardBreadCrumbStyles";
import backArrow from "../../assets/svgs/back-arrow.svg";
import { SxProps, Theme } from "@mui/material";

interface BreadCrumProps {
  variant: "primary" | "secondary";
  text: string;
  additionalText?: string;
  additionalContianerClass?: SxProps<Theme>;
  additionalArrowImageClass?: SxProps<Theme>;
  additionalContentClass?: SxProps<Theme>;
  handleClick?: () => void;
}

const DashboardBreadCrumb: React.FC<BreadCrumProps> = ({
  variant,
  text,
  additionalText,
  additionalContianerClass,
  additionalArrowImageClass,
  additionalContentClass,
  handleClick,
}) => {
  return (
    <Box sx={Object.assign({}, DashboardBreadCrumStyles.container, additionalContianerClass)}>
      <Box onClick={handleClick} sx={Object.assign({}, DashboardBreadCrumStyles.arrowImage, additionalArrowImageClass)}>
        <img src={backArrow} alt={LOGO} />
      </Box>
      <Box
        sx={Object.assign(
          {},
          DashboardBreadCrumStyles.breadcrumText,
          additionalContentClass
        )}
      >
        {text}
      </Box>

      {variant === "secondary" && (
        <>
          <Box>|</Box>
          <Box
            sx={Object.assign(
              {},
              DashboardBreadCrumStyles.breadcrumAdditionalText,
              additionalContentClass
            )}
          >
            {additionalText}
          </Box>
        </>
      )}
    </Box>
  );
};

export default DashboardBreadCrumb;
