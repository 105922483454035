import React from "react";
import { Props } from "./Props";

const PolicyListingCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 30"
      fill="none"
    >
      <ellipse cx="15.3258" cy="11.2501" rx="7.275" ry="7.2" fill={fill} />
      <path
        d="M23.8 29.4H2.2C1.88174 29.4 1.57652 29.2736 1.35147 29.0485C1.12643 28.8235 1 28.5183 1 28.2V1.80001C1 1.48175 1.12643 1.17652 1.35147 0.951478C1.57652 0.726435 1.88174 0.600006 2.2 0.600006L23.5 0.600006C24.3284 0.600006 25 1.27158 25 2.10001V28.2C25 28.5183 24.8736 28.8235 24.6485 29.0485C24.4235 29.2736 24.1183 29.4 23.8 29.4Z"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.25 9.06809V5.72718C18.25 5.6006 18.1997 5.4792 18.1102 5.3897C18.0207 5.30019 17.8993 5.24991 17.7727 5.24991H8.22727C8.10069 5.24991 7.9793 5.30019 7.88979 5.3897C7.80028 5.4792 7.75 5.6006 7.75 5.72718V9.06809C7.75 14.7954 13 16.2272 13 16.2272C13 16.2272 18.25 14.7954 18.25 9.06809Z"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6133 10.5002L12.0451 11.932L13.7156 10.2615L15.386 8.59109"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7.15039 20.2501H18.7004" stroke="#3F3F3F" />
      <path d="M6.25 22.7999H19.6" stroke="#3F3F3F" />
      <path d="M5.34961 25.35H20.4996" stroke="#3F3F3F" />
    </svg>
  );
};

export default PolicyListingCardIcon;
