export const AgencyDashboardStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "25px 30px",
    background:
      "linear-gradient(180deg, var(  --support-green) 0%, var( --support-blue) 100%)",
    marginTop: "68px",
    "@media screen and (max-width: 1023px)": {
      padding: "40px",
      gap: "15px",
      marginTop: "60px",
    },
    "@media screen and (max-width: 599px)": {
      padding: "23px 20px",
      gap: "15px",
    },
  },
  dashboardContainer: {
    maxWidth: "100%",
    marginTop: "0px",
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    marginBottom: "20px",
    "@media screen and (max-width: 1023px)": {
      gap: "7px",
      marginBottom: "0px",
    },
  },
  dropDownContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  },
  overViewText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    letterSpacing: "0px",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--secondary-grey)",
    "@media screen and (max-width: 599px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "20px",
      letterSpacing: "0px",
    },
  },
  line: {
    color: "var(--primary-grey)",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  checkboxContainer: {
    display: "flex",
    gap: "25px",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "30px",
    height: "435px",
    overflowY: "auto",
  },
  checkbox: {
    width: "311px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  modal: {
    width: "1007px",
    height: "560px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
  },
  switchContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "@media screen and (max-width: 1023px)": {
      width: "100%",
      borderTop: "1px solid var(--primary-grey)",
      height: "32px",
      paddingTop: "10px",
    },
  },
  conversationalText: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    "@media (max-width:1023px)": {
      flexDirection: "column",
      gap: "10px",
      alignItems: "flex-start",
      width: "100%",
    },
  },
  conversationalContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "30px",
    "@media (max-width:1023px)": {
      flexDirection: "column",
      height: "unset",
      marginBottom: "20px",
    },
  },
  configureIcon: {
    gap: "2px",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  configureText: {
    color: "var(--support-white)",
  },
  optionsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  switchText: {
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "normal",
    },
  },
  dropDownWithConfigure: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      marginBottom: "5px",
    },
  },
  gearIconMobile: {
    display: "none",
    "@media screen and (max-width: 1023px)": {
      display: "block",
    },
  },
  dropDownClass: {
    marginLeft: "0px",
  },
  headingText: {
    color: "var(--support-white)",
  },
  button: {
    boxShadow: "none",
  },
  newBusinessContainer: {
    "@media screen and (max-width: 1023px)": {
      marginTop: "21px",
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "27px",
    },
  },
  customerStatsContainer: {
    "@media screen and (max-width: 1023px)": {
      marginTop: "21px",
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "27px",
    },
  },
  existingBusinessContainer: {
    "@media screen and (max-width: 1023px)": {
      marginTop: "11px",
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "25px",
      marginLeft: "5px",
    },
  },
  cardsContainer: {
    height: "calc(100vh - 253px)",
  },
};
