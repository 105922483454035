export const NotesStyles = {
  clientContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  clientDetails: {
    display: "flex",
    gap: "19px",
  },
  clientDetailsContainer: {
    display: "flex",
    gap: "12px",
  },
  chatContainer: {
    border: "1px solid var(--primary-grey)",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 217px)",
    gap: "10px",
    backgroundColor: "var(--acne-blue)",
    padding: "10px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },
  notesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90vh",
    padding: "16px",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  personDetails: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  clientName: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.6px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  customButton: {
    position: "absolute",
    right: "10px",
  },
};
