export const ModalStyles = {
  headingText: {
    fontFamily: "Playfair Display",
    fontSize: "var(--font-size-20)",
    color: "var(--support-black)",
    lineHeight: "27px",
  },

  headingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid var(--primary-grey)",
    margin: "0px -20px",
    padding: "0px 20px 10px 20px",
  },
  searchIcon: {
    paddingRight: 0,
  },
  crossIcon: {
    cursor: "pointer",
  },
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 730,
    height: 577,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    padding: "20px 20px",
    border: "0px solid !important",
    outline: "none",
  },
  imageContainer: {
    display: "flex",
    gap: "20px",
  },
  modalContent: {},
};
