export const customerPoliciesTableHeaderData = [
  "Customer Details",
  "Status",
  "Policy Details",
  "Premium",
  "Term",
  "No. of Items",
  "Carrier",
];
export const customerPoliciesData = [
  {
    customerDetails: {
      image: "",
      customerName: "John Smith",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA82345678",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "John Smith",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO82345679",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$900",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mary Johnson",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA91456789",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,500",
    customerTerm: "1 year",
    customItems: "3 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mary Johnson",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO91456790",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$800",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mary Johnson",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO91456791",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,000",
    customerTerm: "6 months",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Michael Williams",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO63789012",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$950",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Michael Williams",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA63789013",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,100",
    customerTerm: "1 year",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Jennifer Brown",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA75234567",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,050",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO83901234",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,800",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO83901235",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA83901236",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$900",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO83901237",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,300",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Linda Miller",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO69876543",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,100",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Linda Miller",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA69876544",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$850",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "William Davis",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO50345678",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,400",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "William Davis",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO50345679",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$950",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "William Davis",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA50345680",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,050",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Elizabeth Garcia",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "HO91567890",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Elizabeth Garcia",
      customerNumber: "",
    },
    customerStatus: "active",
    policyDetails: {
      policyNumber: "PA91567891",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,300",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
];
export const customerPoliciesThatNeedAttention = [
  {
    customerDetails: {
      image: "",
      customerName: "John Smith",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA82345678",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Mary Johnson",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA91456789",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,500",
    customerTerm: "1 year",
    customItems: "3 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Michael Williams",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO63789012",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$950",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Jennifer Brown",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA75234567",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,050",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Robert Jones",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO83901234",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,800",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Linda Miller",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO69876543",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,100",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "William Davis",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO50345678",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,400",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Elizabeth Garcia",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO91567890",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "David Rodriguez",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA28678901",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,100",
    customerTerm: "1 year",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Barbara Wilson",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA12789012",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,000",
    customerTerm: "6 months",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Richard Martinez",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA33456789",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$950",
    customerTerm: "1 year",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Susan Anderson",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA44567890",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,200",
    customerTerm: "6 months",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Joseph Taylor",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA55678901",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,300",
    customerTerm: "1 year",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Jessica Thomas",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO66789012",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,150",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Thomas Hernandez",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA77890123",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,100",
    customerTerm: "1 year",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Patricia Moore",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA89765432",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,250",
    customerTerm: "6 months",
    customItems: "2 Vehicles",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Christopher Jackson",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "HO76234568",
      policyTypeNumber: "Home Owners",
    },
    customerPremium: "$1,200",
    customerTerm: "1 year",
    customItems: "1 Dwelling",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Sarah White",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA58901234",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$950",
    customerTerm: "1 year",
    customItems: "1 Vehicle",
    customerCarrier: "ABC Insure",
  },
  {
    customerDetails: {
      image: "",
      customerName: "Daniel Harris",
      customerNumber: "",
    },
    customerStatus: "Active",
    policyDetails: {
      policyNumber: "PA67890123",
      policyTypeNumber: "Auto",
    },
    customerPremium: "$1,700",
    customerTerm: "1 year",
    customItems: "3 Vehicles",
    customerCarrier: "ABC Insure",
  },
];
