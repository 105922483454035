import React from "react";
import { Box, Typography } from "@mui/material";
import { ClaimHistoryStyles } from "./ClaimHistoryStyles";
import { ACTIVE_CLAIMS, PAST_CLAIMS } from "../../constants";
import ClaimCard from "../../common/ClaimCard/ClaimCard";
import { ClaimHistoryProps } from "../CustomerDetailsList/CustomerDetailsList";
interface ClaimHistoryDataProps {
  activeClaimsData: ClaimHistoryProps[];
  pastClaimsData: ClaimHistoryProps[];
}
const ClaimHistory: React.FC<ClaimHistoryDataProps> = ({
  activeClaimsData,
  pastClaimsData,
}) => {
  return (
    <Box sx={ClaimHistoryStyles.container}>
      <Box sx={ClaimHistoryStyles.claimsFields}>
        <Typography sx={ClaimHistoryStyles.headingText}>
          {ACTIVE_CLAIMS}
        </Typography>
        <Box sx={ClaimHistoryStyles.claimCards}>
          {activeClaimsData.map((claim, index) => (
            <ClaimCard
              claimId={claim.claimId}
              active={claim.active}
              claimsData={claim.claimsData}
            />
          ))}
        </Box>
      </Box>
      <Box sx={ClaimHistoryStyles.claimsFields}>
        <Typography sx={ClaimHistoryStyles.headingText}>
          {PAST_CLAIMS}
        </Typography>
        <Box sx={ClaimHistoryStyles.claimCards}>
          {pastClaimsData.map((claim, index) => (
            <ClaimCard
              claimId={claim.claimId}
              active={claim.active}
              claimsData={claim.claimsData}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ClaimHistory;
