const buttonTextStyles = {
  fontFamily: "var(--font-noto-sans)",
  fontSize: "var(--font-size-14)",
  fontWeight: "var(--font-weight-400)",
  lineHeight: "22px",
  textAlign: "left",
};
export const ButtonStyles = {
  filledButton: {
    background: "var(--support-white)",
    ...buttonTextStyles,
    boxShadow: "0px 6px 10px 0px var(--support-white)",
    padding: "9px 52px 9px 52px",
    borderRadius: "100px",
    color: "var(--white)",
    cursor: "pointer",
    border: "none",
    textTransform: "none",
    "&:hover": {
      background: "var(--support-white)",
    },
  },
  outlinedButton: {
    background: "var(--white)",
    ...buttonTextStyles,
    padding: "9px 52px 9px 52px",
    borderRadius: "100px",
    color: "var(--support-white)",
    cursor: "pointer",
    textTransform: "none",
    border: "1px solid #3F3F3F",
    boxShadow: "0px 6px 10px 0px #3F3F3F4D",
    "&:hover": {
      background: "var(--white)",
      color: "var(--support-white)",
    },
  },
  greyOutlinedButton: {
    border: "1px solid var(--ternary-grey)",
    ...buttonTextStyles,
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    background: "var(--white)",
    padding: "2px 14px 2px 14px",
    color: "var(--black)",
    borderRadius: "160px",
    textTransform: "none",
  },
  greenOutlinedButton: {
    padding: "2px 14px 2px 14px",
    gap: "10px",
    borderRadius: "160px",
    ...buttonTextStyles,
    border: "1px solid var(--secondary-green)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "500",
    color: "var(--secondary-green)",
  },
  greenFilledButton: {
    padding: "2px 14px 2px 14px",
    gap: "10px",
    borderRadius: "160px",
    ...buttonTextStyles,
    backgroundColor: "var( --shaded-green)",
    color: "var(--secondary-green)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "500",
    textTransform: "none",
  },
  redFilledButton: {
    padding: "2px 14px",
    color: "var(--secondary-red)",
    backgroundColor: "var(--shaded-red)",
    ...buttonTextStyles,
    borderRadius: "160px",
    textTransform: "none",
  },
};
