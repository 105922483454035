export const sendRemaindersAndShareMarketData = {
  recieverName: "Cristofer Stanton",
  dropdownData: [
    {
      value: "1",
      name: "ABC LIFE INSURANCE",
    },
    {
      value: "2",
      name: "XYZ LIFE INSURANCE",
    },
  ],
  fileUploadName: "ABC LIFE INSURANCE",
  fileUploadSize: "1.23MB",
};
