import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { paymentdetaildata } from "../../constants/PaymentScheduleCardData";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import dayjs from "dayjs";
import {
  DOWNLOAD,
  FILTER,
  PAYMENT_SCHEDULE,
  UPCOMING_EVENTS,
} from "../../constants";
import { PaymentScheduleStyles } from "./PaymentScheduleStyles";
import HeadingText from "../../common/HeadingText/HeadingText";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import DateComponent from "../../common/DateComponent/DateComponent";
import PaymentScheduleCard from "../../common/PaymentScheduleCard/PaymentScheduleCard";
const PaymentSchedule: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  const highlightedDays = [
    dayjs(`2024-09-01`),
    dayjs(`2024-09-14`),
    dayjs(`2024-09-25`),
    dayjs(`2024-10-01`),
    dayjs(`2024-10-14`),
  ];
  return (
    <>
      <Box sx={PaymentScheduleStyles.container}>
        <HeadingText
          additionalHeadingClass={PaymentScheduleStyles.headingText}
          heading={PAYMENT_SCHEDULE}
        />
        <Box sx={PaymentScheduleStyles.innerContainer}>
          <DateComponent highlightedDays={highlightedDays} />
          <Box sx={PaymentScheduleStyles.upComingEventsContainer}>
            <Box sx={PaymentScheduleStyles.upcomingContainer}>
              <Typography sx={PaymentScheduleStyles.upcomingText}>
                {UPCOMING_EVENTS}
              </Typography>
              <Box sx={PaymentScheduleStyles.rightContainer}>
                <ImageAndText
                  additionalClassName={
                    PaymentScheduleStyles.imageAndTextContainer
                  }
                  onhandleClick={handleClick}
                  image={filterIcon}
                  text={FILTER}
                />
                <Typography sx={PaymentScheduleStyles.line}>|</Typography>
                <ImageAndText
                  additionalClassName={
                    PaymentScheduleStyles.imageAndTextContainer
                  }
                  image={downloadIcon}
                  text={DOWNLOAD}
                />
              </Box>
            </Box>
            <Box sx={PaymentScheduleStyles.totalContainer}>
              <PaymentScheduleCard paymentdetaildata={paymentdetaildata} />
            </Box>
          </Box>
        </Box>
      </Box>
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default PaymentSchedule;
