import {
  Box,
  SelectChangeEvent,
  SxProps,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { SendRemainderStyles } from "./SendRemainderStyles";
import DropDown from "../DropDown/DropDown";
import MultilineTextFields from "../MultilineTextFields/MultilineTextFields";
import CustomButton from "../CustomButton/CustomButton";
import {
  CANCEL,
  SAVE_CHANGES,
  RESET,
  SHARE_MARKETING_COLLATERAL,
  REMAINDER_TYPE,
  ALONG_WITH_THE,
  TO,
  REGARDING,
  SEND_REMAINDER_TO,
  REMOVE,
} from "../../constants";
import crossMark from "../../assets/svgs/cross-mark.svg";
import { Theme } from "@emotion/react";

interface DropDownData {
  value: string | number;
  name: string;
}

interface SendRemainderProps {
  recieverName: string;
  dropDownData: DropDownData[];
  handleRemainderChange: (event: SelectChangeEvent) => void;
  selectedDropdownValue: string;
  primary?: boolean;
  fileUploadName?: string;
  fileUploadSize?: string;
  additionalClassNameText?: SxProps<Theme>;
  onCancelClick?: () => void;
}

const SendRemainder: React.FC<SendRemainderProps> = ({
  recieverName,
  dropDownData,
  handleRemainderChange,
  selectedDropdownValue,
  primary,
  fileUploadName,
  fileUploadSize,
  additionalClassNameText,
  onCancelClick,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(true);
  const isSmallScreen = useMediaQuery("(max-width:1023px)");

  const handleRemoveFileUpload = () => {
    setShowFileUpload(false);
  };

  return (
    <Box sx={SendRemainderStyles.container}>
      <Box sx={SendRemainderStyles.remainderContainer}>
        <Box>
          {primary ? (
            <Box sx={SendRemainderStyles.sendRemainderContainer}>
              <Box sx={SendRemainderStyles.sendRemainder}>
                <Typography sx={SendRemainderStyles.sendRemainderText}>
                  {SHARE_MARKETING_COLLATERAL}
                </Typography>
                <DropDown
                  data={dropDownData}
                  placeholder={REMAINDER_TYPE}
                  selectedValue={selectedDropdownValue}
                  onChange={handleRemainderChange}
                  formControlAdditionalClassName={SendRemainderStyles.dropDown}
                />
              </Box>
              <Typography sx={SendRemainderStyles.sendRemainderTextSubheading}>
                {TO} {recieverName} {ALONG_WITH_THE}
              </Typography>
            </Box>
          ) : (
            <Box sx={SendRemainderStyles.sendRemainder}>
              <Typography sx={SendRemainderStyles.sendRemainderText}>
                {SEND_REMAINDER_TO} {recieverName} {REGARDING}
              </Typography>
              <DropDown
                data={dropDownData}
                placeholder={REMAINDER_TYPE}
                selectedValue={selectedDropdownValue}
                onChange={handleRemainderChange}
                formControlAdditionalClassName={SendRemainderStyles.dropDown}
              />
            </Box>
          )}
        </Box>
        <MultilineTextFields
          bottomComponent={
            primary && showFileUpload ? (
              <Box sx={SendRemainderStyles.fileUploadChip}>
                {isSmallScreen ? (
                  <Box>
                    <Typography sx={SendRemainderStyles.fileUploadChipText}>
                      {fileUploadName}
                    </Typography>
                    <Typography sx={SendRemainderStyles.fileUploadChipFileSize}>
                      {fileUploadSize}
                    </Typography>
                  </Box>
                ) : (
                  <Typography sx={SendRemainderStyles.fileUploadChipText}>
                    {fileUploadName} ({fileUploadSize})
                  </Typography>
                )}
                <img
                  src={crossMark}
                  alt={REMOVE}
                  onClick={handleRemoveFileUpload}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            ) : null
          }
          variant={"secondary"}
          rows={26}
          textAreaStyles={SendRemainderStyles.textArea}
        />
      </Box>
      <Box sx={SendRemainderStyles.buttonsContainer}>
        <Box sx={SendRemainderStyles.resetButtons}>
          <CustomButton
            additionalClassName={SendRemainderStyles.button}
            text={RESET}
            type={"outlined"}
          />
          <CustomButton
            additionalClassName={SendRemainderStyles.button}
            text={CANCEL}
            type={"outlined"}
            onClick={onCancelClick}
          />
        </Box>
        <CustomButton
          additionalClassName={SendRemainderStyles.saveButton}
          text={SAVE_CHANGES}
        />
      </Box>
    </Box>
  );
};

export default SendRemainder;
