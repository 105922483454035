import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { login } from "./userAuthActions";
import {
  IUserAuthSlice,
  IUserDetails,
} from "../../../models/user/userAuth/userAuth";

const initialState: IUserAuthSlice = {
  userDetails: null,
};

const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: initialState,
  reducers: {
    setUserDetails: (state, { payload }: PayloadAction<IUserDetails>) => {
      state.userDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        state.userDetails = payload.data;
      }
    });
  },
});

export default userAuthSlice.reducer;
export const { setUserDetails } = userAuthSlice.actions;
