export const documentListingListViewTableHeadersData = [
  "Document Type & Name",
  "Last Modified",
];
export const documentListingListViewData = [
  {
    folderName: "Folder 1",
    lastModified: "05 Aug‘24",
  },
  {
    folderName: "Folder 2",
    lastModified: "05 Aug‘24",
  },
  {
    folderName: "Folder 3",
    lastModified: "05 Aug‘24",
  },
  {
    folderName: "Folder 4",
    lastModified: "05 Aug‘24",
  },
  {
    folderName: "Folder 5",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 1",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 2",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 3",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 4",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 5",
    lastModified: "05 Aug‘24",
  },
  {
    fileName: "File 6",
    lastModified: "05 Aug‘24",
  },
];
