import React from "react";
import CustomerListingCard from "../../common/CustomerListingCard/CustomerListingCard";
import { Box } from "@mui/material";
import { CustomerListingResponsiveStyles } from "./CustomerListingResponsiveStyles";
import { CustomerListingProps } from "../CustomerList/CustomerList";
interface CustomerListCardProps {
  customerListingData: CustomerListingProps[];
}
const CustomerListCards: React.FC<CustomerListCardProps> = ({
  customerListingData,
}) => {
  return (
    <>
      <Box sx={CustomerListingResponsiveStyles.customerListingContainer}>
        <Box sx={CustomerListingResponsiveStyles.container}>
          {customerListingData.map((customer, index) => (
            <CustomerListingCard
              key={index}
              image={
                typeof customer.customerDetails === "string"
                  ? ""
                  : customer.customerDetails.image
              }
              name={
                typeof customer.customerDetails === "string"
                  ? ""
                  : customer.customerDetails.customerName
              }
              policyNo={
                typeof customer.customerDetails === "string"
                  ? ""
                  : customer.customerDetails.customerNumber
              }
              phoneNumber={
                typeof customer.contactDetails === "object" &&
                "customerPhoneNumber" in customer.contactDetails
                  ? customer.contactDetails.customerPhoneNumber
                  : ""
              }
              email={
                typeof customer.contactDetails === "object" &&
                "customerEmail" in customer.contactDetails
                  ? customer.contactDetails.customerEmail
                  : ""
              }
              policyStatus={
                typeof customer.customerPolicyStatus === "object"
                  ? {
                      active: customer.customerPolicyStatus.active || 0,
                      pending: customer.customerPolicyStatus.pending || 0,
                      expired: customer.customerPolicyStatus.expired || 0,
                      tasks: customer.customerPolicyStatus.tasks || 0,
                    }
                  : { active: 0, pending: 0, expired: 0, tasks: 0 }
              }
              claimStatus={
                typeof customer.claimStatus === "object"
                  ? {
                      open: customer.claimStatus.open || 0,
                      closed: customer.claimStatus.closed || 0,
                    }
                  : { open: 0, closed: 0 }
              }
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CustomerListCards;
