import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";

export const clearChat = createAsyncThunk(
  "isureAssist/clearChat",
  async (userId: string, { dispatch }) => {
    await CustomAction(
      Api.get(`ia/${userId}/clear/qa`),
      dispatch,
      "isureAssist/clearChat"
    );
  }
);
