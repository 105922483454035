import React from "react";
import { Box, Typography, InputBase } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ResponsiveLeinholderTabComponent from "./ResponsiveLeinholderTab";
import LeinholderStyles from "./LeinholderStyles";
import upDownArrow from "../../../assets/upDownArrow.svg";
import { SEARCH } from "../../../constants/Constants";
import leinholderData from "../../../mockJson/CustomerDashboard/LeinholderData.json";

const LeinholderTabComponent: React.FC = () => {
  return (
    <Box
      aria-label="leinholder container"
      sx={LeinholderStyles.leinholderContainer}
    >
      <Box aria-label="search box" sx={LeinholderStyles.searchBox}>
        <Box aria-label="search-input" sx={LeinholderStyles.searchInput}>
          <InputBase
            sx={LeinholderStyles.searchPlaceholder}
            placeholder="Search By Name / Bank Name"
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={SEARCH} alt="search Icon" />
          </Box>
        </Box>
      </Box>
      <TableContainer sx={LeinholderStyles.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={LeinholderStyles.tableRow}>
              {leinholderData[0] &&
                Object.entries(leinholderData[0]).map(
                  ([key, value], index) =>
                    key !== "id" && (
                      <TableCell sx={{ border: "0px" }} key={index}>
                        <Box sx={LeinholderStyles.tableHeadCellContainer}>
                          <Typography sx={LeinholderStyles.tableHeadText}>
                            {value}
                          </Typography>
                          {!key.includes("emptyCell") && (
                            <img src={upDownArrow} alt="up-down arrow" />
                          )}
                        </Box>
                      </TableCell>
                    )
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {leinholderData.slice(1).map((row) => (
              <TableRow sx={LeinholderStyles.leinholderTableRows} key={row.id}>
                <TableCell sx={LeinholderStyles.tableBodyCells}>
                  {row.name}
                </TableCell>
                <TableCell sx={LeinholderStyles.tableBodyCells}>
                  {row.address}
                </TableCell>
                <TableCell sx={LeinholderStyles.tableBodyCells}>
                  {row.contactNumber}
                </TableCell>
                <TableCell sx={LeinholderStyles.tableBodyCells}>
                  {row.bankName}
                </TableCell>
                <TableCell sx={LeinholderStyles.tableBodyCells}>
                  {row.accountNumber}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={LeinholderStyles.cardContainer}>
        <ResponsiveLeinholderTabComponent />
      </Box>
    </Box>
  );
};

export default LeinholderTabComponent;
