export const InvoicesCardStyles = {
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "16px",
    columnGap: "53px",
    padding: "0px 0px 18px 0px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    paddingTop: "4px",
  },
  container: {
    padding: "20px",
    width: "100%",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
    paddingBottom: "14px",
  },
  heading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  subheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  headingContainer: {
    display: "flex",
    gap: "0px",
    flexDirection: "column",
    minWidth: "70px",
  },
};
