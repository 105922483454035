import { Box, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import Alert from "./Alert/Alert";
import { AlertsStyles } from "./AlertsStyles";
import React, { useEffect, useMemo, useState } from "react";
import { getUserPreferences } from "../../../store/user/profile/profileActions";
import moment from "moment";
import { IAlert } from "../../../models/user/alert/alert";
import CustomDialog from "../../../common/CustomDialog/CustomDialog";
import Payment from "../../Payment/Payment";

const Alerts: React.FC = () => {
  const dispatch = useAppDispatch();

  const { features } = useAppSelector((state) => state.features);
  const { calledReducerType } = useAppSelector((state) => state.common);

  const { userDetails } = useAppSelector((state) => state.userAuth);

  const { userSelectedPreferences } = useAppSelector((state) => state.profile);

  const { policiesData } = useAppSelector(
    (state: RootState) => state.dashboard
  );

  const [openPaymentPopup, setOpenPaymentPopup] = useState<boolean>(false);
  const [currentSelectedAmount, setCurrentSelectedAmount] =
    useState<string>("");
  const [policyNumber, setPolicyNumber] = useState<string>("No Policy");

  const handlePayNow = (amount: string, carrierPolicyNumber: string) => {
    setPolicyNumber(carrierPolicyNumber);
    setCurrentSelectedAmount(amount);
    setOpenPaymentPopup(true);
  };

  const handlePaymentDialogeClose = () => {
    setOpenPaymentPopup(false);
  };

  const alerts: IAlert[] = useMemo(() => {
    const alerts: IAlert[] = [];
    if (
      userDetails &&
      policiesData &&
      policiesData.policyList &&
      policiesData.policyList.length > 0 &&
      userSelectedPreferences
    ) {
      if (userSelectedPreferences.length === 0) {
        policiesData.policyList.forEach((policy) => {
          if (
            policy.dueDate &&
            policy.dueAmount &&
            moment(policy.dueDate).diff(moment(), "days") <= 7
          ) {
            alerts.push({
              insuredName: policy.insuredName,
              carrierPolicyNo: policy.carrierPolicyNo,
              dueAmount: policy.dueAmount,
              dueDate: policy.dueDate,
            });
          }
          if (policy.isLegalNotice) {
            alerts.push({
              insuredName: userDetails?.customerName,
              carrierPolicyNo: policy.carrierPolicyNo,
              isLegalNotice: policy.isLegalNotice,
            });
          }
          if (policy.isRefundAvailable) {
            policy.refunds.forEach((refund) =>
              alerts.push({
                insuredName: userDetails?.customerName,
                carrierPolicyNo: policy.carrierPolicyNo,
                isRefundAvailable: policy.isRefundAvailable,
                refund: refund,
              })
            );
          }
        });
      } else {
        policiesData.policyList.forEach((policy) => {
          if (policy.dueDate && policy.dueAmount) {
            const daysToDueDate = moment(policy.dueDate).diff(moment(), "days");
            if (
              userSelectedPreferences.some(
                (userPreference) =>
                  userPreference.preferenceType === "policyUpdates" &&
                  userPreference.preferenceChannel === "pushNotifications" &&
                  parseInt(userPreference.duration) >= daysToDueDate
              )
            ) {
              alerts.push({
                insuredName: policy.insuredName,
                carrierPolicyNo: policy.carrierPolicyNo,
                dueAmount: policy.dueAmount,
                dueDate: policy.dueDate,
              });
            }
          }
          if (policy.isLegalNotice) {
            alerts.push({
              insuredName: userDetails?.customerName,
              carrierPolicyNo: policy.carrierPolicyNo,
              isLegalNotice: policy.isLegalNotice,
            });
          }
          if (policy.isRefundAvailable) {
            policy.refunds.forEach((refund) =>
              alerts.push({
                insuredName: userDetails?.customerName,
                carrierPolicyNo: policy.carrierPolicyNo,
                isRefundAvailable: policy.isRefundAvailable,
                refund: refund,
              })
            );
          }
        });
      }
    }
    return alerts;
  }, [policiesData, userSelectedPreferences, userDetails]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.customerId &&
      calledReducerType === "dashboard/getClaimsData"
    ) {
      dispatch(getUserPreferences(userDetails.customerId));
    }
  }, [userDetails, calledReducerType, dispatch]);

  return (
    <>
      {features.some(
        (feature) => feature.featureCode === "alerts" && feature.selected
      ) && alerts.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "0px 30px 30px 30px",
          }}
        >
          <Typography sx={AlertsStyles.heading}>Alerts</Typography>
          {alerts.map((alert: IAlert, index: number) => (
            <Alert
              key={`${alert.carrierPolicyNo}_${index}`}
              alert={alert}
              handlePayNow={handlePayNow}
            />
          ))}
        </Box>
      ) : (
        <></>
      )}
      <CustomDialog
        showDivider={true}
        open={openPaymentPopup}
        maxWidth="lg"
        onClose={handlePaymentDialogeClose}
        title={`Payment for Policy Number : ${policyNumber}`}
        content={
          <Payment
            amount={currentSelectedAmount}
            carrierPolicyNumber={policyNumber}
          />
        }
      />
    </>
  );
};

export default Alerts;
