export const BasicInfoQuickStyles = {
    BasicInfoQuickActionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: "26px 30px 19px 30px",
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.09) inset',
        background: "#FCFEFF",
        color: '#1D252B',
        borderRadius: '0 9px 9px 0',
        "@media (max-width: 399px)": {
            borderRadius: '0 0 10px 10px',
            padding: "0px",
        },
        "@media (min-width: 399px) and (max-width: 799px)": {
            borderRadius: '0 0 9px 9px',
            padding: "0px",
        },
        "@media (min-width: 800px) and (max-width: 1300px)": {
            borderRadius: '0px 0px 9px 9px',
            padding: "15px 20px",
        },
    },
    BasicInfoQuickActionCard: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridAutoRows: "minmax(10px, auto)",
        gap: "10px",
        columnGap: '20px',
        "@media (max-width: 599px)": {
            display: "flex",
        },
        "@media (min-width: 799px) and (max-width: 1300px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        "@media (min-width: 1300px) and (max-width: 1920px)": {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridAutoRows: "minmax(10px, auto)",
            gap: "10px",
            columnGap: '20px',
        },
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        textTransform: "none",
        height: "35px",
        width: "fit-content",
        borderRadius: "20px",
        backgroundColor: "#FFF",
        padding: "8.5px 15px",
        minWidth: "151px",
        flexShrink: 0,
        color: "#1D252B",
        fontSize: "16px",
        fontFamily: "Noto Sans",
        lineHeight: "110%",
        fontWeight: 300,
        fontStyle: "normal",
        border: "1px solid #B3C2C8",
        gap: "10px",
        img: {
            marginTop: "3px"
        },
        '&:hover': {
            backgroundColor: "#FFF",
        }
    },
    container: {
        display: "flex",
        alignItems: "center",
        margin: "15px 20px",
        justifyContent: "space-between",
    },
    buttonsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "20px",
    },
    contactPop: {
        marginTop: "10px",
        marginLeft: "15px",
        "@media(max-width: 780px)": {
            marginTop: "0px",
            marginLeft: "0px",
        },
    },
    morePop: {
        marginTop: "1px",
        "& .MuiPopover-paper": {
            borderRadius: "5px",
            boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
        },
    }
}