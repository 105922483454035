const BasicInfoCardQuickActionsStyles = {
  BasicInfoQuickActionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.09) inset",
    background: "#FCFEFF",
    color: "#1D252B",
    borderRadius: "0 9px 9px 0",
    "@media (max-width: 1200px)": {
      flexDirection: "row",
      alignItems: "center",
    },
    "@media (max-width: 900px)": {
      justifyContent: "space-between",
    },
  },
  quickStyle: {
    color: "#1D252B",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 300,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
};

export default BasicInfoCardQuickActionsStyles;
