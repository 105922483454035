import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_GET_LOOKUP_LIST,
  API_DELETE_LOOKUP_ADD,
  API_POST_LOOKUP_ADD,
} from "../../../Apis/ApiEndPoints";
import { Api } from "../../..";
import {
  ILookup,
  ILookupListResponse,
} from "../../../models/admin/lookup/Lookup";
import { CustomAction } from "../../customAction";
import { ICommonResponse } from "../../../models/user/common/Common";

export const getLookups = createAsyncThunk(
  "lookup/getLookups",
  async (_, { dispatch }) => {
    const response: ILookupListResponse = await CustomAction(
      Api.get(API_GET_LOOKUP_LIST),
      dispatch,
      "lookup/getLookups"
    );
    return response;
  }
);

export const deleteLookup = createAsyncThunk(
  "lookup/deleteLookup",
  async (requestParams: ILookup, { dispatch }) => {
    const response: ICommonResponse = await CustomAction(
      Api.delete(
        `${API_DELETE_LOOKUP_ADD}/${requestParams.name}/${requestParams.code}`
      ),
      dispatch,
      "lookup/deleteLookup"
    );
    return response;
  }
);
export const addLookUp = createAsyncThunk(
  "lookup/addLookUp",
  async (requestParams: ILookup, { dispatch }) => {
    const response: ICommonResponse = await CustomAction(
      Api.post(API_POST_LOOKUP_ADD, requestParams),
      dispatch,
      "lookup/addLookUp"
    );
    return response;
  }
);
