import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SxProps, Typography } from "@mui/material";
import { TabsStyles } from "./BasicTabsStyles";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface MuiTabsProps {
  tabs: Array<{
    label: string;
    imageUrl?: string;
    activeImageUrl?: string;
    secondaryLabel?: string;
    content: React.ReactNode;
  }>;
  additionalLabelClass?: SxProps<Theme>;
}
const StyledTabs = styled(Tabs)({
  display: "flex",
  "& .MuiTabs-flexContainer": {
    gap: "30px",
    "& .MuiTab-root": {
      padding: "10px 20px !important",
    },
  },
});
const StyledTab = styled(Tab)({
  fontSize: "var(--font-size-16)",
  fontWeight: "var(--font-weight-500)",
  lineHeight: 0,
  textAlign: "left",
  textTransform: "none",
  color: "var(--text-black)",
  "&.Mui-selected": {
    color: `var(--black) !important`,
    fontWeight: 700,
  },
});
const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingTop: "23px" }}>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const BasicTabs = ({ tabs, additionalLabelClass }: MuiTabsProps) => {
  const [value, setValue] = React.useState(0);
  if (!tabs) {
    return null;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={TabsStyles.tabContainer}>
      <Box sx={TabsStyles.tabBox}>
        <StyledTabs
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--black)",
              border: `2px solid var(--black)`,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, index) => (
            <StyledTab
              key={index}
              label={
                tab.imageUrl ? (
                  <Box sx={TabsStyles.tabs}>
                    <Box sx={TabsStyles.icon}>
                      <img
                        src={
                          value === index ? tab.activeImageUrl : tab.imageUrl
                        }
                        alt={`Icon for ${tab.label}`}
                      />
                    </Box>
                    {tab.secondaryLabel && (
                      <Typography sx={TabsStyles.secondaryLabel}>
                        {tab.secondaryLabel}
                      </Typography>
                    )}
                    <Typography sx={TabsStyles.label}>{tab.label}</Typography>
                  </Box>
                ) : (
                  <Box sx={TabsStyles.tabs}>
                    {tab.secondaryLabel && (
                      <Typography sx={TabsStyles.secondaryLabel}>
                        {tab.secondaryLabel}
                      </Typography>
                    )}
                    <Typography
                      sx={Object.assign(
                        {},
                        TabsStyles.label,
                        additionalLabelClass
                      )}
                    >
                      {" "}
                      {tab.label}{" "}
                    </Typography>
                  </Box>
                )
              }
              {...a11yProps(index)}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};
export default BasicTabs;
