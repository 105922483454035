export const customerDetailsOverviewPolicyListingTableHeadingsData = [
  "",
  "Policy Details",
  "Status",
  "Premium",
  "Term",
  "ID No: 12345",
  "Next Payment",
];
export const customerDetailsOverviewPolicyListing = [
  {
    policyType: "House",
    policyDetails: {
      policyNumber: "12AW1145G057",
      policyTypeNumber: "House_02 Policy",
    },

    policyStatus: "Pending",
    policyPremium: "$821",
    policyTerm: "01 Year",
    policyIdNo: "Customer Name 2",
    policyLastPayment: "14 Jul ‘ 24 | $150",
  },
  {
    policyType: "House",
    policyDetails: {
      policyNumber: "12AW1145G057",
      policyTypeNumber: "House_02 Policy",
    },
    policyStatus: "Pending",
    policyPremium: "$821",
    policyTerm: "01 Year",
    policyIdNo: "Customer Name 2",
    policyLastPayment: "14 Jul ‘ 24 | $150",
  },
  {
    policyType: "House",
    policyDetails: {
      policyNumber: "12AW1145G057",
      policyTypeNumber: "House_02 Policy",
    },
    policyStatus: "Pending",
    policyPremium: "$821",
    policyTerm: "01 Year",
    policyIdNo: "Customer Name 2",
    policyLastPayment: "14 Jul ‘ 24 | $150",
  },
];
