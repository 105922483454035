import React from 'react'
import { Box } from '@mui/material'
import CustomerDetailsNotes from '../CustomerDetailsNotes/CustomerDetailsNotes'
import { personChat } from '../../constants/myTasksPersonChatData'
import { MyTasksNotesResponsoiveStyles } from './MyTasksNotesResponsoiveStyles'
import DashboardBreadCrumb from '../../common/DashboardBreadCrumb/DashboardBreadCrumb'
import { useNavigate } from 'react-router-dom'
import { NOTES } from '../../constants'

const MyTasksNotesResponsoive = () => {
    const navigate = useNavigate();
  return (
   <Box sx={MyTasksNotesResponsoiveStyles.container}>
        <DashboardBreadCrumb variant={'primary'} text={NOTES} additionalContianerClass={MyTasksNotesResponsoiveStyles.breadCrumb}  handleClick={() => navigate(-1)}  />
        <CustomerDetailsNotes personChatArray={personChat} additionalChatContainer={MyTasksNotesResponsoiveStyles.chatContainer} additionalSeparatorClass={MyTasksNotesResponsoiveStyles.separator} />
   </Box>
  )
}

export default MyTasksNotesResponsoive