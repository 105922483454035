export const TabSwitchStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  tab1Styles: {
    position: "relative",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    zIndex: 2,
    cursor: "pointer",
    padding: "9px 25px",
    transition: "color 250ms ease-in-out",
    "> img": {
      width: "13px",
      height: "16px",
    },
    "@media (max-width: 1023px)": {
      padding: "9px 34px",
    },
  },
  tab1TextStyles: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: 500,
    lineHeight: 1,
    textTransform: "none",
    display: "inline-block",
    textAlign: "center",
  },
  tab2TextStyles: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: 500,
    display: "inline-block",
    lineHeight: 1,
    textTransform: "none",
    textAlign: "center",
    position: "relative",
  },
  tab2Styles: {
    zIndex: 2,
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    padding: "9px 25px",
    transition: "color 250ms ease-in-out",
    "> img": {
      width: "13px",
      height: "16px",
    },
    "@media (max-width: 1023px)": {
      padding: "9px 34px",
    },
  },
  innerContainer: {
    display: "block",
    float: "left",
    margin: 0,
    padding: 0,
    listStyle: "none",
    position: "relative",
    borderRadius: "35px",
    overflow: "hidden",
    backgroundColor: "white",
    height: "36px",
    userSelect: "none",
    border: "1px solid var(--ternary-grey)",
  },
  tabSwitchContainer: {
    content: '""',
    width: "50%",
    backgroundColor: "var(--support-white)",
    height: "100%",
    position: "absolute",
    top: 0,
    transition: "all 250ms ease-in-out",
    borderRadius: "35px",
  },
  tabInnerContainer: {
    float: "left",
  },
  tabSwitchInnerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
};
