import React from "react";
import { Props } from "./Props";

const PolicyChangeIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 41"
      fill="none"
    >
      <path d="M18.7773 15.3535H33.7268L18.7773 0V15.3535Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55556 3.94531C3.47996 3.94531 2.44841 4.37259 1.68784 5.13316C0.92728 5.89372 0.5 6.92527 0.5 8.00087V36.4453C0.5 37.5209 0.92728 38.5525 1.68784 39.313C2.44841 40.0736 3.47995 40.5009 4.55556 40.5009H25.8889C26.9645 40.5009 27.996 40.0736 28.7566 39.313C29.5172 38.5525 29.9444 37.5209 29.9444 36.4453V15.112C29.9444 14.9794 29.8918 14.8522 29.798 14.7584L19.1313 4.09176C19.0376 3.99799 18.9104 3.94531 18.7778 3.94531H4.55556ZM2.39495 5.84026C2.96798 5.26724 3.74517 4.94531 4.55556 4.94531H18.2773V15.112C18.2773 15.3881 18.5012 15.612 18.7773 15.612H28.9444V36.4453C28.9444 37.2557 28.6225 38.0329 28.0495 38.6059C27.4765 39.1789 26.6993 39.5009 25.8889 39.5009H4.55556C3.74517 39.5009 2.96798 39.1789 2.39495 38.6059C1.82192 38.0329 1.5 37.2557 1.5 36.4453V8.00087C1.5 7.19048 1.82192 6.41329 2.39495 5.84026ZM28.2373 14.612L19.2773 5.65199V14.612H28.2373Z"
        fill="#262729"
      />
    </svg>
  );
};

export default PolicyChangeIcon;
