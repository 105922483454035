import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAdminLoginRequestBody,
  IAdminLoginResponse,
} from "../../../models/admin/adminAuth/adminAuth";
import { Api } from "../../..";
import { CustomAction } from "../../customAction";
import {
  API_GET_ADMIN_LOGOUT,
  API_POST_ADMIN_LOGIN,
} from "../../../Apis/ApiEndPoints";

export const adminLogin = createAsyncThunk(
  "adminAuth/adminLogin",
  async (requestBody: IAdminLoginRequestBody, { dispatch }) => {
    const response: IAdminLoginResponse = await CustomAction(
      Api.post(API_POST_ADMIN_LOGIN, requestBody),
      dispatch,
      "adminAuth/adminLogin"
    );

    return response;
  }
);

export const adminLogout = createAsyncThunk(
  "adminAuth/adminLogout",
  async (_, { dispatch }) => {
    await CustomAction(
      Api.get(API_GET_ADMIN_LOGOUT),
      dispatch,
      "adminAuth/adminLogout"
    );
  }
);
