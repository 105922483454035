import React, { useState } from "react";
import { Box, Link, Popover, Typography } from "@mui/material";
import { ClaimActivityCardStyles } from "./ClaimActivityCardStyles";
import { IClaimTransaction } from "../../../../../../models/user/dashboard/Dashboard";
import CustomIcon from "../../../../../../common/CustomIcon/CustomIcon";

interface Props {
  transaction: IClaimTransaction;
  isLast?: boolean;
}

const ClaimActivityCard: React.FC<Props> = ({ transaction, isLast }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const getActivityIcon = (transactionType: string) => {
    switch (transactionType) {
      case "New Business":
        return <CustomIcon iconName="Attention" />; // attensionsIcon;
      case "Expire":
        return <CustomIcon iconName="PolicyRenewal" />; // PolicyRenewal;
      case "Policy Change":
        return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
      case "Claim Closed":
        return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
      case "Reinstatement":
        return <CustomIcon iconName="Star" />; // star;
      case "Claim Approved":
        return <CustomIcon iconName="Star" />; // star;
      case "Cancellation":
        return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
      default:
        return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
    }
  };

  const handleContactPersonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box
        sx={{
          ...ClaimActivityCardStyles.activityCard,
          borderBottom: isLast ? "none" : "1px solid rgba(201, 225, 236, 1)",
        }}
      >
        <Box sx={ClaimActivityCardStyles.activityCardContainer}>
          <Box sx={ClaimActivityCardStyles.icon}>
            {getActivityIcon(transaction?.identifier)}
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: "12px",
              gridArea: "status",
              alignItems: "center",
            }}
          >
            <Typography sx={ClaimActivityCardStyles.heading}>
              {transaction.carrierClaimStatus}
            </Typography>
            <Typography sx={ClaimActivityCardStyles.description}>
              {transaction.carrierClaimNo}
            </Typography>
          </Box>
          <Box
            sx={{
              gridArea: "description",
              display: "grid",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <Typography
              sx={{
                ...ClaimActivityCardStyles.description,
                whiteSpace: "wrap",
              }}
            >
              {transaction.transactionDescription}
            </Typography>
            {transaction.adjusterDetails ? (
              <Link
                sx={{
                  ...ClaimActivityCardStyles.description,
                  cursor: "pointer",
                }}
                onClick={handleContactPersonClick}
              >
                Contact Person
              </Link>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={ClaimActivityCardStyles.contactPopoverContent}>
          <Box>
            <Typography sx={ClaimActivityCardStyles.description}>
              Name
            </Typography>
            <Typography sx={ClaimActivityCardStyles.heading}>
              {transaction.adjusterDetails
                ? transaction.adjusterDetails.nameInfo
                : ""}
            </Typography>
          </Box>
          {transaction?.adjusterDetails?.emailInfo ? (
            <Box>
              <Typography sx={ClaimActivityCardStyles.description}>
                Email Id
              </Typography>
              <Typography sx={ClaimActivityCardStyles.heading}>
                {transaction.adjusterDetails
                  ? transaction.adjusterDetails.emailInfo
                  : ""}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Box>
            <Typography sx={ClaimActivityCardStyles.description}>
              Mobile Number
            </Typography>
            <Typography sx={ClaimActivityCardStyles.heading}>
              {transaction &&
              transaction.adjusterDetails &&
              transaction.adjusterDetails.phoneInfo &&
              transaction?.adjusterDetails?.phoneInfo.length > 0
                ? transaction?.adjusterDetails?.phoneInfo
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ClaimActivityCard;
