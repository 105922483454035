export const LatestNewsStyles = {
  container: {
    display: "flex",
    gap: "18px",
    paddingTop: "5px",
    flexDirection: "column",
    paddingLeft: "2px",
    "@media screen and (max-width: 599px)": {
      gap: "10px",
    },
  },
};
