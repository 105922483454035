import { Box, Typography } from "@mui/material";
import ResponsiveCoverages from "./ResponsiveCoverages/ResponsiveCoverages";
import { CoveragesStyles } from "./CoveragesStyles";
import Premium from "./Premium/Premium";
import CoveragesAndLimits from "./CoveragesAndLimits/CoveragesAndLimits";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../store/store";
import { IColumn } from "../../../../common/CustomTable/CustomTable";
import { currencyFormat } from "../../../../utils/Utility";
import Discounts from "./Discounts/Discounts";
import { GetPolicyCoveragesAndDiscounts } from "../../../../store/user/policyDetails/policyDetailsActions";

const Coverages = () => {
  const dispatch = useAppDispatch();

  const { coveragesAndDiscounts } = useAppSelector(
    (store: RootState) => store.policyDetails
  );
  const { policyId } = useParams();

  useEffect(() => {
    if (policyId) {
      dispatch(GetPolicyCoveragesAndDiscounts({ policyId }));
    }
  }, [policyId, dispatch]);

  const getCoverageData = () => {
    const dataArray: any[] = [];
    const headerArray: IColumn[] = [
      {
        label: "Coverages",
        Cell: (row: any) => {
          return (
            <>
              <Typography sx={CoveragesStyles.coverageText}>
                {row ? row.coverageName : ""}
              </Typography>
              <Typography sx={CoveragesStyles.coverageSubText}>
                {row && row.coverageSubText ? `(${row.coverageSubText})` : ""}
              </Typography>
            </>
          );
        },
      },
      {
        label: "Limit",
        styles: { textAlign: "right" },
        Cell: (row: any) => {
          let defaultVal = "N/A";
          if (row && row.limit) {
            const limitArray = row.limit.split("/");
            if (limitArray[2]) {
              defaultVal = `${currencyFormat(
                "us",
                limitArray[0]
              )} / ${currencyFormat(
                "us",
                limitArray[2].match(/\d/g).join("")
              )}`;
            } else if (limitArray[1]) {
              defaultVal = `${currencyFormat(
                "us",
                limitArray[0]
              )} / ${currencyFormat("us", limitArray[1])}`;
            } else {
              defaultVal = /\d/.test(limitArray[0])
                ? currencyFormat("us", limitArray[0])
                : row.limit;
            }
          }
          return (
            <Typography
              sx={{ ...CoveragesStyles.coverageText, textAlign: "right" }}
            >
              {defaultVal}
            </Typography>
          );
        },
      },
      {
        label: "Deductible",
        styles: { textAlign: "right" },
        Cell: (row: any) => {
          return (
            <Typography
              sx={{ ...CoveragesStyles.coverageText, textAlign: "right" }}
            >
              {row && row.deductible && /\d/.test(row.deductible)
                ? currencyFormat("us", row.deductible)
                : "N/A"}
            </Typography>
          );
        },
      },
    ];

    if (
      coveragesAndDiscounts?.coverages &&
      coveragesAndDiscounts?.coverages.length > 0
    ) {
      coveragesAndDiscounts.coverages.forEach((item) => {
        const vehicleKey = `${item.manufactureYr}-${item.make}-${item.model}`;
        headerArray.push({
          label: vehicleKey,
          styles: { textAlign: "right" },
          Cell: (row: any) => (
            <Typography
              sx={{ ...CoveragesStyles.coverageText, textAlign: "right" }}
            >
              {row && row[vehicleKey]
                ? currencyFormat("us", row[vehicleKey])
                : "N/A"}
            </Typography>
          ),
        });

        item.policyCoverages.forEach((coverage) => {
          let existingCoverageIndex = dataArray.findIndex(
            (row) => row.coverageName === coverage.coverageName
          );

          if (existingCoverageIndex > -1) {
            // Add this vehicle's premium to the existing coverage
            dataArray[existingCoverageIndex] = {
              ...dataArray[existingCoverageIndex],
              [vehicleKey]: coverage.coveragePremiumAmt,
            };
          } else {
            // Create a new coverage entry
            const newCoverage = {
              coverageName: coverage.coverageName,
              coverageSubText: coverage.coverageSubText,
              deductible: coverage.deductible,
              limit: coverage.limit,
              [vehicleKey]: coverage.coveragePremiumAmt,
            };
            dataArray.push(newCoverage);
          }
        });
      });
    }
    return { dataArray, headerArray };
  };

  const { headerArray, dataArray } = getCoverageData();

  return (
    <>
      <Box sx={CoveragesStyles.container}>
        <Premium
          perVehicledata={coveragesAndDiscounts?.premiumPerVehicle || []}
          totalPremium={coveragesAndDiscounts?.totalPremiumAmount || null}
        />
        <CoveragesAndLimits
          columns={headerArray || []}
          data={dataArray || []}
        />
        <Discounts />
      </Box>
      <Box sx={CoveragesStyles.container2}>
        <ResponsiveCoverages
          perVehicledata={coveragesAndDiscounts?.premiumPerVehicle || []}
          totalPremium={coveragesAndDiscounts?.totalPremiumAmount || null}
          data={dataArray || []}
        />
      </Box>
    </>
  );
};

export default Coverages;
