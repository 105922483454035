export const OverviewPolicyListingCardStyles = {
  subHeadingText: {
    fontSize: "var(--font-size-12)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "16.34px",
  },
  headingText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  imageAndTextContainer: {
    gap: "2px",
  },
  headerContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    borderBottom: "1px solid var(--primary-grey)",
    paddingBottom: "10px",
  },
  container: {
    padding: "16px 16px 12px 16px",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
    width: "100%",
  },
  innerContainer: {
    paddingTop: "14px",
    paddingBottom: "20px",
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "14px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  cardFooter: {
    display: "flex",
    gap: "34px",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingTop: "12px",
  },
  headingPolicyTypeText: {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "100%",
  },
  headingsContainer: {
    gap: "2px",
    minWidth: "84px",
  },
  subHeadingHeadingText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  headingsPolicyContainer: {
    gap: "0px",
  },
  imageAndTextContainerImage: {
    "> img": {
      width: "16px",
      height: "16px",
    },
  },
  subHeadingPolicyText: {
    fontSize: "var(--font-size-12)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "16.34px",
    maxWidth: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuIcon: {
    "> img": {
      paddingRight: "10px",
    },
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
};
