export const OverViewCardsStyles = {
  heading: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
  },
  policyCards: {
    display: "flex",
    flexWrap: "wrap",
    gap: "19px",
  },
  policyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  claimsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
  },
};
