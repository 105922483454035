export const StatsStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    rowGap: "45px",
    paddingTop: "10px",
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "25px",
      columnGap: "100px",
    },
    "@media screen and (max-width: 599px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "25px",
    },
  },
  headingsContainer: {
    gap: "4px",
    "@media screen and (max-width: 1023px)": {
      gap: "2px",
    },
    "@media screen and (max-width: 599px)": {
      minWidth: "108px",
    },
  },
  headingText: {
    "@media screen and (max-width: 599px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "22px",
    },
  },
  subheadingText: {
    "@media screen and (max-width: 599px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16px",
    },
  },
};
