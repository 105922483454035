import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box, SxProps, Typography } from "@mui/material";
import { CheckBoxStyles } from "./CheckBoxStyles";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

interface CheckBoxProps {
  heading: string;
  subheading?: string;
  additionalClass?: SxProps<Theme>;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const StyledCheckbox = styled(Checkbox)({
  color: "var(--support-white)",
  padding: "0px",
  borderRadius: "2px",
  "&.Mui-checked": {
    color: "var(--support-white)",
  },
});
const CheckBox: React.FC<CheckBoxProps> = ({
  heading,
  subheading,
  additionalClass,
  checked,
  onChange,
}) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <Box sx={{ ...CheckBoxStyles.container, ...additionalClass }}>
      <StyledCheckbox
        checked={checked}
        onChange={handleCheckboxChange}
        {...label}
      />
      <Box>
        <Typography sx={CheckBoxStyles.heading}>{heading}</Typography>
        <Typography sx={CheckBoxStyles.subheading}>{subheading}</Typography>
      </Box>
    </Box>
  );
};

export default CheckBox;
