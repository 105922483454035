import React from "react";
import { Props } from "./Props";

const ClaimsClosedIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle cx="21.1843" cy="21.1842" r="7.1101" fill={fill} />
      <path
        d="M16.4378 4.22171C19.8383 3.45341 23.3961 3.80492 26.5805 5.22381L26.9875 4.31038C23.6061 2.80372 19.8283 2.43047 16.2174 3.2463C12.6066 4.06212 9.35621 6.0233 6.95103 8.83735C4.54586 11.6514 3.11477 15.1675 2.8712 18.8614C2.62763 22.5552 3.58463 26.2288 5.59947 29.3343C7.61431 32.4399 10.579 34.8109 14.0515 36.0938C17.5239 37.3766 21.3181 37.5027 24.868 36.4531C28.418 35.4035 31.5335 33.2345 33.75 30.2695C35.9665 27.3046 37.1652 23.7023 37.1673 20.0004V18.4671H36.1673V20.0001C36.1653 23.4864 35.0365 26.8785 32.9491 29.6708C30.8617 32.463 27.9276 34.5057 24.5845 35.4941C21.2413 36.4826 17.6682 36.3639 14.398 35.1557C11.1279 33.9476 8.33584 31.7147 6.43837 28.7901C4.5409 25.8655 3.63965 22.4058 3.86903 18.9272C4.09841 15.4485 5.44614 12.1372 7.71121 9.48707C9.97627 6.83695 13.0373 4.99001 16.4378 4.22171Z"
        fill="#262729"
      />
      <path
        d="M36.313 6.3147L19.9999 22.6441L15.3536 17.9979L14.6465 18.705L19.6465 23.705C19.7403 23.7988 19.8675 23.8514 20.0002 23.8514C20.1328 23.8514 20.26 23.7986 20.3538 23.7048L37.0204 7.02145L36.313 6.3147Z"
        fill="#262729"
      />
    </svg>
  );
};

export default ClaimsClosedIcon;
