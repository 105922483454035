import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import LoginWithEmailOrMobileStyles from "./LoginWithEmailOrMobileStyles";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import OTP from "../../components/OTP/OTP";
import {
  login,
  sendOtp,
  verifyOtp,
} from "../../store/user/userAuth/userAuthActions";

interface IProps {
  isFunctionable: boolean;
}

const LoginWithEmailOrMobile: React.FC<IProps> = ({ isFunctionable }) => {
  const dispatch = useAppDispatch();

  const { isError, calledReducerType, isSuccess, message } = useAppSelector(
    (state) => state.common
  );

  const [emailOrMobile, setEmailOrMobile] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [validationErrors, setValidationErrors] = useState({
    emailOrMobile: "",
    postalCode: "",
    otp: "",
  });
  const [resendOtpTimer, setResendOtpTimer] = useState<number>(120);

  const postalCodePlaceholder = useMemo(
    () =>
      postalCode.length > 0 && postalCode.length < 10
        ? "XXXXX-XXXX or XXXXX"
        : "",
    [postalCode]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setValidationErrors((prevState) => ({ ...prevState, [name]: "" }));
      switch (name) {
        case "emailOrMobile":
          setEmailOrMobile(value);
          break;
        case "postalCode":
          setPostalCode(value);
          break;
      }
    },
    []
  );

  const handleResendOtp = useCallback(() => {
    if (emailOrMobile) {
      setResendOtpTimer(120);
      setOtp("");
      dispatch(sendOtp({ emailOrMobile }));
    }
  }, [emailOrMobile, dispatch]);

  const getOtpSection = useCallback(() => {
    if (
      (!isError && calledReducerType === "userAuth/sendOtp") ||
      (isSuccess && calledReducerType === "userAuth/sendOtp") ||
      calledReducerType === "userAuth/verifyOtp" ||
      calledReducerType === "userAuth/login"
    ) {
      return (
        <>
          <Box sx={{ display: "grid", gap: "8px" }}>
            <OTP
              separator={<span> </span>}
              value={otp}
              onChange={setOtp}
              length={6}
              disabled={
                (isSuccess && calledReducerType === "userAuth/verifyOtp") ||
                !isFunctionable
              }
            />
            {validationErrors.otp && (
              <Typography color="error">{validationErrors.otp}</Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {(isSuccess && calledReducerType === "userAuth/verifyOtp") ||
              calledReducerType === "userAuth/login" ? (
                <Typography sx={{ color: "green" }}>
                  Otp Verified Successfully
                </Typography>
              ) : (
                <Typography>PLEASE ENTER THE OTP YOU RECEIVED</Typography>
              )}
              {(isError && calledReducerType === "userAuth/verifyOtp") ||
              (!isSuccess && calledReducerType === "userAuth/verifyOtp") ||
              calledReducerType === "userAuth/sendOtp" ? (
                <Button
                  sx={{ cursor: "pointer" }}
                  onClick={handleResendOtp}
                  disabled={resendOtpTimer > 0}
                >
                  Resend Otp {resendOtpTimer ? `in ${resendOtpTimer}s` : ""}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        </>
      );
    }
    return null;
  }, [
    calledReducerType,
    isError,
    isSuccess,
    handleResendOtp,
    otp,
    validationErrors,
    resendOtpTimer,
    isFunctionable,
  ]);

  const getPostalCodeInputField = useCallback(() => {
    if (
      (isSuccess && calledReducerType === "userAuth/verifyOtp") ||
      calledReducerType === "userAuth/login"
    ) {
      return (
        <>
          <FormControl disabled={!isFunctionable}>
            <InputLabel>Postal Code</InputLabel>
            <OutlinedInput
              label="Postal Code"
              type={"text"}
              placeholder={"Please enter your postal code"}
              name="postalCode"
              onChange={handleChange}
              value={postalCode}
              error={Boolean(validationErrors.postalCode)}
              // endAdornment={
              //   <InputAdornment position="end">
              //     <Typography>{postalCodePlaceholder}</Typography>
              //   </InputAdornment>
              // }
            />
          </FormControl>
          {postalCodePlaceholder ? (
            <Typography sx={{ color: "#cccccc" }}>
              Please enter the postal in {postalCodePlaceholder} format
            </Typography>
          ) : (
            <></>
          )}
          {validationErrors.postalCode && (
            <Typography color="error">{validationErrors.postalCode}</Typography>
          )}
        </>
      );
    }
  }, [
    calledReducerType,
    handleChange,
    isSuccess,
    postalCode,
    validationErrors.postalCode,
    postalCodePlaceholder,
    isFunctionable,
  ]);

  const buttonText: string = useMemo((): string => {
    if (isSuccess && calledReducerType === "userAuth/sendOtp") {
      return "Verify Otp";
    }
    if (calledReducerType === "userAuth/verifyOtp") {
      if (isSuccess) {
        return "Login";
      }
      return "Verify Otp";
    }
    if (calledReducerType === "userAuth/login") {
      return "Login";
    }
    return "Send Otp";
  }, [isSuccess, calledReducerType]);

  const validate = useCallback(() => {
    let validationErrors = {
      emailOrMobile: "",
      postalCode: "",
      otp: "",
    };
    if (!emailOrMobile || emailOrMobile.trim().length <= 0) {
      validationErrors.emailOrMobile = "Email or Mobile is required";
    }
    if (
      isSuccess &&
      calledReducerType === "userAuth/sendOtp" &&
      otp.length < 6
    ) {
      validationErrors.otp = "Please enter a valid OTP";
    }
    if (
      (isSuccess && calledReducerType === "userAuth/verifyOtp") ||
      calledReducerType === "userAuth/login"
    ) {
      if (!postalCode || postalCode.trim().length <= 0) {
        validationErrors.postalCode = "Postal code is required";
      } else if (!/^\d{5}(-\d{4})?$/.test(postalCode)) {
        validationErrors.postalCode = "Please enter a valid postal code";
      }
    }
    return validationErrors;
  }, [emailOrMobile, postalCode, calledReducerType, isSuccess, otp]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>): void => {
      e.preventDefault();
      const errors = validate();
      console.log(errors);
      if (Object.values(errors).some((error) => error)) {
        setValidationErrors(errors);
        return;
      }
      if (
        (isSuccess && calledReducerType === "userAuth/sendOtp") ||
        (isError && calledReducerType === "userAuth/verifyOtp")
      ) {
        dispatch(verifyOtp({ emailOrMobile, otp: otp }));
      } else if (
        (isSuccess && calledReducerType === "userAuth/verifyOtp") ||
        calledReducerType === "userAuth/login"
      ) {
        dispatch(
          login({
            loginType: "emailOrMobile",
            requestParams: { emailOrMobile, postalCode },
          })
        );
      } else {
        dispatch(sendOtp({ emailOrMobile }));
      }
    },
    [
      validate,
      calledReducerType,
      dispatch,
      isSuccess,
      otp,
      emailOrMobile,
      postalCode,
      isError,
    ]
  );

  useEffect(() => {
    setValidationErrors((prevState) => ({ ...prevState, otp: "" }));
  }, [otp]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (
      resendOtpTimer > 0 &&
      isSuccess &&
      calledReducerType === "userAuth/sendOtp"
    ) {
      interval = setInterval(() => {
        setResendOtpTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    if (isError && calledReducerType === "userAuth/verifyOtp") {
      setResendOtpTimer(0);
    }
    return () => clearInterval(interval);
  }, [resendOtpTimer, isSuccess, calledReducerType, isError]);

  return (
    <form
      style={LoginWithEmailOrMobileStyles.loginWithEmailOrMobileForm}
      onSubmit={handleSubmit}
    >
      {(isError && calledReducerType === "userAuth/sendOtp") ||
      (isError && calledReducerType === "userAuth/verifyOtp") ||
      (isError && calledReducerType === "userAuth/login") ? (
        <Typography sx={{ color: "red" }}>
          {message || "Something went wrong, please  try again..."}
        </Typography>
      ) : (
        <></>
      )}
      <FormControl disabled={!isFunctionable}>
        <InputLabel>Email/Mobile</InputLabel>
        <OutlinedInput
          label="Email/Mobile"
          type={"text"}
          placeholder="Please enter your email id or mobile number"
          name="emailOrMobile"
          onChange={handleChange}
          disabled={
            (isSuccess && calledReducerType === "userAuth/sendOtp") ||
            calledReducerType === "userAuth/verifyOtp" ||
            !isFunctionable
          }
          value={emailOrMobile}
          error={Boolean(validationErrors.emailOrMobile)}
        />
        {validationErrors.emailOrMobile && (
          <Typography color="error">
            {validationErrors.emailOrMobile}
          </Typography>
        )}
      </FormControl>
      {getOtpSection()}
      {getPostalCodeInputField()}
      <Button
        variant="contained"
        type="submit"
        disabled={
          Boolean(validationErrors.emailOrMobile) ||
          (isSuccess &&
            calledReducerType === "userAuth/sendOtp" &&
            Boolean(validationErrors.otp)) ||
          (isSuccess &&
            calledReducerType === "userAuth/verifyOtp" &&
            Boolean(validationErrors.postalCode)) ||
          !isFunctionable
        }
      >
        {buttonText}
      </Button>
    </form>
  );
};

export default LoginWithEmailOrMobile;
