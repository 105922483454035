import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ImageAndText from "../ImageAndText/ImageAndText";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import folderSymbolIcon from "../../assets/svgs/folder-symbol-icon.svg";
import {
  ARCHIVING_THE_FOLDER,
  ARE_YOU_SURE_ARCHIVE,
  CANCEL,
  FOLDER_LOWER,
  LAST_MODIFIED,
  MENU_ICON,
} from "../../constants";
import { DocumentFolderCardStyles } from "./DocumentFolderCardStyles";
import CustomIcon from "../CustomIcon/CustomIcon";
import ModalComponent from "../ModalComponent/ModalComponent";
import CustomButton from "../CustomButton/CustomButton";
import CustomTooltip from "../Tooltip/Tooltip";
import Drawers from "../Drawers/Drawers";
import HeadingText from "../HeadingText/HeadingText";

interface DocumentFolderCardProps {
  folderName: string;
  lastModified: string;
  fileCount: number;
  id: string;
}

const DocumentFolderCard: React.FC<DocumentFolderCardProps> = ({
  folderName,
  lastModified,
  fileCount,
  id,
}) => {
  const [menuOpen, setMenuOpen] = React.useState<string | null>(null);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const drawerOptions = [
    { value: "View", key: "view" },
    { value: "Edit", key: "edit" },
    { value: "Move", key: "move" },
    { value: "Archive", key: "archive" },
  ];

  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  const handleMenuOpen = (cardId: string) => {
    if (menuOpen === cardId) {
      setMenuOpen(null);
    } else {
      setMenuOpen(cardId);
    }
  };

  const handleArchiveClick = () => {
    setArchiveOpen(!archiveOpen);
    setMenuOpen(null);
  };

  const handleToolTipItem = (key: string) => {
    if (key === "archive") {
      handleArchiveClick();
    }
    setMenuOpen(null);
  };

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerOptionClick = (key: string) => {
    if (key === "archive") {
      handleArchiveClick();
    }
  };

  return (
    <>
      <Box sx={DocumentFolderCardStyles.container}>
        <Box sx={DocumentFolderCardStyles.folderContainer}>
          <Box sx={DocumentFolderCardStyles.folderCustomIcon}>
            <CustomIcon iconName="Folder" width="34px" height="28px" />
          </Box>
          {!isSmallScreen && (
            <Box sx={DocumentFolderCardStyles.folderTexts}>
              <Typography sx={DocumentFolderCardStyles.folderName}>
                {folderName}
              </Typography>
              <Typography sx={DocumentFolderCardStyles.lastModified}>
                {LAST_MODIFIED} {lastModified}
              </Typography>
            </Box>
          )}
          {isSmallScreen && (
            <Box sx={DocumentFolderCardStyles.menuIcon} onClick={handleDrawer}>
              <img src={menuIcon} alt={MENU_ICON} />
            </Box>
          )}
        </Box>

        {isSmallScreen && (
          <Box sx={DocumentFolderCardStyles.folderTexts}>
            <Typography sx={DocumentFolderCardStyles.folderName}>
              {folderName}
            </Typography>
            <Typography sx={DocumentFolderCardStyles.lastModified}>
              {lastModified}
            </Typography>
          </Box>
        )}

        <Box sx={DocumentFolderCardStyles.footer}>
          <ImageAndText
            image={folderSymbolIcon}
            text={`${fileCount}`}
            additionalClassText={DocumentFolderCardStyles.footerText}
          />

          {!isSmallScreen && (
            <>
              {menuOpen !== id && (
                <Box
                  onClick={() => handleMenuOpen(id)}
                  sx={DocumentFolderCardStyles.menuIconClass}
                >
                  <img src={menuIcon} alt={MENU_ICON} />
                </Box>
              )}
              {menuOpen === id && (
                <CustomTooltip
                  additionalClassOfMenuIcon={
                    DocumentFolderCardStyles.toolTipmenuIcon
                  }
                  menuIcon={menuIcon}
                  MENU_ICON={MENU_ICON}
                  myTasksTooltip={drawerOptions}
                  placement="bottom"
                  handleTooltipItem={handleToolTipItem}
                  open={menuOpen === id}
                  handleTooltip={() => setMenuOpen(null)}
                />
              )}
            </>
          )}
        </Box>
      </Box>

      <Drawers
        type="secondary"
        anchor="bottom"
        open={drawerOpen}
        additionalClass={DocumentFolderCardStyles.drawer}
        toggleDrawer={handleDrawer}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={DocumentFolderCardStyles.drawertextHeading}
            heading={"Menu"}
          />
          <Box sx={DocumentFolderCardStyles.optionsContainer}>
            {drawerOptions.map((option, index) => (
              <Typography
                key={option.key}
                sx={
                  index === drawerOptions.length - 1
                    ? DocumentFolderCardStyles.lastText
                    : DocumentFolderCardStyles.drawerTexts
                }
                onClick={() => handleDrawerOptionClick(option.key)}
              >
                {option.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Drawers>

      <ModalComponent
        headingAdditionalClass={DocumentFolderCardStyles.modalHeading}
        heading={ARE_YOU_SURE_ARCHIVE + `"${folderName}"` + FOLDER_LOWER}
        open={archiveOpen}
        handleOpen={handleArchiveClick}
        additionalClassContainer={DocumentFolderCardStyles.modalContainer}
        headingContainerAdditionalClass={
          DocumentFolderCardStyles.modalHeadingContainer
        }
      >
        <Box sx={DocumentFolderCardStyles.contentContainer}>
          <ImageAndText
            image={<CustomIcon iconName="Archive" />}
            text={ARCHIVING_THE_FOLDER}
            additionalClassText={DocumentFolderCardStyles.imageText}
          />
          <Box sx={DocumentFolderCardStyles.buttonsContainer}>
            <CustomButton
              text={"Confirm"}
              additionalClassName={DocumentFolderCardStyles.cancelButton}
            />
            <CustomButton
              text={CANCEL}
              onHandleClick={handleArchiveClick}
              type="outlined"
              additionalClassName={DocumentFolderCardStyles.cancelButton}
            />
          </Box>
        </Box>
      </ModalComponent>
    </>
  );
};

export default DocumentFolderCard;
