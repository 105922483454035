const CoveragesAndLimitsStyles = {
  coverageAndLimitsHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "13px",
  },
  coverageAndLimitsHeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontHeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
};

export default CoveragesAndLimitsStyles;
