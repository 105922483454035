import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import {
  ADD_TASK,
  CLIENT_NAME,
  MENU_ICON,
  NOTES,
  POLICY,
  STATUS,
  TASK,
  TASK_DATE,
} from "../../constants";
import activeIcon from "../../assets/svgs/active-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import { MyTasksCardStyles } from "./MyTasksCardStyles";
import highPriority from "../../assets/svgs/high.svg";
import mediumPriority from "../../assets/svgs/medium.svg";
import lowPriority from "../../assets/svgs/low.svg";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import clockIcon from "../../assets/svgs/clock-icon.svg";
import inProgressIcon from "../../assets/svgs/in-progress.svg";
import reScheduleIcon from "../../assets/svgs/re-schedule.svg";
import CustomTooltip from "../../common/Tooltip/Tooltip";
import { myTasksTooltip } from "../../constants/TootipData";
import Drawers from "../../common/Drawers/Drawers";
import { personData } from "../../constants/myTasksPersonData";
import { personChat } from "../../constants/myTasksPersonChatData";
import Notes from "../../components/Notes/Notes";
import NewTask from "../NewTask/NewTask";
import { useNavigate } from "react-router-dom";
interface DateProps {
  date: string;
  time: string;
}
interface MyTasksCardProps {
  task: string;
  priority: string;
  status: string;
  clientName: string;
  timeStamp: DateProps;
  policy: string;
  id: string;
}
const MyTasksCard: React.FC<MyTasksCardProps> = ({
  task,
  priority,
  status,
  clientName,
  timeStamp,
  policy,
  id,
}) => {
  const [menuOpen, setMenuOpen] = React.useState<string | null>(null);
  const [notesOpen, setNotesOpen] = useState(false);
  const [tasksOpen, setTasksOpen] = useState(false);
  const navigate = useNavigate();
  const renderPriority = (priority: string) => {
    let priorityText = "";
    let priorityImage = "";
    if (priority.toLowerCase() === "high") {
      priorityImage = highPriority;
      priorityText = "High Priority";
    }
    if (priority.toLowerCase() === "low") {
      priorityImage = lowPriority;
      priorityText = "Low Priority";
    }
    if (priority.toLowerCase() === "medium") {
      priorityImage = mediumPriority;
      priorityText = "Medium Priority";
    }
    return (
      <ImageAndText
        additionalClassName={MyTasksCardStyles.priorityContainer}
        image={priorityImage}
        text={priorityText}
      />
    );
  };
  const renderStatus = (status: string) => {
    let statusText = "";
    let statusImage = "";
    if (status.toLowerCase() === "pending") {
      statusText = "Pending";
      statusImage = clockIcon;
    }
    if (status.toLowerCase() === "active") {
      statusImage = activeIcon;
      statusText = "Active";
    }
    if (status.toLowerCase() === "in progress") {
      statusImage = inProgressIcon;
      statusText = "In Progress";
    }
    if (status.toLowerCase() === "rescheduled") {
      statusImage = reScheduleIcon;
      statusText = "Rescheduled";
    }
    return (
      <ImageAndText
        additionalClassName={MyTasksCardStyles.statusContainer}
        image={statusImage}
        text={statusText}
      />
    );
  };
  const handleMenuOpen = (cardId: string) => {
    if (menuOpen === cardId) {
      setMenuOpen(null);
    } else {
      setMenuOpen(cardId);
    }
  };
  const handleToolTipItem = (key: string) => {
    if (key === "notes") {
      setNotesOpen(!notesOpen);
    }
    if (key === "rescheduleTask") {
      setTasksOpen(!tasksOpen);
    }
    if (key === "viewPolicyDetails") {
      navigate("/policy-details");
    }
    setMenuOpen(null);
  };
  const handleNotesClick = () => {
    setNotesOpen(!notesOpen);
  };
  const handleTasksClick = () => {
    setTasksOpen(!tasksOpen);
  };
  return (
    <>
      <Box sx={MyTasksCardStyles.container}>
        <Box sx={MyTasksCardStyles.hedingDataContainer}>
          <>{renderPriority(priority)}</>
          <Box sx={MyTasksCardStyles.headingSubHeadingContainer}>
            <HeadingSubHeading
              containerAdditionalClassName={MyTasksCardStyles.headingContainer}
              headingAdditionalClassName={MyTasksCardStyles.heading}
              subHeadingAdditionalClassName={MyTasksCardStyles.subheading}
              heading={timeStamp.date + " " + timeStamp.time}
              subheading={TASK_DATE}
            />

            <HeadingSubHeading
              containerAdditionalClassName={MyTasksCardStyles.headingContainer}
              headingAdditionalClassName={MyTasksCardStyles.heading}
              subHeadingAdditionalClassName={MyTasksCardStyles.subheading}
              heading={clientName}
              subheading={CLIENT_NAME}
            />
            <HeadingSubHeading
              containerAdditionalClassName={MyTasksCardStyles.headingContainer}
              headingAdditionalClassName={MyTasksCardStyles.heading}
              subHeadingAdditionalClassName={MyTasksCardStyles.subheading}
              heading={policy}
              subheading={POLICY}
            />

            <Box sx={MyTasksCardStyles.statusRenderer}>
              <Box>{renderStatus(status)}</Box>
              <Typography sx={MyTasksCardStyles.subheading}>
                {STATUS}
              </Typography>
            </Box>
            <HeadingSubHeading
              containerAdditionalClassName={MyTasksCardStyles.headingContainer}
              headingAdditionalClassName={MyTasksCardStyles.headingTask}
              subHeadingAdditionalClassName={MyTasksCardStyles.subheading}
              heading={task}
              subheading={TASK}
            />
          </Box>
        </Box>
        <>
          {menuOpen !== id && (
            <Box sx={MyTasksCardStyles.menuIcon}>
              <img
                onClick={() => handleMenuOpen(id)}
                src={menuIcon}
                alt={MENU_ICON}
              />
            </Box>
          )}
          {menuOpen === id && (
            <CustomTooltip
              menuIcon={menuIcon}
              MENU_ICON={MENU_ICON}
              myTasksTooltip={myTasksTooltip}
              placement="bottom"
              handleTooltipItem={handleToolTipItem}
              open={menuOpen === id}
              handleTooltip={() => setMenuOpen(null)}
            />
          )}
        </>
      </Box>
      <Drawers open={notesOpen} toggleDrawer={handleNotesClick} heading={NOTES}>
        <Notes personDataArray={personData} personChatArray={personChat} />
      </Drawers>
      <Drawers
        additionalClass={MyTasksCardStyles.drawer}
        open={tasksOpen}
        toggleDrawer={handleTasksClick}
        heading={ADD_TASK}
      >
        <NewTask onCancelClick={handleTasksClick} />
      </Drawers>
    </>
  );
};

export default MyTasksCard;
