import React from "react";
import { Box, Typography } from "@mui/material";
import LeinholderStyles from "./LeinholderStyles";
import leinholderData from "../../../mockJson/CustomerDashboard/LeinholderData.json";
import {
  accountNumber,
  address,
  bankName,
  contactNumber,
  names,
} from "../../../constants/Constants";

const ResponsiveLeinholderTabComponent: React.FC = () => {
  return (
    <Box>
      <Box sx={LeinholderStyles.leinholderMobileContainer}>
        {leinholderData.slice(1).map((e, index) => (
          <Box sx={LeinholderStyles.container} key={index}>
            <Box sx={LeinholderStyles.cardContent}>
              <Typography sx={LeinholderStyles.descriptionText}>
                {names}
              </Typography>
              <Typography sx={LeinholderStyles.contentText}>
                {e.name}
              </Typography>
            </Box>
            <Box sx={LeinholderStyles.cardContent}>
              <Typography sx={LeinholderStyles.descriptionText}>
                {contactNumber}
              </Typography>
              <Typography sx={LeinholderStyles.contentText}>
                {e.contactNumber}
              </Typography>
            </Box>
            <Box sx={LeinholderStyles.cardContent}>
              <Typography sx={LeinholderStyles.descriptionText}>
                {bankName}
              </Typography>
              <Typography sx={LeinholderStyles.contentText}>
                {e.bankName}
              </Typography>
            </Box>
            <Box sx={LeinholderStyles.cardContent}>
              <Typography sx={LeinholderStyles.descriptionText}>
                {accountNumber}
              </Typography>
              <Typography sx={LeinholderStyles.contentText}>
                {e.accountNumber}
              </Typography>
            </Box>
            <Box sx={LeinholderStyles.cardContent}>
              <Typography sx={LeinholderStyles.descriptionText}>
                {address}
              </Typography>
              <Typography sx={LeinholderStyles.contentText}>
                {e.address}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ResponsiveLeinholderTabComponent;
