import React from "react";
import PerformingPoliciesCard from "../PerformingPoliciesCard/PerformingPoliciesCard";
import { PerformingPoliciesStyles } from "./PerformingPoliciesStyles";
import { Box } from "@mui/material";
interface Policy {
  number: string;
  description: string;
  image: string;
  isLast?: boolean;
}

interface PerformingPoliciesProps {
  policies: Policy[];
}

const PerformingPolicies: React.FC<PerformingPoliciesProps> = ({
  policies,
}) => {
  return (
    <Box sx={PerformingPoliciesStyles.container}>
      {policies.slice(0, 3).map((policy, index) => (
        <PerformingPoliciesCard
          key={index}
          number={policy.number}
          description={policy.description}
          image={policy.image}
          isLast={index === 2}
        />
      ))}
    </Box>
  );
};

export default PerformingPolicies;
