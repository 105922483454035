export const CustomerDetailsHeaderStyles = {
  policyDetailsContainer: {
    padding: "0px 0px 24px 0px",
  },
  policyDetails: {
    marginBottom: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    paddingBottom: "3px",
  },
  policyNumber: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  policyNumbertext: {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
  },
  customerDetailsImageContainer: {
    gap: "7px",
  },
  statusImage: {
    display: "flex",
  },
  policyNumberImage: {
    gap: "3px",
  },
  policyType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statusText: {
    color: "var(--support-white)",
  },
  policyTypeText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--support-white)",
    paddingLeft: "17px",
  },
  addTaskButton: {
    boxShadow: "none",
    width: "320px",
    marginTop: "4px",
  },
  cardContainer: {
    width: "100%",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
    padding: "16px 16px 20px 16px",
    backgroundColor: "var(--white)",
  },
  customerDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "12px",
  },
  menuIcon: {
    paddingRight: "10px",
  },
  interactionContainer: {
    marginBottom: "15px",
  },
  interaction: {
    gap: "2px",
  },
  interactionImage: {
    display: "flex",
  },
  interactionDetail: {
    color: "var(--secondary-grey)",
    alignItems: "center",
    fontWeight: "var(--font-weight-300)",
  },
  separator: {
    width: "100%",
    height: "0.5px",
    backgroundColor: "var(--primary-grey)",
    marginBottom: "12px",
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 4px",
  },
  buttonContainer: {
    margin: "0px auto",
    width: "320px",
  },
  button: {
    padding: "5px 16px",
    boxShadow: "none",
  },
  viewMoreContainer: {
    display: "flex",
    alignItems: "end",
    cursor: "pointer",
    gap: "6px",
  },
  viewMoreText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var( --primary-black)",
    lineHeight: "22px",
  },
  customerName: {
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  container: {
    paddingBottom: "23px",
  },
  rotateIcon: {
    transition: "transform 0.3s",
  },
  rotated: {
    transform: "rotate(180deg)",
  },
  cutomerPoliciesContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: "20px",
    rowGap: "20px",
    columnGap: "70px",
  },
  numberTextPolicyContainer: {
  },
  policyDataNumber: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  policyDataText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  cutomerClaimsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    marginBottom: "30px",
    rowGap: "20px",
    columnGap: "72px",
    justifyContent: "space-between",
  },
  numberTextClaimContainer: {
  },
  claimDataNumber: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  claimDataText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
};
