import React from "react";
import { Props } from "./Props";

const CalendarIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 37"
      fill="none"
    >
      <rect
        x="0.107422"
        y="3.27277"
        width="20.4551"
        height="11.4548"
        fill={fill}
      />
      <path
        d="M32.998 3.59964H7.79736C5.80908 3.59964 4.19727 5.21146 4.19727 7.19973V14.3182V32.4004C4.19727 34.3887 5.80908 36.0005 7.79736 36.0005H32.998C34.9863 36.0005 36.5981 34.3887 36.5981 32.4004V7.19973C36.5981 5.21146 34.9863 3.59964 32.998 3.59964Z"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M27.5977 -0.000457764V7.19973"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M13.1992 -0.000457764V7.19973"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M4.19727 14.4004H36.5981"
        stroke="#262729"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
