export const TabsStyles = {
  tabs: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
  },
  tabContainer: {
    width: "100%",
  },
  tabBox: {
    paddingLeft: "20px",
    borderBottom: "1px solid var(--secondary-gray)",
    borderColor: "divider",
  },
  secondaryLabel: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-30)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
  },
  label: {
    lineHeight: "15px",
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
  },
  icon: {
    width: "10px",
    height: "24px",
  },
  "@media screen and (max-width: 599px)": {
    tabs: {
      flexDirection: "column",
      gap: "4px",
    },
    label: {
      lineHeight: "15px",
      fontSize: "12px",
    },
  },
};
