export const HeadingSubHeadingStyles = {
  heading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-30)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "40.86px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  subheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--secondary-grey)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  percentage: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
};
