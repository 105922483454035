import React from "react";
import { Props } from "./Props";

const BellIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 43"
      fill="none"
    >
      <ellipse
        cx="26.0473"
        cy="11.4947"
        rx="8.59418"
        ry="8.59418"
        fill={fill}
      />
      <path
        d="M28.6369 15.0809C27.7983 12.357 25.912 10.0777 23.393 8.74461C20.8739 7.41148 17.9285 7.13364 15.2045 7.97221C12.4806 8.81078 10.2014 10.6971 8.8683 13.2161C7.53517 15.7352 7.25733 18.6806 8.0959 21.4045C11.7847 33.3868 7.7034 38.3912 7.7034 38.3912L38.5148 28.9057C38.5148 28.9057 32.3257 27.0631 28.6369 15.0809Z"
        stroke="#262729"
        stroke-linejoin="round"
      />
      <path
        d="M28.1785 39.5833C28.0373 40.1947 27.7379 40.7583 27.3103 41.2177C26.8828 41.677 26.342 42.016 25.7423 42.2006C25.1425 42.3853 24.5048 42.4091 23.8929 42.2697C23.281 42.1304 22.7165 41.8327 22.2559 41.4066"
        stroke="#262729"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default BellIcon;
