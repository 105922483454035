export const CustomerDetailsCardStyles = {
  container: {
    display: "flex",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
    padding: "29px 26px 29px 30px",
    marginBottom: "3px",
    justifyContent: "space-between",
    alignItems: "end",
    width: "100%",
    flexWrap: "wrap",
  },
  policyDetails: {
    display: "flex",
    gap: "50px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  policyContainer: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
  },
  claimContainer: {
    display: "flex",
    gap: "40px",
    alignItems: "center",
  },
  separator: {
    width: "1px",
    height: "46px",
    backgroundColor: "var(--ternary-grey)",
  },
  contentContainer: {
    gap: "2px",
  },
  customerDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  interactionDetail: {
    color: "var(--secondary-grey)",
    alignItems: "center",
    fontWeight: "var(--font-weight-300)",
  },
  contentContainerHeading: {
    fontSize: "var(--font-size-20)",
    lineHeight: "27px",
  },
  contentContainerSubHeading: {
    fontSize: "var(--font-size-16)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-300)",
  },
  interactionDetailContainer: {
    gap: "3px",
  },
  contentDetailsContainer: {
    gap: "7px",
  },
};
