import React, { useState } from "react";
import { Box, Drawer, Popover, Typography, useMediaQuery } from "@mui/material";
import WhiteButton from "../common/WhiteButton";
import { BasicInfoCardStyles } from "./BasicInfoCardStyles";
import { buttonText } from "../../constants/QuickActionButtonsList";
import { BasicInfoQuickStyles } from "./BasicInfoQuickStyles";
import { fileClaim } from "../../constants/Constants";
import ContactButtonComponent from "./QuickActions/ContactButtonComponent";
import DropDown from "../common/DropDown";
import AutoPaymentComponent from "./QuickActions/AutoPaymentComponent";
import EllipsisButtonComponent from "./QuickActions/EllipsisButtonComponent";
import FileAClaim from "../../FileAClaim/FileAClaim";
import { mobileOptions, options } from "../../constants/BasicInfoButtons";
import Acknowledgement from "../../Acknowledgement/Acknowledgement";

const BasicInfoCardQuickActions: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:799px)");
  const isTablet = useMediaQuery("(min-width:800px) and (max-width:1300px)");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>("");

  const buttonsToDisplay = isMobile ? 1 : isTablet ? 4 : buttonText.length;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseFileAClaim = () => {
    setIsDrawerOpen(false);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string
  ) => {
    event.preventDefault();

    if (text === "File a claim") {
      toggleDrawer();
    }
    setAnchorEl(event.currentTarget);
    setSelectedAction(text);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAction("");
  };

  const renderPopoverContent = () => {
    if (selectedAction === "File a claim") {
      return (
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <FileAClaim onClose={handleCloseFileAClaim} />
        </Drawer>
      );
    }
    if (selectedAction === "Contact") {
      return (
        <Popover
          id={id}
          sx={BasicInfoQuickStyles.contactPop}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <ContactButtonComponent />
        </Popover>
      );
    }
    if (selectedAction === "Enable Auto Pay") {
      return <AutoPaymentComponent onClose={handleClose} open={open} />;
    }
    if (selectedAction === "File a claim") {
      return (
        <Box>
          <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
            <FileAClaim onClose={handleCloseFileAClaim} />
            <Acknowledgement />
          </Drawer>
        </Box>
      );
    }
    if (selectedAction === "More") {
      return (
        <Popover
          id={id}
          sx={BasicInfoQuickStyles.morePop}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <DropDown />
        </Popover>
      );
    }
    return null;
  };

  return (
    <Box
      sx={BasicInfoQuickStyles.BasicInfoQuickActionContainer}
      aria-label="Quick Actions Container"
    >
      {isMobile ? (
        <Box sx={BasicInfoQuickStyles.container}>
          <Box>
            <WhiteButton
              buttonText={fileClaim}
              buttonStyles={BasicInfoQuickStyles.buttonStyle}
              onClick={() => toggleDrawer()}
            />
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
              <FileAClaim onClose={handleCloseFileAClaim} />
            </Drawer>
          </Box>
          <EllipsisButtonComponent
            options={mobileOptions}
            handleClick={handleClick}
            handleClose={handleClose}
          />
          {renderPopoverContent()}
        </Box>
      ) : (
        <>
          {isTablet ? (
            <>
              <Box
                aria-label="tablet container"
                sx={BasicInfoQuickStyles.BasicInfoQuickActionCard}
              >
                <Box sx={BasicInfoQuickStyles.buttonsContainer}>
                  {buttonText.slice(0, buttonsToDisplay).map((text, index) => (
                    <WhiteButton
                      key={index}
                      buttonText={text}
                      buttonStyles={BasicInfoQuickStyles.buttonStyle}
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => handleClick(event, text)}
                    />
                  ))}
                </Box>
                <EllipsisButtonComponent
                  handleClick={handleClick}
                  handleClose={handleClose}
                  options={options}
                />
                {renderPopoverContent()}
              </Box>
            </>
          ) : (
            <>
              <Typography sx={BasicInfoCardStyles.quickStyle}>
                {"Quick Actions"}
              </Typography>
              <Box sx={BasicInfoQuickStyles.BasicInfoQuickActionCard}>
                {buttonText.map((text, index) => (
                  <WhiteButton
                    key={index}
                    buttonText={text}
                    buttonStyles={BasicInfoQuickStyles.buttonStyle}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleClick(event, text)}
                  />
                ))}
                {renderPopoverContent()}
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default BasicInfoCardQuickActions;
