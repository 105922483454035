export const SwitchStyles = {
  container: {
    display: "flex",
    gap: "0px",
    alignItems: "center",
  },
  switchText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
};
