import React from "react";
import { ChatStyles } from "./ChatStyles";
import profileLogo from "../../assets/svgs/profile-16-logo.svg";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PROFILE_LOGO } from "../../constants";

interface UserDetailsProps {
  personLogo: string;
  clientName: string;
  date: string;
  time: string;
  userMessage: boolean;
}

interface PersonChatProps {
  message: string;
  userDetails: UserDetailsProps;
}

export interface ChatProps {
  personChat: PersonChatProps;
}

const Chat: React.FC<ChatProps> = ({ personChat }) => {
  const isMobile = useMediaQuery("(max-width:1023px)");

  const chatStyle = personChat.userDetails.userMessage
    ? ChatStyles.chat
    : ChatStyles.userChat;

  return (
    <Box sx={chatStyle}>
      <Typography sx={ChatStyles.message}>{personChat.message}</Typography>

      <Box sx={ChatStyles.userDetails}>
       {!isMobile && (<img
          src={personChat.userDetails.personLogo || profileLogo}
          alt={PROFILE_LOGO}
        />)}
        <Box sx={ChatStyles.clientChat}>
          <Typography sx={ChatStyles.clientDetails}>
            {personChat.userDetails.clientName}
          </Typography>
          <Typography sx={ChatStyles.clientDetails}>|</Typography>
          <Typography sx={ChatStyles.clientDetails}>
            {personChat.userDetails.date} , {personChat.userDetails.time}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
