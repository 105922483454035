import { Box, Typography } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import { ACTIONS, MENU_ICON, STATUS, TASK, TASK_DATE } from "../../constants";
import clockIcon from "../../assets/svgs/clock-icon.svg";
import activeIcon from "../../assets/svgs/active-icon.svg";
import highPriorityIcon from "../../assets/svgs/high.svg";
import mediumPriorityIcon from "../../assets/svgs/medium.svg";
import lowPriorityIcon from "../../assets/svgs/low.svg";
import { TasksStyles } from "./MyTasksResponsiveCardStyles";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import inProgressIcon from "../../assets/svgs/in-progress.svg";
import reScheduleIcon from "../../assets/svgs/re-schedule.svg";
import Drawers from "../../common/Drawers/Drawers";
import HeadingText from "../../common/HeadingText/HeadingText";
import { useNavigate } from "react-router-dom";
import { myTasksTooltip } from "../../constants/TootipData";
interface TimeStampProps {
  date: string;
  time: string;
}
interface TasksDataProps {
  timeStamp: TimeStampProps;
  task: string;
  clientName: string;
  policy: string;
  priority: string;
  status: string;
}
const Tasks: React.FC<TasksDataProps> = ({
  clientName,
  policy,
  timeStamp,
  status,
  task,
  priority,
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerOptionClick = (key: string) => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 100);
    if (key === "notes") {
      navigate("/notes");
    }
    if (key === "viewPolicyDetails") {
      navigate("/policy-details");
    }
    if (key === "rescheduleTask") {
      navigate("/new-task");
    }
  };

  const renderStatus = (statusRenderer: string) => {
    let statusImage = "";
    let statusText = "";
    if (statusRenderer.toLowerCase() === "pending") {
      statusImage = clockIcon;
      statusText = "Pending";
    }
    if (statusRenderer.toLowerCase() === "active") {
      statusImage = activeIcon;
      statusText = "Active";
    }
    if (statusRenderer.toLowerCase() === "in progress") {
      statusImage = inProgressIcon;
      statusText = "In Progress";
    }
    if (statusRenderer.toLowerCase() === "rescheduled") {
      statusImage = reScheduleIcon;
      statusText = "Rescheduled";
    }
    return (
      <ImageAndText
        additionalClassName={TasksStyles.statusContainer}
        imageClassName={TasksStyles.statusImage}
        image={statusImage}
        text={statusText}
      />
    );
  };
  const renderPriority = (priorityRenderer: string) => {
    let priorityImage = "";
    let priorityText = "";
    if (priorityRenderer.toLowerCase() === "high") {
      priorityImage = highPriorityIcon;
      priorityText = "High Priority";
    }
    if (priorityRenderer.toLowerCase() === "medium") {
      priorityImage = mediumPriorityIcon;
      priorityText = "Medium Priority";
    }
    if (priorityRenderer.toLowerCase() === "low") {
      priorityImage = lowPriorityIcon;
      priorityText = "Low Priority";
    }
    return (
      <ImageAndText
        additionalClassName={TasksStyles.priorityContainer}
        additionalClassText={TasksStyles.subHeadingPriorityText}
        image={priorityImage}
        text={priorityText}
      />
    );
  };
  return (
    <Box sx={TasksStyles.container}>
      <Box sx={TasksStyles.header}>
        <HeadingSubHeading
          containerAdditionalClassName={TasksStyles.headingContainer}
          headingAdditionalClassName={TasksStyles.headingNameText}
          heading={clientName}
          subHeadingAdditionalClassName={TasksStyles.subHeadingNameText}
          subheading={policy}
        />
        <Box>{renderPriority(priority)}</Box>
      </Box>
      <Box sx={TasksStyles.innerContainer}>
        <HeadingSubHeading
          heading={timeStamp.date + "," + timeStamp.time}
          subheading={TASK_DATE}
          containerAdditionalClassName={TasksStyles.headingContainer}
          headingAdditionalClassName={TasksStyles.headingText}
          subHeadingAdditionalClassName={TasksStyles.subHeadingText}
        />
        <Box sx={TasksStyles.headingContainer}>
          {renderStatus(status)}
          <Typography sx={TasksStyles.subHeadingText}>{STATUS}</Typography>
        </Box>
        <HeadingSubHeading
          headingAdditionalClassName={TasksStyles.headingtaskText}
          subHeadingAdditionalClassName={TasksStyles.subHeadingTaskText}
          containerAdditionalClassName={TasksStyles.headingContainer}
          heading={TASK}
          subheading={task}
        />
      </Box>
      <Box sx={TasksStyles.menuContainer} onClick={handleDrawer}>
        <img src={menuIcon} alt={MENU_ICON} />
      </Box>
      <Drawers
        type="secondary"
        anchor="bottom"
        open={drawerOpen}
        additionalClass={TasksStyles.drawer}
        toggleDrawer={handleDrawer}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={TasksStyles.drawertextHeading}
            heading={ACTIONS}
          />
          <Box sx={TasksStyles.optionsContainer}>
            {myTasksTooltip.map((option, index) => (
              <Typography
                key={option.key}
                sx={
                  index === myTasksTooltip.length - 1
                    ? TasksStyles.lastText
                    : TasksStyles.drawerTexts
                }
                onClick={() => handleDrawerOptionClick(option.key)}
              >
                {option.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Drawers>
    </Box>
  );
};

export default Tasks;
