import { createSlice } from "@reduxjs/toolkit";
import { IUiSettingsSlice } from "../../../models/admin/uiSettings/uiSettings";
import { getUiSettings } from "./uiSettingsActions";

const initialState: IUiSettingsSlice = {
  uiSettings: null,
};

const uiSettingsSlice = createSlice({
  name: "uiSettings",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUiSettings.fulfilled, (state, { payload }) => {
      state.uiSettings = payload.data;
    });
  },
});

export default uiSettingsSlice.reducer;
