import { Box, Typography } from "@mui/material";
import React from "react";
import DocumentFolderCard from "../../common/DocumentFolderCard/DocumentFolderCard";
import DocumentFileCard from "../../common/DocumentFileCard/DocumentFileCard";
import { DocumnetListingCardsStyles } from "./DocumentsListingCardsStyles";
import { FILES, FOLDERS } from "../../constants";
interface FolderDataProps {
  folderName: string;
  lastModified: string;
  fileCount: number;
}
interface FileDataProps {
  fileName: string;
  lastModified: string;
  docImage: string;
}
interface DocumentListingCardsProps {
  folderData: FolderDataProps[];
  fileData: FileDataProps[];
}
const DocumentListingCards: React.FC<DocumentListingCardsProps> = ({
  folderData,
  fileData,
}) => {
  return (
    <Box sx={DocumnetListingCardsStyles.container}>
      <Box sx={DocumnetListingCardsStyles.totalContainer}>
        <Typography sx={DocumnetListingCardsStyles.fileHeading}>
          {`${FOLDERS} (${folderData.length.toString().padStart(2, "0")})`}
        </Typography>
        <Box sx={DocumnetListingCardsStyles.folderContainer}>
          {folderData.map((folder, index) => (
            <DocumentFolderCard
              id={index.toString()}
              folderName={folder.folderName}
              lastModified={folder.lastModified}
              fileCount={folder.fileCount}
            />
          ))}
        </Box>
      </Box>
      <Box sx={DocumnetListingCardsStyles.totalContainer}>
        <Typography sx={DocumnetListingCardsStyles.fileHeading}>
          {`${FILES} (${fileData.length.toString().padStart(2, "0")})`}
        </Typography>
        <Box sx={DocumnetListingCardsStyles.filesContainer}>
          {fileData.map((file, index) => (
            <DocumentFileCard
              fileName={file.fileName}
              lastModified={file.lastModified}
              docImage={file.docImage}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentListingCards;
