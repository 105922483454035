import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import car from "../../assets/svgs/car.svg";
import { ClaimCardStyles } from "./ClaimCardStyles";
import { CAR, DETAILS, TRACK_YOUR_CLAIM, VIEW_DETAILS } from "../../constants";
import docIcon from "../../assets/svgs/document-icon.svg";
import trackClaim from "../../assets/svgs/track-claim-icon.svg";
import ImageAndText from "../ImageAndText/ImageAndText";
import { useNavigate } from "react-router-dom";
import ClaimTracking from "../../components/ClaimTracking/ClaimTracking";
import Drawers from "../Drawers/Drawers";
import CustomIcon from "../CustomIcon/CustomIcon";
interface HeadingSubheadingProps {
  heading: string | number;
  subheading: string;
}
interface ClaimCardResponsiveProps {
  claimId: string;
  active: boolean;
  claimsData: HeadingSubheadingProps[];
}

const ClaimCard: React.FC<ClaimCardResponsiveProps> = ({
  claimId,
  active,
  claimsData,
}) => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
    });
    if (isSmallScreen) {
      handleNavigate();
    } else {
      handleTrackYourClaim();
    }
  };

  const handleNavigate = () => {
    navigate("/claim-tracking");
  };
  const handleTrackYourClaim = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box sx={ClaimCardStyles.claimCardContainer}>
        <Typography sx={ClaimCardStyles.claimIdNo}>{claimId}</Typography>
        <Box sx={ClaimCardStyles.container}>
          <Box sx={ClaimCardStyles.innerContainer}>
            <Box sx={ClaimCardStyles.claimIdContainer}>
              <Box sx={ClaimCardStyles.carIcon}>
                <CustomIcon iconName="ClaimsCar" />
              </Box>
              <Box sx={ClaimCardStyles.headingContainer}>
                <Typography sx={ClaimCardStyles.heading}>
                  {claimsData[0].heading}
                </Typography>
                <Typography sx={ClaimCardStyles.subheading}>
                  {claimsData[0].subheading}
                </Typography>
              </Box>
            </Box>
            <Box sx={ClaimCardStyles.claimsContainer}>
              {claimsData.slice(1).map((data, index) => (
                <Box
                  sx={{
                    ...ClaimCardStyles.headingContainer,
                    ...(index === 0 &&
                      active &&
                      ClaimCardStyles.firstHeaderClassName),
                  }}
                  key={index}
                >
                  <Typography sx={ClaimCardStyles.heading}>
                    {data.heading}
                  </Typography>
                  <Typography sx={ClaimCardStyles.subheading}>
                    {data.subheading}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={ClaimCardStyles.cardFooter}>
            {active ? (
              <ImageAndText
                additionalClassName={ClaimCardStyles.imageAndTextContainer}
                onClick={handleClick}
                image={trackClaim}
                text={TRACK_YOUR_CLAIM}
              />
            ) : (
              <ImageAndText
                additionalClassName={ClaimCardStyles.imageAndTextContainer}
                image={docIcon}
                text={isSmallScreen ? VIEW_DETAILS : DETAILS}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Drawers
        open={open}
        toggleDrawer={handleTrackYourClaim}
        heading={"Claim Tracking"}
      >
        <ClaimTracking />
      </Drawers>
    </>
  );
};

export default ClaimCard;
