import { Box, Typography } from "@mui/material";
import CustomTable from "../../../../../common/CustomTable/CustomTable";
import CoveragesAndLimitsStyles from "./CoveragesAndLimitsStyles";
import CustomIcon from "../../../../../common/CustomIcon/CustomIcon";

interface IAddFormRequestBody {
  label: string;
  accessorKey?: string;
  Cell?: any;
}

interface IProps {
  columns: IAddFormRequestBody[];
  data: any[];
}

const CoveragesAndLimits = (props: IProps) => {
  const { data, columns } = props;

  const getCoveragesSection = () => {
    return <CustomTable columns={columns} data={data} />;
  };

  return (
    <>
      <Box>
        <Box sx={CoveragesAndLimitsStyles.coverageAndLimitsHeading}>
          <Box>
            <CustomIcon iconName="Coverages" />
          </Box>
          <Typography
            sx={CoveragesAndLimitsStyles.coverageAndLimitsHeadingText}
          >
            Coverages and limits
          </Typography>
        </Box>
        {getCoveragesSection()}
      </Box>
    </>
  );
};

export default CoveragesAndLimits;
