import React, { useState } from "react";
import ActivityContainer from "./ActivityContainer";
import {
  Box,
  Card,
  Typography,
  MenuItem,
  Popover,
  Button,
  TextField,
} from "@mui/material";
import ActivityTabStyles from "./ActivityTabStyles";
import filter from "../../../assets/filter.svg";
import FilterType from "../../../mockJson/CustomerDashboard/FilterType.json";
import YearToDate from "../../../mockJson/CustomerDashboard/YearToDateData.json";
import { filterText } from "../../../constants/Constants";

type Props = {};

const ActivityTabComponent = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Box sx={ActivityTabStyles.filter}>
        <Button sx={ActivityTabStyles.filterButton} onClick={handleClick}>
          <Box sx={ActivityTabStyles.tableHeadingFilterImage}>
            <img src={filter} alt="filter" />
          </Box>
          <Typography sx={ActivityTabStyles.tableHeadingFilterText}>
            {filterText}
          </Typography>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={ActivityTabStyles.popOver}
        >
          <Box sx={ActivityTabStyles.popoverContent}>
            <TextField
              key={FilterType[0].value}
              select
              label="Type"
              variant="outlined"
              fullWidth
              defaultValue={FilterType[0].value}
              sx={ActivityTabStyles.textField}
            >
              {FilterType.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
            <TextField
              key={YearToDate[0].value}
              select
              label="Period"
              variant="outlined"
              fullWidth
              defaultValue={YearToDate[0].value}
              sx={ActivityTabStyles.textField}
            >
              {YearToDate.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Box>
        </Popover>
      </Box>
      <Card sx={ActivityTabStyles.container}>
        <Box sx={ActivityTabStyles.active}>
          <ActivityContainer />
        </Box>
      </Card>
    </Box>
  );
};

export default ActivityTabComponent;
