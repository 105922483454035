const VehicleSyles = {
  discounts: {
    marginTop: "20px",
    marginBottom: "50px",
  },

  headingAndButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:599px)": {
      borderBottom: "1px solid rgba(217, 217, 217, 1)",
    },
  },
  vehicleHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "13px",

    img: {
      width: "20px",
      height: "20px",
    },
  },
  HeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontHeight: "400",
    textAlign: "left",
  },

  tableHead: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "600",
    textAlign: "left",
    opacity: "70%",
    color: "rgba(33, 24, 20, 1)",
    borderBottom: "0px",
  },
  tableContainer: {
    padding: "0px 30px",
    width: "auto",
    border: "1px solid rgba(201, 225, 236, 1)",
    display: "block",
    "@media (max-width:599px)": {
      width: "100%",
      maxWidth: "320px",
      display: "none",
    },
  },
  rowOne: {
    width: "221px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowTwo: {
    width: "245px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowThree: {
    width: "173px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowFour: {
    width: "150px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  row: {
    height: "64px",
    fontFamily: "Noto Sans",
    "&:last-child": {
      borderBottom: "0px",
    },
  },
  rowEmpty: {
    width: "150px",
    borderBottom: "0px",
    "@media (min-width:601px) and (max-width:690px)": { display: "none" },
  },

  addVehicleButton: {
    borderRadius: "20px",
    width: "151px",
    height: "35px",
    backgroundColor: "#FFFFFF",
    color: "#1D252B",
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "300",
    textAlign: "left",
    border: "1px solid #B3C2C8",
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#1D252B",
      border: "1px solid #B3C2C8",
      boxShadow: "none",
    },
  },

  rowName: {
    width: "366px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowLicense: {
    width: "301px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowDriverType: {
    width: "240px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowGender: {
    width: "171px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowDob: {
    width: "198px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  mobileCard: {
    display: "none",
    "@media (max-width:599px)": {
      display: "block",
      width: "auto",
      height: "auto",

      gap: "0px",
      borderRadius: "10px",
      opacity: "0px",
      margin: "22px 0px",
      border: "1px solid rgba(217, 217, 217, 1)",
      padding: "20px 20px 20px 20px",
    },
  },
  vin: {
    width: "auto",
    height: "22px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
  },
  modelYear: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginTop: "10px",
  },
  genderField: {
    display: "flex",
    gap: "10px",
  },
  model: {
    width: "auto",
    marginTop: "6px",
    left: "132px",
    fontFamily: "Noto Sans",
    height: "18px",
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  year: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2px",
    width: "43px",
    height: "23px",
    gap: "0px",
    borderRadius: "4px",
    opacity: "0px",
    // background: "rgba(217, 217, 217, 1)",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  type: {
    width: "auto",
    height: "13px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
    display: "flex",
    marginTop: "10px",
  },
  vehicleType: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
    color: "rgba(101, 101, 101, 1)",
  },
  name: {
    width: "auto",
    height: "22px",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
  },
  gender: {
    width: "auto",
    height: "22px",
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
    fontFamily: "Noto Sans",
  },
  line: { marginTop: "-2px", color: "rgba(101, 101, 101, 1)" },
  drivingLicense: {
    width: "auto",
    height: "13px",
    marginTop: "10px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  imageBox: {
    width: "40px",
    height: "40px",
  },
  tablesHead: {
    borderBottom: "1px solid rgba(63, 63, 63, 1)",
  },
};
export default VehicleSyles;
