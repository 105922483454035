import React from "react";
import { Box, Typography } from "@mui/material";
import { ImageAndTextStyles } from "./ImageAndTextStyles";
import { LOGO } from "../../constants";
import { SxProps, Theme } from "@mui/system";

interface ImageAndTextProps {
  image: string | null | React.ReactNode;
  text: string;
  additionalClassName?: SxProps<Theme>;
  primary?: boolean;
  imageClassName?: SxProps<Theme>;
  hoverElementClass?: SxProps<Theme>;
  additionalClassText?: SxProps<Theme>;
  hoverElement?: boolean;
  onClick?: () => void;
  onhandleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ImageAndText: React.FC<ImageAndTextProps> = ({
  image,
  text,
  additionalClassName,
  primary = false,
  imageClassName,
  additionalClassText,
  onClick,
  onhandleClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onhandleClick) onhandleClick(event);
    if (onClick) onClick();
  };
  return (
    <Box
      sx={Object.assign(
        {},
        ImageAndTextStyles.container,
        additionalClassName,
        primary && ImageAndTextStyles.primaryClassName
      )}
      onClick={handleClick}
    >
      <Box sx={Object.assign({}, ImageAndTextStyles.image, imageClassName)}>
        {typeof image === "string" ? <img src={image} alt="LOGO" /> : image}
      </Box>
      <Typography
        sx={Object.assign({}, ImageAndTextStyles.text, additionalClassText)}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default ImageAndText;
