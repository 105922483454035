const CustomDialogStyles = {
  commonDialogTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "@media(max-width:600px)": {
      fontSize: "1rem",
    },
  },
  commonDialogContent: {
    background: "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    paddingTop: "20px !important",
  },
  commonDialogActions: { padding: "10px" },
};

export default CustomDialogStyles;
