import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { CelebrationCardStyles } from "./CelebrationCardStyles";
import { birthdayCards } from "../../constants/CelebrationCardData";
import CustomIcon from "../CustomIcon/CustomIcon";

const CelebrationCard = () => {
  const [celebrationCards, setCelebrationCards] = useState(
    birthdayCards.slice(0, 3)
  );
  const renderImage = (image: string) => {
    if (image === "birthday") {
      return <CustomIcon iconName={"Celebration"} />;
    }
  };
  return (
    <Box sx={CelebrationCardStyles.cardContainer}>
      {celebrationCards.map((card, index) => (
        // <ImageAndText
        //   image={card.image}
        //   text={card.text}
        //   additionalClassName={CelebrationCardStyles.card}
        // additionalClassText={CelebrationCardStyles.text}
        // />
        <Box sx={CelebrationCardStyles.card}>
          {renderImage(card.image)}
          <Typography sx={CelebrationCardStyles.text}>{card.text}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CelebrationCard;
