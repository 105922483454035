import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  API_POST_CLAIMS,
  API_POST_POLICIES,
  POST_CLAIM_HISTORY,
  API_GET_DOWNLOAD_ID_CARD,
  API_GET_CLAIM_TRANSACTION_DOCUMENT_CONTENT,
} from "../../Apis/ApiEndPoints";
import {
  IGetClaimTransactionsRequestParams,
  IGetClaimTransactionsResponse,
  IGetClaimsRequestParams,
  IGetClaimsResponse,
  IGetPolicesResponse,
  IGetPoliciesRequestParams,
  IDownloadIdCardParams,
  IGetClaimTransactionDocumentContentRequestParams,
} from "../../models/user/dashboard/Dashboard";
import { CustomAction } from "../../store/customAction";
import { Api } from "../..";

export const GetPoliciesData = createAsyncThunk(
  "dashboard/GetPoliciesData",
  async (requestParams: IGetPoliciesRequestParams, { dispatch }) => {
    const response: IGetPolicesResponse = await CustomAction(
      Api.post(API_POST_POLICIES, requestParams),
      dispatch,
      "dashboard/GetPoliciesData"
    );
    return response;
  }
);

export const getClaimsData = createAsyncThunk(
  "dashboard/getClaimsData",
  async (requestParams: IGetClaimsRequestParams, { dispatch }) => {
    const response: IGetClaimsResponse = await CustomAction(
      Api.post(API_POST_CLAIMS, requestParams),
      dispatch,
      "dashboard/getClaimsData"
    );
    return response;
  }
);

export const GetClaimTransactions = createAsyncThunk(
  "dashboard/GetClaimTransactions",
  async (requestParams: IGetClaimTransactionsRequestParams, { dispatch }) => {
    const response: IGetClaimTransactionsResponse = await CustomAction(
      Api.post(POST_CLAIM_HISTORY, requestParams),
      dispatch,
      "GetClaimTransactions"
    );
    return response;
  }
);

export const GetDownloadIdCard = createAsyncThunk(
  "dashboard/GetDownloadIdCard",
  async (requestParams: IDownloadIdCardParams, { dispatch }) => {
    const response: any = await CustomAction(
      Api.get(`${API_GET_DOWNLOAD_ID_CARD}/${requestParams.policyId}`, {
        responseType: "arraybuffer",
      }),
      dispatch,
      "GetDownloadIdCard"
    );
    return response;
  }
);

export const GetClaimTransactionDocumentContent = createAsyncThunk(
  "dashboard/GetClaimTransactionDocumentContent",
  async (
    { claimId, documentId }: IGetClaimTransactionDocumentContentRequestParams,
    { dispatch }
  ) => {
    const response: any = await CustomAction(
      Api.get(
        `${API_GET_CLAIM_TRANSACTION_DOCUMENT_CONTENT}/${claimId}/${documentId}`,
        { responseType: "arraybuffer" }
      ),
      dispatch,
      "dashboard/GetClaimTransactionDocumentContent"
    );

    return response;
  }
);

export const getInitBasePrompt = createAsyncThunk(
  "dashboard/getInitBasePrompt",
  async (customerId: string, { dispatch }) => {
    const response = await CustomAction(
      Api.get(`ia/${customerId}/init`),
      dispatch,
      "dashboard/getInitBasePrompt"
    );
    return response;
  }
);
