export const StackedBarChartStyles = {
  stackedbarChartContainer: {
    marginTop: "-15px",
    marginLeft: "-20px",
    "@media only screen and (max-width: 599px)": {
      marginLeft: "-30px",
      marginTop: "0px",
    },
  },
};
export const CustomLegendStyles = {
  container: {
    display: "flex",
    listStyleType: "none",
    padding: "0px",
    justifyContent: "center",
    minWidth: "246px",
  },
  listItem: {
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
  },
  colorBox: {
    width: 9,
    height: 9,
    borderRadius: "50%",
    marginRight: "10px",
  },
  label: {
    color: "var(--black)",
    fontSize: "var(--font-size-12)",
    fontFamily: "var(--font-noto-sans)",
  },
};
