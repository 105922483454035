import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { TimeLineStyles } from "./BasicTimeLineStyles";
import { useMediaQuery } from "@mui/material";

interface TimelineData {
  left: string;
  right: React.ReactNode;
}

interface BasicTimelineProps {
  data: TimelineData[];
  dataPosition?: number;
  type?: "primary" | "secondary" | "ternary";
}

const BasicTimeline: React.FC<BasicTimelineProps> = ({
  data,
  type = "primary",
  dataPosition,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  let minWidthStyle = {};
  if (type === "secondary" && !isSmallScreen) {
    minWidthStyle = { minWidth: "95px" };
  }
  return (
    <Timeline sx={{ padding: "0px" }}>
      {data.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{
              ...TimeLineStyles.oppositeContent,
              marginTop:
                type === "primary" && index === 0
                  ? "17px"
                  : type === "secondary"
                  ? "0px"
                  : type === "ternary"
                  ? "0px"
                  : "-13px",
              flex: type === "secondary" ? 0 : 1,
              padding:
                type === "secondary" && isSmallScreen ? "0px" : "0px 24px",
              ...minWidthStyle,
              paddingLeft:
                type === "secondary" && isSmallScreen ? "0px" : "0px",
            }}
          >
            {item.left}
          </TimelineOppositeContent>
          <TimelineSeparator>
            {type === "primary" && index === 0 && (
              <TimelineConnector sx={TimeLineStyles.firstSeparator} />
            )}

            {type !== "secondary" && (
              <TimelineDot sx={{ ...TimeLineStyles.dot }} />
            )}

            {(type === "primary" || type === "ternary" || type === "secondary"
              ? index < data.length
              : index < data.length - 1) && (
              <TimelineConnector
                sx={{
                  ...TimeLineStyles.saperator,
                  width: index === dataPosition ? "3px" : "0.5px",
                }}
              />
            )}
            {type === "primary" && index === data.length - 1 && (
              <TimelineConnector sx={TimeLineStyles.lastSaperator} />
            )}
          </TimelineSeparator>
          <TimelineContent
            sx={{
              ...TimeLineStyles.leftContent,
              marginTop:
                type === "primary" && index === 0
                  ? "0px"
                  : type === "secondary"
                  ? "0px"
                  : type === "ternary"
                  ? "0px"
                  : "-27px",
              padding:
                type === "secondary" && isSmallScreen
                  ? "0px 0px 0px 10px"
                  : "0px 24px",
            }}
          >
            {item.right}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default BasicTimeline;
