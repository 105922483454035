import { Box, SelectChangeEvent } from "@mui/material";
import React from "react";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import { ShareMarketingCollateralResponsiveStyles } from "./ShareMarketingCollateralResponsiveStyles";
import { SHARE_MARKETING_COLLATERAL } from "../../constants";
import { useNavigate } from "react-router-dom";
import SendRemainder from "../../common/SendRemainder/SendRemainder";
import { sendRemaindersAndShareMarketData } from "../../constants/SendRemaindersAndShareMarketData";
const ShareMarketingCollateralResponsive: React.FC = () => {
  const navigate = useNavigate();
  const [selectedDropdownValue, setSelectedDropdownValue] = React.useState("");
  const handleRemainderChange = (event: SelectChangeEvent) => {
    setSelectedDropdownValue(event.target.value as string);
  };

  return (
    <Box sx={ShareMarketingCollateralResponsiveStyles.container}>
      <DashboardBreadCrumb
        handleClick={() => navigate(-1)}
        additionalContentClass={
          ShareMarketingCollateralResponsiveStyles.breadCrumbText
        }
        additionalContianerClass={
          ShareMarketingCollateralResponsiveStyles.breadCrumb
        }
        variant={"primary"}
        text={SHARE_MARKETING_COLLATERAL}
      />
      <SendRemainder
        recieverName={sendRemaindersAndShareMarketData.recieverName}
        dropDownData={sendRemaindersAndShareMarketData.dropdownData}
        primary={true}
        fileUploadName={sendRemaindersAndShareMarketData.fileUploadName}
        fileUploadSize={sendRemaindersAndShareMarketData.fileUploadSize}
        handleRemainderChange={handleRemainderChange}
        selectedDropdownValue={selectedDropdownValue}
      />
    </Box>
  );
};

export default ShareMarketingCollateralResponsive;
