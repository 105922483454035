export const PolicyListingResponsiveStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "25px",
  },
  dropDownContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  policyListingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  headingText: {
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    border: "1px solid var(--shaded-cyan)",
    padding: "16px 16px 10px 16px",
    minHeight: "104px",
    borderRadius: "10px",
    position: "relative",
    backgroundColor: "var(--white)",
  },
  headingSubheadingContainer: {
    minWidth: "79px",
    gap: "2px",
  },
  headingContainerText: {
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
  },
  subHeadingContainerText: {
    fontSize: "var(--font-size-12)",
    fontWeight: "300",
  },

  footerImageAndText: {
    display: "flex",
    width: "100%",
  },
  viewMoreContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
    paddingTop: "10px",
  },
  viewLessContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
  },
};
