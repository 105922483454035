import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import DropDownStyles from "./DropDownStyles";

const options = ["Road Side Assistance", "Update Policy", "Cancel Policy"];

const StyledMenuItem = styled(MenuItem)({
  padding: "16px",
  "& li": {
    padding: "0px",
  },
});

const DropDown: React.FC = () => {
  return (
    <Box sx={DropDownStyles.container}>
      {options.map((option) => (
        <StyledMenuItem key={option} selected={option === "Pyxis"}>
          <Typography sx={DropDownStyles.text}>{option}</Typography>
        </StyledMenuItem>
      ))}
    </Box>
  );
};

export default DropDown;
