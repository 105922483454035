import React from "react";
import { Props } from "./Props";
import { useMediaQuery } from "@mui/material";

const StarIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  const tab = useMediaQuery("@media(min-width: 600px) and (max-width: 800px)");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={tab ? "40px" : width}
      height={tab ? "40px" : height}
      viewBox="0 0 52 50"
      fill="none"
    >
      <path
        d="M10.6406 35.5938L13.7441 7.66699L31.6716 29.733L10.6406 35.5938Z"
        fill={fill}
      />
      <path
        d="M24.075 1.44622C24.3413 0.49505 25.6787 0.464797 25.9878 1.40295L30.2182 14.2449L29.2684 14.5578L25.038 1.71583L19.5512 21.3129L18.5882 21.0433L24.075 1.44622Z"
        fill="black"
      />
      <path
        d="M0.833727 21.2445C0.000484109 20.714 0.358948 19.4252 1.3464 19.4012L14.8632 19.0722L14.8875 20.0719L1.37073 20.4009L18.5381 31.3293L18.0011 32.1729L0.833727 21.2445Z"
        fill="black"
      />
      <path
        d="M9.05092 47.7991C8.71128 48.7267 9.77896 49.5327 10.5779 48.9519L27.0391 36.9861L26.4511 36.1772L9.98994 48.143L14.6391 35.4467L13.7001 35.1028L9.05092 47.7991Z"
        fill="black"
      />
      <path
        d="M40.8612 47.741C41.1404 48.6885 40.0231 49.4241 39.2632 48.7931L28.8607 40.1559L29.4995 39.3866L39.902 48.0238L34.1484 28.5033L35.1076 28.2206L40.8612 47.741Z"
        fill="black"
      />
      <path
        d="M50.8031 21.1291C51.6041 20.5511 51.1713 19.2853 50.1841 19.3188L29.8451 20.008L29.8789 21.0075L50.218 20.3182L39.2538 28.2302L39.839 29.0411L50.8031 21.1291Z"
        fill="black"
      />
    </svg>
  );
};

export default StarIcon;
