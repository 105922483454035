export const ConfigureDashboardStyles = {
  container: {
    display: "flex",
    margin: "0px -20px",
    borderBottom: "1px solid var(--primary-grey)",
    height: "415px",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    padding: "30px 30px 30px 30px",
    borderRight: "1px solid var(--primary-grey)",
    width: "277px !important",
  },
  checkBoxes: {
    display: "flex",
    gap: "25px",
    flexWrap: "wrap",
  },

  heading: {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-500)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
    padding: "30px",
  },
  checkBox: {
    width: "311px",
  },
};
