import { Box, SelectChangeEvent } from "@mui/material";
import BreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import CustomerDetailsCard from "../../common/CustomerDetailsCard/CustomerDetailsCard";
import { CustomerDetailsListStyles } from "./CustomerDetailsListStyles";
import Button from "../../common/CustomButton/CustomButton";
import { CustomerDetailsButton } from "../../constants/CustomerDetailsButtons";
import Drawers from "../../common/Drawers/Drawers";
import NewTask from "../NewTask/NewTask";
import {
  ADD_TASK,
  CRISTOFER,
  CRISTOFER_ID,
  MENU_ICON,
  NEW_TASK,
  NOTES,
} from "../../constants";
import React, { useState } from "react";
import BasicTabs from "../../common/BasicTabs/BasicTabs";
import Tables from "../../common/Tables/Tables";
import InteractionHistory from "../InteractionHistory/InteractionHistory";
import CustomerDetailsNotes from "../CustomerDetailsNotes/CustomerDetailsNotes";
import CustomerDetailsOverView from "../CustomerDetailsOverView/CustomerDetailsOverView";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import { useNavigate } from "react-router-dom";
import SendRemainder from "../../common/SendRemainder/SendRemainder";
import ClaimHistory from "../ClaimHistory/ClaimHistory";
import PaymentMethod, {
  PaymentMethodCardDataProps,
} from "../../common/PaymentMethod/PaymentMethod";
import { PersonChatProps } from "../CustomerDetailsNotes/CustomerDetailsNotes";
import { sendRemaindersAndShareMarketData } from "../../constants/SendRemaindersAndShareMarketData";
import Notes from "../Notes/Notes";
import { personData } from "../../constants/myTasksPersonData";
import { personChat } from "../../constants/myTasksPersonChatData";
export interface OverviewTableDataProps {
  policyType: string;
  policyDetails: {
    policyNumber: string;
    policyTypeNumber: string;
  };
  policyStatus: string;
  policyPremium: string;
  policyTerm: string;
  policyIdNo: string;
  policyLastPayment: string;
}
export interface InvoicesTableDataProps {
  invoice: string;
  policyDetails: {
    policyNumber: string;
    policyTypeNumber: string;
  };
  billingDate: string;
  amount: string;
  paymentMethod: string;
  paidOn: string;
}
interface MyTasksClientDetailsProps {
  clientName: string;
  clientEmail: string;
  clientPhone: string;
}
export interface MytasksDataProps {
  timeStamp: {
    date: string;
    time: string;
  };
  task: string;
  clientDetails: MyTasksClientDetailsProps;
  policy: string;
  priority: string;
  status: string;
}
export interface CustomerDetailsOverviewProps {
  overviewPolicyData: OverviewTableDataProps[];

  overviewTableHeaders: string[];
}
export interface CustomerDetailsInvoicesProps {
  invoicesTableHeaders: string[];
  invoicesData: InvoicesTableDataProps[];
}
interface HeadingSubheadingProps {
  heading: string;
  subheading: string;
}

export interface ClaimHistoryProps {
  claimId: string;
  active: boolean;
  claimsData: HeadingSubheadingProps[];
}
export interface InteractionHistoryDataProps {
  left: string;
  cardIcon: string;
  idNumber: string;
  interaction: string;
  text: string;
  profileIcon: string;
  agentName: string;
}
export interface CustomerDetailsHeaderCardDataProps {
  policyDetails: Array<{
    heading: string;
    subheading: string;
  }>;
  claimDetails: Array<{
    heading: string;
    subheading: string;
  }>;
  contactDetails: Array<{
    image: string;
    text: string;
  }>;
  interactionDetail: {
    text: string;
  };
}

interface CustomerDetailsListProps {
  customerOverViewData: CustomerDetailsOverviewProps;
  customerTasksData: MytasksDataProps[];
  customerInvoicesData: CustomerDetailsInvoicesProps;
  claimHistoryData: {
    activeClaimsData: ClaimHistoryProps[];
    pastClaimsData: ClaimHistoryProps[];
  };
  interactionHistoryData: InteractionHistoryDataProps[];
  paymentMethodCardData: PaymentMethodCardDataProps[];
  customerHeaderCardData: CustomerDetailsHeaderCardDataProps;
  notesChatData: PersonChatProps[];
}
const CustomerDetailsList: React.FC<CustomerDetailsListProps> = ({
  customerOverViewData,
  customerTasksData,
  customerInvoicesData,
  claimHistoryData,
  interactionHistoryData,
  paymentMethodCardData,
  customerHeaderCardData,
  notesChatData,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [open, setOpen] = React.useState(false);
  const [heading, setHeading] = React.useState("");
  const [primary, setPrimary] = React.useState(false);
  const [notesOpen, setNotesOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleButtonAction = (key: string) => {
    if (key === "Edit") {
      console.log("edit");
    } else if (key === "CALL") {
      console.log("call");
    } else if (key === "MARKETING") {
      toggleDrawer();
      setHeading("Share Marketing Collateral");
      setPrimary(true);
    } else if (key === "ADD NOTES") {
      setNotesOpen(true);
    }
  };
  const handleNotesClick = () => {
    setNotesOpen(!notesOpen);
  };
  const customerDetailsTabData = [
    {
      label: "Overview",
      content: (
        <CustomerDetailsOverView
          overviewPolicyData={customerOverViewData.overviewPolicyData}
          tasksData={customerTasksData}
          overviewTableHeaders={customerOverViewData.overviewTableHeaders}
        />
      ),
    },
    {
      label: "Invoices",
      content: (
        <Tables
          headerData={customerInvoicesData.invoicesTableHeaders}
          data={customerInvoicesData.invoicesData}
          type="invoicesTable"
        />
      ),
    },
    {
      label: "Payment Methods",
      content: <PaymentMethod paymentMethodCardData={paymentMethodCardData} />,
    },
    {
      label: "Claim History",
      content: (
        <ClaimHistory
          activeClaimsData={claimHistoryData.activeClaimsData}
          pastClaimsData={claimHistoryData.pastClaimsData}
        />
      ),
    },
    {
      label: "Interaction History",
      content: (
        <InteractionHistory interactionHistoryData={interactionHistoryData} />
      ),
    },
    {
      label: "Notes",
      content: <CustomerDetailsNotes personChatArray={notesChatData} />,
    },
  ];
  const navigate = useNavigate();

  const handleRemainderChange = (event: SelectChangeEvent) => {
    setSelectedDropdownValue(event.target.value as string);
  };
  return (
    <>
      <Box sx={CustomerDetailsListStyles.container}>
        <Box sx={CustomerDetailsListStyles.headerContainer}>
          <BreadCrumb
            variant={"secondary"}
            text={CRISTOFER}
            additionalText={CRISTOFER_ID}
            handleClick={() => navigate(-1)}
          />
          <Box sx={CustomerDetailsListStyles.buttonsContainer}>
            {CustomerDetailsButton.map((button, index) => (
              <Button
                key={index}
                type="outlined"
                text={button.text}
                onClick={() => handleButtonAction(button.key)}
                additionalClassName={CustomerDetailsListStyles.button}
              />
            ))}
            <Button
              text={ADD_TASK}
              additionalClassName={CustomerDetailsListStyles.addTaskButton}
              onClick={handleClick}
            />
            <Box sx={CustomerDetailsListStyles.menuIcon}>
              <img src={menuIcon} alt={MENU_ICON} />
            </Box>
          </Box>
        </Box>
        <Box>
          <CustomerDetailsCard data={customerHeaderCardData} />
        </Box>
        <Drawers
          open={open}
          toggleDrawer={handleClick}
          heading={NEW_TASK}
          additionalClass={CustomerDetailsListStyles.drawer}
        >
          <NewTask onCancelClick={handleClick} />
        </Drawers>
        <Box sx={CustomerDetailsListStyles.tabs}>
          <BasicTabs
            additionalLabelClass={CustomerDetailsListStyles.tabsText}
            tabs={customerDetailsTabData}
          />
        </Box>
      </Box>
      <Drawers open={drawerOpen} toggleDrawer={toggleDrawer} heading={heading}>
        <SendRemainder
          onCancelClick={toggleDrawer}
          recieverName={sendRemaindersAndShareMarketData.recieverName}
          dropDownData={sendRemaindersAndShareMarketData.dropdownData}
          primary={primary}
          fileUploadName={sendRemaindersAndShareMarketData.fileUploadName}
          fileUploadSize={sendRemaindersAndShareMarketData.fileUploadSize}
          handleRemainderChange={handleRemainderChange}
          selectedDropdownValue={selectedDropdownValue}
        />
      </Drawers>
      <Drawers open={notesOpen} toggleDrawer={handleNotesClick} heading={NOTES}>
        <Notes personDataArray={personData} personChatArray={personChat} />
      </Drawers>
    </>
  );
};

export default CustomerDetailsList;
