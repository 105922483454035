const fontStyles = {
  fontFamily: "var(--font-noto-sans)",
  fontSize: "var(--font-size-14)",
  fontWeight: "var(--font-weight-400)",
  lineHeight: "22px",
  textAlign: "left",
  color: "var(--support-white)",
};
export const TablesStyles = {
  status: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  statusText: {
    ...fontStyles,
  },
  policyText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(  --primary-light-blue)",
    cursor: "pointer",
  },
  imageAbsent: {
    visibility: "hidden",
  },
  imagePresent: {
    visibility: "visible",
  },
  clientInfoContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  clientName: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    color: "var(--support-white)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
  },
  priorityContainer: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  tableContainer: {
    boxShadow: "none",
    padding: "0px",
    maxHeight: "calc(100vh - 292px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableHead: {
    position: "sticky",
    top: "0",
    backgroundColor: "var(--white)",
    borderBottom: "2px solid var(--primary-grey)",
    zIndex: "10",
  },
  tableHeaderCells: {
    borderBottom: "2px solid var(--primary-grey)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    textAlign: "left",
    padding: "8px 25px 8px 20px",
    color: "var(--secondary-grey)",
    minWidth: "100px",
    height: "22px",
  },
  invoicesTableHeaderCells: {
    borderBottom: "2px solid var(--primary-grey)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    textAlign: "left",
    padding: "8px 25px 10px 25px",
    color: "var(--secondary-grey)",
    minWidth: "100px",
    height: "22px",
    cursor: "pointer",
  },
  tableHeaderLastCell: {
    borderBottom: "2px solid var(--primary-grey)",
    height: "22px",
  },
  customerStatusImg: {
    "> img": {
      width: "16px",
      height: "16px",
    },
  },
  tableHeaderText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--secondary-gray)",
    textAlign: "left",
  },
  tableRow: {
    borderBottom: "1px solid var(--primary-grey)",
    cusor: "pointer",
    verticalAlign: "top",
  },
  tableHeader: {
    display: "flex",
    gap: "9px",
    alignItems: "center",
  },
  tableBodyCells: {
    borderBottom: "0px",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    minWidth: "100px",
    color: "var(--support-white)",
    padding: "13px 25px 13px 20px",
  },
  tableBodyLastCell: {
    padding: "16px 25px 16px 25px",
  },
  customerPoliciesHoverElement: {
    display: "flex",
    alignItems: "center",
  },
  customerListingHoverElement: {
    display: "flex",
    alignItems: "center",
  },

  priorityText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
  },
  policyDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  policyTypeNumber: {
    borderBottom: "0px",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: 300,
    minWidth: "100px",
    color: "var(--support-white)",
  },
  policyType: {
    display: "flex",
    justifyContent: "flex-end",
  },
  customerDetailsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "180px",
  },
  customerName: {
    ...fontStyles,
  },
  customerNumber: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: 300,
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--support-white)",
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customerEmail: {
    ...fontStyles,
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customerPhoneNumber: {
    ...fontStyles,
  },
  downloadIcon: {
    width: "18px",
    height: "18px",
  },
  contactDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: "190px",
  },
  overView: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  commionStatementsHoverElement: {
    display: "flex",
    gap: "16px",
  },
  docIcon: {
    ">img": {
      height: "18px",
    },
  },
  commissionStatusImage: {
    width: "16px",
    height: "16px",
  },
  policyStatusImg: {
    width: "20px",
    height: "20px",
  },
  tableBodyCheckboxCells: {
    padding: "19.5px 19px 29.5px 27px",
    minWidth: "60px",
    borderBottom: "0px",
  },
  invoicesTableBodyCells: {
    borderBottom: "0px",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    minWidth: "100px",
    color: "var(--support-white)",
    padding: "13px 25px 13px 25px",
  },
  tableHeaderCheckboxCells: {
    padding: "8px 20px 8px 27px",
    borderBottom: "2px solid var(--primary-grey)",
    minWidth: "60px",
  },
  tasksMessage: {
    display: "flex",
    flexDirection: "column",
    gap: "0px",
  },
  tasksText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--support-white)",
    maxWidth: "350px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customerStatusContainer: {
    gap: "3px",
  },
  commissionStatusContainer: {
    gap: "3px",
  },
  policyDetailsText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--support-white)",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  customerDetailsText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--support-white)",
    maxWidth: "250px",
  },
  policyNumber: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--support-white)",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  folderImage: {
    "> img": {
      width: "24px",
      height: "24px",
    },
  },
  documentListingHoverElement: {
    "> img": {
      width: "3px",
      height: "16px",
    },
  },
  folderName: {
    color: "var(--support-white)",
  },
  dateButton: {
    color: "var(--support-white)",
    fontSize: "var(--font-size-12)",
    "&:hover": {
      backgroundColor: "var(--white) !important",
    },
  },
  commissionStatementsHoverContainer: {
    gap: "4px",
  },
  modalContainer: {
    width: "604px",
    height: "220px",
    paddingTop: "10px",
    "@media (max-width: 1023px)": {
      width: "320px",
      height: "290px",
      paddingTop: "10px",
    },
  },
  modalHeadingContainer: {
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    paddingTop: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "30px",
      paddingTop: "30px",
      paddingBottom: "20px",
    },
  },
  modalHeading: {
    fontSize: "var(--font-size-16)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "normal",
    },
  },
  imageText: {
    paddingLeft: "10px",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "normal",
      paddingLeft: "0px",
    },
  },
  cancelButton: {
    boxShadow: "none",
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
  statusContainer: {
    gap: "6px",
    minWidth: "110px",
  },
  dateButtonContainer: {
    width: "160px",
  },
  drawer: {
    width: "820px",
    padding: "unset",
  },
};
