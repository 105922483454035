import { Box } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import { MonthlyStatementCardStyles } from "./MonthlyStatementCardStyles";
import {
  COMMISSION_EARNED,
  CUSTOMER,
  POLICY_DETAILS_CAPS,
  TRANS_DATE,
  TRANS_TYPE,
} from "../../constants";
interface MonthlyStatementCardProps {
  commsissionDate: string;
  commissionTransactionType: string;
  commisionPolicyDetails: string;
  commissionCustomerDetails: string;
  commissionMonthlyEarned: string;
}
const MonthlyStatementCard: React.FC<MonthlyStatementCardProps> = ({
  commsissionDate,
  commissionTransactionType,
  commisionPolicyDetails,
  commissionCustomerDetails,
  commissionMonthlyEarned,
}) => {
  return (
    <Box sx={MonthlyStatementCardStyles.container}>
      <HeadingSubHeading
        containerAdditionalClassName={
          MonthlyStatementCardStyles.headingContainer
        }
        headingAdditionalClassName={MonthlyStatementCardStyles.headingText}
        subHeadingAdditionalClassName={
          MonthlyStatementCardStyles.subHeadingText
        }
        heading={commsissionDate}
        subheading={TRANS_DATE}
      />
      <HeadingSubHeading
        heading={commissionTransactionType}
        containerAdditionalClassName={
          MonthlyStatementCardStyles.headingContainer
        }
        headingAdditionalClassName={MonthlyStatementCardStyles.headingText}
        subHeadingAdditionalClassName={
          MonthlyStatementCardStyles.subHeadingText
        }
        subheading={TRANS_TYPE}
      />
      <HeadingSubHeading
        containerAdditionalClassName={
          MonthlyStatementCardStyles.headingContainer
        }
        headingAdditionalClassName={MonthlyStatementCardStyles.headingPolicyText}
        subHeadingAdditionalClassName={
          MonthlyStatementCardStyles.subHeadingText
        }
        heading={commisionPolicyDetails}
        subheading={POLICY_DETAILS_CAPS}
      />
      <HeadingSubHeading
        containerAdditionalClassName={
          MonthlyStatementCardStyles.headingContainer
        }
        headingAdditionalClassName={MonthlyStatementCardStyles.headingText}
        subHeadingAdditionalClassName={
          MonthlyStatementCardStyles.subHeadingText
        }
        heading={commissionCustomerDetails}
        subheading={CUSTOMER}
      />
      <HeadingSubHeading
        containerAdditionalClassName={
          MonthlyStatementCardStyles.headingContainer
        }
        headingAdditionalClassName={MonthlyStatementCardStyles.headingText}
        subHeadingAdditionalClassName={
          MonthlyStatementCardStyles.subHeadingText
        }
        heading={commissionMonthlyEarned}
        subheading={COMMISSION_EARNED}
      />
    </Box>
  );
};

export default MonthlyStatementCard;
