import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  getFileAClaimFormFieldsJson,
  PostFileAClaim,
} from "../../store/user/claims/claimsActions";
import DynamicForm from "../../common/DynamicForm/DynamicForm";
import {
  IAsyncOptionFn,
  IGetOptionsResponse,
  IOption,
} from "../../models/user/DynamicForm/DynamicForm";
import { CustomAction } from "../../store/customAction";
import {
  API_GET_LOOKUP_VALUES,
  API_POST_POLICIES,
  API_POST_POLICY_DETAILS_HEADER,
} from "../../Apis/ApiEndPoints";
import {
  IGetPolicesResponse,
  IPolicy,
} from "../../models/user/dashboard/Dashboard";
import { IPolicyDetailsHeaderResponse } from "../../models/user/policyDetails/PolicyDetails";
import {
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import { IPostFileAClaimRequestParams } from "../../models/user/claims/claims";
import { CloseRounded } from "@mui/icons-material";
import {
  setFileAClaimFormFieldsJson,
  setFileAClaimResponse,
} from "../../store/user/claims/claimsSlice";
import { Api } from "../..";
import Acknowledgement from "../Acknowledgement/Acknowledgement";
import MessageDialog from "../MessageDialog/MessageDialog";
import { useParams } from "react-router-dom";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const FileAClaim: React.FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { policiesData } = useAppSelector((state) => state.dashboard);
  const { userDetails } = useAppSelector((state) => state.userAuth);
  const { fileAClaimFormFieldsJson, fileAClaimResponse } = useAppSelector(
    (state: RootState) => state.claims
  );
  const { isError, calledReducerType } = useAppSelector(
    (state) => state.common
  );

  const [incidentTypes, setIncidentTypes] = useState<IOption[]>([]);
  const [policies, setPolicies] = useState<IOption[]>([]);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    dispatch(setFileAClaimFormFieldsJson(null));
    dispatch(setFileAClaimResponse(null));
    setOpen(false);
  }, [dispatch, setOpen]);

  const getPoliciesOptions = ({
    policies,
    policyType,
  }: {
    policies: IPolicy[];
    policyType: string;
  }): IOption[] =>
    policies
      .filter((policy) => {
        if (policyId) {
          if (
            policy.policyId === policyId &&
            policy.policyType === policyType
          ) {
            return true;
          }
        } else if (policy.policyType === policyType) {
          return true;
        }
        return false;
      })
      .map((policy) => ({
        code: `${policy.policyId}`,
        label: policy.carrierPolicyNo,
      }));

  const asyncOptionsFunctions: { [key: string]: IAsyncOptionFn } = useMemo(
    () => ({
      getPolicyTypes: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getPolicyTypes"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getPolicies: async (policyType) => {
        try {
          if (policiesData && policiesData.policyList) {
            const options: IOption[] = getPoliciesOptions({
              policies: policiesData.policyList,
              policyType: policyType ? policyType : "",
            });
            setPolicies(options);
            return options;
          } else {
            if (userDetails) {
              const response: IGetPolicesResponse = await CustomAction(
                Api.post(API_POST_POLICIES, {
                  customerId: userDetails?.customerId,
                }),
                dispatch,
                "getPolicies"
              );
              if (response.data && response.data.policyList) {
                const options: IOption[] = getPoliciesOptions({
                  policies: response.data.policyList,
                  policyType: policyType ? policyType : "",
                });
                setPolicies(options);
                return options;
              }
            }
          }
          return [];
        } catch (error) {
          return [];
        }
      },
      getVehicles: async (policyId) => {
        try {
          const response: IPolicyDetailsHeaderResponse = await CustomAction(
            Api.post(API_POST_POLICY_DETAILS_HEADER, {
              policyId,
            }),
            dispatch,
            "getVehicles"
          );
          if (response && response.data && response.data.vehicles) {
            const options: IOption[] = response.data.vehicles.map(
              (vehicle) => ({
                code: vehicle.riskId,
                label: vehicle.vehicleDescription,
              })
            );
            return options;
          }
          return [];
        } catch (error) {
          return [];
        }
      },
      getIncidentTypes: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getIncidentTypes"
          );
          setIncidentTypes(response.data);
          return response.data;
        } catch (error) {
          return [];
        }
      },
    }),
    [dispatch, policiesData, userDetails, policyId]
  );

  const getCarrierPolicyNo = useCallback(
    ({ policyId }: { policyId: string }) => {
      const policy = policies.find(
        (policy) => policy.code.toString() === policyId.toString()
      );
      return policy?.label || "";
    },
    [policies]
  );

  const getEventCode = useCallback(
    ({ templateId }: { templateId: string }): string => {
      return (
        incidentTypes.find((incidentType) => incidentType.code === templateId)
          ?.label || ""
      );
    },
    [incidentTypes]
  );

  const handleSubmit = useCallback(
    async (formValues: { [key: string]: any }) => {
      if (userDetails && userDetails.customerId) {
        const requestBody: IPostFileAClaimRequestParams = {
          accidentAddress: formValues.accidentAddress,
          carrierPolicyNo: getCarrierPolicyNo({
            policyId: formValues.policyId,
          }),
          customerId: userDetails?.customerId,
          eventCode: getEventCode({ templateId: formValues.templateId }),
          incidentDesc: formValues.incidentDesc,
          incidentTime: formValues.incidentTime.format("HH:mm") || "",
          lossDate: formValues.lossDate.format("YYYYMMDD") || "",
          policyType: formValues.policyType,
          riskId: formValues.riskId,
          sourceCd: "WebPortal",
          templateId: formValues.templateId,
        };
        dispatch(PostFileAClaim(requestBody));
      }
    },
    [userDetails, getCarrierPolicyNo, getEventCode, dispatch]
  );

  const handleMessageDialogClose = () => {
    setMessageDialogOpen(false);
  };

  useEffect(() => {
    if (open) {
      dispatch(getFileAClaimFormFieldsJson());
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (isError && calledReducerType === "claims/PostFileAClaim") {
      setOpen(false);
      setMessageDialogOpen(true);
    }
  }, [isError, calledReducerType, setOpen]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: "50%",
            "@media (max-width:768px)": {
              width: "100%",
            },
          },
        }}
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>File A Claim</Typography>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {fileAClaimFormFieldsJson && !fileAClaimResponse ? (
            <DynamicForm
              formFieldsJson={fileAClaimFormFieldsJson}
              onFormSubmit={handleSubmit}
              asyncOptionsFunctions={asyncOptionsFunctions}
            />
          ) : (
            <></>
          )}
          {fileAClaimResponse ? <Acknowledgement /> : <></>}
        </DialogContent>
      </Drawer>
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
    </>
  );
};

export default FileAClaim;
