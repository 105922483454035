import { Box } from "@mui/material";
import VehicleStyles from "./VehicleStyles";
import VehicleTable from "./VehicleTable";
import DriverTable from "./DriverTable";

function VehicleCard() {
  return (
    <Box sx={VehicleStyles.discounts}>
      <VehicleTable />
      <DriverTable />
    </Box>
  );
}

export default VehicleCard;
