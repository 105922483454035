import React from "react";
import { Box, Typography } from "@mui/material";
import { HeadingSubHeadingStyles } from "./HeadingSubHeadingStyles";
import { SxProps, Theme } from "@mui/system";
interface HeadingSubHeadingProps {
  heading: string | number;
  subheading: string;
  headingAdditionalClassName?: SxProps<Theme>;
  subHeadingAdditionalClassName?: SxProps<Theme>;
  containerAdditionalClassName?: SxProps<Theme>;
  percentage?: boolean; 
}

const HeadingSubHeading: React.FC<HeadingSubHeadingProps> = ({
  heading,
  subheading,
  headingAdditionalClassName,
  subHeadingAdditionalClassName,
  containerAdditionalClassName,
  percentage = false,
 ...rest
}) => {
  return (
    <Box
      sx={Object.assign(
        {},
        HeadingSubHeadingStyles.container,
        containerAdditionalClassName
      )}
    >
      <Typography
        sx={Object.assign(
          {},
          HeadingSubHeadingStyles.heading,
          headingAdditionalClassName
        )}
      >
        {heading}
        {percentage && (
        <Box component="span" sx={HeadingSubHeadingStyles.percentage}>
          %
        </Box>
  )}
      </Typography>
      <Typography
        sx={Object.assign(
          {},
          HeadingSubHeadingStyles.subheading,
          subHeadingAdditionalClassName
        )}
      >
        {subheading}
      </Typography>
    </Box>
  );
};

export default HeadingSubHeading;