export const CustomerlistingStyles = {
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    padding: "25px 30px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
    "@media (max-width: 1023px)": {
      marginTop: "60px",
      padding: "24px 20px",
      gap: "20px",
    },
  },
};
