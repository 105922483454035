import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box, useMediaQuery } from "@mui/material";
import { LineChartStyles } from "./LineChartStyles";

interface CommitionTrackingProps {
  day: string;
  value: number | null;
  currencySymbol?: string;
  suffix?: string;
}

interface ComissionTrackingDataProps {
  comissionTrackingData: CommitionTrackingProps[];
  primary?: boolean;
}

const LineChartComponent: React.FC<ComissionTrackingDataProps> = ({
  comissionTrackingData,
  primary = false,
}) => {
  const [data, setData] = useState<CommitionTrackingProps[]>([]);

  useEffect(() => {
    setData(comissionTrackingData);
  }, [comissionTrackingData]);

  const isMobile = useMediaQuery("(max-width:599px)");

  const filteredData = data.filter((item) => item.value !== null);

  const minValue = Math.min(
    ...filteredData.map((item) => item.value as number)
  );
  const adjustedMinValue = minValue > 0 ? minValue : 1;

  const CustomDot = (props: any) => {
    const { cx, cy } = props;
    return (
      <svg
        x={cx - 6}
        y={cy - 8}
        width={16}
        height={16}
        viewBox="0 0 12 12"
        fill="var( --white)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="6"
          cy="6"
          r="4"
          stroke="var(--secondary-blue)"
          strokeWidth="3"
          fill="var( --white)"
        />
      </svg>
    );
  };

  const yAxisTickFormatter = (value: any) => {
    const shouldFormat = primary;
    return shouldFormat
      ? ` ${value}`
      : value === 0
      ? `$${value}`
      : `$${value / 1000}K`;
  };

  return (
    <Box sx={LineChartStyles.lineChartContainer}>
      <ResponsiveContainer width="100%" height={isMobile ? 220 : 250}>
        <LineChart data={data}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            interval={6}
            tickLine={false}
            tick={({ x, y, payload, index }) => {
              const dx =
                index === 0 ? (isMobile ? -10 : 5) : isMobile ? -15 : -10;
              const dayIndex = data.findIndex(
                (item) => item.day === payload.value
              );
              const weekLabel = `Week ${Math.floor(dayIndex / 7) + 1}`;

              return (
                <text
                  x={x + dx}
                  y={y + 10}
                  color="var(--secondary-grey)"
                  fontSize={
                    isMobile ? "var(--font-size-10)" : "var(--font-size-14)"
                  }
                  fontFamily="var(--font-noto-sans)"
                  fontWeight={400}
                >
                  {weekLabel}
                </text>
              );
            }}
          />
          <YAxis
            axisLine={false}
            tickFormatter={yAxisTickFormatter}
            domain={[adjustedMinValue, "auto"]}
            tickLine={false}
            tick={{
              fontSize: isMobile
                ? "var(--font-size-10)"
                : "var(--font-size-14)",
              fontFamily: "var(--font-noto-sans)",
              color: "var(--secondary-grey)",
            }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "var(--support-white)",
              color: "var(--primary-grey)",
              boxShadow: " 0px 4px 12px 0px #00000052",
              borderRadius: "6px",
              fontFamily: "var(--font-noto-sans)",
              fontSize: "var(--font-size-14)",
              padding: "6px",
              display: "flex",
              alignItems: "center",
            }}
            formatter={(value: number) => {
              const shouldFormat = primary;
              return shouldFormat
                ? [` ${value}`, ""]
                : [`$ ${value / 1000}K`, ""];
            }}
            itemStyle={{
              color: "var(--primary-grey)",
            }}
          />
          <Line
            type="linear"
            dataKey="value"
            stroke="var(--secondary-blue)"
            dot={false}
            activeDot={<CustomDot />}
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default LineChartComponent;
