import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { RootState } from "../../../../../store/store";
import { getClaimsData } from "../../../../Dashboard/DashboardAction";
import { IClaimId } from "../../../../../models/user/policyDetails/PolicyDetails";
import { IClaim } from "../../../../../models/user/dashboard/Dashboard";
import { GetPolicyClaimsTransactions } from "../../../../../store/user/policyDetails/policyDetailsActions";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import CustomTimeline from "../../../../../common/CustomTimeline/CustomTimeline";
import moment from "moment";
import { dateFormat } from "../../../../../constants/Constants";
import { Typography, useMediaQuery } from "@mui/material";
import ClaimActivityCard from "./ClaimActivityCard/ClaimActivityCard";

const Claims = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { claimsData } = useAppSelector((state: RootState) => state.dashboard);
  const { policyDetailsHeader, claimsTransactions } = useAppSelector(
    (state: RootState) => state.policyDetails
  );

  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (userDetails?.customerId && !claimsData) {
      dispatch(getClaimsData({ customerId: userDetails?.customerId }));
    }
  }, [userDetails?.customerId, dispatch, claimsData]);

  useEffect(() => {
    if (
      policyDetailsHeader &&
      policyDetailsHeader.carrierPolicyNumber &&
      claimsData &&
      claimsData.claimList &&
      claimsData.claimList.length > 0
    ) {
      const claimIds: IClaimId[] = claimsData.claimList
        .filter(
          (claim: IClaim) =>
            claim.carrierPolicyNo === policyDetailsHeader.carrierPolicyNumber
        )
        .map((claim: IClaim) => ({
          claimId: claim.claimId,
        }));
      dispatch(GetPolicyClaimsTransactions(claimIds));
    }
  }, [
    claimsData,
    policyDetailsHeader,
    policyDetailsHeader?.carrierPolicyNumber,
    dispatch,
  ]);

  return claimsTransactions.length > 0 ? (
    <Timeline
      sx={{
        padding: "0px",
        [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.1 },
      }}
    >
      {[...claimsTransactions]
        .sort(
          (a, b) =>
            new Date(b.transactionDate).getTime() -
            new Date(a.transactionDate).getTime()
        )
        .map((transaction, index) => (
          <CustomTimeline
            key={`policy-activity-${index}`}
            oppositeContent={moment(transaction.transactionDate).format(
              dateFormat
            )}
            oppositeContentStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            dotStyle={{
              display: "flex",
              alignItems: "center",
            }}
            children={
              <ClaimActivityCard
                transaction={transaction}
                isLast={
                  index ===
                  (claimsTransactions
                    ? isMobile
                      ? claimsTransactions.length - 2
                      : claimsTransactions.length - 1
                    : false)
                }
              />
            }
          />
        ))}
    </Timeline>
  ) : (
    <Typography>No Transactions Found</Typography>
  );
};

export default Claims;
