import { createSlice } from "@reduxjs/toolkit";
import { ITasks } from "../../../models/user/Tasks/Tasks";
import { deleteTask, getTasks } from "./tasksActions";

const initialState: ITasks = {
  tasks: [],
  taskMessage: null,
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    setTaskMessage: (state, { payload }) => {
      state.taskMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.fulfilled, (state, { payload }) => {
      state.tasks = payload.data || [];
    });
    builder.addCase(deleteTask.fulfilled, (state, { payload }) => {
      state.taskMessage = payload.message || null;
    });
  },
});

export default tasksSlice.reducer;
export const { setTaskMessage } = tasksSlice.actions;
