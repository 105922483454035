const UpdateCoveragesStyles = {
  heading: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #3F3F3F",
    position: "sticky",
    top: 0,
    background: "#FBFDFC",
    zIndex: 99,
    img: {
      cursor: "pointer",
    },
    "@media (min-width:360px) and (max-width:599px)": {
      paddingBottom: "0px",
      height: "60px",
      alignItems: "center",
      padding: "19px 20px 19px 20px",
      border: "1px solid #D8D8D8",
      boxShadow: "0px 6px 30px 0px #00000014",
    },
  },
  headingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
  },
};

export default UpdateCoveragesStyles;
