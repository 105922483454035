import { Box } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../ImageAndText/ImageAndText";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import payNowIcon from "../../assets/svgs/paid-dollar-icon.svg";
import { InvoicesCardStyles } from "./InvoicesCardStyles";
import {
  AMOUNT,
  BILLING_DATE,
  DOWNLOAD,
  INVOICE,
  PAID_ON,
  PAYMENT_METHOD,
  PAY_NOW,
} from "../../constants";
interface InvoicesCardProps {
  billingDate: string;
  paymentMethod: string;
  paidOn: string;
  amount: string;
  invoice: string;
}
const InvoicesCard: React.FC<InvoicesCardProps> = ({
  billingDate,
  paymentMethod,
  paidOn,
  amount,
  invoice,
}) => {
  return (
    <Box sx={InvoicesCardStyles.container}>
      <Box sx={InvoicesCardStyles.cardContainer}>
        <HeadingSubHeading
          headingAdditionalClassName={InvoicesCardStyles.heading}
          containerAdditionalClassName={InvoicesCardStyles.headingContainer}
          subHeadingAdditionalClassName={InvoicesCardStyles.subheading}
          heading={invoice}
          subheading={INVOICE}
        />
        <HeadingSubHeading
          headingAdditionalClassName={InvoicesCardStyles.heading}
          subHeadingAdditionalClassName={InvoicesCardStyles.subheading}
          containerAdditionalClassName={InvoicesCardStyles.headingContainer}
          heading={billingDate}
          subheading={BILLING_DATE}
        />
        <HeadingSubHeading
          headingAdditionalClassName={InvoicesCardStyles.heading}
          containerAdditionalClassName={InvoicesCardStyles.headingContainer}
          subHeadingAdditionalClassName={InvoicesCardStyles.subheading}
          heading={amount}
          subheading={AMOUNT}
        />
        <HeadingSubHeading
          containerAdditionalClassName={InvoicesCardStyles.headingContainer}
          headingAdditionalClassName={InvoicesCardStyles.heading}
          subHeadingAdditionalClassName={InvoicesCardStyles.subheading}
          heading={paymentMethod.length === 0 ? "---- " : paymentMethod}
          subheading={PAYMENT_METHOD}
        />
        <HeadingSubHeading
          headingAdditionalClassName={InvoicesCardStyles.heading}
          containerAdditionalClassName={InvoicesCardStyles.headingContainer}
          subHeadingAdditionalClassName={InvoicesCardStyles.subheading}
          heading={paidOn.length === 0 ? "---- " : paidOn}
          subheading={PAID_ON}
        />
      </Box>
      <Box sx={InvoicesCardStyles.imagesContainer}>
        <ImageAndText image={downloadIcon} text={DOWNLOAD} />
        {paidOn.length === 0 && (
          <ImageAndText image={payNowIcon} text={PAY_NOW} />
        )}
      </Box>
    </Box>
  );
};

export default InvoicesCard;
