import { Box, Typography } from "@mui/material";
import React from "react";
import {
  claimSubmitted,
  referenceNumber,
  contactAgent,
} from "../../constants/Constants";
import AcknowledgementStyles from "./AcknowledgementStyles";
import success from "../../assets/success.svg";
import emailIcon from "../../assets/mail-1.svg";
import mobileIcon from "../../assets/phone-1.svg";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

const Acknowledgement: React.FC = () => {
  const { fileAClaimResponse } = useAppSelector(
    (state: RootState) => state.claims
  );
  return (
    <Box sx={AcknowledgementStyles.totalContainer}>
      <Box sx={AcknowledgementStyles.container}>
        <Box sx={AcknowledgementStyles.successImage}>
          <img src={success} alt="success" />
        </Box>
        <Box sx={AcknowledgementStyles.claimData}>
          <Typography sx={AcknowledgementStyles.claimSubmittedText}>
            {claimSubmitted}
          </Typography>
          <Typography sx={AcknowledgementStyles.referenceNumberText}>
            {referenceNumber}
          </Typography>
          <Typography sx={AcknowledgementStyles.referenceNumber}>
            {fileAClaimResponse?.carrierClaimNo || ""}
          </Typography>
        </Box>
        <Box sx={AcknowledgementStyles.agentData}>
          <Typography sx={AcknowledgementStyles.contactAgentText}>
            {contactAgent}
          </Typography>
          <Typography sx={AcknowledgementStyles.agentName}>
            {fileAClaimResponse?.adjusterDetails.nameInfo}
          </Typography>
          <Box sx={AcknowledgementStyles.agentContact}>
            <img src={mobileIcon} alt="mobile-icon" />
            <Typography sx={AcknowledgementStyles.agentNumber}>
              {fileAClaimResponse?.adjusterDetails.phoneInfo}
            </Typography>
          </Box>
          <Box sx={AcknowledgementStyles.agentemailContact}>
            <img src={emailIcon} alt="mobile-icon" />
            <Typography sx={AcknowledgementStyles.agentMailId}>
              {fileAClaimResponse?.adjusterDetails.emailInfo}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Acknowledgement;
