import React from "react";
import { Props } from "./Props";
import { useMediaQuery } from "@mui/material";

const LearnAboutIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;
  const tab = useMediaQuery("@media(min-width: 600px) and (max-width: 800px)");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={tab ? "40px" : width}
      height={tab ? "40px" : height}
      viewBox="0 0 46 47"
      fill="none"
    >
      <circle cx="34.0605" cy="11.0898" r="11.0898" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3452 1.24023C10.8327 1.24023 1.5 10.573 1.5 22.0854C1.5 26.1799 2.67998 29.9972 4.71822 33.2177L3.87324 33.7525C1.73672 30.3767 0.5 26.3748 0.5 22.0854C0.5 10.0207 10.2804 0.240234 22.3452 0.240234C34.41 0.240234 44.1904 10.0207 44.1904 22.0854C44.1904 34.1502 34.41 43.9307 22.3452 43.9307C20.5944 43.9307 18.891 43.7246 17.2583 43.3352C14.996 44.9973 9.28853 47.6854 2.74246 46.3377L1.70323 46.1237L2.52978 45.4584C3.32911 44.8151 4.55605 43.6651 5.61428 42.3264C6.68439 40.9726 7.52305 39.4989 7.65303 38.1992L8.64806 38.2987C8.48855 39.8938 7.49782 41.5562 6.39878 42.9465C5.60353 43.9526 4.72063 44.8544 3.9716 45.5365C9.91526 46.3774 14.992 43.8348 16.8396 42.3984L17.0317 42.249L17.2679 42.3081C18.8926 42.7147 20.5933 42.9307 22.3452 42.9307C33.8577 42.9307 43.1904 33.5979 43.1904 22.0854C43.1904 10.573 33.8577 1.24023 22.3452 1.24023Z"
        fill="black"
      />
    </svg>
  );
};

export default LearnAboutIcon;
