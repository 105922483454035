import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import MyTasksTable from "../MyTasksTable/MyTasksTable";
import MyTasksResponsive from "../MyTasksResponsive/MyTasksResponsive";
import { MyTasksStyles } from "./MyTasksStyles";
import listIcon from "../../assets/svgs/list-view-icon.svg";
import listWhiteIcon from "../../assets/svgs/list-view-white-icon.svg";
import calenderIcon from "../../assets/svgs/calender-view-icon.svg";
import calenderWhiteIcon from "../../assets/svgs/calender-view-white-icon.svg";
import HeadingText from "../../common/HeadingText/HeadingText";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import TabSwitch from "../../common/TabSwitch/TabSwitch";
import { tasksTableHeadingsData } from "../../constants/tasksData";
import { FILTER, MY_TASKS, TAB1, TAB2 } from "../../constants";
import FullCalendarComponent from "../MyTasksCalendar/MyTasksCalendar";
import { calenderData } from "../../constants/CalenderData";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
const MyTasks: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const [activeTab, setActiveTab] = useState(TAB1);
  const [isSlide, setIsSlide] = useState(false);
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setIsSlide(tab === TAB2);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  const tasksData = calenderData.flatMap((calendar) =>
    calendar.meetings.map((meeting) => meeting.taskDetails)
  );

  return (
    <>
      <Box sx={MyTasksStyles.container}>
        <Box sx={MyTasksStyles.headerContainer}>
          <Box sx={MyTasksStyles.headingFilterContainer}>
            <HeadingText heading={MY_TASKS} />
            {isSmallScreen && activeTab === TAB2 && (
              <ImageAndText
                additionalClassName={MyTasksStyles.imageAndTextHeadingContainer}
                image={filterIcon}
                text={FILTER}
                onhandleClick={handleClick}
              />
            )}
          </Box>
          <Box sx={MyTasksStyles.imageContainer}>
            <ImageAndText
              additionalClassName={MyTasksStyles.imageAndTextContainer}
              image={filterIcon}
              text={FILTER}
              onhandleClick={handleClick}
            />
            <TabSwitch
              tab1Title={isSmallScreen ? "List" : "List View"}
              tab2Title={isSmallScreen ? "Calendar" : "Calendar View"}
              tab1ActiveImage={listWhiteIcon}
              tab2ActiveImage={calenderWhiteIcon}
              tab2InActiveImage={calenderIcon}
              tab1InActiveImage={listIcon}
              handleTabClick={handleTabChange}
              activeTab={activeTab}
              isSlide={isSlide}
              tab2AdditionalStyles={MyTasksStyles.tab2AdditionalStyles}
              tab1AdditionalStyles={MyTasksStyles.tab1AdditionalStyles}
            />
          </Box>
        </Box>
        {activeTab === TAB1 ? (
          isSmallScreen ? (
            <MyTasksResponsive tasksData={tasksData} />
          ) : (
            <MyTasksTable
              tasksData={tasksData}
              activeTab={activeTab}
              tasksTableHeadingsData={tasksTableHeadingsData}
            />
          )
        ) : (
          <FullCalendarComponent dayWiseEvents={calenderData} />
        )}
      </Box>
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default MyTasks;
