export const PolicyListingCardStyles = {
  heading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  nameHeading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--support-white)",
    lineHeight: "22px",
  },
  nameSubheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--support-white)",
    lineHeight: "22px",
  },
  headingCarrierText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  imageAndText: {
    color: "var(--support-white)",
  },
  subheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  policyCardDetails: {
    display: "flex",
    rowGap: "15px",
    columnGap: "40px",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "16px",
    paddingBottom: "16px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  policyCardHeadings: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingBottom: "8px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  policyCardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    paddingBottom: "8px",
    borderRadius: "8px",
    border: "1px solid var(--shaded-cyan)",
    width: "100%",
    backgroundColor: "var(--white)",
  },
  viewDetails: {
    gap: "4px",
    alignItems: "center",
  },
  text: {
    color: "var(--support-white)",
  },
  headingsContainer: {
    minWidth: "81px",
    gap: "2px",
  },
  avatarContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  statusImg: {
    "> img": {
      width: "16px",
      height: "16px",
    },
  },
  headingsCardContainer: {
    minWidth: "81px",
    gap: "0px",
  },
  cardFooter: {
    display: "flex",
    gap: "24px",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "9px",
  },
  imageAndTextContainer: {
    gap: "6px",
  },
};
