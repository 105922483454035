import React from "react";
import { Props } from "./Props";

const CoveragesIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8238 2.86484C19.937 2.8224 20.0617 2.8224 20.1749 2.86484L33.5082 7.86484C33.7034 7.93802 33.8327 8.12459 33.8327 8.33301V19.9997C33.8327 25.2229 30.3585 29.5165 26.9953 32.4593C25.3032 33.9399 23.6134 35.1013 22.3477 35.8924C21.7143 36.2883 21.1858 36.5922 20.8144 36.7978C20.6287 36.9006 20.4822 36.9788 20.3815 37.0317C20.3311 37.0582 20.2922 37.0783 20.2655 37.0919L20.2347 37.1076L20.2241 37.113C20.2241 37.113 20.223 37.1136 19.9993 36.6663C19.7757 37.1136 19.7753 37.1133 19.7753 37.1133L19.764 37.1076L19.7332 37.0919C19.7065 37.0783 19.6676 37.0582 19.6172 37.0317C19.5165 36.9788 19.37 36.9006 19.1843 36.7978C18.8129 36.5922 18.2844 36.2883 17.651 35.8924C16.3853 35.1013 14.6955 33.9399 13.0034 32.4593C9.64022 29.5165 6.16602 25.2229 6.16602 19.9997V8.33301C6.16602 8.12459 6.2953 7.93802 6.49045 7.86484L19.8238 2.86484ZM19.9993 36.6663L19.7753 37.1133C19.9161 37.1837 20.0822 37.1839 20.223 37.1136L19.9993 36.6663ZM19.9993 36.1026C20.0865 36.0562 20.1976 35.9962 20.3301 35.9229C20.6879 35.7248 21.2011 35.4298 21.8177 35.0444C23.052 34.273 24.6955 33.1428 26.3368 31.7067C29.6402 28.8162 32.8327 24.7765 32.8327 19.9997V8.67951L19.9993 3.86701L7.16602 8.67951V19.9997C7.16602 24.7765 10.3585 28.8162 13.6619 31.7067C15.3032 33.1428 16.9467 34.273 18.181 35.0444C18.7976 35.4298 19.3108 35.7248 19.6686 35.9229C19.8011 35.9962 19.9122 36.0562 19.9993 36.1026Z"
        fill="black"
      />
      <circle cx="20.0013" cy="20.3333" r="8.33333" fill={fill} />
    </svg>
  );
};

export default CoveragesIcon;
