import React from "react";
import { Props } from "./Props";

const CarIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 65 52"
      fill="none"
    >
      <path
        d="M9.68306 6.37875C10.063 5.93976 10.6149 5.6875 11.1954 5.6875H42.7676C43.8721 5.6875 44.7676 6.58293 44.7676 7.6875V15.2432C44.7676 16.3477 43.8721 17.2432 42.7676 17.2432H4.65691C2.94375 17.2432 2.02352 15.2299 3.14456 13.9344L9.68306 6.37875Z"
        fill={fill}
      />
      <path
        d="M13.0911 6.56838C13.4719 5.72514 14.2667 4.44042 15.3923 3.37393C16.5178 2.30749 17.9289 1.5 19.5544 1.5H44.0748C45.7418 1.79059 49.4225 3.21513 51.1079 6.58619C52.8917 10.1541 57.7783 20.2676 60.0499 24.9848V50.3931C58.8046 50.5863 57.1058 50.7523 55.6152 50.6938C54.7802 50.661 54.0434 50.5588 53.4961 50.3698C52.9341 50.1757 52.6891 49.9336 52.6179 49.7021C52.0695 47.9197 52.0523 46.3649 52.1047 45.8508L51.1099 45.7493C51.0439 46.3957 51.0741 48.085 51.6621 49.9962C51.875 50.6881 52.5025 51.0846 53.1696 51.315C53.8514 51.5505 54.7003 51.6587 55.576 51.693C57.3336 51.762 59.3315 51.5372 60.6356 51.3102L61.0499 51.2381V24.7566L61.0004 24.6538C60.1627 22.914 58.9499 20.4001 57.6647 17.7441H64.4502V16.7441H57.1806C55.1566 12.5644 53.0484 8.23138 52.0023 6.13901C50.1034 2.34083 46.0114 0.808807 44.1993 0.506803L44.1585 0.5H19.5544C17.5821 0.5 15.9372 1.48004 14.7045 2.64802C13.4747 3.81326 12.6101 5.20611 12.1826 6.1503L7.28223 16.2337V16.0703H0V17.0703H6.87563L3.14062 24.7557V51.2702L3.59093 51.3152C4.76401 51.4323 7.76426 51.5961 10.439 51.3149C11.9801 51.1529 12.9508 50.1316 13.5252 49.0185C14.096 47.9122 14.3151 46.6506 14.3459 45.8186L13.3466 45.7816C13.3197 46.5069 13.1237 47.6159 12.6365 48.5599C12.1529 49.4971 11.4227 50.206 10.3345 50.3204C8.04294 50.5613 5.48136 50.4637 4.14062 50.3596V24.9858L13.0853 6.58124L13.0911 6.56838Z"
        fill="black"
      />
      <path
        d="M31.9044 41.3632C42.3493 41.3632 52.6037 39.9846 55.4905 39.188L55.7565 40.152C52.7485 40.982 42.3944 42.3632 31.9044 42.3632C30.7907 42.3632 29.7227 42.3651 28.6994 42.3669H28.6918C23.8487 42.3755 20.0043 42.3823 17.0325 42.1801C15.2294 42.0575 13.7221 41.8567 12.4915 41.5244C11.2606 41.192 10.2757 40.72 9.54688 40.0341L10.2322 39.3059C10.7992 39.8395 11.6132 40.2514 12.7522 40.559C13.8913 40.8666 15.3245 41.0617 17.1004 41.1824C20.034 41.382 23.8334 41.3754 28.676 41.3669C29.7045 41.3651 30.7801 41.3632 31.9044 41.3632Z"
        fill="black"
      />
      <path
        d="M46.3531 33.1151C46.1438 31.9451 46.278 30.2543 47.3043 28.9529C48.3084 27.6798 50.2629 26.661 53.9492 27.0018L54.0412 26.0061C50.1358 25.645 47.7972 26.7131 46.5191 28.3337C45.2633 29.926 45.1263 31.9357 45.3687 33.2912L46.3531 33.1151Z"
        fill="black"
      />
      <path
        d="M15.9359 28.9529C16.9622 30.2543 17.0964 31.9451 16.8871 33.1151L17.8715 33.2912C18.114 31.9357 17.9769 29.926 16.7211 28.3337C15.443 26.7131 13.1044 25.645 9.199 26.0061L9.29106 27.0018C12.9774 26.661 14.9318 27.6798 15.9359 28.9529Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8784 27.1074H20.6973L22.6979 34.0273H41.5598L43.8784 27.1074ZM23.4497 33.0273L22.0273 28.1074H42.4887L40.8402 33.0273H23.4497Z"
        fill="black"
      />
    </svg>
  );
};

export default CarIcon;
