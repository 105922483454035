import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialogStyles from "./CustomDialogStyles";

type onClose = () => void;

interface props {
  open: boolean;
  maxWidth: "lg" | "md" | "sm" | "xl" | "xs";
  onClose: onClose;
  title?: string;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  showDivider: boolean;
}

const CustomDialog: FC<props> = ({
  open,
  maxWidth,
  onClose,
  title,
  content,
  actions,
  showDivider,
}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} onClose={onClose} fullWidth>
      {title ? (
        <>
          <DialogTitle sx={CustomDialogStyles.commonDialogTitle}>
            <Typography>{title}</Typography>
            <CloseIcon onClick={onClose} sx={{ cursor: "pointer" }} />
          </DialogTitle>
          {showDivider ? <Divider /> : <></>}
        </>
      ) : (
        <></>
      )}
      <DialogContent sx={CustomDialogStyles.commonDialogContent}>
        {content}
      </DialogContent>
      {showDivider ? <Divider /> : <></>}
      {actions ? (
        <DialogActions sx={{ padding: actions ? "6px" : "20px" }}>
          {actions}
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default CustomDialog;
