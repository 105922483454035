import { Box, useMediaQuery } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  CustomLegendStyles,
  StackedBarChartStyles,
} from "./StackedBarChartStyles";

interface BarChartData {
  name: string;
  Open: number;
  "In Progress": number;
  Closed: number;
}

interface StackedBarChartDataProps {
  stackedBarChartData: BarChartData[];
}

const StackeBarChart: React.FC<StackedBarChartDataProps> = ({
  stackedBarChartData,
}) => {
  const isMobile = useMediaQuery("(max-width:599px)");

  const labelColors = [
    " var(--primary-red)",
    "var( --primary-yellow)",
    "var(--primary-green)",
  ];

  const XAxisTickStyles = {
    fontFamily: "var(--font-noto-sans)",
    fontStyle: "normal",
    fontSize: isMobile ? "var(--font-size-10)" : "var(--font-size-14)",
    color: "var(--secondary-grey)",
    fontWeight: "var(--font-weight-400)",
  };
  const YAxisTickStyles = {
    fontFamily: "var(--font-noto-sans)",
    fontStyle: "normal",
    fontSize: isMobile ? "var(--font-size-10)" : "var(--font-size-14)",
    color: "var(--secondary-grey)",
    fontWeight: "var(--font-weight-400)",
  };

  const CustomLegend = (props: any) => {
    const { payload } = props;
    return (
      <Box sx={CustomLegendStyles.container}>
        {payload.map((entry: any, index: any) => (
          <Box sx={CustomLegendStyles.listItem} key={`item-${index}`}>
            <Box
              sx={{
                ...CustomLegendStyles.colorBox,
                backgroundColor: labelColors[index],
              }}
            />
            <span style={CustomLegendStyles.label}>{entry.value}</span>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={StackedBarChartStyles.stackedbarChartContainer}>
      <ResponsiveContainer width={"100%"} height={isMobile ? 225 : 287}>
        <BarChart
          data={stackedBarChartData}
          margin={{
            top: 30,
            right: 0,
            left: 10,
            bottom: 5,
          }}
          style={{ cursor: "pointer" }}
          barSize={isMobile ? 10 : 20}
        >
          <defs>
            <linearGradient id="colorPart1" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stopColor="#FF9D9D" stopOpacity={1} />
              <stop
                offset="100%"
                stopColor="var(--secondary-red)"
                stopOpacity={1}
              />
            </linearGradient>

            <linearGradient id="colorPart2" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stopColor="#FFDF7D" stopOpacity={1} />
              <stop offset="100%" stopColor="#EABF47" stopOpacity={1} />
            </linearGradient>

            <linearGradient id="colorPart3" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stopColor="#96DFB9" stopOpacity={1} />
              <stop offset="100%" stopColor="#3EB172" stopOpacity={1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="2.5 2" vertical={false} />
          <XAxis dataKey="name" tickLine={false} tick={XAxisTickStyles} />
          <YAxis axisLine={false} tickLine={false} tick={YAxisTickStyles} />
          <Legend content={<CustomLegend />} />
          <Bar
            dataKey="Open"
            stackId="a"
            fill="url(#colorPart3)"
            stroke="var(--white)"
            strokeWidth={0.4}
            strokeOpacity={0.9}
          />
          <Bar
            dataKey="In Progress"
            stackId="a"
            fill="url(#colorPart2)"
            stroke="var(--white)"
            strokeWidth={0.4}
            strokeOpacity={0.9}
          />
          <Bar
            dataKey="Closed"
            stackId="a"
            fill="url(#colorPart1)"
            stroke="var(--white)"
            strokeWidth={0.4}
            strokeOpacity={0.9}
            radius={[2, 2, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StackeBarChart;
