export const configureDashboardResponsiveData = [
  {
    heading: "Productivity",
    dashboardData: ["Graph 1", "Graph 2", "Graph 3", "Graph 4", "Graph 5"],
  },
  {
    heading: "Policy related",
    dashboardData: ["Graph 1", "Graph 2", "Graph 3", "Graph 4", "Graph 5"],
  },
  {
    heading: "Performance Analytics",
    dashboardData: ["Graph 1", "Graph 2", "Graph 3", "Graph 4", "Graph 5"],
  },
  {
    heading: "Commission related",
    dashboardData: ["Graph 1", "Graph 2", "Graph 3", "Graph 4", "Graph 5"],
  },
  {
    heading: "Business overview",
    dashboardData: ["Graph 1", "Graph 2", "Graph 3", "Graph 4", "Graph 5"],
  },
];
