export const ClaimTrackingStyles = {
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "35px",
  },
  headingContainer: {
    gap: "6px",
  },
  heading: {
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
  },
  subheading: {
    fontSize: "var(--font-size-14)",
    lineHeight: "19px",
  },
  timeLineRightContent: {},
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "9px 14px",
  },
  timeLineContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  headingText: {
    paddingBottom: "10px",
    borderBottom: "1px solid var(--secondary-gray)",
  },
};
