export const InteractionHistoryStyles = {
  timelineCard: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingLeft: "4px",
    marginTop: "-7px",
  },
  timelineLeft: {
    fontFamily: "Noto Sans",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    minWidth: "62px",
  },
};
