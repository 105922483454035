import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { InputAdornment, SxProps, Theme } from "@mui/material";
import styled from "@emotion/styled";

interface MultilineTextFieldsProps {
  variant: "primary" | "secondary";
  rows?: number;
  label?: string;
  additionalStyles?: SxProps<Theme>;
  bottomComponent?: React.ReactNode;
  textAreaStyles?: SxProps<Theme>;
}
const StyledBox = styled(Box) ({
  "& .MuiTextField-root": {
    width: "100%",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "var(--font-noto-sans)",
  },
  "& .MuiOutlinedInput-input": {
    fontFamily: "var(--font-noto-sans)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid var(--primary-grey)",
    },
    "&:hover fieldset": {
      border: "1px solid var(--primary-grey)",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid var(--primary-grey)",
    },
  },
});
const MultilineTextFields: React.FC<MultilineTextFieldsProps> = ({
  variant,
  rows,
  label,
  additionalStyles,
  bottomComponent,
  textAreaStyles,
}) => {
  return (
    <StyledBox
      component="div"
      sx={additionalStyles} 
    >
      <TextField
        sx={Object.assign({}, textAreaStyles)}
        id="outlined-multiline-flexible"
        label={label}
        multiline={variant === "secondary"}
        rows={variant === "secondary" ? rows : undefined}
        InputProps={{
          endAdornment: bottomComponent ? (
            <InputAdornment
              position="end"
              sx={{ position: "absolute", left: "6px", bottom: "35px" }}
            >
              {bottomComponent}
            </InputAdornment>
          ) : null,
        }}
      />
    </StyledBox>
  );
};

export default MultilineTextFields;
