import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";
import {
  API_GET_FILE_A_CLAIM_FORM_FIELDS_JSON,
  POST_FILE_A_CLAIM,
} from "../../../Apis/ApiEndPoints";
import {
  IGetFileAClaimFormFieldsJsonResponse,
  IPostFileAClaimRequestParams,
  IPostFileAClaimResponse,
} from "../../../models/user/claims/claims";

export const getFileAClaimFormFieldsJson = createAsyncThunk(
  "claims/getFileAClaimFormFieldsJson",
  async (_, { dispatch }) => {
    const response: IGetFileAClaimFormFieldsJsonResponse = await CustomAction(
      Api.get(API_GET_FILE_A_CLAIM_FORM_FIELDS_JSON),
      dispatch,
      "claims/getFileAClaimFormFieldsJson"
    );
    return response;
  }
);

export const PostFileAClaim = createAsyncThunk(
  "claims/PostFileAClaim",
  async (requestParams: IPostFileAClaimRequestParams, { dispatch }) => {
    const response: IPostFileAClaimResponse = await CustomAction(
      Api.post(POST_FILE_A_CLAIM, requestParams),
      dispatch,
      "claims/PostFileAClaim"
    );
    return response;
  }
);
