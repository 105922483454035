import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { IPaymentFailed } from "../../models/user/payment/Payment";
import paymentStyle from "./PaymentStyle";
import { ErrorOutlineOutlined } from "@mui/icons-material";

const PaymentCancel: React.FC = () => {
  const { errorCode } = useParams();

  const navigate = useNavigate();

  let errorData: IPaymentFailed | null = null;

  if (errorCode) {
    errorData = JSON.parse(atob(errorCode));
  }

  const handleHome = () => navigate("/customer/dashboard");

  return (
    <Box sx={paymentStyle.pSuccesMain}>
      <Card
        sx={{
          width: "40%",
          "@media (max-width:768px)": {
            width: "100%",
          },
        }}
      >
        <CardContent sx={{ display: "grid", gap: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <ErrorOutlineOutlined fontSize="large" sx={{ color: "red" }} />
            <Typography sx={paymentStyle.pFailMsg}> Payment Failed </Typography>
          </Box>
          <Box>
            <Typography sx={{ ...paymentStyle.textFont, textAlign: "center" }}>
              {errorData?.message}
            </Typography>
          </Box>
          <Box sx={paymentStyle.paySection}>
            <Button
              onClick={handleHome}
              type="button"
              name="home"
              variant="contained"
            >
              Home
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PaymentCancel;
