export const claimTrackingData = [
  {
    date: "20 Feb’24",
    heading: "Approval Pending",
    subHeading: "Finalizing approvals and awaiting policyholder confirmation",
  },
  {
    date: "20 Feb’24",
    heading: "Resolution Negotiation",
    subHeading: "Negotiations with the repair shop for cost adjustments.",
  },
  {
    date: "20 Feb’24",
    heading: "Adjuster's Report",
    subHeading:
      "Minor damage to the front bumper, estimated repair cost provided.",
  },
  {
    date: "20 Feb’24",
    heading: "Claim Assessment",
    subHeading: "Initial assessment conducted, vehicle inspection scheduled.",
  },
  {
    date: "20 Feb’24",
    heading: "Claim Initiated",
    subHeading: "Policyholder reported a collision with another vehicle.",
  },
];
