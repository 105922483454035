import React from "react";
import HeadingText from "../../common/HeadingText/HeadingText";
import DropDown from "../../common/DropDown/DropDown";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import gearIcon from "../../assets/svgs/gear-icon.svg";
import ModalComponent from "../../common/ModalComponent/ModalComponent";
import CustomButton from "../../common/CustomButton/CustomButton";
import { dashboardJson } from "../../constants/dashboardData";
import {
  checkBoxData,
  radioButtons,
} from "../../constants/configureDashboardData";
import { AgentDashBoardStyles } from "./AgentDashboardStyles";
import { options } from "../../constants/DropDown";
import searchIcon from "../../assets/svgs/search-icon.svg";
import {
  CANCEL,
  CONFIGURE_DASHBOARD,
  DASHBOARD_SUBHEADING,
  MIKE,
  RESET,
  SAVE_CHANGES,
  THIS_MONTH,
  WELCOME,
} from "../../constants";
import PerformingPolicies from "../../common/PerformingPolicies/PerformingPolicies";
import LatestNews from "../../common/LatestNews/LatestNews";
import BarChartComponent from "../../common/BarChartComponent/BarChartComponent";
import LineChartComponent from "../LineChart/LineChart";
import Stats from "../../common/Stats/Stats";
import StackeBarChart from "../StackedBarChart/StackedBarChart";
import PieChartComponent from "../PieChart/PieChart";
import ConfigureDashboard from "../ConfigureDashboard/ConfigureDashboard";
import CelebrationCard from "../../common/CelebrationCard/CelebrationCard";
import { useNavigate } from "react-router-dom";
import DashboardContainer from "../DashboardContainer/DashboardContainer";

const AgentDashboard: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:1023px)");
  const initialCards = [
    {
      id: "card-1",
      heading: "Existing Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgentDashBoardStyles.existingBusinessContainer}
          statsData={dashboardJson.existingBusinessData}
        />
      ),
      showFooter: true,
    },
    {
      id: "card-2",
      heading: "Customers Celebrations",
      viewTask: false,
      component: <CelebrationCard />,
      showFooter: false,
      dropDownData: [],
    },
    {
      id: "card-3",
      heading: "Task That Need Attention",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: true,
      component: (
        <>
          <PieChartComponent />
        </>
      ),
    },
    {
      id: "card-4",
      heading: "Premium Written ",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 5000, 10000, 15000, 20000]}
          barChartData={dashboardJson.premiumWrittenData}
        />
      ),
    },
    {
      id: "card-5",
      heading: "Policies Sold",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 10, 20, 30, 40]}
          barChartData={dashboardJson.policiesSoldData}
          primary={true}
        />
      ),
    },
    {
      id: "card-6",
      heading: "Customer Stats",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgentDashBoardStyles.customerStatsContainer}
          statsData={dashboardJson.customerData}
        />
      ),
    },
    {
      id: "card-7",
      heading: "Claim Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <StackeBarChart
          stackedBarChartData={dashboardJson.stackedBarChartData}
        />
      ),
    },
    {
      id: "card-8",
      heading: "New Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgentDashBoardStyles.newBusinessContainer}
          statsData={dashboardJson.newBusinessData}
        />
      ),
    },
    {
      id: "card-9",
      heading: "Commission Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <LineChartComponent
          comissionTrackingData={dashboardJson.comissionTrackingData}
        />
      ),
    },
    {
      id: "card-10",
      heading: "Commissions By LOB Companies",
      dropDownData: [],
      viewTask: false,
      component: (
        <BarChartComponent
          barChartData={dashboardJson.lobCompaniesData}
          yAxisData={[0, 5000, 10000, 15000, 20000]}
        />
      ),
    },
    // {
    //   id: "card-11",
    //   heading: "Commissions By UW Companies",
    //   dropDownData: [],
    //   viewTask: false,
    //   component: (
    //     <BarChartComponent
    //       barChartData={dashboardJson.lobCompaniesData}
    //       yAxisData={[0, 5000, 10000, 15000, 20000]}
    //     />
    //   ),
    // },
    {
      id: "card-12",
      heading: "Top Performing Policies",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: <PerformingPolicies policies={dashboardJson.policiesData} />,
    },
    {
      id: "card-13",
      heading: "Latest News from Insurer",
      dropDownData: [],
      viewTask: false,
      component: <LatestNews newsData={dashboardJson.latestNewsData} />,
    },
  ];

  const [open, setOpen] = React.useState(false);
  const [agentData] = React.useState(initialCards);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [selectedAge, setSelectedAge] = React.useState<string>("");
  const navigate = useNavigate();

  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedAge(event.target.value);
  };
  const handleClick = () => {
    isMobile ? navigate("/config") : setOpen(!open);
  };

  return (
    <Box sx={AgentDashBoardStyles.container}>
      <Box sx={AgentDashBoardStyles.dashboardContainer}>
        <Box sx={AgentDashBoardStyles.headingContainer}>
          <HeadingText
            additionalHeadingClass={AgentDashBoardStyles.headingText}
            heading={WELCOME + " " + MIKE}
          />
          <Box sx={AgentDashBoardStyles.dropDownContainer}>
            <Box sx={AgentDashBoardStyles.conversationalText}>
              <Typography sx={AgentDashBoardStyles.overViewText}>
                {DASHBOARD_SUBHEADING}
              </Typography>
              <DropDown
                data={options}
                placeholder={THIS_MONTH}
                selectedValue={selectedAge}
                onChange={handleDropdownChange}
                formControlAdditionalClassName={
                  AgentDashBoardStyles.dropDownClass
                }
              />
            </Box>
            <Box sx={AgentDashBoardStyles.options}>
              <ImageAndText
                image={gearIcon}
                onClick={handleClick}
                text={CONFIGURE_DASHBOARD}
                additionalClassName={AgentDashBoardStyles.configureIcon}
                additionalClassText={AgentDashBoardStyles.configureText}
                imageClassName={AgentDashBoardStyles.gearIcon}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={AgentDashBoardStyles.cardsContainer}>
          <DashboardContainer
            additionalClassContainer={AgentDashBoardStyles.cardsInnerContainer}
            data={agentData}
          />
        </Box>
        {!isMobile && (
          <ModalComponent
            heading={CONFIGURE_DASHBOARD}
            open={open}
            handleOpen={handleOpen}
            additionalClassContainer={AgentDashBoardStyles.modal}
            searchImage={searchIcon}
          >
            <Box sx={AgentDashBoardStyles.modalContainer}>
              <ConfigureDashboard
                radioButtons={radioButtons}
                checkBoxData={checkBoxData}
              />
              <Box sx={AgentDashBoardStyles.buttonsContainer}>
                <CustomButton
                  additionalClassName={AgentDashBoardStyles.button}
                  onClick={handleOpen}
                  type="outlined"
                  text={RESET}
                />
                <CustomButton
                  additionalClassName={AgentDashBoardStyles.button}
                  onClick={handleOpen}
                  type="outlined"
                  text={CANCEL}
                />
                <CustomButton
                  additionalClassName={AgentDashBoardStyles.button}
                  onClick={handleOpen}
                  type="filled"
                  text={SAVE_CHANGES}
                />
              </Box>
            </Box>
          </ModalComponent>
        )}
      </Box>
    </Box>
  );
};

export default AgentDashboard;
