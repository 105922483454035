import React, { useEffect } from "react";
import { Box, Card, Typography } from "@mui/material";
import ClaimsStyles from "./ClaimsStyles";
import { activeClaims, pastClaims } from "../../../../constants/Constants";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  getClaimsData,
  GetClaimTransactions,
} from "../../../Dashboard/DashboardAction";
import ClaimCard from "../../../Dashboard/Claims/ClaimCard/ClaimCard";
import { RootState } from "../../../../store/store";
import {
  IClaim,
  IGetClaimTransactionsRequestParams,
} from "../../../../models/user/dashboard/Dashboard";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";

const Claims: React.FC = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { claimsData } = useAppSelector((state: RootState) => state.dashboard);
  const { policyDetailsHeader } = useAppSelector(
    (state: RootState) => state.policyDetails
  );

  const handleClaimViewDetails = (claimId: string) => {
    const requestParams: IGetClaimTransactionsRequestParams = {
      claimId,
    };
    dispatch(GetClaimTransactions(requestParams));
  };

  const getActiveClaims = () => {
    if (claimsData && claimsData.claimList && claimsData.claimList.length > 0) {
      return claimsData.claimList
        ?.filter(
          (claim: IClaim) =>
            claim.carrierClaimStatus !== "Closed" &&
            claim.carrierPolicyNo === policyDetailsHeader?.carrierPolicyNumber
        )
        .map((claim: IClaim) => (
          <ClaimCard
            claim={claim}
            key={`policy-tab-claim-active-${claim.carrierClaimNo}`}
          />
        ));
    } else {
      return [];
    }
  };

  const getPastClaims = () => {
    if (claimsData && claimsData.claimList && claimsData.claimList.length > 0) {
      return claimsData.claimList
        ?.filter(
          (claim: IClaim) =>
            claim.carrierClaimStatus === "Closed" &&
            claim.carrierPolicyNo === policyDetailsHeader?.carrierPolicyNumber
        )
        .map((claim: IClaim) => (
          <ClaimCard
            claim={claim}
            key={`policy-tab-claim-past-${claim.carrierClaimNo}`}
          />
        ));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (userDetails) {
      dispatch(getClaimsData({ customerId: userDetails?.customerId }));
    }
  }, [userDetails, dispatch]);

  return (
    <Box aria-label="Claims Tab" sx={ClaimsStyles.container}>
      <Box aria-label="Active Claims" sx={ClaimsStyles.activeClaimsContainer}>
        <Box sx={ClaimsStyles.titleBox}>
          <CustomIcon iconName="ActiveClaims" />
          <Typography sx={ClaimsStyles.titleText}>{activeClaims}</Typography>
        </Box>
        {getActiveClaims().length > 0 ? (
          getActiveClaims()
        ) : (
          <Card sx={ClaimsStyles.noClaimsCard}>
            <Typography sx={ClaimsStyles.noClaimsText}>
              No Claims Available
            </Typography>
          </Card>
        )}
      </Box>
      <Box aria-label="Active Claims" sx={ClaimsStyles.activeClaimsContainer}>
        <Box sx={ClaimsStyles.titleBox}>
          <CustomIcon iconName="PastClaims" />
          <Typography sx={ClaimsStyles.titleText}>{pastClaims}</Typography>
        </Box>
        {getPastClaims().length > 0 ? (
          getPastClaims()
        ) : (
          <Card sx={ClaimsStyles.noClaimsCard}>
            <Typography sx={ClaimsStyles.noClaimsText}>
              No Claims Available
            </Typography>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default Claims;
