export const documentListingListCardStyles = {
  documentCard: {
    display: "flex",
    justifyContent: "space-between",
    padding: "14px 16px",
    width: "100%",
    border: "1px solid var(--shaded-cyan)",
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    alignItems: "center",
  },
  documentIcon: {
    width: "24px",
    height: "24px",
    "> img": {
      width: "100%",
      height: "100%",
    },
  },
  documentContent: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  documentFolderName: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--support-white)",
  },
  documentLastModified: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--secondary-grey)",
  },
  documentMenu: {
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
  modalContainer: {
    width: "604px",
    height: "220px",
    paddingTop: "10px",
    "@media (max-width: 1023px)": {
      width: "320px",
      height: "290px",
      paddingTop: "10px",
    },
  },
  modalHeadingContainer: {
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    paddingTop: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "30px",
      paddingTop: "30px",
      paddingBottom: "20px",
    },
  },
  modalHeading: {
    fontSize: "var(--font-size-16)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "normal",
    },
  },
  imageText: {
    paddingLeft: "10px",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "normal",
      paddingLeft: "0px",
    },
  },
  cancelButton: {
    boxShadow: "none",
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
};
