import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import MonthlyStatementResponsiveListing from "../MonthlyStatementResponsiveListing/MonthlyStatementResponsiveListing";
import MonthlyCommissionTable from "../MonthlyCommissionTable/MonthlyCommissionTable";
import { MonthlyCommissionStatementsStyles } from "./MonthlyCommissionStatementsStyles";
import { AUGUST, COMMISSION, DOWNLOAD, STATEMENT } from "../../constants";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import { monthlyStatement } from "../../constants/MonthlyStatementHeaderData";
import { useNavigate } from "react-router-dom";
import { comissionMonthlyStatementsData, commissionMonthlyStatementTableHeadingsData } from "../../constants/comissionMonthlyStatementData";
const MonthlyCommissionStatements: React.FC = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  return (
    <Box sx={MonthlyCommissionStatementsStyles.container}>
      <DashboardBreadCrumb
        variant={"primary"}
        text={`${COMMISSION} - ${AUGUST} ${STATEMENT}`}
        handleClick={() => navigate(-1)}
        additionalContentClass={MonthlyCommissionStatementsStyles.breadCrumbText}
      />
      <Box sx={MonthlyCommissionStatementsStyles.mainContainer}>
        <Box sx={MonthlyCommissionStatementsStyles.headingsContainer}>
          {monthlyStatement.map((item, index) => (
            <HeadingSubHeading
              key={index}
              containerAdditionalClassName={
                MonthlyCommissionStatementsStyles.headingContainer
              }
              headingAdditionalClassName={
                MonthlyCommissionStatementsStyles.heading
              }
              subHeadingAdditionalClassName={
                MonthlyCommissionStatementsStyles.subHeading
              }
              heading={item.heading}
              subheading={item.subHeading}
            />
          ))}
        </Box>
        {!isSmallScreen && (
          <ImageAndText
            additionalClassName={
              MonthlyCommissionStatementsStyles.imageAndTextContainer
            }
            imageClassName={MonthlyCommissionStatementsStyles.image}
            image={downloadIcon}
            text={DOWNLOAD}
          />
        )}
      </Box>
      <Box>
        {isSmallScreen ? (
          <MonthlyStatementResponsiveListing comissionMonthlyStatementsData={comissionMonthlyStatementsData} />
        ) : (
          <MonthlyCommissionTable comissionMonthlyStatementsData={comissionMonthlyStatementsData} headerData={commissionMonthlyStatementTableHeadingsData} />
        )}
      </Box>
    </Box>
  );
};

export default MonthlyCommissionStatements;
