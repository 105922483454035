import { Box } from "@mui/material";
import DocumentListingListCard from "../../common/DocumentListingListCard/DocumentListingListCard";
import { documentListingListViewCardsStyles } from "./DocumentListingListViewCardsStyles";
interface DocumentListingListViewCardsDataProps {
  folderName?: string;
  fileName?: string;
  lastModified: string;
}
interface DocumentListingListViewCardsProps {
  documentListingListViewData: DocumentListingListViewCardsDataProps[];
}
const DocumentListingListViewCards: React.FC<
  DocumentListingListViewCardsProps
> = ({ documentListingListViewData }) => {
  return (
    <Box sx={documentListingListViewCardsStyles.cardsContainer}>
      {documentListingListViewData.map((item, index) => (
        <DocumentListingListCard
          key={index}
          name={item.folderName || item.fileName}
          lastModified={item.lastModified}
          isFolder={!!item.folderName}
        />
      ))}
    </Box>
  );
};

export default DocumentListingListViewCards;
