import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { Box, DialogContent, Drawer, Typography } from "@mui/material";
import AddDriverFormStyles from "./AddDriverFormStyles";
import { CloseOutlined } from "@mui/icons-material";
import { RootState } from "../../../../../../store/store";
import {
  IAsyncOptionFn,
  IGetOptionsResponse,
} from "../../../../../../models/user/DynamicForm/DynamicForm";
import DynamicForm from "../../../../../../common/DynamicForm/DynamicForm";
import { CustomAction } from "../../../../../../store/customAction";
import { API_GET_LOOKUP_VALUES } from "../../../../../../Apis/ApiEndPoints";
import { PostAddDriver } from "../../../../../../store/user/policyChange/policyChangeActions";
import { IDriverPolicyChangeRequestParams } from "../../../../../../models/user/PolicyChange/PolicyChange";
import { formatDateToYYYYMMDD } from "../../../../../../utils/Utility";
import { useParams } from "react-router-dom";
import { GetAddDriverFormJson } from "../../../../../../store/user/driver/driverActions";
import MessageDialog from "../../../../../../components/MessageDialog/MessageDialog";
import { setAddDriverFormFieldsJson } from "../../../../../../store/user/driver/driverSlice";
import { Api } from "../../../../../..";

interface IProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AddDriverForm: React.FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { policyDetailsHeader } = useAppSelector(
    (state: RootState) => state.policyDetails
  );
  const { addDriverFormFieldsJson } = useAppSelector(
    (state: RootState) => state.driver
  );

  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state: RootState) => state.common
  );
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);

  const asyncFunctions: { [key: string]: IAsyncOptionFn } = useMemo(
    () => ({
      getPrefixOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getPrefixOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getMaritalStatusOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getMaritalStatusOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getGenderOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getGenderOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getRelationshipToInsuredOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getRelationshipToInsuredOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getEmploymentStatusOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getEmployementStatusOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getEducationLevelOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getEducationLevelOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getLicensingStateOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getLicensingStateOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
      getDriverLicenseStatusOptions: async (fieldName) => {
        try {
          const response: IGetOptionsResponse = await CustomAction(
            Api.get(`${API_GET_LOOKUP_VALUES}/${fieldName}`),
            dispatch,
            "getDriverLicenseStatusOptions"
          );
          return response.data;
        } catch (error) {
          return [];
        }
      },
    }),
    [dispatch]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleAddDriverFromOnSubmit = (formValues: { [key: string]: any }) => {
    if (
      policyDetailsHeader &&
      policyDetailsHeader.carrierPolicyNumber &&
      userDetails &&
      userDetails.customerId &&
      policyId
    ) {
      const requestParms: IDriverPolicyChangeRequestParams = {
        carrierPolicyNo: policyDetailsHeader?.carrierPolicyNumber,
        changeDt: formatDateToYYYYMMDD(new Date()),
        customerId: userDetails?.customerId,
        detailDescription: "Add Driver to the Policy",
        policyRef: policyId,
        driverInfo: {
          dateOfBirth: formValues.dateOfBirth.format("YYYY-MM-DD"),
          driverAge: JSON.stringify(
            new Date().getFullYear() - formValues.dateOfBirth.year()
          ),
          genderCd: formValues.genderCd,
          givenName: formValues.givenName,
          licensedStateProvCd: formValues.licensedStateProvCd,
          licenseDt: formValues.licenseDt.format("YYYY-MM-DD"),
          licenseNumber: formValues.licenseNumber,
          maritalStatus: formValues.maritalStatus,
          otherGivenName: formValues.otherGivenName,
          relationshipToInsuredCd: formValues.relationshipToInsuredCd,
          surName: formValues.surName,
        },
      };
      dispatch(PostAddDriver(requestParms));
    }
  };

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
  }, []);

  useEffect(() => {
    if (open) {
      dispatch(GetAddDriverFormJson());
    }
  }, [dispatch, open]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "policyChange/PostAddDriver"
    ) {
      setOpen(false);
      setMessageDialogOpen(true);
      dispatch(setAddDriverFormFieldsJson(null));
    }
  }, [isSuccess, isError, calledReducerType, setOpen, dispatch]);

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: {
            width: "50%",
            "@media (max-width:768px)": {
              width: "100%",
            },
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={AddDriverFormStyles.heading}>
          <Typography sx={AddDriverFormStyles.headingText}>
            Add Driver
          </Typography>
          <CloseOutlined sx={{ cursor: "pointer" }} onClick={handleClose} />
        </Box>
        <DialogContent>
          {addDriverFormFieldsJson ? (
            <DynamicForm
              formFieldsJson={addDriverFormFieldsJson}
              asyncOptionsFunctions={asyncFunctions}
              onFormSubmit={handleAddDriverFromOnSubmit}
            />
          ) : (
            <></>
          )}
        </DialogContent>
      </Drawer>
      <MessageDialog
        open={messageDialogOpen}
        handleClose={handleMessageDialogClose}
      />
    </>
  );
};

export default AddDriverForm;
