import React from "react";
import { Box, Button, SxProps, Theme } from "@mui/material";
import { ButtonStyles } from "./CustomButtonStyles";

interface ButtonProps {
  text: string;
  type?:
    | "outlined"
    | "filled"
    | "grey-outlined"
    | "green-outlined"
    | "green-filled"
    | "red-filled";
  onClick?: () => void;
  onHandleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  additionalClassName?: SxProps<Theme>;
  additionalContainerClassName?: SxProps<Theme>;
}

const CustomButton: React.FC<ButtonProps> = ({
  text,
  type = "filled",
  onClick,
  onHandleClick,
  additionalClassName,
  additionalContainerClassName,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onHandleClick) {
      onHandleClick(event);
    }
    if (onClick) {
      onClick();
    }
  };

  const buttonClassName =
    type === "filled"
      ? ButtonStyles.filledButton
      : type === "outlined"
      ? ButtonStyles.outlinedButton
      : type === "green-outlined"
      ? ButtonStyles.greenOutlinedButton
      : type === "green-filled"
      ? ButtonStyles.greenFilledButton
      : type === "red-filled"
      ? ButtonStyles.redFilledButton
      : ButtonStyles.greyOutlinedButton;

  return (
    <Box
      sx={Object.assign({}, additionalContainerClassName)}
      onClick={handleClick}
    >
      <Button sx={Object.assign({}, buttonClassName, additionalClassName)}>
        {text}
      </Button>
    </Box>
  );
};

export default CustomButton;
