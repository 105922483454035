import React from "react";
import { Props } from "./Props";

const EmailIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect
        x="1"
        y="7.80493"
        width="15.4915"
        height="10.3502"
        rx="1.21482"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.03807 5.5C5.31159 5.5 4.72266 6.08893 4.72266 6.81542V9.31162L15.9306 17.3953L25.7838 9.18339C25.9959 9.00659 26.3112 9.03524 26.488 9.24736C26.6648 9.45949 26.6361 9.77478 26.424 9.95157L15.9749 18.6601L3.72266 9.82334V6.81542C3.72266 5.53665 4.7593 4.5 6.03807 4.5H26.5422C27.821 4.5 28.8576 5.53665 28.8576 6.81542V23.5218C28.8576 24.6334 27.9564 25.5346 26.8448 25.5346H5.7355C4.62384 25.5346 3.72266 24.6334 3.72266 23.5218V14.1373C3.72266 13.8612 3.94651 13.6373 4.22266 13.6373C4.4988 13.6373 4.72266 13.8612 4.72266 14.1373V23.5218C4.72266 24.0812 5.17612 24.5346 5.7355 24.5346H26.8448C27.4042 24.5346 27.8576 24.0812 27.8576 23.5218V6.81542C27.8576 6.08893 27.2687 5.5 26.5422 5.5H6.03807Z"
        fill="black"
      />
    </svg>
  );
};

export default EmailIcon;
