import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_GET_ADD_DRIVER_FORM_FIELDS_JSON } from "../../../Apis/ApiEndPoints";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";
import { IGetAddDriverFormFieldsResponse } from "../../../models/user/Driver/Driver";

export const GetAddDriverFormJson = createAsyncThunk(
  "driver/GetAddDriverFormJson",
  async (_, { dispatch }) => {
    const response: IGetAddDriverFormFieldsResponse = await CustomAction(
      Api.get(API_GET_ADD_DRIVER_FORM_FIELDS_JSON),
      dispatch,
      "driver/GetAddDriverFormJson"
    );
    return response;
  }
);
