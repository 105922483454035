export const paymentdetaildata = [
  {
    date: "01 Sep ‘24",
    name: "UW Logo/ Name",
    payment: "Paid",
    price: "$16,656",
  },
  {
    date: "14 Sep ‘24",
    name: "UW Logo/ Name",
    payment: "Paid",
    price: "$6,656",
  },
  {
    date: "25 Sep ‘24",
    name: "UW Logo/ Name",
    payment: "pending",
    price: "$16,656",
  },
  {
    date: "01 Oct ‘24",
    name: "UW Logo/ Name",
    payment: "Paid",
    price: "$16,656",
  },
  {
    date: "14 Oct ‘24",
    name: "UW Logo/ Name",
    payment: "pending",
    price: "$656",
  },
];
