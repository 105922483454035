import React from "react";
import { Props } from "./Props";

const PastClaimsIcons: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle cx="11.5" cy="11.5" r="4.5" fill={fill} />
      <path
        d="M11.5 5C11.5 4.72386 11.2761 4.5 11 4.5C10.7239 4.5 10.5 4.72386 10.5 5V11C10.5 11.1894 10.607 11.3625 10.7764 11.4472L14.7764 13.4472C15.0234 13.5707 15.3237 13.4706 15.4472 13.2236C15.5707 12.9766 15.4706 12.6763 15.2236 12.5528L11.5 10.691V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0.5C5.20101 0.5 0.5 5.20101 0.5 11C0.5 16.799 5.20101 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 5.20101 16.799 0.5 11 0.5ZM1.5 11C1.5 5.75329 5.75329 1.5 11 1.5C16.2467 1.5 20.5 5.75329 20.5 11C20.5 16.2467 16.2467 20.5 11 20.5C5.75329 20.5 1.5 16.2467 1.5 11Z"
        fill="black"
      />
    </svg>
  );
};

export default PastClaimsIcons;
