import { Avatar, Box } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import { PolicyListingCardStyles } from "./PolicyListingCardStyles";
import activeIcon from "../../assets/svgs/active-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import payNowIcon from "../../assets/svgs/paynow-icon.svg";
import ImageAndText from "../ImageAndText/ImageAndText";
import stringAvatar from "../../utils/StringAvatar";
import pendingIcon from "../../assets/svgs/pending-icon.svg";
import cancelIcon from "../../assets/svgs/cancelled-icon.svg";
import overdueIcon from "../../assets/svgs/overdue-icon.svg";
import docIcon from "../../assets/svgs/document-icon.svg";
import {
  ACTIVE_CAPS,
  ACTIVE_LOWER,
  CANCELLED,
  CANCELLED_CAPS,
  CARRIER,
  CUSTOMER_NAME_2,
  DUE_RENEWAL,
  MENU_ICON,
  NO_OF_ITEMS,
  OVERDUE,
  OVERDUE_CAPS,
  PAY_NOW,
  POLICY_DETAILS_CAPS,
  PREMIUM,
  RENEWAL,
  TERMS,
  VIEW_DETAILS,
} from "../../constants";
import { useNavigate } from "react-router-dom";
interface CardProps {
  image: string;
  customerName: string;
  customerNumber: string;
  policyNumber: string;
  policyTypeNumber: string;
  customerStatus: string;

  customerPremium: string;
  customerTerm: string;
  customItems: string;
  customerCarrier?: string;
  customerIdNo?: string;
}

const PolicyListingCard: React.FC<CardProps> = ({
  policyNumber,
  policyTypeNumber,
  image,
  customerName,
  customerNumber,
  customerStatus,

  customerPremium,
  customerTerm,
  customItems,
  customerCarrier,
  customerIdNo,
}) => {
  const renderStatus = (status: string) => {
    let statusImage = "";
    let statusText = "";
    if (status.toLowerCase() === ACTIVE_LOWER) {
      statusImage = activeIcon;
      statusText = ACTIVE_CAPS;
    }
    if (status.toLowerCase() === RENEWAL) {
      statusImage = pendingIcon;
      statusText = DUE_RENEWAL;
    }
    if (status.toLowerCase() === OVERDUE) {
      statusImage = overdueIcon;
      statusText = OVERDUE_CAPS;
    }
    if (status.toLowerCase() === CANCELLED) {
      statusImage = cancelIcon;
      statusText = CANCELLED_CAPS;
    }
    return <ImageAndText imageClassName={PolicyListingCardStyles.statusImg} additionalClassText={PolicyListingCardStyles.imageAndText} additionalClassName={PolicyListingCardStyles.imageAndTextContainer} image={statusImage} text={statusText} />;
  };
  const navigate=useNavigate();
  return (
    <Box sx={PolicyListingCardStyles.policyCardContainer}>
      <Box sx={PolicyListingCardStyles.policyCardHeadings}>
        <Box sx={PolicyListingCardStyles.avatarContainer}>
          <Box>
            {image.length === 0 ? (
              <Avatar {...stringAvatar(customerName)} />
            ) : (
              <Avatar src={image} />
            )}
          </Box>
          <HeadingSubHeading
            heading={customerName}
            subheading={customerNumber}
            containerAdditionalClassName={
              PolicyListingCardStyles.headingsContainer
            }
            headingAdditionalClassName={PolicyListingCardStyles.nameHeading}
            subHeadingAdditionalClassName={PolicyListingCardStyles.nameSubheading}
          />
        </Box>
        <Box>{renderStatus(customerStatus)}</Box>
      </Box>
      <Box sx={PolicyListingCardStyles.policyCardDetails}>
        <HeadingSubHeading
          heading={policyNumber + " | " + policyTypeNumber}
          subheading={POLICY_DETAILS_CAPS}
          containerAdditionalClassName={
            PolicyListingCardStyles.headingsCardContainer
          }
          headingAdditionalClassName={PolicyListingCardStyles.heading}
          subHeadingAdditionalClassName={PolicyListingCardStyles.subheading}
        />
        <HeadingSubHeading
          heading={customerTerm}
          subheading={TERMS}
          containerAdditionalClassName={
            PolicyListingCardStyles.headingsCardContainer
          }
          headingAdditionalClassName={PolicyListingCardStyles.heading}
          subHeadingAdditionalClassName={PolicyListingCardStyles.subheading}
        />
        <HeadingSubHeading
          heading={customItems}
          subheading={NO_OF_ITEMS}
          containerAdditionalClassName={
            PolicyListingCardStyles.headingsCardContainer
          }
          headingAdditionalClassName={PolicyListingCardStyles.heading}
          subHeadingAdditionalClassName={PolicyListingCardStyles.subheading}
        />

        <HeadingSubHeading
          heading={customerPremium}
          subheading={PREMIUM}
          containerAdditionalClassName={
            PolicyListingCardStyles.headingsCardContainer
          }
          headingAdditionalClassName={PolicyListingCardStyles.heading}
          subHeadingAdditionalClassName={PolicyListingCardStyles.subheading}
        />
        {customerCarrier && (
          <HeadingSubHeading
            heading={customerCarrier || ""}
            subheading={CARRIER}
            containerAdditionalClassName={
              PolicyListingCardStyles.headingsCardContainer
            }
            headingAdditionalClassName={PolicyListingCardStyles.headingCarrierText}
            subHeadingAdditionalClassName={PolicyListingCardStyles.subheading}
          />
        )}

        {customerIdNo && (
          <HeadingSubHeading
            heading={CUSTOMER_NAME_2}
            subheading={customerIdNo}
          />
        )}
      </Box>

      {customerIdNo && (
        <Box sx={PolicyListingCardStyles.cardFooter}>
          <ImageAndText
            additionalClassText={PolicyListingCardStyles.text}
            additionalClassName={PolicyListingCardStyles.viewDetails}
            image={payNowIcon}
            text={PAY_NOW}
          />
          <img src={menuIcon} alt={MENU_ICON} />
        </Box>
      )}
      {customerCarrier && (
        <Box sx={PolicyListingCardStyles.cardFooter} onClick={()=>navigate("/policy-details")}>
          <ImageAndText
            additionalClassText={PolicyListingCardStyles.text}
            additionalClassName={PolicyListingCardStyles.viewDetails}
            image={docIcon}
            text={VIEW_DETAILS}
          />
        </Box>
      )}
    </Box>
  );
};

export default PolicyListingCard;
