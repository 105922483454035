export const CustomerDetailsInteractionHistory=[
    {
      "left": "20 Feb’24",
      "cardIcon": "calenderIcon",
      "idNumber": "# 2Aa114530g7f",
      "interaction": "Email Interaction",
      "text": "Endorsement to add a new dxriver. The note can be much longer as well.",
      "profileIcon": "profileIcon",
      "agentName": "Agent name"
    },
    {
      "left": "18 Feb’24",
      "cardIcon": "bellIcon",
      "idNumber": "# 2Aa114530g7f",
      "interaction": "Phone Interaction",
      "text": "Endorsement to add a new driver. The note can be much longer as well.",
      "profileIcon": "profileIcon",
      "agentName": "Agent name"
    },
    {
      "left": "15 Feb’24",
      "cardIcon": "starIcon",
      "idNumber": "# 2Aa114530g7f",
      "interaction": "Chat Interaction",
      "text": "Endorsement to add a new driver. The note can be much longer as well.",
      "profileIcon": "profileIcon",
      "agentName": "Agent name"
    },
    {
      "left": "12 Feb’24",
      "cardIcon": "starIcon",
      "idNumber": "# 2Aa114530g7f",
      "interaction": "Chat Interaction",
      "text": "Endorsement to add a new driver. The note can be much longer as well.",
      "profileIcon": "profileIcon",
      "agentName": "Agent name"
    }
  ]
  