import { Box } from "@mui/material";
import React from "react";
import { MonthlyCommissionTableStyles } from "./MonthlyCommissionTableStyles";
import Tables from "../../common/Tables/Tables";
export interface MonthlyCommissionTableDataProps {
  commissionDate: string;
  commissionTransactionType: string;
  commissionPolicyDetails: string;
  commissionCustomerDetails: string;
  comissionMonthlyEarned: string;
}

interface MonthlyCommissionTableProps {
  comissionMonthlyStatementsData: MonthlyCommissionTableDataProps[];
  headerData: string[];
}
const MonthlyCommissionTable: React.FC<MonthlyCommissionTableProps> = ({comissionMonthlyStatementsData,headerData}) => {
  return (
    <Box>
      <Tables
        headerData={headerData}
        primary={true}
        type={"nohover"}
        data={comissionMonthlyStatementsData}
        additionaltableContainer={MonthlyCommissionTableStyles.tableContainer}
      />
    </Box>
  );
};

export default MonthlyCommissionTable;
