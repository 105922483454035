import React from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent, Box, SxProps, Theme, InputLabel, styled } from '@mui/material';
import { ReactComponent as CustomArrowIcon } from '../../assets/svgs/dropdown-carret.svg';
import { FormDropdownStyles } from './FormDropDownStyles';


interface FormDropdownProps {
  labelId: string;
  label: string; 
  value: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  additionalFormControlStyles?: SxProps<Theme>;
  additionalSelectStyles?: SxProps<Theme>;
  additionalMenuItemStyles?: SxProps<Theme>;
}

const StyledSelect = styled(Select)({
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
    padding: "17px 36px 17px 12px",
    color: "var(--secondary-black)",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "14px !important",
    fontWeight: "var(--font-weight-500)",
  },
  "& .MuiSelect-icon": {
    top: "unset",
    right: "25px",
  },
})

const FormDropDown: React.FC<FormDropdownProps> = ({
  labelId,
  value,
  label,
  options,
  onChange,
  additionalFormControlStyles,
  additionalSelectStyles,
  additionalMenuItemStyles,
  
}) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string);
  };

  return (
    <Box sx={FormDropdownStyles.container}>
      <FormControl
        sx={Object.assign({}, FormDropdownStyles.formControl, additionalFormControlStyles)}
      >
        <InputLabel>{label}</InputLabel>
        <StyledSelect
          labelId={labelId}
          value={value}
          onChange={handleChange}
          IconComponent={CustomArrowIcon}
          label={label}
          sx={ additionalSelectStyles}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={Object.assign({}, FormDropdownStyles.menuItem, additionalMenuItemStyles)}
            >
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
};

export default FormDropDown;
