import React from "react";
import { TextField, InputAdornment, Box, SxProps, Theme } from "@mui/material";
import searchIcon from "../../assets/svgs/search-icon.svg";
import { SearchFieldStyles } from "./SearchFieldStyles";
import { SEARCHFIELD_PLACEHOLDER, SEARCH_ICON } from "../../constants";
import styled from "@emotion/styled";

interface SearchFieldProps {
  additionalClassName?: SxProps<Theme>;
  handleSearch?: () => void;
  placeholder?: string;
}
const StyledSearchField = styled(TextField)({
  width: "536px",
  padding: "10px",
  borderRadius: "5px",
  fontSize: "16px",
  outline: "none",
  position: "relative",
  "& .MuiOutlinedInput-root": {
    height: "48px",
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
    "& .MuiInputBase-input": {
      width: "88%",
    },
  },
});
const SearchField: React.FC<SearchFieldProps> = ({
  additionalClassName,
  handleSearch,
  placeholder = SEARCHFIELD_PLACEHOLDER,
}) => {
  return (
    <StyledSearchField
      sx={additionalClassName}
      onChange={handleSearch}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box sx={SearchFieldStyles.searchIcon}>
              <img src={searchIcon} alt={SEARCH_ICON} />
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
