import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import { Box, SxProps } from "@mui/material";
import { StatsStyles } from "./StatsStyles";
import { Theme } from "@emotion/react";
interface StatsProps {
  heading: string | number;
  subheading: string;
  percentage?: boolean;
}
interface StatsDataProps {
  statsData: StatsProps[];
  additionalClass?: SxProps<Theme>;
}
const Stats: React.FC<StatsDataProps> = ({ statsData, additionalClass }) => {
  return (
    <Box sx={Object.assign({}, StatsStyles.container, additionalClass)}>
      {statsData.map((data, index) => (
        <HeadingSubHeading
        containerAdditionalClassName={StatsStyles.headingsContainer}
          key={index}
          heading={data.heading}
          subheading={data.subheading}
          percentage={data.percentage}
          headingAdditionalClassName={StatsStyles.headingText}
          subHeadingAdditionalClassName={StatsStyles.subheadingText}
        />
      ))}
    </Box>
  );
};

export default Stats;
