import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Header from "./components/Header/Header";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Forms from "./pages/Forms/Forms";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import Lookup from "./pages/Lookup/Lookup";
import UiSettings from "./pages/UiSettings/UiSettings";
import Features from "./pages/Features/Features";
import Login from "./pages/Login/Login";
import Navbar from "./components/Navbar/Navbar";
import { getCookie } from "../utils/Utility";
import { LogoutOutlined } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { adminLogout } from "../store/admin/adminAuth/adminAuthActions";

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

const AdminRoutes = () => {
  const drawerWidth = 240;

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const authenticated = getCookie("authenticated");
  const roleCode = getCookie("roleCode");

  const { isSuccess, calledReducerType } = useAppSelector(
    (state) => state.common
  );

  const [open, setOpen] = useState(false);
  const [pageName, setPageName] = useState("Dashboard");
  const [snackbarState, setSnackbarState] = useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({ open: false, Transition: SlideTransition });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = useCallback(() => {
    dispatch(adminLogout());
  }, [dispatch]);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarState((prevState) => ({ ...prevState, open: false }));
  }, []);

  const headerAndNavbarSection: ReactElement = useMemo(
    () =>
      authenticated && roleCode === "1" ? (
        <>
          <CssBaseline />
          <Header position="fixed" open={open} drawerwidth={drawerWidth}>
            <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  {pageName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleLogout}
                >
                  <LogoutOutlined />
                </IconButton>
              </Box>
            </Toolbar>
          </Header>
          <Navbar
            handleDrawerClose={handleDrawerClose}
            open={open}
            setPageName={setPageName}
            drawerWidth={drawerWidth}
          />
        </>
      ) : (
        <></>
      ),
    [authenticated, handleLogout, open, pageName, roleCode]
  );

  const routes: ReactElement = useMemo(
    () =>
      authenticated && roleCode === "1" ? (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/lookup" element={<Lookup />} />
          <Route path="/uisettings" element={<UiSettings />} />
          <Route path="/features" element={<Features />} />
        </>
      ) : (
        <>
          <Route index element={<Login />} />
          <Route path="/login" element={<Login />} />
        </>
      ),
    [authenticated, roleCode]
  );

  useEffect(() => {
    if (!authenticated || roleCode !== "1") {
      navigate("/admin/login");
    }
  }, [authenticated, roleCode, navigate]);

  useEffect(() => {
    const pageName = location.pathname.split("/")[2];
    if (pageName) {
      setPageName(pageName.charAt(0).toUpperCase() + pageName.slice(1));
    } else {
      setPageName("Dashboard");
    }
  }, [location]);

  useEffect(() => {
    if (isSuccess && calledReducerType === "adminAuth/adminLogin") {
      setSnackbarState((prevState) => ({ ...prevState, open: true }));
    }
  }, [isSuccess, calledReducerType]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {headerAndNavbarSection}
        <Box
          component="main"
          sx={
            authenticated
              ? { flexGrow: 1, p: 3, marginTop: "64px" }
              : { width: "100%", minHeight: "100vh" }
          }
        >
          <Routes>{routes}</Routes>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={snackbarState.open}
        TransitionComponent={snackbarState.Transition}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="success" variant="filled">
          Logged in successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminRoutes;
