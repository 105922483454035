const CoverageTabStyles = {
  container: {
    display: "block",
    marginTop: "20.5px",
    marginLeft: "0px",
    "@media (min-width: 1367px)": {
      margin: "0px auto",
    },
    "@media (min-width: 360px) and (max-width: 649px)": {
      display: "none",
    },
  },
  container2: {
    display: "none",
    "@media (max-width: 649px)": {
      display: "block",
    },
  },
  coverageAndLimits: {
    marginTop: "34px",
  },
  discounts: {
    marginTop: "29px",
    marginBottom: "60px",
  },
  coverageAndLimitsHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "8px",
    width: "320px",
  },
  coverageAndLimitsHeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontHeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  discountsHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginBottom: "13px",
    // width: "100%",
    maxWidth: "320px",
  },
  discountsHeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontHeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  coveragesText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  perThing: {
    color: "#969696",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  responsiveContainer: {
    border: "1px solid #C9E1EC",
    padding: "0px 10px",
    borderRadius: "10px",
    background: "#FFFFFF",
  },
  coveragesHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  coveragesCard: {
    borderBottom: "1px solid #D0D1D9",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    ":last-child": {
      borderBottom: "none",
    },
  },
  headText: {
    fontFamily: "Playfair Display",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    padding: "15px 0px 13px 0px",
    textAlign: "left",
    borderBottom: "1px solid #3F3F3F",
  },
  coveragesTextHeading: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  perItemText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "#969696",
  },
  limitsText: {
    fontFamily: " Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  limitsCount: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "19.07px",
    textAlign: "left",
  },
  coverages: {
    border: "1px solid #C9E1EC",
    padding: "0px 10px 0px 10px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
  },
  premiumText: {
    fontFamily: "Playfair Display",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "left",
  },
  premiumValueText: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "right",
  },
  vechileValueText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "left",
  },
  vechileNameText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "15.4px",
    color: "#1D252B",
    textAlign: "left",
  },
  vechilePremiumTextValues: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  expanededItemkeyText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  expanededItemvalueText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  premiumTableContainer: {
    marginTop: "18px",
    padding: "0px 24px",
    width: "auto",
    height: "auto",
    minWidth: "450px",
    border: "1px solid rgba(201, 225, 236, 1)",
    boxShadow: "none",
    "@media(min-width:650px) and (max-width:1024px)": {
      width: "auto",
    },
  },
  discountCard: {
    marginBottom: "30px",
  },
  expandedItemsContainer: {
    borderTop: "1px solid #D0D1D9",
    display: "flex",
    padding: "22px 23px 20px 0px",
    flexDirection: "column",
    gap: "15px",
  },

  discountsTableContainer: {
    padding: "0px 24px",
    width: "auto",
    minWidth: "450px",
    boxShadow: "none",
    border: "1px solid #C9E1EC",
    "@media(min-width:650px) and (max-width:1024px)": {
      width: "auto",
    },
  },
  discountsTableHead: {
    borderBottom: "1px solid rgba(63, 63, 63, 1)",
  },
  expandedItems: {
    display: "flex",
    justifyContent: "space-between",
  },

  discountCardContext: {
    borderBottom: " 1px solid #D0D1D9",
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    ":last-child": {
      borderBottom: "none",
    },
  },
  discountsCardTypesText: {
    fontFamily: "Playfair Display",
    padding: "16px 0px 15px 0px",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "left",
    borderBottom: "1px solid #3F3F3F",
  },
  discountHeadText: {
    display: "flex",
    justifyContent: "space-between",
    img: {
      cursor: "pointer",
    },
  },
  discountContentText: {
    display: "flex",
    gap: "9px",
  },
  totalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    paddingTop: "20px",
  },
  coverageHeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "left",
  },
  discountsText: {
    fontFamily: "Playfair Display",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "17.6px",
    textAlign: "left",
  },
  discountsCard: {
    border: "1px solid #C9E1EC",
    borderRadius: "10px",
    padding: "0px 10px 0px 10px",
    backgroundColor: "#FFFFFF",
  },
  discountText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  vechileText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  typesText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  cardpremiums: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 10px 19px 10px",
    borderBottom: "1px solid #C9E1EC",
  },
  limits: {
    display: "flex",
    gap: "10px",
    aliginItems: "center",
  },
  cardTotalPremiums: {
    padding: "15px 0px 20px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  vechilePremiumValues: {
    display: "flex",
    gap: "85px",
  },
  tableHead: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    opacity: "70%",
    borderBottom: "0px",
    paddingBottom: "8px",
    textAlign: "left",
  },
  tableContainer: {
    padding: "0px 24px",
    width: "auto",
    minWidth: "450px",
    boxShadow: "none",
    border: "1px solid #C9E1EC",
    "@media(min-width:650px) and (max-width:1024px)": {
      width: "auto",
    },
  },
  tableRow: {
    border: "none",
    borderBottom: "1px solid rgba(63, 63, 63, 1)",
  },
  premiumFirstRow: {
    minWidth: "255px",
    borderBottom: "0px",
  },
  premiumFirstTitle: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",

    letterSpacing: "0em",
    textAlign: "left",
  },
  premiumTableCells: {
    display: "flex",
    gap: "28px",
  },
  tableInnerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  premiumTableRowCells: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderBottom: "0px",
  },
  premiumTableCellValue: {
    fontFamily: "Noto Sans",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  premiumTableCellVechile: {
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "19.8px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#1D252B",
  },
  tableBodyCell: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    opacity: "100%",
    letterSpacing: "0px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "223px",
    borderBottom: "none",
    textAlign: "left",
  },

  tableRows: {
    borderBottom: "1px solid #D0D1D9",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  textAlign: {
    textAlign: "right",
  },
};
export default CoverageTabStyles;
