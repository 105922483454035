export const CustomerDetailsListStyles = {
  container: {
    padding: "25px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  button: {
    padding: "9px 24px",
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "var(--support-white)",
    },
  },
  addTaskButton: {
    padding: "9px 32px",
    boxShadow: "none",
  },
  drawer: {
    width: "820px",
    padding: "0px",
  },
  tabsText: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
  },
  tabs: {
    marginTop: "5px",
  },
  menuIcon: {
    cursor: "pointer",
    "> img": {
      marginRight: "10px",
    },
  },
};
