export const HeadingTextStyles = {
  dashboardCardHeading: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "26.66px",
    textAlign: "left",
    color: "var(--support-white)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-18)",
      lineHeight: "21px",
    },
    "@media screen and (max-width: 599px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "21px",
    },
  },
};
