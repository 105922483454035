import React from "react";
import { Props } from "./Props";

const ArchiveCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 49 48"
      fill="none"
    >
      <g clip-path="url(#clip0_1256_3213)">
        <path
          d="M42.875 10.5H6.125C5.27931 10.5 4.59375 11.1716 4.59375 12V16.5C4.59375 17.3284 5.27931 18 6.125 18H42.875C43.7207 18 44.4062 17.3284 44.4062 16.5V12C44.4062 11.1716 43.7207 10.5 42.875 10.5Z"
          stroke="#1D252B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.3438 18V36C41.3438 36.3978 41.1824 36.7794 40.8953 37.0607C40.6081 37.342 40.2186 37.5 39.8125 37.5H9.1875C8.78139 37.5 8.39191 37.342 8.10474 37.0607C7.81758 36.7794 7.65625 36.3978 7.65625 36V18"
          stroke="#1D252B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <rect
          x="10.9707"
          y="21.8182"
          width="26.3284"
          height="13.0909"
          fill={fill}
        />
        <path
          d="M19.9062 25.5H29.0938"
          stroke="#1D252B"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1256_3213">
          <rect width="49" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArchiveCardIcon;
