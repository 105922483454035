export const MonthlyStatementResponsiveListingStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  headingContainer: {
    display: "flex",
    minWidth: "113px",
    gap: "0px",
    flexDirection: "column",
  },
  heading: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  subHeading: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  headerContainer: {
    display: "flex",
    rowGap: "16px",
    columnGap: "40px",
    flexWrap: "wrap",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "10px",
    padding: "20px 16px 20px 16px",
    paddingBottom: "16px",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "var(--white)",
  },
  breadCrumbText: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    alignItems: "center",
    justifyContent: "center",
  },
  monthlyStatementContainer: {
    paddingTop: "10px",
  },
};
