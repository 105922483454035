import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { SxProps, Theme } from "@mui/system";
import styled from "@emotion/styled";

interface DatePickerProps {
  label: string;
  additionalClassName?: SxProps<Theme>;  
  handleDate?: (date: Date | null) => void;
  type?: "primary" | "secondary";
}

const StyledDemoContainer = styled("div")({
  "& .MuiTextField-root": {
    width: "360px",
    "& .MuiStack-root": {
      paddingTop: "0px !important",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      minWidth: "320px !important",
    },
  },
  "& .MuiInputLabel-root": {
    fontFamily: "var(--font-noto-sans)",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "var(--font-noto-sans)",
  },
});

const DatePickerComponent: React.FC<DatePickerProps> = ({
  label,
  handleDate,
  additionalClassName,
  type = "primary",
}) => {
  const handleDatePickerChange = (value: Dayjs | null) => {
    if (handleDate) {
      handleDate(value?.toDate() || null);
    }
  };

  const inputFormat = type === "secondary" ? "DD MMM YYYY" : "MM/DD/YYYY";

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDemoContainer>
        <DatePicker
          localeText={
            type === "secondary"
              ? {
                  fieldMonthPlaceholder: () => "MMM",
                }
              : undefined
          }
          sx={additionalClassName} 
          format={inputFormat}
          onChange={handleDatePickerChange}
          label={label}
        />
      </StyledDemoContainer>
    </LocalizationProvider>
  );
};

export default DatePickerComponent;
