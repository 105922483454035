import React from "react";
import { Props } from "./Props";

const InteractionCardEmailIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 26 20"
      fill="none"
    >
      <path
        d="M16.9097 11.5005L1.1582 1.37457H25.1604L16.9097 11.5005Z"
        fill={fill}
      />
      <path
        d="M1.1582 0.999527H25.1595V18.0004C25.1595 18.2657 25.0541 18.52 24.8666 18.7076C24.679 18.8951 24.4247 19.0005 24.1594 19.0005H2.15826C1.89303 19.0005 1.63866 18.8951 1.45111 18.7076C1.26357 18.52 1.1582 18.2657 1.1582 18.0004V0.999527Z"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.1595 0.999527L13.1588 12.0001L1.1582 0.999527"
        stroke="#1D252B"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default InteractionCardEmailIcon;
