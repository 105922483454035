import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import DnsIcon from "@mui/icons-material/Dns";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import CategoryIcon from "@mui/icons-material/Category";
import { useNavigate } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { adminPages } from "../../../constants/Constants";

interface IProps {
  drawerWidth: number;
  open: boolean;
  setPageName: Dispatch<SetStateAction<string>>;
  handleDrawerClose: () => void;
}

const Navbar: React.FC<IProps> = ({
  drawerWidth,
  open,
  setPageName,
  handleDrawerClose,
}) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  const getIcon = useCallback((pageName: string) => {
    switch (pageName) {
      case "dashboard":
        return <SpaceDashboardIcon />;
      case "forms":
        return <DynamicFormIcon />;

      case "lookup":
        return <DnsIcon />;

      case "uisettings":
        return <DisplaySettingsIcon />;
      case "features":
        return <CategoryIcon />;
    }
  }, []);

  const handleNavigate = useCallback(
    (pageName: string) => {
      setPageName(pageName);
      navigate(`/admin/${pageName.toLocaleLowerCase()}`);
    },
    [navigate, setPageName]
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {adminPages
          .filter((pageName: string) => pageName !== "login")
          .map((text) => (
            <Tooltip
              key={text}
              title={text.toUpperCase()}
              placement="right-end"
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={() => handleNavigate(`${text}`)}>
                  <ListItemIcon>{getIcon(text)}</ListItemIcon>
                  <ListItemText
                    primary={text.toUpperCase()}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
      </List>
    </Drawer>
  );
};

export default Navbar;
