export const customerDetailsInvoicesTableData = {
  invoicesTableHeaders: [
    "Invoice",
    "Policy",
    "Billing Date",
    "Amount",
    "Payment Method",
    "Paid On",
  ],
  invoicesData: [
    {
      invoice: "63453",
      policyDetails: {
        policyNumber: "12AW1145G057",
        policyTypeNumber: "Auto_Garage101",
      },
      billingDate: "01 Feb’24",
      amount: "$2818.00",
      paymentMethod: "",
      paidOn: "",
    },
    {
      invoice: "85747",
      policyDetails: {
        policyNumber: "2Aa114530g7f",
        policyTypeNumber: "House_02 Policy",
      },
      billingDate: "23 Dec’23",
      amount: "$2195.00",
      paymentMethod: "85956748",
      paidOn: "28 Dec’23",
    },
    {
      invoice: "34012",
      policyDetails: {
        policyNumber: "15WQ1245H013",
        policyTypeNumber: "Home_Protection",
      },
      billingDate: "23 Jun’23",
      amount: "$2195.00",
      paymentMethod: "98830370",
      paidOn: "20 Jun’23",
    },
    {
      invoice: "5128",
      policyDetails: {
        policyNumber: "15WQ1245H013",
        policyTypeNumber: "Home_Protection",
      },
      billingDate: "23 Jun’23",
      amount: "$2195.00",
      paymentMethod: "98830370",
      paidOn: "20 Jun’23",
    },
    {
      invoice: "28597",
      policyDetails: {
        policyNumber: "15WQ1245H013",
        policyTypeNumber: "Home_Protection",
      },
      billingDate: "23 Jun’23",
      amount: "$2195.00",
      paymentMethod: "Online Banking",
      paidOn: "20 Jun’23",
    },
    {
      invoice: "2314",
      policyDetails: {
        policyNumber: "15WQ1245H013",
        policyTypeNumber: "Home_Protection",
      },
      billingDate: "23 Jun’23",
      amount: "$2195.00",
      paymentMethod: "98830370",
      paidOn: "20 Jun’23",
    },
  ],
};
