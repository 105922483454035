const ProfileStyles = {
  profile: {
    padding: "30px",
    minHeight: "88vh",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  profileDetailsHeading: {
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    textAlign: "left",
    fontFamily: "Playfair Display",
  },
  profileDetailsContainer: {
    // display: "grid",
    // gridTemplateColumns: "1fr 2.5fr",
    // justifyContent: "center",
    // gap: "30px",
    "@media(max-width:768px)": {
      gridTemplateColumns: "1fr",
    },
  },
  container: {
    borderRadius: "13px",
    background: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(201, 225, 236, 1)",
    padding: "20px 20px ",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  userNameContainer: {
    border: "1px solid #cccccc",
    height: "100px",
    borderRadius: "50%",
    width: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userName: {
    fontFamily: "Noto Sans",
    fontWeight: "400",
    fontSize: "24px",
  },
  information: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    paddingBottom: "18px",
    paddingLeft: "20px",
    width: "auto",
    margin: "auto",
  },
  profileAgeTextStyle: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  profileGenderTextStyle: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  detailsInfo: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  profileCardTextStyle: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-around",
    "@media(max-width:1023px)": {
      flexDirection: "column-reverse",
      gap: "20px",
      alignItems: "center",
    },
  },
  profileButton: {
    border: "1px solid #3F3F3F",
    textTransform: "none",
    borderRadius: "40px",
    width: "150px",
    height: "40px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
    letterSpacing: "0.5px",
    textAlign: "left",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.2)",
  },
};

export default ProfileStyles;
