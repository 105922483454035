import React from "react";
import { Props } from "./Props";

const HouseIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 30"
      fill="none"
    >
      <rect
        x="14.5"
        y="10.7239"
        width="11.5222"
        height="11.5222"
        rx="2"
        fill={fill}
      />
      <path
        d="M3.77539 11.7366V28.5766H30.6873V11.7366"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.1067 13.5902L17.3141 1.4234L11.3516 6.01622"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.4128 3.76197V1.50607H7.32227V13.3505"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.16072 9.0809L1.35938 13.5124"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="11.3516"
        y="13.3436"
        width="11.5222"
        height="11.5222"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.1523 13.4263V24.7069"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5117 19.0687H22.7923"
        stroke="#3F3F3F"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HouseIcon;
