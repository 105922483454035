import { Box, Menu, MenuItem } from "@mui/material";
import { FilterPopUpStyles } from "./FilterPopUpStyles";

interface FilterPopUpProps {
  options: { key: string; value: string }[];
  selectedKey: string;
  onSelect: (key: string) => void;
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: () => void;
}

const FilterPopUp: React.FC<FilterPopUpProps> = ({
  options,
  selectedKey,
  onSelect,
  anchorEl,
  open,
  onClose,
}) => {
  const handleSelect = (key: string) => {
    onSelect(key);
  };

  return (
    <Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option) => (
          <MenuItem
            sx={FilterPopUpStyles.menuItem}
            key={option.key}
            onClick={() => handleSelect(option.key)}
          >
            {option.value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default FilterPopUp;
