import { Box } from "@mui/material";
import React from "react";
import Tables from "../../common/Tables/Tables";
import { CustomerListStyles } from "./CustomerListStyles";
import { CUSTOMER_LISTING } from "../../constants";

interface CustomerDetailsProps {
  image: string;
  customerName: string;
  customerNumber: string;
}
interface ContactDetailsProps {
  customerPhoneNumber: string;
  customerEmail: string;
}
interface CustomerPolicyStatusProps {
  active: number;
  pending: number;
  expired: number;
  tasks: number;
}
interface ClaimStatusProps {
  open: number;
  closed: number;
}
export interface CustomerListingProps {
  customerDetails: CustomerDetailsProps;
  contactDetails: ContactDetailsProps;
  customerPolicyStatus: CustomerPolicyStatusProps;
  claimStatus: ClaimStatusProps;
  customerLastInteractionDate: string;
}
interface CustomerListProps {
  customerListingData: CustomerListingProps[];
  headerData: string[];
}

const CustomerList: React.FC<CustomerListProps> = ({
  customerListingData,
  headerData,
}) => {
  return (
    <Box sx={CustomerListStyles.container}>
      <Tables
        type={CUSTOMER_LISTING}
        primary={false}
        data={customerListingData}
        additionaltableContainer={CustomerListStyles.tableContainer}
        headerData={headerData}
      />
    </Box>
  );
};

export default CustomerList;
