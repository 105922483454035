const PolicyDetailsStyles = {
  policyDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    minHeight: "92vh",
    padding: "30px",
  },
  link: {
    display: "flex",
    alignItems: "center",
    color: "#1D252B",
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: 400,
    cursor: "pointer",
    "@media (max-width: 599px)": {
      fontSize: "14px",
    },
    "@media (min-width: 600px) and (max-width: 1024px)": {
      fontSize: "16px",
    },
    "@media (min-width: 1024px) and (max-width: 1920px)": {
      fontSize: "18px",
    },
  },
};

export default PolicyDetailsStyles;
