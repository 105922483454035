export const DocumnetListingStyles = {
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "10px",
      alignItems: "flex-start",
    },
  },
  headingRightContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  line: {
    fontSize: "var(--font-size-24)",
    color: "var(--primary-grey)",
  },
  container: {
    padding: "25px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "0 auto",
    marginTop: "68px",
    maxWidth: "1920px",
    "@media (max-width: 1023px)": {
      padding: "24px 20px",
      marginTop: "60px",
      gap: "24px",
    },
  },
  tabsText: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-20)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
  },
  selectText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
  },
  menuItem: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
  },
  dropDownContainer: {
    marginLeft: "0px",
  },
  imageAndTextContainer: {
    display: "flex",
    gap: "12px",
  },
  activeContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid var(--primary-grey)",
    paddingBottom: "4px",
    marginTop: "-5px",
  },
  imageContainer: {
    gap: "4px",
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  addNewButton: {
    padding: "9px 32px",
  },
  imageAndTextFilterContainer: {
    gap: "4px",
  },
};
