const ContactComponentStyles = {
    contactContainer: {
        padding: "30px 0px 30px 20px",
        width: "390px",
        "@media(max-width: 780px)": {
            width: "320px",
        },
    },
    contactInsuranceCompany: {
        marginTop: "20px",
        height: "32px",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        fontFamily: "Noto Sans",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    },
    contactContactNumber: {
        display: "flex",
        gap: "10px",
    },
    contactPhoneLogo: {
        marginTop: "15.84px",
        width: "24px",
        height: "24px",
    },
    contactMailLogo: {
        marginTop: "15.84px",
        width: "24px",
        height: "24px",
    },
    contactNumber: {
        width: "auto",
        height: "20px",
        marginTop: "19px",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        fontFamily: "Noto Sans",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    },
    contactBusinessAddress: {
        marginTop: "37px",
        height: "20px",
        fontSize: "18px",
        fontWeight: "300",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        fontFamily: "Noto Sans",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    },
    contactAddress: {
        marginTop: "5px",
        width: "auto",
        height: "20px",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        fontFamily: "Noto Sans",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    },
    contactAgent: {
        fontFamily: "Noto Sans",
        width: "auto",
        height: "20px",
        fontSize: "18px",
        fontWeight: "300",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    },
    contactAgentName: {
        marginTop: "11.15px",
        fontFamily: "Noto Sans",
        width: "auto",
        height: "20px",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        textAlign: "left",
        "@media(max-width: 780px)": {
            fontSize: "14px",
            lineHeight: "110%",
        },
    }
}

export default ContactComponentStyles