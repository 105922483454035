export const PolicyListingStyles = {
  tabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1023px)": {
      flexDirection: "column",
      gap: "10px",
      alignItems: "flex-start",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 30px",
    gap: "20px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
    "@media (max-width:1023px)": {
      marginTop: "60px",
      padding: "24px 20px",
      gap: "23px",
    },
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    "@media (max-width:1023px)": {
      gap: "26px",
    },
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    gap: "40px",
    border: "1px solid var(--shaded-cyan)",
    padding: "16px 16px 10px 18px",
    minHeight: "107px",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
    "@media (max-width:1023px)": {
      rowGap: "20px",
      minHeight: "104px",
      columnGap: "14px",
      flexWrap: "wrap",
      position: "relative",
    },
  },
  headingContainerText: {
    "@media (max-width:1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "22px",
    },
  },
  subHeadingContainerText: {
    "@media (max-width:1023px)": {
      fontSize: "var(--font-size-12)",
      fontWeight: "300",
    },
  },

  footerImageAndText: {
    display: "flex",
    width: "100%",
  },
  viewMoreContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
  },
  viewLessContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
  },
  headingSubheadingContainer: {
    minWidth: "150px",
    gap: "2px",
    "@media (max-width:1023px)": {
      minWidth: "115px",
    },
  },
  addTaskButton: {
    boxShadow: "none",
    padding: "9px 22px",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  rightContainer: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "@media (max-width:1023px)": {
      gap: "0px",
    },
  },
  filterIcon: {
    "@media (max-width:1023px)": {
      display: "none",
    },
  },
  drawer:{
    width: "820px",
    padding: "unset",
  }
};
