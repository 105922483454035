import PrevIcon from "../../assets/svgs/left-arrow-calendar.svg";
import NextIcon from "../../assets/svgs/right-arrow-calendar.svg";

export const MyTasksCalendarStyles = {
  calendarContainer: {
    ".fc-direction-ltr": {
      height: "calc(100vh - 120px)",
    },
    ".fc-event": {
      "&:focus": {
        backgroundColor: "unset !important",
      },
    },
    ".fc-prev-button": {
      width: "24px",
      height: "26px",
      backgroundImage: `url(${PrevIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "var(--white)",
      border: "unset",
      boxShadow: "none !important",
      ":hover": {
        backgroundColor: "var(--white)",
      },
    },
    ".fc-next-button": {
      width: "24px",
      height: "26px",
      backgroundImage: `url(${NextIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundColor: "var(--white)",
      border: "unset",
      boxShadow: "none !important",
      ":hover": {
        backgroundColor: "var(--white)",
      },
    },
    ".fc-icon-chevron-right": {
      display: "none",
    },
    ".fc-icon-chevron-left": {
      display: "none",
    },
    ".fc-button-group": {
      display: "flex",
      gap: "18px",
      marginLeft: "50px !important",
    },
    ".fc-toolbar-chunk": {
      display: "flex",
      alignItems: "center",
    },
    ".fc-view-harness": {
      backgroundColor: "var(--white)",
      borderRadius: "10px",
      width: "100%",
      overflow: "scroll",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": { display: "none" },
    },
    ".fc-header-toolbar": {
      backgroundColor: "var(--white)",
      justifyContent: "unset",
      borderRadius: "10px 10px 0px 0px",
      boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.08)",
      marginBottom: "16px !important",
      padding: "8px 24px",
      borderBottom: "1.5px solid var(--support-white)",
    },
    ".fc-dayGridMonth-view .fc-day-today": {
      backgroundColor: "unset !important",
      border: "2px solid var(--black)",
    },
    ".fc-daygrid-day-frame": {
      display: "flex",
      flexDirection: "column-reverse",
      "@media (max-width: 1023px)": {
        maxHeight: "90px",
      },
    },
    ".fc-scrollgrid-section-header > *": {
      border: "0px",
    },
    ".fc-scrollgrid": {
      border: "1px solid var(--shaded-cyan)",
      borderRadius: "10px",
      tableLayout: "unset",
      "@media screen and (max-width: 1023px)": {
        tableLayout: "fixed",
      },
    },
    ".fc-scrollgrid-section-liquid > td": {
      border: "0px",
    },
    ".fc-col-header-cell-cushion": {
      padding: "18.5px 0px",
      fontFamily: "var(--font-noto-sans)",
      fontSize: "var(--font-size-14)",
      fontWeight: "var(--font-weight-400)",
      color: "var(--primary-black)",
      "@media (max-width: 1023px)": {
        maxWidth: "20px",
      },
    },
    ".fc-daygrid-day-number": {
      fontFamily: "var(--font-noto-sans)",
      fontSize: "var(--font-size-14)",
      fontWeight: "var(--font-weight-400)",
      color: "var(--primary-black)",
    },
    ".fc-dayGridMonth-view table tbody tr td": {
      height: "106px !important",
      "@media (max-width: 1023px)": {
        height: "34px !important",
      },
    },
    "table tbody tr:nth-of-type(6)": {
      display: "none",
    },
    ".fc-today-button": {
      backgroundColor: "var(--white) !important",
      color: "var(--primary-black) !important",
      textTransform: "capitalize ",
      opacity: "1 !important",
      padding: "4px 22px",
      borderRadius: "8px",
      boxShadow: "none !important",
      marginLeft: "50px",
    },
    ".fc-timeGridDay-button, .fc-timeGridWeek-button, .fc-dayGridMonth-button":
      {
        backgroundColor: "white",
        color: "var(--primary-black)",
        border: "none",
        borderBottom: "2px solid transparent",
        borderRadius: "0px",
        textTransform: "capitalize",
        transition: "border-bottom 0.3s",
        padding: "10px 20px",
        boxShadow: "none !important",
      },
    ".fc-timeGridDay-button.fc-button-active, .fc-timeGridWeek-button.fc-button-active, .fc-dayGridMonth-button.fc-button-active":
      {
        borderBottom: "2px solid var(--primary-black)",
      },
    ".fc-button-group > .fc-button": {
      backgroundColor: "transparent !important",
      color: "var(--primary-black) !important",
      fontFamily: "var(--font-noto-sans)",
      fontSize: "var(--font-size-18)",
    },
    ".fc-timeGridWeek-view .fc-scrollgrid-sync-table, .fc-timegrid-slot-minor,.fc-timegrid-divider":
      {
        display: "none",
      },
    ".fc-timegrid-slot": {
      height: "60px",
    },
    ".fc-daygrid-body-natural": {
      display: "none",
    },
    ".fc-timegrid-axis-frame": {
      justifyContent: "center",
    },
    ".fc-timegrid-axis": {
      verticalAlign: "middle",
      minWidth: "unset !important",
    },
    ".fc-timegrid-now-indicator-line": {
      border: "1px solid var(--support-white)",
    },
    ".fc-day-today a": {
      fontWeight: "bold",
    },
    ".fc-v-event": {
      backgroundColor: "transparent",
      border: "0px",
    },
    ".fc-day-today": {
      backgroundColor: "white !important",
    },
    ".fc-daygrid-day-events": {
      flex: 1,
    },
    ".fc-daygrid-dot-event:hover": {
      backgroundColor: "transparent",
    },
    ".fc .fc-timegrid-slot-label": {
      border: "0px",
    },
    ".fc .fc-timegrid-slot-label-cushion ": {
      fontSize: "var(--font-size-14)",
      fontFamily: "var(--font-noto-sans)",
      fontWeight: "var(--font-weight-400)",
      color: "var(--primary-black)",
      textAlign: "center",
    },

    ".fc-now-indicator-line::before": {
      content: "''",
      position: "absolute",
      width: "10px",
      height: "10px",
      backgroundColor: "#00aaff",
      borderRadius: "50%",
      top: " -4px",
      left: "-5px",
      zIndex: "1",
    },
    ".fc-timegrid-now-indicator-arrow": {
      display: "none",
    },
    ".fc-timegrid-now-indicator-container": {
      position: "unset",
    },
    ".fc-timegrid-now-indicator-line::before": {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      transform: "translate(-50%, -50%)",
      width: " 8px",
      height: "8px",
      backgroundColor: "var(--support-white)",
      borderRadius: "50%",
    },
    ".fc-theme-standard th": {
      width: "231px",
      "@media (max-width: 1023px)": {
        width: "unset",
      },
    },
    ".fc-h-event ": {
      backgroundColor: "transparent",
      background: "transparent",
    },
    ".fc-scroller": {
      "&::-webkit-scrollbar ": {
        background: "transparent",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "rgba(255, 255, 255, 0.5)",
        borderRadius: "4px",
      },

      "&::-webkit-scrollbar-thumb:hover": {
        background: "rgba(255, 255, 255, 0.8)",
      },
      scrollbarWidth: "none",
      scrollbarColor: "transparent transparent",
    },
    " .fc table ":{
      "@media screen and (max-width: 1023px)":{
        borderCollapse: "separate !important",
      }
    },

  },

  lowPriorityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media (max-width: 1023px)": {
      background: "#E8F6EE",
      border: "0.5px solid #20AA5E",
      borderRadius: "8px",
    },
  },
  lowPriorityModalContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  mediumPriorityModalContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  highPriorityModalContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  highPriorityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media (max-width: 1023px)": {
      borderRadius: "8px",
      background: "#FEF0F0",
      border: "0.5px solid #FE6C6C",
    },
  },
  mediumPriorityContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media (max-width: 1023px)": {
      borderRadius: "8px",
      background: "#FCF4E6",
      border: "0.5px solid #E69806",
    },
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  timeRange: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
    },
  },
  withWhom: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100px",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      padding: "10px 5px",
      maxWidth: "70px",
    },
    "@media (max-width: 599px)": {
      maxWidth: "45px",
    },
  },

  nameContainer: {
    display: "flex",
    gap: "3px",
    alignItems: "center",
  },
  nameWeekContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  lowPriority: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "#20AA5E",
  },
  highPriority: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "#FE6C6C",
  },
  container: {
    display: "flex",
    gap: "24px",
  },
  remainingCount: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
  },
  modalContainer: {
    width: "520px",
    height: "316px",
    padding: "16px",
    "@media (max-width: 1023px)": {
      width: "320px",
    },
  },
  modalMeetingsContainer: {
    width: "520px",
    height: "316px",
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
    padding: "20px",
    "@media (max-width: 1023px)": {
      width: "320px",
    },
  },
  lowDot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: " #20AA5E",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  lowModalDot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: " #20AA5E",
  },
  highModalDot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "#FE6C6C",
  },
  mediumModalDot: {
    background: "#E69806",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
  },
  highDot: {
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    background: "#FE6C6C",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  mediumDot: {
    background: "#E69806",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  innerTextContainer: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  formattedTime: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  meetingTitle: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
    },
  },
  innerWeekTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  lowPriorityWeekContainer: {
    background: "#E8F6EE",
    border: "0.5px solid #20AA5E",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "6px 15px 11px 10px",
    "@media (max-width: 1023px)": {
      paddingLeft: "0px",
    },
  },
  mediumPriorityWeekContainer: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    padding: "6px 15px 11px 10px",
    background: "#FCF4E6",
    border: "0.5px solid #E69806",
    "@media (max-width: 1023px)": {
      paddingLeft: "0px",
    },
  },
  highPriorityWeekContainer: {
    borderRadius: "8px",
    background: "#FEF0F0",
    border: "0.5px solid #FE6C6C",
    display: "flex",
    flexDirection: "column",
    padding: "6px 15px 11px 10px",
    "@media (max-width: 1023px)": {
      paddingLeft: "0px",
    },
  },
  totalContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  totalMeetingsMonthContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    flex: 1,
  },
  remaining: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
  },
  formattedModalTime: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
  },
  totalMeetingsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    paddingTop: "10px",
  },
  timeContainer: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  mobileDropdown: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginLeft: "12px",
  },
  selectContainer: {
    marginRight: "12px",
  },
  dropDownIcon: {
    width: "10px",
    height: "10px",
    transition: "transform 0.3s ease",
  },
  rotated: {
    transform: "rotate(180deg)",
  },
  toolbarHeader: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-18)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    minWidth: "160px",
    display: "flex",
    cursor: "pointer",
  },
  mobileHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    borderBottom: "1px solid var(--primary-black)",
    backgroundColor: "var(--white)",
    boxShadow: "0px 2px 14px 0px #0000000F",
    borderRadius: "6px 6px 0px 0px",
    marginBottom: "20px",
  },
  modalHeadingContainer: {
    margin: "0px -15px",
    "@media (max-width: 1023px)": {
      padding: "0px 15px 10px 15px",
      margin: "0px -15px",
    },
  },
  monthTitle: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
