export const commissionMonthlyStatementTableHeadingsData = [
  "Transaction Date",
  "Transaction Type",
  "Policy Details",
  "Customer Details",
  "Commission Earned",
];
export const comissionMonthlyStatementsData = [
  {
    commissionDate: "05 Aug‘24",
    commissionTransactionType: "New Business",
    commissionPolicyDetails: "#PAZ0000032",
    commissionCustomerDetails: "Michael Marah",
    comissionMonthlyEarned: "$16,656",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionTransactionType: "New Business",
    commissionPolicyDetails: "#PAZ0000032",
    commissionCustomerDetails: "Michael Marah ",
    comissionMonthlyEarned: "$16,656",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionTransactionType: "Renewal",
    commissionPolicyDetails: "#PAZ0000032",
    commissionCustomerDetails: "Michael Marah",
    comissionMonthlyEarned: "$16,656",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionTransactionType: "Endorsement",
    commissionPolicyDetails: "#PAZ0000032",
    commissionCustomerDetails: "Michael Marah",
    comissionMonthlyEarned: "$16,656",
  },
  {
    commissionDate: "05 Aug‘24",
    commissionTransactionType: "Cancellation",
    commissionPolicyDetails: "#PAZ0000032",
    commissionCustomerDetails: "Michael Marah",
    comissionMonthlyEarned: "$16,656",
  },
];
