export const ConfigureDashboardResponsiveStyles = {
  container: {
    padding: "24px 20px",
    marginTop: "60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
    minHeight: "calc(100vh - 165px)",
  },
  checkBoxContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  accordianContainer: {
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
  },
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  resetButton: {
    display: "flex",
    gap: "30px",
    justifyContent: "space-between",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    '@media screen and (min-width:599px) and (max-width: 1023px)': {
      maxWidth: "599px",
      margin: "0 auto",
    },
  },
  button: {
    boxShadow: "none",
    width: "100%",
  },
  breadCrumbText: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  cancelButton: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
};
