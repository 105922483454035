import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import {
  API_GET_ADD_VEHICLE_FORM_SCHEMA,
  API_POST_INSURED_ADDRESS_DETAILS,
  API_POST_OPTED_AND_UNOPTED_COVERAGES,
  API_POST_VEHICLE_DETAILS,
} from "../../../Apis/ApiEndPoints";
import {
  IGetAddVehicleFormFieldsResponse,
  IGetInsuredAddressDetailsResponse,
  IGetInsuredAddressDetailsReuqestParams,
  IGetOptedAndUnoptedCoveragesRequestParams,
  IGetOptedAndUnoptedCoveragesResponse,
  IGetVehcileDetailsRequestParams,
  IGetVehicleDetailsResponse,
} from "../../../models/user/Vehicle/Vehicle";
import { Api } from "../../..";

export const GetAddVehicleFormFields = createAsyncThunk(
  "vehicle/GetAddVehicleFormFields",
  async (_, { dispatch }) => {
    const response: IGetAddVehicleFormFieldsResponse = await CustomAction(
      Api.get(API_GET_ADD_VEHICLE_FORM_SCHEMA),
      dispatch,
      "vehicle/GetAddVehicleFormFields"
    );
    return response;
  }
);

export const GetVehicleDetails = createAsyncThunk(
  "vehicle/GetVehicleDetails",
  async (requestParams: IGetVehcileDetailsRequestParams, { dispatch }) => {
    const response: IGetVehicleDetailsResponse = await CustomAction(
      Api.post(API_POST_VEHICLE_DETAILS, requestParams),
      dispatch,
      "vehicle/GetVehicleDetails"
    );
    return response;
  }
);

export const GetInsuredAddressDetails = createAsyncThunk(
  "vehicle/GetInsuredAddressDetails",
  async (
    { policyId }: IGetInsuredAddressDetailsReuqestParams,
    { dispatch }
  ) => {
    const response: IGetInsuredAddressDetailsResponse = await CustomAction(
      Api.post(API_POST_INSURED_ADDRESS_DETAILS, { policyId }),
      dispatch,
      "vehicle/GetInsuredAddressDetails"
    );
    return response;
  }
);
export const GetOptedAndUnoptedCoverages = createAsyncThunk(
  "policyDetails/GetOptedAndUnoptedCoverages",
  async (
    requestParams: IGetOptedAndUnoptedCoveragesRequestParams,
    { dispatch }
  ) => {
    const response: IGetOptedAndUnoptedCoveragesResponse = await CustomAction(
      Api.post(API_POST_OPTED_AND_UNOPTED_COVERAGES, requestParams),
      dispatch,
      "policyDetails/GetOptedAndUnoptedCoverages"
    );
    return response;
  }
);
