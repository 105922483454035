import React from "react";
import HeadingText from "../../common/HeadingText/HeadingText";
import DropDown from "../../common/DropDown/DropDown";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import gearIcon from "../../assets/svgs/gear-icon.svg";
import ModalComponent from "../../common/ModalComponent/ModalComponent";
import CustomButton from "../../common/CustomButton/CustomButton";
import { AgencyDashboardStyles } from "./AgencyDashboardStyles";
import { dashboardJson } from "../../constants/dashboardData";
import {
  checkBoxData,
  radioButtons,
} from "../../constants/configureDashboardData";
import Switches from "../../common/Switches/Switches";
import { locations, options } from "../../constants/DropDown";
import StackeBarChart from "../StackedBarChart/StackedBarChart";
import PieChartComponent from "../PieChart/PieChart";
import PerformingPolicies from "../../common/PerformingPolicies/PerformingPolicies";
import LatestNews from "../../common/LatestNews/LatestNews";
import BarChartComponent from "../../common/BarChartComponent/BarChartComponent";
import LineChartComponent from "../LineChart/LineChart";
import Stats from "../../common/Stats/Stats";
import {
  AGENCY_DASHBOARD_SUBHEADING,
  AGENT_DASHBOARD_SUBHEADING,
  ALL_LOCATIONS,
  CANCEL,
  CONFIGURE_DASHBOARD,
  FOR,
  LOGO,
  MIKE,
  RESET,
  SAVE_CHANGES,
  SWITCH_TO_AGENCY_DASHBOARD,
  SWITCH_TO_AGENT_DASHBOARD,
  THIS_MONTH,
  WELCOME,
} from "../../constants";
import ConfigureDashboard from "../ConfigureDashboard/ConfigureDashboard";
import CelebrationCard from "../../common/CelebrationCard/CelebrationCard";
import { useNavigate } from "react-router-dom";
import DashboardContainer from "../DashboardContainer/DashboardContainer";
import searchIcon from "../../assets/svgs/search-icon.svg";
const AgencyDashboard: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:1023px)");
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [selectedAge, setSelectedAge] = React.useState<string>("");
  const [selectedAgentValue, setSelectedAgentValue] =
    React.useState<string>("");
  const [selectedAnotherAge, setSelectedAnotherAge] =
    React.useState<string>("");
  const handleClick = () => {
    isMobile ? navigate("/config") : setOpen(!open);
  };
  const handleSelect = (event: SelectChangeEvent) => {
    setSelectedAge(event.target.value);
  };
  const handleAgentSelect = (event: SelectChangeEvent) => {
    setSelectedAgentValue(event.target.value);
  };
  const handleAnotherSelect = (event: SelectChangeEvent) => {
    setSelectedAnotherAge(event.target.value);
  };
  const initialCards = [
    {
      id: "card-1",
      heading: "Existing Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgencyDashboardStyles.existingBusinessContainer}
          statsData={dashboardJson.existingBusinessData}
        />
      ),
      showFooter: true,
    },
    {
      id: "card-2",
      heading: "Location Comparison",
      dropDownData: [
        { name: "Revenue Earned", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 10, 20, 30, 40]}
          barChartData={dashboardJson.locationComparisonData}
          primary={true}
        />
      ),
    },
    {
      id: "card-3",
      heading: "Premium Written ",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 5000, 10000, 15000, 20000]}
          barChartData={dashboardJson.premiumWrittenData}
        />
      ),
    },
    {
      id: "card-4",
      heading: "Policies Sold",

      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 10, 20, 30, 40]}
          barChartData={dashboardJson.policiesSoldData}
          primary={true}
        />
      ),
    },
    {
      id: "card-5",
      heading: "Latest News from Insurer",
      dropDownData: [],
      viewTask: false,
      component: <LatestNews newsData={dashboardJson.latestNewsData} />,
    },
    {
      id: "card-6",
      heading: "Claim Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <StackeBarChart
          stackedBarChartData={dashboardJson.stackedBarChartData}
        />
      ),
    },
    {
      id: "card-7",
      heading: "New Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgencyDashboardStyles.newBusinessContainer}
          statsData={dashboardJson.newBusinessData}
        />
      ),
    },
    {
      id: "card-8",
      heading: "Commission Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <LineChartComponent
          comissionTrackingData={dashboardJson.comissionTrackingData}
        />
      ),
    },
    {
      id: "card-9",
      heading: "Commissions By LOB Companies",
      dropDownData: [],
      viewTask: false,
      component: (
        <BarChartComponent
          barChartData={dashboardJson.lobCompaniesData}
          yAxisData={[0, 5000, 10000, 15000, 20000]}
        />
      ),
    },
    // {
    //   id: "card-10",
    //   heading: "Commissions By UW Companies",
    //   dropDownData: [],
    //   viewTask: false,
    //   component: (
    //     <BarChartComponent
    //       barChartData={dashboardJson.lobCompaniesData}
    //       yAxisData={[0, 5000, 10000, 15000, 20000]}
    //     />
    //   ),
    // },
    {
      id: "card-11",
      heading: "Top Performing Policies",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: <PerformingPolicies policies={dashboardJson.policiesData} />,
    },
    {
      id: "card-12",
      heading: "Top Performing Agents",
      dropDownData: [
        { name: "All policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      placeholder: "Commission Earned",
      component: (
        <PerformingPolicies policies={dashboardJson.topPerformingAgentsData} />
      ),
    },
  ];
  const agentCards = [
    {
      id: "card-1",
      heading: "Existing Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgencyDashboardStyles.existingBusinessContainer}
          statsData={dashboardJson.existingBusinessData}
        />
      ),
      showFooter: true,
    },
    {
      id: "card-2",
      heading: "Customers Celebrations",
      viewTask: false,
      dropDownData: [],
      component: <CelebrationCard />,
      showFooter: false,
    },
    {
      id: "card-3",
      heading: "Task That Need Attention",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: true,
      component: (
        <>
          <PieChartComponent />
        </>
      ),
    },
    {
      id: "card-4",
      heading: "Premium Written ",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 5000, 10000, 15000, 20000]}
          barChartData={dashboardJson.premiumWrittenData}
        />
      ),
    },
    {
      id: "card-5",
      heading: "Policies Sold",

      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <BarChartComponent
          yAxisData={[0, 10, 20, 30, 40]}
          barChartData={dashboardJson.policiesSoldData}
          primary={true}
        />
      ),
    },
    {
      id: "card-6",
      heading: "Customer Stats",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgencyDashboardStyles.customerStatsContainer}
          statsData={dashboardJson.customerData}
        />
      ),
    },
    {
      id: "card-7",
      heading: "Claim Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <StackeBarChart
          stackedBarChartData={dashboardJson.stackedBarChartData}
        />
      ),
    },
    {
      id: "card-8",
      heading: "New Business",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <Stats
          additionalClass={AgencyDashboardStyles.newBusinessContainer}
          statsData={dashboardJson.newBusinessData}
        />
      ),
    },
    {
      id: "card-9",
      heading: "Commission Tracking",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: (
        <LineChartComponent
          comissionTrackingData={dashboardJson.comissionTrackingData}
        />
      ),
    },
    {
      id: "card-10",
      heading: "Commissions By LOB Companies",
      dropDownData: [],
      viewTask: false,
      component: (
        <BarChartComponent
          barChartData={dashboardJson.lobCompaniesData}
          yAxisData={[0, 5000, 10000, 15000, 20000]}
        />
      ),
    },
    // {
    //   id: "card-11",
    //   heading: "Commissions By UW Companies",
    //   dropDownData: [],
    //   viewTask: false,
    //   component: (
    //     <BarChartComponent
    //       barChartData={dashboardJson.lobCompaniesData}
    //       yAxisData={[0, 5000, 10000, 15000, 20000]}
    //     />
    //   ),
    // },
    {
      id: "card-12",
      heading: "Top Performing Policies",
      dropDownData: [
        { name: "All Policies", value: 1 },
        { name: "Policy 1", value: 2 },
        { name: "Policy 3", value: 3 },
      ],
      viewTask: false,
      component: <PerformingPolicies policies={dashboardJson.policiesData} />,
    },
    {
      id: "card-13",
      heading: "Latest News from Insurer",
      dropDownData: [],
      viewTask: false,
      component: <LatestNews newsData={dashboardJson.latestNewsData} />,
    },
  ];
  const [agencyData, setAgencyData] = React.useState(initialCards);
  const [checked, setChecked] = React.useState(false);
  const handleSwitchChange = () => {
    setChecked(!checked);
    if (checked) {
      setAgencyData(initialCards);
    } else {
      setAgencyData(agentCards);
    }
  };
  return (
    <Box sx={AgencyDashboardStyles.container}>
      <Box sx={AgencyDashboardStyles.dashboardContainer}>
        <Box sx={AgencyDashboardStyles.headingContainer}>
          <HeadingText
            additionalHeadingClass={AgencyDashboardStyles.headingText}
            heading={WELCOME + " " + MIKE}
          />
          <Box sx={AgencyDashboardStyles.conversationalContainer}>
            <Box sx={AgencyDashboardStyles.conversationalText}>
              <Typography sx={AgencyDashboardStyles.overViewText}>
                {checked
                  ? AGENT_DASHBOARD_SUBHEADING
                  : AGENCY_DASHBOARD_SUBHEADING}
              </Typography>
              <Box sx={AgencyDashboardStyles.dropDownWithConfigure}>
                <Box sx={AgencyDashboardStyles.optionsContainer}>
                  {checked ? (
                    <DropDown
                      data={options}
                      placeholder={THIS_MONTH}
                      selectedValue={selectedAgentValue}
                      onChange={handleAgentSelect}
                      formControlAdditionalClassName={
                        AgencyDashboardStyles.dropDownClass
                      }
                    />
                  ) : (
                    <>
                      <DropDown
                        data={locations}
                        placeholder={ALL_LOCATIONS}
                        selectedValue={selectedAge}
                        onChange={handleSelect}
                        formControlAdditionalClassName={
                          AgencyDashboardStyles.dropDownClass
                        }
                      />
                      <Typography sx={AgencyDashboardStyles.overViewText}>
                        {FOR}
                      </Typography>
                      <DropDown
                        data={options}
                        placeholder={THIS_MONTH}
                        selectedValue={selectedAnotherAge}
                        onChange={handleAnotherSelect}
                      />
                    </>
                  )}
                </Box>
                <Box sx={AgencyDashboardStyles.gearIconMobile}>
                  <img src={gearIcon} onClick={handleClick} alt={LOGO} />
                </Box>
              </Box>
            </Box>
            <Box sx={AgencyDashboardStyles.switchContainer}>
              <Switches
                checked={checked}
                handleSwitchChange={handleSwitchChange}
                switchText={
                  checked
                    ? SWITCH_TO_AGENCY_DASHBOARD
                    : SWITCH_TO_AGENT_DASHBOARD
                }
                additionalClassNameText={AgencyDashboardStyles.switchText}
              />
              <Typography sx={AgencyDashboardStyles.line}>|</Typography>
              <ImageAndText
                image={gearIcon}
                onClick={handleClick}
                text={CONFIGURE_DASHBOARD}
                additionalClassText={AgencyDashboardStyles.configureText}
                additionalClassName={AgencyDashboardStyles.configureIcon}
              />
            </Box>
          </Box>
        </Box>
        <DashboardContainer
          additionalClassContainer={AgencyDashboardStyles.cardsContainer}
          data={agencyData}
        />
        <ModalComponent
          heading={CONFIGURE_DASHBOARD}
          open={open}
          handleOpen={handleOpen}
          additionalClassContainer={AgencyDashboardStyles.modal}
          searchImage={searchIcon}
        >
          <Box sx={AgencyDashboardStyles.modalContainer}>
            <ConfigureDashboard
              radioButtons={radioButtons}
              checkBoxData={checkBoxData}
            />
            <Box sx={AgencyDashboardStyles.buttonsContainer}>
              <CustomButton
                additionalClassName={AgencyDashboardStyles.button}
                onClick={handleOpen}
                type="outlined"
                text={RESET}
              />
              <CustomButton
                additionalClassName={AgencyDashboardStyles.button}
                onClick={handleOpen}
                type="outlined"
                text={CANCEL}
              />
              <CustomButton
                additionalClassName={AgencyDashboardStyles.button}
                onClick={handleOpen}
                type="filled"
                text={SAVE_CHANGES}
              />
            </Box>
          </Box>
        </ModalComponent>
      </Box>
    </Box>
  );
};

export default AgencyDashboard;
