import { Box } from "@mui/material";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import { claimTrackingHeaderData } from "../../constants/ClaimTrackingHeaderData";
import { ClaimTrackingStyles } from "./ClaimTrackingStyles";
import HeadingText from "../../common/HeadingText/HeadingText";
import { claimTrackingData } from "../../constants/ClaimTrackingData";
import ClaimTrackingCard from "../../common/ClaimTrakingCard/ClaimTrackingCard";
import BasicTimeline from "../../common/BasicTimeLine/BasicTimeLine";
import { OVERVIEW } from "../../constants";

const ClaimTracking = () => {
  const mappedTimelineData = claimTrackingData.map((item, index) => ({
    left: item.date,
    right: (
      <Box sx={ClaimTrackingStyles.timeLineRightContent}>
        <ClaimTrackingCard title={item.heading} subTitle={item.subHeading} />
      </Box>
    ),
  }));
  return (
    <Box sx={ClaimTrackingStyles.container}>
      <Box sx={ClaimTrackingStyles.headerContainer}>
        {claimTrackingHeaderData.map((data, index) => (
          <HeadingSubHeading
            containerAdditionalClassName={ClaimTrackingStyles.headingContainer}
            subHeadingAdditionalClassName={ClaimTrackingStyles.subheading}
            headingAdditionalClassName={ClaimTrackingStyles.heading}
            heading={data.heading}
            subheading={data.subHeading}
          />
        ))}
      </Box>
      <Box sx={ClaimTrackingStyles.timeLineContainer}>
        <HeadingText additionalHeadingClass={ClaimTrackingStyles.headingText} heading={OVERVIEW} />
        <BasicTimeline data={mappedTimelineData} type="secondary" dataPosition={0} />
      </Box>
    </Box>
  );
};

export default ClaimTracking;
