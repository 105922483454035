export const TimeLineStyles = {
  oppositeContent: {
    padding: "0px 24px",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
  },
  dot: {
    padding: "0px",
    margin: "0px",
    width: "7px",
    height: "7px",
    backgroundColor: "var( --primary-black)",
  },
  saperator: {
    backgroundColor: "var( --primary-black)",
    width: "0.5px",
    height: "102px",
  },
  lastSaperator: {
    backgroundColor: "var( --primary-black)",
    width: "0.5px",
    height: "20px",
    flexGrow: "0",
  },
  leftContent: {
    padding: "0px 24px",
    "&:nth-child(1)": {
      marginTop: "0px !important",
    },
  },
  firstSeparator: {
    backgroundColor: "var(--primary-black)",
    width: "0.5px",
    height: "27px",
    flexGrow: "0",
  },
};
