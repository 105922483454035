import React from "react";
import { Props } from "./Props";

const HomeClaimIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 41"
      fill="none"
    >
      <rect y="25.2812" width="24.7793" height="11.5557" rx="2" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7494 0.366577L37.8112 14.4147V36.1237C37.8112 37.2914 37.3474 38.4112 36.5217 39.2369C35.6961 40.0625 34.5762 40.5264 33.4086 40.5264H6.09014C4.92248 40.5264 3.80266 40.0625 2.977 39.2369C2.15135 38.4112 1.6875 37.2914 1.6875 36.1237V14.4147L19.7494 0.366577ZM2.6875 14.9038V36.1237C2.6875 37.0262 3.04599 37.8916 3.68411 38.5298C4.32223 39.1679 5.1877 39.5264 6.09014 39.5264H33.4086C34.311 39.5264 35.1765 39.1679 35.8146 38.5298C36.4527 37.8916 36.8112 37.0262 36.8112 36.1237V14.9038L19.7494 1.63344L2.6875 14.9038Z"
        fill="#2B225D"
      />
    </svg>
  );
};

export default HomeClaimIcon;
