import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import car from "../../assets/car.svg";
import tickIcon from "../../assets/tick.svg";
import { BasicInfoCardStyles } from "./BasicInfoCardStyles";
import {
  billingHead,
  dueOn,
  vehicles,
  policyDetailsHead,
  premiumHead,
  status,
} from "../../constants/Constants";
import basicInfoData from "../../mockJson/CustomerDashboard/BasicInfoCardData.json";
import home from "../../assets/home.svg";

interface CarLoanProps {
  carLoan: boolean;
  clientName: string;
  numberOfVehicles: string;
  premiumPrice: string;
  dueDate: string;
  annualPrice: string;
  term: string;
  validity: string;
}

const BasicInfoCard: React.FC = () => {
  const [basicData, setBasicData] = useState<CarLoanProps[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setBasicData(basicInfoData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {basicData.map((data, index) => (
        <Box
          key={index}
          sx={BasicInfoCardStyles.container}
          aria-label="Basic card info details container"
        >
          <Box sx={BasicInfoCardStyles.logoContainer}>
            <img src={data.carLoan ? car : home} alt="car-logo" />
            <Box sx={BasicInfoCardStyles.imageBox}>
              <img src={tickIcon} alt="tick-logo" />
              <Typography sx={BasicInfoCardStyles.statusText}>
                {status}
              </Typography>
            </Box>
          </Box>
          <Box sx={BasicInfoCardStyles.container1}>
            <Box
              sx={BasicInfoCardStyles.box}
              aria-label="Basic card info details"
            >
              <Box
                sx={BasicInfoCardStyles.container2}
                aria-label="Basic details"
              >
                <Typography sx={BasicInfoCardStyles.head}>
                  {policyDetailsHead}
                </Typography>
                <Box sx={BasicInfoCardStyles.container3}>
                  <Typography sx={BasicInfoCardStyles.content}>
                    {data.clientName}
                  </Typography>
                  <Typography sx={BasicInfoCardStyles.content}>
                    &bull;{`  ${data.numberOfVehicles} ${vehicles}`}
                  </Typography>
                </Box>
              </Box>
              <Box sx={BasicInfoCardStyles.container2}>
                <Typography sx={BasicInfoCardStyles.head}>
                  {billingHead}
                </Typography>
                <Box sx={BasicInfoCardStyles.container3}>
                  <Typography sx={BasicInfoCardStyles.content}>
                    {`$ ${data.premiumPrice} `}
                  </Typography>
                  <Typography sx={BasicInfoCardStyles.content}>
                    &bull;{`  ${dueOn} ${data.dueDate}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={BasicInfoCardStyles.container4}>
              <Typography sx={BasicInfoCardStyles.head}>
                {premiumHead}
              </Typography>
              <Box sx={BasicInfoCardStyles.container5}>
                <Typography sx={BasicInfoCardStyles.content}>
                  {`$ ${data.annualPrice} / ${data.term}`}
                </Typography>
                <Typography
                  sx={{
                    ...BasicInfoCardStyles.content,
                    ...BasicInfoCardStyles.container6,
                  }}
                >
                  &bull;
                </Typography>
                <Typography sx={BasicInfoCardStyles.content}>
                  {data.validity}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default BasicInfoCard;
