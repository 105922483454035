import React from "react";
import Chat from "../../common/Chat/Chat";
import { NotesStyles } from "./NotesStyles";
import { Box, InputAdornment, styled, TextField, Typography } from "@mui/material";
import CustomButton from "../../common/CustomButton/CustomButton";
import {
  CLIENT_NAME,
  CONTACT,
  DUE_DATE,
  PERSON_LOGO,
  POLICY,
  SAVE,
  ADD_NOTES,
} from "../../constants";

interface PersonDataProps {
  personLogo: string;
  clientName: string;
  mobileNumber: string;
  policyNumber: string;
  dueDate: string;
}

interface UserDetailsProps {
  personLogo: string;
  clientName: string;
  date: string;
  time: string;
  userMessage: boolean;
}

interface PersonChatProps {
  message: string;
  userDetails: UserDetailsProps;
}

interface PersonNotesProps {
  personDataArray: PersonDataProps;
  personChatArray: PersonChatProps[];
}

const StyledTextField =styled(TextField)({
  width: "100%",
  cursor: "text",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      border: "1px solid var(--primary-grey)",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid var(--primary-grey)",
    },
  },
})

const Notes: React.FC<PersonNotesProps> = ({
  personDataArray,
  personChatArray,
}) => {
  return (
    <Box sx={NotesStyles.notesContainer}>
      <Box sx={NotesStyles.innerContainer}>
        <Box sx={NotesStyles.clientDetailsContainer}>
          <img src={personDataArray.personLogo} alt={PERSON_LOGO} />
          <Box sx={NotesStyles.clientContainer}>
            <Typography sx={NotesStyles.clientName}>
              {CLIENT_NAME}: {personDataArray.clientName}
            </Typography>
            <Box sx={NotesStyles.clientDetails}>
              <Typography sx={NotesStyles.personDetails}>
                {CONTACT} : {personDataArray.mobileNumber}
              </Typography>
              <Typography sx={NotesStyles.personDetails}>
                {POLICY}: {personDataArray.policyNumber}
              </Typography>
              <Typography sx={NotesStyles.personDetails}>
                {DUE_DATE}: {personDataArray.dueDate}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={NotesStyles.chatContainer}>
          {personChatArray.map((personChat, index) => (
            <Chat personChat={personChat} />
          ))}
        </Box>
      </Box>
      <Box>
        <StyledTextField
          placeholder={ADD_NOTES}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Box sx={NotesStyles.customButton}>
                  <CustomButton text={SAVE} />
                </Box>
              </InputAdornment>
            ),
          }}
          autoFocus
        />
      </Box>
    </Box>
  );
};

export default Notes;
