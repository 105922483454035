import { Box, Typography } from "@mui/material";
import AgentFooterStyles from "./AgentFooterStyles";
import { PRIVACY_POLICY, RESERVED_RIGHTS, TERMS_AND_SERVICES } from "../../constants";

const AgencyFooter = () => {
  return (
    <Box sx={AgentFooterStyles.footerContainer}>
      <Typography sx={AgentFooterStyles.copyRights}>
       {RESERVED_RIGHTS}
      </Typography>
      <Box sx={AgentFooterStyles.footerItems}>
        <Typography sx={AgentFooterStyles.rightText}>
          {TERMS_AND_SERVICES}
        </Typography>
        <Typography sx={AgentFooterStyles.rightText}>
        {PRIVACY_POLICY}
        </Typography>
      </Box>
    </Box>
  );
};

export default AgencyFooter;
