export const DocumentManagementStyles = {
  container: {
    padding: "25px 30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginTop: "68px",
    minHeight: "calc(100vh - 120px)",
    "@media screen and (max-width: 1023px)": {
      padding: "24px 20px",
      marginTop: "60px",
      gap: "18px",
    },
  },
  breadCrumb: {
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  innerContainer: {
    display: "flex",
    gap: "27px",
  },
  breadCrumbContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  detailsContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      gap: "1px",
    },
  },
  breadCrumbText: {
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
};
