import { memo, useCallback, useState } from "react";
import { Box, Link, Paper, Typography } from "@mui/material";
import FooterStyles from "./FooterStyles";
import CustomDialog from "../../common/CustomDialog/CustomDialog";

const Footer = memo(() => {
  const currentEnv = process.env.REACT_APP_ENV;
  const buildNo = process.env.REACT_APP_BUILD_NO;

  const [termsOfServiceDialogOpen, setTermsOfServicePolicyOpen] =
    useState(false);
  const [privacyPolicyDialogOpen, setPrivacyPolicyDialogOpen] = useState(false);

  const handleTermsOfServiceDialogClose = useCallback(
    () => setTermsOfServicePolicyOpen(false),
    []
  );

  const handlePrivacyPolicyDialogClose = useCallback(
    () => setPrivacyPolicyDialogOpen(false),
    []
  );

  return (
    <>
      <Box sx={FooterStyles.footerContainer}>
        <Typography sx={FooterStyles.copyRights}>
          © 2024 Smart IMS. All rights reserved.
        </Typography>
        <Box sx={FooterStyles.footerItems}>
          <Typography>
            {currentEnv || ""} {buildNo ? ` - ${buildNo}` : ""}
          </Typography>
          <Link
            sx={FooterStyles.rightText}
            onClick={() => setTermsOfServicePolicyOpen(true)}
          >
            Terms of Service{" "}
          </Link>
          <Link
            sx={FooterStyles.rightText}
            onClick={() => setPrivacyPolicyDialogOpen(true)}
          >
            {" "}
            Privacy Policy
          </Link>
        </Box>
      </Box>
      <CustomDialog
        maxWidth="xl"
        onClose={handleTermsOfServiceDialogClose}
        open={termsOfServiceDialogOpen}
        showDivider={true}
        content={
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Box mb={4}>
              <Typography variant="h4" gutterBottom>
                Terms of Service
              </Typography>
              <Typography variant="subtitle1">
                Effective Date: {new Date().toLocaleDateString()}
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                1. Acceptance of Terms
              </Typography>
              <Typography variant="body1">
                By accessing or using the Service, you agree to be bound by
                these Terms. If you disagree with any part of the terms, you may
                not access the Service.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                2. Use of the Service
              </Typography>
              <Typography variant="body1">
                You agree to use the Service only for lawful purposes and in
                compliance with all applicable local, state, national, and
                international laws and regulations.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                3. User Accounts
              </Typography>
              <Typography variant="body1">
                When you create an account with us, you must provide accurate,
                complete, and current information at all times. Failure to do so
                constitutes a breach of the Terms, which may result in immediate
                termination of your account on our Service.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                4. Privacy
              </Typography>
              <Typography variant="body1">
                Our Privacy Policy governs how we collect, use, and disclose
                your information when you use our Service. Please review it
                carefully.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                5. Intellectual Property
              </Typography>
              <Typography variant="body1">
                The Service and its original content, features, and
                functionality are and will remain the exclusive property of
                [Your Company Name] and its licensors.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                6. Termination
              </Typography>
              <Typography variant="body1">
                We may terminate or suspend your account immediately, without
                prior notice or liability, for any reason whatsoever, including
                without limitation if you breach the Terms.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                7. Changes to the Terms
              </Typography>
              <Typography variant="body1">
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. What constitutes a material
                change will be determined at our sole discretion.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                8. Disclaimer
              </Typography>
              <Typography variant="body1">
                The Service is provided on an "AS IS" and "AS AVAILABLE" basis
                without warranties of any kind, either express or implied.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                9. Governing Law
              </Typography>
              <Typography variant="body1">
                These Terms shall be governed and construed in accordance with
                the laws of [Your Country/State], without regard to its conflict
                of law provisions.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                10. Contact Us
              </Typography>
              <Typography variant="body1">
                If you have any questions about these Terms, please contact us
                at [Your Contact Information].
              </Typography>
            </Box>
          </Paper>
        }
        title="Terms of service"
      />
      <CustomDialog
        maxWidth="xl"
        onClose={handlePrivacyPolicyDialogClose}
        open={privacyPolicyDialogOpen}
        showDivider={true}
        content={
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Box mb={4}>
              <Typography variant="h4" gutterBottom>
                Privacy Policy
              </Typography>
              <Typography variant="subtitle1">
                Effective Date: {new Date().toLocaleDateString()}
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                1. Introduction
              </Typography>
              <Typography variant="body1">
                SmartIms ("we", "us", or "our") operates <b>Smart Portal</b>{" "}
                (the "Service"). This page informs you of our policies regarding
                the collection, use, and disclosure of personal information when
                you use our Service.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                2. Information Collection and Use
              </Typography>
              <Typography variant="body1">
                We collect several different types of information for various
                purposes to provide and improve our Service to you. This
                includes personal data like name, email, etc., and usage data
                like IP addresses and browser details.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                3. Cookies and Tracking Data
              </Typography>
              <Typography variant="body1">
                We use cookies and similar tracking technologies to track the
                activity on our Service and hold certain information. You can
                instruct your browser to refuse all cookies or indicate when a
                cookie is being sent.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                4. Data Usage
              </Typography>
              <Typography variant="body1">
                The data we collect is used for various purposes such as
                providing and maintaining the Service, notifying you about
                changes, allowing you to participate in interactive features,
                and providing customer support.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                5. Data Security
              </Typography>
              <Typography variant="body1">
                The security of your data is important to us. We strive to use
                commercially acceptable means to protect your Personal Data, but
                no method of transmission over the internet or method of
                electronic storage is 100% secure.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                6. Data Sharing and Disclosure
              </Typography>
              <Typography variant="body1">
                We may share your personal information with third parties in the
                event of business transfers, with service providers, or if
                required by law.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                7. Links to Other Sites
              </Typography>
              <Typography variant="body1">
                Our Service may contain links to other sites that are not
                operated by us. If you click on a third-party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                8. Changes to This Privacy Policy
              </Typography>
              <Typography variant="body1">
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes.
              </Typography>
            </Box>

            <Box mb={3}>
              <Typography variant="h6" gutterBottom>
                9. Contact Us
              </Typography>
              <Typography variant="body1">
                If you have any questions about this Privacy Policy, please
                contact us at smartims.india@smartims.com.
              </Typography>
            </Box>
          </Paper>
        }
        title="Terms of service"
      />
    </>
  );
});

export default Footer;
