
export const documentPropertiesData = {
  fileName: "Document Name 1",
  createdOn: "01’Oct 2023",
  lastViewed: "04’Oct 2023",
  lastModified: "02’Oct 2023",
  fileType: "PDF",
  fileSize: "05 MB",
  status: "active",
};
