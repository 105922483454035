export const DashboardContainerStyles = {
  container: {
    overflowY: "scroll",
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    paddingBottom: "25px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    margin: "0 auto",
    maxWidth: "1306px",
    "&::-webkit-scrollbar": { display: "none" },
    "@media screen and (min-width:1366px)": {
      maxWidth: "1306px",
      margin: "0px auto",
    },
    "@media screen and (min-width:600px) and (max-width: 1308px)": {
      justifyContent: "center",
      height: "unset",
    },
    "@media screen and (max-width: 599px)": {
      height: "unset",
    },
  },
};
