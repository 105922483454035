export const DocumentPreviewStyles = {
  headingText: {
    fontSize: "var(--font-size-20)",
    color: "var(--light-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  container: {
    border: "1px solid var(--shaded-cyan)",
    padding: "20px",
    backgroundColor: "var(--white)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "11px",
    borderRadius: "10px",
    "@media screen and (max-width: 1023px)": {
      gap: "15px",
      padding: "16px",
    },
  },
  headingTextContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingBottom: "13px",
      width: "100%",
      borderBottom: "0.5px solid var(--primary-grey)",
    },
  },
  imageAndTextContainer: {
    gap: "2px",
  },
  imagesContainer: {
    display: "flex",
    gap: "18px",
    "@media screen and (max-width: 1023px)": {
      gap: "17.5px",
      paddingTop: "10px",
    },
  },
  textContainer: {
    width: "100%",
    maxHeight: "calc(100vh - 295px)",
    border: "1px solid var(--primary-grey)",
    overflowY: "scroll",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
    backgroundColor: "var(--shaded-brown)",
    borderRadius: "10px",
    padding: "20px",
    "@media screen and (max-width: 1023px)": {
      width: "100%",
      height: "440px",
      overflowY: "scroll",
      border: "unset",
      backgroundColor: "unset",
      padding: "0px",
    },
  },
  templateStyles: {
    whiteSpace: "pre-line",
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fonWeight: "var(--font-weight-400)",
    lineHeight: "24px",
    textAlign: "left",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      color: "var(--ternary-grey)",
    },
  },
  line: {
    display: "none",
    "@media screen and (max-width: 1023px)": {
      display: "block",
      backgroundColor: "var(--primary-grey)",
      width: "1px",
      height: '24px',
      lineHeight: 'normal',
    },
  },
  text: {
    color: "var(--support-white)",
  },
  archiveIcon: {
    "@media (max-width:1023px)": {
      width: "20px",
      heigth: "20px",
      "> img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  moveIcon: {
    width: "16px",
    height: "16px",
    "@media (max-width:1023px)": {
      width: "16px",
      heigth: "13px",
      "> img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  downloadIcon: {
    width: "16px",
    height: "16px",
    "@media (max-width:1023px)": {
      width: "20px",
      height: "20px",
      "> img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  expandIcon:{
    width: "16px",
    height: "16px",
  },
};
