import paymentBackground from "../../assets/svgs/payment-background.svg";

export const PaymentMethodStyles = {
  cardsContainer: {
    padding: "40px",
    backgroundColor: "var(--white)",
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    borderRadius: "8px",
    border: "1px solid var(--shaded-cyan)",
    maxWidth: "1920px",
    margin: "0 auto",
    "@media (max-width: 1023px)": {
      backgroundColor: "unset",
      padding: "unset",
      border: "unset",
      flexDirection: "column",
      flexWrap: "unset",
      gap: "33px",
      marginTop: "10px",
    },
  },
  customerCard: {
    backgroundImage: `url(${paymentBackground})`,
    width: "311px",
    height: "200px",
    borderRadius: "18px",
    boxShadow: "10px 40px 64px 12px #1136810F",
    "@media (max-width: 1023px)": {
      margin: "0 auto",
      boxShadow: "unset",
    },
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 15px 17px 15px",
  },
  recurringButton: {
    padding: "9px 10px",
    color: "var(--dark-black)",
    border: "unset",
    boxShadow: "unset",
    lineHeight: "16px",
    letterSpacing: "0.04em",
  },
  cardNumberContainer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    lineHeight: "24px",
  },
  cardNumber: {
    color: "var(--white)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-16)",
  },
  contentContainer: {
    backgroundColor: "var(--white)",
    display: "flex",
    justifyContent: "space-between",
    padding: "26px 32px 26px 24px",
    marginTop: "14px",
    borderRadius: "0 0 18px 18px",
  },
  cardInformation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "var(--black)",
    gap: "2px",
  },
  cardHolderName: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "17px",
    fontSize: "var(--font-size-12)",
  },
  cardExpiry: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "17px",
    fontSize: "var(--font-size-12)",
  },
  addPaymentMethodContainer: {
    width: "322px",
    height: "195px",
    border: "1px solid var(--support-white)",
    borderRadius: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  addPaymentMethodContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "7px",
    justifyContent: "center",
  },
  addPaymentMethodAddSymbol: {
    width: "24px",
    height: "24px",
  },
  addPaymentMethodDescription: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "normal",
    fontSize: "var(--font-size-12)",
    letterSpacing: "0.48px",
  },
  addPaymentButton: {
    display: "none",
    "&:hover": {
      backgroundColor: "unset",
    },
    "@media (max-width: 1023px)": {
      display: "flex",
      width: "100%",
      margin: "0 auto",
      backgroundColor: "unset",
      maxWidth: "450px",
      boxShadow: "none",
    },
  },
};
