import { Box, useMediaQuery } from "@mui/material";
import CustomerDetailsList from "../CustomerDetailsList/CustomerDetailsList";
import CustomerDetailsResponsive from "../CustomerDetailsResponsive/CustomerDetailsResponsive";
import { CustomerDetailsOverViewData } from "../../constants/CustomerDetailsOverviewData";
import { customerDetailsInvoicesTableData } from "../../constants/CustomerDetailsInvoicesTableData";
import { ClaimHistoryData } from "../../constants/ClaimHistoryData";
import { CustomerDetailsInteractionHistory } from "../../constants/CustomerDetailsInteractionHistory";
import { paymentMethodCardData } from "../../constants/PaymentMethodCard";
import { customerDetailsCardData } from "../../constants/CustomerDetailsCardData";
import { personChat } from "../../constants/myTasksPersonChatData";
import { calenderData } from "../../constants/CalenderData";

const CustomerDetails = () => {
  const isSmallScreen = useMediaQuery("(max-width:1200px)");
  const tasksData = calenderData.flatMap((calendar) =>
    calendar.meetings.map((meeting) => meeting.taskDetails)
  );
  return (
    <Box>
      {isSmallScreen ? (
        <CustomerDetailsResponsive
          claimData={ClaimHistoryData}
          interactionHistoryData={CustomerDetailsInteractionHistory}
          overviewData={CustomerDetailsOverViewData.overviewPolicyData}
          mytasksData={tasksData}
          invoiceCards={customerDetailsInvoicesTableData.invoicesData}
          paymentMethodCardData={paymentMethodCardData}
          customerHeaderData={customerDetailsCardData}
          notesChatData={personChat}
        />
      ) : (
        <CustomerDetailsList
          customerOverViewData={CustomerDetailsOverViewData}
          customerTasksData={tasksData}
          customerInvoicesData={customerDetailsInvoicesTableData}
          claimHistoryData={ClaimHistoryData}
          interactionHistoryData={CustomerDetailsInteractionHistory}
          paymentMethodCardData={paymentMethodCardData}
          customerHeaderCardData={customerDetailsCardData}
          notesChatData={personChat}
        />
      )}
    </Box>
  );
};
export default CustomerDetails;
