import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { HeaderMenuStyles } from "./HeaderMenuStyles";
import {
  CLOSE,
  LOGOUT,
  MENU,
  MY_TASKS_LOWER,
  VIEW_COMMISSION_LOWER,
  VIEW_CUSTOMER_LOWER,
  VIEW_DASHBOARD_LOWER,
  VIEW_DOCUMENTS_LOWER,
  VIEW_PAYMENTS_LOWER,
  VIEW_POLICY_LOWER,
  VIEW_SETTINGS_LOWER,
} from "../../constants";
import crossIcon from "../../assets/svgs/cross-icon.svg";
import { menuItems } from "../../constants/MobileMenu";
import ImageAndText from "../ImageAndText/ImageAndText";
import logoutIcon from "../../assets/svgs/logout-icon.svg";
import { useNavigate } from "react-router-dom";

type MenuItemType = {
  image: string;
  text: string;
  action: string;
  key: string;
};

type HeaderMenuProps = {
  onClose: () => void;
};

const HeaderMenu: React.FC<HeaderMenuProps> = ({ onClose }) => {
  const [hovered, setHovered] = React.useState(false);
  const [logOutHover, setLogOutHover] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = (action: string) => {
    switch (action) {
      case MY_TASKS_LOWER:
        navigate("/mytasks");
        break;
      case VIEW_DASHBOARD_LOWER:
        navigate("/agency");
        break;
      case VIEW_POLICY_LOWER:
        navigate("/policy-listing");
        break;
      case VIEW_CUSTOMER_LOWER:
        navigate("/customer-listing");
        break;
      case VIEW_COMMISSION_LOWER:
        navigate("/commission-statements");
        break;
      case VIEW_PAYMENTS_LOWER:
        navigate("/payment-schedule");
        break;
      case VIEW_DOCUMENTS_LOWER:
        navigate("/document-listing");
        break;
      case VIEW_SETTINGS_LOWER:
        console.log("Navigating to Settings");
        break;
      default:
        navigate("/");
        break;
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Box sx={HeaderMenuStyles.menuContainer}>
      <Box sx={HeaderMenuStyles.headingContainer}>
        <Typography sx={HeaderMenuStyles.headingText}>{MENU}</Typography>
        <Box sx={HeaderMenuStyles.imageContainer} onClick={onClose}>
          <Box sx={HeaderMenuStyles.crossIcon}>
            <img src={crossIcon} alt={CLOSE} />
          </Box>
        </Box>
      </Box>
      <Box sx={HeaderMenuStyles.linksContainer}>
        <Box sx={HeaderMenuStyles.navigationContainer}>
          {menuItems.map((item: MenuItemType, index: number) => (
            <Box
              key={item.key}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              <ImageAndText
                key={item.key}
                image={item.image}
                text={item.text}
                onClick={() => handleClick(item.action)}
                additionalClassName={HeaderMenuStyles.imageTextContainer}
                additionalClassText={
                  hovered
                    ? HeaderMenuStyles.imageHoverText
                    : HeaderMenuStyles.imageText
                }
                imageClassName={HeaderMenuStyles.image}
              />
            </Box>
          ))}
        </Box>
        <Box
          sx={HeaderMenuStyles.logOutContainer}
          onMouseEnter={() => setLogOutHover(true)}
          onMouseLeave={() => setLogOutHover(false)}
        >
          <ImageAndText
            image={logoutIcon}
            text={LOGOUT}
            onClick={() => {
              onClose();
            }}
            additionalClassName={HeaderMenuStyles.imageTextContainer}
            additionalClassText={
              logOutHover
                ? HeaderMenuStyles.imageHoverText
                : HeaderMenuStyles.imageText
            }
            imageClassName={HeaderMenuStyles.image}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderMenu;
