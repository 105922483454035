import { Box, Typography } from "@mui/material";
import React from "react";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import {
  ARCHIVE_FILE,
  ARCHIVING_THE_FOLDER,
  ARE_YOU_SURE_ARCHIVE,
  CANCEL,
  FOLDER_LOWER,
  LAST_MODIFIED,
  MENU,
} from "../../constants";
import { documentListingListCardStyles } from "./DocumentListingListCardStyles";
import CustomIcon from "../CustomIcon/CustomIcon";
import HeadingText from "../HeadingText/HeadingText";
import Drawers from "../Drawers/Drawers";
import ModalComponent from "../ModalComponent/ModalComponent";
import ImageAndText from "../ImageAndText/ImageAndText";
import CustomButton from "../CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

export interface DocumentListingListCardProps {
  name: string | undefined;
  lastModified: string;
  isFolder: boolean;
}

const DocumentListingListCard: React.FC<DocumentListingListCardProps> = ({
  name,
  lastModified,
  isFolder,
}) => {
  const [, setMenuOpen] = React.useState<string | null>(null);
  const [archiveOpen, setArchiveOpen] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const navigate = useNavigate();
  const drawerOptions = [
    { value: "View", key: "view" },
    { value: "Edit", key: "edit" },
    { value: "Move", key: "move" },
    { value: "Archive", key: "archive" },
  ];

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleArchiveClick = () => {
    setArchiveOpen(!archiveOpen);
    setMenuOpen(null);
  };

  const handleDrawerOptionClick = (key: string) => {
    if (key === "archive") {
      handleArchiveClick();
    }
    if (key === "view") {
      if (!isFolder) {
        navigate("/document-details");
      }
    }
  };
  return (
    <>
      <Box sx={documentListingListCardStyles.documentCard}>
        <Box sx={documentListingListCardStyles.innerContainer}>
          <Box sx={documentListingListCardStyles.documentIcon}>
            {isFolder ? (
              <CustomIcon width="20px" height="17px" iconName="Folder" />
            ) : (
              <CustomIcon width="17px" height="20px" iconName="Document" />
            )}
          </Box>
          <Box sx={documentListingListCardStyles.documentContent}>
            <Typography sx={documentListingListCardStyles.documentFolderName}>
              {name}
            </Typography>
            <Typography sx={documentListingListCardStyles.documentLastModified}>
              {LAST_MODIFIED} {lastModified}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={documentListingListCardStyles.documentMenu}
          onClick={handleDrawer}
        >
          <img src={menuIcon} alt={MENU} />
        </Box>
      </Box>

      <Drawers
        type="secondary"
        anchor="bottom"
        open={drawerOpen}
        additionalClass={documentListingListCardStyles.drawer}
        toggleDrawer={handleDrawer}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={
              documentListingListCardStyles.drawertextHeading
            }
            heading={"Menu"}
          />
          <Box sx={documentListingListCardStyles.optionsContainer}>
            {drawerOptions.map((option, index) => (
              <Typography
                key={option.key}
                sx={
                  index === drawerOptions.length - 1
                    ? documentListingListCardStyles.lastText
                    : documentListingListCardStyles.drawerTexts
                }
                onClick={() => handleDrawerOptionClick(option.key)}
              >
                {option.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Drawers>
      <ModalComponent
        headingAdditionalClass={documentListingListCardStyles.modalHeading}
        heading={
          isFolder
            ? `${ARE_YOU_SURE_ARCHIVE} "${name}" ${FOLDER_LOWER}`
            : ARCHIVE_FILE
        }
        open={archiveOpen}
        handleOpen={handleArchiveClick}
        additionalClassContainer={documentListingListCardStyles.modalContainer}
        headingContainerAdditionalClass={
          documentListingListCardStyles.modalHeadingContainer
        }
      >
        <Box sx={documentListingListCardStyles.contentContainer}>
          <ImageAndText
            image={<CustomIcon iconName="Archive" />}
            text={ARCHIVING_THE_FOLDER}
            additionalClassText={documentListingListCardStyles.imageText}
          />
          <Box sx={documentListingListCardStyles.buttonsContainer}>
            <CustomButton
              text={"Confirm"}
              additionalClassName={documentListingListCardStyles.cancelButton}
            />
            <CustomButton
              onHandleClick={handleArchiveClick}
              text={CANCEL}
              type="outlined"
              additionalClassName={documentListingListCardStyles.cancelButton}
            />
          </Box>
        </Box>
      </ModalComponent>
    </>
  );
};

export default DocumentListingListCard;
