export const MyTasksStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 30px",
    gap: "26px",
    maxWidth: "1920px",
    margin: "0 auto",
    marginTop: "68px",
    "@media (max-width: 1023px)": {
      gap: "24px",
      padding: "24px 20px",
      marginTop: "60px",
    },
  },
  line: {
    color: "var(--primary-grey)",
    fontSize: "var(--font-size-24)",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  imageAndTextHeadingContainer: {
    gap: "6px",
  },
  imageAndTextContainer: {
    gap: "6px",
    "@media (max-width: 1023px)": {
      display: "none",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },
  imageContainer: {
    display: "flex",
    gap: "20px",
  },
  headingFilterContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tab2AdditionalStyles: {
    paddingLeft: "30px",
    paddingRight: "14px",
    "@media (max-width: 1023px)": {
      padding: "9px 40px",
    },
  },
  tab1AdditionalStyles: {
    "@media (max-width: 1023px)": {
      padding: "9px 40px",
    },
  },
};
