import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IClaimId,
  ICoveragesAndDiscountsResponse,
  IGetBillingTransactionsRequestParams,
  IGetBillingTransactionsResponse,
  IGetLienHolderRequestParams,
  IGetLienHolderResponse,
  IGetPoliciyActivitiesRequestParams,
  IGetPolicyActivitiesResponse,
  IGetPolicyClaimsTransactionsResponse,
  IGetPolicyCoveragesAndDiscountsRequestParams,
  IGetPolicyDetailsHeaderRequestParams,
  IGetPolicyVehiclesAndDriversRequestParams,
  IGetPolicyVehiclesAndDriversResponse,
  IPolicyDetailsHeaderResponse,
} from "../../../models/user/policyDetails/PolicyDetails";
import {
  API_GET_BILLING_TRANSACTIONS,
  API_GET_DOCUMENT_CONTENT,
  API_POST_BILLING_DETAILS,
  API_POST_COVERAGES_AND_COVERAGES,
  API_POST_DOCUMENTS,
  API_POST_LEIN_HOLDERS,
  API_POST_POLICY_ACTIVITIES,
  API_POST_POLICY_CLAIMS_ACTIVITIES,
  API_POST_POLICY_DETAILS_HEADER,
  API_POST_POLICY_VEHICLES_AND_VEHICLES,
} from "../../../Apis/ApiEndPoints";
import {
  IDocumentContentRequest,
  IDocumentResponse,
  IDocumentsRequestParams,
} from "../../../models/user/policyDetails/Document";
import { CustomAction } from "../../customAction";
import {
  IBillingRequestParams,
  IBillingResponse,
} from "../../../models/user/policyDetails/Billings";
import { Api } from "../../..";

export const GetPolicyDetailsHeader = createAsyncThunk(
  "policyDetails/GetPolicyDetailsHeader",
  async (requestParams: IGetPolicyDetailsHeaderRequestParams, { dispatch }) => {
    const response: IPolicyDetailsHeaderResponse = await CustomAction(
      Api.post(API_POST_POLICY_DETAILS_HEADER, requestParams),
      dispatch,
      "policyDetails/GetPolicyDetailsHeader"
    );
    return response;
  }
);

export const GetPolicyActivities = createAsyncThunk(
  "policyDetails/GetGetPolicyActivities",
  async (requestParams: IGetPoliciyActivitiesRequestParams, { dispatch }) => {
    const response: IGetPolicyActivitiesResponse = await CustomAction(
      Api.post(API_POST_POLICY_ACTIVITIES, requestParams),
      dispatch,
      "policyDetails/GetGetPolicyActivities"
    );
    return response;
  }
);

export const GetPolicyClaimsTransactions = createAsyncThunk(
  "policyDetails/GetPolicyClaimsTransactions",
  async (claimIds: IClaimId[], { dispatch }) => {
    const response: IGetPolicyClaimsTransactionsResponse = await CustomAction(
      Api.post(API_POST_POLICY_CLAIMS_ACTIVITIES, claimIds),
      dispatch,
      "policyDetails/GetPolicyClaimsTransactions"
    );
    return response;
  }
);

export const GetBillingTransactions = createAsyncThunk(
  "policyDetails/GetBillingTransactions",
  async ({ accountId }: IGetBillingTransactionsRequestParams, { dispatch }) => {
    const response: IGetBillingTransactionsResponse = await CustomAction(
      Api.post(API_GET_BILLING_TRANSACTIONS, { accountId }),
      dispatch,
      "policyDetails/GetBillingTransactions"
    );
    return response;
  }
);

export const GetPolicyCoveragesAndDiscounts = createAsyncThunk(
  "policyDetails/GetPolicyCoveragesAndDiscounts",
  async (
    requestParams: IGetPolicyCoveragesAndDiscountsRequestParams,
    { dispatch }
  ) => {
    const response: ICoveragesAndDiscountsResponse = await CustomAction(
      Api.post(API_POST_COVERAGES_AND_COVERAGES, requestParams),
      dispatch,
      "policyDetails/GetPolicyCoveragesAndDiscounts"
    );
    return response;
  }
);

export const GetPolicyVehiclesAndDrivers = createAsyncThunk(
  "policyDetails/GetPolicyVehiclesAndDrivers",
  async (
    requestParams: IGetPolicyVehiclesAndDriversRequestParams,
    { dispatch }
  ) => {
    const response: IGetPolicyVehiclesAndDriversResponse = await CustomAction(
      Api.post(API_POST_POLICY_VEHICLES_AND_VEHICLES, requestParams),
      dispatch,
      "policyDetails/GetPolicyVehiclesAndDrivers"
    );
    return response;
  }
);

export const GetInsuranceDocuments = createAsyncThunk(
  "policyDetails/GetPolicyDocuments",
  async (requestParams: IDocumentsRequestParams, { dispatch }) => {
    const response: IDocumentResponse = await CustomAction(
      Api.post(API_POST_DOCUMENTS, requestParams),
      dispatch,
      "policyDetails/GetPolicyDocuments"
    );
    return response;
  }
);

export const GetInsuranceDocumentContent = createAsyncThunk(
  "policyDetails/GetPolicyDocumentContent",
  async (requestParams: IDocumentContentRequest, { dispatch }) => {
    const response: any = await CustomAction(
      Api.get(
        `${API_GET_DOCUMENT_CONTENT}/${requestParams.policyId}/${requestParams.documentId}`,
        { responseType: "arraybuffer" }
      ),
      dispatch,
      "GetPolicyDocumentContent"
    );

    return response;
  }
);

export const GetLienHolders = createAsyncThunk(
  "policyDetails/GetLienHolders",
  async (requestParams: IGetLienHolderRequestParams, { dispatch }) => {
    const response: IGetLienHolderResponse = await CustomAction(
      Api.post(API_POST_LEIN_HOLDERS, requestParams),
      dispatch,
      "policyDetails/GetLienHolders"
    );
    return response;
  }
);

export const GetPolicyBillingDetails = createAsyncThunk(
  "billingDetails",
  async (requestParams: IBillingRequestParams, { dispatch }) => {
    const response: IBillingResponse = await CustomAction(
      Api.post(API_POST_BILLING_DETAILS, requestParams),
      dispatch,
      "billingDetails"
    );
    return response;
  }
);
