export const AccordianStyles = {
  headingText: {
    fontSize: "var(--font-size-14)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
  },
  firstAccordian: {
    borderTopLeftRadius: "8px !important",
    borderTopRightRadius: "8px !important",
    boxShadow: "none",
  },
  accordian: {
    borderRadius: "0px !important",
    boxShadow: "none",
    borderBottomLeftRadius: "8px !important",
    borderBottomRightRadius: "8px !important",
  },
  radioButton: {
    padding: "0px",
    width: "16px",
    height: "16px",
  },
};
