import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAdminAuthSlice,
  IAdminDetails,
} from "../../../models/admin/adminAuth/adminAuth";
import { adminLogin } from "./adminAuthActions";

const initialState: IAdminAuthSlice = {
  adminDetails: null,
};

const adminAuthSlice = createSlice({
  name: "adminAuth",
  initialState: initialState,
  reducers: {
    setAdminDetails: (state, { payload }: PayloadAction<IAdminDetails>) => {
      state.adminDetails = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      state.adminDetails = payload.data;
    });
  },
});

export default adminAuthSlice.reducer;

export const { setAdminDetails } = adminAuthSlice.actions;
