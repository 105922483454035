import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import CustomButton from "../../common/CustomButton/CustomButton";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import { ClaimTrackingResponsiveStyles } from "./ClaimTrackingResponsiveStyles";
import ClaimTrackingCard from "../../common/ClaimTrakingCard/ClaimTrackingCard";
import BasicTimeline from "../../common/BasicTimeLine/BasicTimeLine";
import { claimTrackingData } from "../../constants/ClaimTrackingData";
import { CLAIM_DETAILS, OPEN, VIEW_LESS, VIEW_MORE } from "../../constants";
import dropDownArrowIcon from "../../assets/svgs/drop-down-arrow.svg";
import upSideDownArrowIcon from "../../assets/svgs/upside-down-icon.svg";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import HeadingText from "../../common/HeadingText/HeadingText";
import { claimTrackingHeaderData } from "../../constants/ClaimTrackingHeaderData";
import { useNavigate } from "react-router-dom";
const ClaimTrackingResponsive: React.FC = () => {
  const [headerData, setHeaderData] = useState(
    claimTrackingHeaderData.slice(0, 3)
  );
  const [isViewMore, setIsViewMore] = useState(true);
  const handleSlicing = () => {
    if (isViewMore) {
      setHeaderData(claimTrackingHeaderData);
      setIsViewMore(false);
    } else {
      setHeaderData(claimTrackingHeaderData.slice(0, 3));
      setIsViewMore(true);
    }
  };
  const navigate = useNavigate();
  const mappedTimelineData = claimTrackingData.map((item, index) => ({
    left: "",
    right: (
      <Box sx={ClaimTrackingResponsiveStyles.timeLineRightContent}>
        <Typography sx={ClaimTrackingResponsiveStyles.dateText}>
          {item.date}
        </Typography>
        <ClaimTrackingCard title={item.heading} subTitle={item.subHeading} />
      </Box>
    ),
  }));
  return (
    <Box sx={ClaimTrackingResponsiveStyles.container}>
      <Box sx={ClaimTrackingResponsiveStyles.dashboardCrumb}>
        <DashboardBreadCrumb
          handleClick={() => navigate(-1)}
          variant={"primary"}
          text={"Auto Accident |  C789"}
        />
        <CustomButton type="red-filled" text={OPEN} />
      </Box>
      <Box sx={ClaimTrackingResponsiveStyles.claimTrackingHeader}>
        <Box sx={ClaimTrackingResponsiveStyles.headerContainer}>
          {headerData.map((data, index) => (
            <HeadingSubHeading
              containerAdditionalClassName={
                ClaimTrackingResponsiveStyles.headingContainer
              }
              subHeadingAdditionalClassName={
                ClaimTrackingResponsiveStyles.subheading
              }
              headingAdditionalClassName={ClaimTrackingResponsiveStyles.heading}
              heading={data.heading}
              subheading={data.subHeading}
            />
          ))}
        </Box>
        <Box sx={ClaimTrackingResponsiveStyles.viewContainer}>
          {isViewMore ? (
            <ImageAndText
              additionalClassName={
                ClaimTrackingResponsiveStyles.viewMoreContainer
              }
              onClick={handleSlicing}
              image={dropDownArrowIcon}
              text={VIEW_MORE}
            />
          ) : (
            <ImageAndText
              additionalClassName={
                ClaimTrackingResponsiveStyles.viewLessContainer
              }
              onClick={handleSlicing}
              image={upSideDownArrowIcon}
              text={VIEW_LESS}
            />
          )}
        </Box>
      </Box>
      <Box sx={ClaimTrackingResponsiveStyles.timelineContainer}>
        <HeadingText
          additionalHeadingClass={
            ClaimTrackingResponsiveStyles.claimDetailsHeading
          }
          heading={CLAIM_DETAILS}
        />
        <BasicTimeline
          dataPosition={0}
          type={"secondary"}
          data={mappedTimelineData}
        />
      </Box>
    </Box>
  );
};

export default ClaimTrackingResponsive;
