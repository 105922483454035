export const policyListingHeaderData = [
  {
    heading: "100",
    subHeading: "Total Policies",
  },
  {
    heading: "08",
    subHeading: "Overdue Policies",
  },
  {
    heading: "70",
    subHeading: "Active Policies",
  },
  {
    heading: "20",
    subHeading: "Due Renewal Policies",
  },
  {
    heading: "02",
    subHeading: "Cancelled Policies",
  },
];
