export const MonthlyStatementCardStyles = {
  headingContainer: {
    display: "flex",
    gap: "1px",
    flexDirection: "column",
    minWidth: "122px",
  },
  headingText: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },

  container: {
    display: "flex",
    rowGap: "16px",
    columnGap: "33px",
    width: "100%",
    flexWrap: "wrap",
    border: "1px solid var(--shaded-cyan)",
    padding: "16px 20px",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
  },
  subHeadingText: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  headingPolicyText: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    lineHeight: "22px",
    color: "var(--primary-black)",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
