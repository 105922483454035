import {
  Card,
  Box,
  Typography,
  useMediaQuery,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import VehiclesStyles from "./VehiclesStyles";
import { IPolicyVehicle } from "../../../../../models/user/policyDetails/PolicyDetails";
import CustomTable, {
  IColumn,
} from "../../../../../common/CustomTable/CustomTable";
import AddVehicleForm from "../../../../../common/AddVehicleForm/AddVehicleForm";
import {
  DeleteOutline,
  EditOutlined,
  MoreVertOutlined,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { PostRemoveVehicle } from "../../../../../store/user/policyChange/policyChangeActions";
import { RootState } from "../../../../../store/store";
import { useParams } from "react-router-dom";
import {
  formatDateToYYYYMMDD,
  isFeatureAvailable,
} from "../../../../../utils/Utility";
import UpdateCoverages from "../../Coverages/UpdateCoverages/UpdateCoverages";
import AlertDialog from "../../../../../components/AlertDialog/AlertDialog";
import MessageDialog from "../../../../../components/MessageDialog/MessageDialog";
import CustomIcon from "../../../../../common/CustomIcon/CustomIcon";

interface IProps {
  vehicles: IPolicyVehicle[];
}

function Vehicles({ vehicles }: IProps) {
  const dispatch = useAppDispatch();

  const { policyId } = useParams();

  const isMobile = useMediaQuery("(max-width:600px)");

  const { userDetails } = useAppSelector((state) => state.userAuth);
  const { policyDetailsHeader } = useAppSelector(
    (state: RootState) => state.policyDetails
  );
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state) => state.common
  );
  const { features } = useAppSelector((state: RootState) => state.features);

  const [addVehcileFormOpen, setsetAddVehicleFormOpen] =
    useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<IPolicyVehicle | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
  const [updateCoveragesOpen, setUpdateCoveragesOpen] =
    useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleAddVehicle = useCallback(() => {
    setsetAddVehicleFormOpen(true);
  }, []);

  const handleMoreClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, vehicle: IPolicyVehicle) => {
      setAnchorEl(event.currentTarget);
      setSelectedVehicle(vehicle);
    },
    []
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteVehicleClick = () => {
    handleMenuClose();
    setAlertDialogOpen(true);
  };

  const handleAlertDialogCancel = () => {
    setAlertDialogOpen(false);
  };

  const handleAlertDialogOkClick = () => {
    setAlertDialogOpen(false);
    if (
      selectedVehicle &&
      policyId &&
      userDetails &&
      userDetails.customerId &&
      policyDetailsHeader &&
      policyDetailsHeader.carrierPolicyNumber
    ) {
      dispatch(
        PostRemoveVehicle({
          additionalInterestDetails: {
            accountNumber: "",
            address: "",
            interestName: "",
            interestTypeCd: "",
          },
          carrierPolicyNo: policyDetailsHeader?.carrierPolicyNumber,
          changeDt: formatDateToYYYYMMDD(new Date()),
          customerId: userDetails.customerId,
          detailDescription: "Removing vehicle from the policy",
          policyRef: policyId,
          vehicle: {
            make: selectedVehicle?.make,
            model: selectedVehicle?.model,
            manufactureYr: selectedVehicle.manufactureYr,
            vehicleType: selectedVehicle.vehicleType,
            vin: selectedVehicle.vin,
            address: "",
          },
        })
      );
    }
  };

  const handleMessageModalClose = useCallback(() => {
    setMessageDialogOpen(false);
  }, []);

  const handleUpdateCoveragesClick = useCallback(() => {
    handleMenuClose();
    setUpdateCoveragesOpen(true);
  }, []);

  const columns: IColumn[] = [
    {
      label: "Year",
      accessorKey: "manufactureYr",
    },
    {
      label: "Make",
      accessorKey: "make",
    },
    {
      label: "Model",
      accessorKey: "model",
    },
    {
      label: "VIN",
      accessorKey: "vin",
    },
    {
      label: "Type",
      accessorKey: "typeCd",
      Cell: (row: any) => {
        return row.vehicleType === "PrivatePassengerAuto"
          ? "Private Passenger Vehicle"
          : "";
      },
    },
    {
      label: "",
      accessorKey: "",
      Cell: (vehicle: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isFeatureAvailable({
            featureCodes: ["lob_pa_policies_update_coverages"],
            features,
          }) ? (
            <Tooltip
              title="Update Coverages"
              onClick={() => {
                setSelectedVehicle(vehicle);
                handleUpdateCoveragesClick();
              }}
            >
              <IconButton>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          {isFeatureAvailable({
            featureCodes: ["lob_pa_policies_delete_vehicle"],
            features,
          }) ? (
            <Tooltip title="Remove Vehicle">
              <IconButton
                disabled={vehicles.length === 1}
                onClick={() => {
                  setSelectedVehicle(vehicle);
                  handleDeleteVehicleClick();
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "policyChange/PostRemoveVehicle"
    ) {
      setMessageDialogOpen(true);
    }
  }, [isSuccess, isError, calledReducerType]);

  return (
    <>
      <Box sx={{ display: "grid", gap: "20px" }}>
        <Box sx={VehiclesStyles.headingAndButton}>
          <Box sx={VehiclesStyles.vehicleHeading}>
            <Box>
              <CustomIcon iconName="Car" />
            </Box>
            <Typography sx={VehiclesStyles.HeadingText}>
              {`Vehicles (${vehicles.length})`}
            </Typography>
          </Box>

          {isFeatureAvailable({
            featureCodes: ["lob_pa_policies_add_vehicle"],
            features,
          }) && policyDetailsHeader?.carrierPolicyStatus !== "Cancelled" ? (
            <Button
              variant="contained"
              sx={VehiclesStyles.addVehicleButton}
              onClick={handleAddVehicle}
            >
              Add vehicle
            </Button>
          ) : (
            <></>
          )}
        </Box>
        {isMobile ? (
          vehicles.map((vehicle: IPolicyVehicle, index: number) => (
            <Card key={`veh-${index}`} sx={VehiclesStyles.mobileCard}>
              <Typography
                sx={{
                  ...VehiclesStyles.year,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "wrap",
                }}
              >
                {vehicle.manufactureYr}-{vehicle.make}-{vehicle.model}
              </Typography>
              <Typography sx={VehiclesStyles.vin}>{vehicle.vin}</Typography>
              <Box sx={VehiclesStyles.type}>
                Type:&nbsp;
                <Typography sx={VehiclesStyles.vehicleType}>
                  {vehicle.vehicleType === "PrivatePassengerAuto"
                    ? "Private Passenger Vehicle"
                    : ""}
                </Typography>
              </Box>
              {isFeatureAvailable({
                featureCodes: [
                  "lob_pa_policies_delete_vehicle",
                  "lob_pa_policies_update_coverages",
                ],
                features,
              }) ? (
                <IconButton
                  sx={{ position: "absolute", right: "0px" }}
                  onClick={(event) => handleMoreClick(event, vehicle)}
                >
                  <MoreVertOutlined />
                </IconButton>
              ) : (
                <></>
              )}
            </Card>
          ))
        ) : (
          <CustomTable columns={columns} data={vehicles} />
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {isFeatureAvailable({
          featureCodes: ["lob_pa_policies_update_coverages"],
          features,
        }) ? (
          <MenuItem onClick={handleUpdateCoveragesClick}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <EditOutlined sx={{ fontSize: "16px" }} />
              <Typography>Update Coverages</Typography>
            </Box>
          </MenuItem>
        ) : null}

        {isFeatureAvailable({
          featureCodes: ["lob_pa_policies_delete_vehicle"],
          features,
        }) ? (
          <MenuItem
            onClick={handleDeleteVehicleClick}
            disabled={vehicles.length === 1}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <DeleteOutline sx={{ fontSize: "16px" }} />
              <Typography>Remove</Typography>
            </Box>
          </MenuItem>
        ) : null}
      </Menu>
      <AddVehicleForm
        open={addVehcileFormOpen}
        setOpen={setsetAddVehicleFormOpen}
      />
      <AlertDialog
        handleCancelClick={handleAlertDialogCancel}
        handleOkClick={handleAlertDialogOkClick}
        open={alertDialogOpen}
        title="Removing this vehicle will end its coverage under this policy"
      />
      <MessageDialog
        handleClose={handleMessageModalClose}
        open={messageDialogOpen}
      />
      {selectedVehicle ? (
        <UpdateCoverages
          open={updateCoveragesOpen}
          setOpen={setUpdateCoveragesOpen}
          vehicle={selectedVehicle}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Vehicles;
