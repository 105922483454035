export const monthlyStatement = [
  {
    heading: "05 Aug‘24",
    subHeading: "Date",
  },
  {
    heading: "August",
    subHeading: "Month",
  },
  {
    heading: "25",
    subHeading: "No.of Transactions",
  },
  {
    heading: "$16,656",
    subHeading: "Total Commission",
  },
];
