export const CustomerListingResponsiveStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customerListingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  heading: {
    fontSize: "var(--font-size-16)",
  },
};
