import { createSlice } from "@reduxjs/toolkit";
import { IPolicyChangeSlice } from "../../../models/user/PolicyChange/PolicyChange";

const initialState: IPolicyChangeSlice = {};

const policyChangeSlice = createSlice({
  name: "policyChange",
  initialState: initialState,
  reducers: {},
});

export default policyChangeSlice.reducer;
