const EditPictureStyles = {
    mainBox: { 
        display: "flex", 
        alignItems: "end", 
        justifyContent: "end" 
    },
    innerBoxForProfile:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
    },
    svgPhotoBorder:{ position: "relative" },
    svgPhoto:{
        position: "absolute"
    },
    cameraImage:{
        position: "absolute" 
    }
};
export default EditPictureStyles;
