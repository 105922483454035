import React from "react";
import { Props } from "./Props";

const PhoneIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="19.4758" cy="16.6489" r="6.32542" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46299 3.60357C6.71082 2.89755 8.24318 3.45845 8.93463 4.62106C9.54123 5.64099 10.2481 6.61344 10.8891 7.41481C11.7561 8.4986 11.6393 10.154 10.4815 11.0124L9.39136 11.8206C9.16953 11.9851 8.85638 11.9386 8.69192 11.7167C8.52746 11.4949 8.57397 11.1817 8.7958 11.0173L9.88597 10.2091C10.5462 9.71956 10.655 8.723 10.1083 8.03948C9.44696 7.21279 8.71137 6.20197 8.07515 5.13223C7.61936 4.36586 6.66097 4.07472 5.95542 4.47392L5.35285 4.81484C4.5591 5.26394 3.93512 5.789 3.72359 6.49405C3.18504 8.28907 3.36511 11.6871 7.24241 16.0714L7.25834 16.0894L7.27248 16.1089C9.37991 19.0118 10.7309 20.4121 13.4693 22.5918L13.4788 22.5994L13.488 22.6074C14.3462 23.3616 15.7606 24.3657 17.422 25.1452C19.0872 25.9265 20.9587 26.4625 22.746 26.3337C23.9725 26.2454 24.9946 25.3381 25.745 24.1525L25.7856 24.0884C26.2328 23.3818 25.9552 22.3994 25.1718 21.9775C24.2357 21.4733 23.2004 20.8485 22.3136 20.1688C21.5381 19.5745 20.4309 19.5597 19.8383 20.1508L18.6331 21.353L18.3518 21.2741C16.5272 20.7626 12.3682 18.7087 9.81248 14.1164C9.67819 13.8751 9.76494 13.5707 10.0062 13.4364C10.2475 13.3021 10.552 13.3889 10.6863 13.6301C12.9588 17.7136 16.587 19.6467 18.3461 20.2269L19.1321 19.4428C20.1741 18.4034 21.8648 18.565 22.9219 19.3751C23.7517 20.011 24.737 20.6075 25.646 21.0971C26.8922 21.7682 27.4162 23.3819 26.6306 24.6232L26.59 24.6873C25.7734 25.9775 24.514 27.209 22.8179 27.3312C20.7958 27.4769 18.7463 26.8711 16.9973 26.0505C15.2503 25.2309 13.7616 24.1774 12.8371 23.3667C10.0408 21.1402 8.62782 19.6759 6.47733 16.7158C2.45166 12.1548 2.10314 8.41529 2.76577 6.20668C3.09425 5.11183 4.01863 4.42077 4.86042 3.94449L5.46299 3.60357Z"
        fill="black"
      />
    </svg>
  );
};

export default PhoneIcon;
