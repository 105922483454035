import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { CommissionStatementsStyles } from "./CommissionStatementsStyles";
import HeadingText from "../../common/HeadingText/HeadingText";
import { COMMISSION_STATEMENTS_CAPS, DOWNLOAD, FILTER } from "../../constants";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import { commissionStatemnetsHeadingsData } from "../../constants/CommisionStatemnetsHeaderData";
import CommissionStatementsList from "../CommissionStatementsList/CommissionStatementsList";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import {
  comissionStatementsData,
  commissionStatemnetsTableHeadingsData,
} from "../../constants/comissionStatementsData";
import CommissionCards from "../CommissionCards/CommissionCards";
const CommissionStatements: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width:1023px)");
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  return (
    <>
      <Box sx={CommissionStatementsStyles.container}>
        <Box sx={CommissionStatementsStyles.header}>
          <Box sx={CommissionStatementsStyles.headingContainer}>
            <HeadingText
              additionalHeadingClass={CommissionStatementsStyles.headingText}
              heading={COMMISSION_STATEMENTS_CAPS}
            />
            <Box sx={CommissionStatementsStyles.filterContainer}>
              <ImageAndText
                imageClassName={CommissionStatementsStyles.image}
                onhandleClick={handleClick}
                additionalClassName={
                  CommissionStatementsStyles.imageAndTextContainer
                }
                image={filterIcon}
                text={FILTER}
              />
              <Typography sx={CommissionStatementsStyles.line}>|</Typography>
              <ImageAndText
                imageClassName={CommissionStatementsStyles.image}
                additionalClassName={
                  CommissionStatementsStyles.imageAndTextContainer
                }
                image={downloadIcon}
                text={DOWNLOAD}
              />
            </Box>
          </Box>
          <Box sx={CommissionStatementsStyles.headingsContainer}>
            {commissionStatemnetsHeadingsData.map((data, index) => (
              <HeadingSubHeading
                containerAdditionalClassName={
                  CommissionStatementsStyles.headingSubheadingContainer
                }
                headingAdditionalClassName={CommissionStatementsStyles.headingContainerText}
                subHeadingAdditionalClassName={CommissionStatementsStyles.subheadingContainerText}
                key={index}
                heading={data.heading}
                subheading={data.subheading}
              />
            ))}
          </Box>
        </Box>
        {isSmallScreen ? (
          <CommissionCards comissionStatementsData={comissionStatementsData} />
        ) : (
          <CommissionStatementsList
            comissionStatementsData={comissionStatementsData}
            headerData={commissionStatemnetsTableHeadingsData}
          />
        )}
      </Box>
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default CommissionStatements;
