const DriversStyles = {
  headingAndButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px",
    "@media (max-width:600px)": {
      borderBottom: "1px solid rgb(217, 217, 217);",
    },
  },
  vehicleHeading: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    img: {
      width: "20px",
      height: "20px",
    },
  },
  HeadingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontHeight: "400",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  tableHead: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
    opacity: "70%",
    color: "rgba(33, 24, 20, 1)",
  },
  tableContainer: {
    padding: "0px 30px",
    width: "auto",
    border: "1px solid rgba(201, 225, 236, 1)",
    display: "block",
    "@media (max-width:599px)": {
      width: "100%",
      maxWidth: "320px",
      display: "none",
    },
  },
  rowOne: {
    width: "221px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowTwo: {
    width: "245px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowThree: {
    width: "173px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  rowFour: {
    width: "150px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  row: { height: "64px", fontFamily: "Noto Sans" },
  rowEmpty: {
    width: "471px",
    "@media (min-width:601px) and (max-width:690px)": { display: "none" },
  },

  addVehicleButton: {
    fontFamily: "Noto Sans",
    borderRadius: "40px",
    backgroundColor: "rgba(63, 63, 63, 1)",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
  },

  rowName: {
    width: "366px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowLicense: {
    width: "301px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowDriverType: {
    width: "240px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowGender: {
    width: "171px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },
  rowDob: {
    width: "198px",
    fontFamily: "Noto Sans",
    border: "0px 0px 1px 0px",
    opacity: "0px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
  },

  mobileCard: {
    display: "none",
    "@media (max-width:599px)": {
      display: "grid",
      gap: "12px",
      borderRadius: "10px",
      border: "1px solid rgba(217, 217, 217, 1)",
      padding: "12px",
      position: "relative",
    },
  },
  vin: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "17.57px",
    letterSpacing: "0.01em",
  },
  driverName: {
    display: "flex",
    gap: "10px",
    alignItems: "baseline",
    fontSize: "14px",
    fontFamily: "Noto Sans",
  },

  year: {
    width: "max-content",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
  },
  model: {
    width: "max-content",
    fontFamily: "Noto Sans",
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  type: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
    display: "flex",
  },
  vehicleType: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
    color: "rgba(101, 101, 101, 1)",
  },
  name: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "17.57px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  gender: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
    color: "rgba(101, 101, 101, 1)",
  },
  line: { marginTop: "6px", color: "rgba(101, 101, 101, 1)" },
  drivingLicense: {
    width: "auto",
    height: "13px",
    fontFamily: "Noto Sans",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "12.55px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  noDriversCard: {
    padding: "30px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  },
  noDriversText: {
    overflow: "hidden",
    color: "#1D252B",
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "400",
    lineHeight: "19.8px",
    textOverflow: "ellipsis",
    "@media (max-width:599px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "@media (min-width:600px) and (max-width:1366px)": {
      whiteSpace: "nowrap",
    },
  },
};

export default DriversStyles;
