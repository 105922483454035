const LookupStyle = {
  lookupCard: {
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  },
  submitBtn: {
    width: "155px",
    height: "40px",
    borderRadius: "40px",
    backgroundColor: "rgba(63, 63, 63, 1)",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
    color: "#ffffff",
    fontFamily: "Noto Sans",
    margin: "1rem",
    marginBottom: 0,
    ":hover": {
      backgroundColor: "rgba(63, 63, 63, 1)",
    },
  },
  resetBtn: {
    width: "155px",
    height: "40px",
    borderRadius: "40px",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
    color: "#000000",
    fontFamily: "Noto Sans",
    margin: "1rem",
    marginBottom: 0,
  },
  lSuccessIcon: {
    color: "green",
    marginRight: "1rem",
    marginTop: "1rem",
    alignItems: "center",
  },
  lSuccessMsg: {
    textAlign: "center",
    fontSize: "1rem",
    color: "green",
  },
  lFailMsg: {
    textAlign: "center",
    fontSize: "1rem",
    color: "red",
  },
};

export default LookupStyle;
