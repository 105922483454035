export const CustomerDetailsResponsiveStyles = {
  container: {
    padding: "24px 20px",
    display: "flex",
    flexDirection: "column",
    marginTop: "60px",
  },
  dropDown: {
    borderBottom: "1px solid var(--primary-grey)",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  componentRendererContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "23px",
  },
  dropDownContainer: {
    marginLeft: "0px",
  },
  selectText: {
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-16)",
  },
  menuItem:{
    fontFamily: "var(--font-playfair-display)",
    fontSize: "var(--font-size-16)",
  }
};
