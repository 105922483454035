import { createAsyncThunk } from "@reduxjs/toolkit";
import { CustomAction } from "../../customAction";
import { Api } from "../../..";
import {
  API_DELETE_FORM,
  API_GET_FORMS,
  API_GET_LOOKUP_VALUES,
  API_POST_FORM_JSON,
} from "../../../Apis/ApiEndPoints";
import {
  IGetFormsResponse,
  IGetFormTypesResponse,
  IAddFormRequestBody,
  IAddFormJsonResponse,
} from "../../../models/admin/Forms/Forms";

export const getFormTypes = createAsyncThunk(
  "forms/getFormTypes",
  async (_, { dispatch }) => {
    const response: IGetFormTypesResponse = await CustomAction(
      Api.get(`${API_GET_LOOKUP_VALUES}/formType`),
      dispatch,
      "from/getFormTypes"
    );
    return response;
  }
);

export const addForm = createAsyncThunk(
  "forms/addForm",
  async (body: IAddFormRequestBody, { dispatch }) => {
    const response: IAddFormJsonResponse = await CustomAction(
      Api.post(API_POST_FORM_JSON, body),
      dispatch,
      "forms/addForm"
    );
    return response;
  }
);

export const getForms = createAsyncThunk(
  "forms/getForms",
  async (_, { dispatch }) => {
    const response: IGetFormsResponse = await CustomAction(
      Api.get(API_GET_FORMS),
      dispatch,
      "forms/getForms"
    );
    return response;
  }
);

export const deleteForm = createAsyncThunk(
  "forms/deleteForm",
  async (formCode: string, { dispatch }) => {
    const response = await CustomAction(
      Api.delete(`${API_DELETE_FORM}/${formCode}`),
      dispatch,
      "forms/deleteForm"
    );
    return response;
  }
);
