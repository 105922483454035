export const DateComponentStyles = {
  dot: {
    height: 5,
    width: 5,
    backgroundColor: "var(--secondary-red)",
    borderRadius: "50%",
  },
  todayButton: {
    position: "absolute",
    left: "370px",
    fontFamily: "var(--font-noto-sans)",
    padding: "4px 23px",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    backgroundColor: "var(--white)",
    color: "var(--primary-black)",
    borderRadius: "8px",
    border: "1px solid var(--primary-black)",
    lineHeight: "normal",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "var(--white)",
      boxShadow: "none",
      color: "var(--primary-black)",
    },
    "@media (max-width: 1200px)": {
      left: "54vw",
      top: "12px",
      padding: "4px 15px",
    },
    "@media (max-width: 1023px)": {
      left: "51vw",
    },
    "@media (max-width: 599px)": {
      left: "46vw",
    },
  },
  dotContainer: {
    display: "flex",
    gap: "10px",
  },
  todayButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3px",
  },
};
