import React from "react";
import { Props } from "./Props";

const NotificationIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 59 61"
      fill="none"
    >
      <circle cx="32.3701" cy="16.2041" r="9.59863" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3412 11.3939C17.1722 12.3695 14.5206 14.564 12.9696 17.4947C11.4186 20.4253 11.0954 23.8521 12.071 27.0211C14.1093 33.642 13.9907 38.2999 13.3847 41.2788C13.0814 42.7697 12.6549 43.8456 12.3103 44.5417C12.1379 44.8899 11.9857 45.1435 11.8794 45.3067C11.8262 45.3884 11.7844 45.4474 11.7573 45.4845C11.7438 45.503 11.7339 45.516 11.728 45.5235L11.723 45.5299L11.7219 45.5313C11.5838 45.702 11.573 45.943 11.6958 46.1252C11.8188 46.308 12.047 46.3886 12.2576 46.3238L46.6702 35.7297C46.8808 35.6649 47.0241 35.4698 47.0231 35.2495C47.022 35.0294 46.8772 34.8359 46.6664 34.7728L46.658 34.7701L46.6382 34.7635L46.6118 34.7543C46.5685 34.7389 46.5008 34.7136 46.4109 34.676C46.2312 34.6008 45.9627 34.4767 45.6243 34.2857C44.9479 33.904 43.9901 33.2542 42.9009 32.1921C40.7244 30.0697 38.0067 26.285 35.9685 19.6641C34.9929 16.4951 32.7983 13.8435 29.8677 12.2925C26.937 10.7415 23.5102 10.4183 20.3412 11.3939ZM45.1329 35.1566L13.2064 44.9854C13.5877 44.2153 14.0433 43.0574 14.3646 41.4781C15.0078 38.3167 15.1084 33.4886 13.0267 26.7269C12.1292 23.8113 12.4266 20.6587 13.8535 17.9624C15.2804 15.2662 17.7199 13.2472 20.6355 12.3496C23.551 11.4521 26.7037 11.7494 29.3999 13.1764C32.0962 14.6033 34.1152 17.0428 35.0127 19.9583C37.0944 26.7201 39.8929 30.6557 42.2027 32.908C43.3566 34.0332 44.3845 34.7343 45.1329 35.1566Z"
        fill="#262729"
      />
      <path
        d="M28.7037 48.8468C28.501 48.6592 28.1847 48.6716 27.9971 48.8743C27.8096 49.077 27.8219 49.3933 28.0246 49.5808C28.6034 50.1163 29.3127 50.4902 30.0815 50.6653C30.8503 50.8404 31.6516 50.8105 32.4052 50.5785C33.1588 50.3465 33.8382 49.9206 34.3754 49.3434C34.9126 48.7663 35.2888 48.0581 35.4662 47.2899C35.5283 47.0208 35.3606 46.7523 35.0916 46.6902C34.8225 46.6281 34.554 46.7958 34.4919 47.0648C34.3538 47.6624 34.0612 48.2132 33.6434 48.6621C33.2255 49.111 32.6971 49.4423 32.111 49.6227C31.5248 49.8032 30.9016 49.8265 30.3036 49.6903C29.7056 49.5541 29.1539 49.2632 28.7037 48.8468Z"
        fill="#262729"
      />
    </svg>
  );
};

export default NotificationIcon;
