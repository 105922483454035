import React from "react";
import { ClaimTrackingCardStyles } from "./ClaimTrackingCardStyles"
import { Box, Typography } from "@mui/material";


interface ClaimTrackingCardProps {
    title:string;
    subTitle:string;
}

const ClaimTrackingCard:  React.FC<ClaimTrackingCardProps> = ({
    title,
    subTitle,
}) => {
    return(
        <Box sx={ClaimTrackingCardStyles.trackingContainer}>
            <Typography sx={ClaimTrackingCardStyles.title}>{title}</Typography>
            <Typography sx={ClaimTrackingCardStyles.subTitle}>{subTitle}</Typography>
        </Box>
    )
}

export default ClaimTrackingCard;