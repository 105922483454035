export const DocumentsStyles = {
  card: {
    display: "inline-flex",
    width: "100%",
    gap: "20px",
    borderRadius: "10px",
    marginTop: "22px",
    padding: "30px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
    "@media only screen and (max-width: 768px)": {
      display: "grid",
    },
  },
  cardContentBox: {
    display: "inline-flex",
    width: "70%",
    "@media only screen and (max-width: 768px)": {
      width: "100%",
    },
  },
};
