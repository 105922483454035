import { Box, Typography } from "@mui/material";
import React from "react";
import HeadingSubHeading from "../HeadingSubHeading/HeadingSubHeading";
import activeIcon from "../../assets/svgs/active-icon.svg";
import pendingIcon from "../../assets/svgs/pending-icon.svg";
import {
  ACTIONS,
  MENU_ICON,
  NEXT_PAYMENT,
  PAY_NOW,
  PREMIUM,
  STATUS,
  TERMS,
} from "../../constants";
import ImageAndText from "../ImageAndText/ImageAndText";
import { customerDetailsOverviewPolicyListing } from "../../constants/customerDetailsOverviewPolicyListing";
import { OverviewPolicyListingCardStyles } from "./OverviewPolicyListingCardStyles";
import payNow from "../../assets/svgs/paynow-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import CustomIcon from "../CustomIcon/CustomIcon";
import Drawers from "../Drawers/Drawers";
import HeadingText from "../HeadingText/HeadingText";
import { customerDetailsOverviewOptions } from "../../constants/CustomerDetailsOverviewOptions";
import { useNavigate } from "react-router-dom";
interface OverviewPolicyListingCardProps {
  policyType: string;
  policyNumber: string;
  policyTypeNumber: string;
  policyStatus: string;
  policyPremium: string;
  policyTerm: string;
  policyIdNo: string;
  policyLastPayment: string;
}
const OverviewPolicyListingCard: React.FC<OverviewPolicyListingCardProps> = ({
  policyType,
  policyNumber,
  policyTypeNumber,
  policyStatus,
  policyPremium,
  policyTerm,
  policyIdNo,
  policyLastPayment,
}) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const renderPolicyType = (policyType: string) => {
    let policyTypeIcon = "";
    if (policyType.toLowerCase() === "house") {
      policyTypeIcon = "House";
    }
    return <CustomIcon width="38px" height="38px" iconName={policyTypeIcon} />;
  };
  const renderStatus = (policyStatus: string) => {
    let statusImage = "";
    let statusText = "";
    if (policyStatus.toLowerCase() === "active") {
      statusImage = activeIcon;
      statusText = "Active";
    } else if (policyStatus.toLowerCase() === "pending") {
      statusImage = pendingIcon;
      statusText = "Pending Renewal";
    }
    return (
      <ImageAndText
        additionalClassText={OverviewPolicyListingCardStyles.headingText}
        additionalClassName={
          OverviewPolicyListingCardStyles.imageAndTextContainer
        }
        imageClassName={
          OverviewPolicyListingCardStyles.imageAndTextContainerImage
        }
        image={statusImage}
        text={statusText}
      />
    );
  };

  const handleDrawerOptionClick = (key: string) => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
      });
    }, 100);
    if (key === "sendRemainder") {
      navigate("/remainder");
    }
    if (key === "viewDetails") {
      navigate("/policy-details");
    }
  };
  const overviewPolicyIdNumber =
    customerDetailsOverviewPolicyListing[0].policyIdNo;
  return (
    <>
      <Box sx={OverviewPolicyListingCardStyles.container}>
        <Box sx={OverviewPolicyListingCardStyles.headerContainer}>
          <Box>{renderPolicyType(policyType)}</Box>
          <HeadingSubHeading
            headingAdditionalClassName={
              OverviewPolicyListingCardStyles.headingPolicyTypeText
            }
            subHeadingAdditionalClassName={
              OverviewPolicyListingCardStyles.subHeadingHeadingText
            }
            heading={policyNumber}
            subheading={policyTypeNumber}
            containerAdditionalClassName={
              OverviewPolicyListingCardStyles.headingsPolicyContainer
            }
          />
        </Box>
        <Box sx={OverviewPolicyListingCardStyles.innerContainer}>
          <Box sx={OverviewPolicyListingCardStyles.statusContainer}>
            {renderStatus(policyStatus)}
            <Typography sx={OverviewPolicyListingCardStyles.subHeadingText}>
              {STATUS}
            </Typography>
          </Box>

          <HeadingSubHeading
            headingAdditionalClassName={
              OverviewPolicyListingCardStyles.headingText
            }
            subHeadingAdditionalClassName={
              OverviewPolicyListingCardStyles.subHeadingText
            }
            containerAdditionalClassName={
              OverviewPolicyListingCardStyles.headingsContainer
            }
            heading={policyTerm}
            subheading={TERMS}
          />
          <HeadingSubHeading
            heading={policyLastPayment}
            subheading={NEXT_PAYMENT}
            headingAdditionalClassName={
              OverviewPolicyListingCardStyles.headingText
            }
            containerAdditionalClassName={
              OverviewPolicyListingCardStyles.headingsContainer
            }
            subHeadingAdditionalClassName={
              OverviewPolicyListingCardStyles.subHeadingText
            }
          />

          <HeadingSubHeading
            subHeadingAdditionalClassName={
              OverviewPolicyListingCardStyles.subHeadingText
            }
            headingAdditionalClassName={
              OverviewPolicyListingCardStyles.headingText
            }
            containerAdditionalClassName={
              OverviewPolicyListingCardStyles.headingsContainer
            }
            heading={policyPremium}
            subheading={PREMIUM}
          />
          <HeadingSubHeading
            heading={policyIdNo}
            headingAdditionalClassName={
              OverviewPolicyListingCardStyles.headingText
            }
            containerAdditionalClassName={
              OverviewPolicyListingCardStyles.headingsContainer
            }
            subHeadingAdditionalClassName={
              OverviewPolicyListingCardStyles.subHeadingPolicyText
            }
            subheading={overviewPolicyIdNumber}
          />
        </Box>
        <Box sx={OverviewPolicyListingCardStyles.cardFooter}>
          <ImageAndText
            additionalClassName={
              OverviewPolicyListingCardStyles.imageAndTextContainer
            }
            image={payNow}
            text={PAY_NOW}
          />
          <Box
            sx={OverviewPolicyListingCardStyles.menuIcon}
            onClick={handleDrawer}
          >
            <img src={menuIcon} alt={MENU_ICON} />
          </Box>
        </Box>
      </Box>
      <Drawers
        type="secondary"
        anchor="bottom"
        open={drawerOpen}
        additionalClass={OverviewPolicyListingCardStyles.drawer}
        toggleDrawer={handleDrawer}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={
              OverviewPolicyListingCardStyles.drawertextHeading
            }
            heading={ACTIONS}
          />
          <Box sx={OverviewPolicyListingCardStyles.optionsContainer}>
            {customerDetailsOverviewOptions.map((option, index) => (
              <Typography
                key={option.key}
                sx={
                  index === customerDetailsOverviewOptions.length - 1
                    ? OverviewPolicyListingCardStyles.lastText
                    : OverviewPolicyListingCardStyles.drawerTexts
                }
                onClick={() => handleDrawerOptionClick(option.key)}
              >
                {option.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Drawers>
    </>
  );
};

export default OverviewPolicyListingCard;
