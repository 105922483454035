const LoginStyles = {
  login: {
    width: "100%",
    minHeight: "100vh",
    display: "grid",
    gridTemplateColumns: "60% 40%",
    overflow: "hidden",
    "@media (max-width:599px)": {
      gridTemplateColumns: "100%",
      background:
        "linear-gradient(180deg, #FBFDFC 0%, #E5F6FE 100%),linear-gradient(180deg, #FBFDFC 0%, #B2E6FF 139.93%),linear-gradient(1.4deg, #BCC9CF -3.2%, #FFFFFF 32.82%)",
    },
  },
  loginImage: {
    height: "100%",
    width: "100%",
    position: "relative",
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
    "@media (max-width:599px)": {
      display: "none",
    },
  },
  loginCardContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginCard: {},
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    img: {
      maxWidth: "150px",
      maxHeight: "150px",
      objectFit: "contain",
    },
  },
  loginMessage: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "22px",
    color: "rgba(56, 61, 78, 1)",
  },
  loginName: {
    width: "100%",
    height: "54px",
    borderRadius: "4px",
    label: {
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.4px",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "dark gray",
    },
    input: {
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.4px",
      textAlign: "left",
    },
    "@media (max-width:699px)": {
      width: "319px",
      height: "56px",
      margin: "auto",
    },
    "@media (min-width:700px) and (max-width:1024px)": {
      width: "579px",
      height: "56px",
      margin: "auto",
    },
  },
  loginNameText: {
    height: "22.4px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  loginPassword: {
    width: "100%",
    height: "54px",
    borderRadius: "4px",
    input: {
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "dark gray",
    },
    "@media (max-width:699px)": {
      width: "319px",
      height: "56px",
      margin: "auto",
    },
    "@media (min-width:700px) and (max-width:1024px)": {
      width: "579px",
      height: "56px",
      margin: "auto",
    },
  },
  checkBoxPassword: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
    "@media (max-width:699px)": {
      width: "319px",
      height: "20px",
      margin: "auto",
      marginTop: "18px",
    },
    "@media (min-width:700px) and (max-width:1024px)": {
      width: "579px",
      height: "20px",
      margin: "auto",
      marginTop: "18px",
    },
  },
  checkBoxLabel: {
    display: "flex",
    alignItems: "center",
  },
  checkBox: {
    color: "black",
    width: "20px",
    height: "20px",
  },
  label: {
    width: "auto",
    height: "15px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    textAlign: "left",
    color: "rgba(63, 63, 63, 1)",
    marginLeft: "-20.5px",
  },
  password: {
    width: "auto",
    height: "16px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
    textAlign: "right",
    color: "rgba(65, 101, 177, 1)",
    cursor: "pointer",
  },
  sendOtp: {
    width: "100%",
    borderRadius: "40px",
    background: "rgba(63, 63, 63, 1)",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63, 0.3)",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "400",
    padding: "10px",
  },
  registerAccount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "114.36px",
    backgroundColor: "transparent",
    "@media (max-width:699px)": {
      width: "277px",
      height: "15px",
      margin: "auto",
      marginTop: "43.34px",
      backgroundColor: "transparent",
    },
    "@media (min-width:700px) and (max-width:1024px)": {
      width: "517px",
      height: "15px",
      margin: "auto",
      marginTop: "43.34px",
    },
  },
  submitAction: {
    "@media (max-width:699px)": {
      margin: "auto",
      width: "320px",
    },
    "@media (min-width:700px) and (max-width:1024px)": {
      margin: "auto",
      width: "580px",
    },
  },
  loginFields: {
    display: "flex",
    flexDirection: "column",
    rowGap: "25px",
    marginTop: "30px",
  },
  account: {
    width: "163px",
    height: "15px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    textAlign: "left",
  },
  register: {
    width: "91px",
    height: "15px",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "15.4px",
    textAlign: "left",
    color: "rgba(65, 101, 177, 1)",
    cursor: "pointer",
  },
  registerAccountTexts: {
    display: "flex",
    gap: "2px",
  },
  forword: {
    img: {
      width: "24px",
      height: "24px",
      marginTop: "5px",
      cursor: "pointer",
    },
  },
};

export default LoginStyles;
