import { createSlice } from "@reduxjs/toolkit";
import { IDashboardSlice } from "../../models/user/dashboard/Dashboard";

import {
  GetPoliciesData,
  getClaimsData,
  GetClaimTransactions,
  GetDownloadIdCard,
  GetClaimTransactionDocumentContent,
} from "./DashboardAction";

const initialState: IDashboardSlice = {
  policiesData: null,
  claimsData: null,
  claimTransactions: [],
  claimDocuments: [],
  claimTransactionDocumentContent: null,
  downloadIdCardContent: null,
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setClaimsData: (state, { payload }) => {
      state.claimsData = payload;
    },
    setClaimTransactionDocumentContent: (state, { payload }) => {
      state.claimTransactionDocumentContent = payload;
    },
    setDownloadIdCardContent: (state, { payload }) => {
      state.downloadIdCardContent = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetPoliciesData.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        state.policiesData = payload.data;
      }
    });

    builder.addCase(getClaimsData.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        state.claimsData = payload.data;
      }
    });
    builder.addCase(GetClaimTransactions.fulfilled, (state, { payload }) => {
      if (payload.status === "SUCCESS") {
        const { claimTransactionHistory, claimDocuments } = payload.data;
        state.claimTransactions = claimTransactionHistory || [];
        state.claimDocuments = claimDocuments || [];
      } else {
        state.claimTransactions = [];
        state.claimDocuments = [];
      }
    });
    builder.addCase(
      GetClaimTransactionDocumentContent.fulfilled,
      (state, { payload }) => {
        state.claimTransactionDocumentContent = payload;
      }
    );
    builder.addCase(GetDownloadIdCard.fulfilled, (state, { payload }) => {
      state.downloadIdCardContent =
        payload && payload.status !== "FAIL" ? payload : null;
    });
  },
});

export default DashboardSlice.reducer;
export const {
  setClaimsData,
  setClaimTransactionDocumentContent,
  setDownloadIdCardContent,
} = DashboardSlice.actions;
