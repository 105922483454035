import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CustomerDetailsHeaderStyles } from "./CustomerDetailsHeaderStyles";
import ImageAndText from "../ImageAndText/ImageAndText";
import clockIcon from "../../assets/svgs/clock-icon-1.svg";
import phoneIcon from "../../assets/svgs/phone-icon.svg";
import mailIcon from "../../assets/svgs/mail-icon.svg";
import CustomButton from "../CustomButton/CustomButton";
import {
  ADD_A_TASK,
  CUSTOMER_ID,
  CUSTOMER_NAME,
  FILE_A_CLAIM,
  LOGO,
  VIEW_MORE,
  VIEW_LESS,
} from "../../constants";
import viewMoreIcon from "../../assets/svgs/view-more-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import { useNavigate } from "react-router-dom";
import DashboardBreadCrumb from "../DashboardBreadCrumb/DashboardBreadCrumb";
import { customerDetailsHeaderPopUpDetails } from "../../constants/CustomerDetailsHeaderPopUpDetails";
import FilterPopUp from "../FilterPopUp/FilterPopUp";

interface CustomerDetailsCardProps {
  data: {
    policyDetails: { heading: string; subheading: string }[];
    claimDetails: { heading: string; subheading: string }[];
    contactDetails: { image: string; text: string }[];
    interactionDetail: { text: string };
  };
}

const CustomerDetailsHeader: React.FC<CustomerDetailsCardProps> = ({
  data,
}) => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(
    customerDetailsHeaderPopUpDetails[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isViewMore, setIsViewMore] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isSmallScreen = useMediaQuery("(max-width:1023px)");

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    if (key === "market") {
      if (isSmallScreen) {
        navigate("/market");
      }
    }
    if (key === "addNotes") {
      if (isSmallScreen) {
        navigate("/notes");
      }
    }
    handleClose();
  };

  const handleViewMoreToggle = () => {
    setIsViewMore((prev) => !prev);
  };

  return (
    <>
      <Box sx={CustomerDetailsHeaderStyles.container}>
        <Box sx={CustomerDetailsHeaderStyles.policyDetailsContainer}>
          <Box sx={CustomerDetailsHeaderStyles.policyDetails}>
            <Box sx={CustomerDetailsHeaderStyles.policyNumber}>
              <DashboardBreadCrumb
                handleClick={() => navigate(-1)}
                additionalContentClass={
                  CustomerDetailsHeaderStyles.customerName
                }
                variant={"primary"}
                text={CUSTOMER_NAME}
              />
            </Box>
            <Box sx={CustomerDetailsHeaderStyles.policyType}>
              <Typography sx={CustomerDetailsHeaderStyles.policyTypeText}>
                {CUSTOMER_ID}
              </Typography>
              <Box
                onClick={handleClick}
                sx={CustomerDetailsHeaderStyles.menuIcon}
              >
                <img src={menuIcon} alt={LOGO} />
              </Box>
            </Box>
          </Box>
          <Box sx={CustomerDetailsHeaderStyles.separator}></Box>
          <Box sx={CustomerDetailsHeaderStyles.buttonContainer}>
            <CustomButton
              text={ADD_A_TASK}
              onClick={() => navigate("/new-task")}
              additionalClassName={CustomerDetailsHeaderStyles.addTaskButton}
            />
          </Box>
        </Box>
        <Box sx={CustomerDetailsHeaderStyles.cardContainer}>
          <Box sx={CustomerDetailsHeaderStyles.customerDetails}>
            <ImageAndText
              additionalClassName={
                CustomerDetailsHeaderStyles.customerDetailsImageContainer
              }
              image={phoneIcon}
              text={data.contactDetails[0].text}
            />
            <ImageAndText
              additionalClassName={
                CustomerDetailsHeaderStyles.customerDetailsImageContainer
              }
              image={mailIcon}
              text={data.contactDetails[1].text}
            />
          </Box>
          <Box sx={CustomerDetailsHeaderStyles.separator}></Box>
          <Box sx={CustomerDetailsHeaderStyles.interactionContainer}>
            <ImageAndText
              image={clockIcon}
              text={`Last Interaction ${data.interactionDetail.text}`}
              additionalClassText={
                CustomerDetailsHeaderStyles.interactionDetail
              }
              imageClassName={CustomerDetailsHeaderStyles.interactionImage}
              additionalClassName={CustomerDetailsHeaderStyles.interaction}
            />
          </Box>
          {isViewMore && (
            <Box>
              <Box sx={CustomerDetailsHeaderStyles.cutomerPoliciesContainer}>
                {data.policyDetails.map((detail, index) => (
                  <Box
                    key={index}
                    sx={CustomerDetailsHeaderStyles.numberTextPolicyContainer}
                  >
                    <Typography
                      sx={CustomerDetailsHeaderStyles.policyDataNumber}
                    >
                      {detail.heading}
                    </Typography>
                    <Typography sx={CustomerDetailsHeaderStyles.policyDataText}>
                      {detail.subheading}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box sx={CustomerDetailsHeaderStyles.separator}></Box>
              <Box sx={CustomerDetailsHeaderStyles.cutomerClaimsContainer}>
                {data.claimDetails.map((detail, index) => (
                  <Box
                    key={index}
                    sx={CustomerDetailsHeaderStyles.numberTextClaimContainer}
                  >
                    <Typography
                      sx={CustomerDetailsHeaderStyles.claimDataNumber}
                    >
                      {detail.heading}
                    </Typography>
                    <Typography sx={CustomerDetailsHeaderStyles.claimDataText}>
                      {detail.subheading}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
          <Box sx={CustomerDetailsHeaderStyles.buttonsContainer}>
            <Box
              sx={CustomerDetailsHeaderStyles.viewMoreContainer}
              onClick={handleViewMoreToggle}
            >
              <Typography sx={CustomerDetailsHeaderStyles.viewMoreText}>
                {isViewMore ? VIEW_LESS : VIEW_MORE}
              </Typography>
              <Box
                sx={{
                  ...CustomerDetailsHeaderStyles.rotateIcon,
                  ...(isViewMore && CustomerDetailsHeaderStyles.rotated),
                }}
              >
                <img src={viewMoreIcon} alt={LOGO} />
              </Box>
            </Box>
            <CustomButton
              type="outlined"
              text={FILE_A_CLAIM}
              additionalClassName={CustomerDetailsHeaderStyles.button}
            />
          </Box>
        </Box>
      </Box>

      <FilterPopUp
        options={customerDetailsHeaderPopUpDetails}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default CustomerDetailsHeader;
