export const MyTasksCalendarCardStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginTop: "16px",
  },
  statusContainer: {
    gap: "4px",
  },
  priorityContainer: {
    gap: "4px",
  },
  heading: {
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "16.34px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
  },
  subheading: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
  },
  headingContainer: {
    gap: "2px",
  },
  priorityHeadingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  lowerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  clientDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
};
