import { Box } from "@mui/material";
import React from "react";
import MonthlyStatementCard from "../../common/MonthlyStatementCard/MonthlyStatementCard";
import { MonthlyStatementResponsiveListingStyles } from "./MonthlyStatementResponsiveListingStyles";
import { MonthlyCommissionTableDataProps } from "../MonthlyCommissionTable/MonthlyCommissionTable";
interface MonthlyStatementResponsiveListingProps {
  comissionMonthlyStatementsData: MonthlyCommissionTableDataProps[];
}
const MonthlyStatementResponsiveListing: React.FC<
  MonthlyStatementResponsiveListingProps
> = ({ comissionMonthlyStatementsData }) => {
  return (
    <Box sx={MonthlyStatementResponsiveListingStyles.monthlyStatementContainer}>
      <Box sx={MonthlyStatementResponsiveListingStyles.cardContainer}>
        <Box sx={MonthlyStatementResponsiveListingStyles.container}>
          {comissionMonthlyStatementsData.map((data) => (
            <MonthlyStatementCard
              commsissionDate={data.commissionDate ?? ""}
              commissionTransactionType={data.commissionTransactionType ?? ""}
              commisionPolicyDetails={data.commissionPolicyDetails ?? ""}
              commissionCustomerDetails={data.commissionCustomerDetails ?? ""}
              commissionMonthlyEarned={data.comissionMonthlyEarned ?? ""}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default MonthlyStatementResponsiveListing;
