import React from "react";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box, SxProps } from "@mui/material";
import styled from "@emotion/styled";
import { Theme } from "@emotion/react";

interface CustomTooltipProps {
  menuIcon: string;
  MENU_ICON: string;
  myTasksTooltip: { key: string; value: string }[];
  placement?: "top" | "bottom" | "left" | "right";
  handleTooltipItem: (key: string) => void;
  open: boolean;
  handleTooltip: () => void;
  additionalClassOfMenuIcon?: SxProps<Theme>;
}
export const StyledListItemText = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
});
const CustomTooltip: React.FC<CustomTooltipProps> = ({
  menuIcon,
  MENU_ICON,
  myTasksTooltip,
  placement = "right",
  handleTooltipItem,
  open,
  handleTooltip,
  additionalClassOfMenuIcon,
}) => {
  return (
    <>
      <ClickAwayListener onClickAway={handleTooltip}>
        <div>
          <Tooltip
            title={
              <List>
                {myTasksTooltip.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => handleTooltipItem(item.key)}
                  >
                    <StyledListItemText> {item.value}</StyledListItemText>
                  </ListItem>
                ))}
              </List>
            }
            arrow
            open={open}
            onClose={handleTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement={placement}
            componentsProps={{
              tooltip: {
                sx: {
                  background: "var( --white)",
                  border: "1px solid #CFD4E1",
                  color: "var( --black)",
                },
              },
              arrow: {
                sx: {
                  color: "var( --white)",
                  position: "relative",
                  "&::before": {
                    border: "1px solid #CFD4E1",
                    margin: "-1px",
                  },
                },
              },
            }}
          >
            <Box sx={additionalClassOfMenuIcon} onClick={handleTooltip}>
              <img src={menuIcon} alt={MENU_ICON} />
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default CustomTooltip;
