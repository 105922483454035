export const PolicyListingListStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  headerMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingRightContainer: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
  addTaskButton: {
    boxShadow: "none",
    padding: "9px 22px",
    "@media screen and (max-width: 1023px)": {
      display: "none",
    },
  },
  header: {
    display: "flex",
    width: "100%",
    backgroundColor: "var(--white)",
    border: "1px solid var(--shaded-cyan )",
    borderRadius: "8px",
    padding: "20px",
    gap: "40px",
  },
  headingSubheadingContainer: {
    minWidth: "150px",
    gap: "4px",
  },
  headingText: {
    fontSize: "var(--font-size-20)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 421px)",
  },
};
