import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { Box, Button, Typography } from "@mui/material";
import { DateComponentStyles } from "./DateComponentStyles";
import { LEFT_ARROW, RIGHT_ARROW, TODAY } from "../../constants";
import CustomLeftArrow from "../../assets/svgs/left-arrow.svg";
import CustomRightArrow from "../../assets/svgs/right-arrow.svg";
import styled from "@emotion/styled";

interface ServerDayProps extends PickersDayProps<Dayjs> {
  highlightedDays?: Dayjs[];
}
const StyledDateCalendar = styled(DateCalendar)({
  margin: "0px",
  width: "530px",
  height: "100%",
  position: "relative",
  minHeight: "473px",
  "@media (max-width: 1200px)": {
    width: "470px",
    backgroundColor: "var(--white)",
    margin: "0 auto",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "10px",
    minHeight: "480px",
  },
  "& .MuiPickersArrowSwitcher-spacer": {
    "@media (max-width: 1023px)": {
      width: "12px",
    },
  },
  "@media (max-width: 1023px)": {
    width: "420px",
    minHeight: "360px",
  },
  "@media (max-width: 599px)": {
    width: "320px",
  },
  "& .Mui-selected":{
    backgroundColor: "var(--primary-black) !important", 
  },
  "& .MuiBadge-root": {
    marginLeft: "0px !important",
  },
  "& .MuiYearCalendar-root": {
    width: "100%",
    maxHeight: "443px",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": { display: "none" },
    "@media (max-width: 1023px)": {
      maxHeight: "280px",
    },
  },
  "& .MuiDayCalendar-header": {
    justifyContent: "space-around",
    "@media (max-width: 1023px)": {
      justifyContent: "space-between",
      marginTop: "10px",
    },
  },
  "& .MuiPickersCalendarHeader-root": {
    marginTop: "16px",
    borderBottom: "1px solid var(--primary-grey)",
    paddingBottom: "20px",
    marginBottom: "12px",
    "@media (max-width: 1023px)": {
      borderBottom: "1px solid var(--primary-grey)",
      padding: "28px 16px 28px 16px ",
      margin: "0px",
    },
  },
  "& .MuiDayCalendar-weekContainer ": {
    justifyContent: "space-around",
    "@media (max-width: 1023px)": {
      justifyContent: "space-between",
    },
  },
  "& .MuiDayCalendar-slideTransition": {
    minHeight: "375px",
    "@media (max-width: 1023px)": {
      minHeight: "217px",
    },
  },
  "& .MuiDayCalendar-monthContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    height: "372px",
    "@media (max-width: 1023px)": {
      gap: "2px",
      height: "auto",
    },
  },
  "& .MuiPickersArrowSwitcher-button": {
    padding: "0px",
    marginLeft: "0px",
  },
  "& .MuiPickersCalendarHeader-label ": {
    fontSize: "var(--font-size-20)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
    fontFamily: "var(--font-noto-sans)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
});
const StyledBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    top: "83%",
    right: "51%",
  },
});
const ServerDay = (props: ServerDayProps) => {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !outsideCurrentMonth &&
    highlightedDays.some((highlightedDay) => highlightedDay.isSame(day, "day"));

  const count = highlightedDays.filter((highlightedDay) =>
    highlightedDay.isSame(day, "day")
  ).length;

  return (
    <StyledBadge
      overlap="circular"
      badgeContent={
        isSelected ? (
          <Box sx={DateComponentStyles.dotContainer}>
            {Array(count)
              .fill(null)
              .map((_, index) => (
                <Typography
                  key={index}
                  sx={DateComponentStyles.dot}
                ></Typography>
              ))}
          </Box>
        ) : undefined
      }
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </StyledBadge>
  );
};

interface DateComponentProps {
  highlightedDays: Dayjs[];
}

const DateComponent: React.FC<DateComponentProps> = ({ highlightedDays }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState<Dayjs>(dayjs());

  const handleMonthChange = () => {
    setIsLoading(true);
    setIsLoading(false);
  };
  const handleTodayClick = () => {
    setCurrentDate(dayjs());
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledDateCalendar
          value={currentDate}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay as React.ComponentType<PickersDayProps<unknown>>,
            leftArrowIcon: () => <img src={CustomLeftArrow} alt={LEFT_ARROW} />,
            rightArrowIcon: () => (
              <img src={CustomRightArrow} alt={RIGHT_ARROW} />
            ),
          }}
          slotProps={{
            day: {
              highlightedDays,
            } as any,
          }}
        />
      </LocalizationProvider>
      <Box sx={DateComponentStyles.todayButtonContainer}>
        <Button
          variant="contained"
          onClick={handleTodayClick}
          sx={DateComponentStyles.todayButton}
        >
          {TODAY}
        </Button>
      </Box>
    </>
  );
};

export default DateComponent;
