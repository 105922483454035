import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import DropDown from "../../common/DropDown/DropDown";

import ImageAndText from "../../common/ImageAndText/ImageAndText";
import dragIcon from "../../assets/svgs/drag-icon.svg";
import eyeOff from "../../assets/svgs/eye-off.svg";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import maximizeIcon from "../../assets/svgs/maximize-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import viewTaskIcon from "../../assets/svgs/view-tasks-icon.svg";
import {
  ALL_POLICIES,
  CARD_FOOTER_TEXT,
  DOWNLOAD_ICON,
  DOWNLOAD_WIDGET,
  DRAG_ICON,
  EYE_ICON,
  HIDE_WIDGET,
  MAXIMIZE_ICON,
  MENU_ICON,
  VIEW_TASKS,
} from "../../constants";
import Drawers from "../../common/Drawers/Drawers";
import HeadingText from "../../common/HeadingText/HeadingText";
import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DashboardDraggableCardStyles } from "./DashboardDraggableCardStyles";
import CustomIcon from "../../common/CustomIcon/CustomIcon";
import { useNavigate } from "react-router-dom";
interface DashboardCardProps {
  heading: string;
  children: React.ReactNode;
  dropDownData?: { name: string; value: number | string }[];
  placeholder?: string;
  id: string;
  index: number;
  viewTask: boolean;
  showFooter?: boolean;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}
const DashboardDraggableCard: React.FC<DashboardCardProps> = ({
  heading,
  children,
  dropDownData,
  id,
  index,
  viewTask,
  showFooter = true,
  placeholder,
  moveCard,
}) => {
  const ref = useRef(null);

  const [, drag] = useDrag({
    type: "CARD",
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: "CARD",
    hover: (draggedItem: any) => {
      if (draggedItem.index !== index) {
        moveCard(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  drag(drop(ref));
  const [selectedAge, setSelectedAge] = React.useState<string>("");
  const [hovered, setHovered] = React.useState<boolean>(false);
  const [selectedHeading, setSelectedHeading] = React.useState<string>("");
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");

  const navigate = useNavigate();
  const handleDropdownChange = (event: SelectChangeEvent) => {
    setSelectedAge(event.target.value);
  };
  const handleMenuIcon = () => {
    setSelectedHeading(heading);
    setDrawerOpen(!drawerOpen);
  };
  const handleToggeleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleNavigationClick = () => {
    navigate("/mytasks");
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <>
      <Box
        sx={DashboardDraggableCardStyles.dashboardCard}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        ref={ref}
      >
        <Box sx={DashboardDraggableCardStyles.dashboardCardHeader}>
          <Box sx={DashboardDraggableCardStyles.dashboardHeaderMenu}>
            <Typography sx={DashboardDraggableCardStyles.dashboardCardHeading}>
              {heading}
            </Typography>
            <Box
              sx={DashboardDraggableCardStyles.menuIcon}
              onClick={handleMenuIcon}
            >
              <img src={menuIcon} alt={MENU_ICON} />
            </Box>
          </Box>
          {dropDownData && dropDownData.length > 0 && (
            <DropDown
              data={dropDownData}
              placeholder={placeholder ?? ALL_POLICIES}
              selectedValue={selectedAge}
              onChange={handleDropdownChange}
              primary={true}
              additionalClassName={DashboardDraggableCardStyles.dropdownClass}
            />
          )}
        </Box>
        <Box sx={DashboardDraggableCardStyles.dashboardCardContent}>
          {children}
        </Box>
        {showFooter && (
          <Box sx={DashboardDraggableCardStyles.dashboardCardFooter}>
            {(isSmallScreen || (!isSmallScreen && !hovered)) && (
              <Box sx={DashboardDraggableCardStyles.imageTextContainer}>
                <ImageAndText
                  image={<CustomIcon iconName="Bulb" />}
                  text={CARD_FOOTER_TEXT}
                  hoverElement={true}
                  hoverElementClass={DashboardDraggableCardStyles.hoverElement}
                  imageClassName={DashboardDraggableCardStyles.bulbIcon}
                  additionalClassName={
                    DashboardDraggableCardStyles.imageAndTextContainer
                  }
                  additionalClassText={DashboardDraggableCardStyles.text}
                />
              </Box>
            )}

            {!isSmallScreen && hovered && (
              <Box sx={DashboardDraggableCardStyles.hoveredElementsContainer}>
                <Box sx={DashboardDraggableCardStyles.dragButton}>
                  <img src={dragIcon} alt={DRAG_ICON} />
                </Box>
                <Box sx={DashboardDraggableCardStyles.hoverOptions}>
                  {viewTask && (
                    <Box onClick={() => navigate("/mytasks")}>
                      <img src={viewTaskIcon} alt={VIEW_TASKS} />
                    </Box>
                  )}

                  <Box sx={DashboardDraggableCardStyles.eyeButton}>
                    <img src={eyeOff} alt={EYE_ICON} />
                  </Box>
                  <Box sx={DashboardDraggableCardStyles.downloadButton}>
                    <img src={downloadIcon} alt={DOWNLOAD_ICON} />
                  </Box>
                  <Box sx={DashboardDraggableCardStyles.maximizeButton}>
                    <img src={maximizeIcon} alt={MAXIMIZE_ICON} />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Drawers
        additionalClass={DashboardDraggableCardStyles.drawer}
        open={drawerOpen}
        type="secondary"
        anchor="bottom"
        toggleDrawer={handleToggeleDrawer}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={DashboardDraggableCardStyles.headingText}
            heading={selectedHeading}
          />
          <Box sx={DashboardDraggableCardStyles.textsContainer}>
            {viewTask && (
              <Typography
                onClick={handleNavigationClick}
                sx={DashboardDraggableCardStyles.viewTasks}
              >
                {" "}
                {VIEW_TASKS}
              </Typography>
            )}
            <Typography sx={DashboardDraggableCardStyles.drawerText}>
              {" "}
              {DOWNLOAD_WIDGET}
            </Typography>
            <Typography sx={DashboardDraggableCardStyles.drawerhideText}>
              {" "}
              {HIDE_WIDGET}
            </Typography>
          </Box>
        </Box>
      </Drawers>
    </>
  );
};

export default DashboardDraggableCard;
