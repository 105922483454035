export const ShareMarketingCollateralResponsiveStyles = {
  container: {
    marginTop: "60px",
    padding: "24px 20px",
  },
  sendRemainderText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    color: "var(--primary-black)",
  },
  dropDown: {
    borderBottom: "1px solid var(--primary-black)",
    marginLeft: "0px",
  },
  sendRemainder: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    paddingTop: "12px",
    paddingBottom: "25px",
    letterSpacing: "-0.5px",
  },
  textArea: {
    background: "var(--white)",
    border: "1px solid var(--shaded-cyan)",
  },
  button: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    paddingTop: "26px",
  },
  resetButtons: {
    display: "flex",
    gap: "20px",
  },
  breadCrumb: {
    paddingBottom: "16px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  breadCrumbText: {
    fontSize: "var(--font-size-14)",
  },
  fileUploadChip: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "288px",
    borderRadius: "100px",
    padding: "8px 16px",
    boxShadow: "0px 1px 4px 0px #00000029",
    border: "0.5px solid #A5DAF3",
  },
  sendShareText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    color: "var(--primary-black)",
    paddingTop: "11px",
    letterSpacing: "-0.5px",
  },
  fileUploadText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  formArea: {
    minHeight: "calc(100vh - 500px)",
  },
};
