import React, { useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Popover,
  Button,
  TextField,
} from "@mui/material";
import billingData from "../../../mockJson/CustomerDashboard/BillingData.json";
import BillingTabStyles from "./BillingTabStyles";
import downloadIcon from "../../../assets/DownloadIcon.svg";
import filter from "../../../assets/filter.svg";
import ActionButton from "../../../ImsDashboard/commonComponents/ActionButton";
import FilterType from "../../../mockJson/CustomerDashboard/FilterType.json";
import YearToDate from "../../../mockJson/CustomerDashboard/YearToDateData.json";
import { billingAmount, billingDate, duesDate, filterText, paidAmount, paidOn } from "../../../constants/Constants";

const ResponsiveBillingTabComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Box sx={BillingTabStyles.filterContainer}>
        <Button sx={BillingTabStyles.filterButton} onClick={handleClick}>
          <Box sx={BillingTabStyles.tableHeadingFilterImage}>
            <img src={filter} alt="filter" />
          </Box>
          <Typography sx={BillingTabStyles.tableHeadingFilterText}>
            {filterText}
          </Typography>
        </Button>
        <Popover
          aria-label="popoverBox"
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={BillingTabStyles.popoverStyles}
        >
          <Box sx={BillingTabStyles.popoverContent}>
            <TextField
              select
              key={FilterType[0].value}
              label="Type"
              variant="outlined"
              fullWidth
              defaultValue={FilterType[0].value}
              sx={BillingTabStyles.textField}
            >
              {FilterType.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
            <TextField
              select
              key={YearToDate[0].value}
              label="Period"
              variant="outlined"
              fullWidth
              defaultValue={YearToDate[0].value}
              sx={BillingTabStyles.textField}
            >
              {YearToDate.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Box>
        </Popover>
      </Box>

      <Box sx={BillingTabStyles.billingContainer}>
        {billingData.slice(1).map((e, index) => (
          <Box sx={BillingTabStyles.container} key={index}>
            <Box sx={BillingTabStyles.cardContent}>
              <Typography sx={BillingTabStyles.amount}>
                {e.billedAmount}
              </Typography>
              <Typography sx={BillingTabStyles.descriptionText}>
                {billingAmount}
              </Typography>
            </Box>
            <Box sx={BillingTabStyles.cardContent}>
              <Typography sx={BillingTabStyles.amount}>
                {e.billingDate}
              </Typography>
              <Typography sx={BillingTabStyles.descriptionText}>
                {billingDate}
              </Typography>
            </Box>
            <Box sx={BillingTabStyles.cardContent}>
              <Typography sx={BillingTabStyles.amount}>
                {e.paidAmount}
              </Typography>
              <Typography sx={BillingTabStyles.descriptionText}>
                {paidAmount}
              </Typography>
            </Box>
            <Box sx={BillingTabStyles.cardContent}>
              <Typography sx={BillingTabStyles.amount}>{e.dueDate}</Typography>
              <Typography sx={BillingTabStyles.descriptionText}>
                {duesDate}
              </Typography>
            </Box>
            {e.paidOn ? (
              <Box sx={BillingTabStyles.cardContent}>
                <Typography sx={BillingTabStyles.amount}>{e.paidOn}</Typography>
                <Typography sx={BillingTabStyles.descriptionText}>
                  {paidOn}
                </Typography>
              </Box>
            ) : (
              <Box sx={BillingTabStyles.cardContent}>
                <ActionButton
                  sx={BillingTabStyles.payActionButton}
                  buttonText={"Pay"}
                />
              </Box>
            )}
            <Box sx={BillingTabStyles.downloadIcon}>
              <img src={downloadIcon} alt="download-icon" />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ResponsiveBillingTabComponent;
