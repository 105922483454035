export const ClaimHistoryData = {
  activeClaimsData: [
    {
      claimId: "2012-KIA-SORENTO EX",
      active: true,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "SMIC",
          subheading: "Contact Person",
        },
        {
          heading: "Verification",
          subheading: "Claim Status",
        },
        {
          heading: "CLM-00000013",
          subheading: "Claim Number",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Updated On",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Claim Initiated",
        },
      ],
    },
    {
      claimId: "2012-KIA-SORENTO EX",
      active: true,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "SMIC",
          subheading: "Contact Person",
        },
        {
          heading: "Verification",
          subheading: "Claim Status",
        },
        {
          heading: "CLM-00000013",
          subheading: "Claim Number",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Updated On",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Claim Initiated",
        },
      ],
    },
    {
      claimId: "2012-KIA-SORENTO EX",
      active: true,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "SMIC",
          subheading: "Contact Person",
        },
        {
          heading: "Verification",
          subheading: "Claim Status",
        },
        {
          heading: "CLM-00000013",
          subheading: "Claim Number",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Updated On",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Claim Initiated",
        },
      ],
    },
    {
      claimId: "2012-KIA-SORENTO EX",
      active: true,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "SMIC",
          subheading: "Contact Person",
        },
        {
          heading: "Verification",
          subheading: "Claim Status",
        },
        {
          heading: "CLM-00000013",
          subheading: "Claim Number",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Updated On",
        },
        {
          heading: "Jul 01‘24",
          subheading: "Claim Initiated",
        },
      ],
    },
  ],
  pastClaimsData: [
    {
      claimId: "2012-KIA-SORENTO EX",
      active: false,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "25 Feb‘23",
          subheading: "Closed On",
        },
        {
          heading: "14 Feb‘23",
          subheading: "Claim Initiated",
        },
        {
          heading: "Settlement Amount: $5,000.00, and Release of Liability",
          subheading: "Outcome",
        },
      ],
    },
    {
      claimId: "2012-KIA-SORENTO EX",
      active: false,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "25 Feb‘23",
          subheading: "Closed On",
        },
        {
          heading: "14 Feb‘23",
          subheading: "Claim Initiated",
        },
        {
          heading: "Settlement Amount: $5,000.00, and Release of Liability",
          subheading: "Outcome",
        },
      ],
    },
    {
      claimId: "2012-KIA-SORENTO EX",
      active: false,
      claimsData: [
        {
          heading: "C789",
          subheading: "Policy Number",
        },
        {
          heading: "25 Feb‘23",
          subheading: "Closed On",
        },
        {
          heading: "14 Feb‘23",
          subheading: "Claim Initiated",
        },
        {
          heading: "Settlement Amount: $5,000.00, and Release of Liability",
          subheading: "Outcome",
        },
      ],
    },
  ],
};
