import * as React from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Divider, InputLabel, Typography } from "@mui/material";
import {
  CLOSE,
  creditCard,
  debitCard,
  paymentmethod,
  save,
  selectPayment,
  turnOnAutoPayment,
} from "../../../../constants/Constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AutoPaymentStyles from "./AutoPaymentStyles";
interface AutoPaymentProps {
  onClose: () => void;
  open: boolean;
}

const AutoPaymentComponent: React.FC<AutoPaymentProps> = ({
  onClose,
  open,
}) => {
  const [paymentMethod, setPaymentMethod] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as string);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={onClose} aria-label="dialog box">
        <Box sx={AutoPaymentStyles.container}>
          <DialogTitle sx={AutoPaymentStyles.dialogTitle}>
            {turnOnAutoPayment}
          </DialogTitle>
          <Box
            onClick={onClose}
            aria-label="close"
            sx={AutoPaymentStyles.boxOne}
          >
            <img src={CLOSE} alt="close icon" />
          </Box>
        </Box>
        <Divider sx={AutoPaymentStyles.divider}></Divider>
        <DialogContent sx={AutoPaymentStyles.dialogBox}>
          <Box sx={AutoPaymentStyles.boxTwo}>
            <Typography sx={AutoPaymentStyles.text}>{paymentmethod}</Typography>
            <Box sx={AutoPaymentStyles.formBox}>
              <FormControl fullWidth sx={AutoPaymentStyles.form}>
                <InputLabel
                  id="Select Payment Method"
                  sx={AutoPaymentStyles.textOne}
                >
                  {selectPayment}
                </InputLabel>
                <Select
                  labelId="Select Payment Method"
                  id="Select Payment Method"
                  value={paymentMethod}
                  label="Select Payment Method"
                  onChange={handleChange}
                  sx={AutoPaymentStyles.textOne}
                >
                  <MenuItem sx={AutoPaymentStyles.text} value="Credit">
                    {creditCard}
                  </MenuItem>
                  <MenuItem sx={AutoPaymentStyles.text} value="Debit">
                    {debitCard}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={AutoPaymentStyles.boxThree}>
              <AddCircleOutlineIcon />
              <Typography sx={AutoPaymentStyles.text}>
                {paymentmethod}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={AutoPaymentStyles.dialogActions}>
          <Button variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AutoPaymentComponent;
