export const AgentDashBoardStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "25px 30px",
    background:
      "linear-gradient(180deg, var(  --support-green) 0%, var( --support-blue) 100%)",
    marginTop: "68px",
    "@media screen and (max-width: 1023px)": {
      padding: " 23px 20px",
      gap: "15px",
      marginTop: "60px",
    },
  },
  dashboardContainer: {
    maxWidth: "100%",
    "@media screen and (max-width: 599px)": {
      margin: "unset",
    },
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "@media screen and (max-width: 1023px)": {
      gap: "6px",
    },
  },
  dropDownContainer: {
    display: "flex",
    gap: "16px",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    "@media screen and (max-width: 1023px)": {
      alignItems: "flex-end",
      position: "relative",
    },
  },
  line: {
    color: "var(--primary-grey)",
    fontSize: "var(--font-size-26)",
  },
  modal: {
    width: "1007px",
    height: "560px",
  },
  checkboxContainer: {
    display: "flex",
    gap: "25px",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: "30px",
    height: "435px",
    overflowY: "auto",
  },
  checkbox: {
    width: "311px",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
  },
  conversationalText: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    flexWrap: "wrap",
    "@media screen and (max-width: 1023px)": {
      flexDirection: "column",
      gap: "5px",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  overViewText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    letterSpacing: "0px",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--secondary-grey)",
    "@media screen and (max-width: 599px)": {
      letterSpacing: "-0.5px",
    },
  },
  options: {
    display: "flex",
    alignItems: "center",
    maxWidth: "356px",
    gap: "16px",
    "@media screen and (max-width:1023px)": {
      gap: "10px",
      position: "absolute",
      bottom: "0px",
      right: "0px",
    },
  },
  dropDownClass: {
    marginLeft: "0px",
  },
  configureText: {
    color: "var(--support-white)",
    "@media screen and (max-width: 650px)": {
      display: "none",
    },
  },
  configureIcon: {
    gap: "4px",
  },
  cardsContainer: {
    width: "100%",
    marginTop: "25px",
    "@media screen and (max-width: 599px)": {
      marginTop: "20px",
    },
  },
  gearIcon: {
    width: "24px",
    height: "24px",
    "@media screen and (max-width: 599px)": {
      width: "18px",
      height: "18px",
    },
  },
  newBusinessContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "45px",
      columnGap: "100px",
      marginTop: "11px",
      marginLeft: "5px",
    },
    "@media screen and (max-width: 599px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "27px",
    },
  },
  customerStatsContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "45px",
      columnGap: "100px",
      marginTop: "11px",
      marginLeft: "5px",
    },
    "@media screen and (max-width: 599px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "27px",
    },
  },
  existingBusinessContainer: {
    "@media screen and (max-width: 1023px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "30px",
      columnGap: "27px",
      marginTop: "11px",
      marginLeft: "5px",
    },
    "@media screen and (max-width: 599px)": {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "33px",
      columnGap: "25px",
    },
  },
  button: {
    boxShadow: "none",
  },
  headingText: {
    color: "var(--support-white)",
  },
  cardsInnerContainer: {
    height: "calc(100vh - 258px)",
  },
};
