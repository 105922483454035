import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { PerformingPoliciesCardStyles } from "./PerformingPoliciesCardStyles";
import { PERFORMING_POLICIES } from "../../constants";
import CustomIcon from "../CustomIcon/CustomIcon";
interface PerformingPoliciesCardProps {
  number: string;
  description: string;
  image: string;
  isLast?: boolean;
}

const PerformingPoliciesCard: React.FC<PerformingPoliciesCardProps> = ({
  number,
  description,
  image,
  isLast,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  return (
    <Box
      sx={{
        ...(isSmallScreen
          ? PerformingPoliciesCardStyles.mobileContainer
          : PerformingPoliciesCardStyles.container),
        borderBottom:
          isLast && isSmallScreen
            ? "none !important"
            : "1px solid var(--primary-grey) !important",
      }}
    >
      <Box sx={PerformingPoliciesCardStyles.innerContainer}>
        {image === "agent" ? (
          <CustomIcon width="24px" height="24px" iconName="Agent" />
        ) : (
          <CustomIcon width="24px" height="24px" iconName="PolicyCard" />
        )}
        <Typography sx={PerformingPoliciesCardStyles.description}>
          {" "}
          {description}
        </Typography>
      </Box>
      <Typography sx={PerformingPoliciesCardStyles.number}>{number}</Typography>
    </Box>
  );
};

export default PerformingPoliciesCard;
