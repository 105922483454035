export const DashboardBreadCrumStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  arrowImage: {
    width: "7px",
    height: "14px",
    cursor:"pointer",
    "> img": {
      width: "100%",
      height: "100%",
    },
  },
  breadcrumText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  breadcrumAdditionalText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
};
