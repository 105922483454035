import docImage from "../assets/svgs/doc-image.svg";
export const folderData = [
  {
    folderName: "Folder 1",
    lastModified: "14th Aug‘24",
    fileCount: 2,
  },
  {
    folderName: "Folder 2",
    lastModified: "14th Aug‘24",
    fileCount: 2,
  },
  {
    folderName: "Folder 3",
    lastModified: "14th Aug‘24",
    fileCount: 2,
  },
  {
    folderName: "Folder 4",
    lastModified: "14th Aug‘24",
    fileCount: 2,
  },
];
export const fileData = [
  {
    fileName: "File 1",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 2",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 3",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 4",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 1",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 2",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 3",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
  {
    fileName: "File 4",
    lastModified: "14th Aug‘24",
    docImage: docImage,
  },
];
