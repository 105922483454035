import React from "react";
import { Box, Typography } from "@mui/material";
import { IBillingTransaction } from "../../../../../../models/user/policyDetails/PolicyDetails";
import moment from "moment";
import { dateFormat } from "../../../../../../constants/Constants";
import { currencyFormat } from "../../../../../../utils/Utility";
import { BillingActivityCardStyles } from "./BillingActivityCardStyles";
import CustomIcon from "../../../../../../common/CustomIcon/CustomIcon";

interface Props {
  transaction: IBillingTransaction;
  isLast?: boolean;
}

const getActivityIcon = (transactionType: string) => {
  switch (transactionType) {
    case "New Business":
      return <CustomIcon iconName="Attention" />; // attensionsIcon;
    case "Expire":
      return <CustomIcon iconName="PolicyRenewal" />; // PolicyRenewal;
    case "Policy Change":
      return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
    case "Claim Closed":
      return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
    case "Reinstatement":
      return <CustomIcon iconName="Star" />; // star;
    case "Claim Approved":
      return <CustomIcon iconName="Star" />; // star;
    case "Cancellation":
      return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
    default:
      return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
  }
};

const BillingActivityCard: React.FC<Props> = ({ transaction, isLast }) => {
  const icon = getActivityIcon(transaction?.typeCd);

  const getColumnOneHeading = (transactionType: string): string => {
    switch (transactionType) {
      case "CreateAccount":
      case "BillItems":
      case "Invoice":
        return "Invoice";
      case "PolicyChange":
        return "Policy Change";
      case "Receipt":
        return "Payment";
      case "Legal":
        return "Legal Notice";
      case "ItemAdjustment":
        return "Late Fee";
      case "Cancel":
        return "Cancellation";
      case "CancelRescind":
        return "Reinstatement";
      case "AutomatedRefund":
      case "ACHRefund":
      case "CreditCardRefund":
      case "ManualRefund":
      case "CancelRefund":
        return "Refund";
      default:
        return transaction.description;
    }
  };

  const getColumThreeDataSign = (transactionType: string): number => {
    switch (transactionType) {
      case "CreateAccount":
      case "BillItems":
        return parseFloat(transaction.invoiceAmount);

      case "PolicyChange":
        return parseFloat(transaction.changeWrittenAmount);

      case "Receipt":
      case "ItemAdjustment":
      case "FlatCancel":
      case "Cancel":
        return parseFloat(transaction.transactionAmount);
      default:
        return 0;
    }
  };

  const getColumnThreeData = (transactionType: string): string => {
    switch (transactionType) {
      case "CreateAccount":
      case "BillItems":
        return currencyFormat(
          "us",
          parseFloat(transaction.invoiceAmount) < 0
            ? -parseFloat(transaction.invoiceAmount).toFixed(2)
            : +parseFloat(transaction.invoiceAmount).toFixed(2)
        );
      case "PolicyChange":
        return currencyFormat(
          "us",
          parseFloat(transaction.changeWrittenAmount) < 0
            ? -parseFloat(transaction.changeWrittenAmount).toFixed(2)
            : +parseFloat(transaction.changeWrittenAmount).toFixed(2)
        );
      case "Receipt":
      case "ItemAdjustment":
      case "FlatCancel":
      case "Cancel":
        return currencyFormat(
          "us",
          parseFloat(transaction.transactionAmount) < 0
            ? -parseFloat(transaction.transactionAmount).toFixed(2)
            : +parseFloat(transaction.transactionAmount).toFixed(2)
        );
      default:
        return "$0.00";
    }
  };

  return (
    <>
      <Box
        sx={{
          ...BillingActivityCardStyles.activityCard,
          borderBottom: isLast ? "none" : "1px solid rgba(201, 225, 236, 1)",
        }}
      >
        <Box
          sx={{
            ...BillingActivityCardStyles.activeCardContainer,
            "@media (max-width: 768px)": {
              gridTemplateColumns: "1fr",
              gridTemplateAreas:
                transaction.typeCd === "CreateAccount" ||
                transaction.typeCd === "BillItems"
                  ? `"icon"
                                  "type"
                                  "amount"
                                  "totalDue"
                                  "dueDate"`
                  : `"icon"
                                  "type"
                                  "amount"
                                  "totalDue"`,
            },
          }}
        >
          <Box sx={BillingActivityCardStyles.icon}>{icon}</Box>
          <Box
            sx={{
              display: "grid",
              gap: "12px",
              alignItems: "center",
              "@media (max-width: 768px)": {
                gridArea: "type",
              },
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={BillingActivityCardStyles.heading}
            >
              {getColumnOneHeading(transaction.typeCd)}
            </Typography>
          </Box>
          {transaction.typeCd === "CreateAccount" ||
          transaction.typeCd === "BillItems" ? (
            <Box
              sx={{
                display: "grid",
                gap: "12px",
                alignItems: "center",
                "@media (max-width: 768px)": {
                  gridArea: "dueDate",
                },
              }}
            >
              <Typography sx={BillingActivityCardStyles.heading}>
                Due Date
              </Typography>
              <Typography sx={BillingActivityCardStyles.description}>
                {transaction.dueDate
                  ? moment(transaction?.dueDate).format(dateFormat)
                  : "-"}
              </Typography>
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box
            sx={{
              display: "grid",
              gap: "12px",
              alignItems: "center",
              "@media (max-width: 768px)": {
                gridArea: "amount",
              },
            }}
          >
            <Typography
              sx={{
                ...BillingActivityCardStyles.heading,
                textAlign: "right",
                "@media (max-width: 768px)": {
                  textAlign: "left",
                },
              }}
            >
              Amount
            </Typography>
            <Typography
              sx={{
                ...BillingActivityCardStyles.description,
                color:
                  getColumThreeDataSign(transaction.typeCd) < 0
                    ? "red"
                    : "green",
                textAlign: "right",
                "@media (max-width: 768px)": {
                  textAlign: "left",
                },
              }}
            >
              {getColumThreeDataSign(transaction.typeCd) < 0
                ? `(${getColumnThreeData(transaction.typeCd)})`
                : getColumnThreeData(transaction.typeCd)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: "12px",
              alignItems: "center",
              "@media (max-width: 768px)": {
                gridArea: "totalDue",
              },
            }}
          >
            <Typography
              sx={{
                ...BillingActivityCardStyles.heading,
                textAlign: "right",
                "@media (max-width: 768px)": {
                  textAlign: "left",
                },
              }}
            >
              Total Due
            </Typography>
            <Typography
              sx={{
                ...BillingActivityCardStyles.description,
                color:
                  parseFloat(transaction?.totalAmount) < 0 ? "red" : "green",
                textAlign: "right",
                "@media (max-width: 768px)": {
                  textAlign: "left",
                },
              }}
            >
              {currencyFormat(
                "us",
                +parseFloat(transaction?.totalAmount).toFixed(2)
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingActivityCard;
