import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import React, { useState } from "react";
import CheckBox from "../../common/CheckBox/CheckBox";
import { ConfigureDashboardStyles } from "./ConfigureDashboardStyles";
import styled from "@emotion/styled";

interface RadioButtonsProps {
  label: string;
  value: string;
}

interface CheckBoxDataProps {
  heading: string;
  subheading: string;
}

interface ConfigureDashboardProps {
  radioButtons: RadioButtonsProps[];
  checkBoxData: { [key: string]: CheckBoxDataProps[] };
}
const StyledFormControlLabel = styled(FormControlLabel)({
  width: "230px",
  marginRight: "0px",
  "& .MuiFormControlLabel-label": {
    fontSize: "var(--font-size-16)",
    fontFamily: "var(--font-noto-sans)",
    color: "var(--primary-black)",
    fontWeight: "var(--font-weight-400)",
  },
  "& .Mui-checked":{
    color: "var(--primary-black)",
  }
});
const ConfigureDashboard: React.FC<ConfigureDashboardProps> = ({
  radioButtons,
  checkBoxData,
}) => {
  const [selectedRadio, setSelectedRadio] = useState<string>(
    radioButtons[0].value
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(event.target.value);
  };
  const selectedRadioLabel = radioButtons.find(
    (radioButton) => radioButton.value === selectedRadio
  )?.label;

  return (
    <Box sx={ConfigureDashboardStyles.container}>
      <Box sx={ConfigureDashboardStyles.radioButtons}>
        {radioButtons.map((radioButton) => (
          <StyledFormControlLabel
            key={radioButton.value}
            value={radioButton.value}
            control={
              <Radio
                checked={selectedRadio === radioButton.value}
                onChange={handleRadioChange}
              />
            }
            label={radioButton.label}
          />
        ))}
      </Box>
      <Box sx={ConfigureDashboardStyles.checkBoxContainer}>
        <Typography sx={ConfigureDashboardStyles.heading}>
          {selectedRadioLabel}
        </Typography>
        <Box sx={ConfigureDashboardStyles.checkBoxes}>
          {checkBoxData[selectedRadio].map((checkBox) => (
            <CheckBox
              key={checkBox.heading}
              additionalClass={ConfigureDashboardStyles.checkBox}
              heading={checkBox.heading}
              subheading={checkBox.subheading}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ConfigureDashboard;
