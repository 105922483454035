export const MyTasksTableStyles = {
  container: {},
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  imageContainer: {
    display: "flex",
    gap: "20px",
  },
  drawer: {
    width: "50%",
  },
};
