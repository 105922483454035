import React, { useCallback, useMemo, useState } from "react";
import { Box, Link, Popover, Typography } from "@mui/material";
import ellipsisDots from "../../../../../../assets/ellipsisDots.svg";
import {
  IDescription,
  IPolicyTransaction,
} from "../../../../../../models/user/policyDetails/PolicyDetails";
import { ActivityCardStyles } from "./ActivityCardStyles";
import moment from "moment";
import { dateFormat } from "../../../../../../constants/Constants";
import { currencyFormat } from "../../../../../../utils/Utility";
import { IDocument } from "../../../../../../models/user/policyDetails/Document";
import CustomIcon from "../../../../../../common/CustomIcon/CustomIcon";
import Download from "../../../../../../assets/Download.svg";
import Document from "../../../../../../assets/Document.svg";

interface Props {
  transaction: IPolicyTransaction;
  isLast?: boolean;
  handleFileClick: (type: string, document: IDocument | null) => void;
}

const ActivityCard: React.FC<Props> = ({
  transaction,
  isLast,
  handleFileClick,
}) => {
  const [hover, setHover] = useState(false);
  const [anchorE1, setAnchorE1] = useState<HTMLAnchorElement | null>(null);

  const open = Boolean(anchorE1);

  const getActivityIcon = useCallback((transactionType: string) => {
    switch (transactionType) {
      case "New Business":
        return <CustomIcon iconName="Attention" />; // attensionsIcon;
      case "Expire":
        return <CustomIcon iconName="PolicyRenewal" />; // PolicyRenewal;
      case "Policy Change":
        return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
      case "Claim Closed":
        return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
      case "Reinstatement":
        return <CustomIcon iconName="Star" />; // star;
      case "Claim Approved":
        return <CustomIcon iconName="Star" />; // star;
      case "Cancellation":
        return <CustomIcon iconName="ClaimsClosed" />; // ClaimClosed;
      default:
        return <CustomIcon iconName="PolicyChange" />; // PolicyChange;
    }
  }, []);

  const premium = useMemo(() => {
    if (transaction.writtenFeeAmt && transaction.writtenPremium) {
      if (
        parseFloat(transaction?.writtenFeeAmt) +
          parseFloat(transaction?.writtenPremium) <
        0
      ) {
        return `(${currencyFormat(
          "us",
          -(
            parseFloat(transaction?.writtenFeeAmt) +
            parseFloat(transaction?.writtenPremium)
          ).toFixed(2)
        )})`;
      } else {
        return currencyFormat(
          "us",
          +(
            parseFloat(transaction?.writtenFeeAmt) +
            parseFloat(transaction?.writtenPremium)
          ).toFixed(2)
        );
      }
    }
    if (!transaction.writtenFeeAmt && !transaction.writtenPremium) {
      return "$0.00";
    }
    if (transaction.writtenPremium && !transaction.writtenFeeAmt) {
      if (parseFloat(transaction?.writtenPremium) < 0) {
        return `(${currencyFormat(
          "us",
          -parseFloat(transaction?.writtenPremium).toFixed(2)
        )})`;
      } else {
        return `${currencyFormat(
          "us",
          +parseFloat(transaction?.writtenPremium).toFixed(2)
        )}`;
      }
    }
    if (transaction.writtenFeeAmt && !transaction.writtenPremium) {
      if (parseFloat(transaction?.writtenFeeAmt) < 0) {
        return `(${currencyFormat(
          "us",
          -parseFloat(transaction?.writtenFeeAmt).toFixed(2)
        )})`;
      } else {
        return `${currencyFormat(
          "us",
          +parseFloat(transaction?.writtenFeeAmt).toFixed(2)
        )}`;
      }
    }

    return "$0.00";
  }, [transaction]);

  return (
    <>
      <Box
        sx={{
          ...ActivityCardStyles.activityCard,
          borderBottom: isLast ? "none" : "1px solid rgba(201, 225, 236, 1)",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box sx={ActivityCardStyles.activityCardContainer}>
          <Box sx={ActivityCardStyles.icon}>
            {getActivityIcon(transaction?.identifier)}
            {/* <img
              src={getActivityIcon(transaction?.transactionType)}
              alt="Icon"
            /> */}
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: "12px",
              gridArea: "type",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              sx={ActivityCardStyles.heading}
            >
              {transaction?.transactionType}
            </Typography>
            {transaction?.descriptionList?.length > 0 ? (
              <Link
                onClick={(e) => setAnchorE1(e.currentTarget)}
                sx={{
                  ...ActivityCardStyles.description,
                  cursor: "pointer",
                }}
              >{`${transaction?.descriptionList.length} ${
                transaction?.descriptionList.length > 1 ? "Changes" : "Change"
              } made to the policy`}</Link>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              gridArea: "effective",
            }}
          >
            <Typography sx={ActivityCardStyles.heading}>
              Effective Date
            </Typography>
            <Typography
              sx={{
                ...ActivityCardStyles.description,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
            >
              {moment(transaction?.transactionEffectiveDate).format(dateFormat)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              alignItems: "flex-end",
              gridArea: "premium",
              "@media (max-width: 768px)": {
                alignItems: "flex-start",
              },
            }}
          >
            <Typography sx={ActivityCardStyles.heading}>Premium</Typography>
            <Typography
              sx={{
                ...ActivityCardStyles.description,
                color:
                  parseFloat(transaction?.writtenFeeAmt) +
                    parseFloat(transaction?.writtenPremium) <
                  0
                    ? "red"
                    : "green",
              }}
            >
              {premium}
            </Typography>
          </Box>
          {/* {hover && transaction?.document && (
            <Box sx={ActivityCardStyles.images}>
              <Box
                sx={ActivityCardStyles.hoveredImage}
                onClick={() =>
                  handleFileClick("download", transaction?.document)
                }
              >
                <img src={Download} alt="Download" />
              </Box>
              <Box
                sx={ActivityCardStyles.hoveredImage}
                onClick={() => handleFileClick("view", transaction?.document)}
              >
                <img src={Document} alt="Document" />
              </Box>
            </Box>
          )} */}
          <Box sx={ActivityCardStyles.ellipsis}>
            <img src={ellipsisDots} alt="ellipsis" />
          </Box>
        </Box>
      </Box>
      <Popover
        id={"descriptions"}
        open={open}
        anchorEl={anchorE1}
        onClose={() => setAnchorE1(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "grid", padding: "20px" }}>
          {transaction.descriptionList?.map(
            (description: IDescription, index: number) => (
              <Box key={`${description.description}_${index}`}>
                {description.description}
              </Box>
            )
          )}
        </Box>
      </Popover>
    </>
  );
};

export default ActivityCard;
