import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import downloadIcon from "../../../../../assets/Download.svg";
import { dateFormat, download } from "../../../../../constants/Constants";
import moment from "moment";
import { DocumentStyles } from "./DocumentStyles";
import { IDocument } from "../../../../../models/user/policyDetails/Document";

interface Props {
  documentData: IDocument;
  handleFileClick: (type: string, documentData: IDocument) => void;
  view: boolean;
}

const Document: React.FC<Props> = ({ documentData, handleFileClick, view }) => {
  return (
    <Box sx={DocumentStyles.cardContainer}>
      <Card sx={DocumentStyles.card}>
        <Box sx={DocumentStyles.cardContentBox}>
          <Box sx={DocumentStyles.imageContainer}>
            <PictureAsPdfIcon sx={DocumentStyles.pdfIcon} />
          </Box>
          <Box sx={DocumentStyles.cardItem}>
            <Typography sx={DocumentStyles.cardItemHeadingStyle}>
              {documentData.name}
            </Typography>
            <Typography sx={DocumentStyles.cardItemContentStyle}>
              Date: {moment(documentData.addDate).format(dateFormat)}
            </Typography>
          </Box>
        </Box>
        <Box sx={DocumentStyles.buttonGroup} aria-label="Add to wallet">
          {view ? (
            <Button
              sx={{
                gap: "4px",
                padding: "0.5rem",
                ":hover": {
                  backgroundColor: "white",
                },
                ":disabled": {
                  backgroundColor: "white !important",
                },
              }}
              onClick={() => handleFileClick("view", documentData)}
              disabled
            >
              <VisibilityIcon
                titleAccess="View Document"
                sx={{ color: "black" }}
              />
              <Typography sx={DocumentStyles.text}>View</Typography>
            </Button>
          ) : (
            <></>
          )}
          <Button
            sx={DocumentStyles.downloadButton}
            aria-label="Download"
            onClick={() => handleFileClick("download", documentData)}
            disabled
          >
            <img src={downloadIcon} alt="download Icon" />
            <Typography sx={DocumentStyles.text}>{download}</Typography>
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default Document;
