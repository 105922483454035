import React from "react";
import LatestNewsCard from "../LatestNewsCard/LatestNewsCard";
import { Box } from "@mui/material";
import { LatestNewsStyles } from "./LatestNewsStyles";
interface NewsItem {
  description: string;
  date: string;
  time: string;
  image: string;
  name: string;
}
interface LatestNewsProps {
  newsData: NewsItem[];
}
const LatestNews: React.FC<LatestNewsProps> = ({ newsData }) => {
  return (
    <Box sx={LatestNewsStyles.container}>
      {newsData.slice(0, 2).map((data, index) => (
        <LatestNewsCard
          key={index}
          description={data.description}
          date={data.date}
          time={data.time}
          image={data.image}
          name={data.name}
          isLast={index === 1}
        />
      ))}
    </Box>
  );
};

export default LatestNews;
