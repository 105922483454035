import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DropDownStyles } from "./DropDownStyles";
import { Box, SxProps, Typography } from "@mui/material";
import { ALL_POLICIES, DROP_DOWN_ICON, VIEW_BY } from "../../constants";
import { Theme } from "@emotion/react";
import downSideIcon from "../../assets/svgs/drop-down-arrow.svg";
import upSideIcon from "../../assets/svgs/upside-down-icon.svg";
import styled from "@emotion/styled";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
interface DataProps {
  name: string;
  value: number | string;
}

interface DropDownProps {
  data: DataProps[];
  placeholder: string;
  selectedValue: string;
  onChange: (event: SelectChangeEvent) => void;
  primary?: boolean;
  formControlAdditionalClassName?: SxProps<Theme>;
  additionalClassName?: SxProps<Theme>;
  selectAdditionalClassName?: SxProps<Theme>;
  menuItemClassName?: SxProps<Theme>;
  type?: "default" | "primary";
}
const StyledFormControl = styled(FormControl)({
  margin: "0px",
  "& .MuiInputBase-root::before, & .MuiInputBase-root::after": {
    border: "none",
  },
  border: "none",
});
const StyledSelect = styled(Select)({
  marginTop: "0px !important",
  fontFamily: "var(--font-noto-sans)",
  fontSize: "var(--font-size-14)",
  fontWeight: "var(--font-weight-400)",
  color: "var(--primary-black)",
  ".MuiSvgIcon-root": {
    color: "var(--black)",
  },
  "&:hover:not(.Mui-disabled, .Mui-error):before": {
    borderBottom: "0px solid",
  },
});

const DropDown: React.FC<DropDownProps> = ({
  data,
  placeholder = ALL_POLICIES,
  selectedValue,
  onChange,
  type = "default",
  primary = false,
  formControlAdditionalClassName,
  additionalClassName,
  selectAdditionalClassName,
  menuItemClassName,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleChange: SelectInputProps<unknown>["onChange"] = (
    event,
    child
  ) => {
    onChange(event as SelectChangeEvent);
  };
  return (
    <Box sx={Object.assign({}, DropDownStyles.container, additionalClassName)}>
      {primary && <Typography sx={DropDownStyles.text}>{VIEW_BY}</Typography>}
      <StyledFormControl variant="standard" sx={formControlAdditionalClassName}>
        <StyledSelect
          value={selectedValue}
          onChange={handleChange}
          displayEmpty
          sx={{
            ...DropDownStyles.select,
            ...selectAdditionalClassName,
            "& .MuiInput-input": {
              paddingRight:
                type === "primary" ? "10px !important" : "24px !important",
            },
          }}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          IconComponent={
            type === "primary"
              ? () => (
                  <img
                    src={isOpen ? upSideIcon : downSideIcon}
                    alt={DROP_DOWN_ICON}
                    style={{ width: "16px", height: "16px" }}
                  />
                )
              : undefined
          }
          variant={"standard"}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {data.map((item, index) => (
            <MenuItem
              sx={{ ...DropDownStyles.menuItem, ...menuItemClassName }}
              key={index}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledFormControl>
    </Box>
  );
};

export default DropDown;
