const ClaimsStyles = {
  container: {
    marginTop: "20px",
    paddingBottom: "60px",
    display: "flex",
    flexDirection: "column",
    gap: "44px",
  },
  myClaimsCardStyles: {
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "22px",
    padding: "14.56px 42px 13.34px 15px",
    border: "1px solid #C9E1EC",
    flexWrap: "wrap",
    justifyContent: "space-between",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0)",
    "@media (max-width:599px)": {
      padding: "15px 30px 28px 15px",
      justifyContent: "space-between",
      marginTop: "15px",
    },
    "@media (min-width: 600px) and (max-width: 1023px)": {
      paddingRight: "15px",
    },
  },
  claimContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFF",
    border: "1px solid #C9E1EC",
    borderRadius: "10px",
    padding: "15px 29px 15px 26.84px",
    flexWrap: "wrap",
    flexShrink: 0,
    flexDirection: "row",
    "@media (max-width:599px)": {
      padding: "30px",
    },
    "@media (min-width: 600px) and (max-width: 1023px)": {
      paddingRight: "15px",
    },
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width:599px)": {
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  imgStyle: {
    paddingTop: "5px",
    "@media (maxwidth: 599px)": {
      width: "28.5px",
      height: "30px",
    },
  },
  carImgStyle: {
    paddingTop: "5px",
    height: "46px",
    width: "39px",
    "@media (maxwidth: 599px)": {
      width: "28.5px",
      height: "30px",
    },
  },
  container1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  myClaimsCardWithIconStyles: {
    gap: "37.79px",
    "@media (max-width:899px)": {
      width: "100%",
      gap: "14.93px",
    },
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "15px",
    minWidth: "114px",
    "@media (max-width:599px)": {
      gap: "8.03px",
      padding: "10px",
      justifyContent: "space-between",
    },
  },
  activeClaimsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  titleText: {
    color: "#383D4E",
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "110%",
    "@media (max-width: 799px)": {
      fontSize: "16px",
    },
  },
  linkStyle: {
    display: "flex",
    alignItem: "center",
    textDecoration: "none",
    gap: "10px",
    "& :hover": {
      textDecoration: "none",
    },
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    img: {
      height: "41px",
      width: "39px",
    },
  },
  trackYourClaimButton: {
    "@media (max-width:599px)": {
      display: "flex",
      alignItem: "center",
      justifyContent: "flex-end",
      marginLeft: "auto",
      paddingTop: "24px",
    },
    "@media (min-width: 600px) and (max-width: 795px)": {
      marginLeft: "auto",
      paddingTop: "5px",
    },
    "@media (min-width: 795px) and (max-width: 1000px)": {
      paddingTop: "5px",
      marginLeft: "auto",
    },
    "@media (min-width: 1000px) and (max-width: 1082px)": {
      marginLeft: "auto",
      paddingTop: "5px",
    },
  },
  trackYourClaim: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "black",
  },
  noClaimsCard: {
    padding: "30px",
    border: "1px solid #C9E1EC",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
  },
  noClaimsText: {
    overflow: "hidden",
    color: "#1D252B",
    fontSize: "18px",
    fontFamily: "Noto Sans",
    fontWeight: "400",
    lineHeight: "19.8px",
    textOverflow: "ellipsis",
    "@media (max-width:599px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
    "@media (min-width:600px) and (max-width:1366px)": {
      whiteSpace: "nowrap",
    },
  },
};
export default ClaimsStyles;
