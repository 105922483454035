import React from "react";
import { Props } from "./Props";
import { useMediaQuery } from "@mui/material";

const PaymentsHistoryIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;
  const tab = useMediaQuery("@media(min-width: 600px) and (max-width: 800px)");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={tab ? "40px" : width}
      height={tab ? "40px" : height}
      viewBox="0 0 46 44"
      fill="none"
    >
      <circle cx="31.4502" cy="9.59863" r="9.59863" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.767 5.35059H5.17706V6.35059H14.3528L19.2532 11.251H44.4355V39.002C43.9295 40.1598 42.0729 42.5081 38.4309 42.7882C34.5623 43.0858 12.5374 42.9189 1.5 42.7925V11.251H11.449L16.7746 16.398H37.9693V36.0592H38.9693V15.398H17.1789L11.8533 10.251H0.5V43.7809L0.994152 43.7867C11.8171 43.9133 34.5226 44.0918 38.5076 43.7853C42.686 43.4638 44.8349 40.7008 45.3998 39.288L45.4355 39.1986V10.251H19.6674L14.767 5.35059Z"
        fill="black"
      />
    </svg>
  );
};

export default PaymentsHistoryIcon;
