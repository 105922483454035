export const radioButtons = [
  {
    label: "Productivity",
    value: "productivity",
  },
  {
    label: "Policy related",
    value: "policyRelated",
  },
  {
    label: "Performance Analytics",
    value: "performanceAnalytics",
  },
  {
    label: "Commission related",
    value: "commissionRelated",
  },
  {
    label: "Business overview",
    value: "businessOverview",
  },
];
export const checkBoxData = {
  productivity: [
    { heading: "Productivity Heading 1", subheading: "Subheading 1" },
    { heading: "Productivity Heading 2", subheading: "Subheading 2" },
  ],
  policyRelated: [
    { heading: "Policy Heading 1", subheading: "Subheading 1" },
    { heading: "Policy Heading 2", subheading: "Subheading 2" },
  ],
  performanceAnalytics: [
    { heading: "Performance Analytics Heading 1", subheading: "Subheading 1" },
    { heading: "Performance Analytics Heading 2", subheading: "Subheading 2" },
  ],
  commissionRelated: [
    { heading: "Commission Heading 1", subheading: "Subheading 1" },
    { heading: "Commission Heading 2", subheading: "Subheading 2" },
  ],
  businessOverview: [
    { heading: "Business Overview Heading 1", subheading: "Subheading 1" },
    { heading: "Business Overview Heading 2", subheading: "Subheading 2" },
  ],
};
