import { Box } from "@mui/material";
import React, { useState } from "react";
import { configureDashboardResponsiveData } from "../../constants/ConfigureDashboardResponsiveData";
import Accordian from "../../common/Accordian/Accordian";
import CheckBox from "../../common/CheckBox/CheckBox";
import { ConfigureDashboardResponsiveStyles } from "./ConfigureDashboardResponsiveStyles";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import {
  CANCEL,
  CONFIGURE_DASHBOARD,
  RESET,
  SAVE_CHANGES,
} from "../../constants";
import CustomButton from "../../common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";

const ConfigureDashboardResponsive = () => {
  const navigate = useNavigate();
  const [expandedAccordion, setExpandedAccordion] = useState<string | false>(
    false
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{
    [key: string]: boolean[];
  }>({});
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? panel : false);
    };
  const handleCheckboxChange = (
    accordionIndex: number,
    checkboxIndex: number,
    checked: boolean
  ) => {
    setSelectedCheckboxes((prevState) => {
      const newCheckboxes = { ...prevState };
      if (!newCheckboxes[`panel${accordionIndex}`]) {
        newCheckboxes[`panel${accordionIndex}`] = Array(
          configureDashboardResponsiveData[accordionIndex].dashboardData.length
        ).fill(false);
      }
      newCheckboxes[`panel${accordionIndex}`][checkboxIndex] = checked;

      return newCheckboxes;
    });
  };

  return (
    <Box sx={ConfigureDashboardResponsiveStyles.container}>
      <Box sx={ConfigureDashboardResponsiveStyles.dashboardContainer}>
        <DashboardBreadCrumb
          handleClick={() => navigate(-1)}
          variant={"primary"}
          additionalContentClass={ConfigureDashboardResponsiveStyles.breadCrumbText}
          text={CONFIGURE_DASHBOARD}
        />
        <Box sx={ConfigureDashboardResponsiveStyles.accordianContainer}>
          {configureDashboardResponsiveData.map((data, accordionIndex) => (
            <Accordian
              key={accordionIndex}
              isFirst={accordionIndex === 0}
              isLast={
                accordionIndex === configureDashboardResponsiveData.length - 1
              }
              header={data.heading}
              body={
                <Box sx={ConfigureDashboardResponsiveStyles.checkBoxContainer}>
                  {data.dashboardData.map((graph, checkboxIndex) => (
                    <CheckBox
                      key={checkboxIndex}
                      heading={graph}
                      checked={
                        selectedCheckboxes[`panel${accordionIndex}`]
                          ? selectedCheckboxes[`panel${accordionIndex}`][
                              checkboxIndex
                            ]
                          : false
                      }
                      onChange={(checked) =>
                        handleCheckboxChange(
                          accordionIndex,
                          checkboxIndex,
                          checked
                        )
                      }
                    />
                  ))}
                </Box>
              }
              expanded={expandedAccordion === `panel${accordionIndex}`}
              onChange={handleAccordionChange(`panel${accordionIndex}`)}
            />
          ))}
        </Box>
      </Box>
      <Box sx={ConfigureDashboardResponsiveStyles.buttonsContainer}>
        <Box sx={ConfigureDashboardResponsiveStyles.resetButton}>
          <CustomButton
            additionalClassName={ConfigureDashboardResponsiveStyles.cancelButton}
            text={RESET}
            type="outlined"
          />
          <CustomButton
            additionalClassName={ConfigureDashboardResponsiveStyles.cancelButton}
            text={CANCEL}
            type="outlined"
          />
        </Box>
        <CustomButton
          additionalClassName={ConfigureDashboardResponsiveStyles.button}
          text={SAVE_CHANGES}
          type="filled"
        />
      </Box>
    </Box>
  );
};

export default ConfigureDashboardResponsive;
