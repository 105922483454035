import {
  CheckCircleOutlineOutlined,
  CloseOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

export interface IProps {
  open: boolean;
  handleClose: () => void;
}

const MessageDialog: React.FC<IProps> = ({ open, handleClose }) => {
  const { isSuccess, isError, message } = useAppSelector(
    (state: RootState) => state.common
  );
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={handleClose}>
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        {isSuccess ? (
          <CheckCircleOutlineOutlined
            sx={{ fontSize: "60px", color: "green" }}
          />
        ) : isError ? (
          <ErrorOutlineOutlined sx={{ fontSize: "60px", color: "red" }} />
        ) : (
          <></>
        )}
        <DialogContentText color={isError ? "red" : ""}>
          {message
            ? message
            : isSuccess
            ? "Success"
            : isError
            ? "Something went wrong please try again"
            : ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
