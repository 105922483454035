export const myTasksTooltip=[
      {
            key:"viewPolicyDetails",
            value:"View Policy Details",
      },
      {
            key:"emailClient",
            value:"Email Client",
      },
      {
            key:"callClient",
            value: "Call Client",
      },
      {
            key:"rescheduleTask",
            value:"Reschedule Task",
      },
      {
            key:"reassignTask",
            value:"Reassign Task",
      },
      {
            key:"markComplete",
            value: "Mark Complete",
      },
      {
            key:"notes",
            value: "Notes",
      },
]
  