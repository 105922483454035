import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import Drivers from "./Drivers/Drivers";
import Vehicles from "./Vehicles/Vehicles";
import { GetPolicyVehiclesAndDrivers } from "../../../../store/user/policyDetails/policyDetailsActions";

const VehiclesAndDrivers = () => {
  const dispatch = useAppDispatch();
  const { policyId } = useParams();

  const { policyVehicles, policyDrivers } = useAppSelector(
    (store: RootState) => store.policyDetails
  );

  useEffect(() => {
    if (policyId) {
      dispatch(GetPolicyVehiclesAndDrivers({ policyId }));
    }
  }, [policyId, dispatch]);

  return (
    <Box sx={{ padding: "20px 0px" }}>
      <Vehicles vehicles={policyVehicles} />
      <Drivers drivers={policyDrivers} />
    </Box>
  );
};

export default VehiclesAndDrivers;
