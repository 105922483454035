import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChartToolTipStyles } from "./PieChartToolTipStyles";
interface PieChartToolTipProps {
  heading: string;
  subheading: string;
}
const PieChartToolTip: React.FC<PieChartToolTipProps> = ({
  heading,
  subheading,
}) => {
  return (
    <Box sx={PieChartToolTipStyles.container}>
      <Typography sx={PieChartToolTipStyles.heading}>{heading}</Typography>
      <Typography sx={PieChartToolTipStyles.subheading}>
        {subheading}
      </Typography>
    </Box>
  );
};

export default PieChartToolTip;
