import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Popover, Typography } from "@mui/material";
import EllipsisButtonStyles from "./EllipsisButtonStyles";
import { ELLIPSIS } from "../../../constants/Constants";

interface EllipsisButtonProps {
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    text: string
  ) => void;
  handleClose: () => void;
  options: string[];
}

const EllipsisButtonComponent: React.FC<EllipsisButtonProps> = ({
  handleClick,
  options,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const buttonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    option: string
  ) => {
    event.preventDefault();
    handleClick(event, option);
    setAnchorEl(null);
  };

  const Open = Boolean(anchorEl);
  const HandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const HandleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-label="more Icon"
        id="long-button"
        aria-controls={Open ? "long-menu" : undefined}
        aria-expanded={Open ? "true" : undefined}
        aria-haspopup="true"
        onClick={HandleClick}
        sx={EllipsisButtonStyles.iconButton}
      >
        <img src={ELLIPSIS} alt="vertical ellipsis icon" />
      </IconButton>
      <Popover
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        id="long-menu"
        anchorEl={anchorEl}
        open={Open}
        onClose={HandleClose}
        aria-labelledby="long-button"
        sx={EllipsisButtonStyles.popOver}
      >
        {options.map((option) => (
          <Box key={option} sx={EllipsisButtonStyles.box}>
            <Typography
              onClick={(event: any) => buttonClick(event, option)}
              sx={EllipsisButtonStyles.text}
            >
              {option}
            </Typography>
          </Box>
        ))}
      </Popover>
    </Box>
  );
};

export default EllipsisButtonComponent;
