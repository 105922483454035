export const PremiunStyles = {
  mainBox: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    overflowX: "auto",
    marginTop: "20px",
    padding: "0px 24px",
    width: "100%",
    height: "auto",
    border: "1px solid rgba(201, 225, 236, 1)",
  },
  totalPremiumBox: {
    color: "inherit",
    display: "inline-block",
    verticalAlign: "middle",
    outline: "0",
    borderBottom: "2px solid rgba(63, 63, 63, 1)",
    width: "100%",
  },
  totalPremiumLabel: {
    margin: 0,
    display: "table-cell",
    verticalAlign: "inherit",
    textAlign: "left",
    padding: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "225px",
    borderBottom: "0px",
    fontFamily: "Playfair Display",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    float: "left",
  },
  totalPremiumText: {
    margin: 0,
    fontFamily: "Noto Sans",
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0em",
    marginTop: "1rem",
    float: "right",
  },
  vehicleContainer: {
    display: "grid",
    gap: "12px",
  },
  perVehicleBox: {
    display: "inline-flex",
    float: "right",
    marginTop: "0.5rem",
    marginBottom: ".5rem",
    fontSize: "1rem",
  },
  vehicleLabel: {
    overflow: "hidden",
    color: "#1D252B",
    fontSize: "1rem",
    fontWeight: "400",
    textOverflow: "ellipsis",
    margin: "0.5rem",
    fontFamily: "Noto Sans",
    // fontSize: '20px',
    lineHeight: "22px",
    letterSpacing: "0em",
    textAlign: "right",
    marginBottom: "0px",
    // "@media (max-width:599px)": {
    //   fontSize: "16px",
    //   lineHeight: "24px",
    // },
    // "@media (min-width:600px) and (max-width:1024px)": {
    //   whiteSpace: "nowrap",
    // },
  },
  vehicleText: {
    margin: "0.5rem",
    marginTop: "0px",
    color: "#1D252B",
    fontSize: "1rem",
    fontFamily: "Noto Sans",
    fontWeight: "300",
    lineHeight: "19.8px",
    // "@media(max-width:599px)": {
    //   fontSize: "14px",
    //   lineHeight: "18px",
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   fontFamily: '"Noto Sans"',
    //   whiteSpace: "nowrap",
    // },
    // "@media (min-width: 1025px) and (max-width: 1366px)": {
    //   overflow: "hidden",
    //   textOverflow: "ellipsis",
    //   whiteSpace: "nowrap",
    // },
  },
};
