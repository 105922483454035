export const SendRemainderStyles = {
  email: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "24px",
    textAlign: "left",
  },
  sendRemainderText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  sendRemainderTextSubheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    margin: "unset",
    marginTop: "8px",
    "@media (max-width: 1023px)": {
      marginTop: "11px",
      minWidth: "326px",
    },
  },
  emailContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "20px",
    border: "1px solid var(--ternary-grey)",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    backgroundColor: "var( --acne-blue)",
  },
  sendRemainderContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sendRemainder: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    "@media (max-width: 1023px)": {
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  remainderContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "4px",
    minHeight: "calc(100vh - 80px)",
    justifyContent: "space-between",
    gap: "15px",
  },
  buttonsContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "25px",
    },
  },
  fileUploadChip: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "382px",
    borderRadius: "100px",
    padding: "8px 16px",
    boxShadow: "0px 1px 4px 0px #00000029",
    border: "0.5px solid #A5DAF3",
    backgroundColor: "var(--white)",
    "@media (max-width: 1023px)": {
      minWidth: "268px",
      marginBottom: "20px",
    },
  },
  fileUploadChipText: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
  },
  fileUploadChipFileSize: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-12)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
  },
  textArea: {
    background: "var(--acne-blue)",
  },
  button: {
    boxShadow: "none",
    background: "transparent",
    "&:hover": {
      background: "transparent",
      color: "var(--primary-black)",
    },
  },
  dropDown: {
    borderBottom: "2px solid var(--black)",
  },
  emailCntainer: {
    height: "50vh",
  },
  resetButtons: {
    display: "flex",
    gap: "20px",
    "@media (max-width: 1023px)": {
      justifyContent: "space-between",
    },
  },
  saveButton: {
    width: "100%",
  },
};
