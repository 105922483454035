import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { LatestNewsCardStyles } from "./LatestNewsCardStyles";
import CustomIcon from "../CustomIcon/CustomIcon";
import ModalComponent from "../ModalComponent/ModalComponent";
import { LATEST_NEWS } from "../../constants";
interface LatestNewsCardProps {
  description: string;
  date: string;
  time: string;
  image: string;
  name: string;
  isLast?: boolean;
}

const LatestNewsCard: React.FC<LatestNewsCardProps> = ({
  description,
  date,
  time,
  image,
  name,
  isLast,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const [open, setOpen] = React.useState(false);
  const handleModal = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box
        sx={{
          ...(isSmallScreen
            ? LatestNewsCardStyles.mobileContainer
            : LatestNewsCardStyles.container),
          borderBottom:
            isLast && isSmallScreen
              ? "none !important"
              : "1px solid var(--primary-grey) !important",
        }}
      >
        <Box sx={LatestNewsCardStyles.imageContainer}>
          <CustomIcon width="24px" height="24px" iconName="News" />
        </Box>
        <Box sx={LatestNewsCardStyles.descriptionContainer}>
          <Typography
            onClick={handleModal}
            sx={LatestNewsCardStyles.description}
          >
            {description}
          </Typography>

          <Box sx={LatestNewsCardStyles.insuranceContainer}>
            <Typography sx={LatestNewsCardStyles.insurance}>
              {name} | {date}, {time}
            </Typography>
          </Box>
        </Box>
      </Box>
      <ModalComponent
        additionalClassContainer={LatestNewsCardStyles.modalContainer}
        heading={LATEST_NEWS}
        open={open}
        handleOpen={handleModal}
        additionalModalClass={LatestNewsCardStyles.modalContent}
      >
        <Typography sx={LatestNewsCardStyles.modalDescription}> {description}</Typography>
        <Box sx={LatestNewsCardStyles.modalFooter}>
        <Typography sx={LatestNewsCardStyles.insurance}>
              {name} | {date}, {time}
            </Typography>
        </Box>
      </ModalComponent>
    </>
  );
};

export default LatestNewsCard;
