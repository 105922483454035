import * as React from "react";
import Switch from "@mui/material/Switch";
import { SwitchStyles } from "./SwitchesStyles";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import styled from "@emotion/styled";
const label = { inputProps: { "aria-label": "Switch demo" } };
interface SwitchProps {
  switchText: string;
  handleSwitchChange?: () => void;
  checked?: boolean;
  additionalClassNameText?: SxProps<Theme>;
  additionalClassName?: SxProps<Theme>;
}
const StyledSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    color: " var(--support-white)",
    "&.Mui-checked": {
      color: "var(--support-white)",
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "var(--primary-grey)",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "var(--primary-grey)",
  },
});
const Switches: React.FC<SwitchProps> = ({
  switchText,
  handleSwitchChange,
  checked,
  additionalClassNameText,
  additionalClassName,
}) => {
  return (
    <Box sx={Object.assign({}, SwitchStyles.container, additionalClassName)}>
      <StyledSwitch
        onChange={handleSwitchChange}
        checked={checked}
        {...label}
      />
      <Typography
        sx={Object.assign({}, SwitchStyles.switchText, additionalClassNameText)}
      >
        {switchText}
      </Typography>
    </Box>
  );
};

export default Switches;
