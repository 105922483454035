import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { MenuItem, Select, Tabs, useMediaQuery } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PolicyTabStyles } from "./PolicyTabStyles";
import Activity from "./Activity/Activity";
import Billing from "./Billing/Billing";
import { policyTabsList } from "../../../constants/Constants";
import Claims from "./Claims/Claims";
import LienHolders from "./LienHolders/LienHolders";
import Coverages from "./Coverages/Coverages";
import Documents from "./Documents/Documents";
import VehiclesAndDrivers from "./VehiclesAnDrivers/VehiclesAndDrivers";
import { useAppSelector } from "../../../store/hooks";
import { RootState } from "../../../store/store";
import { isFeatureAvailable } from "../../../utils/Utility";

const PolicyTab = () => {
  const isMobile = useMediaQuery("(max-width:789px)");

  const [value, setValue] = useState<string>("");

  const { features } = useAppSelector((state: RootState) => state.features);

  const tabComponents: { [key: string]: React.ComponentType<any> } = {
    lob_pa_policies_activity: Activity,
    lob_pa_policies_coverages: Coverages,
    lob_pa_policies_vehicles_and_drivers: VehiclesAndDrivers,
    lob_pa_policies_policy_documents: Documents,
    lob_pa_policies_lienholders: LienHolders,
    lob_pa_billing_billing_tab: Billing,
    lob_pa_claims_claims_tab: Claims,
  };

  const tabOptions = useMemo(
    () =>
      policyTabsList.filter((tab) => {
        switch (tab.label) {
          case "Activity": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Coverages": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Vehicles & Drivers": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Documents": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Lienholder": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Billing": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          case "Claims": {
            return isFeatureAvailable({
              featureCodes: [tab.code],
              features,
            });
          }
          default:
            return true;
        }
      }),
    [features]
  );

  const getComponent = (code: string) => {
    const ComponentItem = tabComponents[code];
    return ComponentItem ? (
      <ComponentItem />
    ) : (
      <Box>Missing component for this tab</Box>
    );
  };

  useEffect(() => {
    if (tabOptions.length > 0) {
      setValue(tabOptions[0].code);
    }
  }, [tabOptions]);

  return (
    <Box sx={PolicyTabStyles.PolicyTabsStyles}>
      {isMobile ? (
        <Box sx={{ padding: "0px" }}>
          <Select
            value={value}
            IconComponent={KeyboardArrowDownIcon}
            onChange={(event) => setValue(event.target.value)}
            sx={PolicyTabStyles.selectStyles}
          >
            {tabOptions.map((tab) => (
              <MenuItem
                key={tab.code}
                value={tab.code}
                sx={PolicyTabStyles.menuStyles}
              >
                {tab.label}
              </MenuItem>
            ))}
          </Select>
          {getComponent(value)}
        </Box>
      ) : value ? (
        <Box>
          <Tabs
            sx={PolicyTabStyles.tabList}
            onChange={(e, value) => setValue(value)}
            value={value}
          >
            {tabOptions.map((tab) => (
              <Tab
                key={tab.code}
                label={tab.label}
                sx={PolicyTabStyles.tab}
                value={tab.code}
              />
            ))}
          </Tabs>
          {getComponent(value)}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PolicyTab;
