import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

interface IProps {
  title: string;
  open: boolean;
  handleCancelClick: () => void;
  handleOkClick: () => void;
}

const AlertDialog: React.FC<IProps> = ({
  title,
  open,
  handleCancelClick,
  handleOkClick,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleOkClick}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
