export const CustomerListStyles = {
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: "linear-gradient(180deg, #fbfdfc 0%, #e5f6fe 100%)",
  },
  headingText: {
    fontSize: "var(--font-size-20)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 230px)",
  },
};
