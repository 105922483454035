import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IPaymentSuccess } from "../../models/user/payment/Payment";
import paymentStyle from "./PaymentStyle";

const PaymentSuccess: React.FC = () => {
  const { responseData } = useParams();
  const navigate = useNavigate();
  if (!responseData) {
    navigate("/");
  }
  let data: IPaymentSuccess | null = null;
  if (responseData) {
    data = JSON.parse(atob(responseData));
  }
  if (!data) {
    navigate("/");
  }

  const handleHome = () => navigate("/customer/dashboard");

  return (
    <Box sx={paymentStyle.pSuccesMain}>
      <Box sx={paymentStyle.pSuccessMai2}>
        <Box sx={paymentStyle.pSuccessMsgSec}>
          <CheckCircleOutlineIcon
            fontSize="large"
            sx={paymentStyle.pSuccessIcon}
          />
          <Typography sx={paymentStyle.pSuccessMsg}>
            Payment Successful
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "@media (max-width:768px)": {
              gridTemplateColumns: "1fr",
              justifyItems: "center",
            },
          }}
        >
          <Typography sx={paymentStyle.textFont}>Paid Amount </Typography>
          <Typography sx={paymentStyle.textFont}>
            ${data?.totalAmount.toFixed(2)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "@media (max-width:768px)": {
              gridTemplateColumns: "1fr",
              justifyItems: "center",
            },
          }}
        >
          <Typography sx={paymentStyle.textFont}>Policy Number</Typography>
          <Typography sx={paymentStyle.textFont}>
            {data?.carrierPolicyNo}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            "@media (max-width:768px)": {
              gridTemplateColumns: "1fr",
              justifyItems: "center",
            },
          }}
        >
          <Typography sx={paymentStyle.textFont}>Reference Number</Typography>
          <Typography sx={paymentStyle.transactionId}>
            {data?.transactionId}
          </Typography>
        </Box>
        <Box sx={{ ...paymentStyle.paySection }}>
          <Button onClick={handleHome} variant="contained">
            Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
