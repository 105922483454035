import { Box, Card, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import claimsData from "../../../mockJson/CustomerDashboard/ClaimsData.json";
import MyClaimCardItem from "../../../ImsDashboard/commonComponents/MyClaimCardItem";
import {
  MYCLAIM_ICON,
  CAR_ICON_SMALL,
  VIEW_ARROW,
} from "../../../constants/Constants";
import ClaimsTabStyles from "./ClaimsTabStyles";

interface ClaimsDataItem {
  houseLoanData?: {
    data: {
      id: number;
      headText: string;
      contentText: string;
    }[];
  };
  carLoanData?: {
    data: {
      id: number;
      headText: string;
      contentText: string;
    }[];
  };
}

interface ClaimsItemProps {
  linkText: string;
}

const ClaimsItem: React.FC<ClaimsItemProps> = ({ linkText }) => {
  const [claimData, setClaimData] = useState<ClaimsDataItem[]>([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setClaimData(claimsData.flat());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {claimData.map((claim, id) => (
        <Box key={id}>
          <Card sx={ClaimsTabStyles.myClaimsCardStyles}>
            {claim.houseLoanData?.data?.map((item, index) => (
              <MyClaimCardItem
                key={index}
                headText={item.headText}
                contentText={item.contentText}
                imgStyle={
                  MYCLAIM_ICON
                    ? ClaimsTabStyles.imgStyle
                    : ClaimsTabStyles.carImgStyle
                }
                icons={index === 0 ? MYCLAIM_ICON : ""}
                icon={index === 0 ? MYCLAIM_ICON : undefined}
                parentClasses={
                  index === 0
                    ? ClaimsTabStyles.myClaimsCardWithIconStyles
                    : "none"
                }
              />
            ))}
            {claim.carLoanData?.data?.map((item, index) => (
              <MyClaimCardItem
                key={index}
                headText={item.headText}
                imgStyle={
                  CAR_ICON_SMALL
                    ? ClaimsTabStyles.carImgStyle
                    : ClaimsTabStyles.imgStyle
                }
                contentText={item.contentText}
                icons={index === 0 ? CAR_ICON_SMALL : ""}
                icon={index === 0 ? CAR_ICON_SMALL : undefined}
                parentClasses={
                  index === 0
                    ? ClaimsTabStyles.myClaimsCardWithIconStyles
                    : "none"
                }
              />
            ))}
            <Box sx={ClaimsTabStyles.trackYourClaimButton}>
              <Link href="#" sx={ClaimsTabStyles.linkStyle}>
                <Typography sx={ClaimsTabStyles.trackYourClaim}>
                  {linkText}
                </Typography>
                <img src={VIEW_ARROW} alt="arrow image" />
              </Link>
            </Box>
          </Card>
        </Box>
      ))}
    </>
  );
};

export default ClaimsItem;
