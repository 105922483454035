import { Box } from "@mui/material";
import React from "react";
import Commision from "../Commision/Commision";
import { CommissionStatementsDataProps } from "../CommissionStatementsList/CommissionStatementsList";
interface CommissionCardsProps {
  comissionStatementsData: CommissionStatementsDataProps[];
}

const CommissionCards: React.FC<CommissionCardsProps> = ({
  comissionStatementsData,
}) => {
  return (
    <>
      <Box>
        <Commision detailsdata={comissionStatementsData} />
      </Box>
    </>
  );
};

export default CommissionCards;
