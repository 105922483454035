import { Box, Typography } from "@mui/material";
import React from "react";
import { Commisionstyles } from "./Commisionstyles";
import docicon from "../../assets/svgs/document-icon.svg";
import {
  COMMISSION_AMOUNT,
  DATE,
  DOC_IMAGE,
  STATEMENT,
  STATUS,
  VIEW_DETAILS,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import clockIcon from "../../assets/svgs/clock-icon.svg";
import paidIcon from "../../assets/svgs/paid-dollar-icon.svg";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
interface DetailsProps {
  commissionDate?: string;
  commissionAmount?: string;
  commissionStatemnet?: string;
  commissionStatus?: string;
}

interface CommisionProps {
  detailsdata: DetailsProps[];
}

const Commision: React.FC<CommisionProps> = ({ detailsdata }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/monthly-statement");
    window.scrollTo({
      top: 0, 
    });
  }
  const renderStatus = (status: string | undefined) => {
    let priceText = "";
    let priceImage = "";
    if (status?.toLowerCase() === "paid") {
      priceImage = paidIcon;
      priceText = "Paid";
    } else if (status?.toLowerCase() === "pending") {
      priceImage = clockIcon;
      priceText = "Pending";
    }
    return (
      <ImageAndText
        additionalClassName={Commisionstyles.imageAndText}
        imageClassName={Commisionstyles.statusImage}
        image={priceImage}
        text={priceText}
      />
    );
  };
  return (
    <>
      <Box sx={Commisionstyles.gap}>
        {detailsdata.map((data, index) => (
          <Box sx={Commisionstyles.container} key={index}>
            <Box sx={Commisionstyles.separator}>
              <Box key={index} sx={Commisionstyles.subcontainer}>
                <Box sx={Commisionstyles.datecontainer}>
                  <Typography sx={Commisionstyles.date}>
                    {data.commissionDate}
                  </Typography>
                  <Typography sx={Commisionstyles.subdate}>{DATE}</Typography>
                </Box>
                <Box sx={Commisionstyles.amountcontainer}>
                  <Typography sx={Commisionstyles.amount}>
                    {data.commissionAmount}
                  </Typography>
                  <Typography sx={Commisionstyles.commision}>
                    {COMMISSION_AMOUNT}
                  </Typography>
                </Box>
                <Box sx={Commisionstyles.statementcontainer}>
                  <Typography sx={Commisionstyles.statement}>
                    {data.commissionStatemnet}
                  </Typography>
                  <Typography sx={Commisionstyles.substatement}>
                    {STATEMENT}
                  </Typography>
                </Box>
                <Box sx={Commisionstyles.statuscontainer}>
                  <Typography sx={Commisionstyles.status}>
                    {renderStatus(data.commissionStatus)}
                  </Typography>
                  <Typography sx={Commisionstyles.substatus}>
                    {STATUS}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={Commisionstyles.details}>
              <Box sx={Commisionstyles.image}>
                <img src={docicon} alt={DOC_IMAGE} />
              </Box>
              <Typography
                onClick={handleClick}
                sx={Commisionstyles.viewdetails}
              >
                {VIEW_DETAILS}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Commision;
