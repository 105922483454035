import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import { dateFormat } from "../../../../constants/Constants";
import PolicyCardStyles from "./PolicyCardStyles";
import {
  IDownloadIdCardParams,
  IPolicy,
} from "../../../../models/user/dashboard/Dashboard";
import { FC, useMemo, useState } from "react";
import { currencyFormat, isFeatureAvailable } from "../../../../utils/Utility";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setClaimsData } from "../../DashboardSlice";
import { setPolicyDetailsHeader } from "../../../../store/user/policyDetails/policyDetailsSlice";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";
import { GetDownloadIdCard } from "../../DashboardAction";
import { RootState } from "../../../../store/store";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface props {
  policy: IPolicy;
}

const PolicyCard: FC<props> = ({ policy }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { features } = useAppSelector((state: RootState) => state.features);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToDetails = () => {
    dispatch(setClaimsData(null));
    dispatch(setPolicyDetailsHeader(null));
    navigate(`/customer/policyDetails/${policy.policyId}`);
  };

  const handleDownloadIdCard = (policyId: string) => {
    const requestParams: IDownloadIdCardParams = {
      policyId,
    };
    dispatch(GetDownloadIdCard(requestParams));
  };

  const diffInDays = useMemo(() => {
    const dueDate = moment(policy.dueDate).startOf("day");
    const currentDate = moment().startOf("day");
    return dueDate.diff(currentDate, "days");
  }, [policy]);

  const getDueDateText = () => {
    if (diffInDays === 0) {
      return (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due: <span style={{ fontWeight: "bold" }}>Today</span>
        </Typography>
      );
    } else if (diffInDays > 0) {
      return (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due: {`${moment(policy.dueDate).format(dateFormat)}`} in{" "}
          <span style={{ fontWeight: "bold" }}>
            {diffInDays} {diffInDays === 1 ? "day" : "days"}
          </span>
        </Typography>
      );
    } else {
      return (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due: {`${moment(policy.dueDate).format(dateFormat)}`} Overdue by{" "}
          <span style={{ fontWeight: "bold" }}>
            {Math.abs(diffInDays)} {Math.abs(diffInDays) === 1 ? "day" : "days"}
          </span>
        </Typography>
      );
    }
  };

  const getIcon = (linecode: string) => {
    switch (linecode) {
      case "1":
        return <CustomIcon iconName="Car" />;
      case "2":
        return <CustomIcon iconName="Home" />;
    }
  };

  return (
    <Card sx={PolicyCardStyles.policyCard}>
      <Box sx={PolicyCardStyles.containerOne}>
        <Box sx={PolicyCardStyles.policyNumber}>
          {getIcon(policy.lineCode)}
          <Box>
            <Typography sx={PolicyCardStyles.cardTopPolicyHeading}>
              Policy Number
            </Typography>
            <Typography sx={PolicyCardStyles.cardTopPolicyNumber}>
              {policy.carrierPolicyNo}
            </Typography>
          </Box>
        </Box>
        <Box sx={PolicyCardStyles.policyStatusContainer}>
          {policy.carrierPolicyStatus ? (
            <>
              <Typography sx={PolicyCardStyles.policyStatusHeading}>
                Status
              </Typography>
              <Typography sx={PolicyCardStyles.policyStatus}>
                {policy.carrierPolicyStatus}
                {policy.carrierPolicyStatus !== "Cancelled" &&
                policy?.carrierPolicyStatus !== "Cancel Notice"
                  ? ` till ${moment(policy.expiryDate).format(dateFormat)}`
                  : ""}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Typography sx={PolicyCardStyles.productName}>
          {policy.insuredName}
          {policy.insuredNameJoint ? ` | ${policy.insuredNameJoint}` : ""}
        </Typography>
      </Box>
      <Box sx={PolicyCardStyles.premium}>
        <Typography sx={PolicyCardStyles.cardBodyPremium}>
          Premium:{" "}
          <span style={{ fontWeight: "bold" }}>
            {policy.totalPremium > 0
              ? currencyFormat("us", policy.totalPremium)
              : 0}
          </span>{" "}
          | Term: <span style={{ fontWeight: "bold" }}>{policy.tenure}</span>
        </Typography>
      </Box>
      {policy.dueDate ? (
        getDueDateText()
      ) : (
        <Typography sx={PolicyCardStyles.dueDate}>
          Due: No Pending Invoices
        </Typography>
      )}
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={PolicyCardStyles.dueDate}>
          <span style={PolicyCardStyles.vehicleCount}>
            {policy.riskCount}{" "}
            {policy.lineCode === "1"
              ? policy.riskCount > 1
                ? "Vehicles"
                : "Vehicle"
              : "Dwelling"}
          </span>
        </Typography>
        {policy.payPlanType === "Automatic" ? (
          <Box sx={PolicyCardStyles.autoDebitActive}>
            <CheckCircleOutlineIcon sx={PolicyCardStyles.checkCircleIcon} />
            <Typography sx={PolicyCardStyles.cardBodyPremiumAuto}>
              Auto Debit Active
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Box sx={PolicyCardStyles.actions}>
        {isFeatureAvailable({
          featureCodes: ["lob_pa_policies_view_policy"],
          features,
        }) ? (
          <Button
            variant="contained"
            onClick={redirectToDetails}
            disabled={policy.lineCode === "2"}
          >
            View Policy
          </Button>
        ) : (
          <></>
        )}

        {isFeatureAvailable({
          featureCodes: ["lob_pa_policies_insurance_id"],
          features,
        }) ? (
          <IconButton onClick={handleClick}>
            <MoreVertIcon sx={{ color: theme.palette.secondary.main }} />
          </IconButton>
        ) : (
          <></>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => handleDownloadIdCard(policy.policyId)}
            disabled
          >
            Download ID
          </MenuItem>
        </Menu>
      </Box>
    </Card>
  );
};

export default PolicyCard;
