export const ClaimTrackingResponsiveStyles = {
  headingContainer: {
    gap: "2px",
  },
  heading: {
    fontSize: "var(--font-size-14)",
    lineHeight: "22px",
  },
  subheading: {
    fontSize: "var(--font-size-12)",
    lineHeight: "19px",
  },
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  container: {
    marginTop: "60px",
    padding: "24px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  dashboardCrumb: {
    display: "flex",
    justifyContent: "space-between",
  },
  viewMoreContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
  },
  viewLessContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    gap: "6px",
    width: "90px",
  },
  timelineContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  timeLineRightContent: {
    display: "flex",
    gap: "14px",
  },
  dateText: {
    fontSize: "var(--font-size-14)",
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    color: "var(--support-white)",
    lineHeight: "22px",
    minWidth: "62px",
  },
  claimDetailsHeading: {
    fontSize: "var(--font-size-16)",
    borderBottom: "1px solid var(--secondary-gray)",
    paddingBottom: "10px",
  },
  claimTrackingHeader: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    flexDirection: "column",
    padding: "16px",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
    border: "1px solid var(--shaded-cyan)",
  },
  viewContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};
