export const CheckBoxStyles = {
  container: {
    display: "flex",
    gap: "12px",
    alignItems: "flex-start",
  },
  heading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  subheading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: 300,
    lineHeight: "19.07px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
};
