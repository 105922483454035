import React, { useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Popover,
  TextField,
  Button,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import ResponsiveBillingTabComponent from "./ResponsiveBillingTabCompoenent";
import filter from "../../../assets/Filter1.svg";
import BillingTabStyles from "./BillingTabStyles";
import billingData from "../../../mockJson/CustomerDashboard/BillingData.json";
import upDownArrow from "../../../assets/upDownArrow.svg";
import downloadIcon from "../../../assets/DownloadIcon.svg";
import ActionButton from "../../../ImsDashboard/commonComponents/ActionButton";
import FilterType from "../../../mockJson/CustomerDashboard/FilterType.json";
import YearToDate from "../../../mockJson/CustomerDashboard/YearToDateData.json";
import { filterText } from "../../../constants/Constants";

const BillingTabComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <Box sx={BillingTabStyles.billingTable}>
        <Box sx={BillingTabStyles.tableHeading}>
          <Button sx={BillingTabStyles.filterButton} onClick={handleClick}>
            <Box sx={BillingTabStyles.tableHeadingFilterImage}>
              <img src={filter} alt="filter" />
            </Box>
            <Typography sx={BillingTabStyles.tableHeadingFilterText}>
              {filterText}
            </Typography>
          </Button>
          <Popover
            aria-label="popoverBox"
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={BillingTabStyles.popoverStyles}
          >
            <Box sx={BillingTabStyles.popoverContent}>
              <TextField
                select
                key={FilterType[0].value}
                label="Type"
                variant="outlined"
                fullWidth
                defaultValue={FilterType[0].value}
                sx={BillingTabStyles.textField}
              >
                {FilterType.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </TextField>
              <TextField
                select
                key={YearToDate[0].value}
                label="Period"
                variant="outlined"
                fullWidth
                defaultValue={YearToDate[0].value}
                sx={BillingTabStyles.textField}
              >
                {YearToDate.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </TextField>
            </Box>
          </Popover>
        </Box>
        <Box>
          <TableContainer
            sx={BillingTabStyles.tableContainer}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow sx={BillingTabStyles.tableRow}>
                  {billingData[0] &&
                    Object.entries(billingData[0]).map(
                      ([key, value], index) =>
                        key !== "id" && (
                          <TableCell key={index}>
                            <Box
                              sx={{
                                ...BillingTabStyles.tableHeadCellContainer,
                                justifyContent:
                                  key === "billedAmount" ||
                                  key === "paidAmount" ||
                                  key === "paidOn"
                                    ? "flex-end"
                                    : "flex-start",
                              }}
                            >
                              <Typography sx={BillingTabStyles.tableHeadText}>
                                {value}
                              </Typography>
                              {!key.includes("emptyCell") && (
                                <img src={upDownArrow} alt="up-down arrow" />
                              )}
                            </Box>
                          </TableCell>
                        )
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {billingData.slice(1).map((row) => (
                  <TableRow sx={BillingTabStyles.billingTableRows} key={row.id}>
                    <TableCell sx={BillingTabStyles.tableBodyCells}>
                      {row.billingDate}
                    </TableCell>
                    <TableCell sx={BillingTabStyles.tableBodyCells}>
                      {row.dueDate}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...BillingTabStyles.tableBodyCells,
                        ...BillingTabStyles.paid,
                      }}
                    >
                      {row.billedAmount}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...BillingTabStyles.tableBodyCells,
                        ...BillingTabStyles.paid,
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      sx={{
                        ...BillingTabStyles.tableBodyCells,
                        ...BillingTabStyles.paidOn,
                      }}
                    >
                      {row.paidOn}
                    </TableCell>

                    {row.showPayIcon ? (
                      <TableCell sx={BillingTabStyles.payAndDownloadOptions}>
                        <ActionButton
                          sx={BillingTabStyles.payButton}
                          buttonText={"Pay"}
                        />
                        <img src={downloadIcon} alt="First icon" />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={BillingTabStyles.tableBodyCells}
                      ></TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box sx={BillingTabStyles.cardContainer}>
        <ResponsiveBillingTabComponent />
      </Box>
    </Box>
  );
};

export default BillingTabComponent;
