export const DocumentFolderCardStyles = {
  folderContainer: {
    display: "flex",
    gap: "15px",
    padding: "15px 18px 14px 18px",
    borderBottom: "0.5px solid var(  --primary-grey)",
    alignItems: "flex-start",
    "@media (max-width: 1023px)": {
      borderBottom: "0px",
      justifyContent: "space-between",
      padding: "0px",
    },
  },
  folderTexts: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    "@media (max-width: 1023px)": {
      gap: "3px",
      paddingTop: "10px",
    },
  },
  lastModified: {
    fontFamily: " var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--secondary-grey)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.5px",
    },
  },
  folderName: {
    fontFamily: " var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "21.79px",
    textAlign: "left",
    color: "var(--support-white)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 16px 11px 17px",
    "@media (max-width: 1023px)": {
      padding: "0px",
      paddingTop: "30px",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: "1px solid var( --shaded-cyan)",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
    boxShadow: "2px 2px 8px 0px #0000001A",
    width: "308px",
    height: "121px",
    cursor: "pointer",
    "@media (max-width: 1023px)": {
      width: "152px",
      height: "155px",
      padding: "10px",
      justifyContent: "unset",
    },
  },
  menuIcon: {
    paddingRight: "14px",
  },
  footerText: {
    color: "var(--secondary-grey)",
  },
  folderCustomIcon: {
    "@media (max-width: 1023px)": {
      width: "35px",
      height: "35px",
    },
  },
  modalContainer: {
    width: "604px",
    height: "220px",
    paddingTop: "10px",
    "@media (max-width: 1023px)": {
      width: "320px",
      height: "290px",
      paddingTop: "10px",
    },
  },
  modalHeadingContainer: {
    alignItems: "center",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "20px",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    paddingTop: "20px",
    "@media (max-width: 1023px)": {
      flexDirection: "column",
      gap: "30px",
      paddingTop: "30px",
      paddingBottom: "20px",
    },
  },
  modalHeading: {
    fontSize: "var(--font-size-16)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
      lineHeight: "normal",
    },
  },
  imageText: {
    paddingLeft: "10px",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-14)",
      lineHeight: "normal",
      paddingLeft: "0px",
    },
  },
  cancelButton: {
    boxShadow: "none",
    "@media (max-width: 1023px)": {
      width: "100%",
    },
  },
  drawerTexts: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
    borderBottom: "0.5px solid var(--primary-grey)",
  },
  drawertextHeading: {
    fontSize: "var(--font-size-18)",
    padding: "16px 24px 12px 24px",
    borderBottom: "1px solid var(--primary-grey)",
  },
  lastText: {
    fontSize: "var(--font-size-16)",
    lineHeight: "21.79px",
    fontWeight: "var(--font-weight-400)",
    color: "var(--primary-black)",
    fontFamily: "var(--font-noto-sans)",
    paddingBottom: "12px",
    paddingTop: "12px",
  },
  drawer: {
    width: "100%",
    padding: "0px",
  },
  optionsContainer: {
    padding: "0px 24px 16px 24px",
  },
  menuIconClass: {
    paddingLeft: "3px",
    paddingRight: "3px",
  },
  toolTipmenuIcon: {
    paddingLeft: "3px",
    paddingRight: "3px",
  },
};
