export const calenderData = [
  {
    id: "1",
    date: "2024-10-15",
    isMeetingsAvailable: true,
    meetings: [
      {
        meetingId: "102",
        title: "scheduled calendar",
        startTime: "2024-10-15T09:00:00",
        endTime: "2024-10-15T10:00:00",
        withWhom: "venkata anantha lakshmi Akhila sri satya",
        taskDetails: {
          timeStamp: {
            date: "15 Oct",
            time: "9:00 AM",
          },
          task: "Renew auto insurance policy",
          clientDetails: {
            clientName: "John Smith",
            clientEmail: "johnsmith@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA82345678",
          priority: "Medium",
          status: "Pending",
        },
      },
      {
        meetingId: "103",
        title: "scheduled calendar",
        startTime: "2024-10-15T10:30:00",
        endTime: "2024-10-15T11:00:00",
        withWhom: "venkata anantha lakshmi Akhila sri satya",
        taskDetails: {
          timeStamp: {
            date: "15 Oct",
            time: "10:30 AM",
          },
          task: "Process claim for recent accident",

          clientDetails: {
            clientName: "Mary Johnson",
            clientEmail: "marysmith@gmail.com",
            clientPhone: "(+1) - 9019877686",
          },
          policy: "#PA91456789",
          priority: "High",
          status: "In progress",
        },
      },
      {
        meetingId: "104",
        title: "scheduled calendar",
        startTime: "2024-10-15T11:15:00",
        endTime: "2024-10-15T11:40:00",
        withWhom: "venkata anantha lakshmi Akhila sri satya",
        taskDetails: {
          timeStamp: {
            date: "15 Oct",
            time: "11:15 AM",
          },
          task: "Update homeowner's policy after renovation",
          clientDetails: {
            clientName: "Michael Williams",
            clientEmail: "michael@gmail.com",
            clientPhone: "(+1) - 9019077656",
          },
          policy: "#HO63789012",
          priority: "Medium",
          status: "Pending",
        },
      },
    ],
  },

  {
    id: "3",
    date: "2024-10-16",
    isMeetingsAvailable: true,
    meetings: [
      {
        meetingId: "105",
        title: "MRI Registration",
        startTime: "2024-10-16T09:45:00",
        endTime: "2024-10-16T10:30:00",
        withWhom: "John2",
        taskDetails: {
          timeStamp: {
            date: "16 Oct",
            time: "9:45 AM",
          },
          task: "Address lapse in payment",

          clientDetails: {
            clientName: "Jennifer Brown",
            clientEmail: "jennifer@gmail.com",
            clientPhone: "(+1) - 9019877656",
          },
          policy: "#PA75234567",
          priority: "High",
          status: "active",
        },
      },
      {
        meetingId: "106",
        title: "MRI ",
        startTime: "2024-10-16T14:00:00",
        endTime: "2024-10-16T14:30:00",
        withWhom: "John3",
        taskDetails: {
          timeStamp: {
            date: "16 Oct",
            time: "2:00 PM",
          },
          task: "Schedule inspection for new property",
          clientDetails: {
            clientName: "Robert Brown",
            clientEmail: "robert@gmail.com",
            clientPhone: "(+1) - 8013877656",
          },
          policy: "#HO83901234",
          priority: "High",
          status: "Rescheduled",
        },
      },
      {
        meetingId: "107",
        title: "MRI ",
        startTime: "2024-10-16T13:00:00",
        endTime: "2024-10-16T13:30:00",
        withWhom: "John3",
        taskDetails: {
          timeStamp: {
            date: "16 Oct",
            time: "1:30 PM",
          },
          task: "Send reminder for policy renewal",
          clientDetails: {
            clientName: "Linda Miller",
            clientEmail: "linda@gmail.com",
            clientPhone: "(+1) - 9013890656",
          },
          policy: "#HO69876543",
          priority: "Medium",
          status: "Pending",
        },
      },
    ],
  },
  {
    id: "4",
    date: "2024-10-17",
    isMeetingsAvailable: true,
    meetings: [
      {
        meetingId: "111",
        title: "scheduled calendar",
        startTime: "2024-10-17T15:00:00",
        endTime: "2024-10-17T16:00:00",
        withWhom: "William Davis",
        taskDetails: {
          timeStamp: {
            date: "17 Oct",
            time: "3:00 PM",
          },
          task: "Review coverage options with client",

          clientDetails: {
            clientName: "William Davis",
            clientEmail: "william@gmail.com",
            clientPhone: "(+1) - 9010877656",
          },
          policy: "#HO50345678",
          priority: "Medium",
          status: "In progress",
        },
      },
      {
        meetingId: "112",
        title: "scheduled calendar",
        startTime: "2024-10-17T09:00:00",
        endTime: "2024-10-17T10:00:00",
        withWhom: "Elizabeth Garcia",
        taskDetails: {
          timeStamp: {
            date: "17 Oct",
            time: "9:00 AM",
          },
          task: "Update client contact information",
          clientDetails: {
            clientName: "Elizabeth Garcia",
            clientEmail: "elizabeth@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#HO91567890",
          priority: "Medium",
          status: "Pending",
        },
      },
      {
        meetingId: "113",
        title: "scheduled calendar",
        startTime: "2024-10-17T10:30:00",
        endTime: "2024-10-17T11:00:00",
        withWhom: "David Rodriguez",
        taskDetails: {
          timeStamp: {
            date: "17 Oct",
            time: "10:30 AM",
          },
          task: "Follow up on claim status",
          clientDetails: {
            clientName: "David Rodriguez",
            clientEmail: "davidrodiguz@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA28678901",
          priority: "Medium",
          status: "Pending",
        },
      },
    ],
  },
  {
    id: "5",
    date: "2024-10-18",
    isMeetingsAvailable: true,
    meetings: [
      {
        meetingId: "117",
        title: "scheduled calendar",
        startTime: "2024-10-18T11:00:00",
        endTime: "2024-10-18T12:00:00",
        withWhom: "Barbara Wilson",
        taskDetails: {
          timeStamp: {
            date: "18 Oct",
            time: "11:00 AM",
          },
          task: "Provide quote for additional coverage",
          clientDetails: {
            clientName: "Barbara Wilson",
            clientEmail: "wilson@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA12789012",
          priority: "Medium",
          status: "Rescheduled",
        },
      },
      {
        meetingId: "118",
        title: "scheduled calendar",
        startTime: "2024-10-18T14:30:00",
        endTime: "2024-10-18T15:30:00",
        withWhom: "Richard Martinez",
        taskDetails: {
          timeStamp: {
            date: "18 Oct",
            time: "2:30 PM",
          },
          task: "Send newsletter to client",
          clientDetails: {
            clientName: "Richard Martinez",
            clientEmail: "richard@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA33456789",
          priority: "Low",
          status: "Pending",
        },
      },
      {
        meetingId: "119",
        title: "scheduled calendar",
        startTime: "2024-10-18T15:45:00",
        endTime: "2024-10-18T16:45:00",
        withWhom: "Susan Anderson",
        taskDetails: {
          timeStamp: {
            date: "18 Oct",
            time: "3:45 PM",
          },
          task: "Update client on new policy offerings",
          clientDetails: {
            clientName: "Susan Anderson",
            clientEmail: "susan@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA44567890",
          priority: "Low",
          status: "Pending",
        },
      },
    ],
  },
  {
    id: "6",
    date: "2024-10-19",
    isMeetingsAvailable: true,
    meetings: [
      {
        meetingId: "121",
        title: "scheduled calendar",
        startTime: "2024-10-19T16:15:00",
        endTime: "2024-10-19T17:15:00",
        withWhom: "Joseph Taylor",
        taskDetails: {
          timeStamp: {
            date: "19 Oct",
            time: "4:15 PM",
          },
          task: "Conduct annual policy review",
          clientDetails: {
            clientName: "Joseph Anderson",
            clientEmail: "susan@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA55678901",
          priority: "Low",
          status: "In progress",
        },
      },
      {
        meetingId: "122",
        title: "scheduled calendar",
        startTime: "2024-10-19T09:00:00",
        endTime: "2024-10-19T10:00:00",
        priorityType: "low",
        withWhom: "Jessica Thomas",
        taskDetails: {
          timeStamp: {
            date: "19 Oct",
            time: "9:00 AM",
          },
          task: "Confirm receipt of documents",
          clientDetails: {
            clientName: "Jessica Anderson",
            clientEmail: "susan@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#HO66789012",
          priority: "Low",
          status: "Pending",
        },
      },
      {
        meetingId: "123",
        title: "scheduled calendar",
        startTime: "2024-10-19T10:15:00",
        endTime: "2024-10-19T11:15:00",
        withWhom: "Thomas Hernandez",
        taskDetails: {
          timeStamp: {
            date: "19 Oct",
            time: "10:15 AM",
          },
          task: "Check in with client for feedback",
          clientDetails: {
            clientName: "Thomas Anderson",
            clientEmail: "susan@gmail.com",
            clientPhone: "(+1) - 9013877656",
          },
          policy: "#PA77890123",
          priority: "Low",
          status: "Rescheduled",
        },
      },
    ],
  },
];
