export const PieChartToolTipStyles = {
    heading: {
        fontFamily: "var(--font-noto-sans)",
        fontSize: "var(--font-size-14)",
        fontWeight: "var(--font-weight-400)",
        lineHeight: "22px",
        textAlign: "left",
    },
    subheading: {
        fontFamily: "var(--font-noto-sans)",
        fontSize: "var(--font-size-14)",
        fontWeight: "var(--font-weight-300)",
        lineHeight: "19px",
        textAlign: "left",
        color: "var(--secondary-grey)",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        height: "42px",
    },
};
