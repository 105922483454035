import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getUiSettings,
  postUiSettings,
} from "../../../store/admin/uiSettings/uiSettingsActions";
import MessageDialog from "../../../components/MessageDialog/MessageDialog";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import { RootState } from "../../../store/store";
import { MuiColorInput } from "mui-color-input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import {
  IPostUiSettingsRequestBody,
  IUiSettings,
} from "../../../models/admin/uiSettings/uiSettings";
import CustomIcon from "../../../common/CustomIcon/CustomIcon";
import CustomDialog from "../../../common/CustomDialog/CustomDialog";
import CustomerLoginPreview from "./CustomerLoginPreview";

interface IProps {}

const UiSettings: FC<IProps> = () => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const dispatch = useAppDispatch();

  const { uiSettings } = useAppSelector((state: RootState) => state.uiSettings);
  const { isSuccess, isError, calledReducerType } = useAppSelector(
    (state: RootState) => state.common
  );

  const [formData, setFormData] = useState<IUiSettings>({});

  const [originalData, setOriginalData] = useState<IUiSettings>({});
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState<boolean>(false);

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const img = new Image();
      const reader = new FileReader();

      // Check for unsupported file types
      if (!file.type.startsWith("image/")) {
        alert("Please upload a valid image file. Only images are allowed.");
        return; // Stop processing if it's not an image
      }

      reader.onload = (e) => {
        console.log(e);
        img.src = e.target?.result as string;
        img.onload = () => {
          // Specific dimension check for loginBanner
          if (fieldName === "loginBanner") {
            const isValidDimensions = img.width === 788 && img.height === 644;
            if (!isValidDimensions) {
              alert("The login banner must be 788 x 644 pixels.");
              return;
            }
          }
          // If valid, update formData with the file
          setFormData((prevState) => ({ ...prevState, [fieldName]: file }));
        };
      };

      reader.readAsDataURL(file);
    }
  };
  const handleFileDelete = useCallback(
    (key: keyof IUiSettings) => {
      setFormData((prevState) => ({ ...prevState, [key]: originalData[key] }));
    },
    [originalData]
  );

  const handleCancelClick = useCallback(() => {
    setFormData(originalData);
  }, [originalData]);

  const handleSave = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAlertDialogOpen(true);
  }, []);

  const handleAlertDialogCancelClick = useCallback(() => {
    setAlertDialogOpen(false);
  }, []);

  const handleAlertDialogOkClick = useCallback(() => {
    setAlertDialogOpen(false);
    const newFormData: IPostUiSettingsRequestBody = new FormData();

    const appendFormData = (
      formData: FormData,
      data: any,
      parentKey: string | null = null
    ) => {
      Object.keys(data).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        const value = data[key];

        if (value instanceof File) {
          formData.append(fullKey, value);
        } else if (typeof value === "object" && value !== null) {
          formData.append(fullKey, JSON.stringify(value));
        } else if (typeof value === "string" || value !== null) {
          formData.append(fullKey, value);
        }
      });
    };

    if (formData) {
      appendFormData(newFormData, formData);
      dispatch(postUiSettings(newFormData));
    }
  }, [dispatch, formData]);

  const handleMessageDialogClose = useCallback(() => {
    setMessageDialogOpen(false);
    dispatch(getUiSettings());
  }, [dispatch]);

  useEffect(() => {
    if (uiSettings) {
      setFormData(uiSettings);
      setOriginalData(uiSettings);
    }
  }, [uiSettings]);

  useEffect(() => {
    if (
      (isSuccess || isError) &&
      calledReducerType === "uiSettings/postUiSettings"
    ) {
      setMessageDialogOpen(true);
      if (isSuccess) {
        setOriginalData(formData);
      }
    }
  }, [isSuccess, isError, calledReducerType, formData]);

  const getImageSrc = (image: string | File | undefined) => {
    if (!image) return "";

    if (typeof image === "string") {
      return image.startsWith("data:image") || image.startsWith("http")
        ? image
        : `data:image/png;base64,${image}`;
    }

    return URL.createObjectURL(image);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
        }}
      >
        <Card>
          <form onSubmit={handleSave}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Logo</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => handleFileChange(event, "logo")}
                    />
                  </Button>
                  <Box>
                    {formData && formData.logo instanceof File ? (
                      <Chip
                        label={formData.logo.name}
                        onDelete={() => handleFileDelete("logo")}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Login Banner</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, "loginBanner")
                      }
                    />
                  </Button>
                  <Box>
                    {formData && formData.loginBanner instanceof File ? (
                      <Chip
                        label={formData.loginBanner.name}
                        onDelete={() => handleFileDelete("loginBanner")}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Icon Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={
                    formData && formData.iconColor ? formData.iconColor : ""
                  }
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      iconColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Primary Button Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={formData?.lightPrimaryMainColor || ""}
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      lightPrimaryMainColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Secondary Button Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={formData.lightSecondaryMainColor || ""}
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      lightSecondaryMainColor: newValue,
                    }))
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Primary Button Text Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={formData.lightPrimaryContrastTextColor || ""}
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      lightPrimaryContrastTextColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1.5fr",
                  alignItems: "center",
                }}
              >
                <Typography>Secondary Button Text Color</Typography>
                <MuiColorInput
                  format="hex"
                  value={formData.lightSecondaryContrastTextColor || ""}
                  onChange={(newValue) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      lightSecondaryContrastTextColor: newValue,
                    }))
                  }
                />
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }}>
                <Button
                  variant="contained"
                  sx={{ width: "max-content", marginTop: "20px" }}
                  onClick={() => setPreviewDialogOpen(true)}
                  disabled={
                    JSON.stringify(formData) === JSON.stringify(originalData)
                  }
                >
                  Preview
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "max-content",
                    marginTop: "20px",
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: "max-content",
                    marginTop: "20px",
                  }}
                  disabled={
                    JSON.stringify(formData) === JSON.stringify(originalData)
                  }
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </form>
        </Card>
        <Card>
          <CardHeader title="Current Configuration" />
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {formData && (
              <>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1fr",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Typography variant="h5">Logo</Typography>
                  {uiSettings && uiSettings.logo && (
                    <img
                      src={getImageSrc(uiSettings.logo)}
                      alt="Logo Preview"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1fr",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Typography variant="h5">Login Banner</Typography>
                  {uiSettings && uiSettings.loginBanner && (
                    <img
                      src={getImageSrc(uiSettings.loginBanner)}
                      alt="Login Banner Preview"
                      style={{ maxWidth: "150px", maxHeight: "150px" }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Primary Button</Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: formData.lightPrimaryMainColor,
                      color: formData.lightPrimaryContrastTextColor,
                    }}
                  >
                    Text
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Secondary Button </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: formData.lightSecondaryMainColor,
                      color: formData.lightSecondaryContrastTextColor,
                    }}
                  >
                    Text
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "0.5fr 1.5fr",
                    alignItems: "center",
                  }}
                >
                  <Typography>Icon</Typography>
                  <CustomIcon iconName="RoadsideAssistance" />
                </Box>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
      <AlertDialog
        handleCancelClick={handleAlertDialogCancelClick}
        handleOkClick={handleAlertDialogOkClick}
        open={alertDialogOpen}
        title="Saving this configuration will impact the end user, is this okay?"
      />
      <MessageDialog
        handleClose={handleMessageDialogClose}
        open={messageDialogOpen}
      />
      <CustomDialog
        maxWidth="xl"
        onClose={() => setPreviewDialogOpen(false)}
        open={previewDialogOpen}
        showDivider={false}
        content={
          <CustomerLoginPreview
            isFunctionable={false}
            loginBannerSrc={formData.loginBanner || ""}
            logoSrc={formData.logo || ""}
          />
        }
        title="Preview"
      />
    </>
  );
};

export default UiSettings;
