import React from "react";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import { Box, Typography } from "@mui/material";
import { STATUS, TASK, TASK_DATE } from "../../constants";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import highPriority from "../../assets/svgs/high.svg";
import mediumPriority from "../../assets/svgs/medium.svg";
import lowPriority from "../../assets/svgs/low.svg";
import clockIcon from "../../assets/svgs/clock-icon.svg";
import { MyTasksCalendarCardStyles } from "./MyTasksCalendarCardStyles";
import inProgressIcon from "../../assets/svgs/in-progress.svg";
import reScheduleIcon from "../../assets/svgs/re-schedule.svg";
import activeIcon from "../../assets/svgs/active-icon.svg";

interface DateProps {
  date: string;
  time: string;
}
interface MyTasksCustomerDetailsProps {
  clientName: string;
  clientEmail: string;
  clientPhone: string;
}
interface MyTasksCalendarCardDataProps {
  task: string;
  priority: string;
  status: string;
  clientDetails: MyTasksCustomerDetailsProps;
  timeStamp: DateProps;
  policy: string;
}
interface MyTasksCalendarCardProps {
  data: MyTasksCalendarCardDataProps;
}

const MyTasksCalendarCard: React.FC<MyTasksCalendarCardProps> = ({ data }) => {
  const renderPriority = (priority: string) => {
    let priorityText = "";
    let priorityImage = "";
    if (priority.toLowerCase() === "high") {
      priorityImage = highPriority;
      priorityText = "High Priority";
    }
    if (priority.toLowerCase() === "low") {
      priorityImage = lowPriority;
      priorityText = "Low Priority";
    }
    if (priority.toLowerCase() === "medium") {
      priorityImage = mediumPriority;
      priorityText = "Medium Priority";
    }
    return (
      <ImageAndText
        additionalClassName={MyTasksCalendarCardStyles.priorityContainer}
        image={priorityImage}
        text={priorityText}
      />
    );
  };
  const renderStatus = (statusRenderer: string) => {
    let statusImage = "";
    let statusText = "";
    if (statusRenderer.toLowerCase() === "pending") {
      statusImage = clockIcon;
      statusText = "Pending";
    }
    if (statusRenderer.toLowerCase() === "active") {
      statusImage = activeIcon;
      statusText = "Active";
    }
    if (statusRenderer.toLowerCase() === "in progress") {
      statusImage = inProgressIcon;
      statusText = "In Progress";
    }
    if (statusRenderer.toLowerCase() === "rescheduled") {
      statusImage = reScheduleIcon;
      statusText = "Rescheduled";
    }

    return (
      <ImageAndText
        additionalClassName={MyTasksCalendarCardStyles.statusContainer}
        image={statusImage}
        text={statusText}
      />
    );
  };
  return (
    <Box sx={MyTasksCalendarCardStyles.container}>
      <Box sx={MyTasksCalendarCardStyles.priorityHeadingContainer}>
        <HeadingSubHeading
          headingAdditionalClassName={MyTasksCalendarCardStyles.heading}
          subHeadingAdditionalClassName={MyTasksCalendarCardStyles.subheading}
          containerAdditionalClassName={
            MyTasksCalendarCardStyles.headingContainer
          }
          heading={data.clientDetails.clientName}
          subheading={data.policy}
        />

        <Box>{renderPriority(data.priority)}</Box>
      </Box>
      <Box sx={MyTasksCalendarCardStyles.lowerContainer}>
        <Box sx={MyTasksCalendarCardStyles.clientDetails}>
          <Box sx={MyTasksCalendarCardStyles.headingContainer}>
            <Typography sx={MyTasksCalendarCardStyles.heading}>
              {STATUS}
            </Typography>
            <Box>{renderStatus(data.status)}</Box>
          </Box>
          <HeadingSubHeading
            headingAdditionalClassName={MyTasksCalendarCardStyles.heading}
            subHeadingAdditionalClassName={MyTasksCalendarCardStyles.subheading}
            containerAdditionalClassName={
              MyTasksCalendarCardStyles.headingContainer
            }
            heading={TASK_DATE}
            subheading={data.timeStamp.date + " " + data.timeStamp.time}
          />
        </Box>
        <HeadingSubHeading
          headingAdditionalClassName={MyTasksCalendarCardStyles.heading}
          subHeadingAdditionalClassName={MyTasksCalendarCardStyles.subheading}
          containerAdditionalClassName={
            MyTasksCalendarCardStyles.headingContainer
          }
          heading={TASK}
          subheading={data.task}
        />
      </Box>
    </Box>
  );
};

export default MyTasksCalendarCard;
