import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ActionButton from "../ImsDashboard/commonComponents/ActionButton";
import WhiteButton from "../PolicyDetails/common/WhiteButton";
import tickMark from "../assets/Grid.svg";
import dotImage from "../assets/dot.svg";
import document from "../assets/fileDocument.svg";
import trashBin from "../assets/trash-2.svg";
import uploadFile from "../assets/upload.svg";
import closeImage from "../assets/x-circle.svg";
import {
  clickToUpload,
  complete,
  fileAClaim,
  fileAClaimVehicle,
  incidentDescription,
  incidentLocation,
  policyHash,
  policyTypeLabel,
} from "../constants/Constants";
import { policyType } from "../constants/PolicyType";
import { policyNumber } from "../constants/policyNumber";
import { vechileName } from "../constants/vechileName";
import { incidentType } from "../constants/vechileType";
import fileAClaimJosn from "../mockJson/CustomerDashboard/FileAClaim.json";
import FileAClaimStyles from "./FileAClaimStyles";
import Acknowledgement from "../Acknowledgement/Acknowledgement";
interface FileAClaimProps {
  onClose: () => void;
}
const FileAClaim: React.FC<FileAClaimProps> = ({ onClose }) => {
  console.log(fileAClaimJosn[0].policyType);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState(
    dayjs(fileAClaimJosn[0].incidentDate)
  );
  const [selectedTime, setSelectedTime] = useState(
    dayjs(fileAClaimJosn[0].incidentTime)
  );

  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDateChange = (date: any) => {
    console.log(date);
    setSelectedDate(date);
  };
  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
  };
  const handleSubmit = () => {
    setFormSubmitted(true);
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > 1024) {
        setFileSize((fileSizeInKB / 1024).toFixed(2) + "Mb");
      } else {
        setFileSize(fileSizeInKB.toFixed(2) + "Kb");
      }
    } else {
      setFileName("");
      setFileSize(null);
    }
  };

  const handleDelete = () => {
    console.log(fileName);
    setFileName("");
  };
  const isMobile = windowWidth <= 1023;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          ...FileAClaimStyles.container,
          overflowY: formSubmitted ? "hidden" : "scroll",
        }}
      >
        <Box sx={FileAClaimStyles.heading}>
          <Typography sx={FileAClaimStyles.headingText}>
            {fileAClaim}
          </Typography>
          <Box>
            <img onClick={onClose} src={closeImage} alt="closing-tag" />
          </Box>
        </Box>
        {!formSubmitted ? (
          <Box sx={FileAClaimStyles.formContainer}>
            <Box sx={FileAClaimStyles.formsContainer}>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <InputLabel id="Policy Type" sx={FileAClaimStyles.policyLabel}>
                  {policyTypeLabel}
                </InputLabel>
                <Select
                  labelId="Policy Type"
                  id="Policy Type"
                  label="Policy Type"
                  inputProps={{ sx: FileAClaimStyles.inputField }}
                  value={fileAClaimJosn[0].policyType}
                >
                  {policyType.map((policy) => (
                    <MenuItem key={policy} value={policy}>
                      {policy}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <InputLabel id="Policy #" sx={FileAClaimStyles.policyLabel}>
                  {policyHash}
                </InputLabel>
                <Select
                  labelId="Policy #"
                  id="Policy #"
                  label="Policy#"
                  inputProps={{ sx: FileAClaimStyles.inputField }}
                  value={fileAClaimJosn[0].policyNumber}
                >
                  {policyNumber.map((policyNumber) => (
                    <MenuItem key={policyNumber} value={policyNumber}>
                      {policyNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <InputLabel id="Vehicle" sx={FileAClaimStyles.policyLabel}>
                  {fileAClaimVehicle}
                </InputLabel>
                <Select
                  labelId="Vehicle"
                  id="Vehicle"
                  label="Vehicle"
                  inputProps={{ sx: FileAClaimStyles.inputField }}
                  value={fileAClaimJosn[0].vechile}
                >
                  {vechileName.map((vechile) => (
                    <MenuItem key={vechile} value={vechile}>
                      {vechile}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <InputLabel
                  id="Incident Type"
                  sx={FileAClaimStyles.policyLabel}
                >
                  Incident Type
                </InputLabel>
                <Select
                  labelId="Incident Type"
                  id="Incident Type"
                  label="Incident Type"
                  inputProps={{ sx: FileAClaimStyles.inputField }}
                  value={fileAClaimJosn[0].incidentType}
                >
                  {incidentType.map((incidentType) => (
                    <MenuItem key={incidentType} value={incidentType}>
                      {incidentType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  sx={FileAClaimStyles.textFields}
                />
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <TimePicker
                  label="Select Time"
                  value={selectedTime}
                  onChange={handleTimeChange}
                  sx={FileAClaimStyles.textFields}
                />
              </FormControl>
              <FormControl
                fullWidth
                sx={{
                  width: isMobile ? 300 : 760,
                }}
              >
                <InputLabel
                  htmlFor="Incident Location"
                  sx={FileAClaimStyles.policyLabel}
                >
                  {incidentLocation}
                </InputLabel>
                <OutlinedInput
                  label="Incident Location"
                  value={fileAClaimJosn[0].location}
                  inputProps={{ sx: FileAClaimStyles.textFields }}
                />
              </FormControl>
              <FormControl
                sx={{
                  width: isMobile ? 300 : 760,
                }}
              >
                <InputLabel
                  htmlFor="Incident Description"
                  sx={FileAClaimStyles.policyLabel}
                >
                  {incidentDescription}
                </InputLabel>
                <OutlinedInput
                  label="Incident Description"
                  value={fileAClaimJosn[0].description}
                  inputProps={{ sx: FileAClaimStyles.textFields }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ width: isMobile ? 300 : 360 }}>
                <Button
                  sx={FileAClaimStyles.fileUploadButton}
                  variant="outlined"
                  component="label"
                >
                  <Box sx={FileAClaimStyles.uplaodBox}>
                    <img src={uploadFile} alt="upload" />
                    {clickToUpload}
                    <input type="file" onChange={handleFileChange} hidden />
                  </Box>
                </Button>
                <Box>
                  {fileName && (
                    <Box sx={FileAClaimStyles.imageDisplayerBox}>
                      <Box sx={FileAClaimStyles.documentImage}>
                        <img src={document} alt="document-text" />
                      </Box>
                      <Box sx={FileAClaimStyles.fileNames}>
                        <Tooltip title={fileName}>
                          <Typography sx={FileAClaimStyles.fileNameText}>
                            {fileName}
                          </Typography>
                        </Tooltip>
                        <Box sx={FileAClaimStyles.fileSizeBox}>
                          <Typography sx={FileAClaimStyles.fileSizeText}>
                            {fileSize}
                          </Typography>
                          <img src={dotImage} alt="dot" />
                          <Typography sx={FileAClaimStyles.completedText}>
                            {complete}
                          </Typography>
                        </Box>
                      </Box>
                      <img
                        onClick={handleDelete}
                        src={trashBin}
                        alt="trash-bin"
                      />
                      <img src={tickMark} alt="tickMark" />
                    </Box>
                  )}
                </Box>
              </FormControl>
            </Box>
            <Box sx={FileAClaimStyles.buttons}>
              <WhiteButton
                buttonStyles={FileAClaimStyles.cancelButton}
                buttonText={"Cancel"}
                onClick={onClose}
              />
              <ActionButton
                sx={FileAClaimStyles.submitButton}
                buttonText={"Submit"}
                onClick={handleSubmit}
              />
            </Box>
          </Box>
        ) : (
          <Acknowledgement />
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default FileAClaim;
