export const ChatStyles = {
  chat: {
    display: "flex",
    backgroundColor: "var( --shaded-blue)",
    boxShadow: "0px 1px 4px 0px #00000026",
    width: "532px",
    flexDirection: "column",
    borderRadius: "8px",
    gap: "10px",
    padding: "10px 15px",
    "@media (max-width: 1023px)": {
      width: "93.6%",
      padding: "15px 10px",
    },
  },
  userChat: {
    display: "flex",
    backgroundColor: "var( --shaded-grey)",
    boxShadow: "0px 1px 4px 0px #00000026",
    width: "532px",
    flexDirection: "column",
    borderRadius: "8px",
    gap: "10px",
    alignSelf: "flex-end",
    marginTop: "25px",
    padding: "10px 15px",
    "@media (max-width: 1023px)": {
      width: "93.6%",
      alignSelf: "flex-end",
      borderradius: "10px",
      padding: "15px 10px",
    },
  },
  userDetails: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  message: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "19.07px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  clientDetails: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    textAlign: "left",
    color: "var(--primary-black)",
  },
  clientChat: {
    display: "flex",
    gap: "3px",
  },
};
