import React from "react";
import { Box } from "@mui/material";
import { IPolicyDetailsHeader } from "../../../models/user/policyDetails/PolicyDetails";
import PolicyDetailsHeaderStyles from "./PolicyDetailsHeaderStyles";
import BasicInfoCard from "./BasicInfoCard/BasicInfoCard";
import BasicInfoCardQuickActions from "./BasicInfoCardQuickActions/BasicInfoCardQuickActions";

interface IProps {
  data: IPolicyDetailsHeader | null;
  handlePayment: () => void;
}

const PolicyDetailsHeader: React.FC<IProps> = ({
  data,
  handlePayment,
}: IProps) => {
  return (
    <Box
      sx={PolicyDetailsHeaderStyles.BasicInfoCard}
      aria-label="Basic Info Container"
    >
      <BasicInfoCard data={data} />
      <BasicInfoCardQuickActions handlePayment={handlePayment} />
    </Box>
  );
};

export default PolicyDetailsHeader;
