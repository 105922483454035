const ActivityTabStyles = {
  container: {
    background: "rgba(255, 255, 255, 1)",
    overflow: "visible",
    marginTop: "8px",
    "@media (min-width:360px) and (max-width:1024px)": {
      background: "none",
    },
    "@media (min-width:601px) and (max-width:1024px)": {
      marginTop: "30px",
    },
    "@media (min-width:360px) and (max-width:600px)": {
      marginTop: "-16px",
    },
  },
  active: {
    width: "100%",
  },
  carrier: {
    flex: 1,
    padding: "30px 0px 61px 0px",
  },
  filter: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginTop: "20.5px",
    justifyContent: "flex-end",
    cursor: "pointer",

    "@media (max-width:599px)": {
      marginLeft: "75%",
    },
    "@media (max-width:799px)": {
      marginTop: "-35.5px",
    },
  },
  tableHeadingFilterImage: {
    img: {
      width: "24px",
      height: "24px",
    },
  },
  tableHeadingFilterText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    marginTop: "-5px",
    textTransform: "none",
  },
  filterButton: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "5px 0px",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  popoverContent: {
    width: "340px",
    height: "172px",
    padding: "20px 20px 0px 20px",
    gap: "20px",
    borderRadius: "4px",
  },
  textField: {
    width: "300px",
    height: "56px",
    marginBottom: "20px",
    fontFamily: "Noto Sans",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
    textAlign: "left",
  },
  popOver: {
    "& .MuiPopover-paper": {
      left: "unset !important",
      right: "0px !important",
      "@media (max-width: 1024px)": {
        right: "7px !important",
      },
    },
    "@media (max-width: 599px)": {
      "& .MuiPopover-paper": {
        left: "unset !important",
        right: "7px !important",
      },
    },
  },
};
export default ActivityTabStyles;
