import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { RootState } from "../../../../../store/store";
import {
  GetInsuranceDocumentContent,
  GetPolicyActivities,
} from "../../../../../store/user/policyDetails/policyDetailsActions";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import CustomTimeline from "../../../../../common/CustomTimeline/CustomTimeline";
import { dateFormat } from "../../../../../constants/Constants";
import moment from "moment";
import ActivityCard from "./ActivityCard/ActivityCard";
import { Typography, useMediaQuery } from "@mui/material";
import {
  IDocument,
  IDocumentContentRequest,
} from "../../../../../models/user/policyDetails/Document";
import { getBufferData } from "../../../../../utils/Utility";

const Policies = () => {
  const { policyId } = useParams();
  const dispatch = useAppDispatch();

  const { policyDetailsHeader, policyTransactions, insuranceDocumentContent } =
    useAppSelector((state: RootState) => state.policyDetails);
  const { isSuccess, calledReducerType, isError } = useAppSelector(
    (state: RootState) => state.common
  );

  const isMobile = useMediaQuery("(max-width:1024px)");

  const [actionType, setActionType] = useState<string | null>(null);
  const [currentClickFile, setCurrentClickFile] = useState<IDocument | null>(
    null
  );

  const handleFileClick = useCallback(
    (type: string, document: IDocument | null) => {
      if (!policyId || !document) return;
      setActionType(type);
      setCurrentClickFile(document);
      const requestParams: IDocumentContentRequest = {
        policyId,
        documentId: document.documentId,
      };
      dispatch(GetInsuranceDocumentContent(requestParams));
    },
    [dispatch, policyId]
  );

  useEffect(() => {
    if (policyId && policyDetailsHeader?.accountId) {
      dispatch(
        GetPolicyActivities({
          policyId,
        })
      );
    }
  }, [policyId, policyDetailsHeader?.accountId, dispatch]);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetPolicyDocumentContent" &&
      insuranceDocumentContent &&
      actionType
    ) {
      const bufferArray = getBufferData(insuranceDocumentContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      if (actionType === "view") {
        window.open(url, "_blank");
      } else {
        const downloadLink = document.createElement("a");
        downloadLink.download = `New Business Package-Apr 20, 2024.pdf`;
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  }, [
    isSuccess,
    calledReducerType,
    insuranceDocumentContent,
    actionType,
    currentClickFile,
  ]);

  useEffect(() => {
    if (
      isError &&
      calledReducerType === "GetPolicyDocumentContent" &&
      actionType
    ) {
      alert(`Error in ${actionType} document`);
    }
  }, [isError, calledReducerType, actionType]);

  return policyTransactions.length > 0 ? (
    <Timeline
      sx={{
        padding: "0px",
        [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.1 },
      }}
    >
      {[...policyTransactions]
        .reverse()
        // .sort((a, b) => {
        //   const dateA = new Date(a.transactionDate);
        //   const dateB = new Date(b.transactionDate);

        //   if (dateA.getTime() !== dateB.getTime()) {
        //     return dateB.getTime() - dateA.getTime();
        //   }

        //   const parseTime = (timeString: string) => {
        //     if (!timeString) return 0;

        //     const timeWithoutZone = timeString.split(" ")[0];

        //     const [hours, minutes, seconds] = timeWithoutZone
        //       .split(":")
        //       .map(Number);

        //     return hours * 3600000 + minutes * 60000 + seconds * 1000;
        //   };

        //   const timeA = parseTime(a.transactionTime);
        //   const timeB = parseTime(b.transactionTime);

        //   return timeB - timeA;
        // })
        .map((transaction, index) => (
          <CustomTimeline
            key={`policy-activity-${index}`}
            oppositeContent={moment(transaction.transactionDate).format(
              dateFormat
            )}
            oppositeContentStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            dotStyle={{
              display: "flex",
              alignItems: "center",
            }}
            children={
              <ActivityCard
                transaction={transaction}
                isLast={
                  index ===
                  (policyTransactions
                    ? isMobile
                      ? policyTransactions.length - 2
                      : policyTransactions.length - 1
                    : false)
                }
                handleFileClick={handleFileClick}
              />
            }
          />
        ))}
    </Timeline>
  ) : (
    <Typography>No Transactions Found</Typography>
  );
};

export default Policies;
