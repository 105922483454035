import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
  AnyAction,
} from "@reduxjs/toolkit";
import DashboardSlice from "../customers/Dashboard/DashboardSlice";
import CommonSlice from "./common/CommonSlice";
import userAuthSlice from "./user/userAuth/userAuthSlice";
import formsSlice from "./admin/forms/formsSlice";
import featuresSlice from "./admin/features/featuresSlice";
import uiSettingsSlice from "./admin/uiSettings/uiSettingsSlice";
import PolicyDetailsSlice from "./user/policyDetails/policyDetailsSlice";
import lookupSlice from "./admin/lookup/lookupSlice";
import claimsSlice from "./user/claims/claimsSlice";
import adminAuthSlice from "./admin/adminAuth/adminAuthSlice";
import driverSlice from "./user/driver/driverSlice";
import vehicleSlice from "./user/vehicle/vehicleSlice";
import policyChangeSlice from "./user/policyChange/policyChangeSlice";
import tasksSlice from "./user/tasks/tasksSlice";
import ProfileSlice from "./user/profile/profileSlice";
import insureAssistSlice from "./user/insureAssist/insureAssistSlice";

const appReducer = combineReducers({
  common: CommonSlice,
  dashboard: DashboardSlice,
  policyDetails: PolicyDetailsSlice,
  policyChange: policyChangeSlice,
  driver: driverSlice,
  tasks: tasksSlice,
  vehicle: vehicleSlice,
  profile: ProfileSlice,
  userAuth: userAuthSlice,
  forms: formsSlice,
  features: featuresSlice,
  uiSettings: uiSettingsSlice,
  lookup: lookupSlice,
  claims: claimsSlice,
  adminAuth: adminAuthSlice,
  insureAssist: insureAssistSlice,
});

const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === "logout") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
