import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { TabSwitchStyles } from "./TabSwitchStyles";
import { TAB1, TAB2 } from "../../constants";
import { Theme } from "@emotion/react";

interface TabSwitchProps {
  tab1Title: string;
  tab2Title: string;
  tab1ActiveImage?: string;
  tab1InActiveImage?: string;
  tab2ActiveImage?: string;
  tab2InActiveImage?: string;
  handleTabClick: (tab: string) => void;
  activeTab?: string;
  isSlide?: boolean;
  tab1AdditionalStyles?: SxProps<Theme>;
  tab2AdditionalStyles?: SxProps<Theme>;
}
const TabSwitch: React.FC<TabSwitchProps> = ({
  handleTabClick,
  tab1Title,
  tab1ActiveImage,
  tab1InActiveImage,
  tab2ActiveImage,
  tab2InActiveImage,
  tab2Title,
  activeTab,
  isSlide,
  tab1AdditionalStyles,
  tab2AdditionalStyles,
}) => {
  return (
    <Box sx={TabSwitchStyles.container}>
      <Box sx={TabSwitchStyles.tabInnerContainer}>
        <Box sx={TabSwitchStyles.innerContainer}>
          <Box
            sx={{
              ...TabSwitchStyles.tabSwitchContainer,
              left: isSlide ? "50%" : 0,
            }}
          />
          <Box sx={TabSwitchStyles.tabSwitchInnerContainer}>
            <Box
              onClick={() => handleTabClick("tab1")}
              sx={Object.assign(
                {},
                TabSwitchStyles.tab1Styles,
                tab1AdditionalStyles
              )}
            >
              {tab1ActiveImage && (
                <img
                  src={
                    activeTab === `${TAB1}`
                      ? tab1ActiveImage
                      : tab1InActiveImage
                  }
                  alt={TAB1}
                />
              )}

              <Typography
                sx={{
                  zIndex: 3,
                  ...TabSwitchStyles.tab1TextStyles,
                  color:
                    activeTab === `${TAB1}`
                      ? "var(--white)"
                      : "var(--support-white)",
                }}
              >
                {tab1Title}
              </Typography>
            </Box>
            <Box
               sx={Object.assign(
                {},
                TabSwitchStyles.tab2Styles,
                tab2AdditionalStyles
              )}
              onClick={() => handleTabClick("tab2")}
            >
              {tab2ActiveImage && (
                <img
                  src={
                    activeTab === `${TAB2}`
                      ? tab2ActiveImage
                      : tab2InActiveImage
                  }
                  alt={TAB2}
                />
              )}

              <Typography
                sx={{
                  zIndex: 3,

                  ...TabSwitchStyles.tab2TextStyles,

                  color:
                    activeTab === `${TAB2}`
                      ? "var(--white)"
                      : "var(--support-white)",
                }}
              >
                {tab2Title}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TabSwitch;
