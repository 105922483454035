import React from "react";
import { Props } from "./Props";

const TopPerformingAgentCardIcon: React.FC<Props> = ({ fill, otherProps }) => {
  const { width, height } = otherProps;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 28"
      fill="none"
    >
      <path
        d="M23.5635 14.7747L19.5988 27.4966H12.8768L8.62891 14.2216C8.62891 14.2216 12.6131 11.2096 15.0978 11.2096C17.5824 11.2096 23.5635 14.7747 23.5635 14.7747Z"
        fill={fill}
      />
      <path
        d="M21.9364 7.16628C21.9364 10.8461 19.3867 13.8291 16.2416 13.8291C13.0965 13.8291 10.5469 10.8461 10.5469 7.16628C10.5469 3.48649 13.0965 0.503433 16.2416 0.503433C19.3867 0.503433 21.9364 3.48649 21.9364 7.16628Z"
        fill="white"
        stroke="#3F3F3F"
      />
      <path
        d="M13.7349 27.4396L10.3331 15.4025C10.1712 14.8296 9.55677 14.5146 8.99706 14.7177L4.26961 16.433C3.82553 16.5941 3.41345 16.8325 3.05239 17.1371C2.23548 17.8264 1.72783 18.8135 1.64233 19.8789L1.12444 26.3326C1.07657 26.9291 1.54776 27.4396 2.14621 27.4396H13.7349Z"
        stroke="#3F3F3F"
      />
      <path
        d="M18.7461 27.4965L22.1496 15.3486C22.3105 14.7744 22.9257 14.4581 23.4863 14.6615L28.2114 16.376C28.6555 16.5371 29.0675 16.7755 29.4286 17.0801C30.2455 17.7694 30.7532 18.7565 30.8387 19.8219L31.3573 26.2855C31.4049 26.8784 30.9395 27.3872 30.3448 27.3925L18.7461 27.4965Z"
        stroke="#3F3F3F"
      />
      <path
        d="M17.3434 15.6498H15.2112C14.8409 15.6498 14.5691 15.9976 14.6586 16.3569L15.0324 17.8572C15.0497 17.9267 15.0537 17.9989 15.0443 18.0699L13.791 27.4949H18.6885L17.5479 18.0597C17.5401 17.9953 17.5434 17.9301 17.5576 17.8668L17.8991 16.3439C17.9789 15.9879 17.7082 15.6498 17.3434 15.6498Z"
        fill="white"
        stroke="#3F3F3F"
      />
    </svg>
  );
};

export default TopPerformingAgentCardIcon;
