export const MyTasksCardStyles = {
  heading: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
  },
  subheading: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "22px",
  },
  headingContainer: {
    gap: "6px",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "25px",
    border: "1px solid var(--shaded-cyan)",
    borderRadius: "8px",
    backgroundColor: "var(--white)",
  },
  hedingDataContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "35px",
    alignItems: "flex-start",
  },
  statusRenderer: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  headingSubHeadingContainer: {
    display: "flex",
    gap: "70px",
    flexWrap: "wrap",
  },
  priorityContainer: {
    gap: "4px",
    minWidth: "142px",
  },
  statusContainer: {
    gap: "4px",
  },
  headingTask: {
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    width: "400px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  menuIcon: {
    cursor: "pointer",
  },
  drawer: {
    width: "870px",
    padding: "unset",
  },
};
