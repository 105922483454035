import { createSlice } from "@reduxjs/toolkit";
import { IDriver } from "../../../models/user/Driver/Driver";
import { GetAddDriverFormJson } from "./driverActions";

const initialState: IDriver = {
  addDriverFormFieldsJson: null,
};

const driverSlice = createSlice({
  name: "driver",
  initialState: initialState,
  reducers: {
    setAddDriverFormFieldsJson: (state, { payload }) => {
      state.addDriverFormFieldsJson = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAddDriverFormJson.fulfilled, (state, { payload }) => {
      state.addDriverFormFieldsJson =
        payload.status === "SUCCESS" ? payload.data : null;
    });
  },
});

export default driverSlice.reducer;
export const { setAddDriverFormFieldsJson } = driverSlice.actions;
