import { Box } from "@mui/material";
import React from "react";
import HeadingText from "../../common/HeadingText/HeadingText";
import HeadingSubHeading from "../../common/HeadingSubHeading/HeadingSubHeading";
import { DocumentPropertiesStyles } from "./DocumentPropertiesStyles";
import pdfIcon from "../../assets/svgs/grouped-pdf-icon.svg";
import {
  CREATED_ON,
  FILE_NAME_CAPS,
  FILE_SIZE,
  FILE_TYPE,
  LAST_MODIFIED,
  LAST_VIEWED,
  PDF,
  PROPERTIES,
  STATUS,
} from "../../constants";
import DashboardBreadCrumb from "../../common/DashboardBreadCrumb/DashboardBreadCrumb";
import { useNavigate } from "react-router-dom";
import CustomIcon from "../../common/CustomIcon/CustomIcon";
interface DocumentPropertiesDataProps {
  fileName: string;
  createdOn: string;
  lastViewed: string;
  lastModified: string;
  fileType: string;
  fileSize: string;
  status: string;
}
interface DocumentPropertiesProps {
  documentPropertiesData: DocumentPropertiesDataProps;
}
const DocumentProperties: React.FC<DocumentPropertiesProps> = ({
  documentPropertiesData,
}) => {
  const navigate = useNavigate();
  return (
    <Box sx={DocumentPropertiesStyles.totalContainer}>
      <DashboardBreadCrumb
        handleClick={() => navigate(-1)}
        additionalContianerClass={DocumentPropertiesStyles.breadCrumb}
        additionalContentClass={DocumentPropertiesStyles.breadCrumbText}
        variant={"primary"}
        text={PROPERTIES}
      />
      <Box sx={DocumentPropertiesStyles.container}>
        <HeadingText
          additionalHeadingClass={DocumentPropertiesStyles.headingText}
          heading={PROPERTIES}
        />
        <Box>
          <Box sx={DocumentPropertiesStyles.pdfCardContainer}>
            <CustomIcon iconName="Document" />
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingHeaderContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.pdfHeading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.pdfSubheading
              }
              heading={documentPropertiesData.fileName}
              subheading={FILE_NAME_CAPS}
            />
          </Box>
          <Box sx={DocumentPropertiesStyles.innerContainer}>
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.heading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.createdOn}
              subheading={CREATED_ON}
            />
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.heading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.lastViewed}
              subheading={LAST_VIEWED}
            />
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.heading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.lastModified}
              subheading={LAST_MODIFIED}
            />
          </Box>
          <Box sx={DocumentPropertiesStyles.lowerContainer}>
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.heading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.fileSize}
              subheading={FILE_SIZE}
            />
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={DocumentPropertiesStyles.heading}
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.fileType}
              subheading={FILE_TYPE}
            />
          </Box>
          <Box sx={DocumentPropertiesStyles.statusContainer}>
            <HeadingSubHeading
              containerAdditionalClassName={
                DocumentPropertiesStyles.headingSubHeadingContainer
              }
              headingAdditionalClassName={
                DocumentPropertiesStyles.statusHeading
              }
              subHeadingAdditionalClassName={
                DocumentPropertiesStyles.subheading
              }
              heading={documentPropertiesData.status}
              subheading={STATUS}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DocumentProperties;
