export const Commisionstyles = {
  container: {
    width: "100%",
    border: "1px solid var(--shaded-cyan)",
    padding: " 20px 16px 16px 20px",
    boxSizing: "border-box",
    borderRadius: "10px",
    backgroundColor: "var(--white)",
  },
  subcontainer: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "20px",
    columnGap: "28px",
  },
  subcontainerboxone: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  separator: {
    borderBottom: "0.5px solid var(--primary-grey)",
    paddingBottom: "20px",
  },
  subcontainerboxtwo: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  datecontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "127px",
    gap: "1px",
  },
  date: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  subdate: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    fontSize: "12px",
    lineHeight: "16.34px",
    color: "var(--primary-black)",
  },
  statementcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "127px",
    gap: "1px",
  },
  statement: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  substatement: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    fontSize: "12px",
    lineHeight: "16.34px",
    color: "var(--primary-black)",
  },
  amountcontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "127px",
    gap: "1px",
  },
  amount: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  commision: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    fontSize: "12px",
    lineHeight: "16.34px",
    color: "var(--primary-black)",
  },
  statuscontainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "127px",
    gap: "1px",
  },
  status: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--primary-black)",
  },
  substatus: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-300)",
    fontSize: "12px",
    lineHeight: "16.34px",
    color: "var(--primary-black)",
  },
  details: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: "20px",
  },
  viewdetails: {
    fontFamily: "var(--font-noto-sans)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "14px",
    lineHeight: "22px",
    color: "var(--support-white)",
  },
  image: {
    width: "16px",
    height: "16px",
    "> img": {
      width: "10.67px",
      height: "13.33px",
      top: "1.33px",
      left: "2.67px",
      borderRadius: "1px",
    },
  },
  gap: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  statusImage: {
    width: "16px",
    height: "16px",
  },
  imageAndText: {
    gap: "4px",
  },
};
