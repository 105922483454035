import React from "react";
import { Box, Typography } from "@mui/material";
import { InteractionHistoryCardStyles } from "./InteractionHistoryCardStyles";

interface InteractionHistoryCardProps {
  cardIcon: React.ReactNode;
  idNumber: string;
  interaction: string;
  text: string;
  profileIcon: string;
  agentName: string;
}

const InteractionHistoryCard: React.FC<InteractionHistoryCardProps> = ({
  cardIcon,
  idNumber,
  interaction,
  text,
  profileIcon,
  agentName,
}) => {
  return (
    <Box sx={InteractionHistoryCardStyles.container}>
      <Box sx={InteractionHistoryCardStyles.imageContainer}>
        <Box sx={InteractionHistoryCardStyles.image}>{cardIcon}</Box>
      </Box>
      <Box sx={InteractionHistoryCardStyles.contentContainer}>
        <Box sx={InteractionHistoryCardStyles.idTextContainer}>
          <Box sx={InteractionHistoryCardStyles.idContainer}>
            <Typography sx={InteractionHistoryCardStyles.idNumber}>
              {idNumber}
            </Typography>
            <Typography sx={InteractionHistoryCardStyles.symbol}>
              {"|"}
            </Typography>
            <Typography sx={InteractionHistoryCardStyles.interaction}>
              {interaction}
            </Typography>
          </Box>
          <Typography sx={InteractionHistoryCardStyles.text}>{text}</Typography>
        </Box>
        <Box sx={InteractionHistoryCardStyles.statusContainer}>
          <Box sx={InteractionHistoryCardStyles.profileContainer}>
            <img src={profileIcon} alt="profile" />
          </Box>
          <Typography sx={InteractionHistoryCardStyles.agentname}>
            {agentName}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InteractionHistoryCard;
