import { Box, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import { FILTER } from "../../constants";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import DropDown from "../../common/DropDown/DropDown";
import { options } from "../../constants/DropDown";
import MyTasksResponsiveCard from "../MyTasksResponsiveCard/MyTasksResponsiveCard";
import { MyTasksResponsiveStyles } from "./MyTasksResponsiveStyles";
import { TasksDataProps } from "../MyTasksTable/MyTasksTable";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
interface TasksCardDataProps {
  tasksData: TasksDataProps[];
}
const MyTasksResponsive: React.FC<TasksCardDataProps> = ({ tasksData }) => {
  const [selectedKey, setSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleDropdownChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (key: string) => {
    setSelectedKey(key);
    handleClose();
  };
  return (
    <>
      <Box sx={MyTasksResponsiveStyles.container}>
        <Box sx={MyTasksResponsiveStyles.lowerContainer}>
          <Box sx={MyTasksResponsiveStyles.dropDownContainer}>
            <DropDown
              data={options}
              formControlAdditionalClassName={MyTasksResponsiveStyles.dropDown}
              placeholder={"Total (30)"}
              selectedValue={""}
              onChange={handleDropdownChange}
            />
            <ImageAndText
              onhandleClick={handleClick}
              image={filterIcon}
              text={FILTER}
            />
          </Box>
          <Box sx={MyTasksResponsiveStyles.tasksCardContainer}>
            {tasksData.map((task, index) => (
              <MyTasksResponsiveCard
                timeStamp={
                  typeof task.timeStamp === "string"
                    ? { date: task.timeStamp, time: "" }
                    : task.timeStamp
                }
                task={task.task}
                clientName={task.clientDetails.clientName}
                policy={task.policy}
                priority={task.priority}
                status={task.status}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </>
  );
};

export default MyTasksResponsive;
