import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  Typography,
  IconButton,
  Avatar,
  Checkbox,
  SxProps,
  Theme,
  SelectChangeEvent,
  Tooltip,
  List,
  ListItem,
} from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import clockIcon from "../../assets/svgs/clock-icon.svg";
import menuIcon from "../../assets/svgs/menu-icon.svg";
import { TablesStyles } from "./TablesStyles";
import highIcon from "../../assets/svgs/high.svg";
import mediumIcon from "../../assets/svgs/medium.svg";
import lowIcon from "../../assets/svgs/low.svg";
import infoIcon from "../../assets/svgs/info-icon.svg";
import renewalIcon from "../../assets/svgs/renewal-icon.svg";
import cancelIcon from "../../assets/svgs/cancelled-icon.svg";
import overdueIcon from "../../assets/svgs/overdue-icon.svg";
import activeIcon from "../../assets/svgs/active-icon.svg";
import pendingIcon from "../../assets/svgs/pending-icon.svg";
import sortIcon from "../../assets/svgs/sorting-icon.svg";
import docIcon from "../../assets/svgs/document-icon.svg";
import downloadIcon from "../../assets/svgs/download-icon.svg";
import descendingSort from "../../assets/svgs/decending-sort.svg";
import inProgress from "../../assets/svgs/in-progress.svg";
import phoneIcon from "../../assets/svgs/phone-icon.svg";
import mailIcon from "../../assets/svgs/mail-icon.svg";
import {
  ACTIVE,
  CLAIMS,
  CLAIM_STATUS,
  CLOSED,
  CONTACT_DETAILS,
  CUSTOMER_DETAILS,
  CUSTOMER_POLICY_STATUS,
  EXPIRED,
  HIGH_PRIORITY,
  INFO_ICON,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
  MENU_ICON,
  NO_DATA_AVAILABLE,
  OVERDUE,
  OVERDUE_CAPS,
  OPEN,
  PENDING_RENEWAL,
  POLICY_DETAILS,
  POLICY_STATUS,
  POLICY_TYPE,
  RENEWAL,
  ACTIVE_CAPS,
  DUE_RENEWAL,
  CANCELLED,
  CANCELLED_CAPS,
  CUSTOMER_STATUS,
  SORT_ICON,
  DETAILS,
  DOWNLOAD,
  PAY_NOW,
  COMMISSION_STATUS,
  TASKS,
  TASK,
  PENDING,
  ACTIVE_LOWER,
  POLICY_LOWER,
  STATUS_LOWER,
  CLIENT_NAME_LOWER,
  PRIORITY_LOWER,
  TASK_DATE_LOWER,
  TASK_LOWER,
  COMMISSION_POLICY_DETAILS,
  COMMISSION_CUSTOMER_DETAILS,
  FOLDER_NAME,
  FILE_NAME,
  NOTES,
  ARCHIVE_FILE,
  ARE_YOU_SURE_ARCHIVE,
  FILE_LOWER,
  CANCEL,
  FOLDER_LOWER,
  ARCHIVING_THE_FOLDER,
  ADD_TASK,
  CLIENT_DETAILS_LOWER,
} from "../../constants";
import CustomTooltip, { StyledListItemText } from "../Tooltip/Tooltip";
import ImageAndText from "../ImageAndText/ImageAndText";
import paynowIcon from "../../assets/svgs/paynow-icon.svg";
import stringAvatar from "../../utils/StringAvatar";
import { useNavigate } from "react-router-dom";
import { myTasksTooltip } from "../../constants/TootipData";
import { personData } from "../../constants/myTasksPersonData";
import { personChat } from "../../constants/myTasksPersonChatData";
import Drawers from "../Drawers/Drawers";
import Notes from "../../components/Notes/Notes";
import CustomIcon from "../CustomIcon/CustomIcon";
import styled from "@emotion/styled";
import ModalComponent from "../ModalComponent/ModalComponent";
import reScheduleIcon from "../../assets/svgs/re-schedule.svg";
import { customerDetailsOverviewOptions } from "../../constants/CustomerDetailsOverviewOptions";
import SendRemainder from "../SendRemainder/SendRemainder";
import { sendRemaindersAndShareMarketData } from "../../constants/SendRemaindersAndShareMarketData";
import NewTask from "../../components/NewTask/NewTask";
interface DataTableProps {
  data: Array<Record<string, any>>;
  headerData: string[];
  type?:
    | "default"
    | "primary"
    | "secondary"
    | "customerPolicies"
    | "customerListing"
    | "myTasksTable"
    | "overviewTable"
    | "invoicesTable"
    | "commissionStatements"
    | "nohover"
    | "documentListingList"
    | "policyListing";
  onhandleDateClick?: () => void;
  primary?: boolean;
  additionaltableContainer?: SxProps<Theme>;
}
const StyledCustomerDetailsAvatar = styled(Box)({
  "& .MuiAvatar-root": {
    width: "32px",
    height: "32px",
    border: "1px solid var(--primary-grey)",
  },
});
const StyledCheckbox = styled(Checkbox)({
  color: "var(--black)",
  padding: "0px",
  width: "16px",
  "& .MuiSvgIcon-root": {
    fontSize: "17px",
  },
});
const Tables: React.FC<DataTableProps> = ({
  data,
  headerData,
  type = "default",
  onhandleDateClick,
  primary = false,
  additionaltableContainer,
}) => {
  const navigate = useNavigate();
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const [openTooltipRow, setOpenTooltipRow] = useState<number | null>(null);
  const [toolTipIndex, setToolTipIndex] = useState<number | null>(null);
  const [notesOpen, setNotesOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState<Record<number, boolean>>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isFolder, setIsFolder] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const [tasksOpen, setTasksOpen] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState<number | null>(null);
  useEffect(() => {
    setOpenTooltipRow(null);
    setToolTipIndex(null);
  }, [hoveredRow, notesOpen, modalOpen, tasksOpen, activeTooltip]);
  useEffect(() => {
    if (openTooltipRow !== null) {
      const rowData = data[openTooltipRow];
      const nameToSet = rowData.fileName || rowData.folderName;
      setIsFolder(!!rowData.folderName);
      setFileName(nameToSet);
    }
  }, [openTooltipRow]);
  const handleDetailsToolTipOpen = (index: number | null) => {
    setActiveTooltip((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleDetailsToolTipClose = () => {
    setActiveTooltip(null);
  };
  if (!data || data.length === 0) {
    return <Typography>{NO_DATA_AVAILABLE}</Typography>;
  }
  const drawerOptions = [
    { value: "View", key: "view" },
    { value: "Edit", key: "edit" },
    { value: "Move", key: "move" },
    { value: "Archive", key: "archive" },
  ];
  const handleRowClick = (type: String) => {
    if (type === "policyListing") {
      navigate("/policy-details");
    }
    if (type === "customerListing") {
      navigate("/customer-details");
    }
  };
  const headers = headerData?.length === 0 ? data[0] : headerData;
  const renderCellContent = (key: string, value: any, rowIndex: any) => {
    switch (key) {
      case TASK_DATE_LOWER:
        return (
          <CustomButton
            onClick={onhandleDateClick}
            additionalContainerClassName={TablesStyles.dateButtonContainer}
            additionalClassName={TablesStyles.dateButton}
            text={value.date + "," + value.time}
            type="grey-outlined"
          />
        );
      case PRIORITY_LOWER:
        let priorityText = "";
        let priorityImg = null;

        if (value.toLowerCase() === "high") {
          priorityText = HIGH_PRIORITY;
          priorityImg = highIcon;
        } else if (value.toLowerCase() === "medium") {
          priorityText = MEDIUM_PRIORITY;
          priorityImg = mediumIcon;
        } else if (value.toLowerCase() === "low") {
          priorityText = LOW_PRIORITY;
          priorityImg = lowIcon;
        }

        return (
          <Box sx={TablesStyles.priorityContainer}>
            {priorityImg && <img src={priorityImg} alt={priorityText} />}
            <Typography sx={TablesStyles.priorityText}>
              {priorityText}
            </Typography>
          </Box>
        );
      case CLIENT_DETAILS_LOWER:
        return (
          <Box sx={TablesStyles.clientInfoContainer}>
            <Typography sx={TablesStyles.clientName}>
              {value.clientName}
            </Typography>

            <Tooltip
              arrow
              placement="right"
              open={activeTooltip === rowIndex}
              onClose={handleDetailsToolTipClose}
              componentsProps={{
                tooltip: {
                  sx: {
                    background: "var( --white)",
                    border: "1px solid #CFD4E1",
                    color: "var( --black)",
                  },
                },
                arrow: {
                  sx: {
                    color: "var( --white)",
                    position: "relative",
                    "&::before": {
                      border: "1px solid #CFD4E1",
                      margin: "-1px",
                    },
                  },
                },
              }}
              title={
                <List>
                  <ListItem button>
                    <StyledListItemText>
                      <ImageAndText image={mailIcon} text={value.clientEmail} />
                    </StyledListItemText>
                  </ListItem>
                  <ListItem button>
                    <StyledListItemText>
                      <ImageAndText
                        image={phoneIcon}
                        text={value.clientPhone}
                      />
                    </StyledListItemText>
                  </ListItem>
                </List>
              }
            >
              <IconButton onClick={() => handleDetailsToolTipOpen(rowIndex)}>
                <img src={infoIcon} alt={INFO_ICON} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      case POLICY_LOWER:
        return (
          <Typography
            sx={TablesStyles.policyText}
            onClick={() => navigate("/policy-details")}
          >
            {value}
          </Typography>
        );
      case STATUS_LOWER:
        const renderStatus = (status: string) => {
          let statusImage = "";
          let statusText = "";
          if (status.toLowerCase() === "pending") {
            statusImage = clockIcon;
            statusText = "Pending";
          }
          if (status.toLowerCase() === "active") {
            statusImage = activeIcon;
            statusText = "Active";
          }
          if (status.toLowerCase() === "in progress") {
            statusImage = inProgress;
            statusText = "In Progress";
          }
          if (status.toLowerCase() === "rescheduled") {
            statusImage = reScheduleIcon;
            statusText = "Rescheduled";
          }
          return (
            <ImageAndText
              additionalClassName={TablesStyles.statusContainer}
              image={statusImage}
              text={statusText}
            />
          );
        };
        return renderStatus(value);
      case CUSTOMER_STATUS:
        let customerStatusImg = "";
        let customerStatusText = "";
        if (value.toLowerCase() === ACTIVE_LOWER) {
          customerStatusImg = activeIcon;
          customerStatusText = ACTIVE_CAPS;
        } else if (value.toLowerCase() === RENEWAL) {
          customerStatusImg = renewalIcon;
          customerStatusText = DUE_RENEWAL;
        } else if (value.toLowerCase() === OVERDUE) {
          customerStatusImg = overdueIcon;
          customerStatusText = OVERDUE_CAPS;
        } else if (value.toLowerCase() === CANCELLED) {
          customerStatusImg = cancelIcon;
          customerStatusText = CANCELLED_CAPS;
        }

        return (
          <ImageAndText
            imageClassName={TablesStyles.customerStatusImg}
            additionalClassName={TablesStyles.customerStatusContainer}
            image={customerStatusImg}
            text={customerStatusText}
          />
        );
      case POLICY_DETAILS:
        return (
          <Box sx={TablesStyles.policyDetails}>
            <Typography
              onClick={() => navigate("/policy-details")}
              sx={TablesStyles.policyNumber}
            >
              {value.policyNumber}
            </Typography>
            <Typography sx={TablesStyles.policyTypeNumber}>
              {value.policyTypeNumber}
            </Typography>
          </Box>
        );
      case POLICY_STATUS:
        let policyStatus = "";
        let policyStatusImg = null;

        if (value.toLowerCase() === "pending") {
          policyStatus = PENDING_RENEWAL;
          policyStatusImg = pendingIcon;
        } else if (value.toLowerCase() === "active") {
          policyStatus = ACTIVE;
          policyStatusImg = activeIcon;
        }

        return (
          <ImageAndText
            imageClassName={TablesStyles.policyStatusImg}
            image={policyStatusImg}
            text={policyStatus}
          />
        );
      case POLICY_TYPE:
        let policyTypeImage = "";
        if (value.toLowerCase() === "house") {
          policyTypeImage = "House";
        } else if (value.toLowerCase() === "auto") {
          policyTypeImage = "";
        }
        return (
          <Box sx={TablesStyles.policyType}>
            {policyTypeImage && <CustomIcon iconName={policyTypeImage} />}
          </Box>
        );
      case CUSTOMER_DETAILS:
        return (
          <Box sx={TablesStyles.customerDetailsContainer}>
            <StyledCustomerDetailsAvatar>
              {value.image.length === 0 ? (
                <Avatar {...stringAvatar(value.customerName)} />
              ) : (
                <Avatar src={value.image} />
              )}
            </StyledCustomerDetailsAvatar>
            <Box>
              <Typography sx={TablesStyles.customerName}>
                {value.customerName}
              </Typography>
              <Typography sx={TablesStyles.customerNumber}>
                {value.customerNumber}
              </Typography>
            </Box>
          </Box>
        );
      case CONTACT_DETAILS:
        return (
          <Box sx={TablesStyles.contactDetailsContainer}>
            <Typography sx={TablesStyles.customerPhoneNumber}>
              {value.customerPhoneNumber}
            </Typography>
            <Typography sx={TablesStyles.customerEmail}>
              {value.customerEmail}
            </Typography>
          </Box>
        );
      case CUSTOMER_POLICY_STATUS: {
        const { active, pending, expired, tasks } = value;
        let statusMessage = "";
        let tasksMessage = "";
        if (active > 0) {
          statusMessage = `${active} ${ACTIVE}`;
          if (pending > 0) {
            statusMessage += ` | ${pending} ${PENDING_RENEWAL}`;
          }
          if (expired > 0) {
            statusMessage += ` | ${expired} ${EXPIRED}`;
          }
        } else if (pending > 0) {
          statusMessage = `${pending}  ${PENDING_RENEWAL}`;
          if (expired > 0) {
            statusMessage += ` | ${expired} ${EXPIRED}`;
          }
        } else if (expired > 0) {
          statusMessage = `${expired} ${EXPIRED}`;
        }
        if (tasks > 0) {
          tasksMessage = `${tasks} ${tasks > 1 ? TASKS : TASK} ${PENDING}`;
        }

        return (
          <Box sx={TablesStyles.tasksMessage}>
            <Typography sx={TablesStyles.statusText}>
              {statusMessage}
            </Typography>
            <Typography sx={TablesStyles.statusText}>{tasksMessage}</Typography>
          </Box>
        );
      }
      case CLAIM_STATUS: {
        const { open = 0, closed = 0 } = value;
        let statusMessage = "";

        if (open === 0 && closed === 0) {
          statusMessage = `0 ${CLAIMS}`;
        } else {
          if (open > 0 && closed > 0) {
            statusMessage = `${open} ${OPEN} | ${closed} ${CLOSED}`;
          } else if (open > 0 && closed === 0) {
            statusMessage = `${open} ${OPEN}`;
          } else if (open === 0 && closed > 0) {
            statusMessage = `${closed} ${CLOSED}`;
          } else if (open === 0 && closed === 0) {
            statusMessage = `0 ${CLAIMS}`;
          }
        }

        return (
          <Typography sx={TablesStyles.statusText}>{statusMessage}</Typography>
        );
      }
      case COMMISSION_STATUS:
        let commissionStatusImage = "";
        let commissionStatusText = "";
        if (value.toLowerCase() === "pending") {
          commissionStatusImage = clockIcon;
          commissionStatusText = "Pending";
        } else if (value.toLowerCase() === "paid") {
          commissionStatusImage = activeIcon;
          commissionStatusText = "Paid";
        }
        return (
          <ImageAndText
            additionalClassName={TablesStyles.commissionStatusContainer}
            imageClassName={TablesStyles.commissionStatusImage}
            image={commissionStatusImage}
            text={commissionStatusText}
          />
        );
      case TASK_LOWER:
        return <Typography sx={TablesStyles.tasksText}>{value}</Typography>;
      case COMMISSION_POLICY_DETAILS:
        return (
          <Typography sx={TablesStyles.policyDetailsText}>{value}</Typography>
        );
      case COMMISSION_CUSTOMER_DETAILS:
        return (
          <Typography sx={TablesStyles.customerDetailsText}>{value}</Typography>
        );
      case FOLDER_NAME:
        return (
          <ImageAndText
            imageClassName={TablesStyles.folderImage}
            image={<CustomIcon width="24px" height="24px" iconName="Folder" />}
            text={value}
            additionalClassText={TablesStyles.folderName}
          />
        );
      case FILE_NAME:
        return (
          <ImageAndText
            imageClassName={TablesStyles.folderImage}
            image={
              <CustomIcon width="24px" height="24px" iconName="Document" />
            }
            text={value}
            additionalClassText={TablesStyles.folderName}
          />
        );
      default:
        return value.length === 0 ? "----" : value;
    }
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleSelectAllChange = () => {
    if (checkedItems.length === data.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(data.map((_, index) => index));
    }
  };

  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(index)) {
        return prevCheckedItems.filter((i) => i !== index);
      } else {
        return [...prevCheckedItems, index];
      }
    });
  };
  const handleToolTip = (index: any) => {
    setOpenTooltipRow(index);
  };
  const handleToolTipItem = (key: string) => {
    if (key === "notes") {
      setNotesOpen(true);
      setOpenTooltipRow(null);
      setToolTipIndex(null);
    }
    if (key === "archive") {
      setModalOpen(true);
      setOpenTooltipRow(null);
      setToolTipIndex(null);
    }
    if (key === "rescheduleTask") {
      setTasksOpen(true);
      console.log("rescheduleTask", openTooltipRow);
      setOpenTooltipRow(null);
      setToolTipIndex(null);
      console.log("reschedule2Task", openTooltipRow);
    }
    if (key === "sendRemainder") {
      setDrawerOpen(true);
    }
    if (key === "viewPolicyDetails" || key === "viewDetails") {
      navigate("/policy-details");
    }
    if (key === "view") {
      if (!isFolder) {
        navigate("/document-details");
      }
    }
  };
  const handleNotesClick = () => {
    setNotesOpen(!notesOpen);
  };
  const handleModalClose = () => {
    setModalOpen(!modalOpen);
  };
  const handleSort = (index: number) => {
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      [index]: !prevSortOrder[index],
    }));
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const handleRemainderChange = (event: SelectChangeEvent) => {
    setSelectedDropdownValue(event.target.value as string);
  };
  const handleTasksClick = () => {
    setTasksOpen(!tasksOpen);
  };
  return (
    <>
      <TableContainer
        sx={Object.assign(
          {},
          TablesStyles.tableContainer,
          additionaltableContainer
        )}
        component={Paper}
      >
        <Table>
          <TableHead sx={TablesStyles.tableHead}>
            <TableRow>
              {primary && (
                <TableCell sx={TablesStyles.tableHeaderCheckboxCells}>
                  <StyledCheckbox
                    checked={checkedItems.length === data.length}
                    onChange={handleSelectAllChange}
                    {...label}
                    color="default"
                  />
                </TableCell>
              )}

              {headers.map((header: string, index: number) => (
                <TableCell
                  sx={{
                    ...(type === "invoicesTable"
                      ? TablesStyles.invoicesTableHeaderCells
                      : TablesStyles.tableHeaderCells),
                  }}
                >
                  <Box sx={TablesStyles.tableHeader}>
                    <Typography sx={TablesStyles.tableHeaderText}>
                      {header}
                    </Typography>
                    {type === "invoicesTable" && (
                      <>
                        {sortOrder[index] ? (
                          <img
                            onClick={() => handleSort(index)}
                            src={sortIcon}
                            alt={SORT_ICON}
                          />
                        ) : (
                          <img
                            onClick={() => handleSort(index)}
                            src={descendingSort}
                            alt={SORT_ICON}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </TableCell>
              ))}
              <TableCell sx={TablesStyles.tableHeaderLastCell}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                sx={{
                  ...TablesStyles.tableRow,
                  ...(type !== "nohover" && {
                    ":hover": {
                      backgroundColor: "var(--greyish-blue)",
                      ...(type === "policyListing" || type === "customerListing"
                        ? { cursor: "pointer" }
                        : {}),
                    },
                  }),
                }}
                key={rowIndex}
                onMouseEnter={
                  type !== "nohover" && type !== "policyListing"
                    ? () => setHoveredRow(rowIndex)
                    : undefined
                }
                onMouseLeave={
                  type !== "nohover" && type !== "policyListing"
                    ? () => setHoveredRow(null)
                    : undefined
                }
                onClick={() => handleRowClick(type)}
              >
                {primary && (
                  <TableCell sx={TablesStyles.tableBodyCheckboxCells}>
                    <StyledCheckbox
                      checked={checkedItems.includes(rowIndex)}
                      onChange={() => handleCheckboxChange(rowIndex)}
                      {...label}
                      color="default"
                    />
                  </TableCell>
                )}
                {Object.keys(row).map((key) => (
                  <TableCell
                    sx={{
                      ...(type === "invoicesTable"
                        ? TablesStyles.invoicesTableBodyCells
                        : TablesStyles.tableBodyCells),
                    }}
                    key={key}
                  >
                    {renderCellContent(key, row[key], rowIndex)}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    ...TablesStyles.tableBodyLastCell,
                    ...(type === "documentListingList" && {
                      textAlign: "right",
                    }),
                  }}
                >
                  {type === "myTasksTable" && (
                    <IconButton
                      sx={
                        hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent
                      }
                      onClick={() => {
                        setToolTipIndex(rowIndex);
                        handleToolTip(rowIndex);
                      }}
                    >
                      <CustomTooltip
                        menuIcon={menuIcon}
                        MENU_ICON={MENU_ICON}
                        myTasksTooltip={myTasksTooltip}
                        handleTooltipItem={handleToolTipItem}
                        open={
                          openTooltipRow === rowIndex &&
                          toolTipIndex === rowIndex
                        }
                        handleTooltip={() => handleToolTip(toolTipIndex)}
                      />
                    </IconButton>
                  )}
                  {type === "overviewTable" && (
                    <Box
                      sx={{
                        ...(hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent),
                        ...TablesStyles.overView,
                      }}
                    >
                      <ImageAndText image={paynowIcon} text={PAY_NOW} />
                      <IconButton
                        sx={
                          hoveredRow === rowIndex
                            ? TablesStyles.imagePresent
                            : TablesStyles.imageAbsent
                        }
                        onClick={() => {
                          setToolTipIndex(rowIndex);
                          handleToolTip(rowIndex);
                        }}
                      >
                        <CustomTooltip
                          menuIcon={menuIcon}
                          MENU_ICON={MENU_ICON}
                          myTasksTooltip={customerDetailsOverviewOptions}
                          handleTooltipItem={handleToolTipItem}
                          open={
                            openTooltipRow === rowIndex &&
                            toolTipIndex === rowIndex
                          }
                          handleTooltip={() => handleToolTip(toolTipIndex)}
                        />
                      </IconButton>
                    </Box>
                  )}
                  {type === "invoicesTable" && (
                    <Box
                      sx={{
                        ...(hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent),
                        ...TablesStyles.overView,
                      }}
                    >
                      <ImageAndText image={downloadIcon} text={DOWNLOAD} />
                      <ImageAndText image={paynowIcon} text={PAY_NOW} />
                    </Box>
                  )}
                  {type === "customerListing" && (
                    <Box
                      sx={{
                        ...(hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent),
                        ...TablesStyles.customerListingHoverElement,
                      }}
                    >
                      <ImageAndText
                        onhandleClick={() => navigate("/customer-details")}
                        image={docIcon}
                        text={DETAILS}
                      />
                    </Box>
                  )}

                  {type === "commissionStatements" && (
                    <Box
                      sx={{
                        ...(hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent),
                        ...TablesStyles.commionStatementsHoverElement,
                      }}
                    >
                      <ImageAndText
                        imageClassName={TablesStyles.docIcon}
                        additionalClassName={
                          TablesStyles.commissionStatementsHoverContainer
                        }
                        image={docIcon}
                        text={DETAILS}
                        onClick={() => navigate("/monthly-statement")}
                      />
                      <ImageAndText
                        imageClassName={TablesStyles.downloadIcon}
                        additionalClassName={
                          TablesStyles.commissionStatementsHoverContainer
                        }
                        image={downloadIcon}
                        text={DOWNLOAD}
                      />
                    </Box>
                  )}
                  {type === "documentListingList" && (
                    <IconButton
                      sx={
                        hoveredRow === rowIndex
                          ? TablesStyles.imagePresent
                          : TablesStyles.imageAbsent
                      }
                      onClick={() => {
                        setToolTipIndex(rowIndex);
                        handleToolTip(rowIndex);
                      }}
                    >
                      <CustomTooltip
                        menuIcon={menuIcon}
                        MENU_ICON={MENU_ICON}
                        myTasksTooltip={drawerOptions}
                        handleTooltipItem={handleToolTipItem}
                        open={
                          openTooltipRow === rowIndex &&
                          toolTipIndex === rowIndex
                        }
                        handleTooltip={() => handleToolTip(toolTipIndex)}
                      />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawers open={notesOpen} toggleDrawer={handleNotesClick} heading={NOTES}>
        <Notes personDataArray={personData} personChatArray={personChat} />
      </Drawers>
      <ModalComponent
        headingAdditionalClass={TablesStyles.modalHeading}
        heading={
          isFolder
            ? ARE_YOU_SURE_ARCHIVE + `"${fileName}"` + FOLDER_LOWER
            : ARCHIVE_FILE
        }
        open={modalOpen}
        handleOpen={handleModalClose}
        additionalClassContainer={TablesStyles.modalContainer}
        headingContainerAdditionalClass={TablesStyles.modalHeadingContainer}
      >
        <Box sx={TablesStyles.contentContainer}>
          <ImageAndText
            image={<CustomIcon iconName="Archive" />}
            text={
              isFolder
                ? ARCHIVING_THE_FOLDER
                : ARE_YOU_SURE_ARCHIVE + `"${fileName}"` + FILE_LOWER
            }
            additionalClassText={TablesStyles.imageText}
          />
          <Box sx={TablesStyles.buttonsContainer}>
            <CustomButton
              text={"Confirm"}
              additionalClassName={TablesStyles.cancelButton}
            />
            <CustomButton
              onHandleClick={handleModalClose}
              text={CANCEL}
              type="outlined"
              additionalClassName={TablesStyles.cancelButton}
            />
          </Box>
        </Box>
      </ModalComponent>
      <Drawers
        open={drawerOpen}
        toggleDrawer={toggleDrawer}
        heading={"Send Reminder"}
      >
        <SendRemainder
          recieverName={sendRemaindersAndShareMarketData.recieverName}
          dropDownData={sendRemaindersAndShareMarketData.dropdownData}
          fileUploadName={sendRemaindersAndShareMarketData.fileUploadName}
          fileUploadSize={sendRemaindersAndShareMarketData.fileUploadSize}
          handleRemainderChange={handleRemainderChange}
          selectedDropdownValue={selectedDropdownValue}
          onCancelClick={toggleDrawer}
        />
      </Drawers>
      <Drawers
        additionalClass={TablesStyles.drawer}
        open={tasksOpen}
        toggleDrawer={handleTasksClick}
        heading={ADD_TASK}
      >
        <NewTask onCancelClick={handleTasksClick} />
      </Drawers>
    </>
  );
};

export default Tables;
