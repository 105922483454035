import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CoveragesLogo from "../../../assets/CoveragesLogo.svg";
import coverageLinksData from "../../../mockJson/CustomerDashboard/CoverageAndLinksData.json";
import CoverageTabStyles from "./CoverageTabStyles";
import { coveragesLimits } from "../../../constants/Constants";

const CoverageAndLinks = () => {
  const tableHead = coverageLinksData.find((row) => row.id === "0");

  if (!tableHead) return null;

  const { Coverages, Limit, Deductible, Vechile1, Vechile2 } = tableHead;

  return (
    <Box sx={CoverageTabStyles.coverageAndLimits}>
      <Box sx={CoverageTabStyles.coverageAndLimitsHeading}>
        <Box>
          <img src={CoveragesLogo} alt="coverages logo" />
        </Box>
        <Box>
          <Typography sx={CoverageTabStyles.coverageAndLimitsHeadingText}>
            {coveragesLimits}
          </Typography>
        </Box>
      </Box>
      <TableContainer sx={CoverageTabStyles.tableContainer} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={CoverageTabStyles.tableRow}>
              <TableCell sx={CoverageTabStyles.tableHead}>
                {Coverages}
              </TableCell>
              <TableCell
                sx={{
                  ...CoverageTabStyles.tableHead,
                  ...CoverageTabStyles.textAlign,
                }}
              >
                {Limit}
              </TableCell>
              <TableCell
                sx={{
                  ...CoverageTabStyles.tableHead,
                  ...CoverageTabStyles.textAlign,
                }}
              >
                {Deductible}
              </TableCell>
              <TableCell
                sx={{
                  ...CoverageTabStyles.tableHead,
                  ...CoverageTabStyles.textAlign,
                }}
              >
                {Vechile1}
              </TableCell>
              <TableCell
                sx={{
                  ...CoverageTabStyles.tableHead,
                  ...CoverageTabStyles.textAlign,
                }}
              >
                {Vechile2}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coverageLinksData
              .filter((row) => row.id !== "0")
              .map((row) => (
                <TableRow sx={CoverageTabStyles.tableRows} key={row.id}>
                  <TableCell
                    sx={CoverageTabStyles.tableBodyCell}
                    component="th"
                    scope="row"
                  >
                    <Box>
                      <Typography sx={CoverageTabStyles.coveragesText}>
                        {row.Coverages}
                      </Typography>
                      <Typography sx={CoverageTabStyles.perThing}>
                        {row.perThing}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...CoverageTabStyles.tableBodyCell,
                      ...CoverageTabStyles.textAlign,
                    }}
                  >
                    {row.Limit}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...CoverageTabStyles.tableBodyCell,
                      ...CoverageTabStyles.textAlign,
                    }}
                  >
                    {row.Deductible}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...CoverageTabStyles.tableBodyCell,
                      ...CoverageTabStyles.textAlign,
                    }}
                  >
                    {row.Vechile1}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...CoverageTabStyles.tableBodyCell,
                      ...CoverageTabStyles.textAlign,
                    }}
                  >
                    {row.Vechile2}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default CoverageAndLinks;
