import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ApplicationRoutes from "./Routes";
import "./index.css";
import "./global.css";
import { store } from "./store/store";
import reportWebVitals from "./reportWebVitals";
import { pdfjs } from "react-pdf";
import createApiInstance from "./Apis/AxiosInterceptor";
import "./global.css";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Api = createApiInstance(store.dispatch);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <ApplicationRoutes />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { Api };
