export const CommissionStatementsListStyles = {
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  filterContainer: {
    display: "flex",
    gap: "20px",
    width: "213px",
    justifyContent: "flex-end",
  },
  headingsContainer: {
    display: "flex",
    gap: "65px",
    border: "1px solid var(--shaded-cyan)",
    padding: "20px",
    borderRadius: "8px",
    height: "107px",
    backgroundColor: "var(--white)",
  },
  container: {

  
   
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
  },
  line: {
    color: "var(--primary-grey)",
    width: "1px",
    fontSize: "var(--font-size-20)",
  },
  headingSubheadingContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1px",
  },
  imageAndTextContainer: {
    gap: "4px",
  },
  image: {
    ">img": {
      width: "20px",
      height: "20px",
    },
  },
  headingText: {
    fontSize: "var(--font-size-20)",
    "@media (max-width: 1023px)": {
      fontSize: "var(--font-size-16)",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 344px)",
  },
};
