import React from "react";
import { Box, Typography } from "@mui/material";
import { PaymentScheduleCardStyles } from "./PaymentScheduleCardStyles";
import paidIcon from "../../assets/svgs/paid-dollar-icon.svg";
import pendingIcon from "../../assets/svgs/clock-icon.svg";
import ImageAndText from "../ImageAndText/ImageAndText";
import {
  COMMISSION_AMOUNT,
  EXPECTED_DATE,
  STATUS,
  UNDERWRITER,
} from "../../constants";
interface PaymentDetailsProps {
  date: string | number;
  name: string;
  payment: string;
  price: string;
}
interface DetailsProps {
  paymentdetaildata: PaymentDetailsProps[];
}
const PaymentScheduleCard: React.FC<DetailsProps> = ({ paymentdetaildata }) => {
  const renderPrice = (price: string) => {
    let pricetext = "";
    let priceImage = "";
    if (price.toLowerCase() === "paid") {
      pricetext = "Paid";
      priceImage = paidIcon;
    } else if (price.toLowerCase() === "pending") {
      pricetext = "Pending";
      priceImage = pendingIcon;
    }
    return (
      <ImageAndText
        additionalClassName={PaymentScheduleCardStyles.imagesContainer}
        image={priceImage}
        text={pricetext}
      />
    );
  };
  return (
    <>
      {paymentdetaildata.map((data, index) => (
        <Box key={index} sx={PaymentScheduleCardStyles.container}>
          <Box sx={PaymentScheduleCardStyles.innerContainer}>
            <Typography sx={PaymentScheduleCardStyles.date}>
              {data.date}
            </Typography>
            <Typography sx={PaymentScheduleCardStyles.text}>
              {EXPECTED_DATE}
            </Typography>
          </Box>
          <Box sx={PaymentScheduleCardStyles.innerContainer}>
            <Typography sx={PaymentScheduleCardStyles.name}>
              {data.name}
              <Typography sx={PaymentScheduleCardStyles.text}>
                {UNDERWRITER}
              </Typography>
            </Typography>
          </Box>
          <Box sx={PaymentScheduleCardStyles.innerContainer}>
            <Typography sx={PaymentScheduleCardStyles.payment}>
              {renderPrice(data.payment)}
            </Typography>
            <Typography sx={PaymentScheduleCardStyles.text}>
              {STATUS}
            </Typography>
          </Box>
          <Box sx={PaymentScheduleCardStyles.innerContainer}>
            <Typography sx={PaymentScheduleCardStyles.price}>
              {data.price}
            </Typography>
            <Typography sx={PaymentScheduleCardStyles.text}>
              {COMMISSION_AMOUNT}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default PaymentScheduleCard;
