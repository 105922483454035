export const LatestNewsCardStyles = {
  insuranceContainer: {
    display: "flex",
    gap: "5px",
  },
  container: {
    display: "flex",
    gap: "14px",
    padding: "15px 16px 14px 10px",
    border: "1px solid var(--primary-grey)",
    borderRadius: "8px",
    "@media screen and (max-width: 599px)": {
      padding: "8px",
      border: "none",
      borderRadius: "0px",
    },
  },
  mobileContainer: {
    borderTop: "none",
    borderBottom: "1px solid var(--primary-grey)",
    borderLeft: "none",
    borderRight: "none",
    display: "flex",
    gap: "14px",
    alignItems: "center",
    padding: "8px",
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    justifyContent: "space-between",
    "@media screen and (max-width: 1365px)": {
      gap: "12px",
    },
  },
  insurance: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-300)",
    lineHeight: "19.07px",
    textAlign: "left",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
    },
  },
  description: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    "@media screen and (max-width: 1023px)": {
      width: "90%",
      WebkitLineClamp: 3,
      fontSize: "var(--font-size-12)",
      lineHeight: "16.34px",
    },
  },
  imageContainer: {
    marginTop: "6px",
  },
  modalContainer: {
    width: "500px",
    height: "fit-content",
    "@media screen and (max-width: 599px)": {
      width: "320px",
    },
  },
  modalDescription: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-14)",
    fontWeight: "var(--font-weight-400)",
    lineHeight: "22px",
    textAlign: "left",
    color: "var(--primary-black)",
    "@media screen and (max-width: 1023px)": {
      fontSize: "var(--font-size-12)",
      lineHeight: "16.34px",
    },
  },
  modalContent: {
    marginTop: "20px",
    minHeight: "calc(100% - 60px)",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  modalFooter:{
    marginTop: "20px",
  }
};
