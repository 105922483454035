const AcknowledgementStyles = {
  totalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 120px)",
  },
  heading: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #3F3F3F",
    position: "sticky",
    top: 0,
    background: "#FBFDFC",
    zIndex: 99,
    paddingTop: "30px",
    img: {
      cursor: "pointer",
    },
    "@media (min-width:360px) and (max-width:599px)": {
      paddingBottom: "0px",
      height: "21.2px",
      alignItems: "center",
      padding: "19px 30px 19px 20px",
      border: "1px solid #D8D8D8",
    },
  },
  headingText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "400",
    textAlign: "left",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "780px",
    margin: "auto",

    linearGradient: "(180deg, #FBFDFC 0%, #E5F6FE 100%)",
    "@media(min-width:600px) and (max-width:1023px)": {
      maxWidth: "540px",
    },
    "@media(min-width:360px) and (max-width:599px)": {
      width: "auto",
      justifyContent: "center",
    },
  },
  successImage: {
    width: "60px",
    height: "60px",
    margin: "0px auto",
    img: {
      width: "100%",
      height: "100%",
    },
  },
  claimData: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  agentData: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  claimSubmittedText: {
    fontFamily: "Playfair Display",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
  },
  referenceNumberText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    color: "#222222",
  },
  referenceNumber: {
    fontFamily: "Noto Sans",
    fontSize: "20px",
    fontWeight: "700",
    textAlign: "center",
    color: "#222222",
  },
  contactAgentText: {
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    color: "#222222",
  },
  agentName: {
    fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "center",
    color: " #000000",
  },
  agentNumber: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "center",
    color: " #171717",
  },
  agentMailId: {
    fontFamily: "Noto Sans",
    fontSize: "16px",
    fontWeight: "400",
    textAlign: "center",
    color: "#171717",
  },
  button: {
    width: "300px",
    height: "30px",
    padding: "16px 12px 16px 12px",
    gap: "0px",
    borderRadius: "40px",
    background: "#3F3F3F",
    boxShadow: "0px 6px 10px 0px #3F3F3F4D",
    fontFamily: "Noto Sans",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "center",
    textTransform: "none",
  },
  submitButton: {
    margin: " 0px auto",
    "@media(min-width:360px) and (max-width:799px)": {
      padding: "0px 20px",
    },
  },
  agentContact: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    justifyContent: "center",
    img: {
      width: "20px",
      height: "20px",
    },
  },
  agentemailContact: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    justifyContent: "center",
    img: {
      width: "20px",
      height: "20px",
    },
  },
};
export default AcknowledgementStyles;
