export const CustomerDetailsOverViewStyles = {
  heading: {
    fontFamily: "var(--font-noto-sans)",
    fontSize: "var(--font-size-16)",
    fontWeight: "var(--font-weight-500)",
    color: "var(--primary-black)",
    lineHeight: "22px",
  },
  policyContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  tasksContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
};
