import React from "react";
import { Box, Typography } from "@mui/material";
import ClaimsTabStyles from "./ClaimsTabStyles";
import ClaimsItem from "./ClaimsItem";
import {
  ACTIVE_CLAIM,
  PAST_CLAIM,
  activeClaims,
  pastClaims,
} from "../../../constants/Constants";

const ClaimTabComponent: React.FC = () => {
  return (
    <Box aria-label="Claims Tab" sx={ClaimsTabStyles.container}>
      <Box
        aria-label="Active Claims"
        sx={ClaimsTabStyles.activeClaimsContainer}
      >
        <Box sx={ClaimsTabStyles.titleBox}>
          <img src={ACTIVE_CLAIM} alt="Active Claims Icon" />
          <Typography sx={ClaimsTabStyles.titleText}>{activeClaims}</Typography>
        </Box>
        <ClaimsItem linkText={"Track Your Claim"} />
      </Box>
      <Box
        aria-label="Active Claims"
        sx={ClaimsTabStyles.activeClaimsContainer}
      >
        <Box sx={ClaimsTabStyles.titleBox}>
          <img src={PAST_CLAIM} alt="Past Claims Icon" />
          <Typography sx={ClaimsTabStyles.titleText}>{pastClaims}</Typography>
        </Box>
        <ClaimsItem linkText={"View Details"} />
      </Box>
    </Box>
  );
};

export default ClaimTabComponent;
