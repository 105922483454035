export const LineChartStyles = {
    lineChartContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: "-5px",
        "@media screen and (max-width:599px)":{
            marginLeft: "-30px",
            marginTop: "20px",
        },
    },
};